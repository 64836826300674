import React from "react";
import { FormattedMessage } from "react-intl";
import { DocumentRow } from "./DocumentRow";
import { Table } from "react-bootstrap";

export function DocumentsTable(props) {
  const { data, handleRowClick } = props;

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.DOCUMENT_FORMAT" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.DATE" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((document) => (
          <DocumentRow
            key={`document-${document.id}`}
            document={document}
            handleClick={handleRowClick}
          />
        ))}
      </tbody>
    </Table>
  );
}
