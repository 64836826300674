import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Button,
  Container,
  Form,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";
import { DashboardBPartnersTable } from "./DashboardBPartnersTable";
import _ from "lodash";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

export function DashboardFilter(props) {
  const {
    initialFilterValue,
    croppings,
    filterFieldsAndCroppings,
    showOnlyEcoCroppings,
    onTogglingEcoCroppings,
    centerOnMap,
  } = props;
  const [key, setKey] = useState("filters");
  const [filter, setFilter] = useState("");
  const [bParners, setBPartners] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const bparners = croppings.map((row) => row.bpartner);
    setBPartners(_.uniqBy(bparners, "bpartnerId"));
  }, [croppings]);

  useEffect(() => {
    setFilter(initialFilterValue);
  }, [initialFilterValue]);

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onFilterRows = () => {
    setFilterApplied(true);
    filterFieldsAndCroppings(filter);
  };

  const showFilters = useSelector((state) => state.cmDashboard.showFilters);
  const dispatch = useDispatch();

  const closeFilters = () => {
    dispatch({
      type: "cmDashboard/setShowFilters",
      payload: false,
    });
  };

  return (
    <div
      className={`card card-custom gutter-b to-parent-height${
        showFilters ? "" : " d-none"
      }`}
    >
      <Button
        onClick={() => closeFilters()}
        size="sm"
        variant="light"
        className="align-self-start position-relative z-50"
      >
        <i className="fa fa-times p-0" />
      </Button>
      <div className="card-body d-flex flex-column scrollable-tab pt-0 mt-n10">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="ml-3">
          <Tab
            eventKey="filters"
            title={<FormattedMessage id="MENU.DASHBOARD" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row>
                <Col className="mb-2 mt-2" md={8}>
                  <InputGroup>
                    <Search
                      onChange={onFilterChange}
                      value={filter}
                      placeholder={"GENERAL.FILTER"}
                    />
                    <OverlayTrigger
                      placement="bottom"
                      popperConfig={{
                        modifiers: {
                          preventOverflow: {
                            enabled: false,
                          },
                        },
                      }}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="DASHBOARD.FILTER_TOOLTIP" />
                        </Tooltip>
                      }
                    >
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <i className="fas fa-info-circle"> </i>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </OverlayTrigger>
                  </InputGroup>
                </Col>
                <Col className="mb-2 mt-2" md={4}>
                  <Button onClick={() => onFilterRows()}>
                    <FormattedMessage id="GENERAL.FILTER" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Form.Group as={Col} md={12} className="mb-0 pl-10">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom checkbox__croppings ${
                      showOnlyEcoCroppings ? "checkbox--active " : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showOnlyEcoCroppings}
                        onChange={() => onTogglingEcoCroppings()}
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_ONLY_ECO_CROPPINGS",
                    })}`}
                  />
                </Form.Group>
              </Row>
            </Container>
            {Array.isArray(croppings) && croppings.length > 0 && (
              <Container fluid className={"scrollable-container"}>
                <Row className="d-flex flex-column">
                  <div className="card__table">
                    <DashboardBPartnersTable
                      bPartners={bParners}
                      centerOnMap={centerOnMap}
                    />
                  </div>
                </Row>
              </Container>
            )}

            {filterApplied && Array.isArray(croppings) && !croppings.length && (
              <div className="text-center mt-3">
                <h6>
                  <FormattedMessage id="GENERAL.NO_RESULTS" />
                </h6>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
