import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-bootstrap";
import { SharedDataTable } from "./SharedDataTable";
import { HintText } from "./HintText";

export function SharedCroppingDataContainer({
  sharedData,
  acceptSharedData,
  rejectSharedData,
  requestExclusivitySharedData,
}) {
  return (
    <Row className="mb-7">
      <Col xs={12}>
        <h6 className="mb-3">
          <FormattedMessage id="GENERAL.SHARED_CROPPING_DATA" />:
        </h6>
      </Col>
      <Col xs={12}>
        {sharedData?.length > 0 ? (
          <SharedDataTable
            sharedData={sharedData}
            acceptSharedData={acceptSharedData}
            rejectSharedData={rejectSharedData}
            requestExclusivitySharedData={requestExclusivitySharedData}
          />
        ) : (
          <div className="text-center bg-light p-3 rounded">
            <h6 className="mb-0">
              <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
            </h6>
          </div>
        )}
        {sharedData?.length > 0 && <HintText label="TABLE_ROW_HINT" />}
      </Col>
    </Row>
  );
}
