import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import {
  DoYouKnow,
  // LoginHistory,
  OldestTreatmentsTimeline,
  AcceptOrCancelTreatmentModal,
} from "../modules/dashboard";
import { getInformations } from "../services/informationCrud";
import { getCroppingWithWeatherForecast } from "../services/croppingsCrud";
// import { getLoginHistory } from "../services/loginHistoryCrud";
import {
  getOldestEventsToLogin,
  cancelEvent,
  finishEvent,
} from "../services/eventsCrud";
import { Row, Col } from "react-bootstrap";
import CroppingsSearch from "../modules/dashboard/components/CroppingsSearch";
import CroppingsList from "../modules/dashboard/components/CroppingsList";
import Onboarding from "../modules/dashboard/components/Onboarding";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core"
import displayFeedback from "../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../components/helpers/displayFeedbackFromHeaders";

export function DashboardPage() {
  const intl = useIntl();
  const [croppings, setCroppings] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventId, setEventId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const showOnboardings = useSelector(
    (state) => state.auth.user.showOnboardings
  );
  const [croppingsLoading, setCroppingsLoading] = useState(false)

  // const [loginHistory, setLoginHistory] = useState([]);
  // const getSignInHistory = () => {
  //   getLoginHistory()
  //     .then((response) => {
  //       setLoginHistory(response.data);
  //     })
  //     .catch((error) => {
  //       const errorOptions = errorHandler(error);
  //       if (errorOptions.type === "error") {
  //         setFeedback({
  //           type: errorOptions.type,
  //           message: `${intl.formatMessage({ id: errorOptions.message })}`,
  //         });
  //         setOpen(true);
  //         setTimeout(() => {
  //           setOpen(false);
  //         }, 6000);
  //       }
  //     });
  // };
  // useEffectOnce(getSignInHistory);

  const getOldEvents = () => {
    getOldestEventsToLogin(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getSuggestions = () => {
    getInformations()
      .then((response) => {
        setSuggestions(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getCroppings = () => {
    setCroppingsLoading(true)
    getCroppingWithWeatherForecast(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setCroppings(response.data);
        setCroppingsLoading(false)
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
        setCroppingsLoading(false)
      });
  };

  const onOpeningModal = (eventId) => {
    setOpenModal(true);
    setEventId(eventId);
  };

  const onClosingModal = () => {
    setOpenModal(false);
    setEventId();
  };

  const onAcceptTreatment = () => {
    return new Promise((resolve) => {
      finishEvent(eventId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          setEventId(null);
          getOldEvents();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onCancelTreatment = () => {
    return new Promise((resolve) => {
      cancelEvent(eventId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          setEventId(null);
          getOldEvents();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  useEffectOnce(getOldEvents);
  useEffectOnce(getSuggestions);
  useEffectOnce(getCroppings);

  return (
    <Row>
      <AcceptOrCancelTreatmentModal
        open={openModal}
        close={onClosingModal}
        onAcceptTreatment={onAcceptTreatment}
        onCancelTreatment={onCancelTreatment}
      />
      <Col xs={12} md={8} className="mt-lg-n8">
        {!croppingsLoading && croppings.length > 0 && (
          <>
            <CroppingsSearch searchTerm={searchTerm} onChange={setSearchTerm} />
            <CroppingsList croppingsData={croppings} searchTerm={searchTerm} />
          </>
        )}
        {!croppingsLoading && croppings.length === 0 && (
          <Col xs={12}>
            {!showOnboardings && (
              <div className="card card-custom gutter-b mt-lg-8">
                <div className="card-body">
                  <div className="text-center">
                    <h6>
                      <FormattedMessage id="GENERAL.NO_CROPPINGS_WITH_SHAPE" />
                    </h6>
                  </div>
                </div>
              </div>
            )}
            {showOnboardings && <Onboarding />}
          </Col>
        )}
        {croppingsLoading && (
          <div className="d-flex justify-content-center mt-lg-8 mb-5">
            <CircularProgress />
          </div>
        )}
      </Col>
      <Col xs={12} md={4}>
        {/* <LoginHistory history={loginHistory} /> */}
        {suggestions.length > 0 && <DoYouKnow suggestions={suggestions} />}
        <OldestTreatmentsTimeline
          events={events}
          onOpeningModal={onOpeningModal}
        />
      </Col>
    </Row>
  );
}
