import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

function ForgotPassword(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let sentEmailRedirectTimeout;
    if (mailSent) {
      sentEmailRedirectTimeout = setTimeout(function() {
        props.history.push("/auth/login");
      }, 6500);
    }

    return () => {
      clearTimeout(sentEmailRedirectTimeout);
    };
    // eslint-disable-next-line
  }, [mailSent]);

  const onSubmit = (data) => {
    const loginWithoutWhiteSpaces = data.login.replace(/\s/g, "");
    data.login = loginWithoutWhiteSpaces;
    setLoading(true);
    resetPassword(data)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        setMailSent(true);
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  };

  return (
    <>
      {mailSent ? (
        <div className="auth__form">
          <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
            <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
            <SVG
              src={toAbsoluteUrl("/media/logos/brigadier__logo.svg")}
              style={{ height: "25px" }}
              className="mt-2"
            />
          </div>
          <div className="auth__heading">
            <h3>
              <FormattedMessage id="AUTH.REMIND_PASSWORD" />
            </h3>
          </div>
          <div
            className="text-center mb-10 mb-lg-10"
            style={{ padding: "0 40px" }}
          >
            <FormattedMessage id="AUTH.REMIND_MAIL_SENT" />.
          </div>
          <div>
            <Link
              to="/auth/login"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GO_TO_LOGIN" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="auth__form">
          <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
            <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
            <SVG
              src={toAbsoluteUrl("/media/logos/brigadier__logo.svg")}
              style={{ height: "25px" }}
              className="mt-2"
            />
          </div>
          <div className="auth__heading">
            <h3>
              <FormattedMessage id="AUTH.REMIND_PASSWORD" />
            </h3>
          </div>
          <form
            className="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group">
              <div>
                <input
                  name="login"
                  type="text"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.LOGIN",
                  })}`}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    formState.touched.login && errors.login ? "is-invalid" : ""
                  }${
                    formState.touched.login && !errors.login ? "is-valid" : ""
                  }`}
                  ref={register({
                    required: true,
                    minLength: 7,
                  })}
                />
                {errors.login?.type === "required" && (
                  <div className="error invalid-feedback">
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
                {errors.login?.type === "minLength" && (
                  <div className="error invalid-feedback">
                    <FormattedMessage id="ERROR.INCORRECT_LOGIN_LENGTH" />
                  </div>
                )}
              </div>
            </div>
            <div
              className="form-group text-center button_bar flex-wrap"
              style={{ marginBottom: "4px", justifyContent: "space-evenly" }}
            >
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={!formState.isValid || loading}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3 button_auth button--auth_background_primary`}
              >
                <span>
                  <FormattedMessage id="GENERAL.SUBMIT" />
                </span>
              </button>
              <Link
                to="/auth/login"
                className="button_link btn btn-secondary button_auth font-weight-bold px-9 py-4 my-3"
              >
                <FormattedMessage id="GENERAL.CANCEL" />
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
