import React, { useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

export function DeleteAccount({ onDeletingAccount }) {
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false)
  const formRef = useRef(null)

  async function onSubmit(data) {
    setLoading(true);
    const callback = await onDeletingAccount(data);
    if (callback === "OK") {
      setLoading(false);
      reset();
    } else {
      setLoading(false);
    }
  }

  const handleYesButtonClick = () => {
    setDialogOpen(false)
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title-delete-passwd"
      >
        <DialogTitle id="scroll-dialog-title-delete-passwd">
          <FormattedMessage id="AUTH.DO_YOU_REALLY_WANNA_DELETE_ACCOUNT" />?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="AUTH.ACCOUNT_DELETE_DESCRIPTION" />.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={loading}
            onClick={handleYesButtonClick}
          >
            <FormattedMessage id="GENERAL.YES" />
          </Button>
          <Button disabled={loading} onClick={() => setDialogOpen(false)}>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="PLACEHOLDER.ENTER_PASSWORD" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.ENTER_PASSWORD",
                  })}`}
                  className={`${
                    formState.touched.password && errors.password
                      ? "is-invalid"
                      : ""
                  }${
                    formState.touched.password && !errors.password
                      ? "is-valid"
                      : ""
                  }`}
                  ref={register({ required: true, minLength: 8 })}
                />
              </InputGroup>
              {errors.password?.type === "required" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.password?.type === "minLength" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.INCORRECT_PASSWORD_LENGTH" />
                </div>
              )}
            </Form.Group>
            <div className="col-xxl-12 text-right">
              <Button type="button" onClick={() => setDialogOpen(true)} disabled={loading || !formState.dirty || errors.password}>
                <FormattedMessage id="AUTH.DELETE_ACCOUNT" />
              </Button>
            </div>
          </Form.Row>
        </Form>
      </div>
    </>
  );
}
