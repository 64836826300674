import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FieldsTable } from "./FieldsTable";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export function FieldsShortcut({
  fields,
  onCenteringOnMap,
  showOnlyActiveFieldsAndCroppings,
}) {
  const filteredFields = useMemo(() => {
    if (fields.length > 0 && showOnlyActiveFieldsAndCroppings)
      return fields.filter((field) => field.isActive);
    else return fields;
  }, [fields, showOnlyActiveFieldsAndCroppings]);

  return (
    <>
      {fields.length > 0 && (
        <>
          <FieldsTable
            fields={filteredFields}
            onCenteringOnMap={onCenteringOnMap}
          />
        </>
      )}
      {fields.length === 0 && (
        <>
          <Col xs={12} className="text-center mt-5">
            <h6>
              <FormattedMessage id="GENERAL.NO_FIELDS" />
            </h6>
          </Col>
        </>
      )}
      <Col xs={12} className="mt-2 text-right">
        <Button type="button" variant="primary">
          <Link
            to={`/farm/fields`}
            target="_blank"
            className="button__link--primary"
          >
            <FormattedMessage id="GENERAL.ADD_FIELD" />
          </Link>
        </Button>
      </Col>
    </>
  );
}
