/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

export function TopWorkers({ className, data }) {
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
  .selectedLang;

  const displayQuantity = useCallback((value, uomSymbol) => {
    if (uomSymbol === "h") {
      const parsedDuration = moment.duration(value, "hours");
        return `${parsedDuration.hours()}:${parsedDuration.minutes()<10?"0":""}${parsedDuration.minutes()}h`
    }
    else return value.toLocaleString(languageCode)
  }, [languageCode])

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className ? className : ""}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div
                to="/brigadier/folders/workers"
                className="font-weight-bolder text-dark"
              >
                <FormattedMessage id="BRIGADIER.DASHBOARD.TOP_WORKERS_PER_UOM" />
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-8 pt-4">
          {/* begin::Chart */}
          {data?.length > 0 && (
            <Row className="mb-n4 mb-md-0">
              {data.map((row, rowIndex) => (
                <Col
                  md={3}
                  key={`brigadier-dashboard-topWorkers-uom${rowIndex}`}
                  className="mb-4 mb-md-0 px-5"
                >
                  <h6 className="mb-3">{row.uom.desc}</h6>
                  <ol className="pl-11 pl-md-10 dashboard-top-workers-list">
                    {row.employees.map((employee, employeeIndex) => (
                      <li
                        key={`brigadier-dashboard-topWorkers-uom${rowIndex}-employee${employeeIndex}`}
                      >
                        {employee.employee.firstName} {employee.employee.lastName} [{displayQuantity(employee.value, row.uom.symbol)}]
                      </li>
                    ))}
                  </ol>
                </Col>
              ))}
            </Row>
          )}
          {(!data || data?.length === 0) && (
            <div className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
              </h6>
            </div>
          )}
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
