import axios from "axios";

export const LICENCE_URL = `${process.env.REACT_APP_URL}gw/licences`;

export function getPinToAcceptPaidLicence(licenceId, form) {
  return axios.post(`${LICENCE_URL}/${licenceId}/paid/init`, form);
}

export function acceptPaidLicenceFinish(licenceId, form) {
  return axios.post(`${LICENCE_URL}/${licenceId}/paid/finish`, form);
}

export function getLicence(licenceId) {
  return axios.get(`${LICENCE_URL}/${licenceId}`);
};
