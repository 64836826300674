import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

export default function JoinToPulpExchangeModal(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function handleClose() {
    props.close();
  }

  async function onSubmit(data) {
    setLoading(true);
    if (!acceptTerms) {
      const callback = await props.onAcceptingPhoneNumber(data);
      if (callback === "OK") {
        setAcceptTerms(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      const form = data;
      form.address = {
        address1: props.applicationData.address1,
        address2:
          props.applicationData.address2 +
          (props.applicationData.apartamentNumber
            ? ` ${props.applicationData.houseNumber}/${props.applicationData.apartamentNumber}`
            : ` ${props.applicationData.houseNumber}`),
        countryId: props.applicationData.countryId,
        postalCode: props.applicationData.postalCode,
        postalCodeName: "",
      };
      if (props.applicationData.contacts) {
        form.contacts = props.applicationData.contacts;
      } else {
        form.contacts = [];
      }
      // usuń stary ten sam numer, jeżeli już istnieje
      form.contacts.push({
        desc: form.phone,
        isDefault: true,
        name: "main phone",
        type: "C",
      });
      form.contacts = form.contacts.filter(contact => {
        return contact.desc.length > 0;
      })
      form.orgentityId = props.applicationData.orgentityId;
      form.fullName = props.applicationData.fullName;
      form.shortName = props.applicationData.shortName;
      form.pesel = props.applicationData.pesel;
      form.vatin = props.applicationData.vatin;
      const callback = await props.onEnteringConfirmationCode(form);
      if (callback === "OK" || callback === "error") {
        setLoading(false);
        handleClose();
      }
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers={true}>
            <DialogContentText>
              <span className="modal__content_header">
                <FormattedMessage id="MENU.PRIVACY_POLICY" />
              </span>
              <span>
                <FormattedMessage id="GENERAL.OUR_PRIVACY_POLICY" />{" "}
                <a
                  href="https://www.suedzucker.pl/polityka-prywatnosci/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="GENERAL.THIS_ADDRESS" />
                </a>
              </span>
              <Form.Row className="mt-2">
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.MOBILE" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="phone"
                      type="tel"
                      className={`${
                        errors.phone?.type === "required" ||
                        (formState.touched.phone &&
                          errors.phone?.type === "pattern")
                          ? "is-invalid"
                          : ""
                      } ${
                        formState.touched.phone && !errors.phone
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder={`${intl.formatMessage({
                        id: "PLACEHOLDER.PHONE_NUMBER",
                      })}`}
                      step="1"
                      disabled={acceptTerms}
                      ref={register({
                        required: true,
                        pattern: /^\+?[0-9]{3}-?[0-9]{6,12}$/,
                      })}
                    />
                    {errors.phone?.type === "required" && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                      </div>
                    )}
                    {formState.touched.phone &&
                      errors.phone?.type === "pattern" && (
                        <div className="error invalid-feedback">
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                  </InputGroup>
                </Form.Group>
                {acceptTerms && (
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.VERIFICATION_CODE" /> *
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="pin"
                        type="text"
                        className={`${errors.pin ? "is-invalid" : ""} ${
                          formState.touched.pin && !errors.pin ? "is-valid" : ""
                        }`}
                        placeholder={`${intl.formatMessage({
                          id: "GENERAL.VERIFICATION_CODE",
                        })}`}
                        ref={register({
                          required: true,
                        })}
                      />
                      {errors.pin?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>
                )}
              </Form.Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit" disabled={loading}>
              {acceptTerms ? (
                <FormattedMessage id="GENERAL.ACCEPT" />
              ) : (
                <FormattedMessage id="GENERAL.CONFIRM" />
              )}
            </Button>
            <Button color="default" disabled={loading} onClick={handleClose}>
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
