import axios from "axios";

export const SETTINGS_URL = `${process.env.REACT_APP_URL}brigadier/parameters`;

export function getSettingsParameters() {
  return axios.get(`${SETTINGS_URL}/values`);
}

export function updateSettingsParameters(form) {
  return axios.put(`${SETTINGS_URL}/update`, form);
}
