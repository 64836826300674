import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { ReceivablesWorkersTable } from "./ReceivablesWorkersTable";
import { Button } from "react-bootstrap";

export function ReceivablesWorkersTableCard({
  workers,
  onSelectingRow,
  onOpeningModal,
}) {
  const [filter, setFilter] = useState("");

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {workers.length === 0 ? (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_WORKERS" />
            </h6>
          </div>
        ) : (
          <div className="card__table card__table--brigadier">
            <ReceivablesWorkersTable
              data={workers}
              onSelectingRow={onSelectingRow}
              filter={filter}
            />
          </div>
        )}
        <div className="text-right">
          <Button disabled={!workers.length} onClick={onOpeningModal}>
            <FormattedMessage id="GENERAL.CREATE_BULK_OPERATION" />
          </Button>
        </div>
      </div>
    </div>
  );
}
