const initialState = {
  showFilters: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "maps/setShowFilters": {
      return { ...state, showFilters: action.payload };
    }

    default:
      return state;
  }
};
