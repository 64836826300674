import * as React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import getModuleName from "../utils/getModuleName";

export default function PageTitle() {
  const location = useLocation();
  const intl = useIntl();

  const moduleName = React.useMemo(() => {
    const moduleName = getModuleName(location);
    const subAppName =
      moduleName.type !== "basic"
        ? intl.formatMessage({
            id: `MENU.${moduleName.type.replace("-", "_")}`,
            defaultMessage: " ",
          })
        : " ";
    const pageTitle = intl.formatMessage({
      id: `MENU.${moduleName.name.replace("-", "_")}`,
      defaultMessage: " ",
    });
    return `${subAppName !== " " ? `${subAppName} > ` : ""}${
      pageTitle !== " " ? `${pageTitle} - ` : ""
    }Cropchart`;
  }, [intl, location]);

  React.useEffect(() => {
    window.document.title = moduleName;
  }, [location.pathname, moduleName]);
  return <></>;
}
