import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import SockJsClient from "react-stomp";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DropdownTopbarItemToggler } from "../../_metronic/_partials/dropdowns";
import store from "../../redux/store";
import errorHandler from "../shared/errorHandler";
import {
  getAllAlertsToLogin,
  readAlertToLogin,
} from "../services/nofitifcationsCrud";
import Notification from "./Notification";
import { Badge } from "@material-ui/core";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import displayFeedback from "./helpers/displayFeedback";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotifications() {
  const intl = useIntl();
  const [notifications, setNotifications] = useState([]);
  const storeState = store.getState();

  const checkIfConnected = () => {
    getAllAlertsToLogin()
      .then((response) => {
        setNotifications(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onReceivingNotification = (message) => {
    const newNotifications = [...notifications];
    newNotifications.push(message);
    setNotifications(newNotifications);
    if (message.symbol === "BA") {
      displayFeedback({
        type: "success",
        message: `${intl.formatMessage({
          id: "PE.BUY_OFFER_ACCEPTED_NOTIFICATION",
        })} ${message.bpartner.bpartnerFullName}`,
      });
    } else if (message.symbol === "BR") {
      displayFeedback({
        type: "error",
        message: `${intl.formatMessage({
          id: "PE.BUY_OFFER_REJECTED_NOTIFICATION",
        })} ${message.bpartner.bpartnerFullName}`,
      });
    } else if (message.symbol === "SE") {
      displayFeedback({
        type: "black",
        message: `${intl.formatMessage({
          id: "PE.SELL_OFFER_EXPIRED_NOTIFICATION",
        })}`,
      });
    } else if (message.symbol === "SA") {
      displayFeedback({
        type: "info",
        message: `${intl.formatMessage({
          id: "PE.BUY_OFFER_RECEIVED_NOTIFICATION",
        })} ${message.bpartner.bpartnerFullName}`,
      });
    } else if (message.symbol === "BN") {
      displayFeedback({
        type: "warning",
        message: `${intl.formatMessage({
          id: "PE.BUY_OFFER_NEGOTIATION_NOTIFICATION",
        })} ${message.bpartner.bpartnerFullName}`,
      });
    } else if (message.symbol === "SF") {
      displayFeedback({
        type: "success",
        message: `${intl.formatMessage({
          id: "PE.SALE_OFFER_FINSHED_NOTIFICATION",
        })}`,
      });
    } else if (message.symbol === "ES") {
      let moduleName;
      const days =
        moment(message.subscription.dateTo).diff(message.timeArised, "days") +
        1;
      if (message.subscription.applicationSymbol === "BRIGADIER") {
        moduleName = "Brygadier";
      } else if (message.subscription.applicationSymbol === "CROPMANAGER") {
        moduleName = "Cropchart Manager";
      }
      displayFeedback({
        type: "info",
        message: `${intl.formatMessage({
          id: "NOTIFICATION.SUBSCRIPTION_TO_MODULE",
        })} ${moduleName}, ${intl.formatMessage({
          id: "NOTIFICATION.ENDS_IN",
        })}: ${days} ${intl.formatMessage({
          id: "GENERAL.DAYS",
        })}`,
      });
    }
  };

  const handleClick = (notification) => {
    readAlertToLogin(notification.alertAddressedToId)
      .then(() => {
        displayFeedback({
          type: "info",
          message: `${intl.formatMessage({
            id: "GENERAL.NOTIFICATION_MARKED_AS_READ",
          })}`,
        });
        checkIfConnected();
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  return (
    <>
      {!process.env.REACT_APP_DISABLE_WEBSOCKETS && <SockJsClient
        url={`${process.env.REACT_APP_WS_URL}`}
        topics={[`/responseQueue/notification/${storeState.auth.user.id}`]}
        onConnect={() => checkIfConnected()}
        onMessage={(msg) => onReceivingNotification(msg)}
      />}
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="kt_quick_notifications_toggle"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="logout-tooltip">
                <FormattedMessage id="GENERAL.USER_NOTIFICATIONS" />
              </Tooltip>
            }
          >
            <div className="topbar-item">
              <div
                className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mx-1"
                id="kt_quick_notifications_toggle"
              >
                <>
                  {notifications.length > 0 && (
                    <Badge
                      color="secondary"
                      badgeContent={notifications.length}
                    >
                      <i className="far fa-bell"></i>
                    </Badge>
                  )}
                  {notifications.length === 0 && (
                    <i className="far fa-bell"></i>
                  )}
                </>
              </div>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          {notifications.length > 0 && (
            <div className="p-8">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="notifications__scroll scroll pr-7 mr-n-7"
              >
                {notifications.map((notification) => (
                  <Notification
                    key={notification.alertAddressedToId}
                    notification={notification}
                    handleClick={handleClick}
                  />
                ))}
              </PerfectScrollbar>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
