import { useEffect, useState } from "react";
import store from "../../redux/store";

function usePulpExchangePermission(permission) {
  const [permissionExist, setPermissionExist] = useState(false);
  const storeState = store.getState();

  useEffect(() => {
    const userPermissions = storeState.ui?.userData?.permissions2Orgentity.filter(
      (perm) => {
        return perm === permission;
      }
    );
    if (userPermissions) {
      if (userPermissions[0]) {
        setPermissionExist(true);
      }
    }
  }, [storeState.ui?.userData?.permissions2Orgentity]);

  return permissionExist;
}

export default usePulpExchangePermission;
