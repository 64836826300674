import moment from "moment";
import store from "../../../../redux/store";
import displayFeedback from "../../../components/helpers/displayFeedback";
import getIntl from "../../../components/helpers/getIntl";
import { getEventsToLoginBetweenDates } from "../../../services/eventsCrud";
import errorHandler from "../../../shared/errorHandler";

export default function getEventsToRedux() {
  const intl = getIntl();
  const treatmentTypes = store.getState().calendar.treatmentTypes;
  const eventStatuses = store.getState().calendar.eventStatuses;
  const dates = store.getState().calendar.dates;
  if (treatmentTypes?.length > 0 && eventStatuses?.length > 0 && dates) {
    store.dispatch({
      type: "calendar/setEventsLoading",
      payload: true,
    });
    const treatmentTypeIds = treatmentTypes
      .filter((type) => type.checked)
      .map((type) => type.treatmentTypeId);
    const eventStatusSymbols = eventStatuses
      .filter((type) => type.checked)
      .map((type) => type.symbol);
    getEventsToLoginBetweenDates(
      localStorage.getItem("activeFarmId"),
      dates.startStr.slice(0, 10),
      dates.endStr.slice(0, 10),
      treatmentTypeIds,
      eventStatusSymbols
    )
      .then((response) => {
        if (response.data.length) {
          let newEvents = [];
          response.data.forEach((event) => {
            let color;
            let eventTitle = "";
            event.treatments.forEach((treatment) => {
              eventTitle += `${intl.formatMessage({
                id: `TREATMENT.${treatment.treatmentTypeDesc}`,
              })} | `;
            });
            const slicedEventTitle = eventTitle.slice(0, -3);
            if (event.treatments[0].treatmentTypeDesc === "Uprawa gleby") {
              color = "#a55858";
            } else if (event.treatments[0].treatmentTypeDesc === "Nawożenie") {
              color = "#ba0000";
            } else if (event.treatments[0].treatmentTypeDesc === "Sadzenie") {
              color = "#abcd83";
            } else if (
              event.treatments[0].treatmentTypeDesc === "Nawadnianie"
            ) {
              color = "#b1d8ff";
            }
            newEvents.push({
              title: slicedEventTitle,
              start: moment(event.eventDateFrom).format("YYYY-MM-DD"),
              end: moment(
                moment(moment(event.eventDateTo).format("YYYY-MM-DD")).add(
                  1,
                  "day"
                )
              ).format("YYYY-MM-DD"),
              classNames: ["event__treatment"],
              backgroundColor: color,
              borderColor: color,
              eventId: event.eventId,
              croppingShapeId: event?.croppingShapeIds[0],
              treatmentShapeId: event.shapeId,
              treatmentHeaderId: event.treatmentHeaderId,
            });
          });
          store.dispatch({
            type: "calendar/setEvents",
            payload: newEvents,
          });
        } else {
          store.dispatch({
            type: "calendar/setEvents",
            payload: [],
          });
        }
        store.dispatch({
          type: "calendar/setEventsLoading",
          payload: false,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
        store.dispatch({
          type: "calendar/setEventsLoading",
          payload: false,
        });
      });
  }
}
