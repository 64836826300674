import axios from "axios";

export const RESULTS_URL = `${process.env.REACT_APP_URL}brigadier/results`;

export function getResultsFormData() {
  return axios.get(`${RESULTS_URL}/attribute`);
}

export function getAllResults(form) {
  return axios.post(`${RESULTS_URL}/employee/all`, form);
}

export function createResultToEmployee(employeeId, form) {
  return axios.post(`${RESULTS_URL}/employee/${employeeId}`, form);
}

export function updateResults(form) {
  return axios.put(`${RESULTS_URL}/update`, form);
}

export function deactivateResult(resultsIds) {
  return axios.put(`${RESULTS_URL}/delete`, resultsIds);
}

export function getResultToEmployeesExcel(form) {
  return axios.post(`${RESULTS_URL}/employee/all/excel`, form, {
    responseType: "blob",
  });
}
