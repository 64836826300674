import * as React from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Button from "react-bootstrap/Button";

export function ModuleOffer({ offer }) {
  return (
    <div className="pt-3 pt-md-1 pb-3 px-5">
      <div className="d-flex flex-center position-relative mb-1">
        <img
          alt="brigadier logo"
          src={toAbsoluteUrl(`/media/agrico-logos/${offer.icon}.png`)}
          width="250px"
          height="250px"
        />
      </div>
      <span className="font-size-h1 d-block font-weight-boldest text-dark-75 py-2">
        {offer.price} PLN
      </span>
      <h4 className="font-size-h6 d-block font-weight-bold mb-7 text-dark-50">
        {offer.name}
      </h4>
      <p className="module__description mb-15 d-flex flex-column">
        {offer.description}
      </p>
      <div className="d-flex justify-content-center">
        {/* {offer.link} */}
        <Button
          type="button"
          variant="secondary"
          className="btn text-uppercase button--brigadier"
        >
          <FormattedMessage id="GENERAL.PURCHASE" />
        </Button>
      </div>
    </div>
  );
}
