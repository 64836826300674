import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import useEffectOnce from "../../../hooks/useEffectOnce";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TermsModal from "../modals/TermsModal";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { signUp } from "../_redux/authCrud";
import { getLanguages } from "../../../services/languageCrud";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import InfoTooltip from "../components/InfoTooltip";
import { getSelectedLang } from "../../../../_metronic/i18n/getSelectedLang";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

function Registration(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    formState,
    getValues,
    setValue,
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });
  const [languages, setLanguages] = useState([]);

  const getAllLanguages = () => {
    setLoading(true);
    getLanguages()
      .then((response) => {
        setLanguages(response.data);
        setTimeout(() => {
          setValue(
            "languageId",
            response.data.find(
              (lang) => lang.code === getSelectedLang().selectedLang
            ).id
          );
          triggerValidation();
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  };

  const submitData = (data) => {
    const loginWithoutWhiteSpaces = data.login.replace(/\s/g, "");
    data.passwordConfirmation = null;
    data.login = loginWithoutWhiteSpaces;
    data.privacyPolicyAccepted = acceptTerms;

    setLoading(true);

    signUp(data)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        setTimeout(() => {
          props.history.push("/auth/login");
        }, 6500);
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  };

  const handleChange = () => setAcceptTerms(!acceptTerms);

  const handleClick = () => setOpenModal(!openModal);

  useEffectOnce(getAllLanguages);

  return (
    <div className="auth__form">
      <TermsModal open={openModal} close={handleClick} acceptVisible={false} />
      <div className="auth__welcoming">
        <h1>
          <FormattedMessage id="AUTH.WELCOME" />!
        </h1>
      </div>
      <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
        <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
        <SVG
          src={toAbsoluteUrl("/media/logos/brigadier__logo.svg")}
          style={{ height: "25px" }}
          className="mt-2"
        />
        <div className="d-flex mt-4 align-items-center">
          <p className="m-0">
            <FormattedMessage id="AUTH.SIGN_UP_MULTI-ACCOUNT" />
          </p>
          <InfoTooltip />
        </div>
      </div>
      <form
        className="form"
        autoComplete="off"
        onSubmit={handleSubmit(submitData)}
      >
        <div className="form-group">
          <div>
            <input
              name="login"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.LOGIN",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.login && errors.login ? "is-invalid" : ""
              }${formState.touched.login && !errors.login ? "is-valid" : ""}`}
              ref={register({
                required: true,
                minLength: 7,
              })}
            />
            {errors.login?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.login?.type === "minLength" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_LOGIN_LENGTH" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div>
            <input
              name="email"
              type="email"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.ENTER_EMAIL",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.email && errors.email ? "is-invalid" : ""
              }${formState.touched.email && !errors.email ? "is-valid" : ""}`}
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                },
              })}
            />
            {errors.email?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.email?.type === "pattern" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_EMAIL" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div>
            <input
              name="password"
              type="password"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.ENTER_PASSWORD",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.password && errors.password
                  ? "is-invalid"
                  : ""
              }${
                formState.touched.password && !errors.password ? "is-valid" : ""
              }`}
              ref={register({
                required: true,
                minLength: 8,
                validate: (value) => {
                  if (
                    (value === getValues()["passwordConfirmation"] &&
                      value.length >= 8) ||
                    (getValues()["passwordConfirmation"].length &&
                      value.length >= 8)
                  ) {
                    triggerValidation("passwordConfirmation");
                    return true;
                  } else if (value.length >= 8) {
                    return true;
                  }
                },
              })}
            />
            {errors.password?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.password?.type === "minLength" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_PASSWORD_LENGTH" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div>
            <input
              name="passwordConfirmation"
              type="password"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.RE_ENTER_YOUR_PASSWORD",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.passwordConfirmation &&
                errors.passwordConfirmation
                  ? "is-invalid"
                  : ""
              }${
                formState.touched.passwordConfirmation &&
                !errors.passwordConfirmation
                  ? "is-valid"
                  : ""
              }`}
              ref={register({
                required: true,
                validate: (value) => {
                  return value === getValues()["password"];
                },
              })}
            />
            {errors.passwordConfirmation?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.passwordConfirmation?.type === "validate" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_PASSWORDS" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div>
            <label style={{ width: "100%" }}>
              <FormattedMessage id="GENERAL.LANGUAGE" />
            </label>
            <select
              name="languageId"
              className="form-control form-control-solid h-auto py-5 px-6"
              ref={register({ required: true })}
            >
              {languages && (
                <>
                  {languages.map((language) => (
                    <option key={language.id} value={language.id}>
                      {language.code}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>
        <div
          className="form-group auth_links flex-wrap"
          style={{ justifyContent: "start", alignItems: "center" }}
        >
          <FormControlLabel
            className={`checkbox__primary ${acceptTerms ? "checkbox--active " : ""}`}
            style={{ marginRight: "0.35rem" }}
            control={
              <Checkbox
                checked={acceptTerms}
                onChange={() => handleChange()}
                value={acceptTerms}
              />
            }
            label={`${intl.formatMessage({
              id: "PLACEHOLDER.I_AGREE_THE",
            })}`}
          />
          <button
            type="button"
            className="text-hover-primary"
            style={{
              border: "none",
              background: "none",
              marginBottom: "6px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "#464E5f",
              paddingLeft: "0",
              fontWeight: "600",
            }}
            onClick={() => handleClick()}
          >
            <FormattedMessage id="GENERAL.TERMS_OF_SERVICE" />
          </button>
          <span style={{ paddingBottom: "0.5rem", marginRight: "0.35rem" }}>
            <FormattedMessage id="GENERAL.AND" />
          </span>
          <button
            type="button"
            className="text-hover-primary"
            style={{
              border: "none",
              background: "none",
              marginBottom: "6px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "#464E5f",
              paddingLeft: "0",
              fontWeight: "600",
            }}
            onClick={() => handleClick()}
          >
            <FormattedMessage id="GENERAL.PRIVACY_POLICY" />.
          </button>
        </div>
        {/*<div*/}
        {/*  className="form-group auth_links flex-wrap"*/}
        {/*  style={{justifyContent: "start", alignItems: "center"}}*/}
        {/*>*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="text-hover-primary"*/}
        {/*    style={{*/}
        {/*      border: "none",*/}
        {/*      background: "none",*/}
        {/*      marginBottom: "6px",*/}
        {/*      display: "flex",*/}
        {/*      flexDirection: "column",*/}
        {/*      justifyContent: "center",*/}
        {/*      color: "#464E5f",*/}
        {/*      paddingLeft: "0",*/}
        {/*      fontWeight: "600",*/}
        {/*    }}*/}
        {/*    disabled={loading}*/}
        {/*    onClick={() => openOrCloseActiveModulesModal()}*/}
        {/*  >*/}
        {/*    <FormattedMessage id="GENERAL.CLICK_TO_CHANGE_APPLICATION_MODULES"/>*/}
        {/*    .*/}
        {/*  </button>*/}
        {/*</div>*/}
        <div
          className="form-group text-center button_bar flex-wrap"
          style={{ marginBottom: "4px", justifyContent: "space-evenly" }}
        >
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formState.isValid || loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 button_auth button--auth_background_primary`}
          >
            <span>
              <FormattedMessage id="GENERAL.SUBMIT" />
            </span>
          </button>
          <Link
            to="/auth/login"
            className="button_link btn btn-secondary button_auth font-weight-bold px-9 py-4 my-3"
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </Link>
        </div>
      </form>
    </div>
  );
}

export default connect(null, auth.actions)(Registration);
