import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";

export function SignatureModal({ open, close, signature }) {
  const [imgUrl, setImgUrl] = useState(null);

  const showSignature = () => {
    if (signature) {
      setImgUrl(signature);
    }
  };

  useEffect(showSignature, [signature]);

  return (
    <Dialog
      open={open}
      onClose={() => close(false)}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.SIGNATURE" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="text-center">
          <img
            src={imgUrl}
            height={isMobile ? 100 : 200}
            width={isMobile ? 200 : 350}
            alt="Worker signature"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => close(false)}>
          <FormattedMessage id="GENERAL.CLOSE" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
