import React from "react";
import { FormattedMessage } from "react-intl";
import { AgronomistsTableRow } from "./AgronomistsTableRow";
import { Table } from "react-bootstrap";

export const AgronomistsTable = ({
  agronomists,
  onDeletingAgronomist,
}) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.LOGIN" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ROLE" />
          </th>
          <th style={{width: "20%"}}>
            <FormattedMessage id="ROLE.BRIGADIER_EXTEND" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {agronomists.map((agronomist) => (
          <AgronomistsTableRow
            key={`agronomist-${agronomist.invitationId}`}
            agronomist={agronomist}
            onDeletingAgronomist={onDeletingAgronomist}
          />
        ))}
      </tbody>
    </Table>
  );
};
