import React, { Suspense, useEffect } from "react";
// lazy
import { Redirect, Switch } from "react-router-dom";
// Route
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { CalendarPage } from "./pages/CalendarPage";
import { MapsPage } from "./pages/MapsPage";
import { FieldsPage } from "./pages/FieldsPage";
import { CroppingsPage } from "./pages/CroppingsPage";
import { ContractorsPage } from "./pages/ContractorsPage";
import { CertificatesPage } from "./pages/CertificatesPage";
import { FoldersPage } from "./pages/FoldersPage";
import { ReportsPage } from "./pages/ReportsPage";
import { UserPage } from "./pages/UserPage";
import { ModulesPage } from "./pages/ModulesPage";
import { PulpExchangePage } from "./modules/pulp-exchange/";
import { BrigadierPage } from "./modules/brigadier/";
import { CropManagerPage } from "./modules/crop-manager/";

import TutorialModal from "./modules/tutorials/modals/TutorialModal";
import SubscriptionThankYouPage from "./pages/SubscriptionThankYouPage";
import { useDispatch } from "react-redux";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );

export default function BasePage() {
  const dispatch = useDispatch();

  const setIsMobile = (value) => {
    dispatch({
      type: "misc/setIsMobile",
      payload: value,
    });
  };

  useEffect(() => {
    handleResize();

    function handleResize() {
      if (window.innerWidth < 992) setIsMobile(true);
      else setIsMobile(false);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <TutorialModal />
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/calendar" component={CalendarPage} />
        <ContentRoute path="/maps" component={MapsPage} />
        <ContentRoute path="/farm/fields" component={FieldsPage} />
        <ContentRoute path="/farm/croppings" component={CroppingsPage} />
        <ContentRoute path="/contractors" component={ContractorsPage} />
        <ContentRoute path="/certificates" component={CertificatesPage} />
        <ContentRoute path="/folders" component={FoldersPage} />
        <ContentRoute path="/reports" component={ReportsPage} />
        <ContentRoute path="/user" component={UserPage} />
        <ContentRoute path="/modules" component={ModulesPage} />

        <ContentRoute path="/pulp-exchange" component={PulpExchangePage} />
        <ContentRoute path="/brigadier" component={BrigadierPage} />
        <ContentRoute path="/crop-manager" component={CropManagerPage} />
        <ContentRoute
          path="/subscription/thanks"
          component={SubscriptionThankYouPage}
        />

        {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/>
                <Redirect to="error/error-v1"/> */}
      </Switch>
    </Suspense>
  );
}
