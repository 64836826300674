import React, { useMemo } from "react";
import moment from "moment";

export function ResultRow({ result, index, onSelectingRow }) {
  const displayedQuantity = useMemo(() => {
    if (result.__tarifTypeValue === "HOURLY") return `${result.timeWorked}h`
    else return result.quantity
  }, [result.__tarifTypeValue, result.quantity, result.timeWorked])

  return (
    <tr
      className={`text-center cursor--pointer ${
        result.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => onSelectingRow(result.__resultId)}
    >
      <td>{index + 1}</td>
      <td>{result.firstName}</td>
      <td>{result.lastName}</td>
      <td>{result.brigadierFirstName}</td>
      <td>{result.brigadierLastName}</td>
      <td>{result.brigadeName}</td>
      <td>
        {result.__tarifTypeValue === "HOURLY"
          ? `${moment(result.dateTo).format("YYYY-MM-DD HH:mm")}`
          : `${moment(result.createdAt).format("YYYY-MM-DD HH:mm")}`}
      </td>
      <td>{result.croppingShortName}</td>
      <td>{result.varietyName}</td>
      <td>{displayedQuantity}</td>
      <td>{result.uomSymbol}</td>
      <td>{result.rate}</td>
      <td>{result.value.toFixed(2)}</td>
      <td>{result.currencySymbol}</td>
      <td>{result.packagingName}</td>
      <td>{result.tariffName}</td>
      <td>{result.resultDescription}</td>
    </tr>
  );
}
