import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import errorHandler from "../../../shared/errorHandler";
import { Row, Col } from "react-bootstrap";
import { getDashboardData } from "../../../services/brigadier/dashboardCrud";
import { StatsByVariety } from "../components/dashboard/StatsByVariety";
import { WorkerCount } from "../components/dashboard/WorkerCount";
import { LatestResults } from "../components/dashboard/LatestResults";
import { TopWorkers } from "../components/dashboard/TopWorkers";
import { ResultsToCropping } from "../components/dashboard/ResultsToCropping";
import { ResultsToTariff } from "../components/dashboard/ResultsToTariff";
import displayFeedback from "../../../components/helpers/displayFeedback";
import BrigadierCircularProgress from "../components/BrigadierCircularProgress";

export function DashboardPage() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [loadingProcessRun, setLoadingProcessRun] = useState(false);
  const [lastResultDate, setLastResultDate] = useState();
  const [dashboardData, setDashboardData] = useState();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const getDashboardDataToState = useCallback(
    (date) => {
      setLoading(true);
      setLoadingProcessRun(true);
      const todayDate = date ? date : new Date().toISOString().slice(0, 10);
      getDashboardData(todayDate)
        .then((response) => {
          if (response.data.lastResultRegisterAt) {
            const dataDate = new Date(response.data.lastResultRegisterAt)
              .toISOString()
              .slice(0, 10);
            if (dataDate >= todayDate) {
              if (!date) setLastResultDate(null);
              setDashboardData(response.data);
              setLoading(false);
            } else {
              setLastResultDate(dataDate);
              getDashboardDataToState(dataDate);
            }
          } else {
            setDashboardData(response.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          setLoading(false);
        });
    },
    [intl]
  );

  useEffect(() => {
    getDashboardDataToState();
  }, [getDashboardDataToState]);

  return (
    <Row>
      {!loading && dashboardData && (
        <Col xs={12}>
          {lastResultDate && languageCode && (
            <div className="bg-info p-4 rounded mb-6 shadow">
              <p className="m-0 text-white text-center">
                <FormattedMessage id="BRIGADIER.DASHBOARD.NO_DATA_TO_DISPLAY_TODAY" />{" "}
                <strong>
                  {new Date(lastResultDate).toLocaleDateString(
                    languageCode === "en" ? "en-GB" : languageCode
                  )}
                </strong>{" "}
                👇
              </p>
            </div>
          )}
          <Row className="mb-8">
            <Col xs={12}>
              <StatsByVariety data={dashboardData?.resultToVariety} />
            </Col>
          </Row>
          <Row className="mb-8">
            <Col xs={12}>
              <LatestResults data={dashboardData?.lastResults} />
            </Col>
          </Row>
          <Row className="mb-8">
            <Col xl={3} className="col-xxl-2">
              <Row>
                <Col xs={6} xl={12} className="mb-3">
                  <WorkerCount data={dashboardData?.employees} />
                </Col>
                <Col xs={6} xl={12}>
                  <WorkerCount data={dashboardData?.overseers} isOverseer />
                </Col>
              </Row>
            </Col>
            <Col xl={9} className="col-xxl-10">
              <TopWorkers data={dashboardData?.resultToUom} />
            </Col>
          </Row>
          <Row>
            <Col xl={6} className="mb-8 mb-xl-0">
              <ResultsToCropping data={dashboardData?.resultToCropping} />
            </Col>
            <Col xl={6}>
              <ResultsToTariff data={dashboardData?.resultToTariff} />
            </Col>
          </Row>
        </Col>
      )}
      {!loading && !dashboardData && loadingProcessRun && (
        <Col xs={12}>
        <div className="bg-white rounded-lg shadow-sm p-10 mx-auto">
          <div className="symbol symbol-100 symbol-danger d-flex justify-content-center">
            <span className="symbol-label font-weight-bold rounded-circle">
              <i
                className="fa fa-exclamation"
                style={{ color: "inherit", fontSize: "3rem" }}
              />
            </span>
          </div>
          <h1 className="text-center mt-6">
            <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
          </h1>
        </div>
        </Col>
      )}
      {(loading || !loadingProcessRun) && (
        <Col xs={12}>
          <div className="d-flex justify-content-center mt-lg-8">
            <BrigadierCircularProgress />
          </div>
        </Col>
      )}
    </Row>
  );
}

export default DashboardPage;
