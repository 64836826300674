import React from "react";
import { FormattedMessage } from "react-intl";

export function ReportTypeRow({ reportType, onSelectingRow }) {
  return (
    <tr
      className={`text-center cursor--pointer ${
        reportType.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => onSelectingRow(reportType.id)}
    >
      <td>
        <FormattedMessage id={reportType.name} />
      </td>
      <td> 
         <FormattedMessage id={reportType.desc} />
      </td>
      <td>
        {!reportType.applicationSymbol && <>Cropchart</>}
        {reportType.applicationSymbol === "BRIGADIER" && <>Brigadier</>}
        {reportType.applicationSymbol === "CROPMANAGER" && <>Cropchart Manager</>}
      </td>
    </tr>
  );
}
