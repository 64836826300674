import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";

export function ExportResultsModal({ open, close, onExportingResults }) {
  const [loading, setLoading] = useState(false);

  async function exportResults() {
    setLoading(true);
    const callback = await onExportingResults();
    callback === "OK" ? onClosingModal() : setLoading(false);
  }

  const onClosingModal = () => {
    setLoading(false);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="BRIGADIER.EXPORT_RESULTS" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="BRIGADIER.EXPORT_RESULTS_MESSAGE" />.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          onClick={() => exportResults()}
          disabled={loading}
        >
          <FormattedMessage id="GENERAL.ACCEPT" />
        </Button>
        <Button
          color="default"
          disabled={loading}
          onClick={() => onClosingModal()}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
