import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { TariffBonusForm } from "./TariffBonusForm";

export function TariffBonusCard({
  formData,
  tariffs,
  tariffLevelGroups,
  onCreatingTariffLevelGroup,
  onDeletingTariffLevelGroup,
  onUpdatingTariffLevelGroup,
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon svg-icon-dark-75 menu-icon mr-2">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Navigation/Angle-double-up.svg"
              )}
            />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="BRIGADIER.BONUS_THRESHOLDS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <TariffBonusForm
          formData={formData}
          tariffs={tariffs}
          tariffLevelGroups={tariffLevelGroups}
          onCreatingTariffLevelGroup={onCreatingTariffLevelGroup}
          onDeletingTariffLevelGroup={onDeletingTariffLevelGroup}
          onUpdatingTariffLevelGroup={onUpdatingTariffLevelGroup}
        />
      </div>
    </div>
  );
}
