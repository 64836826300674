import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  View,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import robotoLightFont from "../../../../fonts/Roboto-Light.ttf";
import robotoMediumFont from "../../../../fonts/Roboto-Medium.ttf";
import { Row, Col, Button } from "react-bootstrap";
import QRCode from "qrcode";

Font.register({ family: "Roboto Light", src: robotoLightFont });
Font.register({ family: "Roboto Medium", src: robotoMediumFont });

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 35,
    fontFamily: "Roboto Light",
  },
  wrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  label: {
    width: 250,
    height: "20vh",
    border: "1px solid #000",
  },
  label__box: {
    marginTop: 15,
    marginBottom: 15,
    alignItems: "center",
  },
  label__packaging_orgentity: {
    fontSize: 12,
    marginBottom: 10,
  },
  label__packaging: {
    fontSize: 16,
    fontFamily: "Roboto Medium"
  },
  label__qrItem: {
    fontSize: 16,
  },
  barcode: {
    width: 200,
    height: 80,
    marginTop: 15,
  },
  qrCode: {
    width: 150,
    height: 150,
    marginTop: 7,
    objectFit: "contain",
  },
});

const BarcodesDocument = ({ barcodes, activePackaging, isQrCodePDF }) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.wrapper}>
        {barcodes.map((bar, index) => (
          <View key={`barcode-${index}`} style={styles.label}>
            <View style={styles.label__box}>
              <Text style={styles.label__packaging_orgentity}>
                {activePackaging.orgentity && (
                  <>
                    {activePackaging.orgentity.bpartner !== null &&
                    activePackaging.orgentity?.bpartner?.bpartnerFullName
                      ?.length
                      ? activePackaging.orgentity.bpartner.bpartnerFullName
                      : activePackaging.orgentity.orgentityDesc}
                  </>
                )}
              </Text>
              <Text style={styles.label__packaging}>
                {activePackaging.name}
              </Text>
              <Image
                style={isQrCodePDF ? styles.qrCode : styles.barcode}
                src={bar.barcode}
              />
              {isQrCodePDF && (
                <Text style={styles.label__qrItem}>
                  {bar.item}
                </Text>
              )}
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export function BarcodesPDF({ activePackaging, barcodesLabels, isQrCodePDF }) {
  const [barcodes, setBarcodes] = useState([]);

  useEffect(() => {
    const newBarcodes = [];
    barcodesLabels.forEach((label) => {
      let canvas = document.createElement("canvas");
      if (isQrCodePDF) {
        QRCode.toCanvas(canvas, label.item, function(error) {
          if (error) console.error(error);
        });
      } else JsBarcode(canvas, label.item);
      const barcode = canvas.toDataURL();
      newBarcodes.push({ barcode, item: label.item });
    });
    setBarcodes(newBarcodes);
  }, [barcodesLabels, isQrCodePDF]);

  return (
    <>
      {isMobile ? (
        <Col
          md={12}
          lg={12}
          xxl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center mt-5">
            <PDFDownloadLink
              document={
                <BarcodesDocument
                  barcodes={barcodes}
                  activePackaging={activePackaging}
                  isQrCodePDF={isQrCodePDF}
                />
              }
              fileName={`barcodes-${moment().format("DD.MM.YYYY_HH:mm")}.pdf`}
            >
              <Button>
                <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF" />
              </Button>
            </PDFDownloadLink>
          </div>
        </Col>
      ) : (
        <>
          <Row>
            <Col md={12} lg={12} xxl={12} style={{ minHeight: "50vh" }}>
              <PDFViewer style={{ width: "100%", height: "100%" }}>
                <BarcodesDocument
                  barcodes={barcodes}
                  activePackaging={activePackaging}
                  isQrCodePDF={isQrCodePDF}
                />
              </PDFViewer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
