import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { validateUserToken } from "../_redux/authCrud";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

function ActivateAccount(props) {
  const intl = useIntl();
  const [tokenStatus, setTokenStatus] = useState();

  const onEnteringPage = () => {
    let redirectTimeout;
    const params = new URLSearchParams(props.location.search);
    const userId = params.get("id");
    const activationToken = params.get("key");
    validateUserToken(activationToken, userId)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        setTokenStatus("active");
        redirectTimeout = setTimeout(function() {
          props.history.push("/auth/login");
        }, 6500);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setTokenStatus(
            error.response?.data.message === "response.USER_ALREADY_VERIFIED"
              ? "active"
              : "error"
          );
          redirectTimeout = setTimeout(function() {
            props.history.push("/auth/login");
          }, 6500);
        }
      });

    return () => {
      clearTimeout(redirectTimeout);
    };
  };

  useEffectOnce(onEnteringPage);

  return (
    <div className="auth__form">
      <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
        <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
      </div>
      <div className="auth__heading">
        <h3>
          <FormattedMessage id="AUTH.SIGN_IN" />
        </h3>
      </div>
      <div className="text-center mb-10 mb-lg-10" style={{ padding: "0 40px" }}>
        {tokenStatus === "activation" && (
          <FormattedMessage id="AUTH.ACCOUNT_ACTIVATED" />
        )}
        {tokenStatus === "active" && (
          <FormattedMessage id="AUTH.ACCOUNT_ACTIVE" />
        )}
        {tokenStatus === "error" && (
          <FormattedMessage id="AUTH.ACCOUNT_ERROR_OR_TOKEN_NOT_VALID" />
        )}
        .
      </div>
      <div>
        <Link
          to="/auth/login"
          className="text-dark-50 text-hover-primary my-3 mr-2"
          id="kt_login_forgot"
        >
          <FormattedMessage id="AUTH.GO_TO_LOGIN" />
        </Link>
      </div>
    </div>
  );
}

export default ActivateAccount;
