import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "react-bootstrap/Table";

export function ShareCroppingsTable({
  croppings,
  activeBusinessPartner,
  changeSpatialDataAllowed,
  addSpatialDataAllowed,
  changeTreatmentDataAllowed,
  addTreatmentDataAllowed,
}) {
  const { control, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [croppingsCopy, setCroppingsCopy] = useState([]);

  useEffect(() => {
    if (activeBusinessPartner) {
      changeCheckboxesValuesToDefault();
    }
  }, [activeBusinessPartner]);

  const changeCheckboxesValuesToDefault = () => {
    const newCroppingsCopy = [...croppings];
    newCroppingsCopy.forEach((cropping) => {
      cropping.isSpatialDataAllowed = false;
      cropping.isTreatmentDataAllowed = false;
    });
    changeCheckboxesValuesToStart(newCroppingsCopy);
  };

  const changeCheckboxesValuesToStart = (newCroppingsCopy) => {
    activeBusinessPartner.croppings.forEach((cropping) => {
      const searchedCropping = newCroppingsCopy.find((crop) => {
        return crop.croppingId === cropping.croppingId;
      });
      if (searchedCropping) {
        searchedCropping.isSpatialDataAllowed = cropping.isSpatialDataAllowed;
        searchedCropping.isTreatmentDataAllowed =
          cropping.isTreatmentDataAllowed;
      }
    });
    setCroppingsCopy(newCroppingsCopy);
  };

  const onChangingSpatialDataAllowedCheckbox = (cropping) => {
    const newCroppingsCopy = [...croppingsCopy];
    const searchedCroppingCopy = newCroppingsCopy.find((crop) => {
      return crop.croppingId === cropping.croppingId;
    });
    searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
    const searchedCropping = activeBusinessPartner.croppings.find((crop) => {
      return crop.croppingId === cropping.croppingId;
    });
    searchedCropping
      ? changeSpatialDataAllowed(searchedCropping)
      : addSpatialDataAllowed(cropping);
    setCroppingsCopy(newCroppingsCopy);
  };

  const isAnySpatialDataCheckboxChecked = useMemo(() => {
    return !!croppingsCopy.find((field) => field.isSpatialDataAllowed);
  }, [croppingsCopy]);

  const toggleAllSpatialDataAllowedCheckboxes = () => {
    const newCroppingsCopy = [...croppingsCopy];
    if (!isAnySpatialDataCheckboxChecked) {
      newCroppingsCopy.forEach((c) => {
        if (!c.isExclusive) {
          const searchedCroppingCopy = newCroppingsCopy.find((fcrop) => {
            return fcrop.croppingId === c.croppingId;
          });
          searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
          const searchedCropping = activeBusinessPartner.croppings.find(
            (fcrop) => {
              return fcrop.croppingId === c.croppingId;
            }
          );
          searchedCropping
            ? changeSpatialDataAllowed(searchedCropping)
            : addSpatialDataAllowed(c);
        } else return null;
      });
    } else {
      newCroppingsCopy.forEach((c) => {
        if (c.isSpatialDataAllowed) {
          const searchedCroppingCopy = newCroppingsCopy.find((fcrop) => {
            return fcrop.croppingId === c.croppingId;
          });
          searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
          const searchedCropping = activeBusinessPartner.croppings.find(
            (fcrop) => {
              return fcrop.croppingId === c.croppingId;
            }
          );
          searchedCropping
            ? changeSpatialDataAllowed(searchedCropping)
            : addSpatialDataAllowed(c);
        } else return null;
      });
    }
    setCroppingsCopy(newCroppingsCopy);
  };

  const onChangingTreatmentDataAllowed = (cropping) => {
    const newCroppingsCopy = [...croppingsCopy];
    const searchedCroppingCopy = newCroppingsCopy.find((crop) => {
      return crop.croppingId === cropping.croppingId;
    });
    searchedCroppingCopy.isTreatmentDataAllowed = !searchedCroppingCopy.isTreatmentDataAllowed;
    const searchedCropping = activeBusinessPartner.croppings.find((crop) => {
      return crop.croppingId === cropping.croppingId;
    });
    searchedCropping
      ? changeTreatmentDataAllowed(searchedCropping)
      : addTreatmentDataAllowed(cropping);
    setCroppingsCopy(newCroppingsCopy);
  };

  const isAnyTreatmentDataCheckboxChecked = useMemo(() => {
    return !!croppingsCopy.find((field) => field.isTreatmentDataAllowed);
  }, [croppingsCopy]);

  const toggleAllTreatmentDataAllowedCheckboxes = () => {
    const newCroppingsCopy = [...croppingsCopy];
    if (!isAnyTreatmentDataCheckboxChecked) {
      newCroppingsCopy.forEach((c) => {
        if (!c.isExclusive) {
          const searchedCroppingCopy = newCroppingsCopy.find((fcrop) => {
            return fcrop.croppingId === c.croppingId;
          });
          searchedCroppingCopy.isTreatmentDataAllowed = !searchedCroppingCopy.isTreatmentDataAllowed;
          const searchedCropping = activeBusinessPartner.croppings.find(
            (fcrop) => {
              return fcrop.croppingId === c.croppingId;
            }
          );
          searchedCropping
            ? changeTreatmentDataAllowed(searchedCropping)
            : addTreatmentDataAllowed(c);
        } else return null;
      });
    } else {
      newCroppingsCopy.forEach((c) => {
        if (c.isTreatmentDataAllowed) {
          const searchedCroppingCopy = newCroppingsCopy.find((fcrop) => {
            return fcrop.croppingId === c.croppingId;
          });
          searchedCroppingCopy.isTreatmentDataAllowed = !searchedCroppingCopy.isTreatmentDataAllowed;
          const searchedCropping = activeBusinessPartner.croppings.find(
            (fcrop) => {
              return fcrop.croppingId === c.croppingId;
            }
          );
          searchedCropping
            ? changeTreatmentDataAllowed(searchedCropping)
            : addTreatmentDataAllowed(c);
        } else return null;
      });
    }
    setCroppingsCopy(newCroppingsCopy);
  };

  const onSubmit = (data) => {};

  if (activeBusinessPartner && croppingsCopy.length > 0)
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>
              <FormattedMessage id="GENERAL.SHORT_NAME" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.CULTIVATION_DATA" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.TREATMENT_DATA" />
            </th>
          </tr>
        </thead>
        <tbody onChange={handleSubmit(onSubmit)}>
          <tr>
            <td></td>
            <td>
              <FormControlLabel
                control={
                  <Controller
                    as={<Checkbox />}
                    control={control}
                    checked={isAnySpatialDataCheckboxChecked}
                    name="isSpatialDataAllowed"
                  />
                }
                label={""}
                onClick={() => toggleAllSpatialDataAllowedCheckboxes()}
              />
            </td>
            <td>
              <FormControlLabel
                control={
                  <Controller
                    as={<Checkbox />}
                    control={control}
                    checked={isAnyTreatmentDataCheckboxChecked}
                    name="isTreatmentDataAllowed"
                  />
                }
                onClick={() => toggleAllTreatmentDataAllowedCheckboxes()}
              />
            </td>
          </tr>
          {croppingsCopy.map((cropping) => (
            <tr key={`cropping-${cropping.croppingId}`}>
              <td>{cropping.shortName}</td>
              <td>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Checkbox />}
                      control={control}
                      checked={cropping.isSpatialDataAllowed}
                      name="isSpatialDataAllowed"
                      disabled={cropping.isExclusive}
                    />
                  }
                  label={""}
                  onClick={() => onChangingSpatialDataAllowedCheckbox(cropping)}
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Checkbox />}
                      control={control}
                      checked={cropping.isTreatmentDataAllowed}
                      name="isTreatmentDataAllowed"
                      disabled={cropping.isExclusive}
                    />
                  }
                  onClick={() => onChangingTreatmentDataAllowed(cropping)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  else if (activeBusinessPartner && croppingsCopy.length === 0)
    return (
      <div className="py-10">
        <p className="text-center font-weight-bold">
          <FormattedMessage id="GENERAL.NO_CROPPINGS" />
        </p>
      </div>
    );
  else
    return (
      <div className="py-10">
        <p className="text-center font-weight-bold">
          <FormattedMessage id="GENERAL.SELECT_FARM" />
        </p>
      </div>
    );
}
