import { useEffect, useState } from "react";
import store from "../../redux/store";

function useSubscription(subscriptionSymbol) {
  const [subscriptionExist, setSubscriptionExist] = useState(false);
  const storeState = store.getState();

  useEffect(() => {
    const userSubscription = storeState.auth.user.subscriptions.find(
      (subscription) => {
        if (Array.isArray(subscriptionSymbol)) return subscriptionSymbol.indexOf(subscription.symbol) > -1;
        else return subscription.symbol === subscriptionSymbol;
      }
    );

    if (userSubscription) {
      setSubscriptionExist(true);
    }
    // eslint-disable-next-line
  }, [storeState.auth.user.subscriptions]);

  return subscriptionExist;
}

export default useSubscription;
