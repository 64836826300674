import axios from "axios";

export const NOTIFICATIONS_URL = `${process.env.REACT_APP_URL}alerts`;

export function getAllAlertsToLogin() {
  return axios.get(`${NOTIFICATIONS_URL}/login`);
}

export function readAlertToLogin(alertAddressedToId) {
  return axios.put(`${NOTIFICATIONS_URL}/${alertAddressedToId}/read `, null);
}
