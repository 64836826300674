import axios from "axios";

const activeFarmId = localStorage.getItem("activeFarmId");
export const CROPPINGS_URL = `${process.env.REACT_APP_URL}croppings`;

export function getCroppingsToLogin(farmId) {
  return axios.get(`${CROPPINGS_URL}/bPartner/${farmId}/all`);
}

export function createCropping(farmId, form) {
  return axios.post(`${CROPPINGS_URL}/bPartner/${farmId}`, form);
}

export function getCroppingDetails(croppingId) {
  return axios.get(`${CROPPINGS_URL}/${croppingId}`);
}

export function updateCropping(croppingId, form) {
  return axios.put(`${CROPPINGS_URL}/${croppingId}`, form);
}

export function setFieldToCropping(croppingId, fieldId) {
  return axios.post(`${CROPPINGS_URL}/${croppingId}/field/${fieldId}`, null);
}

export function removeFieldToCropping(croppingId, fieldId) {
  return axios.delete(`${CROPPINGS_URL}/${croppingId}/field/${fieldId}`, null);
}

export function getCroppingsWithShapeToLogin(showPoints) {
  return axios.get(`${CROPPINGS_URL}/shape/all?withPoints=${showPoints}`);
}

export function getCroppingsWithShapeToBPartner(farmId, showPoints) {
  return axios.get(
    `${CROPPINGS_URL}/bPartner/${farmId}/shape/all?withPoints=${showPoints}&withNoActive=true`
  );
}

export function getCroppingWithWeatherForecast(activeBPartnerId) {
  return axios.get(`${CROPPINGS_URL}/bPartner/${activeBPartnerId}/weather/all`);
}

export function getCroppingShareToBPartner() {
  return axios.get(`${CROPPINGS_URL}/bPartner/${activeFarmId}/share`);
}

export function setFieldToCroppingWithShape(croppingId, fieldId) {
  return axios.put(
    `${CROPPINGS_URL}/${croppingId}/field/${fieldId}/shape`,
    null
  );
}

export function deleteCreatedCropping(fieldId) {
  return axios.delete(`${CROPPINGS_URL}/${fieldId}/delete`);
}
