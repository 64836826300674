import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { debounce } from "lodash";
import moment from "moment";
import { Form, Col, Button } from "react-bootstrap";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Search } from "../../../components/Search";
import { RangeFilter } from "../../../components/RangeFilter";

export function MapFilters({
  species,
  treatmentsType,
  onSpeciesFilterChange,
  getTreatments,
  onTogglingEcoCroppings,
  showShapesAsMarkers,
  setShapesAsMarkers,
  setMapShapesVisibility,
  filterFieldsAndCroppings,
  onTogglingActiveFCOnly,
  showFieldsAndCroppings,
  showTreatments,
  onSettingTreatmentsVisibility,
  showOnlyEcoCroppings,
  showOnlyActiveFieldsAndCroppings,
  treatmentsStatusVisibility,
  setTreatmentsStatusVisibility,
}) {
  const intl = useIntl();
  const [speciesList, setSpeciesList] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (species.length) {
      const newSpeciesList = [];
      species.forEach((type) => {
        type.checked = true;
        newSpeciesList.push(type);
      });
      setSpeciesList(newSpeciesList);
    }
  }, [species]);

  useEffect(() => {
    if (treatmentsType.length) {
      const newTreatmentsType = [];
      treatmentsType.forEach((treatmentType) => {
        treatmentType.checked = true;
        newTreatmentsType.push(treatmentType);
      });
      setTreatmentTypes(newTreatmentsType);
    }
  }, [treatmentsType]);

  const onShowingOrHidingFields = (e) => {
    setMapShapesVisibility({
      showFields: e.target.checked,
      showCroppings: showFieldsAndCroppings.showCroppings,
    });
  };

  const onShowingOrHidingCroppings = (e) => {
    setMapShapesVisibility({
      showFields: showFieldsAndCroppings.showFields,
      showCroppings: e.target.checked,
    });
  };

  const onTogglingEcoCroppingsCheckbox = () => {
    onTogglingEcoCroppings();
  };

  const onTogglingActiveFCOnlyCheckbox = () => {
    onTogglingActiveFCOnly();
  };

  const onShowingOrHidingSpecies = (e, kind) => {
    const newSpeciesList = [...speciesList];
    newSpeciesList.forEach((type) => {
      if (type.id === kind.id) {
        type.checked = e.target.checked;
      }
    });
    const typeList = newSpeciesList.map((type) => {
      return {
        desc: type.desc,
        speciesId: type.id,
        checked: type.checked,
      };
    });
    setSpeciesList(newSpeciesList);
    onSpeciesFilterChange(typeList);
  };

  const anySpeciesSelected = useMemo(() => {
    return !!speciesList.find((species) => species.checked);
  }, [speciesList]);

  const onShowingOrHidingAllSpecies = () => {
    const newSpeciesList = [...speciesList];
    newSpeciesList.forEach((type) => {
      type.checked = !anySpeciesSelected;
    });
    const typeList = newSpeciesList.map((type) => {
      return {
        desc: type.desc,
        speciesId: type.id,
        checked: type.checked,
      };
    });
    setSpeciesList(newSpeciesList);
    onSpeciesFilterChange(typeList);
  };

  const dateChange = useCallback(
    debounce(
      (flag, from, to, treatmentsList) =>
        onDateChange(flag, from, to, treatmentsList),
      1500
    ),
    []
  );

  const onDateChange = (flag, from, to, treatmentsList) => {
    if (flag) {
      const treatmentIds = treatmentsList
        .filter((treatmentType) => {
          return treatmentType.checked === true;
        })
        .map((treatmentType) => {
          return treatmentType.treatmentTypeId;
        });
      getTreatments(
        moment(from).format("YYYY-MM-DD"),
        moment(to).format("YYYY-MM-DD"),
        treatmentIds
      );
    }
  };

  const onChangingDate = (from, to) => {
    setFromDate(from);
    setToDate(to);
    dateChange(showTreatments, from, to, treatmentTypes);
  };

  const onShowingOrHidingTreatments = (e) => {
    const newTreatments = [...treatmentTypes];
    onSettingTreatmentsVisibility(e.target.checked);
    if (e.target.checked) {
      setFullTreatmentStatusVisibility();
      newTreatments.forEach((treatment) => {
        treatment.checked = true;
      });
    } else {
      clearTreatmentStatusVisibility();
      newTreatments.forEach((treatment) => {
        treatment.checked = false;
      });
    }
    const treatmentIds = newTreatments
      .filter((treatment) => {
        return treatment.checked === true;
      })
      .map((treatment) => {
        return treatment.treatmentTypeId;
      });
    getTreatments(fromDate, toDate, treatmentIds);
  };

  const onShowingOrHidingTreatmentType = (e, treatment) => {
    const newTreatments = [...treatmentTypes];
    if (showTreatments === false) {
      onSettingTreatmentsVisibility(true);
      setFullTreatmentStatusVisibility();
    }
    newTreatments.forEach((t) => {
      if (t.treatmentTypeId === treatment.treatmentTypeId) {
        t.checked = e.target.checked;
      }
    });
    setTreatmentTypes(newTreatments);
    const treatmentIds = newTreatments
      .filter((treatment) => {
        return treatment.checked === true;
      })
      .map((treatment) => {
        return treatment.treatmentTypeId;
      });
    getTreatments(fromDate, toDate, treatmentIds);
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onTogglingTreatmentStatusVisibility = (e, type) => {
    const state = { ...treatmentsStatusVisibility };
    if (type === "active") state.active = e.target.checked;
    if (type === "finished") state.finished = e.target.checked;
    if (type === "canceled") state.canceled = e.target.checked;
    setTreatmentsStatusVisibility(state);
  };
  const setFullTreatmentStatusVisibility = () => {
    setTreatmentsStatusVisibility({
      active: true,
      finished: true,
      canceled: true,
    });
  };
  const clearTreatmentStatusVisibility = () => {
    setTreatmentsStatusVisibility({
      active: false,
      finished: false,
      canceled: false,
    });
  };

  return (
    <>
      <Col className="mb-2 mt-2" md={8}>
        <Search
          onChange={onFilterChange}
          value={filter}
          placeholder={"GENERAL.FILTER"}
        />
      </Col>
      <Col className="mb-2 mt-2" md={4}>
        <Button onClick={() => filterFieldsAndCroppings(filter)}>
          <FormattedMessage id="GENERAL.FILTER" />
        </Button>
      </Col>
      <Form.Group as={Col} md={12} className="mb-0">
        <FormControlLabel
          className={`checkbox__fields--no_margin_bottom ${
            showFieldsAndCroppings.showFields ||
            showFieldsAndCroppings.showCroppings
              ? "checkbox--active "
              : ""
          }`}
          control={
            <Checkbox
              checked={showOnlyActiveFieldsAndCroppings}
              onChange={() => onTogglingActiveFCOnlyCheckbox()}
            />
          }
          label={`${intl.formatMessage({
            id: "PLACEHOLDER.SHOW_ONLY_ACTIVE_FIELDS_AND_CROPS",
          })}`}
        />
      </Form.Group>
      <Form.Group as={Col} md={12} className="mb-0">
        <FormControlLabel
          className={`checkbox__fields--no_margin_bottom checkbox__fields ${
            showFieldsAndCroppings.showFields ? "checkbox--active " : ""
          }`}
          control={
            <Checkbox
              checked={showFieldsAndCroppings.showFields}
              onChange={(e) => onShowingOrHidingFields(e)}
            />
          }
          label={`${intl.formatMessage({
            id: "PLACEHOLDER.SHOW_FIELDS",
          })}`}
        />
      </Form.Group>
      <Form.Group as={Col} md={12} className="mb-0">
        <FormControlLabel
          className={`checkbox__fields--no_margin_bottom checkbox__croppings ${
            showFieldsAndCroppings.showCroppings ? "checkbox--active " : ""
          }`}
          control={
            <Checkbox
              checked={showFieldsAndCroppings.showCroppings}
              onChange={(e) => onShowingOrHidingCroppings(e)}
            />
          }
          label={`${intl.formatMessage({
            id: "PLACEHOLDER.SHOW_CROPPINGS",
          })}`}
        />
      </Form.Group>
      <Form.Group as={Col} md={12} className="mb-0 pl-10">
        <FormControlLabel
          className={`checkbox__fields--no_margin_bottom checkbox__croppings ${
            showFieldsAndCroppings.showCroppings ? "checkbox--active " : ""
          }`}
          control={
            <Checkbox
              checked={showOnlyEcoCroppings}
              onChange={() => onTogglingEcoCroppingsCheckbox()}
              disabled={!showFieldsAndCroppings.showCroppings}
            />
          }
          label={`${intl.formatMessage({
            id: "PLACEHOLDER.SHOW_ONLY_ECO_CROPPINGS",
          })}`}
        />
      </Form.Group>
      <Form.Group as={Col} md={12} className="mb-0 mt-4">
        <p className="text--bold m-0">
          <FormattedMessage id="GENERAL.SPECIES" />
        </p>
      </Form.Group>
      {speciesList.length > 0 && (
        <>
          <Form.Group as={Col} md={12} className="mb-0">
            <FormControlLabel
              className={`checkbox__fields--no_margin_bottom`}
              control={
                <Checkbox
                  checked={anySpeciesSelected}
                  color="primary"
                  onChange={(e) => onShowingOrHidingAllSpecies()}
                />
              }
              label={`${intl.formatMessage({ id: "GENERAL.SELECT_ALL" })}`}
            />
          </Form.Group>
          {speciesList.map((type) => (
            <Form.Group key={type.id} as={Col} md={12} className="mb-0">
              <FormControlLabel
                className={`checkbox__fields--no_margin_bottom ${
                  type.checked ? "checkbox--active " : ""
                }`}
                control={
                  <Checkbox
                    checked={type.checked}
                    onChange={(e) => onShowingOrHidingSpecies(e, type)}
                  />
                }
                label={`${type.desc}`}
              />
            </Form.Group>
          ))}
        </>
      )}
      {treatmentTypes.length > 0 && (
        <>
          <RangeFilter
            title={"GENERAL.TREATMENTS_ON_CROPS"}
            dateChange={onChangingDate}
          />
          <Form.Group as={Col} md={12} className="mb-0">
            <FormControlLabel
              className={`checkbox__fields--no_margin_bottom checkbox__treatments ${
                showTreatments ? "checkbox--active " : ""
              }`}
              control={
                <Checkbox
                  checked={showTreatments}
                  onChange={(e) => onShowingOrHidingTreatments(e)}
                />
              }
              label={`${intl.formatMessage({
                id: "PLACEHOLDER.SHOW_TREATMENTS",
              })}`}
            />
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-0">
            <div className="rounded bg-light px-4 mx-2 d-flex flex-wrap">
              <div>
                <FormControlLabel
                  className={`checkbox__fields--no_margin_bottom`}
                  control={
                    <Checkbox
                      checked={treatmentsStatusVisibility.active}
                      onChange={(e) =>
                        onTogglingTreatmentStatusVisibility(e, "active")
                      }
                    />
                  }
                  label={`${intl.formatMessage({
                    id: "GENERAL.ACTIVE",
                  })}`}
                />
              </div>
              <div>
                <FormControlLabel
                  className={`checkbox__fields--no_margin_bottom`}
                  control={
                    <Checkbox
                      checked={treatmentsStatusVisibility.finished}
                      onChange={(e) =>
                        onTogglingTreatmentStatusVisibility(e, "finished")
                      }
                    />
                  }
                  label={`${intl.formatMessage({
                    id: "GENERAL.TREATMENT_FINISHED",
                  })}`}
                />
              </div>
              <div>
                <FormControlLabel
                  className={`checkbox__fields--no_margin_bottom`}
                  control={
                    <Checkbox
                      checked={treatmentsStatusVisibility.canceled}
                      onChange={(e) =>
                        onTogglingTreatmentStatusVisibility(e, "canceled")
                      }
                    />
                  }
                  label={`${intl.formatMessage({
                    id: "GENERAL.TREATMENT_CANCELED",
                  })}`}
                />
              </div>
            </div>
          </Form.Group>
          {treatmentTypes.map((treatment) => (
            <Form.Group
              key={treatment.treatmentTypeId}
              as={Col}
              md={12}
              className="mb-0 pl-10"
            >
              <FormControlLabel
                className={`checkbox__fields--no_margin_bottom ${treatment.treatmentTypeId ===
                  1 &&
                  "checkbox__soil_cultivation"} ${treatment.treatmentTypeId ===
                  2 &&
                  "checkbox__fertilization"} ${treatment.treatmentTypeId ===
                  3 && "checkbox__planting"} ${treatment.treatmentTypeId ===
                  5 &&
                  "checkbox__plant_protection"} ${treatment.treatmentTypeId ===
                  6 && "checkbox__irrigation"} ${
                  treatment.checked ? "checkbox--active " : ""
                }`}
                control={
                  <Checkbox
                    checked={treatment.checked}
                    onChange={(e) =>
                      onShowingOrHidingTreatmentType(e, treatment)
                    }
                  />
                }
                label={`${intl.formatMessage({
                  id: `TREATMENT.${treatment.treatmentTypeDesc}`,
                })}`}
              />
            </Form.Group>
          ))}
        </>
      )}
      <Form.Group as={Col} md={12} className="text-right mb-0">
        <Button onClick={() => setShapesAsMarkers()}>
          {showShapesAsMarkers ? (
            <FormattedMessage id="GENERAL.RESTORE" />
          ) : (
            <FormattedMessage id="GENERAL.SHOW_SHAPES_AS_MARKERS" />
          )}
        </Button>
      </Form.Group>
    </>
  );
}
