import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import { AsideButton } from "../components/AsideButton";
import {
  CroppingsTable,
  CroppingsGeneralData,
  CroppingsRelatedFields,
  CroppingsTreatments,
  CroppingTreatments,
  ShareCroppingsDataModal,
} from "../modules/croppings";
import {
  getCroppingsToLogin,
  createCropping,
  getCroppingDetails,
  updateCropping,
  setFieldToCropping,
  removeFieldToCropping,
  setFieldToCroppingWithShape,
  deleteCreatedCropping,
} from "../services/croppingsCrud";
import { getAllVarieties } from "../services/varietiesCrud";
import { getFieldsToLogin } from "../services/fieldsCrud";
import {
  createPolygonToCropping,
  getShape,
  updatePolygonToCropping,
  getShapeLongestLine,
  getPoint,
} from "../services/shapesCrud";
import {
  getTreatmentsToCropping,
  getDimSizes,
  createTreatmentsToCropping,
  getTreatmentToCroppingWithFilters,
} from "../services/treatmentsCrud";
import {
  getAllFertilizerChemistry,
  getAllPlantProtectionChemistry,
} from "../services/chemistryCrud";
import { getAllUOM } from "../services/uomCrud";
import {
  cancelEvent,
  finishEvent,
  activateEvent,
} from "../services/eventsCrud";
import {
  getDataSharingToBPartnerByLogin,
  createDataSharingByLogin,
} from "../services/farmSharingCrud";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowOnboardings } from "../services/onboardingsCrud";
import CropViewModal from "../modules/croppings/modals/CropViewModal";
import displayFeedback from "../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../components/helpers/displayFeedbackFromHeaders";

export function CroppingsPage(props) {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [croppings, setCroppings] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [selectedCroppingId, setSelectedCroppingId] = useState(null);
  const [croppingData, setCroppingData] = useState(null);
  const [fields, setFields] = useState([]);
  const [shape, setShape] = useState(null);
  const [soilCultivationList, setSoilCultivationList] = useState([]);
  const [fertilizationList, setFertilizationList] = useState([]);
  const [plantingList, setPlantingList] = useState([]);
  const [irrigationList, setIrrigationList] = useState([]);
  const [plantProtectionList, setPlantProtectionList] = useState([]);
  const [dimSizes, setDimSizes] = useState([]);
  const [historicalTreatments, setHistoricalTreatments] = useState([]);
  const [fertilizers, setFertilizers] = useState([]);
  const [plantProtectionProducts, setPlantProtectionProducts] = useState([]);
  const [unitOfMeasurements, setUnitOfMeasurements] = useState([]);
  const [fieldLongestLine, setFieldLongestLine] = useState(null);
  const [marker, setMarker] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [businessPartners, setBusinessPartners] = useState([]);
  const searchedCroppingId = new URLSearchParams(props.location.search).get(
    "id"
  );
  const showOnboardings = useSelector(
    (state) => state.auth.user.showOnboardings
  );
  const dispatch = useDispatch();

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getCroppings = () => {
    getCroppingsToLogin(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setCroppings(response.data);
        if (response.data.length) {
          if (searchedCroppingId) {
            const searchedCroppExist = response.data.filter((row) => {
              return row.croppingId === parseInt(searchedCroppingId);
            });
            setSelectedCroppingId(searchedCroppExist[0].croppingId);
            onGetCroppingDetails(searchedCroppExist[0].croppingId);
          } else {
            setSelectedCroppingId(response.data[0].croppingId);
            onGetCroppingDetails(response.data[0].croppingId);
          }
          setMode("update");
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getCroppings);

  const getVarieties = () => {
    getAllVarieties()
      .then((response) => {
        if (mounted.current) setVarieties(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getVarieties);

  const getFields = () => {
    getFieldsToLogin(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getFields);

  const getTreatments = () => {
    getTreatmentsToCropping()
      .then((response) => {
        response.data.forEach((treatment) => {
          if (treatment.treatmentTypeDesc === "Uprawa gleby") {
            setSoilCultivationList(treatment.dtreatmentList);
          }
          if (treatment.treatmentTypeDesc === "Nawożenie") {
            setFertilizationList(treatment.dtreatmentList);
          }
          if (treatment.treatmentTypeDesc === "Sadzenie") {
            setPlantingList(treatment.dtreatmentList);
          }
          if (treatment.treatmentTypeDesc === "Nawadnianie") {
            setIrrigationList(treatment.dtreatmentList);
          }
          if (treatment.treatmentTypeDesc === "Zabiegi s.o.r.") {
            setPlantProtectionList(treatment.dtreatmentList);
          }
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getTreatments);

  const getSizes = () => {
    getDimSizes()
      .then((response) => {
        setDimSizes(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getSizes);

  const getFertilizers = () => {
    getAllFertilizerChemistry()
      .then((response) => {
        setFertilizers(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getFertilizers);

  const getPlantProtectionProducts = () => {
    getAllPlantProtectionChemistry()
      .then((response) => {
        setPlantProtectionProducts(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getPlantProtectionProducts);

  const getUnitOfMeasurements = () => {
    getAllUOM()
      .then((response) => {
        setUnitOfMeasurements(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getUnitOfMeasurements);

  const getSharingCroppingDataPlan = () => {
    getDataSharingToBPartnerByLogin(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setBusinessPartners(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffect(() => {
    if (modalOpen) getSharingCroppingDataPlan();
  }, [modalOpen]);

  const getHistoricalTreatments = (croppingId) => {
    getTreatmentToCroppingWithFilters(croppingId)
      .then((response) => {
        response.data.forEach((historicalTreatment) => {
          historicalTreatment.treatments.forEach((treat) => {
            if (
              treat.dtreatmentType === "PLOWING" ||
              treat.dtreatmentType === "SUBSOILING" ||
              treat.dtreatmentType === "HARROWING" ||
              treat.dtreatmentType === "CULTIVATING" ||
              treat.dtreatmentType === "THREADING" ||
              treat.dtreatmentType === "ROLLING" ||
              treat.dtreatmentType === "PLATEOUT" ||
              treat.dtreatmentType === "REDRAWING" ||
              treat.dtreatmentType === "DESCALING" ||
              treat.dtreatmentType === "WITH_CULTIVATOR" ||
              treat.dtreatmentType === "WITH_ROTARY_TILLER" ||
              treat.dtreatmentType === "WITH_ACTIVE_HARROW"
            ) {
              historicalTreatment.isSoilCultivation = true;
            } else if (treat.dtreatmentType === "PLANTING") {
              historicalTreatment.isPlanting = true;
            } else if (treat.dtreatmentType === "IRRIGATION") {
              historicalTreatment.isIrrigation = true;
            } else if (
              treat.dtreatmentType === "LIQUID_FERTILIZATION" ||
              treat.dtreatmentType === "ROW_FERTILIZATION" ||
              treat.dtreatmentType === "BROADCAST_FERTILIZATION"
            ) {
              historicalTreatment.isFertilization = true;
            } else if (
              treat.dtreatmentType === "SPRAYING" ||
              treat.dtreatmentType === "FOGGING" ||
              treat.dtreatmentType === "DRESSING"
            ) {
              historicalTreatment.isPlantProtection = true;
            }
          });
        });
        setHistoricalTreatments(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onCreatingCropping = (form) => {
    return new Promise((resolve) => {
      createCropping(localStorage.getItem("activeFarmId"), form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          const newCroppings = [...croppings];
          newCroppings.push(response.data);
          // setShape(null);
          setCroppings(newCroppings);
          setSelectedCroppingId(response.data.croppingId);
          onGetCroppingDetails(response.data.croppingId);
          setMode("update");
          if (showOnboardings) {
            setShowOnboardings(false)
              .then(() => {
                dispatch({
                  type: "[Show Onboardings] Change",
                  payload: {
                    showOnboardings: false,
                  },
                });
              })
              .catch((error) => {
                resolve("error");
                const errorOptions = errorHandler(error);
                if (errorOptions.type === "error") {
                  displayFeedback({
                    type: errorOptions.type,
                    message: `${intl.formatMessage({
                      id: errorOptions.message,
                    })}`,
                  });
                }
              });
          }
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onUpdatingCropping = (form) => {
    return new Promise((resolve) => {
      updateCropping(croppingData.cropping.croppingId, form)
        .then((response) => {
          const newCroppings = [...croppings];
          let searchedCropping = newCroppings.filter((row) => {
            return row.croppingId === response.data.croppingId;
          });
          searchedCropping[0].shortName = response.data.shortName;
          searchedCropping[0].isActive = response.data.isActive;
          searchedCropping[0].areaDeclared = response.data.areaDeclared;
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          setCroppings(newCroppings);
          setCroppingData({ ...croppingData, cropping: response.data });
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onNoFarmSelectedError = () => {
    displayFeedback({
      type: "error",
      message: `${intl.formatMessage({ id: "FEEDBACK.FARM_NOT_SELECTED" })}`,
    });
  };

  const onChangingMode = () => {
    let newMode = mode === "create" ? (newMode = "update") : "create";
    setMode(newMode);

    if (selectedCroppingId) {
      setSelectedCroppingId(null);
    }
  };

  const onSelectingCropping = (croppingId) => {
    if (selectedCroppingId !== croppingId) {
      setSelectedCroppingId(croppingId);
      onGetCroppingDetails(croppingId);
      setMode("update");
    } else {
      setSelectedCroppingId(null);
    }
  };

  const onGetCroppingDetails = (croppingId) => {
    getCroppingDetails(croppingId)
      .then((response) => {
        setFieldLongestLine(null);
        setCroppingData(response.data);
        getHistoricalTreatments(croppingId);
        if (response.data.cropping.shapeId) {
          onGetCroppingShape(response.data.cropping.shapeId);
        } else {
          setShape(null);
        }
        if (response.data.cropping.pointId) {
          getShapePoint(response.data.cropping.pointId);
        } else {
          setMarker(null);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onTyingFieldWithCropping = (fieldId) => {
    return new Promise((resolve) => {
      setFieldToCropping(croppingData.cropping.croppingId, fieldId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          onGetCroppingDetails(croppingData.cropping.croppingId);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onDeletingFieldRelationWithCropping = (fieldId) => {
    return new Promise((resolve) => {
      removeFieldToCropping(croppingData.cropping.croppingId, fieldId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          onGetCroppingDetails(croppingData.cropping.croppingId);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onCreatingPolygon = (form) => {
    form.children.forEach((child) => {
      child.shape.coordinates[0].push(child.shape.coordinates[0][0]);
    });
    return new Promise((resolve) => {
      createPolygonToCropping(croppingData.cropping.croppingId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          onGetCroppingDetails(croppingData.cropping.croppingId);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onGetCroppingShape = (shapeId) => {
    getShape(shapeId)
      .then((response) => {
        const croppingShape = response.data.children;
        croppingShape.forEach((child) => {
          child.shape.coordinates[0].pop();
        });
        getShapeLine(response.data.parent.shapeId);
        setShape(croppingShape);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onUpdatingPolygon = (form) => {
    if (form.children !== null) {
      form.children.forEach((child) => {
        child.shape.coordinates[0].push(child.shape.coordinates[0][0]);
      });
    }
    return new Promise((resolve) => {
      updatePolygonToCropping(croppingData.cropping.croppingId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          setShape(null);
          setTimeout(() => {
            onGetCroppingDetails(croppingData.cropping.croppingId);
          });
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onCreatingTreatment = (form) => {
    form.shape.coordinates[0].push(form.shape.coordinates[0][0]);
    return new Promise((resolve) => {
      createTreatmentsToCropping(croppingData.cropping.croppingId, form)
        .then((response) => {
          getHistoricalTreatments(croppingData.cropping.croppingId);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onFinishingTreatment = (eventId) => {
    return new Promise((resolve) => {
      finishEvent(eventId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getHistoricalTreatments(croppingData.cropping.croppingId);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onCancelingTreatment = (eventId) => {
    return new Promise((resolve) => {
      cancelEvent(eventId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getHistoricalTreatments(croppingData.cropping.croppingId);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onActivatingTreatment = (eventId) => {
    return new Promise((resolve) => {
      activateEvent(eventId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getHistoricalTreatments(croppingData.cropping.croppingId);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const getShapeLine = (shapeId) => {
    getShapeLongestLine(shapeId)
      .then((response) => {
        setFieldLongestLine(response.data.coordinates);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onOpeningOrClosingModal = () => {
    setModalOpen(!modalOpen);
  };

  const onOpeningCropViewModal = () => {
    dispatch({
      type: "cropViewModal/setOpen",
      payload: true,
    });
  };

  const onUpdatingFarmSharingPlan = (form) => {
    return new Promise((resolve) => {
      createDataSharingByLogin(localStorage.getItem("activeFarmId"), form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getSharingCroppingDataPlan();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const getShapePoint = (shapeId) => {
    getPoint(shapeId)
      .then((response) => {
        setMarker({
          position: {
            lat: response.data.coordinates[1],
            lng: response.data.coordinates[0],
          },
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const mapFieldShapeOnCropping = (fieldId) => {
    return new Promise((resolve) => {
      setFieldToCroppingWithShape(croppingData.cropping.croppingId, fieldId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          onGetCroppingDetails(croppingData.cropping.croppingId);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const deleteRow = () => {
    return new Promise((resolve) => {
      deleteCreatedCropping(selectedCroppingId)
        .then((response) => {
          const newCroppings = croppings.filter((cropping) => {
            return cropping.croppingId !== selectedCroppingId;
          });
          setCroppings(newCroppings);
          setSelectedCroppingId(null);
          setCroppingData(null);
          setShape(null);
          setFieldLongestLine(null);
          setMarker(null);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  return (
    <Row>
      <ShareCroppingsDataModal
        open={modalOpen}
        close={onOpeningOrClosingModal}
        businessPartners={[...businessPartners]}
        croppings={[...croppings]}
        onUpdatingFarmSharingPlan={onUpdatingFarmSharingPlan}
        onNoFarmSelectedError={onNoFarmSelectedError}
      />
      <CropViewModal selectedCroppingId={selectedCroppingId} />
      <Col md={12} lg={4} xxl={4}>
        <CroppingsTable
          mode={mode}
          croppings={croppings}
          selectedRow={selectedCroppingId}
          onChangingMode={onChangingMode}
          onSelectingRow={onSelectingCropping}
          deleteRow={deleteRow}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <Row>
          <Col xs={12}>
            <CroppingsGeneralData
              mode={mode}
              varieties={varieties}
              cropping={croppingData}
              createCropping={onCreatingCropping}
              updateCropping={onUpdatingCropping}
            />
          </Col>
          {mode !== "create" && (
            <>
              <Col xs={12}>
                <CroppingsRelatedFields
                  fields={fields}
                  cropping={croppingData}
                  croppingShape={shape}
                  croppingMarker={marker}
                  fieldLongestLine={fieldLongestLine}
                  onTyingFieldWithCropping={onTyingFieldWithCropping}
                  onDeletingFieldRelationWithCropping={
                    onDeletingFieldRelationWithCropping
                  }
                  onCreatingPolygon={onCreatingPolygon}
                  onUpdatingPolygon={onUpdatingPolygon}
                  mapFieldShapeOnCropping={mapFieldShapeOnCropping}
                />
              </Col>
              <Col xs={12}>
                <CroppingsTreatments
                  soilCultivationList={soilCultivationList}
                  fertilizationList={fertilizationList}
                  plantingList={plantingList}
                  irrigationList={irrigationList}
                  plantProtectionList={plantProtectionList}
                  varieties={varieties}
                  dimSizes={dimSizes}
                  fertilizers={fertilizers}
                  plantProtectionProducts={plantProtectionProducts}
                  unitOfMeasurements={unitOfMeasurements}
                  croppingShape={shape}
                  croppingData={croppingData}
                  fieldLongestLine={fieldLongestLine}
                  onCreatingTreatment={onCreatingTreatment}
                />
              </Col>
              <Col xs={12}>
                <CroppingTreatments
                  historicalTreatments={historicalTreatments}
                  onFinishingTreatment={onFinishingTreatment}
                  onCancelingTreatment={onCancelingTreatment}
                  onActivatingTreatment={onActivatingTreatment}
                />
              </Col>
            </>
          )}
        </Row>
        <aside className="aside_buttons_container">
          <AsideButton
            parentFunction={onOpeningOrClosingModal}
            tooltipMessage="SHARE_DATA"
            iconImage="flaticon-network"
          />
          {croppingData?.cropping.hasCropViewStation && (
            <AsideButton
              parentFunction={onOpeningCropViewModal}
              tooltipMessage="PHOTOS_FROM_CROPPING"
              iconImage="fa fa-camera"
            />
          )}
        </aside>
      </Col>
    </Row>
  );
}
