export default function getModuleName(location) {
  if (
    location.pathname.includes("fields") ||
    location.pathname.includes("croppings")
  ) {
    return {
      type: "basic",
      name: location.pathname.split("/")[2].toUpperCase(),
    };
  }
  if (
    location.pathname.includes("brigadier") ||
    location.pathname.includes("crop-manager") ||
    location.pathname.includes("pulp-exchange")
  ) {
    if (location.pathname.split("/")[3])
      return {
        type: location.pathname.split("/")[1].toUpperCase(),
        name: location.pathname.split("/")[3].toUpperCase(),
      };
    return {
      type: location.pathname.split("/")[1].toUpperCase(),
      name: location.pathname.split("/")[2].toUpperCase(),
    };
  }
  return {
    type: "basic",
    name: location.pathname.split("/")[1].toUpperCase(),
  };
}
