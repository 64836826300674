import React from "react";
import { useIntl } from "react-intl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowTutorials,
  resetWathedOnboardings,
} from "../services/onboardingsCrud";
import errorHandler from "../shared/errorHandler";
import displayFeedback from "./helpers/displayFeedback";

export default function ToggleTutorials() {
  const intl = useIntl();
  const checked = useSelector((state) => state.auth.user.showTutorials);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = e.target.checked;
    setShowTutorials(value)
      .then(() => {
        dispatch({
          type: "[Show Tutorials] Change",
          payload: {
            showTutorials: value,
          },
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
    if (!value) {
      resetWathedOnboardings().catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
    }
  };
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label={intl.formatMessage({ id: "GENERAL.SHOW_TUTORIAL_POPUPS" })}
      />
    </>
  );
}
