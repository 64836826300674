import React, { useState, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { SingleTariffBonus } from "./SingleTariffBonus";
import getAvailableTariffs from "../../helpers/getAvailableTariffs";

export function TariffBonusForm({
  formData,
  tariffs,
  tariffLevelGroups,
  onCreatingTariffLevelGroup,
  onDeletingTariffLevelGroup,
  onUpdatingTariffLevelGroup,
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [selectedTariffs, setSelectedTariffs] = useState();

  const availableTariffs = useMemo(() => {
    return getAvailableTariffs(selectedTariffs, tariffLevelGroups, tariffs, undefined, "tariffLevelGroup");
  }, [selectedTariffs, tariffLevelGroups, tariffs]);

  async function handleAddTariffLevelGroup() {
    setLoading(true);
    const callback = await onCreatingTariffLevelGroup(selectedTariffs);
    if (callback) {
      setLoading(false);
    }
    if (callback === "OK") setSelectedTariffs(null);
  }

  const selectedTariffDetails = useMemo(() => {
    if (selectedTariffs?.length > 0 && formData)
      return {
        tariffTypeValue: selectedTariffs[0].tariffTypeValue,
        uomName: formData.uom.find((uom) => uom.id === selectedTariffs[0].uomId)
          .desc,
        currencyName: formData.currencies.find(
          (currency) => currency.id === selectedTariffs[0].currencyId
        ).name,
      };
    else return undefined;
  }, [formData, selectedTariffs]);

  return (
    <>
      <Form>
        <Form.Row>
          <Col xs={12} className="mb-4">
            <div className="d-flex align-items-center">
              <h6 className="mr-4 my-0">
                <FormattedMessage id="BRIGADIER.ADD_BONUS_THRESHOLD_GROUP" />
              </h6>
            </div>
          </Col>
          <Col xs={12} className="mb-4">
            <Select
              isMulti
              options={availableTariffs}
              value={selectedTariffs}
              onChange={setSelectedTariffs}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.SELECT_TARIFFS",
              })}...`}
            />
          </Col>
          {selectedTariffDetails && (
            <Col xs={12}>
              <div className="d-flex">
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.TARIFF_TYPE" />{": "}
                  <FormattedMessage id={`GENERAL.${selectedTariffDetails.tariffTypeValue}`} />
                </p>
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" />{": "}
                  {selectedTariffDetails.uomName}
                </p>
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.CURRENCY" />{": "}
                  {selectedTariffDetails.currencyName}
                </p>
              </div>
            </Col>
          )}
          <Col xs={12} className="text-right">
            <Button
              onClick={handleAddTariffLevelGroup}
              disabled={
                loading || !selectedTariffs || selectedTariffs?.length === 0
              }
              className="mb-4"
            >
              <FormattedMessage id="GENERAL.ADD" />
            </Button>
          </Col>
        </Form.Row>
        <Form.Row>
          {tariffLevelGroups?.length > 0 &&
            tariffLevelGroups.map((group) => (
              <SingleTariffBonus
                key={`tariffLevelGroup-${group.tariffLevelGroupId}`}
                group={group}
                formData={formData}
                tariffs={tariffs}
                tariffLevelGroups={tariffLevelGroups}
                onDeletingTariffLevelGroup={onDeletingTariffLevelGroup}
                onUpdatingTariffLevelGroup={onUpdatingTariffLevelGroup}
              />
            ))}
        </Form.Row>
      </Form>
    </>
  );
}
