import {I18N_CONFIG_KEY} from "./I18N_CONFIG_KEY";

const browserLanguage = navigator.language.slice(0,2)

const getLang = () => {
  const languages = ["en", "pl", "de", "ru", "es"]
  if (languages.indexOf(browserLanguage) > -1) return browserLanguage
  else return "en"
}

const initialState = {
  selectedLang: getLang(),
};

export function getSelectedLang() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}
