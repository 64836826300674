import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import {
  getTreatmentsToCropping,
  getDimSizes,
} from "../services/treatmentsCrud";
import { getAllVarieties } from "../services/varietiesCrud";
import { getCroppingsWithShapeToBPartner } from "../services/croppingsCrud";
import {
  getAllFertilizerChemistry,
  getAllPlantProtectionChemistry,
} from "../services/chemistryCrud";
import { getAllUOM } from "../services/uomCrud";
import {
  CalendarFilters,
  Calendar,
  CreateTreatmentModal,
  UpdateTreatmentModal,
} from "../modules/calendar";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import displayFeedback from "../components/helpers/displayFeedback";
import { useDispatch, useSelector } from "react-redux";
import getEventsToRedux from "../modules/calendar/helpers/getEventsToRedux";
import { getEventStatuses } from "../services/eventsCrud";
import getTreatmentDetails from "../modules/calendar/helpers/getTreatmentDetails";

export function CalendarPage() {
  const intl = useIntl();

  const croppings = useSelector((state) => state.calendar.croppings);
  const treatmentTypes = useSelector((state) => state.calendar.treatmentTypes);
  const eventStatuses = useSelector((state) => state.calendar.eventStatuses);
  const dates = useSelector((state) => state.calendar.dates);
  const dispatch = useDispatch();

  let location = useLocation();
  let history = useHistory();

  const getTreatmentTypes = () => {
    getTreatmentsToCropping()
      .then((response) => {
        const treatmentTypes = response.data.filter((treatment) => {
          return treatment.treatmentTypeDesc !== "Zbiór";
        });
        let treatmentTypeIds = [];
        dispatch({
          type: "calendar/setTreatmentTypes",
          payload: treatmentTypes,
        });
        treatmentTypes.forEach((treatmentType) => {
          treatmentTypeIds.push(treatmentType.treatmentTypeId);
        });
        const treatmentTypesList = {
          soilCultivation: [],
          fertilization: [],
          planting: [],
          irrigation: [],
          plantProtection: [],
        };
        response.data.forEach((treatment) => {
          if (treatment.treatmentTypeDesc === "Uprawa gleby") {
            treatmentTypesList.soilCultivation = treatment.dtreatmentList;
          }
          if (treatment.treatmentTypeDesc === "Nawożenie") {
            treatmentTypesList.fertilization = treatment.dtreatmentList;
          }
          if (treatment.treatmentTypeDesc === "Sadzenie") {
            treatmentTypesList.planting = treatment.dtreatmentList;
          }
          if (treatment.treatmentTypeDesc === "Nawadnianie") {
            treatmentTypesList.irrigation = treatment.dtreatmentList;
          }
          if (treatment.treatmentTypeDesc === "Zabiegi s.o.r.") {
            treatmentTypesList.plantProtection = treatment.dtreatmentList;
          }
        });
        dispatch({
          type: "calendar/setTreatmentTypesList",
          payload: treatmentTypesList,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getTreatmentTypes);

  const getEventStatusesToRedux = () => {
    getEventStatuses()
      .then((response) => {
        dispatch({
          type: "calendar/setEventStatuses",
          payload: response.data?.eventStatuses,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };
  useEffectOnce(getEventStatusesToRedux);

  useEffect(() => {
    if (treatmentTypes.length > 0 && dates) getEventsToRedux();
  }, [dates, treatmentTypes, eventStatuses]);

  const getVarieties = () => {
    getAllVarieties()
      .then((response) => {
        dispatch({
          type: "calendar/setVarieties",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getVarieties);

  const getSizes = () => {
    getDimSizes()
      .then((response) => {
        dispatch({
          type: "calendar/setDimSizes",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getSizes);

  const getCroppings = () => {
    getCroppingsWithShapeToBPartner(localStorage.getItem("activeFarmId"), false)
      .then((response) => {
        dispatch({
          type: "calendar/setCroppings",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getCroppings);

  const getFertilizers = () => {
    getAllFertilizerChemistry()
      .then((response) => {
        dispatch({
          type: "calendar/setFertilizers",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getFertilizers);

  const getPlantProtectionProducts = () => {
    getAllPlantProtectionChemistry()
      .then((response) => {
        dispatch({
          type: "calendar/setPlantProtectionProducts",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getPlantProtectionProducts);

  const getUnitOfMeasurements = () => {
    getAllUOM()
      .then((response) => {
        dispatch({
          type: "calendar/setUOM",
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getUnitOfMeasurements);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (croppings && params.get("treatmentId")) {
      getTreatmentDetails(params.get("treatmentId"));
      history.push("/calendar");
    }
  }, [croppings, history, location]);

  return (
    <>
      <Row>
        <CreateTreatmentModal />
        <UpdateTreatmentModal />
        <Col md={12} lg={3} xxl={3}>
          <Row>
            <Col xs={12}>
              <CalendarFilters />
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={9} xxl={9}>
          <Calendar />
        </Col>
      </Row>
    </>
  );
}
