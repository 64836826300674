import axios from "axios";

export const PACKAGING_URL = `${process.env.REACT_APP_URL}brigadier/packaging`;

export function getPackagingsFormData() {
  return axios.get(`${PACKAGING_URL}/attribute`);
}

export function getAllPackagings() {
  return axios.get(`${PACKAGING_URL}/all`);
}

export function createPackage(form) {
  return axios.post(`${PACKAGING_URL}`, form);
}

export function updatePackage(packagingId, form) {
  return axios.put(`${PACKAGING_URL}/${packagingId}`, form);
}

export function generatePackagingLabels(packagingId, quantity) {
  return axios.post(
    `${PACKAGING_URL}/${packagingId}/generate/${quantity}`,
    null
  );
}

export function deleteLabel(labelId) {
  return axios.delete(`${PACKAGING_URL}/label/${labelId}`);
}

export function deleteLabels(labelIds) {
  return axios.put(`${PACKAGING_URL}/label/delete/group`, labelIds);
}

export function incrementPrinterCount(form) {
  return axios.put(`${PACKAGING_URL}/label/print`, form);
}

export function createExcelWithLabels(labelIds) {
  return axios.post(`${PACKAGING_URL}/label/excel`, labelIds, {
    responseType: "blob",
  });
}
