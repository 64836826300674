import React from "react";
import { FormattedMessage } from "react-intl";
import { ReportTypeRow } from "./ReportTypeRow";
import Table from "react-bootstrap/Table";

export function ReportTypesTable({ data, onSelectingRow }) {
  return (
    <div className="card__table">
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>
              <FormattedMessage id="GENERAL.NAME" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.DESCRIPTION" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.SOURCE" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 && (
            <>
              {data.map((reportType) => (
                <ReportTypeRow
                  key={`reportType-${reportType.name}`}
                  reportType={reportType}
                  onSelectingRow={onSelectingRow}
                />
              ))}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
}
