import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

export function OperationSummaryRows({ data }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    data.length && calculateValueSum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const calculateValueSum = () => {
    let currenciesArray = [];
    data.forEach((row) => {
      if (row.__level__ === 0) {
        if (typeof currenciesArray[row.__currencyId] === "undefined") {
          currenciesArray[row.__currencyId] = {
            currencyName: row.currencyName,
            sum: row.value,
          };
        } else {
          currenciesArray[row.__currencyId].sum += row.value;
        }
      }
    });
    let sortedArray = [];
    for (let key in currenciesArray) {
      sortedArray.push(currenciesArray[key]);
    }
    setRows(sortedArray);
  };

  return (
    <>
      {rows.map((row, index) => (
        <tr key={row.currencyName} className="text-center">
          <td colSpan={3}></td>
          <td>
            {index === 0 && (
              <b>
                <FormattedMessage id="GENERAL.SUMMARY" />:
              </b>
            )}
          </td>
          <td>{row.sum}</td>
          <td>1 {row.currencyName}</td>
        </tr>
      ))}
    </>
  );
}
