import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { PartnerAssigningNumberForm } from "./PartnerAssigningNumberForm";

export function PartnerGeneralCard(props) {
  const { mode, activeFarm, onAssigningPartnerNumber } = props;
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
          </span>
          <h3 className="card-label">{activeFarm.fullName}</h3>
        </div>
      </div>
      <div className="card-body">
        <PartnerAssigningNumberForm
          mode={mode}
          activeFarm={activeFarm}
          onAssigningPartnerNumber={onAssigningPartnerNumber}
        />
      </div>
    </div>
  );
}
