import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { deleteAccount } from "../_redux/authCrud";
import SVG from "react-inlinesvg";

function DeleteAccount(props) {
  const [tokenStatus, setTokenStatus] = useState();

  const onEnteringPage = () => {
    let feedbackTimeout;
    let redirectTimeout;
    const params = new URLSearchParams(props.location.search);
    const id = params.get("id");
    const key = params.get("key");
    deleteAccount(id, key)
      .then(() => {
        setTokenStatus("deleted");
        localStorage.removeItem("i18nConfig");
      })
      .catch(() => {
        setTokenStatus("error");
        localStorage.removeItem("i18nConfig");
      });

    return () => {
      clearTimeout(feedbackTimeout);
      clearTimeout(redirectTimeout);
    };
  };

  useEffectOnce(onEnteringPage);

  return (
    <div className="auth__form">
      <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
        <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
      </div>
      <div className="auth__heading">
        <h3>
          <FormattedMessage id="AUTH.DELETE_ACCOUNT" />
        </h3>
      </div>
      <div className="text-center mb-10 mb-lg-10" style={{ padding: "0 40px" }}>
        {tokenStatus === "deleted" && (
          <FormattedMessage id="AUTH.ACCOUNT_DELETED" />
        )}
        {tokenStatus === "error" && (
          <FormattedMessage id="AUTH.DELETE_ACCOUNT_ERROR_OR_TOKEN_NOT_VALID" />
        )}
        .
      </div>
      <div>
        <a
          href="/auth/login"
          className="text-dark-50 text-hover-primary my-3 mr-2"
          id="kt_login_forgot"
        >
          <FormattedMessage id="AUTH.GO_TO_LOGIN" />
        </a>
      </div>
    </div>
  );
}

export default DeleteAccount;
