import React from "react";
import { usePreferences } from "../../../hooks/usePreferences";
import { useSubscriptionRole } from "../../../hooks/useSubscriptionRole";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import MapsPage from "./MapsPage";
import GrowersPage from "./GrowersPage";
import DocumentsPage from "./DocumentsPage";
import CropManagerDashboard from "./CropManagerDashboard";
import AgronomistsPage from "./AgronomistsPage";

export function CropManagerPage() {
  const showCropManager = usePreferences("CROPMANAGER");
  const isAdmin = useSubscriptionRole(["CM", "CS", "CP", "CE"], "ROLE_ADMIN");
  const isAgronomistExtend = useSubscriptionRole(["CM", "CS", "CP", "CE"], "ROLE_AGRONOMIST_EXTEND");

  return (
    <>
      {showCropManager !== null && (
        <>
          <Switch>
            {showCropManager && (isAdmin || isAgronomistExtend) ? (
              <>
                <ContentRoute
                  path="/crop-manager/dashboard"
                  component={CropManagerDashboard}
                />
                <ContentRoute
                  path="/crop-manager/shared-farmland/:type?/:id?"
                  component={MapsPage}
                />
                <ContentRoute
                  path="/crop-manager/growers/:id?"
                  component={GrowersPage}
                />
                <ContentRoute
                  path="/crop-manager/documents"
                  component={DocumentsPage}
                />
                <ContentRoute
                  path="/crop-manager/agronomists"
                  component={AgronomistsPage}
                />
              </>
            ) : (
              <Redirect to="/dashboard" />
            )}
          </Switch>
        </>
      )}
    </>
  );
}
