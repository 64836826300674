import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { WorkerDataForm } from "./WorkerDataForm";
import { WorkerOperationsContainer } from "./WorkerOperationsContainer";

export function RegisterOperationCard({
  formData,
  activeWorker,
  operations,
  getWorkerOperations,
  createReceivableTransaction,
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.REGISTER_OPERATION" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <WorkerDataForm activeWorker={activeWorker} />
        {activeWorker && (
          <WorkerOperationsContainer
            formData={formData}
            operations={operations}
            getWorkerOperations={getWorkerOperations}
            createReceivableTransaction={createReceivableTransaction}
          />
        )}
      </div>
    </div>
  );
}
