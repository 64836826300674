import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link, useParams } from "react-router-dom";
import { getSubscriptionPriceList } from "../../../services/paymentsCrud";
import errorHandler from "../../../shared/errorHandler";
import { useIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./pricing/Step1";
import Step2 from "./pricing/Step2";
import Step3 from "./pricing/Step3";
import Step4 from "./pricing/Step4";
import displayFeedback from "../../../components/helpers/displayFeedback";

function PricingCard(props) {
  const step = useSelector((state) => state.pricing.step);
  const dispatch = useDispatch();

  const setPreviousStep = () => {
    dispatch({
      type: "pricing/setStep",
      payload: {
        step: step - 1,
      },
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          {step === 1 && (
            <Link to="/user/subscriptions">
              <button className="btn ml-n2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Left-2.svg")}
                />
              </button>
            </Link>
          )}
          {step > 1 && (
            <button className="btn ml-n2" onClick={() => setPreviousStep()}>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Left-2.svg")}
              />
            </button>
          )}
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.BUY_SUBSCRIPTION" />
            &nbsp;-&nbsp;
            {step === 1 && <FormattedMessage id="PRICING.PLAN_SELECTION" />}
            {step === 2 && <FormattedMessage id="PRICING.ADDITIONAL_INFO" />}
            {step === 3 && <FormattedMessage id="GENERAL.ADDRESS" />}
            {step === 4 && <FormattedMessage id="GENERAL.SUMMARY" />}
          </h3>
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default function ApplicationPricing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const priceList = useSelector((state) => state.pricing.priceList);
  const selectedApplication = useSelector(
    (state) => state.pricing.selectedApplication
  );
  const step = useSelector((state) => state.pricing.step);
  const [loading, setLoading] = useState(false);

  let { applicationId } = useParams();

  useEffect(() => {
    setLoading(true);
    getSubscriptionPriceList()
      .then((response) => {
        dispatch({
          type: "pricing/setPriceList",
          payload: {
            priceList: response.data,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  }, [dispatch, intl]);

  useEffect(() => {
    if (priceList && priceList.length > 0) {
      dispatch({
        type: "pricing/setSelectedApplication",
        payload: {
          selectedApplication: priceList.find(
            (app) => app.applicationId === Number(applicationId)
          ),
        },
      });
    }
  }, [priceList, applicationId, dispatch]);

  if (selectedApplication)
    return (
      <>
        <PricingCard>
          {step === 1 && <Step1 />}
          {step === 2 && <Step2 />}
          {step === 3 && <Step3 />}
          {step === 4 && <Step4 />}
        </PricingCard>
      </>
    );
  else if (loading)
    return (
      <div className="d-flex justify-content-center mt-10">
        <CircularProgress />
      </div>
    );
  else
    return (
      <PricingCard>
        <h6 className="text-center my-10">
          <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
        </h6>
      </PricingCard>
    );
}
