import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export function AcceptOrCancelTreatmentModal({
  open,
  close,
  onAcceptTreatment,
  onCancelTreatment,
}) {
  const [loading, setLoading] = useState(false);

  async function handleAcceptClick() {
    setLoading(true);
    const callback = await onAcceptTreatment();
    if (callback === "OK") {
      setLoading(false);
      close();
    }
  }

  async function handleRejectClick() {
    setLoading(true);
    const callback = await onCancelTreatment();
    if (callback === "OK") {
      setLoading(false);
      close();
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="GENERAL.ACCEPT_OR_REJECT_TREATMENT" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="GENERAL.ACCEPT_OR_REJECT_TREATMENT_MESSAGE" />
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={loading}
            onClick={handleAcceptClick}
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={handleRejectClick}
          >
            <FormattedMessage id="GENERAL.REJECT" />
          </Button>
          <Button color="default" disabled={loading} onClick={close}>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// export default function TodoModal(props) {
//   function handleClose() {
//     props.close();
//   }

//   function handleClick() {
//     props.accept();
//   }

//   return (
//     <div>
//       <Dialog
//         open={props.open}
//         onClose={handleClose}
//         scroll={"paper"}
//         aria-labelledby="scroll-dialog-title"
//       >
//         <DialogTitle id="scroll-dialog-title">To Do</DialogTitle>
//         <DialogContent dividers={true}>
//           <DialogContentText>
//             Do you want to mark the task completed?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           {props.acceptVisible && (
//             <Button onClick={handleClick} color="primary">
//               Accept
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
