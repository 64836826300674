import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export function GenerateWorkerNewLabelModal({
  open,
  close,
  onGeneratingNewEmployeeLabel,
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  async function onGeneratingLabel() {
    setLoading(true);
    const callback = await onGeneratingNewEmployeeLabel();
    callback === "OK" ? onClosingModal() : setLoading(false);
  }

  const onClosingModal = () => {
    setLoading(false);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.GENERATE_NEW_WORKER_LABEL" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="GENERAL.GENERATE_NEW_WORKER_LABEL_MESSAGE" />?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          onClick={() => onGeneratingLabel()}
          disabled={loading}
        >
          <FormattedMessage id="GENERAL.ACCEPT" />
        </Button>
        <Button
          color="default"
          disabled={loading}
          onClick={() => onClosingModal()}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
