import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { PackagingTable } from "../index";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "react-bootstrap";

export function PackagingTableCard({
  mode,
  packaging,
  onSelectingRow,
  onChangingMode,
}) {
  const intl = useIntl();
  const [filter, setFilter] = useState("");
  const [checked, setChecked] = useState(true);
  const [filteredData, setFilteredData] = useState(packaging || []);

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packaging, checked]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (packaging.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return packaging.filter((bundle) => {
      const nameIncludes = bundle.name
        .toLowerCase()
        .includes(text.toLowerCase());
      const quantityIncludes = bundle.quantity
        ? bundle?.quantity
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase())
        : false;
      const uomIncludes = bundle.uom.symbol
        .toLowerCase()
        .includes(text.toLowerCase());
      if (checked) {
        if (
          (nameIncludes || quantityIncludes || uomIncludes) &&
          bundle.isActive
        ) {
          return bundle;
        }
      } else {
        if (nameIncludes || quantityIncludes || uomIncludes) {
          return bundle;
        }
      }
    });
  };

  const handleChange = (event) => {
    setChecked(event.currentTarget.checked);
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {packaging.length === 0 ? (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_PACKAGING" />
            </h6>
          </div>
        ) : (
          <div className="card__table card__table--brigadier">
            <PackagingTable
              data={filteredData}
              onSelectingRow={onSelectingRow}
            />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="showActive"
              />
            }
            label={`${intl.formatMessage({
              id: "GENERAL.SHOW_ACTIVE_ONLY",
            })}`}
          />
          <Button disabled={mode === "create"} onClick={() => onChangingMode()}>
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
        </div>
      </div>
    </div>
  );
}
