import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Form, InputGroup, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSelector } from "react-redux";
import InfoTooltip from "./InfoTooltip";

export const AddNewAgronomistForm = ({ onAddingAgronomist }) => {
  const intl = useIntl();
  const { register, errors, handleSubmit, reset, formState } = useForm({
    mode: "onMouseLeave",
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  async function onSubmit(data) {
    setLoading(true);
    const callback = await onAddingAgronomist(data);
    if (callback === "OK") {
      setLoading(false);
      reset();
    } else {
      setLoading(false);
    }
  }

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon svg-icon-dark-75 menu-icon mr-4">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="CROPMANAGER.ADD_NEW_AGRONOMIST" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group className="mb-0">
              <Form.Label>
                <FormattedMessage id="GENERAL.LOGIN" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="login"
                  type="text"
                  placeholder={`${intl.formatMessage({
                    id: "GENERAL.LOGIN",
                  })}`}
                  className={`${
                    formState.touched.login && errors.login ? "is-invalid" : ""
                  }${formState.touched.login && !errors.login ? "is-valid" : ""}`}
                  ref={register({
                    required: true,
                    minLength: 7,
                    validate: value => value !== user?.login
                  })}
                />
              </InputGroup>
              {errors.login?.type === "required" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.login?.type === "minLength" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.INCORRECT_LOGIN_LENGTH" />
                </div>
              )}
              {errors.login?.type === "validate" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.CANNOT_ADD_YOURSELF_AS_AGRONOMIST" />
                </div>
              )}
              <Form.Check type="checkbox" id="isExtend" className="mt-6">
                <Form.Check.Input type="checkbox" name="isExtend" ref={register} />
                <Form.Check.Label className="ml-2">
                  <span><FormattedMessage id="ROLE.BRIGADIER_EXTEND" /></span>
                  <InfoTooltip />
                </Form.Check.Label>
              </Form.Check>
              <Button type="submit" disabled={loading} className="mt-6">
                <FormattedMessage id="GENERAL.ADD" />
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
};
