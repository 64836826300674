import React, { useState } from "react";
import { Switch } from "react-router-dom";
import { useIntl } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import { ContentRoute } from "../../_metronic/layout";
import {
  getProfileToCurrentLogin,
  updateProfileToCurrentLogin,
} from "../services/usersCrud";
import { updateOwnBPartners } from "../services/businessPartnersCrud";
import {
  getDataSharingForBPartner,
  getOwnCroppingExclusive,
  acceptFarmSharingData,
  rejectFarmSharingData,
  createIsExclusivePlanter,
  acceptIsExclusiveByPlanter,
  rejectIsExclusiveByPlanter,
  getOwnWaitingFieldToExclusive,
  getDataSharingFieldToBPartner,
} from "../services/farmSharingCrud";
import {
  getSubscriptionToLoginAdmin,
  getSubscriptionToLogin,
} from "../services/subscriptionsCrud";
import {
  NavigationList,
  ProfilePage,
  FarmPage,
  ProvidedInformationPage,
  SubscriptionsPage,
  ManualsPage,
  HelpCenterPage,
} from "../modules/user";
import { Row, Col } from "react-bootstrap";
import { getAllApplications } from "../services/applicationCrud";
import OrdersPage from "../modules/user/pages/OrdersPage";
import displayFeedback from "../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../components/helpers/displayFeedbackFromHeaders";

export function UserPage() {
  const intl = useIntl();
  const [profileData, setProfileData] = useState();
  const [sharedData, setSharedData] = useState();
  const [exclusiveRequests, setExclusiveRequests] = useState();
  const [sharedFieldData, setSharedFieldData] = useState([]);
  const [exclusiveFieldRequests, setExclusiveFieldRequests] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [
    sharedWithUserSubscriptions,
    setSharedWithUserSubscriptions,
  ] = useState([]);
  const [applications, setApplications] = useState();

  const getProfile = () => {
    getProfileToCurrentLogin()
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getSharedData = () => {
    getDataSharingForBPartner(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setSharedData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getExclusiveRequests = () => {
    getOwnCroppingExclusive(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setExclusiveRequests(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getSharedFieldData = () => {
    getDataSharingFieldToBPartner(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setSharedFieldData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getExclusiveFieldsRequests = () => {
    getOwnWaitingFieldToExclusive(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setExclusiveFieldRequests(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getUserSubscriptions = () => {
    getSubscriptionToLoginAdmin()
      .then((response) => {
        setUserSubscriptions(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getSharedWithUserSubscriptions = () => {
    getSubscriptionToLogin()
      .then((response) => {
        setSharedWithUserSubscriptions(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const updateProfile = (form) => {
    return new Promise((resolve) => {
      updateProfileToCurrentLogin(form)
        .then(() => {
          displayFeedback({
            type: "success",
            message: intl.formatMessage({
              id: "FEEDBACK.ENTERED_DATA_SAVED_SUCCESSFULLY",
            }),
          });
          resolve("OK");
          getProfile();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const updateFarmProfile = (form) => {
    return new Promise((resolve) => {
      updateOwnBPartners(form)
        .then(() => {
          resolve("OK");
          getProfile();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const acceptSharedData = (farmSharingId) => {
    return new Promise((resolve) => {
      acceptFarmSharingData(farmSharingId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getSharedData();
          getSharedFieldData();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const rejectSharedData = (farmSharingId) => {
    return new Promise((resolve) => {
      rejectFarmSharingData(farmSharingId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getSharedData();
          getSharedFieldData();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const requestExclusivitySharedData = (farmSharingId) => {
    return new Promise((resolve) => {
      createIsExclusivePlanter(farmSharingId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const acceptExclusiveRequest = (farmSharingId) => {
    return new Promise((resolve) => {
      acceptIsExclusiveByPlanter(farmSharingId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getExclusiveRequests();
          getExclusiveFieldsRequests();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const rejectExclusiveRequest = (farmSharingId) => {
    return new Promise((resolve) => {
      rejectIsExclusiveByPlanter(farmSharingId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getExclusiveRequests();
          getExclusiveFieldsRequests();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const getApplications = () => {
    getAllApplications()
      .then((response) => {
        setApplications(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getProfile);
  useEffectOnce(getSharedData);
  useEffectOnce(getExclusiveRequests);
  useEffectOnce(getSharedFieldData);
  useEffectOnce(getExclusiveFieldsRequests);
  useEffectOnce(getUserSubscriptions);
  useEffectOnce(getSharedWithUserSubscriptions);
  useEffectOnce(getApplications);

  return (
    <Row>
      <Col md={12} lg={4} xxl={4}>
        <NavigationList profile={profileData} />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <Switch>
          <ContentRoute
            path="/user/profile"
            render={(props) => (
              <ProfilePage
                profile={profileData}
                updateProfile={updateProfile}
              />
            )}
          />
          <ContentRoute
            path="/user/farm"
            render={(props) => (
              <FarmPage
                profile={profileData}
                updateFarmProfile={updateFarmProfile}
              />
            )}
          />
          <ContentRoute
            path="/user/provided-information"
            render={(props) => (
              <ProvidedInformationPage
                sharedFieldData={sharedFieldData}
                sharedData={sharedData}
                acceptSharedData={acceptSharedData}
                rejectSharedData={rejectSharedData}
                exclusiveFieldRequests={exclusiveFieldRequests}
                exclusiveRequests={exclusiveRequests}
                requestExclusivitySharedData={requestExclusivitySharedData}
                acceptExclusiveRequest={acceptExclusiveRequest}
                rejectExclusiveRequest={rejectExclusiveRequest}
              />
            )}
          />
          <ContentRoute
            path="/user/subscriptions"
            render={(props) => (
              <SubscriptionsPage
                userSubscriptions={userSubscriptions}
                sharedWithUserSubscriptions={sharedWithUserSubscriptions}
                applications={applications}
              />
            )}
          />
          <ContentRoute path="/user/help" render={() => <HelpCenterPage />} />
          <ContentRoute path="/user/manuals" render={() => <ManualsPage />} />
          <ContentRoute path="/user/orders" render={() => <OrdersPage />} />
        </Switch>
      </Col>
    </Row>
  );
}
