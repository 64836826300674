/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSubscriptionRole } from "../../../../app/hooks/useSubscriptionRole";
import usePulpExchangePermission from "../../../../app/hooks/usePulpExchangePermission";
import objectPath from "object-path";
import { useLocation, NavLink } from "react-router-dom";
// import {QuickActions} from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { checkIsActive } from "../../../_helpers";
import { ModuleName } from "../../../../app/components/ModuleName";
import { useSelector } from "react-redux";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const isAdmin = useSubscriptionRole(["BT", "BB", "BS", "BP"], "ROLE_ADMIN");
  const isExtendedOverseer = useSubscriptionRole(
    ["BT", "BB", "BS", "BP"],
    "ROLE_BRIGADIER_EXTEND"
  );
  const isPlanter = usePulpExchangePermission("ROLE_PLANTER");
  const isMaterialRepresentat = usePulpExchangePermission(
    "ROLE_MATERIAL_REPRESENTANT"
  );
  const isCMAdmin = useSubscriptionRole(["CM", "CS", "CP", "CE"], "ROLE_ADMIN");
  const isCMAgronomistExtend = useSubscriptionRole(
    ["CM", "CS", "CP", "CE"],
    "ROLE_AGRONOMIST_EXTEND"
  );

  const cropManagerLinks = [
    {
      to: "/crop-manager/dashboard",
      intlText: "MENU.DASHBOARD",
      show: isCMAdmin || isCMAgronomistExtend,
    },
    {
      to: "/crop-manager/shared-farmland",
      intlText: "MENU.SHARED_FARMLAND",
      show: isCMAdmin || isCMAgronomistExtend,
    },
    {
      to: "/crop-manager/growers",
      intlText: "MENU.GROWERS",
      show: isCMAdmin,
    },
    {
      to: "/crop-manager/documents",
      intlText: "MENU.DOCUMENTS",
      show: isCMAdmin,
    },
    {
      to: "/crop-manager/agronomists",
      intlText: "MENU.AGRONOMISTS",
      show: isCMAdmin,
    },
  ];

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const isMobile = useSelector((state) => state.misc.isMobile);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      {/* justify-content-between */}
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-evenly flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <ModuleName />
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>

        {/* Toolbar */}
        {/* <div className="d-flex align-items-center">
            <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
            </a>
            <QuickActions/>
          </div> */}
        {!isMobile && (
          <div className="d-flex align-items-center header-menu">
            {location.pathname.includes("brigadier") && (
              <ul className={`menu-nav`}>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/dashboard"
                  )}`}
                >
                  <NavLink className="menu-link" to="/brigadier/dashboard">
                    <span className="menu-text">
                      <FormattedMessage id="MENU.DASHBOARD" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/results"
                  )}`}
                >
                  <NavLink className="menu-link" to="/brigadier/results">
                    <span className="menu-text">
                      <FormattedMessage id="MENU.RESULTS" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/worktime"
                  )}`}
                >
                <NavLink className="menu-link" to="/brigadier/worktime">
                    <span className="menu-text">
                      <FormattedMessage id="MENU.WORK_TIME" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/receivables"
                  )}`}
                >
                  <NavLink className="menu-link" to="/brigadier/receivables">
                    <span className="menu-text">
                      <FormattedMessage id="MENU.RECEIVABLES" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/folders/workers"
                  )}`}
                >
                  <NavLink
                    className="menu-link"
                    to="/brigadier/folders/workers"
                  >
                    <span className="menu-text">
                      <FormattedMessage id="MENU.WORKERS" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/folders/packaging"
                  )}`}
                >
                  <NavLink
                    className="menu-link"
                    to="/brigadier/folders/packaging"
                  >
                    <span className="menu-text">
                      <FormattedMessage id="MENU.PACKAGING" />
                    </span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/brigadier/folders/tariffs"
                  )}`}
                >
                  <NavLink
                    className="menu-link"
                    to="/brigadier/folders/tariffs"
                  >
                    <span className="menu-text">
                      <FormattedMessage id="MENU.TARIFFS" />
                    </span>
                  </NavLink>
                </li>
                {(isAdmin || isExtendedOverseer) && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/brigadier/settings"
                    )}`}
                  >
                    <NavLink className="menu-link" to="/brigadier/settings">
                      <span className="menu-text">
                        <FormattedMessage id="MENU.SETTINGS" />
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            )}
            {location.pathname.includes("crop-manager") && (
              <ul className={"menu-nav"}>
                {cropManagerLinks.map((link, index) => (
                  <React.Fragment key={`subheader-link-cm-${index}`}>
                    {link.show && (
                      <li className={`menu-item ${getMenuItemActive(link.to)}`}>
                        <NavLink className="menu-link" to={link.to}>
                          <span className="menu-text">
                            <FormattedMessage id={link.intlText} />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            )}
            {location.pathname.includes("pulp-exchange") && (
              <ul className={`menu-nav`}>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/pulp-exchange/offers"
                  )}`}
                >
                  <NavLink className="menu-link" to="/pulp-exchange/offers">
                    <span className="menu-text">
                      <FormattedMessage id="MENU.OFFERS" />
                    </span>
                  </NavLink>
                </li>
                {isPlanter && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/pulp-exchange/growers"
                    )}`}
                  >
                    <NavLink
                      className="menu-link menu-item-rel"
                      to="/pulp-exchange/growers"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="MENU.GROWERS" />
                      </span>
                    </NavLink>
                  </li>
                )}
                {isMaterialRepresentat && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/pulp-exchange/stock-department"
                    )}`}
                  >
                    <NavLink
                      className="menu-link"
                      to="/pulp-exchange/stock-department"
                    >
                      <span className="menu-text">
                        <FormattedMessage id="MENU.STOCK_DEPARTMENT" />
                      </span>
                    </NavLink>
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
