import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getAllPayments } from "../../../services/paymentsCrud";
import errorHandler from "../../../shared/errorHandler";
import { useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import { Button, Table } from "react-bootstrap";
import SelectedOrder from "../components/orders/SelectedOrder";
import getPaymentStatus from "../components/orders/helpers/getPaymentStatus";
import CanceledStateInfoTooltip from "../components/orders/components/CanceledStateInfoTooltip";
import { Link } from "react-router-dom";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function OrdersPage() {
  const [payments, setPayments] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const intl = useIntl();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  useEffect(() => {
    setLoading(true);
    getAllPayments()
      .then((response) => {
        setPayments(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
        setLoading(false);
      });
  }, [intl]);

  useEffect(() => {
    if (payments && payments.length === 1) {
      setBackButtonDisabled(true);
      setSelectedPayment(payments[0]);
    }
  }, [payments]);

  if (!loading && !selectedPayment && payments?.length > 0)
    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <span className="agrico-svg-icon svg-icon menu-icon mr-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}
                />
              </span>
              <h3 className="card-label">
                <FormattedMessage id="GENERAL.ORDERS" />
              </h3>
            </div>
          </div>
          <div className="card-body">
            {payments && (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">
                      <FormattedMessage id="ORDERS.ORDER_NUMBER" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="ORDERS.SUBSCRIPTION_NAME" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="ORDERS.PAYMENT_STATUS" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="GENERAL.AMOUNT" />
                    </th>
                    <th className="text-center">
                      <FormattedMessage id="GENERAL.DETAILS" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment, index) => (
                    <tr key={`ordersPage-order-${index}`}>
                      <td className="text-center align-middle">{payment.id}</td>
                      <td className="text-center align-middle">
                        {payment.subscription.desc}
                      </td>
                      <td className="text-center align-middle">
                        {intl.formatMessage({ id: getPaymentStatus(payment) })}
                        {getPaymentStatus(payment) ===
                          "ORDERS.PAYMENT_STATUS.CANCELLED" && (
                          <CanceledStateInfoTooltip />
                        )}
                      </td>
                      <td className="text-center align-middle">
                        {payment.amount.toLocaleString(languageCode, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        {payment.currency}
                      </td>
                      <td className="text-center align-middle">
                        <Button
                          size="sm"
                          onClick={() => setSelectedPayment(payment)}
                        >
                          <FormattedMessage id="GENERAL.DETAILS" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {!payments && (
              <h6 className="text-center">
                <FormattedMessage id="ORDERS.NO_ORDERS" />
              </h6>
            )}
          </div>
        </div>
      </>
    );
  else if (!loading && !selectedPayment && payments?.length === 0)
    return (
      <>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <span className="agrico-svg-icon svg-icon menu-icon mr-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}
                />
              </span>
              <h3 className="card-label">
                <FormattedMessage id="GENERAL.ORDERS" />
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="symbol symbol-50 symbol-primary d-flex justify-content-center">
              <span className="symbol-label font-weight-bold rounded-circle">
                <i
                  className="fa fa-info-circle"
                  style={{ color: "inherit", fontSize: "2.5rem" }}
                />
              </span>
            </div>
            <h3 className="text-center mt-4 mb-8">
              <FormattedMessage id="ORDERS.NO_REGISTERED_ORDERS" />
            </h3>
            <p className="text-center">
              <FormattedMessage id="ORDERS.CHOOSE_SUBSCRIPTION_FOR_YOU" />:
            </p>
            <Link to="/user/subscriptions" className="d-table mx-auto">
              <Button>
                <FormattedMessage id="PRICING.BUY_NOW" />
              </Button>
            </Link>
          </div>
        </div>
      </>
    );
  else if (!loading && selectedPayment)
    return (
      <SelectedOrder
        payment={selectedPayment}
        goBack={() => setSelectedPayment(null)}
        backButtonDisabled={backButtonDisabled}
      />
    );
  else
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
}

export default OrdersPage;
