/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ActivateAccount from "./ActivateAccount";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/login-1.scss";
import DeleteAccount from "./DeleteAccount";
import DeleteAccountInfo from "./DeleteAccountInfo";
import InlineSVG from "react-inlinesvg";

export function AuthPage() {
  const today = new Date().getFullYear();
  const desktopImage = toAbsoluteUrl("/media/bg/background__desktop.png");

  return (
    <>
      <div
        className="d-flex flex-column flex-root auth__background"
        style={{ backgroundImage: `url(${desktopImage})` }}
      >
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="auth__panel">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/activate-account"
                  component={ActivateAccount}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/delete-account"
                  component={DeleteAccount}
                />
                <ContentRoute
                  path="/auth/delete-account-info"
                  component={DeleteAccountInfo}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
              </Switch>
            </div>
          </div>
          <div className="d-flex flex-column-auto justify-content-between mt-10">
            <div className="auth__footer d-flex flex-row align-items-center justify-content-center text-dark">
              <span className="auth__text--normal">
                &copy; {today.toString()}{" "}
              </span>
              <a
                href="https://seth.software"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-4"
              >
                <InlineSVG
                  src={toAbsoluteUrl("/media/logos/logo-seth.svg")}
                  style={{ height: "25px", width: "auto" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
