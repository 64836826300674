import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Form, Col, InputGroup, Button } from "react-bootstrap";

export const InviteNewFarmMemberForm = ({ formData, sendInvitation }) => {
  const intl = useIntl();
  const { register, errors, handleSubmit, reset } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    setLoading(true);
    const callback = await sendInvitation(data);
    if (callback === "OK") {
      setLoading(false);
      reset();
    } else {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} sm={5}>
          <Form.Label>
            <FormattedMessage id="GENERAL.LOGIN" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="login"
              type="text"
              className={`${errors.value ? "is-invalid" : ""} ${
                !errors.value ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.LOGIN",
              })}`}
              ref={register({ required: true })}
            />
          </InputGroup>
          {errors.value?.type === "required" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={5}>
          <Form.Label>
            <FormattedMessage id="GENERAL.ROLE" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="personRoleId"
            ref={register({ required: true })}
          >
            {formData?.personRoles.map((role) => (
              <option key={`role-${role.desc}`} value={role.personRoleId}>
                {role.desc}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          sm={2}
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button type="submit" disabled={loading}>
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
