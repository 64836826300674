import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function SzTransportRodo() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30">



        <div>
          <h1>RODO</h1>
          <div>
            <p>
              <strong>
                {"§1. Administrator danych osobowych"}
              </strong>
              <br /> <br />
              1. Administratorem danych osobowych w rozumieniu art. 4 pkt 7 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dn. 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO) jest Seth Software Spółka z ograniczoną odpowiedzialnością z siedzibą w Głogowie Małopolskim pod adresem ul. Strefowa 1, 36-060 Głogów Małopolski, NIP: 8132709001, REGON: 690512599, KRS: 0000114098, sąd rejestrowy: Sąd Rejonowy w Rzeszowie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, kapitał zakładowy 50.000 zł.
              <br /><br />
              2. Dane kontaktowe administratora danych: adres e-mail  <a href="mailto:sales@cropchart.net">sales@cropchart.net</a>
              <br /><br />
              3. Administrator w myśl art. 32 ust. 1 RODO przestrzega zasady ochrony danych osobowych oraz stosuje odpowiednie środki techniczne i organizacyjne w celu zapobieżenia przypadkowego lub niezgodnego z prawem zniszczenia, utraty, modyfikacji, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przetwarzanych w związku z prowadzoną działalnością.
              <br /><br />
              4. Podanie danych osobowych jest dobrowolne, ale niezbędne w celu nawiązania współpracy i/lub zawarcia umowy z administratorem danych.
              <br /><br />
              5. Administrator danych przetwarza dane osobowe wyłącznie w zakresie wymaganym do prawidłowej realizacji usługi na rzecz osoby, której dane dotyczą.
            </p>

            <p>
              <strong>
                {"§2. Cel i podstawy przetwarzania danych osobowych"}
              </strong>
              <br /><br />
              Administrator przetwarza dane osobowe w następujących celach:
              <br />a) przygotowanie oferty handlowej w odpowiedzi na zainteresowanie klienta, co jest prawnie uzasadnionym interesem administratora danych (art. 6 ust. 1 lit. f RODO);
              <br />b) świadczenie usług za pośrednictwem Portalu internetowego i realizacja zobowiązań umownych, na podstawie zawartej umowy (art. 6 ust. 1 lit. b RODO);
              <br />c) obsługa procesu reklamacyjnego, na podstawie obowiązku ciążącym na administratorze danych w związku z obowiązującymi przepisami prawa (art. 6 ust. 1 lit. c RODO);
              <br />d) rachunkowych związanych z wystawianiem i przyjmowaniem dokumentów rozliczeniowych, na podstawie przepisów prawa podatkowego (art. 6 ust. 1 lit. c RODO);
              <br />e) archiwizacja danych dla ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami lub potrzeby wykazania faktów, co jest prawnie uzasadnionym interesem administratora danych (art. 6 ust. 1 lit f RODO);
              <br />f) kontakt telefoniczny lub za pośrednictwem poczty elektronicznej, w szczególności w odpowiedzi na zapytania kierowane do administratora danych, co jest prawnie uzasadnionym interesem administratora danych (art. 6 ust. 1 lit. f RODO);
              <br />g) przesyłanie informacji technicznych dotyczących funkcjonowania Serwisu internetowego i usług, z których korzysta klient, co jest prawnie uzasadnionym interesem administratora danych (art. 6 ust. 1 lit. f RODO);
              <br />h) marketing, co jest jego prawnie uzasadnionym interesem (art. 6 ust. 1 lit. f RODO) lub odbywa się na podstawie uprzednio udzielonej zgody (art. 6 ust. 1 lit. a RODO).
            </p>
            <p>
              <strong>
                {"§3. Odbiorcy danych. Przekazywanie danych do państw trzecich"}
              </strong>
              <br /><br />
              1. Odbiorcami danych osobowych przetwarzanych przez administratora danych mogą być podmioty współpracujące z administratorem danych, gdy jest to niezbędne do realizacji umowy zawartej z osobą, której dane dotyczą.
              <br /><br />
              2. Odbiorcami danych osobowych przetwarzanych przez administratora danych mogą być również podwykonawcy – podmioty, z których usług korzysta administrator danych przy przetwarzaniu danych np. biura rachunkowe, kancelarie prawne, podmioty świadczące usługi IT (w tym usługi hostingowe).
              <br /><br />
              3. Administrator danych może być zobowiązany do udostepnienia danych osobowych na podstawie obowiązujących przepisów prawa, w szczególności do udostępnienia danych osobowych uprawnionym organom lub instytucjom państwowym.
              <br /><br />
              4. Dane osobowe w związku z wykorzystywaniem przez administratora narzędzi do analizy i śledzenia ruchu w Serwisie internetowym mogą zostać przekazane do podmiotu mającego siedzibę poza Europejskim Obszarem Gospodarczym, np. do Google LLC, Hotjar Ltd. lub Inspectlet Inc. z siedzibą w USA. Jako właściwy środek ochrony danych, administrator danych zgodził się na standardowe klauzule umowne zgodnie z art. 46 RODO z dostawcami tych usług. Więcej informacji na ten temat jest dostępnych tutaj: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_en">https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_en</a>.
            </p>
            <p>
              <strong>
                {"§4. Okres przechowywania danych osobowych"}
              </strong>
              <br /><br />
              1. Administrator danych przechowuje dane osobowe przez okres obowiązywania umowy zawartej z osobą, której dane dotyczą oraz po zakończeniu jej obowiązywania w celach związanych z dochodzeniem roszczeń związanych z umową, wykonania obowiązków wynikających z obowiązujących przepisów prawa, ale przez czas nie dłuższy niż termin przedawnienia zgodnie z przepisami Kodeksu cywilnego.
              <br /><br />
              2. Administrator danych przechowuje dane osobowe znajdujące się na dokumentach rozliczeniowych (np. faktury) przez okres czasu wskazany przepisami ustawy o podatku od towarów i usług oraz ustawą o rachunkowości
              <br /><br />
              3. Administrator danych przechowuje dane osobowe przetwarzane w celach marketingowych przez okres lat 10, jednak nie dalej niż do momentu wycofania zgody na przetwarzanie danych lub wniesienia sprzeciwu wobec przetwarzania danych
              <br /><br />
              4. Administrator danych przechowuje dane osobowe w celach innych niż wskazane w ust. 1-3 przez okres 3 lat, chyba że wcześniej wycofano zgodę na przetwarzanie danych, a przetwarzanie danych nie może być kontynuowane na innej podstawie niż zgoda osoby, której dane dotyczą.
            </p>
            <p>
              <strong>
                {"§5. Uprawnienia osoby, której dane dotyczą"}
              </strong>
              <br /><br />
              1. Każda osoba, której dane dotyczą, ma prawo: 
              <br /> a) dostępu – uzyskania od administratora potwierdzenia, czy przetwarzane są jej dane osobowe. Jeżeli dane o osobie są przetwarzane, jest ona uprawniona do uzyskania dostępu do nich oraz uzyskania następujących informacji: o celach przetwarzania, kategoriach danych osobowych, informacji o odbiorcach lub kategoriach odbiorców, którym dane zostały lub zostaną ujawnione, o okresie przechowywania danych lub o kryteriach ich ustalania, o prawie do żądania sprostowania, usunięcia lub ograniczenia przetwarzania danych osobowych przysługujących osobie, której dane dotyczą, oraz do wniesienia sprzeciwu wobec takiego przetwarzania (art. 15 RODO); 
              <br /> b) do otrzymania kopii danych – uzyskania kopii danych podlegających przetwarzaniu, przy czym pierwsza kopia jest bezpłatna, a za kolejne kopie administrator może nałożyć opłatę w rozsądnej wysokości wynikającą z kosztów administracyjnych (art. 15 ust. 3 RODO); 
              <br /> c) do sprostowania – żądania sprostowania dotyczących jej danych osobowych, które są nieprawidłowe lub uzupełnienia niekompletnych danych (art. 16 RODO); 
              <br /> d) do usunięcia danych – żądania usunięcia jej danych osobowych, jeżeli administrator nie ma już podstawy prawnej do ich przetwarzania lub dane nie są już niezbędne do celów przetwarzania (art. 17 RODO); 
              <br /> e) do ograniczenia przetwarzania – żądania ograniczenia przetwarzania danych osobowych (art. 18 RODO), gdy: - osoba, której dane dotyczą, kwestionuje prawidłowość danych osobowych – na okres pozwalający administratorowi sprawdzić prawidłowość tych danych, - przetwarzanie jest niezgodne z prawem, a osoba, której dane dotyczą, sprzeciwia się ich usunięciu, żądając ograniczenia ich wykorzystywania, - administrator nie potrzebuje już tych danych, ale są one potrzebne osobie, której dane dotyczą, do ustalenia, dochodzenia lub obrony roszczeń, - osoba, której dane dotyczą, wniosła sprzeciw wobec przetwarzania – do czasu stwierdzenia, czy prawnie uzasadnione podstawy po stronie administratora są nadrzędne wobec podstaw sprzeciwu osoby, której dane dotyczą; 
              <br /> f) do przenoszenia danych – otrzymania w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego danych osobowych jej dotyczących, które dostarczyła administratorowi, oraz żądania przesłania tych danych innemu administratorowi, jeżeli dane są przetwarzane na podstawie zgody osoby, której dane dotyczą lub umowy z nią zawartej oraz jeżeli dane są przetwarzane w sposób zautomatyzowany (art. 20 RODO); 
              <br /> g) do sprzeciwu – wniesienia sprzeciwu wobec przetwarzania jej danych osobowych w prawnie uzasadnionych celach administratora, z przyczyn związanych z jej szczególną sytuacją, w tym wobec profilowania. Wówczas administrator dokonuje oceny istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności osób, których dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń. Jeżeli zgodnie z oceną interesy osoby, której dane dotyczą, będą ważniejsze od interesów administratora, administrator będzie zobowiązany zaprzestać przetwarzania danych w tych celach (art. 21 RODO).
              <br /><br />
              2. Aby skorzystać z wyżej wymienionych praw, osoba, której dane dotyczą, powinna skontaktować się, wykorzystując podane dane kontaktowe, z administratorem i poinformować go, z którego prawa i w jakim zakresie chce skorzystać.
              <br /><br />
              3. Osoba, której dane dotyczą, ma prawo wnieść skargę do organu nadzoru, którym jest Prezes Urzędu Ochrony Danych Osobowych w Warszawie.
            </p>
            <p>
              <strong>
                {"§6. Zautomatyzowane podejmowanie decyzji. Profilowanie"}
              </strong>
              <br /><br />
              Dane osobowe nie będą przetwarzane w sposób automatyczny, w tym poprzez profilowanie.
            </p>
            <p>
              <strong>
                {"§7. Google Analytics"}
              </strong>
              <br /><br />
              1. Administrator używa Google Analytics, internetową usługę analityczną dostarczaną przez Google Inc. z siedzibą w USA.
              <br /><br />
              2. Google Analytics używa plików cookie, które umożliwiają analizę korzystania przez użytkownika ze strony internetowej. Informacje wytworzone przez plik cookie na temat korzystania ze strony internetowej są przekazywane i zapisywane na serwerze Google. Na zlecenie Administratora, Google będzie wykorzystywał te informacje, aby przeanalizować korzystanie ze strony przez użytkowników w celu przygotowania raportów na temat aktywności na stronie oraz świadczenia innych usług związanych z korzystaniem ze strony i Internetu na rzecz podmiotu zlecającego.
              <br /><br /> 
              3. Dane nie będą wykorzystywane do celów identyfikacji jakiejkolwiek osoby fizycznej.
              <br /><br /> 
              4. Użytkownik może uniemożliwić zapisywanie plików cookie poprzez odpowiednie ustawienia przeglądarki; jednak w takim wypadku nie będzie mógł korzystać z pełnej funkcjonalności strony. Ponadto użytkownicy mogą uniemożliwić gromadzenie przez Google danych wytworzonych przez pliki cookie oraz odnoszących się do ich korzystania z serwisu internetowego (łącznie z adresem IP) jak i przetwarzanie tych danych przez Google, pobierając i instalując wtyczkę do przeglądarki dostępną pod następującym linkiem: <a href="https://tools.google.com/dlpage/gaoptout?hl=pl">https://tools.google.com/dlpage/gaoptout?hl=pl</a>.
              <br /><br /> 
              5. W każdym momencie użytkownik może zgłosić sprzeciw wobec gromadzenia oraz przetwarzania danych związanych z użytkowaniem strony Google poprzez pobranie oraz instalację wtyczki w przeglądarce, która jest dostępna pod następującym adresem: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
            </p>
            <p>
              <strong>
                {"§8. HotJar"}
              </strong>
              <br /><br />
              1. Administrator korzysta z narzędzia analitycznego HotJar, które śledzi zachowania użytkownika podejmowane w obrębie stron internetowych administratora.
              <br /><br />   
              2. HotJar gromadzi dane nieosobowe, w tym dane standardowego protokołu internetowego i wzorce zachowań, gdy użytkownik odwiedza stronę. Ma to na celu zwiększenie wygody dla użytkownika, określenie preferencji, zdiagnozowanie problemów technicznych, analizę wydarzeń i ulepszenie strony internetowej. Poniższe informacje są gromadzone w odniesieniu do urządzenia i przeglądarki: adres IP urządzenia (jest pobierany i przechowywany anonimowo), rozdzielczość ekranu, rodzaj urządzenia (elementy identyfikacyjne urządzenia), system operacyjny oraz typ przeglądarki, położenie geograficzne (tylko kraj), preferowany język podczas wyświetlania strony internetowej. Poniższe informacje są zbierane w odniesieniu do interakcji użytkownika: obsługa myszy (ruchy, pozycja i kliknięcia), wpisy poprzez klawiaturę.
              <br /><br /> 
              3. HotJar zbiera również dane logowania zebrane przez stronę internetową w sposób losowy: wskazując domenę, odwiedzane strony, lokalizację geograficzną (tylko kraj), preferowany język, datę i godzinę, kiedy strony były przeglądane.
              <br /><br />   
              4. Odwiedzając stronę <a href="https://www.hotjar.com/opt-out">https://www.hotjar.com/opt-out</a> i klikając "Wyłącz HotJar", użytkownik może w dowolnym czasie odrzucić gromadzenie swoich danych poprzez HotJar podczas odwiedzania strony internetowej.                                  
            </p>
            <p>
              <strong>
                {"§9. Inspectlet"}
              </strong>
              <br /><br />
              1. Administrator wykorzystuje na stronach internetowych narzędzia do analityki internetowej udostępniane przez Inspectlet Inc.
              <br /><br />
              2. Pliki Cookies inspectlet.com – służą do określenia sposobu używania strony przez użytkowników; dokładny sposób działania i politykę prywatności można znaleźć pod adresem: <a href="https://www.inspectlet.com/legal">https://www.inspectlet.com/legal</a>.
            </p>
            <p>
              <strong>
                {"§10. Dane zbierane w aplikacji transportowej"}
              </strong>
              <br /><br />
              1. Aplikacja transportowa, w celu realizacji umowy transportowej, zbiera dane związane z aktywnościami użytkownika takie jak: 
              <br />a) Pozycja geograficzna rozładunku, 
              <br />b) numer dostawy, 
              <br />c) data i godzina rozładunku 
              <br />d) lista aplikacji zmieniających współrzędne GPS, 
              <br />e) dane historyczne dotyczące rozładunków.
              <br /><br />
              2. Aplikacja transportowa zapamiętuje następujące dane konfiguracyjne: 
              <br />a) Imię i nazwisko kierowcy, 
              <br />b) zgoda na przetwarzanie danych osobowych, 
              <br />c) Numer telefonu, 
              <br />d) model telefonu
              <br /><br />
              3. Dane z ww. wymienionych punktów przechowywane są przez okres jednego roku od momentu zarejestrowania.
            </p>




          </div>
        </div>

      </div>
    </div>
  );
}
