import { useEffect, useState } from "react";
import store from "../../redux/store";

export function useSubscriptionRole(symbols, role) {
  const [roleExist, setRoleExist] = useState(null);
  const storeState = store.getState();

  useEffect(() => {
    let symbolExist;
    symbols.forEach((symbol) => {
      symbol === storeState.ui?.organization?.symbol && (symbolExist = true);
    });
    if (symbolExist) {
      const indexOfRole = storeState.ui?.userData?.permissions2Orgentity.indexOf(
        role
      );
      indexOfRole !== -1 ? setRoleExist(true) : setRoleExist(false);
    }
  }, [storeState.ui?.userData?.permissions2Orgentity]);

  return roleExist;
}
