const initialState = {
  open: false,
  initialized: false,
  loading: false,
  selectedCroppingId: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  tabKey: "wide",
  cameras: [],
  selectedImage: undefined
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "cropViewModal/setOpen": {
      return {
        ...state,
        open: action.payload,
      };
    }
    case "cropViewModal/setLoading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "cropViewModal/setInitialized": {
      return {
        ...state,
        initialized: action.payload,
      };
    }
    case "cropViewModal/setSelectedCroppingId": {
      return {
        ...state,
        selectedCroppingId: action.payload,
      };
    }
    case "cropViewModal/setDateFrom": {
      return {
        ...state,
        dateFrom: action.payload,
      };
    }
    case "cropViewModal/setDateTo": {
      return {
        ...state,
        dateTo: action.payload,
      };
    }
    case "cropViewModal/setTabKey": {
      return {
        ...state,
        tabKey: action.payload,
      };
    }
    case "cropViewModal/setCameras": {
      return {
        ...state,
        cameras: action.payload,
      };
    }
    case "cropViewModal/setSelectedImage": {
      return {
        ...state,
        selectedImage: action.payload,
      };
    }
    case "cropViewModal/clearSelectedImage": {
      return {
        ...state,
        selectedImage: undefined,
      };
    }
    case "cropViewModal/updateImageComment": {
      return {
        ...state,
        selectedImage: {
          ...state.selectedImage,
          comment: action.payload.comment
        },
        cameras: state.cameras.map(camera => {
          return {
            ...camera,
            images: camera.images.map(image => {
              if (image.imageId === action.payload.imageId) return {
                ...image,
                comment: action.payload.comment
              }
              else return image
            })
          }
        })
      };
    }
    case "cropViewModal/reset": {
      return initialState;
    }
    default:
      return state;
  }
};
