import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function SharedFieldDataTable({
  sharedData,
  acceptSharedData,
  rejectSharedData,
  requestExclusivitySharedData,
}) {
  const [loading, setLoading] = useState();
  const [
    fieldsAcceptedOrRejectedAfterClick,
    setFieldsAcceptedOrRejectedAfterClick,
  ] = useState([]);

  async function acceptData(farmSharingId) {
    setLoading(true);
    const callback = await acceptSharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
    if (callback === "OK") {
      setFieldsAcceptedOrRejectedAfterClick([
        ...fieldsAcceptedOrRejectedAfterClick,
        farmSharingId,
      ]);
    }
  }

  async function rejectData(farmSharingId) {
    setLoading(true);
    const callback = await rejectSharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
    if (callback === "OK") {
      setFieldsAcceptedOrRejectedAfterClick([
        ...fieldsAcceptedOrRejectedAfterClick,
        farmSharingId,
      ]);
    }
  }

  async function requestExclusivityData(farmSharingId) {
    setLoading(true);
    const callback = await requestExclusivitySharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.ITEM" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.FARM_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.FIELD_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CULTIVATION_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.TREATMENT_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.AVAILABLE_EXCLUSIVELY" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {sharedData && (
          <>
            {sharedData.map((bPartner, bPartnerIndex) => (
              <React.Fragment key={`bPartner-${bPartnerIndex}`}>
                {bPartner.fields.map((field, fieldIndex) => (
                  <tr key={`field-${field.fieldId}`}>
                    <td className="text-center">
                      {fieldIndex === 0 && bPartnerIndex + 1}
                    </td>
                    <td className="text-center">
                      {fieldIndex === 0 && bPartner.bpartnerFullName}
                    </td>
                    <td className="text-center">{field.fieldShortName}</td>
                    <td className="text-center">
                      {field.isSpatialDataAllowed ? (
                        <i
                          className="flaticon2-check-mark"
                          style={{ padding: "none", color: "#1BC5BD" }}
                        />
                      ) : (
                        <i
                          className="flaticon2-check-mark text--muted"
                          style={{ padding: "none" }}
                        />
                      )}
                    </td>
                    <td className="text-center">
                      {field.isTreatmentDataAllowed ? (
                        <i
                          className="flaticon2-check-mark"
                          style={{ padding: "none", color: "#1BC5BD" }}
                        />
                      ) : (
                        <i
                          className="flaticon2-check-mark text--muted"
                          style={{ padding: "none" }}
                        />
                      )}
                    </td>
                    <td className="text-center">
                      {field.isExclusiveAccepted ? (
                        <i
                          className="flaticon2-check-mark"
                          style={{ padding: "none", color: "#1BC5BD" }}
                        />
                      ) : (
                        <i
                          className="flaticon2-check-mark text--muted"
                          style={{ padding: "none" }}
                        />
                      )}
                    </td>
                    <td className="text-center">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="user-tooltip">
                              <FormattedMessage id="GENERAL.ACCEPT" />
                            </Tooltip>
                          }
                        >
                          <Button
                            className="mr-2"
                            type="button"
                            variant="primary"
                            onClick={(e) => {
                              acceptData(field.farmSharingId);
                              e.currentTarget.blur();
                            }}
                            disabled={
                              field.isAccepted ||
                              loading ||
                              fieldsAcceptedOrRejectedAfterClick.includes(
                                field.farmSharingId
                              )
                            }
                          >
                            <i className="fas fa-check icon-nm pr-0"></i>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="user-tooltip">
                              <FormattedMessage id="GENERAL.REJECT" />
                            </Tooltip>
                          }
                        >
                          <Button
                            className="mr-2"
                            type="button"
                            variant="secondary"
                            onClick={(e) => {
                              rejectData(field.farmSharingId);
                              e.currentTarget.blur();
                            }}
                            disabled={
                              field.isAccepted ||
                              loading ||
                              fieldsAcceptedOrRejectedAfterClick.includes(
                                field.farmSharingId
                              )
                            }
                          >
                            <i className="fa fa-trash icon-nm pr-0"></i>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="user-tooltip">
                              <FormattedMessage id="GENERAL.REQUEST_EXCLUSIVITY" />
                            </Tooltip>
                          }
                        >
                          <Button
                            type="button"
                            variant="secondary"
                            onClick={() =>
                              requestExclusivityData(field.farmSharingId)
                            }
                            disabled={field.isExclusiveAccepted || loading}
                          >
                            <i className="fas fa-lock icon-nm pr-0"></i>
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
}
