import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Table } from "react-bootstrap";

export function ShareDataTable({
  data,
  activeBusinessPartner,
  changeSpatialDataAllowed,
  addSpatialDataAllowed,
  changeTreatmentDataAllowed,
  addTreatmentDataAllowed,
  disableTreatmentColumn,
  isFields,
}) {
  const { control, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [fieldsCopy, setFieldsCopy] = useState([]);

  useEffect(() => {
    if (activeBusinessPartner) {
      changeCheckboxesValuesToDefault();
    }
  }, [activeBusinessPartner]);

  const changeCheckboxesValuesToDefault = () => {
    const newFieldsCopy = [...data];
    newFieldsCopy.forEach((field) => {
      field.isSpatialDataAllowed = false;
      field.isTreatmentDataAllowed = false;
    });
    changeCheckboxesValuesToStart(newFieldsCopy);
  };

  const changeCheckboxesValuesToStart = (newFieldsCopy) => {
    activeBusinessPartner.fields.forEach((field) => {
      const searchedField = newFieldsCopy.find((f) => {
        return f.fieldId === field.fieldId;
      });
      if (searchedField) {
        searchedField.isSpatialDataAllowed = field.isSpatialDataAllowed;
        searchedField.isTreatmentDataAllowed = field.isTreatmentDataAllowed;
      }
    });
    setFieldsCopy(newFieldsCopy);
  };

  const onChangingSpatialDataAllowedCheckbox = (field) => {
    const newFieldsCopy = [...fieldsCopy];
    const searchedCroppingCopy = newFieldsCopy.find((f) => {
      return f.fieldId === field.fieldId;
    });
    searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
    const searchedCropping = activeBusinessPartner.fields.find((f) => {
      return f.fieldId === field.fieldId;
    });
    searchedCropping
      ? changeSpatialDataAllowed(searchedCropping)
      : addSpatialDataAllowed(field);
    setFieldsCopy(newFieldsCopy);
  };

  const isAnySpatialDataCheckboxChecked = useMemo(() => {
    return !!fieldsCopy.find((field) => field.isSpatialDataAllowed);
  }, [fieldsCopy]);

  const toggleAllSpatialDataAllowedCheckboxes = () => {
    const newFieldsCopy = [...fieldsCopy];
    if (!isAnySpatialDataCheckboxChecked) {
      newFieldsCopy.forEach((f) => {
        if (!f.isExclusive) {
          const searchedCroppingCopy = newFieldsCopy.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
          const searchedCropping = activeBusinessPartner.fields.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedCropping
            ? changeSpatialDataAllowed(searchedCropping)
            : addSpatialDataAllowed(f);
        } else return null;
      });
    } else {
      newFieldsCopy.forEach((f) => {
        if (f.isSpatialDataAllowed) {
          const searchedCroppingCopy = newFieldsCopy.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedCroppingCopy.isSpatialDataAllowed = !searchedCroppingCopy.isSpatialDataAllowed;
          const searchedCropping = activeBusinessPartner.fields.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedCropping
            ? changeSpatialDataAllowed(searchedCropping)
            : addSpatialDataAllowed(f);
        } else return null;
      });
    }
    setFieldsCopy(newFieldsCopy);
  };

  const onChangingTreatmentDataAllowed = (field) => {
    const newFieldsCopy = [...fieldsCopy];
    const searchedFieldCopy = newFieldsCopy.find((f) => {
      return f.fieldId === field.fieldId;
    });
    searchedFieldCopy.isTreatmentDataAllowed = !searchedFieldCopy.isTreatmentDataAllowed;
    const searchedField = activeBusinessPartner.fields.find((f) => {
      return f.fieldId === field.fieldId;
    });
    searchedField
      ? changeTreatmentDataAllowed(searchedField)
      : addTreatmentDataAllowed(field);
    setFieldsCopy(newFieldsCopy);
  };

  const isAnyTreatmentDataCheckboxChecked = useMemo(() => {
    return !!fieldsCopy.find((field) => field.isTreatmentDataAllowed);
  }, [fieldsCopy]);

  const toggleAllTreatmentDataAllowedCheckboxes = () => {
    const newFieldsCopy = [...fieldsCopy];
    if (!isAnyTreatmentDataCheckboxChecked && !disableTreatmentColumn) {
      newFieldsCopy.forEach((f) => {
        if (!f.isExclusive) {
          const searchedFieldCopy = newFieldsCopy.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedFieldCopy.isTreatmentDataAllowed = !searchedFieldCopy.isTreatmentDataAllowed;
          const searchedField = activeBusinessPartner.fields.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedField
            ? changeTreatmentDataAllowed(searchedField)
            : addTreatmentDataAllowed(f);
        } else return null;
      });
    } else {
      newFieldsCopy.forEach((f) => {
        if (f.isTreatmentDataAllowed) {
          const searchedFieldCopy = newFieldsCopy.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedFieldCopy.isTreatmentDataAllowed = !searchedFieldCopy.isTreatmentDataAllowed;
          const searchedField = activeBusinessPartner.fields.find((sf) => {
            return sf.fieldId === f.fieldId;
          });
          searchedField
            ? changeTreatmentDataAllowed(searchedField)
            : addTreatmentDataAllowed(f);
        } else return null;
      });
    }
    setFieldsCopy(newFieldsCopy);
  };

  const onSubmit = (data) => {};

  if (activeBusinessPartner && fieldsCopy.length > 0)
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>
              <FormattedMessage id="GENERAL.SHORT_NAME" />
            </th>
            <th>
              {isFields ? (
                <FormattedMessage id="GENERAL.FIELDS_DATA" />
              ) : (
                <FormattedMessage id="GENERAL.CULTIVATION_DATA" />
              )}
            </th>
            <th>
              <FormattedMessage id="GENERAL.TREATMENT_DATA" />
            </th>
          </tr>
        </thead>
        <tbody onChange={handleSubmit(onSubmit)}>
          <tr>
            <td></td>
            <td>
              <FormControlLabel
                control={
                  <Controller
                    as={<Checkbox />}
                    control={control}
                    checked={isAnySpatialDataCheckboxChecked}
                    name="isSpatialDataAllowed"
                  />
                }
                label={""}
                onClick={() => toggleAllSpatialDataAllowedCheckboxes()}
              />
            </td>
            <td>
              <FormControlLabel
                control={
                  <Controller
                    as={<Checkbox />}
                    control={control}
                    checked={isAnyTreatmentDataCheckboxChecked}
                    name="isTreatmentDataAllowed"
                    disabled={disableTreatmentColumn}
                  />
                }
                onClick={() => toggleAllTreatmentDataAllowedCheckboxes()}
              />
            </td>
          </tr>
          {fieldsCopy.map((field) => (
            <tr key={`field-${field.fieldId}`}>
              <td>{field.shortName}</td>
              <td>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Checkbox />}
                      control={control}
                      checked={field.isSpatialDataAllowed}
                      name="isSpatialDataAllowed"
                      disabled={field.isExclusive}
                    />
                  }
                  label={""}
                  onClick={() => onChangingSpatialDataAllowedCheckbox(field)}
                />
              </td>
              <td>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Checkbox />}
                      control={control}
                      checked={field.isTreatmentDataAllowed}
                      name="isTreatmentDataAllowed"
                      disabled={field.isExclusive || disableTreatmentColumn}
                    />
                  }
                  onClick={() => onChangingTreatmentDataAllowed(field)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  else if (activeBusinessPartner && fieldsCopy.length === 0)
    return (
      <div className="py-10">
        <p className="text-center font-weight-bold">
          <FormattedMessage id="GENERAL.NO_FIELDS" />
        </p>
      </div>
    );
  else
    return (
      <div className="py-10">
        <p className="text-center font-weight-bold">
          <FormattedMessage id="GENERAL.SELECT_FARM" />
        </p>
      </div>
    );
}
