import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Table from "react-bootstrap/Table";

export function OffersSaleOffersTable(props) {
  const [activeFactoryId, setActiveFactorId] = useState();
  const [activeGrowerId, setActiveGrowerId] = useState();
  const [activeOffer, setActiveOffer] = useState();

  const onSelectingFactory = (factoryId) => {
    if (factoryId === activeFactoryId) {
      setActiveFactorId(null);
      setActiveGrowerId(null);
    } else {
      setActiveFactorId(factoryId);
    }
  };

  const onSelectingGrower = (growerId) => {
    if (growerId === activeGrowerId) {
      setActiveGrowerId(null);
    } else {
      setActiveGrowerId(growerId);
    }
  };

  const onSelectingOffer = (offer) => {
    if (props.applicationStatus === "A") {
      if (activeOffer?.sellOfferId === offer.sellOfferId) {
        setActiveOffer(null);
        props.onSelectingOffer(null);
      } else {
        setActiveOffer(offer);
        props.onSelectingOffer(offer);
      }
    }
  };

  return (
    <Table className="mt-5" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th style={{ minWidth: "80px" }}>
            <FormattedMessage id="GENERAL.ID" />
          </th>
          <th>
            <FormattedMessage id="PE.SUGAR_FACTORY" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.GROWER" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.WEIGHT" /> [t]
          </th>
          <th>
            <FormattedMessage id="PE.AVAIABLE_PULP" /> [t]
          </th>
          <th>
            <FormattedMessage id="GENERAL.PRICE" /> [PLN/t]
          </th>
        </tr>
      </thead>
      <tbody>
        {props.offers.map((factory, index) => (
          <React.Fragment key={factory.orgentityId}>
            <tr
              className={`row__factory text--not_selectable ${
                factory.groOwners?.length ? "cursor--pointer" : ""
              }`}
              onClick={() => onSelectingFactory(factory.orgentityId)}
            >
              <td
                className="text-center"
                style={{ paddingLeft: "0", paddingRight: "0" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {factory.groOwners?.length && (
                    <i className="flaticon2-add-1"></i>
                  )}
                  {factory.orgentityId}
                </div>
              </td>
              <td className="text-center">{factory.orgentityDesc}</td>
              <td className="text-center"></td>
              <td className="text-right">
                {factory.weight ? factory.weight.toFixed(2) : ""}
              </td>
              <td className="text-right">
                {factory.weight
                  ? (factory.weight - factory.weightSold).toFixed(2)
                  : ""}
              </td>
              <td className="text-right">
                {factory.averagePrice ? factory.averagePrice.toFixed(2) : ""}
              </td>
            </tr>
            {factory.groOwners && (
              <>
                {factory.groOwners.map((grower) => (
                  <React.Fragment key={grower.groOwnerId}>
                    <tr
                      className={`row__grower text--not_selectable cursor--pointer ${
                        activeFactoryId === factory.orgentityId
                          ? ""
                          : "row--hidden"
                      }`}
                      onClick={() => onSelectingGrower(grower.groOwnerId)}
                    >
                      <td
                        className="text-center"
                        style={{ paddingLeft: "0", paddingRight: "0" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {grower.sellOffers?.length && (
                            <i className="flaticon2-add-1"></i>
                          )}
                          {grower.groOwnerId}
                        </div>
                      </td>
                      <td className="text-center"></td>
                      <td className="text-center">{grower.bpartnerFullName}</td>
                      <td className="text-right"></td>
                      <td className="text-right">
                        {(grower.weight - grower.weightSold).toFixed(2)}
                      </td>
                      <td className="text-right">
                        {grower.minPrice.toFixed(2)} - {grower.maxPrice.toFixed(2)}
                      </td>
                    </tr>
                    {grower.sellOffers.map((offer) => (
                      <tr
                        key={offer.sellOfferId}
                        className={`text--not_selectable cursor--pointer ${
                          activeGrowerId === grower.groOwnerId
                            ? ""
                            : "row--hidden"
                        } ${
                          activeOffer?.sellOfferId === offer.sellOfferId
                            ? "offer--active"
                            : ""
                        }`}
                        onClick={() => onSelectingOffer(offer)}
                      >
                        <td
                          className="text-center"
                          style={{ paddingLeft: "0", paddingRight: "0" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <i
                              className="flaticon2-add-1"
                              style={{ visibility: "hidden" }}
                            ></i>
                            {offer.sellOfferId}
                          </div>
                        </td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-right">
                          {offer.primaryWeight.toFixed(2)}
                        </td>
                        <td className="text-right">
                          {(offer.primaryWeight - offer.weightSold).toFixed(2)}
                        </td>
                        <td className="text-right">{offer.price.toFixed(2)}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
