import axios from "axios";

export const CONTRACTORS_URL = `${process.env.REACT_APP_URL}gw/supCusts`;
export const SELL_OFFER_URL = `${process.env.REACT_APP_URL}gw/sellOffers`;
export const BUY_OFFER_URL = `${process.env.REACT_APP_URL}gw/buyOffers`;

export function getContractorAssignedToOrgentity() {
  return axios.get(`${CONTRACTORS_URL}/assignedOrgentity`);
}

export function sendRequestToEnterTheExchange(form) {
  return axios.post(`${CONTRACTORS_URL}/register`, form);
}

export function getSupCustOffers() {
  return axios.get(`${CONTRACTORS_URL}/buyOffers`);
}

export function getWaitingSupCustToOrgentity() {
  return axios.get(`${CONTRACTORS_URL}/orgentity/waiting`);
}

export function getApprovedSupCustToOrgentity(orgEntityId) {
  return axios.get(`${CONTRACTORS_URL}/orgentity/${orgEntityId}/approved`);
}

export function changeSupCustStatus(supCust2statusId, form) {
  return axios.put(
    `${CONTRACTORS_URL}/supCust2Status/${supCust2statusId}`,
    form
  );
}

export function activeSupCust(supCustId) {
  return axios.put(`${CONTRACTORS_URL}/${supCustId}/active`, null);
}

export function disactiveSupCust(supCustId) {
  return axios.put(`${CONTRACTORS_URL}/${supCustId}/disactive`, null);
}

export function getStatusesToSupCust() {
  return axios.get(`${CONTRACTORS_URL}/status`);
}

// export function getAllSupCustsToOrgentity() {
//   return axios.get(`${CONTRACTORS_URL}/orgentity/all`);
// }

export function getSaleOffers() {
  return axios.get(`${SELL_OFFER_URL}`);
}

export function getSellOffersToOrgentity(
  priceFrom,
  priceTo,
  weightFrom,
  weightTo
) {
  return axios.get(
    `${SELL_OFFER_URL}/orgentity?priceFrom=${priceFrom}&priceTo=${priceTo}&weightFrom=${weightFrom}&weightTo=${weightTo}`
  );
}

export function createBuyOfferToSellOffer(sellOfferId, form) {
  return axios.post(`${SELL_OFFER_URL}/${sellOfferId}/offer`, form);
}

export function deleteBuyOffer(buyOfferId) {
  return axios.delete(`${BUY_OFFER_URL}/${buyOfferId}`);
}

export function updateBuyOffer(buyOfferId, form) {
  return axios.put(`${BUY_OFFER_URL}/${buyOfferId}`, form);
}

export function initAcceptBySMS(form) {
  return axios.post(`${CONTRACTORS_URL}/register/init`, form);
}

export function countActiveSupCustToOrgentity(filter) {
  if (filter.length > 0) {
    return axios.get(`${CONTRACTORS_URL}/active/count?fullName=${filter}`);
  } else {
    return axios.get(`${CONTRACTORS_URL}/active/count`);
  }
}

export function countActiveSupCustToOrgentityWithPagination(offset, filter) {
  if (filter.length > 0) {
    return axios.get(
      `${CONTRACTORS_URL}/active/offset/${offset}/limit/5?fullName=${filter}`
    );
  } else {
    return axios.get(`${CONTRACTORS_URL}/active/offset/${offset}/limit/5`);
  }
}

export function getContactsToSellOffer(sellOfferId) {
  return axios.get(`${SELL_OFFER_URL}/${sellOfferId}/contacts`);
}
