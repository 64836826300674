import * as React from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { isBefore, isAfter, formatISO } from "date-fns";
import { getImagesCropViewToCropping } from "../../../../services/cropViewCrud";
import errorHandler from "../../../../shared/errorHandler";
import displayFeedback from "../../../../components/helpers/displayFeedback";

export default function CropViewDatePickers() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedCroppingId = useSelector((state) => state.cropViewModal.selectedCroppingId);
  const loading = useSelector((state) => state.cropViewModal.loading);
  const dateFrom = useSelector((state) => state.cropViewModal.dateFrom);
  const dateTo = useSelector((state) => state.cropViewModal.dateTo);
  const [datepickerLocale, setDatepickerLocale] = React.useState("pl");

  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
  }, []);

  const setDateFrom = React.useCallback(
    (date) => {
      dispatch({
        type: "cropViewModal/setDateFrom",
        payload: date,
      });
    },
    [dispatch]
  );

  const setDateTo = React.useCallback(
    (date) => {
      dispatch({
        type: "cropViewModal/setDateTo",
        payload: date,
      });
    },
    [dispatch]
  );

  const setLoading = React.useCallback(
    (value) => {
      dispatch({
        type: "cropViewModal/setLoading",
        payload: value,
      });
    },
    [dispatch]
  );

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setDateFrom(date);
      if (isAfter(date, dateTo)) {
        setDateTo(date);
      }
    } else {
      setDateTo(date);
      if (isBefore(date, dateFrom)) {
        setDateFrom(date);
      }
    }
  };

  const onRefreshBtnClick = () => {
    setLoading(true);
    getImagesCropViewToCropping(
      selectedCroppingId,
      formatISO(dateFrom, { representation: "date" }),
      formatISO(dateTo, { representation: "date" })
    )
      .then((response) => {
        setLoading(false);
        dispatch({
          type: "cropViewModal/setCameras",
          payload: response.data,
        });
      })
      .catch((error) => {
        setLoading(false);
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }
  

  return (
    <div>
      <Form>
        <Form.Row className="align-items-end">
          <Form.Group className="pr-4 crop-view__datepicker-field">
            <Form.Label>
              <FormattedMessage id="GENERAL.DATE_FROM" /> *
            </Form.Label>
            <DatePicker
              name="dateFrom"
              selected={dateFrom}
              dateFormat="dd.MM.yyyy"
              locale={datepickerLocale}
              showMonthDropdown
              showYearDropdown
              onChange={(date) => onChangingDate("from", date)}
            />
          </Form.Group>
          <Form.Group className="pr-4 crop-view__datepicker-field">
            <Form.Label>
              <FormattedMessage id="GENERAL.DATE_TO" /> *
            </Form.Label>
            <DatePicker
              name="dateTo"
              selected={dateTo}
              dateFormat="dd.MM.yyyy"
              locale={datepickerLocale}
              showMonthDropdown
              showYearDropdown
              onChange={(date) => onChangingDate("to", date)}
            />
          </Form.Group>
          <Form.Group className="mr-4 crop-view__datepicker-refresh-btn">
            <Button onClick={onRefreshBtnClick} disabled={loading}>
              <FormattedMessage id="GENERAL.REFRESH" />
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
