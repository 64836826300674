import React from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export function DeleteFieldOrCroppingShapeModal({
  open,
  close,
  screen,
  onRemovingFieldOrCropping,
}) {
  const handleClose = () => {
    close();
  };

  const handleClick = () => {
    onRemovingFieldOrCropping();
    close();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          {screen === "fields" ? (
            <FormattedMessage id="GENERAL.DELETE_FIELD" />
          ) : (
            <FormattedMessage id="GENERAL.DELETE_CROPPING" />
          )}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            {screen === "fields" ? (
              <FormattedMessage id="GENERAL.DELETE_FIELD_SHAPE_MESSAGE" />
            ) : (
              <FormattedMessage id="GENERAL.DELETE_CROPPING_SHAPE_MESSAGE" />
            )}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClick}>
            <FormattedMessage id="GENERAL.YES" />
          </Button>
          <Button color="default" onClick={handleClose}>
            <FormattedMessage id="GENERAL.NO" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
