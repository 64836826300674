import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

export function SubscriptionRow(props) {
  const { subscription, shared } = props;
  const dispatch = useDispatch()
  const resetPricingRedux = () => {
    dispatch({
      type: "pricing/reset"
    })
  }
  return (
    <tr className="text-center">
      <td className="text--bold">
        {subscription.orgentity2Subscription.subscription.application.name}
      </td>
      <td className="text--bold">
        {!shared ? (
          <>
            {subscription.orgentity2Subscription.subscription.symbol ===
              "BT" && <span className="text--trial">Trial</span>}
            {subscription.orgentity2Subscription.subscription.symbol ===
              "BB" && <span className="text--starter">Starter</span>}
            {(subscription.orgentity2Subscription.subscription.symbol ===
              "BS" ||
              subscription.orgentity2Subscription.subscription.symbol ===
                "CM") && <span className="text--standard">Standard</span>}
            {subscription.orgentity2Subscription.subscription.symbol ===
              "BP" && <span className="text--pro">Pro</span>}
          </>
        ) : (
          <>
            {subscription.orgentities[0].roles.map((role, index) => (
              <span key={index}>
                <>
                  {role === "ROLE_ADMIN" && (
                    <FormattedMessage id="ROLE.ADMIN" />
                  )}
                  {role === "ROLE_CONTRACTOR" && (
                    <FormattedMessage id="ROLE.CONTRACTOR" />
                  )}
                  {role === "ROLE_BRIGADIER_EXTEND" && (
                    <FormattedMessage id="ROLE.BRIGADIER_EXTEND" />
                  )}
                  {role === "ROLE_BRIGADIER" && (
                    <FormattedMessage id="ROLE.BRIGADIER" />
                  )}
                </>
              </span>
            ))}
          </>
        )}
      </td>
      <td>
        {subscription.orgentity2Subscription.isActivePayment && (
          <FormattedMessage id="GENERAL.IN_PROGRESS" />
        )}
        {subscription.orgentity2Subscription.dateTo == null && !subscription.orgentity2Subscription.isActivePayment && (
          <FormattedMessage id="GENERAL.ACTIVE" />
        )}
        {subscription.orgentity2Subscription.dateTo && !subscription.orgentity2Subscription.isActivePayment && (
          <>
            {moment(subscription.orgentity2Subscription.dateTo).diff(
              moment(new Date(), "days")
            ) > 0 ? (
              <FormattedMessage id="GENERAL.ACTIVE" />
            ) : (
              <span className="text--bold">
                <FormattedMessage id="GENERAL.INACTIVE" />
              </span>
            )}
          </>
        )}
      </td>
      <td>
        {subscription.orgentity2Subscription.dateFrom === null ? (
          <>-</>
        ) : (
          <>
            {moment(subscription.orgentity2Subscription.dateFrom).format(
              "YYYY-MM-DD HH:mm"
            )}
          </>
        )}
      </td>
      <td>
        {subscription.orgentity2Subscription.dateTo === null ? (
          <>-</>
        ) : (
          <>
            {moment(subscription.orgentity2Subscription.dateTo).format(
              "YYYY-MM-DD HH:mm"
            )}
          </>
        )}
      </td>
      {!shared && (
        <>
          <td>
            {subscription.paymentList?.length > 0 && (
              <>
                {subscription.paymentList[0].amount}{" "}
                {subscription.paymentList[0].currency}
              </>
            )}
          </td>
          <td>
            <Link
              to={`/user/subscriptions/buy/${subscription.orgentity2Subscription.subscription.application.applicationId}`}
              className={`btn btn-primary ${subscription.orgentity2Subscription.isActivePayment ? "disabled" : ""}`}
              onClick={resetPricingRedux}
            >
              <>
                {moment(subscription.orgentity2Subscription.dateTo).diff(
                  moment(new Date(), "days")
                ) > 0 ? (
                  <FormattedMessage id="GENERAL.EXTEND_SUBSCRIPTION" />
                ) : (
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.BUY_SUBSCRIPTION" />
                  </span>
                )}
              </>
            </Link>
          </td>
        </>
      )}
    </tr>
  );
}
