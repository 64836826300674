import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import { enGB, de, pl, ru } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { Form, InputGroup, Col } from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";
import { useSelector } from "react-redux";

export function BulkEditResultsModal({
  open,
  formData,
  results,
  close,
  onUpdatingResults,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    formState,
    getValues,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState(pl);
  const [registerDate, setRegisterDate] = useState(new Date());
  const [isTariffHourly, setIsTariffHourly] = useState(false);
  const numberDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "DECIMAL_PLACE"
      )?.numericValue
  );
  const numberDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "DECIMAL_PLACE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });
  const rateDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "RATE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });

  const setLocaleLanguage = () => {
    switch (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang) {
      case "en":
        registerLocale("enGB", enGB);
        setDatepickerLocale(enGB);
        break;
      case "pl":
        registerLocale("pl", pl);
        setDatepickerLocale(pl);
        break;
      case "de":
        registerLocale("de", de);
        setDatepickerLocale(de);
        break;
      case "ru":
        registerLocale("ru", ru);
        setDatepickerLocale(ru);
        break;
      default:
        break;
    }
  };

  useEffect(setLocaleLanguage, []);

  useEffect(() => {
    if (results.length && open) {
      setFormValues();
    }
  }, [open, results]);

  const setFormValues = () => {
    setTimeout(() => {
      setRegisterDate(null);
    }, 50);
  };

  const checkIfButtonShouldBeDisabled = () => {
    return Object.keys(errors).length ? true : false;
  };

  const onSelectingTariff = (e) => {
    const searchedTariff = formData.tariffs.find((tariff) => {
      return tariff.tariffId === parseInt(e.currentTarget.value);
    });
    if (searchedTariff.tariffTypeValue === "HOURLY") setIsTariffHourly(true);
    else setIsTariffHourly(false);
    setValue("uomId", searchedTariff.uomId);
    setValue("rate", searchedTariff.value);
    setValue("currencyId", searchedTariff.currencyId);
    triggerValidation("rate");
  };

  const onSelectingPackaging = (e) => {
    const searchedPackaging = formData.packagings.find((packaging) => {
      return packaging.id === parseInt(e.currentTarget.value);
    });
    if (searchedPackaging) {
      setValue("quantity", searchedPackaging.quantity);
    } else {
      setValue("quantity", null);
    }
    triggerValidation();
  };

  async function onSubmit(data) {
    setLoading(true);
    data.registerAt = moment(
      moment(registerDate).format("YYYY-MM-DD HH:mm")
    ).utcOffset("+01:00");
    const isTariffTypeHourly = checkIfTariffIsHourly(parseInt(data.tariffId));
    if (isTariffTypeHourly) {
      if (data.quantityH || data.quantityM) {
        const parsedTime = moment.duration(
          `${data.quantityH ? data.quantityH : 0}:${
            data.quantityM ? data.quantityM : 0
          }`
        );
        data.quantity = parsedTime.asHours().toFixed(2);
        data.hours = parsedTime.asHours().toFixed(2);
        data.minutes = parsedTime.asMinutes();
      }
      data.dateTo = data.registerAt;
    }
    data.packageId === "0" && (data.packageId = null);
    data.employeeId = null;
    const callback = await onUpdatingResults(data, true);
    callback === "OK" ? onClosingModal() : setLoading(false);
  }

  const checkIfTariffIsHourly = (tariffId) => {
    const searchedTariff = formData.tariffs.find((tariff) => {
      return tariff.tariffId === tariffId;
    });
    if (searchedTariff) {
      return searchedTariff.tariffTypeValue === "HOURLY" ? true : false;
    } else {
      return false;
    }
  };

  const onClosingModal = () => {
    reset();
    setLoading(false);
    close();
    setTimeout(() => {
      setIsTariffHourly(false);
    }, 150);
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="BRIGADIER.BULK_EDIT_RESULTS" />
      </DialogTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="BRIGADIER.BULK_EDIT_RESULTS_MESSAGE" />.
          </DialogContentText>
          <Form.Row>
            <Form.Group as={Col} md={6} className="modal__group">
              <Form.Label>
                <FormattedMessage id="GENERAL.DATE" />
                <DatePicker
                  name="registerAt"
                  selected={registerDate}
                  dateFormat="dd.MM.yyyy HH:mm"
                  locale={datepickerLocale}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => setRegisterDate(date)}
                />
              </Form.Label>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.CROPPING" />
              </Form.Label>
              <Form.Control as="select" name="croppingId" ref={register()}>
                {formData && (
                  <>
                    <option hidden></option>
                    {formData.croppings.map((cropping) => (
                      <React.Fragment key={`cropping-${cropping.croppingId}`}>
                        {cropping.croppingId !== 0 && (
                          <option
                            key={`cropping-${cropping.croppingId}`}
                            value={cropping.croppingId}
                          >
                            {cropping.shortName}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.TARIFF" />
              </Form.Label>
              <Form.Control
                as="select"
                name="tariffId"
                onChange={(e) => onSelectingTariff(e)}
                ref={register()}
              >
                {formData && (
                  <>
                    <option hidden></option>
                    {formData.tariffs.map((tariff) => (
                      <React.Fragment key={`tariff-${tariff.tariffId}`}>
                        {tariff.tariffId !== 0 && (
                          <option
                            key={`tariff-${tariff.tariffId}`}
                            value={tariff.tariffId}
                          >
                            {tariff.name}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.PACKAGING" />
              </Form.Label>
              <Form.Control
                as="select"
                name="packageId"
                onChange={(e) => onSelectingPackaging(e)}
                ref={register({ required: false })}
              >
                {formData && (
                  <>
                    <option value={0}></option>
                    {formData.packagings.map((packaging) => (
                      <option
                        key={`packaging-${packaging.id}`}
                        value={packaging.id}
                      >
                        {packaging.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                {!isTariffHourly && <FormattedMessage id="GENERAL.QUANTITY" />}
                {isTariffHourly && <FormattedMessage id="GENERAL.DURATION" />}
              </Form.Label>
              <div className="d-flex align-items-center">
                {!isTariffHourly && (
                  <Form.Control
                    name="quantity"
                    type="number"
                    className={`${errors.quantity ? "is-invalid" : ""} ${
                      formState.touched.quantity && !errors.quantity
                        ? "is-valid"
                        : ""
                    }`}
                    placeholder={`${intl.formatMessage({
                      id: "GENERAL.QUANTITY",
                    })}`}
                    step={numberDecimalStep}
                    ref={register({
                      min: numberDecimalStep,
                      max: 1000,
                      validate: (value) => {
                        const split = value.toString().split(".");
                        if (split.length > 1) {
                          if (split[1].length > numberDecimalValue)
                            return false;
                          else return true;
                        } else return true;
                      },
                    })}
                  />
                )}
                {isTariffHourly && (
                  <>
                    <Form.Control
                      name="quantityH"
                      type="number"
                      className={`${errors.quantityH ? "is-invalid" : ""} ${
                        formState.touched.quantityH && !errors.quantityH
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder={`${intl.formatMessage({
                        id: "PREVIEW.hours",
                      })}`}
                      step="1"
                      min="0"
                      max="24"
                      ref={register({ min: 0, max: 24 })}
                      onChange={() => triggerValidation("quantityM")}
                    />
                    <strong className="d-block h-100 mx-4">:</strong>
                    <Form.Control
                      name="quantityM"
                      type="number"
                      className={`${errors.quantityM ? "is-invalid" : ""} ${
                        formState.touched.quantityM && !errors.quantityM
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder={`${intl.formatMessage({
                        id: "PREVIEW.minutes",
                      })}`}
                      step="1"
                      min="0"
                      max="59"
                      ref={register({
                        validate: {
                          max: (value) => (value ? parseInt(value) < 60 : true),
                          min: (value) =>
                            value
                              ? parseInt(value) >=
                                (getValues("quantityH") ? 0 : 1)
                              : true,
                          hr24: (value) =>
                            parseInt(getValues("quantityH")) >= 24 &&
                            parseInt(value) > 0
                              ? false
                              : true,
                        },
                      })}
                    />
                  </>
                )}
              </div>
              {errors.quantity?.type === "required" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.quantityM?.type === "required" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.FIELD_IS_REQUIRED_OR_HOURS" />
                </div>
              )}
              {errors.quantity?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                  {` ${numberDecimalStep}`}
                </div>
              )}
              {errors.quantityH?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="PREVIEW.hours" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO_0" />
                </div>
              )}
              {errors.quantityM?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="PREVIEW.minutes" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_OR_EQUAL_TO_1_OR_HOURS" />
                </div>
              )}
              {errors.quantity?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000" />
                </div>
              )}
              {errors.quantityH?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="PREVIEW.hours" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_OR_EQUAL_TO_24" />
                </div>
              )}
              {errors.quantityM?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="PREVIEW.minutes" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_OR_EQUAL_TO_59" />
                </div>
              )}
              {errors.quantityM?.type === "hr24" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.24_HOURS" />
                </div>
              )}
              {errors.quantity?.type === "validate" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                  <FormattedMessage
                    id={`ERROR.MAX_${numberDecimalValue}_DECIMAL_PLACES`}
                  />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" />
              </Form.Label>
              <Form.Control as="select" name="uomId" ref={register()} disabled>
                {formData && (
                  <>
                    <option hidden></option>
                    {formData.uom.map((unit) => (
                      <option key={`unit-${unit.id}`} value={unit.id}>
                        {unit.desc}
                      </option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="BRIGADIER.RATE" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="rate"
                  type="number"
                  className={`${errors.rate ? "is-invalid" : ""}`}
                  placeholder={`${intl.formatMessage({
                    id: "BRIGADIER.RATE",
                  })}`}
                  step={rateDecimalStep}
                  ref={register({
                    min: rateDecimalStep,
                    max: 1000,
                  })}
                />
              </InputGroup>
              {errors.rate?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                  {` ${rateDecimalStep}`}
                </div>
              )}
              {errors.rate?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.CURRENCY" />
              </Form.Label>
              <Form.Control
                as="select"
                name="currencyId"
                ref={register()}
                disabled
              >
                {formData && (
                  <>
                    <option hidden></option>
                    {formData.currencies.map((currency) => (
                      <option
                        key={`currency-${currency.id}`}
                        value={currency.id}
                      >
                        {currency.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            disabled={
              loading ||
              checkIfButtonShouldBeDisabled() ||
              (!formState.dirty && !registerDate)
            }
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button
            color="default"
            disabled={loading}
            onClick={() => onClosingModal()}
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
