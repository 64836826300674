import axios from "axios";

export const LANGUAGE_URL = `${process.env.REACT_APP_URL}languages`;

export function getLanguages() {
  return axios.get(`${LANGUAGE_URL}/all`);
}

export function setLanguageToLogin(languageCode) {
  return axios.post(`${LANGUAGE_URL}/${languageCode}`, null);
}
