import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { CroppingsTreatmentsForm, CroppingsTreatmentsMap } from "..";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function CroppingsTreatments({
  soilCultivationList,
  fertilizationList,
  plantingList,
  irrigationList,
  plantProtectionList,
  varieties,
  dimSizes,
  fertilizers,
  plantProtectionProducts,
  unitOfMeasurements,
  croppingShape,
  croppingData,
  fieldLongestLine,
  onCreatingTreatment,
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [displaySoilCultivation, setDisplaySoilCultivation] = useState(false);
  const [displayPlanting, setDisplayPlanting] = useState(false);
  const [displayIrrigation, setDisplayIrrigation] = useState(false);
  const [displayFertilization, setFertilization] = useState(false);
  const [displayPlantProtection, setDisplayPlantProtection] = useState(false);
  const [treatmentMap, setTreatmentMap] = useState(null);
  const [reset, setReset] = useState(false);

  async function onSavingTreatment(form) {
    const latLngKey = Object.keys(treatmentMap.latLngs)[0];
    const coords2 = treatmentMap.latLngs[latLngKey][0][latLngKey].map((p) => {
      return [p.lng(), p.lat()];
    });
    coords2.push([
      treatmentMap.latLngs[latLngKey][0][latLngKey][0].lng(),
      treatmentMap.latLngs[latLngKey][0][latLngKey][0].lat(),
    ]);
    if (treatmentMap !== null && treatmentMap?.map !== null) {
      form.shape = {
        type: "Polygon",
        coordinates: [
          treatmentMap.latLngs[latLngKey][0][latLngKey].map((p) => {
            return [p.lng(), p.lat()];
          }),
          coords2,
        ],
      };
    }
    if (form && form.shape && form.treatments.length) {
      const callback = await onCreatingTreatment(form);
      if (callback === "OK") {
        setReset(true);
        setDisplaySoilCultivation(false);
        setDisplayPlanting(false);
        setDisplayIrrigation(false);
        setFertilization(false);
        setDisplayPlantProtection(false);
        setTimeout(() => {
          setReset(false);
        });
      }
    } else {
      const errorMessage = "FEEDBACK.TREATMENT_SHAPE_REQUIRED";
      displayFeedback({
        type: "error",
        message: `${intl.formatMessage({ id: errorMessage })}`,
      });
    }
  }

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              <span className="agrico-svg-icon svg-icon menu-icon mr-2">
                <SVG src={toAbsoluteUrl("/media/icons/Treatment.svg")} />
              </span>
              <FormattedMessage id="GENERAL.TREATMENTS_ON_CROPS" />
            </h3>
          </div>
          <div className="card-toolbar">
            <div
              className="d-flex flex-row align-items-center justify-content-between"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.CHOOSE_SOIL_CULTIVATION_TREATMENT" />
                  </Tooltip>
                }
              >
                <Button
                  variant={`${
                    displaySoilCultivation ? "primary" : "outline-primary"
                  }`}
                  onClick={(e) => {
                    setDisplaySoilCultivation(!displaySoilCultivation);
                    e.currentTarget.blur();
                  }}
                  disabled={loading}
                  className="mr-4"
                >
                  <i className="flaticon2-world pr-0" style={{ padding: "none" }} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.CHOOSE_PLANTING_TREATMENT" />
                  </Tooltip>
                }
              >
                <Button
                  variant={`${displayPlanting ? "primary" : "outline-primary"}`}
                  onClick={(e) => {
                    setDisplayPlanting(!displayPlanting);
                    e.currentTarget.blur();
                  }}
                  disabled={loading}
                  className="mr-4"
                >
                  <i className="flaticon2-plus-1 pr-0" style={{ padding: "none" }} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.CHOOSE_IRRIGATION_TREATMENT" />
                  </Tooltip>
                }
              >
                <Button
                  variant={`${
                    displayIrrigation ? "primary" : "outline-primary"
                  }`}
                  onClick={(e) => {
                    setDisplayIrrigation(!displayIrrigation);
                    e.currentTarget.blur();
                  }}
                  disabled={loading}
                  className="mr-4"
                >
                  <i className="flaticon2-drop pr-0" style={{ padding: "none" }} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.CHOOSE_FERTILIZATION_TREATMENT" />
                  </Tooltip>
                }
              >
                <Button
                  variant={`${
                    displayFertilization ? "primary" : "outline-primary"
                  }`}
                  onClick={(e) => {
                    setFertilization(!displayFertilization);
                    e.currentTarget.blur();
                  }}
                  disabled={loading}
                  className="mr-4"
                >
                  <i
                    className="flaticon2-layers-2 pr-0"
                    style={{ padding: "none" }}
                  />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.CHOOSE_PLANT_PROTECTION_TREATMENT" />
                  </Tooltip>
                }
              >
                <Button
                  variant={`${
                    displayPlantProtection ? "primary" : "outline-primary"
                  }`}
                  onClick={(e) => {
                    setDisplayPlantProtection(!displayPlantProtection);
                    e.currentTarget.blur();
                  }}
                >
                  <i
                    className="flaticon2-protection pr-0"
                    style={{ padding: "none" }}
                  />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        <div className="card-body">
          <Row>
            <Col md={4}>
              <CroppingsTreatmentsForm
                soilCultivationList={soilCultivationList}
                fertilizationList={fertilizationList}
                plantingList={plantingList}
                irrigationList={irrigationList}
                plantProtectionList={plantProtectionList}
                varieties={varieties}
                dimSizes={dimSizes}
                fertilizers={fertilizers}
                plantProtectionProducts={plantProtectionProducts}
                unitOfMeasurements={unitOfMeasurements}
                croppingData={croppingData}
                displaySoilCultivation={displaySoilCultivation}
                displayPlanting={displayPlanting}
                displayIrrigation={displayIrrigation}
                displayFertilization={displayFertilization}
                displayPlantProtection={displayPlantProtection}
                resetForm={reset}
                onSavingTreatment={onSavingTreatment}
                treatmentMap={treatmentMap}
              />
            </Col>
            <Col md={8}>
              <CroppingsTreatmentsMap
                croppingShape={croppingShape}
                fieldLongestLine={fieldLongestLine}
                resetShape={reset}
                setTreatmentMap={setTreatmentMap}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
