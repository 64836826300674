import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function ExclusiveDataRequestsTable({
  exclusiveRequests,
  acceptExclusiveRequest,
  rejectExclusiveRequest,
}) {
  const [activePartnerId, setActivePartner] = useState();
  const [loading, setLoading] = useState();

  async function acceptRequest(farmSharingId) {
    setLoading(true);
    const callback = await acceptExclusiveRequest(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  async function rejectRequest(farmSharingId) {
    setLoading(true);
    const callback = await rejectExclusiveRequest(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.FARM_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CROPPING_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CULTIVATION_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.TREATMENT_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {exclusiveRequests && (
          <>
            {exclusiveRequests.map((bPartner) => (
              <React.Fragment key={`bPartner-${bPartner.bpartnerId}`}>
                <tr
                  className="row__grower cursor--pointer"
                  onClick={() =>
                    setActivePartner(
                      activePartnerId === bPartner.bpartnerId
                        ? null
                        : bPartner.bpartnerId
                    )
                  }
                >
                  <td className="text-center">{bPartner.bpartnerFullName}</td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                </tr>
                {bPartner.croppings.map((cropping) => (
                  <React.Fragment key={`cropping-${cropping.croppingId}`}>
                    <tr
                      className={`${
                        activePartnerId === bPartner.bpartnerId
                          ? ""
                          : "row--hidden"
                      }`}
                    >
                      <td className="text-center"></td>
                      <td className="text-center">
                        {cropping.croppingShortName}
                      </td>
                      <td className="text-center">
                        {cropping.isSpatialDataAllowed ? (
                          <i
                            className="flaticon2-check-mark"
                            style={{ padding: "none", color: "#1BC5BD" }}
                          />
                        ) : (
                          <i
                            className="flaticon2-check-mark text--muted"
                            style={{ padding: "none" }}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {cropping.isTreatmentDataAllowed ? (
                          <i
                            className="flaticon2-check-mark"
                            style={{ padding: "none", color: "#1BC5BD" }}
                          />
                        ) : (
                          <i
                            className="flaticon2-check-mark text--muted"
                            style={{ padding: "none" }}
                          />
                        )}
                      </td>
                      <td className="text-center">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="user-tooltip">
                                <FormattedMessage id="GENERAL.ACCEPT" />
                              </Tooltip>
                            }
                          >
                            <Button
                              className="mr-2"
                              type="button"
                              variant="primary"
                              onClick={() =>
                                acceptRequest(cropping.farmSharingId)
                              }
                              disabled={loading}
                            >
                              <i className="fas fa-check icon-nm pr-0"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="user-tooltip">
                                <FormattedMessage id="GENERAL.REJECT" />
                              </Tooltip>
                            }
                          >
                            <Button
                              className="mr-2"
                              type="button"
                              variant="secondary"
                              onClick={() =>
                                rejectRequest(cropping.farmSharingId)
                              }
                              disabled={loading}
                            >
                              <i className="fa fa-trash icon-nm pr-0"></i>
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
}
