import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function OldestTreatmentsTimeline({ events, onOpeningModal }) {
  const handleClick = (eventId) => {
    onOpeningModal(eventId);
  };

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/icons/Treatment.svg")} />
            </span>
            <h3 className="card-label">
              <FormattedMessage id="GENERAL.TREATMENTS" />
            </h3>
          </div>
        </div>
        <div className="card-body">
          {events.length > 0 ? (
            <div className="timeline timeline-5 mt-3">
              {events.map((event) => (
                <div
                  key={event.eventId}
                  className="timeline-item align-items-start"
                >
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3">
                    {moment(event.eventDateFrom).format("DD.MM")}
                  </div>
                  <div
                    className="timeline-badge"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(event.eventId)}
                  >
                    <i className="fa fa-genderless text-info icon-xxl"></i>
                  </div>
                  <div
                    className="timeline-content text-dark-50"
                    style={{ wordBreak: "break-all" }}
                  >
                    {moment(event.eventDateTo).format("DD.MM")} -
                    {event.treatments.map((treatment, index) => (
                      <span key={index + event.eventId}>
                        <FormattedMessage id={`TREATMENT.${treatment.type}`} />        
                        {index !== event.treatments.length - 1 ? "/" : ""}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_DECLARED_TREATMENTS" />
              </h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
