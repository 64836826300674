import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export default function AcceptBuyOfferModal(props) {
  const [loading, setLoading] = useState(false);

  function handleClose() {
    props.close();
  }

  async function handleClick() {
    setLoading(true);
    const callback = await props.onAcceptingOffer(props.buyOfferId);
    if (callback === "OK") {
      setLoading(false);
      props.close();
    } else {
      setLoading(false);
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="PE.ACCEPTANCE_CONFIRMATION" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="PE.ACCEPTANCE_CONFIRMATION_MESSAGE" />?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={loading} onClick={handleClick}>
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button color="default" disabled={loading} onClick={handleClose}>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
