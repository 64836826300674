import store from "../../../../redux/store";

export default function closeModals() {
  const createModalOpen = store.getState().calendar.openModals.create
  const updateModalOpen = store.getState().calendar.openModals.update

  if (createModalOpen) {
    store.dispatch({
      type: "calendar/setCreateModalOpen",
      payload: false,
    });
  }
  if (updateModalOpen) {
    store.dispatch({
      type: "calendar/setUpdateModalOpen",
      payload: false,
    });
    store.dispatch({
      type: "calendar/setCroppingShape",
      payload: null,
    });
    store.dispatch({
      type: "calendar/setTreatmentDetails",
      payload: null,
    });
    store.dispatch({
      type: "calendar/setTreatmentHeadId",
      payload: null,
    });
  }
}
