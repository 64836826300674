import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

export default function ConfirmPaymentModal(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [numberProvided, setNumberProvided] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });

  function handleClose() {
    props.close();
  }

  async function onSubmit(data) {
    setLoading(true);
    if (!numberProvided) {
      const callback = await props.onEnteringPhoneNumberForPayment(
        props.licenceId
      );
      if (callback === "OK") {
        setNumberProvided(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      const callback = await props.onEnteringPinForPayment(
        props.licenceId,
        data
      );
      if (callback === "OK" || callback === "error") {
        setLoading(false);
        handleClose();
      }
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="scroll-dialog-title">
            <FormattedMessage id="GENERAL.PAYMENT_CONFIRMATION" />
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              <span>
                <FormattedMessage id="GENERAL.PAYMENT_CONFIRMATION_MESSAGE" />.
              </span>
              {numberProvided && (
                <Form.Row className="mt-2">
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.VERIFICATION_CODE" />
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="pin"
                        type="text"
                        placeholder={`${intl.formatMessage({
                          id: "GENERAL.VERIFICATION_CODE",
                        })}`}
                        ref={register({
                          required: true,
                        })}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit" disabled={loading}>
              {numberProvided ? (
                <>
                  <FormattedMessage id="GENERAL.ACCEPT" />
                </>
              ) : (
                <>
                  <FormattedMessage id="GENERAL.CONFIRM" />
                </>
              )}
            </Button>
            <Button color="default" disabled={loading} onClick={handleClose}>
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
