import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export function CroppingRelatedFieldsTable({
  assignedFields,
  onDeletingFieldRelationWithCropping,
}) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.FIELD" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACREAGE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {assignedFields.map((field) => (
          <tr key={field.fieldId} className="text-center">
            <td>{field.shortName}</td>
            <td>
              {field.shapeArea === null
                ? field.acreage
                : field.shapeArea.toFixed(2)}
            </td>
            <td>
              <Link
                to={`/farm/fields?id=${field.fieldId}`}
                className="button__link--primary"
              >
                <Button type="button" variant="secondary">
                  <FormattedMessage id="GENERAL.GO_TO_FIELD" />
                </Button>
              </Link>
              <Button
                variant="secondary"
                onClick={() =>
                  onDeletingFieldRelationWithCropping(field.fieldId)
                }
              >
                <FormattedMessage id="GENERAL.DELETE" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
