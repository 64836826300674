import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export function ShareReportForm({ onFormChange }) {
  const intl = useIntl();
  const { control, register, handleSubmit, getValues, errors } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  useEffect(() => {
    append({ value: "" });
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };

  const formChange = () => {
    onFormChange(errors, getValues());
  };

  const onRemovingField = (index) => {
    remove(index);
    setTimeout(() => {
      formChange();
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onChange={formChange}>
      <Form.Row>
        {fields.map((field, index) => (
          <Form.Group key={field.id} as={Col} xs={12}>
            <InputGroup>
              <Form.Control
                type="email"
                name={`emails[${index}]`}
                placeholder={`${intl.formatMessage({
                  id: "GENERAL.EMAIL",
                })}`}
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                  },
                })}
                defaultValue={field.value}
              />
              <InputGroup.Append>
                {index === fields.length - 1 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="user-tooltip">
                        <FormattedMessage id="GENERAL.ADD_CONTACT" />
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => append({ value: "" })}
                    >
                      <i className="fas fa-plus icon-nm pr-0"></i>
                    </Button>
                  </OverlayTrigger>
                )}
                {fields.length > 1 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="user-tooltip">
                        <FormattedMessage id="GENERAL.REMOVE_CONTACT" />
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => onRemovingField(index)}
                    >
                      <i className="fa fa-trash icon-nm pr-0"></i>
                    </Button>
                  </OverlayTrigger>
                )}
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        ))}
      </Form.Row>
    </Form>
  );
}
