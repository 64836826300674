import axios from "axios";

export const TREATMENTS_URL = `${process.env.REACT_APP_URL}treatments`;

export function getTreatmentsToCropping() {
  return axios.get(`${TREATMENTS_URL}/cropping`);
}

export function getDimSizes() {
  return axios.get(`${process.env.REACT_APP_URL}dimSizes/all`);
}

export function createTreatmentsToCropping(croppingId, form) {
  return axios.post(`${TREATMENTS_URL}/cropping/${croppingId}/create`, form);
}

export function getTreatmentToLoginInDatesWithFilters(
  farmId,
  from,
  to,
  treatmentTypeIds
) {
  if (treatmentTypeIds.length) {
    let queryParamString = "?treatmentTypeIds=";
    treatmentTypeIds.forEach((id) => {
      queryParamString += `${id},`;
    });
    const queryParam = queryParamString.slice(0, -1);
    return axios.get(
      `${TREATMENTS_URL}/bPartner/${farmId}/from/${from}/to/${to}/${queryParam}`
    );
  } else {
    return axios.get(
      `${TREATMENTS_URL}/bPartner/${farmId}/from/${from}/to/${to}`
    );
  }
}

export function getTreatment(treatmentId) {
  return axios.get(`${TREATMENTS_URL}/${treatmentId}`);
}

export function updateTreatmetHeader(treatmentHeaderId, form) {
  return axios.put(`${TREATMENTS_URL}/${treatmentHeaderId}`, form);
}

export function getTreatmentToCroppingWithFilters(croppingId) {
  return axios.get(`${TREATMENTS_URL}/cropping/${croppingId}/all`);
}
