import React, { useState, useEffect } from "react";
import { WorkerOperationsTable } from "./WorkerOperationsTable";
import { WorkerOperationForm } from "./WorkerOperationForm";

export function WorkerOperationsContainer({
  formData,
  operations,
  getWorkerOperations,
  createReceivableTransaction,
}) {
  const [newValue, setNewValue] = useState();

  useEffect(() => {
    if (operations.length) {
      setNewValue(null);
    }
  }, [operations]);

  const onSelectingBalanceValue = (value) => {
    setNewValue(value);
    setTimeout(() => {
      setNewValue(null);
    });
  };

  return (
    <>
      <WorkerOperationsTable
        operations={operations}
        getWorkerOperations={getWorkerOperations}
        onSelectingBalanceValue={onSelectingBalanceValue}
      />
      <WorkerOperationForm
        formData={formData}
        newValue={newValue}
        createReceivableTransaction={createReceivableTransaction}
      />
    </>
  );
}
