import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row, Table } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import getPaymentStatus from "./helpers/getPaymentStatus";
import getDeliveryStatus from "./helpers/getDeliveryStatus";

export default function SelectedOrder(props) {
  const { payment, goBack, backButtonDisabled } = props;
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const getSubscriptionPrice = (payment) => {
    if (
      payment.application.symbol === "BRIGADIER" &&
      payment.parameters.find((p) => p.symbol === "BRIGADIER_NUMBER")
    )
      return (
        payment.subscriptionPrice.amount +
        payment.parameters.find((p) => p.symbol === "BRIGADIER_NUMBER").price
      );
    else return payment.subscriptionPrice.amount;
  };

  const intl = useIntl();
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          {!backButtonDisabled && (
            <button className="btn ml-n2" onClick={() => goBack()}>
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Left-2.svg")}
              />
            </button>
          )}
          {backButtonDisabled && (
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")} />
            </span>
          )}
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.ORDERS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex mb-4 justify-content-between flex-wrap">
          <p className="mr-3">
            <FormattedMessage id="ORDERS.ORDER_NUMBER" />: {payment.id}
          </p>
          <p>
            <FormattedMessage id="ORDERS.ORDER_DATE" />:{" "}
            {new Date(payment.createdAt).toLocaleString("pl")}
          </p>
        </div>
        <div className="d-flex mb-4 justify-content-between">
          <p>
            <FormattedMessage id="ORDERS.FULL_PRICE" />
          </p>
          <div className="d-flex justify-content-center flex-column">
            <h6 className="m-0 mb-1">
              {payment.amount.toLocaleString(languageCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {payment.currency} <FormattedMessage id="PRICING.NETTO" />
            </h6>
            <h6 className="m-0">
              <strong>
                {payment.amountBrutto.toLocaleString(languageCode, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {payment.currency} <FormattedMessage id="PRICING.BRUTTO" />
              </strong>
            </h6>
          </div>
        </div>
        <div className="mb-10">
          {payment.deliveryStatus && (
            <p className="mb-1">
              <FormattedMessage id="ORDERS.DELIVERY_STATUS" />:{" "}
              <strong>
                {intl.formatMessage({ id: getDeliveryStatus(payment) })}
              </strong>
            </p>
          )}
          <p>
            <FormattedMessage id="ORDERS.PAYMENT_STATUS" />:{" "}
            <strong>
              {intl.formatMessage({ id: getPaymentStatus(payment) })}
            </strong>
          </p>
        </div>
        {
          <div className="mb-4">
            <h6>
              <FormattedMessage id="ORDERS.ORDER_DETAILS" />
            </h6>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="PLACEHOLDER.NAME" />
                  </th>
                  <th>
                    <FormattedMessage id="PLACEHOLDER.QUANTITY" />
                  </th>
                  <th className="text-right">
                    <FormattedMessage id="PRICING.VALUE.NETTO" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage id="ORDERS.SUBSCRIPTION" />{" "}
                    {payment.subscription.desc}
                    {payment.application.symbol === "BRIGADIER" && (
                      <>
                        <br />
                        <FormattedMessage id="PRICING.BRIGADIER_COUNT" />:{" "}
                        {payment.parameters?.find(
                          (p) => p.symbol === "BRIGADIER_NUMBER"
                        )
                          ? payment.parameters?.find(
                              (p) => p.symbol === "BRIGADIER_NUMBER"
                            ).value
                          : 1}
                      </>
                    )}
                  </td>
                  <td className="align-middle">
                    1 <FormattedMessage id="PRICING.PCS" />
                  </td>
                  <td
                    className="align-middle text-right"
                    style={{ width: "20%" }}
                  >
                    <strong>
                      {getSubscriptionPrice(payment).toLocaleString(
                        languageCode,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}{" "}
                      {payment.currency}
                    </strong>
                  </td>
                </tr>
                {payment.parameters.length > 0 &&
                  payment.parameters.map((param, index) => {
                    if (
                      param.symbol.includes("COURIER") ||
                      param.symbol.includes("BRIGADIER_NUMBER")
                    )
                      return null;
                    else
                      return (
                        <tr key={`step2-params-${index}`}>
                          <td className="align-middle">
                            <FormattedMessage
                              id={`PRICING.PRODUCT.${param.symbol}`}
                            />
                          </td>
                          <td className="align-middle">
                            {param.value} <FormattedMessage id="PRICING.PCS" />
                          </td>
                          <td
                            className="align-middle text-right"
                            style={{ width: "15%" }}
                          >
                            <strong>
                              {param.price.toLocaleString(languageCode, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              {payment.currency}
                            </strong>
                          </td>
                        </tr>
                      );
                  })}
              </tbody>
            </Table>
          </div>
        }
        {payment.application.symbol === "BRIGADIER" &&
          payment.parameters &&
          payment.parameters.find((p) => p.symbol.includes("COURIER")) && (
            <div className="mb-10">
              <h6 className="mb-4">
                <FormattedMessage id="PRICING.DELIVERY" />
              </h6>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50">
                  <div className="symbol-label">
                    <i className="fa fa-truck" />
                  </div>
                </div>
                <div className="ml-4">
                  <p className="m-0">
                    <FormattedMessage id="PRICING.DHL_COURIER" />
                  </p>
                  <p className="m-0 font-weight-bold">
                    {payment.parameters
                      .find((p) => p.symbol.includes("COURIER"))
                      .price.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    {payment.currency}
                  </p>
                </div>
              </div>
            </div>
          )}
        <div className="d-flex mb-10 justify-content-between">
          <h6>
            <FormattedMessage id="ORDERS.SUM" />
          </h6>
          <div className="d-flex justify-content-center flex-column">
            <h6 className="m-0 mb-1">
              {payment.amount.toLocaleString(languageCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {payment.currency} <FormattedMessage id="PRICING.NETTO" />
            </h6>
            <h6 className="m-0">
              <strong>
                {payment.amountBrutto.toLocaleString(languageCode, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {payment.currency} <FormattedMessage id="PRICING.BRUTTO" />
              </strong>
            </h6>
            {payment.discountCode && (
              <p className="text-success">
                * <FormattedMessage id="PRICING.DISCOUNT_CODE_USED" />
              </p>
            )}
          </div>
        </div>
        <div>
          <Row>
            {payment.mail && (
              <Col md={4} className="mb-10">
                <h6>
                  <FormattedMessage id="PRICING.BASIC_DATA" />
                </h6>
                <p>{payment.mail}</p>
                <p>
                  <FormattedMessage id="PRICING.TELEPHONE_SHORT" />{" "}
                  {payment.phone}
                </p>
              </Col>
            )}
            {payment.address?.firstName && (
              <Col md={4} className="mb-10">
                <h6>
                  <FormattedMessage id="PRICING.DELIVERY_ADDRESS" />
                </h6>
                <p>
                  {payment.address.firstName} {payment.address.lastName}
                </p>
                <p>
                  {payment.address.address1} {payment.address.address2}
                </p>
                <p>
                  {payment.address.countryIdName} {payment.address.postalCode}{" "}
                  {payment.address.postalCodeName}
                </p>
              </Col>
            )}
            {payment.invoiceAddress?.name && (
              <Col md={4} className="mb-10">
                <h6>
                  <FormattedMessage id="PRICING.INVOICE_DATA" />
                </h6>
                <p>{payment.invoiceAddress.name}</p>
                <p>
                  {payment.invoiceAddress.address1}{" "}
                  {payment.invoiceAddress.address2}
                </p>
                <p>
                  {payment.invoiceAddress.countryIdName}{" "}
                  {payment.invoiceAddress.postalCode}{" "}
                  {payment.invoiceAddress.postalCodeName}
                </p>
                <p>
                  <FormattedMessage id="GENERAL.NIP" />:{" "}
                  {payment.invoiceAddress.vatin}
                </p>
                <p>
                  <i
                    className="fa fa-info-circle mr-1"
                    style={{ fontSize: "1rem" }}
                  />{" "}
                  <FormattedMessage id="ORDERS.INVOICE_TIME" />
                </p>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
