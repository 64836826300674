import React, { useMemo } from "react";
import moment from "moment";

export function WorkTimeResultRow({ result, index, onSelectingRow }) {

  return (
    <tr
      className={`text-center cursor--pointer ${
        result.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => onSelectingRow(result.__workFlowId)}
    >
      <td>{index + 1}</td>
      <td>{result.firstName}</td>
      <td>{result.lastName}</td>
      <td>{result.item}</td>
      <td>{result.badge}</td>
      <td>{result.brigadeName}</td>
      <td>{result.brigadierName}</td>
      <td>{result.workFlowEvent}</td>
      <td>
        {`${moment(result.dateFrom).format("YYYY-MM-DD HH:mm")}`}
      </td>
      <td>
        {`${moment(result.dateTo).format("YYYY-MM-DD HH:mm")}`}
      </td>
      <td>{result.duration}</td>
      <td>{result.value}</td>
      <td>{result.tariffName}</td>
      
    </tr>
  );
}
