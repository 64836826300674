import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function DoYouKnow({ suggestions }) {
  const [ordinalNumber, setOrdinalNumber] = useState(0);

  useEffect(() => {
    if (suggestions.length) {
      const interval = setInterval(() => {
        setOrdinalNumber((ordinalNumber) => {
          if (ordinalNumber + 1 === suggestions.length) {
            return 0;
          } else {
            return ordinalNumber + 1;
          }
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/icons/Information.svg")} />
            </span>
            <h3 className="card-label">
              <FormattedMessage id="GENERAL.DO_YOU_KNOW" /> ...
            </h3>
          </div>
        </div>
        <div className="card-body">{suggestions[ordinalNumber].desc}</div>
      </div>
    </>
  );
}
