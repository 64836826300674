import * as React from "react";
import { CircularProgress, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const BrigadierCircularProgress = () => {
  const theme = useTheme();
  const Progress = withStyles({
    circle: { color: theme.palette.brigadier.main },
  })(CircularProgress);

  return <Progress />;
};

export default BrigadierCircularProgress;
