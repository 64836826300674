import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { HistoricalOperationsFilterForm } from "./HistoricalOperationsFilterForm";
import { HistoricalOperationsTable } from "./HistoricalOperationsTable";
import { OperationPDF } from "./OperationPDF";
import { OperationsPDF } from "./OperationsPDF";
import { Tabs, Tab, Button } from "react-bootstrap";

export function HistoricalOperationsCard({
  formData,
  activeWorker,
  historicalOperations,
  getWorkerHistoricalOperations,
  onSelectingRow,
  createWorkerOperationsXLSFile,
  getPayoutSignature,
}) {
  const [key, setKey] = useState("historicalOperationsTable");
  const [loading, setLoading] = useState(false);
  const [documentToDisplay, setDocumentToDisplay] = useState(null);

  useEffect(() => {
    if (activeWorker) {
      setKey("historicalOperationsTable");
      setDocumentToDisplay(null);
    }
  }, [activeWorker]);

  const onGeneratingSinglePDF = () => {
    setDocumentToDisplay("document");
    setKey("historicalOperationsPDF");
  };

  const onGeneratingMultiPDF = () => {
    setDocumentToDisplay("documents");
    setKey("historicalOperationsPDF");
  };

  async function onGeneratingXLSFile() {
    setLoading(true);
    const callback = await createWorkerOperationsXLSFile();
    if (callback) {
      setLoading(false);
    }
  }

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <HistoricalOperationsFilterForm
            formData={formData}
            getWorkerHistoricalOperations={getWorkerHistoricalOperations}
          />
        </div>
      </div>
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="historicalOperationsTable"
            title={<FormattedMessage id="GENERAL.HISTORICAL_OPERATIONS" />}
          >
            <div className="mt-5">
              {!historicalOperations.length ? (
                <div className="text-center">
                  <h6>
                    <FormattedMessage id="GENERAL.NO_OPERATIONS" />
                  </h6>
                </div>
              ) : (
                <HistoricalOperationsTable
                  data={historicalOperations}
                  onSelectingRow={onSelectingRow}
                  getPayoutSignature={getPayoutSignature}
                />
              )}
            </div>
          </Tab>
          <Tab
            eventKey="historicalOperationsPDF"
            title={<FormattedMessage id="GENERAL.OPERATION_DOCUMENT" />}
            disabled={!documentToDisplay}
          >
            <div className="mt-5">
              {documentToDisplay === "document" && (
                <OperationPDF
                  activeWorker={activeWorker}
                  operation={[...historicalOperations].find((operation) => {
                    return operation.isSelected;
                  })}
                />
              )}
              {documentToDisplay === "documents" && (
                <OperationsPDF
                  activeWorker={activeWorker}
                  operations={historicalOperations}
                />
              )}
            </div>
          </Tab>
        </Tabs>
        <div className="mt-5 text-right">
          <Button
            className="mr-1"
            onClick={() => onGeneratingXLSFile()}
            disabled={loading}
          >
            <FormattedMessage id="GENERAL.EXPORT" />
          </Button>
          <Button
            className="mr-1"
            onClick={() => onGeneratingSinglePDF()}
            disabled={
              (historicalOperations.find((operation) => operation.isSelected)
                ? false
                : true) || loading
            }
          >
            <FormattedMessage id="GENERAL.GENERATE_PDF_DOCUMENT" />
          </Button>
          <Button
            onClick={() => onGeneratingMultiPDF()}
            disabled={loading || !historicalOperations.length}
          >
            <FormattedMessage id="GENERAL.GENERATE_PDF_DOCUMENTS" />
          </Button>
        </div>
      </div>
    </div>
  );
}
