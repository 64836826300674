import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function SharedDataTable({
  sharedData,
  acceptSharedData,
  rejectSharedData,
  requestExclusivitySharedData,
}) {
  const [activeBPartnerId, setActiveBPartnerId] = useState();
  const [activeVarietyId, setActiveVariety] = useState();
  const [loading, setLoading] = useState();

  async function acceptData(farmSharingId) {
    setLoading(true);
    const callback = await acceptSharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  async function rejectData(farmSharingId) {
    setLoading(true);
    const callback = await rejectSharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  async function requestExclusivityData(farmSharingId) {
    setLoading(true);
    const callback = await requestExclusivitySharedData(farmSharingId);
    if (callback) {
      setLoading(false);
    }
  }

  const onBPartnerRowClick = (bPartnerId) => {
    setActiveBPartnerId(activeBPartnerId === bPartnerId ? null : bPartnerId);
    if (activeBPartnerId) setActiveVariety(null);
  };

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.ITEM" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.FARM_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.SPECIES" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.VARIETY" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CROPPING_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CULTIVATION_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.TREATMENT_DATA" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.AVAILABLE_EXCLUSIVELY" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {sharedData && (
          <>
            {sharedData.map((bPartner, index) => (
              <React.Fragment key={`bPartner-${bPartner.bpartnerId}`}>
                <tr className="cursor--pointer" onClick={() => onBPartnerRowClick(bPartner.bpartnerId)}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{bPartner.bpartnerFullName}</td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                  <td className="text-center"></td>
                </tr>
                {bPartner.varieties.map((variety) => (
                  <React.Fragment key={`variety-${variety.varietyId}`}>
                    <tr
                      className={`row__grower cursor--pointer ${
                        activeBPartnerId === bPartner.bpartnerId
                          ? ""
                          : "row--hidden"
                      }`}
                      onClick={() =>
                        setActiveVariety(
                          activeVarietyId === variety.varietyId
                            ? null
                            : variety.varietyId
                        )
                      }
                    >
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center">{variety.speciesDesc}</td>
                      <td className="text-center">
                        {variety.varietyShortName}
                      </td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                      <td className="text-center"></td>
                    </tr>
                    {variety.croppings.map((cropping) => (
                      <tr
                        className={`${
                          activeVarietyId === variety.varietyId && activeBPartnerId === bPartner.bpartnerId
                            ? ""
                            : "row--hidden"
                        }`}
                        key={`cropping-${cropping.croppingId}`}
                      >
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center">
                          {cropping.croppingShortName}
                        </td>
                        <td className="text-center">
                          {cropping.isSpatialDataAllowed ? (
                            <i
                              className="flaticon2-check-mark"
                              style={{ padding: "none", color: "#1BC5BD" }}
                            />
                          ) : (
                            <i
                              className="flaticon2-check-mark text--muted"
                              style={{ padding: "none" }}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          {cropping.isTreatmentDataAllowed ? (
                            <i
                              className="flaticon2-check-mark"
                              style={{ padding: "none", color: "#1BC5BD" }}
                            />
                          ) : (
                            <i
                              className="flaticon2-check-mark text--muted"
                              style={{ padding: "none" }}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          {cropping.isExclusiveAccepted ? (
                            <i
                              className="flaticon2-check-mark"
                              style={{ padding: "none", color: "#1BC5BD" }}
                            />
                          ) : (
                            <i
                              className="flaticon2-check-mark text--muted"
                              style={{ padding: "none" }}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="user-tooltip">
                                  <FormattedMessage id="GENERAL.ACCEPT" />
                                </Tooltip>
                              }
                            >
                              <Button
                                className="mr-2"
                                type="button"
                                variant="primary"
                                onClick={() =>
                                  acceptData(cropping.farmSharingId)
                                }
                                disabled={cropping.isAccepted || loading}
                              >
                                <i className="fas fa-check icon-nm pr-0"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="user-tooltip">
                                  <FormattedMessage id="GENERAL.REJECT" />
                                </Tooltip>
                              }
                            >
                              <Button
                                className="mr-2"
                                type="button"
                                variant="secondary"
                                onClick={() =>
                                  rejectData(cropping.farmSharingId)
                                }
                                disabled={cropping.isAccepted || loading}
                              >
                                <i className="fa fa-trash icon-nm pr-0"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="user-tooltip">
                                  <FormattedMessage id="GENERAL.REQUEST_EXCLUSIVITY" />
                                </Tooltip>
                              }
                            >
                              <Button
                                type="button"
                                variant="secondary"
                                onClick={() =>
                                  requestExclusivityData(cropping.farmSharingId)
                                }
                                disabled={
                                  cropping.isExclusiveAccepted || loading
                                }
                              >
                                <i className="fas fa-lock icon-nm pr-0"></i>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
}
