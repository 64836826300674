import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { AgronomistsTable } from "./AgronomistsTable";

export const AgronomistsList = ({
  agronomists,
  agronomistsLoaded,
  onDeletingAgronomist,
}) => {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/User.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="CROPMANAGER.AGRONOMIST_LIST" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        {agronomistsLoaded && agronomists.length > 0 && (
          <AgronomistsTable
            agronomists={agronomists}
            onDeletingAgronomist={onDeletingAgronomist}
          />
        )}
        {agronomistsLoaded && !agronomists.length && (
          <h6 className="text-center"><FormattedMessage id="CROPMANAGER.NO_AGRONOMISTS" />.</h6>
        )}
      </div>
    </div>
  );
};
