import React from "react";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";

export function Search(props) {
  const intl = useIntl();

  return (
    <Form.Control
      type="text"
      value={props.value}
      onChange={props.onChange}
      style={{ width: props.width ? `${props.width}%` : "100%" }}
      placeholder={`${
        props.placeholder
          ? `${intl.formatMessage({ id: props.placeholder })}`
          : `${intl.formatMessage({ id: "PLACEHOLDER.SEARCH" })}`
      }...`}
      aria-label="Search input"
      disabled={props.disabled || false}
      className={props.className ? props.className : ""}
    />
  );
}
