import { createSlice } from "@reduxjs/toolkit";

const initialState = { species: [] };

const fieldsSlice = createSlice({
  name: "fields",
  initialState,
  reducers: {
    setSpecies(state, action) {
      state.species = action.payload;
    },
  },
});

export default fieldsSlice.reducer;
