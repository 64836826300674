import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useIntl, FormattedMessage} from "react-intl";
import moment from "moment";
import DatePicker, {registerLocale} from "react-datepicker";
import {enGB, de, pl, ru} from "date-fns/locale";
import {Form, Col, Button} from "react-bootstrap";
import {FormControlLabel, Checkbox} from "@material-ui/core";

export function ReportForm({
                             selectedReportType,
                             formData,
                             croppingsWithTreatmentDataAllowed,
                             onCreatingReport,
                           }) {
  const intl = useIntl();
  const {register, handleSubmit} = useForm({
    mode: "onChange",
  });
  const [datepickerLocale, setDatepickerLocale] = useState(pl);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(true);

  const setLocaleLanguage = () => {
    switch (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang) {
      case "en":
        registerLocale("enGB", enGB);
        setDatepickerLocale(enGB);
        break;
      case "pl":
        registerLocale("pl", pl);
        setDatepickerLocale(pl);
        break;
      case "de":
        registerLocale("de", de);
        setDatepickerLocale(de);
        break;
      case "ru":
        registerLocale("ru", ru);
        setDatepickerLocale(ru);
        break;
      default:
        break;
    }
  };

  useEffect(setLocaleLanguage, []);

  async function onSubmit(data) {
    setLoading(true);
    const form = data;
    if (selectedReportType !== "P") {
      form.dateFrom = moment(fromDate).format("YYYY-MM-DD");
      form.dateTo = moment(toDate).format("YYYY-MM-DD");
    }
    if (
      selectedReportType === "J" ||
      selectedReportType === "M" ||
      selectedReportType === "X" ||
      selectedReportType === "P" ||
      selectedReportType === "Z" ||
      selectedReportType === "Y"  
    ) {
      form.onlyActive = checkboxActive;
    }
    if (selectedReportType === "X") {
      form.bpartnerId = parseInt(localStorage.getItem("activeFarmId"));
      delete form.dateFrom;
      delete form.dateTo;
    }
    if (selectedReportType === "Y") {
      delete form.dateFrom;
      delete form.dateTo;
    }
    let callback = await onCreatingReport(form);
    if (callback) {
      setLoading(false);
    }
  }

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  const onCheckboxClick = (e) => {
    setCheckboxActive(e.target.checked);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <Form.Row>
        {selectedReportType !== "P" &&
        selectedReportType !== "X" &&
        selectedReportType !== "Y" && (
          <>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DATE_FROM"/> *
              </Form.Label>
              <DatePicker
                name="dateFrom"
                selected={fromDate}
                dateFormat="dd.MM.yyyy"
                locale={datepickerLocale}
                showMonthDropdown
                showYearDropdown
                onChange={(date) => onChangingDate("from", date)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DATE_TO"/> *
              </Form.Label>
              <DatePicker
                name="dateTo"
                selected={toDate}
                dateFormat="dd.MM.yyyy"
                locale={datepickerLocale}
                showMonthDropdown
                showYearDropdown
                onChange={(date) => onChangingDate("to", date)}
              />
            </Form.Group>
          </>
        )}
        {selectedReportType === "G" && (
          <Form.Group as={Col} sm={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.LABEL"/>
            </Form.Label>
            <Form.Control
              name="label"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "GENERAL.LABEL",
              })}`}
              ref={register({required: false})}
            />
          </Form.Group>
        )}
        {selectedReportType === "A" && (
          <Form.Group as={Col} sm={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.FIELDS"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="fieldId"
              ref={register({required: true})}
            >
              {formData.fields.length && (
                <>
                  {formData.fields.map((field, index) => (
                    <option key={index} value={field.fieldId} className={!field.isActive ? "row__factory" : ""}>
                      {field.shortName}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType === "F" && (
          <Form.Group as={Col} sm={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CROPPINGS"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="croppingId"
              ref={register({required: true})}
              disabled={croppingsWithTreatmentDataAllowed.length === 0}
            >
              {croppingsWithTreatmentDataAllowed.length && (
                <>
                  {croppingsWithTreatmentDataAllowed.map((cropping, index) => (
                    <option key={index} value={cropping.croppingId}>
                      {cropping.croppingShortName}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "G" || selectedReportType === "L") && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.OVERSEER"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="overseerId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.EVERYONE"
                    key="GENERAL.EVERYONE"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription.brigadierList.map(
                    (overseer) => (
                      <option
                        key={`overseer-${overseer.login.loginId}`}
                        value={overseer.login.loginId}
                      >
                        {`${overseer.firstName} ${overseer.lastName}`}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType !== "A" &&
        selectedReportType !== "F" &&
        selectedReportType !== "H" &&
        selectedReportType !== "P" &&
        selectedReportType !== "X" &&
        selectedReportType !== "Y" &&
        selectedReportType !== "K" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.EMPLOYEE"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="employeeId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.EVERYONE"
                    key="GENERAL.EVERYONE"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.employees.map(
                    (employee) => (
                      <option
                        key={`employee-${employee.id}`}
                        value={employee.id}
                      >
                        {`${employee.firstName} ${employee.lastName}`}  {employee.item ? `(${employee.item})` : ""}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "G" ||
          selectedReportType === "H" ||
          selectedReportType === "I" ||
          selectedReportType === "J" ||
          selectedReportType === "R" ||
          selectedReportType === "K" ||
          selectedReportType === "L") && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CROPPING"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="croppingId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.croppings.map(
                    (cropping) => (
                      <option
                        key={`cropping-${cropping.croppingId}`}
                        value={cropping.croppingId}
                      >
                        {cropping.shortName}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "R" ||
          selectedReportType === "K" ||
          selectedReportType === "G") && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.VARIETY"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="varietyId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.varieties.map(
                    (variety) => (
                      <option
                        key={`cropping-${variety.varietyId}`}
                        value={variety.varietyId}
                      >
                        {variety.shortName}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}

        {selectedReportType === "K" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.SPECIES"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="speciesId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.species.map((species) => (
                    <FormattedMessage
                      id={`${species.sourceLink}`}
                      key={`species-${species.id}`}
                    >
                      {(message) => (
                        <option value={species.id}>{message}</option>
                      )}
                    </FormattedMessage>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "G" ||
          selectedReportType === "I" ||
          selectedReportType === "J" ||
          selectedReportType === "L" ||
          selectedReportType === "O" ||
          selectedReportType === "H" ||
          selectedReportType === "R") && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.TARIFF"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="tariffId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.tariffs.map((tariff) => (
                    <option
                      key={`tariff-${tariff.tariffId}`}
                      value={tariff.tariffId}
                    >
                      {tariff.name}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType !== "A" &&
        selectedReportType !== "F" &&
        selectedReportType !== "G" &&
        selectedReportType !== "M" &&
        selectedReportType !== "O" &&
        selectedReportType !== "P" &&
        selectedReportType !== "X" &&
        selectedReportType !== "Y" &&
        selectedReportType !== "R" &&
        selectedReportType !== "Z" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CURRENCY"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="currencyId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  {selectedReportType !== "S" && (
                    <FormattedMessage
                      id="GENERAL.ALL"
                      key="GENERAL.ALL"
                    >
                      {(message) => (
                        <option value={0}>{message}</option>
                      )}
                    </FormattedMessage>
                  )}
                  {formData.attributeToSubscription?.currencies.map(
                    (currency) => (
                      <option
                        key={`currency-${currency.id}`}
                        value={currency.id}
                      >
                        {currency.name}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "H" ||
          selectedReportType === "K" ||
          selectedReportType === "L" ||
          selectedReportType === "R" ||
          selectedReportType === "O" ||
          selectedReportType === "S") && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.MEASUREMENT_UNIT"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="uomId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  {selectedReportType !== "R" && (
                    <FormattedMessage
                      id="GENERAL.ALL"
                      key="GENERAL.ALL"
                    >
                      {(message) => (
                        <option value={0}>{message}</option>
                      )}
                    </FormattedMessage>
                  )}
                  {formData.attributeToSubscription?.uoms.map((unit) => (
                    <option key={`unit-${unit.id}`} value={unit.id}>
                      {unit.desc}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType === "N" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.OPERATION_TYPE"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="payoutTypeId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.payoutTypes.map(
                    (payout) => (
                      <option key={`payout-${payout.id}`} value={payout.id}>
                        {payout.name}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType === "P" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.JOB_POSITION"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="workSiteId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.worksites.map(
                    (worksite) => (
                      <option
                        key={`position-${worksite.id}`}
                        value={worksite.id}
                      >
                        {worksite.name}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {selectedReportType === "P" && (
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CONTRACT_TYPE"/> *
            </Form.Label>
            <Form.Control
              as="select"
              name="contractTypeId"
              ref={register({required: false})}
            >
              {formData && (
                <>
                  <FormattedMessage
                    id="GENERAL.ALL"
                    key="GENERAL.ALL"
                  >
                    {(message) => (
                      <option value={0}>{message}</option>
                    )}
                  </FormattedMessage>
                  {formData.attributeToSubscription?.contractTypes.map(
                    (contract) => (
                      <option
                        key={`contract-${contract.id}`}
                        value={contract.id}
                      >
                        {contract.name}
                      </option>
                    )
                  )}
                </>
              )}
            </Form.Control>
          </Form.Group>
        )}
        {(selectedReportType === "J" ||
          selectedReportType === "M" ||
          selectedReportType === "P" ||
          selectedReportType === "Z") && (
          <Form.Group as={Col} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="onlyActive"
                  ref={register({required: false})}
                  checked={checkboxActive}
                  onChange={(e) => onCheckboxClick(e)}
                />
              }
              label={`${intl.formatMessage({
                id: "PLACEHOLDER.ONLY_ACTIVE_EMPLOYEES",
              })}.`}
            />
          </Form.Group>
        )}
        {(selectedReportType === "X" ||
          selectedReportType === "Y") && (
          <Form.Group as={Col} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="onlyActive"
                  ref={register({required: false})}
                  checked={checkboxActive}
                  onChange={(e) => onCheckboxClick(e)}
                />
              }
              label={`${intl.formatMessage({
                id: "PLACEHOLDER.ONLY_ACTIVE_FIELDS_AND_CROPS",
              })}.`}
            />
          </Form.Group>
        )}
        <div className="col-xxl-12 text-right">
          <Button type="submit" disabled={loading}>
            <FormattedMessage id="GENERAL.GENERATE"/>
          </Button>
        </div>
      </Form.Row>
    </Form>
  );
}
