import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { RangeFilter } from "../../../components/RangeFilter";
import { MapFieldsTable } from "./MapFieldsTable";
import { MapCroppingsTable } from "./MapCroppingsTable";
import { Tabs, Tab, Row, Form, Col, Button, Container } from "react-bootstrap";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

export function MapFilter(props) {
  const {
    fields,
    croppings,
    showShapesAsMarkers,
    filterFieldsAndCroppings,
    activeTab,
    getPartnersFieldsAndCroppings,
    setMapShapesVisibility,
    centerOnMap,
    setShapesAsMarkers,
    onTogglingEcoCroppings,
    onTogglingActiveFCOnly,
    showOnlyUserFieldsAndCroppings,
    onTogglingUserFCOnly,
    showFieldsAndCroppings,
    showOnlyEcoCroppings,
    showOnlyActiveFieldsAndCroppings,
  } = props;
  const intl = useIntl();
  const [key, setKey] = useState("filters");
  const [filter, setFilter] = useState("");

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSettingFieldsVisibility = (checked) => {
    setMapShapesVisibility({
      showFields: checked,
      showCroppings: showFieldsAndCroppings.showCroppings,
    });
  };

  const onTogglingEcoCroppingsCheckbox = () => {
    onTogglingEcoCroppings();
  };

  const onTogglingActiveFCOnlyCheckbox = () => {
    onTogglingActiveFCOnly();
  };

  const onSettingCroppingsVisibility = (checked) => {
    setMapShapesVisibility({
      showFields: showFieldsAndCroppings.showFields,
      showCroppings: checked,
    });
  };

  useEffect(() => {
    switch (activeTab) {
      case "field":
        setKey("fields");
        break;
      case "crop":
        setKey("croppings");
        break;
      default:
        return;
    }
  }, [activeTab]);

  const showFilters = useSelector((state) => state.cmMaps.showFilters);
  const dispatch = useDispatch();

  const closeFilters = () => {
    dispatch({
      type: "cmMaps/setShowFilters",
      payload: false,
    });
  };

  return (
    <div
      className={`card card-custom gutter-b to-parent-height${
        showFilters ? "" : " d-none"
      }`}
    >
      <Button
        onClick={() => closeFilters()}
        size="sm"
        variant="light"
        className="align-self-start position-relative z-50"
      >
        <i className="fa fa-times p-0" />
      </Button>
      <div className="card-body d-flex flex-column scrollable-tab pt-0 mt-n10">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="ml-3">
          <Tab
            eventKey="filters"
            title={<FormattedMessage id="GENERAL.FILTERS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row>
                <Col className="mb-2 mt-2" md={8}>
                  <Search
                    onChange={onFilterChange}
                    value={filter}
                    placeholder={"GENERAL.FILTER"}
                  />
                </Col>
                <Col className="mb-2 mt-2" md={4}>
                  <Button onClick={() => filterFieldsAndCroppings(filter)}>
                    <FormattedMessage id="GENERAL.FILTER" />
                  </Button>
                </Col>
                <RangeFilter
                  title={"GENERAL.AGRICULTURAL_LAND_IN_PERIOD"}
                  dateChange={getPartnersFieldsAndCroppings}
                />
                <Form.Group as={Col} md={12} className="mb-0">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom ${
                      showFieldsAndCroppings.showFields ||
                      showFieldsAndCroppings.showCroppings
                        ? "checkbox--active "
                        : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showOnlyActiveFieldsAndCroppings}
                        onChange={() => onTogglingActiveFCOnlyCheckbox()}
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_ONLY_ACTIVE_FIELDS_AND_CROPS",
                    })}`}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-0">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom checkbox__irrigation ${
                      showFieldsAndCroppings.showFields ||
                      showFieldsAndCroppings.showCroppings
                        ? "checkbox--active "
                        : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showOnlyUserFieldsAndCroppings}
                        onChange={() => onTogglingUserFCOnly()}
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_ONLY_MY_SHARES",
                    })}`}
                  />
                </Form.Group>

                <Form.Group as={Col} md={12} className="mb-0">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom checkbox__fields ${
                      showFieldsAndCroppings.showFields
                        ? "checkbox--active "
                        : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showFieldsAndCroppings.showFields}
                        onChange={(e) =>
                          onSettingFieldsVisibility(e.target.checked)
                        }
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_FIELDS",
                    })}`}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-0">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom checkbox__croppings ${
                      showFieldsAndCroppings.showCroppings
                        ? "checkbox--active "
                        : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showFieldsAndCroppings.showCroppings}
                        onChange={(e) =>
                          onSettingCroppingsVisibility(e.target.checked)
                        }
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_CROPPINGS",
                    })}`}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-0 pl-10">
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom checkbox__croppings ${
                      showFieldsAndCroppings.showCroppings
                        ? "checkbox--active "
                        : ""
                    }`}
                    control={
                      <Checkbox
                        checked={showOnlyEcoCroppings}
                        onChange={() => onTogglingEcoCroppingsCheckbox()}
                        disabled={!showFieldsAndCroppings.showCroppings}
                      />
                    }
                    label={`${intl.formatMessage({
                      id: "PLACEHOLDER.SHOW_ONLY_ECO_CROPPINGS",
                    })}`}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12} className="text-right mb-0">
                  <Button onClick={() => setShapesAsMarkers()}>
                    {showShapesAsMarkers ? (
                      <FormattedMessage id="GENERAL.RESTORE" />
                    ) : (
                      <FormattedMessage id="GENERAL.SHOW_SHAPES_AS_MARKERS" />
                    )}
                  </Button>
                </Form.Group>
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="fields"
            title={<FormattedMessage id="GENERAL.FIELDS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row className="d-flex flex-column">
                <div className="card__table">
                  <MapFieldsTable fields={fields} centerOnMap={centerOnMap} />
                </div>
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="croppings"
            title={<FormattedMessage id="GENERAL.CROPPINGS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row className="d-flex flex-column">
                <div className="card__table">
                  <MapCroppingsTable
                    croppings={croppings}
                    centerOnMap={centerOnMap}
                  />
                </div>
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
