import axios from "axios";

export const FARM_SHARING_URL = `${process.env.REACT_APP_URL}cropmanager`;

export function getCroppingAndFieldToOrgentity(from, to) {
  return axios.get(`${FARM_SHARING_URL}/farm/shared?from=${from}&to=${to}`);
}

export function getAttributeFromSharedFarm() {
  return axios.get(`${FARM_SHARING_URL}/farm/shared/attribute`);
}
