import axios from "axios";

export const IMAGES_URL = `${process.env.REACT_APP_URL}images`;

export function getImagesCropViewToCropping(croppingId, from, to) {
  return axios.get(`${IMAGES_URL}/cropping/${croppingId}/cropView?from=${from}&to=${to}`);
}

export function updateCropViewImageComment(imageId, comment) {
  return axios.put(`${IMAGES_URL}/${imageId}/comment`, {
    comment
  });
}
