import * as React from "react";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const CropVievImageCommentTooltipOverlay = ({ comment, children }) => {
  const renderTooltip = (props) => <Tooltip {...props}>{comment}</Tooltip>;

  if (comment)
    return (
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        {children}
      </OverlayTrigger>
    );
  else return children;
};

const CropViewImageThumbnail = ({ image }) => {
  const dispatch = useDispatch();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const formatDate = React.useCallback(
    (dateString) => {
      const dateFromString = new Date(dateString);
      return dateFromString
        .toLocaleDateString(languageCode, {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(",", "");
    },
    [languageCode]
  );

  const setSelectedImage = React.useCallback(
    (image) => {
      dispatch({
        type: "cropViewModal/setSelectedImage",
        payload: image,
      });
    },
    [dispatch]
  );

  return (
    <CropVievImageCommentTooltipOverlay comment={image.comment}>
      <div className="p-2 d-flex justify-content-center crop-view__thumb-image">
        <button
          className="border-0 bg-white position-relative d-inline-block"
          onClick={() => setSelectedImage(image)}
        >
          <div className="position-absolute top-0 left-0 h-100 w-100 crop-view__thumb-image__hover-bg d-flex align-items-center justify-content-center">
            <i
              className="fa fa-search text-white"
              style={{ fontSize: "2rem" }}
            />
          </div>
          <img src={image.thumbPath} alt="" />
          <p className="mb-0">{formatDate(image.date)}</p>
        </button>
      </div>
    </CropVievImageCommentTooltipOverlay>
  );
};

const CropViewCameraImageList = ({ images }) => {
  return (
    <div className="m-n2 d-flex flex-wrap">
      {images.map((image) => (
        <CropViewImageThumbnail key={image.imageId} image={image} />
      ))}
    </div>
  );
};

export default function CropViewImages() {
  const intl = useIntl();
  const camerasRedux = useSelector((state) => state.cropViewModal.cameras);
  const key = useSelector((state) => state.cropViewModal.tabKey);
  const dispatch = useDispatch();

  const setKey = React.useCallback(
    (k) => {
      dispatch({
        type: "cropViewModal/setTabKey",
        payload: k,
      });
    },
    [dispatch]
  );

  const cameras = React.useMemo(() => {
    if (camerasRedux.length > 0)
      return camerasRedux.map((cam) => {
        return {
          ...cam,
          wideImages: cam.images.filter((image) => image.camId === 1),
          zoomImages: cam.images.filter((image) => image.camId === 2),
        };
      });
    else return [];
  }, [camerasRedux]);

  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab
          eventKey="wide"
          title={intl.formatMessage({ id: "CROP_VIEW.WIDE_ANGLE" })}
        >
          <div className="pt-2">
            {cameras.map((camera) => (
              <div key={camera.weatherStationId} className="py-4">
                <h6>{camera.desc}</h6>
                {camera.wideImages.length > 0 && (
                  <CropViewCameraImageList images={camera.wideImages} />
                )}
                {camera.wideImages.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <h6>
                      <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
                    </h6>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Tab>
        <Tab
          eventKey="zoom"
          title={intl.formatMessage({ id: "CROP_VIEW.ZOOM" })}
        >
          <div className="pt-2">
            {cameras.map((camera) => (
              <div key={camera.weatherStationId} className="py-4">
                <h6>{camera.desc}</h6>
                {camera.zoomImages.length > 0 && (
                  <CropViewCameraImageList images={camera.zoomImages} />
                )}
                {camera.zoomImages.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <h6>
                      <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
                    </h6>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
