import React from "react";
import { FormattedMessage } from "react-intl";
import { InvitationRow } from "./InvitationRow";
import { Table } from "react-bootstrap";

export const InvitationsTable = ({
  invitations,
  sendInvitation,
  deleteInvitation,
}) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.LOGIN" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ROLE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.STATUS" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {invitations.map((invitation) => (
          <InvitationRow
            key={`invitation-${invitation.invitationId}`}
            invitation={invitation}
            sendInvitation={sendInvitation}
            deleteInvitation={deleteInvitation}
          />
        ))}
      </tbody>
    </Table>
  );
};
