import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Overlay, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function InfoTooltip() {
  const infoTooltipSvg = useRef(null)
  const [showInfoTooltip, setShowInfoTooltip] = useState(false)

  return (
    <>
      <div
        ref={infoTooltipSvg}
        onMouseEnter={() => setShowInfoTooltip(!showInfoTooltip)}
        onMouseLeave={() => setShowInfoTooltip(!showInfoTooltip)}
        className="ml-4"
      >
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")} className="no-pointer-events" />
      </div>
      <Overlay target={infoTooltipSvg.current} show={showInfoTooltip} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...{...props, show: props.show.toString()}}>
            <FormattedMessage id="AUTH.INFO_TOOLTIP_TEXT" />
          </Tooltip>
        )}
      </Overlay>
    </>
);
}

export default InfoTooltip
