import axios from "axios";

export const FILES_URL = `${process.env.REACT_APP_URL}files`;

export function getFileListToSubscription() {
  return axios.get(`${FILES_URL}/subscription`);
}

export function getFileDetails(fileId) {
  return axios.get(`${FILES_URL}/${fileId}`);
}

export function uploadFile(form) {
  return axios.post(`${FILES_URL}/bPartners/save`, form);
}

export function deleteFile(fileId) {
  return axios.delete(`${FILES_URL}/${fileId}/delete`);
}

export function resendFile(fileId) {
  return axios.post(`${FILES_URL}/${fileId}/resend`, null);
}

export function resendFileToBPartner(fileSharingId) {
  return axios.post(`${FILES_URL}/fileSharing/${fileSharingId}/resend`, null);
}

export function downloadFile(fileSharingId, key) {
  return axios.get(`${FILES_URL}/${fileSharingId}/download?key=${key}`, {
    responseType: "blob",
  });
}
