import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

export default function FirstVisitToPulpExchangeModal(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    triggerValidation,
    errors,
    formState,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function handleClose() {
    props.close();
  }

  async function onSubmit(data) {
    setLoading(true);
    if (!acceptTerms) {
      const callback = await props.onAcceptingPhoneNumber(data);
      if (callback === "OK") {
        setAcceptTerms(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      const form = data;
      delete form.repeatPassword;
      const callback = await props.onEnteringConfirmationCode(form);
      if (callback === "OK" || callback === "error") {
        setLoading(false);
      }
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers={true}>
            <DialogContentText>
              <span className="modal__content_header">
                <FormattedMessage id="MENU.PRIVACY_POLICY" />
              </span>
              <span>
                <FormattedMessage id="GENERAL.OUR_PRIVACY_POLICY" />{" "}
                <a
                  href="https://www.suedzucker.pl/polityka-prywatnosci/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="GENERAL.THIS_ADDRESS" />
                </a>
              </span>
              <Form.Row className="mt-2">
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.MOBILE" />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="phone"
                      type="tel"
                      className={`$${
                        errors.phone?.type === "required" ||
                        (formState.touched.phone &&
                          errors.phone?.type === "pattern")
                          ? "is-invalid"
                          : ""
                      } ${
                        formState.touched.phone && !errors.phone
                          ? "is-valid"
                          : ""
                      }`}
                      placeholder={`${intl.formatMessage({
                        id: "PLACEHOLDER.PHONE_NUMBER",
                      })}`}
                      step="1"
                      disabled={acceptTerms}
                      ref={register({
                        required: true,
                        pattern: /^\+?[0-9]{3}-?[0-9]{6,12}$/,
                      })}
                    />
                    {errors.phone?.type === "required" && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                      </div>
                    )}
                    {formState.touched.phone &&
                      errors.phone?.type === "pattern" && (
                        <div className="error invalid-feedback">
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                  </InputGroup>
                </Form.Group>
                {acceptTerms && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.VERIFICATION_CODE" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="pin"
                          type="text"
                          className={`${errors.pin ? "is-invalid" : ""} ${
                            formState.touched.pin && !errors.pin
                              ? "is-valid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "GENERAL.VERIFICATION_CODE",
                          })}`}
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.phone?.pin === "required" && (
                          <div
                            className="error invalid-feedback"
                            style={{ display: "block" }}
                          >
                            <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                          </div>
                        )}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.NEW_PASSWORD" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.NEW_PASSWORD",
                          })}`}
                          ref={register({
                            required: true,
                            minLength: 8,
                            validate: (value) => {
                              if (
                                (value ===
                                  getValues()["passwordConfirmation"] &&
                                  value.length >= 8) ||
                                (getValues()["passwordConfirmation"].length &&
                                  value.length >= 8)
                              ) {
                                triggerValidation("passwordConfirmation");
                                return true;
                              } else if (value.length >= 8) {
                                return true;
                              }
                            },
                          })}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.REPEAT_NEW_PASSWORD" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="passwordConfirmation"
                          type="password"
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.REPEAT_PASSWORD",
                          })}`}
                          ref={register({
                            required: true,
                            validate: (value) => {
                              return value === getValues()["password"];
                            },
                          })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </>
                )}
              </Form.Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit" disabled={loading}>
              {acceptTerms ? (
                <FormattedMessage id="GENERAL.ACCEPT" />
              ) : (
                <FormattedMessage id="GENERAL.CONFIRM" />
              )}
            </Button>
            <Button color="default" disabled={loading} onClick={handleClose}>
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
