import axios from "axios";

export const BPARTNER_URL = `${process.env.REACT_APP_URL}bPartners`;
export const BPARTNER_CROP_MANAGER_URL = `${process.env.REACT_APP_URL}cropmanager/bPartners`;

export function updateOwnBPartners(form) {
  return axios.put(`${BPARTNER_URL}/update`, form);
}

export function getAllByPartners() {
  return axios.get(`${BPARTNER_CROP_MANAGER_URL}/all`);
}
