import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "react-bootstrap";
import { SubscriptionRow } from "./SubscriptionRow";

export function SharedWithUserSubscriptionsTable(props) {
  const { sharedWithUserSubscriptions } = props;
  return (
    <Table className="mt-1" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.JOB_POSITION" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.STATUS" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.START_DATE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.SUBSCRIPTION_EXPIRATION_DATE" />
          </th>
        </tr>
      </thead>
      <tbody>
        {sharedWithUserSubscriptions.map((subscription, index) => (
          <SubscriptionRow
            key={`sharedWithUserSubscriptionRow-${subscription.orgentity2Subscription.subscription.id}-${index}`}
            subscription={subscription}
            shared={true}
          />
        ))}
      </tbody>
    </Table>
  );
}
