import axios from "axios";

export const TARIFFIES_URL = `${process.env.REACT_APP_URL}brigadier/tariffies`;
export const TARIFF_LEVEL_GROUPS_URL = `${process.env.REACT_APP_URL}brigadier/tariffLevelGroups`;
export const TARIFF_GROUPS_URL = `${process.env.REACT_APP_URL}brigadier/tariffGroups`;

export function getTariffiesFormData() {
  return axios.get(`${TARIFFIES_URL}/attribute`);
}

export function getAllTariffies() {
  return axios.get(`${TARIFFIES_URL}/all`);
}

export function createTariff(form) {
  return axios.post(`${TARIFFIES_URL}`, form);
}

export function updateTariff(tariffId, form) {
  return axios.put(`${TARIFFIES_URL}/${tariffId}`, form);
}

export function getTariffLevelGroups() {
  return axios.get(`${TARIFF_LEVEL_GROUPS_URL}/all`);
}

export function createTariffLevelToOrgentity(form) {
  return axios.post(`${TARIFF_LEVEL_GROUPS_URL}`, form);
}

export function deleteTariffLevelGroup(groupId) {
  return axios.delete(`${TARIFF_LEVEL_GROUPS_URL}/${groupId}/delete`);
}

export function updateTariffsToGroup(groupId, form) {
  return axios.put(`${TARIFF_LEVEL_GROUPS_URL}/${groupId}/tariffs`, form);
}

export function updateLevelsToGroup(groupId, form) {
  return axios.put(`${TARIFF_LEVEL_GROUPS_URL}/${groupId}/levels`, form);
}

export function getTariffGroups(employeeId) {
  return axios.get(`${TARIFF_GROUPS_URL}/employee/${employeeId}/all`);
}

export function createTariffGroup(employeeId, form) {
  return axios.post(`${TARIFF_GROUPS_URL}/employee/${employeeId}/create`, form);
}

export function updateTariffGroupTariffs(groupId, form) {
  return axios.put(`${TARIFF_GROUPS_URL}/${groupId}/update/tariffs`, form);
}

export function deleteTariffGroup(id) {
  return axios.delete(`${TARIFF_GROUPS_URL}/${id}/delete`);
}
