import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DocumentForm } from "./DocumentForm";

export function DocumentDetails(props) {
  const {
    mode,
    document,
    businessPartners,
    handleDocumentCreate,
    handleDocumentSend,
    handleDocumentsSend,
    handleDocumentDelete,
  } = props;
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            {mode === "create" ? (
              <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
            ) : (
              <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
            )}
          </span>
          <h3 className="card-label">
            {mode === "create" ? (
              <FormattedMessage id="GENERAL.ADD_DOCUMENT" />
            ) : (
              <>{document.name}</>
            )}
          </h3>
        </div>
      </div>
      <div className="card-body">
        <DocumentForm
          mode={mode}
          document={document}
          businessPartners={businessPartners}
          handleDocumentCreate={handleDocumentCreate}
          handleDocumentSend={handleDocumentSend}
          handleDocumentsSend={handleDocumentsSend}
          handleDocumentDelete={handleDocumentDelete}
        />
      </div>
    </div>
  );
}
