const initialParametersState = {
  loading: true,
  data: undefined,
  selectedSupplier: undefined
};

export const reducer = (state = initialParametersState, action) => {
  switch (action.type) {
    case "suppliers/set": {
      return {
        ...state,
        data: action.payload
      };
    }

    case "suppliers/setLoading": {
      return {
        ...state,
        loading: action.payload
      };
    }

    case "suppliers/setSelectedSupplier": {
      return {
        ...state,
        selectedSupplier: action.payload
      };
    }

    default:
      return state;
  }
};
