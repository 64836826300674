import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { OffersNewOffer } from "./OffersNewOffer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function OffersGrowerOfferDetails(props) {
  const intl = useIntl();
  const [copy, setCopy] = useState(false);
  const { register, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "primaryWeight",
      (props.offer?.primaryWeight - props.offer?.weightSold).toFixed(2)
    );
    setValue("price", props.offer?.price.toFixed(2));
    setValue("comment", props.offer?.comment);
    // eslint-disable-next-line
  }, [props.offer]);

  const onCopyingGrowerData = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Documents.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="OFFERS.SALE_OFFER" />
          </h3>
        </div>
        <div className="card-toolbar">
          <Button variant="primary" onClick={() => onCopyingGrowerData()}>
            <FormattedMessage id="OFFERS.TAKE_ALL" />
          </Button>
        </div>
      </div>
      <div className="card-body">
        <Form>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.WEIGHT" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="primaryWeight"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: true })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.PRICE" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="price"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.PRICE_PER_TON",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: true })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>PLN/t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.COMMENT" />
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="comment"
                disabled
                ref={register({ required: false })}
              />
            </Form.Group>
          </Form.Row>
        </Form>
        <h6>
          <FormattedMessage id="GENERAL.CONTACT_INFORMATION" />
        </h6>
        <Row>
          <Col md={12}>
            {props?.contacts.map((contact) => (
              <div
                key={contact.contactId}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <span className="font-weight-bold mr-2">
                  {contact.type === "T" && (
                    <FormattedMessage id="GENERAL.PHONE" />
                  )}
                  {contact.type === "C" && (
                    <FormattedMessage id="GENERAL.MOBILE" />
                  )}
                  {contact.type === "F" && (
                    <FormattedMessage id="GENERAL.FAX" />
                  )}
                  {contact.type === "M" && (
                    <FormattedMessage id="GENERAL.EMAIL" />
                  )}
                  :
                </span>
                <span style={{ fontWeight: "700" }}>{contact.desc}</span>
              </div>
            ))}
          </Col>
        </Row>
        <OffersNewOffer
          sellOfferId={props.offer?.sellOfferId}
          onCreatingBuyOffer={props.onCreatingBuyOffer}
          growerOffer={props.offer}
          copyOffer={copy}
        />
      </div>
    </div>
  );
}
