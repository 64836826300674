import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DataArchivizationForm } from "./DataArchivizationForm";

export function DataArchivizationCard(props) {
  const { lastArchivizationDate, onArchivingData } = props;

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.ARCHIVE_DATA" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <FormattedMessage id="GENERAL.ARCHIVE_DATA_MESSAGE" />.
        <DataArchivizationForm
          lastArchivizationDate={lastArchivizationDate}
          onArchivingData={onArchivingData}
        />
      </div>
    </div>
  );
}
