import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import usePulpExchangePermission from "../../../hooks/usePulpExchangePermission";
import { JoinRequests } from "../components/JoinRequests";
import { ActiveUsers } from "../components/ActiveUsers";
import { Reports } from "../components/Reports";
import {
  getWaitingSupCustToOrgentity,
  changeSupCustStatus,
  activeSupCust,
  disactiveSupCust,
  getStatusesToSupCust,
  countActiveSupCustToOrgentity,
  countActiveSupCustToOrgentityWithPagination,
} from "../../../services/contractorsCrud";
import {
  getReportData,
  exportReportDataToExcel,
} from "../../../services/reportCrud";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import errorHandler from "../../../shared/errorHandler";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function StockDepartmentPage() {
  const intl = useIntl();
  const [applications, setApplications] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [users, setUsers] = useState([]);
  const [contractorsStatus, setContractorsStatus] = useState([]);
  const [report, setReport] = useState();
  const [exportData, setExportData] = useState();
  const [refresh, setRefresh] = useState();
  const isCoordinator = usePulpExchangePermission("ROLE_COORDINATOR");

  const getApplications = () => {
    getWaitingSupCustToOrgentity()
      .then((response) => {
        setApplications(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getApplications);

  const getPaginationItemsCount = (filter) => {
    let newFilter = "";
    if (filter) {
      newFilter = filter.toLowerCase();
    }
    countActiveSupCustToOrgentity(newFilter)
      .then((response) => {
        const totalRecords = response.data;
        setTotalRecords(totalRecords);
        if (totalRecords > 0) {
          getUsers(0, newFilter);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getPaginationItemsCount);

  const getUsers = (page, filter) => {
    countActiveSupCustToOrgentityWithPagination(page * 5, filter)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onAcceptingOrRejectingApplication = (supCust2statusId, form) => {
    return new Promise((resolve) => {
      changeSupCustStatus(supCust2statusId, form)
        .then((response) => {
          modifyApplications(supCust2statusId);
          resolve("OK");
          setRefresh(true);
          setTimeout(() => {
            setRefresh(false);
          });
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyApplications = (supCust2statusId) => {
    const applicationsCopy = [...applications];
    const filteredUsers = applicationsCopy.filter((application) => {
      return application.supCust2statusId !== parseInt(supCust2statusId);
    });
    setApplications(filteredUsers);
  };

  const onChangingContractorAccountStatusToActive = (supCustId) => {
    return new Promise((resolve) => {
      activeSupCust(supCustId)
        .then((response) => {
          modifyUsers(supCustId, response.data);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onChangingContractorAccountStatusToDisactive = (supCustId) => {
    return new Promise((resolve) => {
      disactiveSupCust(supCustId)
        .then((response) => {
          removeUser(supCustId);
          // modifyUsers(supCustId, response.data);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const removeUser = (supCustId) => {
    const usersCopy = [...users];
    const filteredUsers = usersCopy.filter((user) => {
      return user.supCustId !== parseInt(supCustId);
    });
    setUsers(filteredUsers);
  };

  const modifyUsers = (supCustId, body) => {
    const usersCopy = [...users];
    usersCopy.forEach((user) => {
      if (user.supCustId === parseInt(supCustId)) {
        user.symbol = body.lastStatus.symbol;
      }
    });
    setUsers(usersCopy);
  };

  const getContractorsStatus = () => {
    getStatusesToSupCust()
      .then((response) => {
        const statuses = response.data;
        statuses.push({ supCustStatusId: 2020, desc: "Każdy", symbol: "EV" });
        setContractorsStatus(statuses);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getContractorsStatus);

  const onCreatingReport = (form) => {
    getReportData(form)
      .then((response) => {
        if (form.type === "CT") {
          const data = [];
          response.data.forEach((row) => {
            row.buyOffers.forEach((child, index) => {
              let obj = Object.assign({}, child);
              obj.rowIndex = index;
              obj.bpartnerFullName = row.bpartnerFullName;
              obj.bpartnerShortName = row.bpartnerShortName;
              data.push(obj);
            });
          });
          setReport(data);
        } else {
          setReport(response.data);
        }
        setExportData(form);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const cleanReport = () => {
    setReport(null);
  };

  const onExportingReportToExcel = () => {
    exportReportDataToExcel(exportData)
      .then((response) => {
        let fileName = `report-${String(new Date().toISOString())}.xlsx`;
        if(exportData.type !== null && exportData.type === 'MOBILE_DIAGNOSTIC')
          fileName = `diagnosis-${String(new Date().toISOString())}.xlsx`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <JoinRequests
            applications={applications}
            onAcceptingOrRejectingApplication={
              onAcceptingOrRejectingApplication
            }
          />
        </Col>
        <Col md={12}>
          <ActiveUsers
            users={users}
            totalRecords={totalRecords}
            getUsers={getUsers}
            getPaginationItemsCount={getPaginationItemsCount}
            onChangingContractorAccountStatusToActive={
              onChangingContractorAccountStatusToActive
            }
            onChangingContractorAccountStatusToDisactive={
              onChangingContractorAccountStatusToDisactive
            }
            refresh={refresh}
          />
        </Col>
        <Col xs={12}>
          <Reports
            isCoordinator={isCoordinator}
            status={contractorsStatus}
            report={report}
            onCreatingReport={onCreatingReport}
            cleanReport={cleanReport}
            onExportingReportToExcel={onExportingReportToExcel}
          />
        </Col>
      </Row>
    </>
  );
}

export default StockDepartmentPage;
