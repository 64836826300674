import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Form, Col, InputGroup, Button } from "react-bootstrap";

export function PartnerAssigningNumberForm(props) {
  const { mode, activeFarm, onAssigningPartnerNumber } = props;
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
  } = useForm({ mode: "onChange" });
  const { touched, submitCount } = formState;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !activeFarm.number ? reset() : setFormParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFarm, mode]);

  const setFormParameters = () => {
    setValue("number", activeFarm.number);
    triggerValidation();
  };

  async function onSubmit(data) {
    setLoading(true);
    data.bpartnerId = activeFarm.bpartnerId;
    const callback = await onAssigningPartnerNumber(data);
    callback && setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PARTNER_NUMBER" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="number"
              type="string"
              className={`${
                (touched?.bpartnerNumberId &&
                  getValues("number").length === 0) ||
                (submitCount >= 1 && getValues("number")?.length === 0) ||
                errors.number ||
                (touched?.number && getValues("number").length >= 51) ||
                (submitCount >= 1 && getValues("number")?.length >= 51)
                  ? "is-invalid"
                  : ""
              } ${
                !errors.number && getValues("number")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.PARTNER_NUMBER",
              })}`}
              ref={register({ required: true, maxLength: 50 })}
            />
          </InputGroup>
          {(errors.number?.required ||
            (touched?.number && getValues("number").length === 0) ||
            (submitCount >= 1 && getValues("number")?.length === 0)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
          {(errors.number?.maxLength ||
            (touched?.number && getValues("number").length >= 51) ||
            (submitCount >= 1 && getValues("number")?.length >= 51)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_NAME_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Col md={12} className="text-right">
          <Button
            type="submit"
            disabled={loading || Object.keys(errors).length !== 0}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
