import React, { useState, Suspense } from "react";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { useLocation, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { acceptInvitation } from "../../../services/invitationCrud";
import { Activation } from "../components/Activation";

export const ConfirmationPage = () => {
  const [linkActive, setLinkActive] = useState(null);
  const queryParams = new URLSearchParams(useLocation().search);
  const desktopImage = toAbsoluteUrl("/media/bg/background__desktop.png");

  const checkIfQueryParamExist = () => {
    if (queryParams.get("id") && queryParams.get("key")) {
      onAcceptingInvitation();
    }
  };

  const onAcceptingInvitation = () => {
    acceptInvitation(queryParams.get("id"), queryParams.get("key"))
      .then(() => {
        setLinkActive(true);
      })
      .catch(() => {
        setLinkActive(false);
      });
  };

  useEffectOnce(checkIfQueryParamExist);

  if (!queryParams.get("id")) {
    return <Redirect to="/" />;
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <div
        className="d-flex flex-column flex-root auth__background"
        style={{ backgroundImage: `url(${desktopImage})` }}
      >
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <Activation linkActive={linkActive} />
          </div>
        </div>
      </div>
    </Suspense>
  );
};
