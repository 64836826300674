import React from "react";
import { FormattedMessage } from "react-intl";
import { TariffRow } from "../index";
import Table from "react-bootstrap/Table";

export function TariffsTable({ data, onSelectingRow }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="BRIGADIER.RATE" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((tarrif) => (
          <TariffRow
            key={`tarrif-${tarrif.name}`}
            tarrif={tarrif}
            onSelectingRow={onSelectingRow}
          />
        ))}
      </tbody>
    </Table>
  );
}
