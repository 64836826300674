import React, { useState, useEffect } from "react";
import { Search } from "../../../components/Search";
import { ReportTypesTable } from "./ReportTypesTable";
import { useIntl } from "react-intl";

export function ReportTypesCard({ reportTypes, onSelectingRow }) {
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState(reportTypes || []);
  const intl = useIntl();

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
  }, [reportTypes]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (reportTypes.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return reportTypes.filter((reportType) => {
      const nameIncludes = intl.formatMessage({ id: reportType.name })
        .toLowerCase()
        .includes(text.toLowerCase());
      const descIncludes = intl.formatMessage({ id: reportType.desc })
        .toLowerCase()
        .includes(text.toLowerCase());
      if (nameIncludes || descIncludes) {
        return reportType;
      }
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {reportTypes.length > 0 && (
          <ReportTypesTable
            data={filteredData}
            onSelectingRow={onSelectingRow}
          />
        )}
      </div>
    </div>
  );
}
