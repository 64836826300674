import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const Activation = ({ linkActive }) => {
  const history = useHistory();

  useEffect(() => {
    let redirectTimeout = setTimeout(() => history.push("/"), 6500);
    return () => {
      clearTimeout(redirectTimeout);
    };
  });

  return (
    <div className="auth__panel">
      <div className="auth__form">
        <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
          <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
        </div>
        <div className="auth__heading">
          <h3>
            {linkActive ? (
              <FormattedMessage id="GENERAL.CONGRATULATIONS" />
            ) : (
              <FormattedMessage id="GENERAL.OOPS_SOMETHING_WENT_WRONG" />
            )}
          </h3>
        </div>
        <div
          className="text-center mb-10 mb-lg-10"
          style={{ padding: "0 40px" }}
        >
          {linkActive ? (
            <FormattedMessage id="GENERAL.CONGRATULATIONS_MESSAGE" />
          ) : (
            <FormattedMessage id="GENERAL.OOPS_SOMETHING_WENT_WRONG_MESSAGE" />
          )}
          .
        </div>
        <div>
          <Link
            to="/"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="GENERAL.GO_TO_MAIN_SCREEN" />
          </Link>
        </div>
      </div>
    </div>
  );
};
