import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { WorkersTable } from "../index";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "react-bootstrap";

export function WorkersTableCard({
  mode,
  workers,
  onSelectingRow,
  onChangingMode,
  onOpeningOrClosingLabelsModal,
  onOpeningOrClosingBulkStatusChangeModal,
  selectedWorkerId
}) {
  const intl = useIntl();
  const [filter, setFilter] = useState("");
  const [checked, setChecked] = useState(true);
  const [filteredData, setFilteredData] = useState(workers || []);

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workers, checked]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (workers.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return workers.filter((worker) => {
      const firstNameIncludes = worker.firstName
        .toLowerCase()
        .includes(text.toLowerCase());
      const lastNameIncludes = worker.lastName
        .toLowerCase()
        .includes(text.toLowerCase());
      const itemIncludes = worker.item
        .toLowerCase()
        .includes(text.toLowerCase());
      if (checked) {
        if (
          (firstNameIncludes || lastNameIncludes || itemIncludes) &&
          worker.isActive
        ) {
          return worker;
        }
      } else {
        if (firstNameIncludes || lastNameIncludes || itemIncludes) {
          return worker;
        }
      }
    });
  };

  const handleChange = (event) => {
    setChecked(event.currentTarget.checked);
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {workers.length === 0 ? (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_WORKERS" />
            </h6>
          </div>
        ) : (
          <div className="card__table card__table--brigadier">
            <WorkersTable data={filteredData} onSelectingRow={onSelectingRow} selectedWorkerId={selectedWorkerId} />
          </div>
        )}
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="showActive"
              />
            }
            label={`${intl.formatMessage({
              id: "GENERAL.SHOW_ACTIVE_ONLY",
            })}`}
          />
          <Button
            className="mr-1"
            onClick={() => onChangingMode()}
            disabled={mode === "create"}
          >
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
          <Button
            onClick={() => onOpeningOrClosingLabelsModal()}
            disabled={mode === "create"}
          >
            <FormattedMessage id="GENERAL.GENERATE_EMPLOYEE_LABELS" />
          </Button>
        </div>
        <div className="text-right">
          <Button
            onClick={() => onOpeningOrClosingBulkStatusChangeModal()}
            disabled={mode === "create"}
          >
            <FormattedMessage id="GENERAL.BULK_STATUS_CHANGE" />
          </Button>
        </div>
      </div>
    </div>
  );
}
