import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import { AsideButton } from "../components/AsideButton";
import {
  FieldsTable,
  FieldsGeneralData,
  FieldsMap,
  FieldsRelatedCroppings,
  ShareFieldModal,
} from "../modules/fields";
import {
  getFieldsToLogin,
  createField,
  getFieldDetails,
  updateField,
  getCroppingsToField,
  deleteCreatedField,
} from "../services/fieldsCrud";
import {
  createPolygonToField,
  getShape,
  updatePolygonToField,
  getCentroidPoint,
  getShapeLongestLine,
  getPoint,
} from "../services/shapesCrud";
import {
  getFieldSharingByBPartner,
  createFieldSharingByBPartner,
} from "../services/farmSharingCrud";
import { Row, Col } from "react-bootstrap";
import displayFeedback from "../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../components/helpers/displayFeedbackFromHeaders";
import { getAllSpecies } from "../services/speciesCrud";
import { useDispatch } from "react-redux";

export function FieldsPage(props) {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [fields, setFields] = useState([]);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [shape, setShape] = useState(null);
  const [centroid, setCentroid] = useState(null);
  const [croppings, setCroppings] = useState([]);
  const [fieldLongestLine, setFieldLongestLine] = useState(null);
  const [marker, setMarker] = useState();
  const [businessPartners, setBusinessPartners] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const searchedFieldId = new URLSearchParams(props.location.search).get("id");
  const dispatch = useDispatch();

  const getFields = () => {
    getFieldsToLogin(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setFields(response.data);
        if (response.data.length) {
          if (searchedFieldId) {
            const searchedFieldExist = response.data.filter((row) => {
              return row.fieldId === parseInt(searchedFieldId);
            });
            setSelectedFieldId(searchedFieldExist[0].fieldId);
            onGetFieldDetails(searchedFieldExist[0].fieldId);
          } else {
            setSelectedFieldId(response.data[0].fieldId);
            onGetFieldDetails(response.data[0].fieldId);
          }
          setMode("update");
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getFields);

  const getSharingFieldDataPlan = () => {
    getFieldSharingByBPartner(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setBusinessPartners(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffect(() => {
    if (modalOpen) getSharingFieldDataPlan();
  }, [modalOpen]);

  const getFieldCroppings = (fieldId) => {
    getCroppingsToField(fieldId)
      .then((response) => {
        if (response.data.length) {
          setCroppings(response.data);
        } else {
          setCroppings([]);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onCreatingField = (form) => {
    return new Promise((resolve) => {
      createField(localStorage.getItem("activeFarmId"), form)
        .then((response) => {
          const newFields = [...fields];
          newFields.push(response.data);
          setShape(null);
          setFields(newFields);
          setSelectedFieldId(response.data.fieldId);
          onGetFieldDetails(response.data.fieldId);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onUpdatingField = (form) => {
    return new Promise((resolve) => {
      updateField(fieldData.fieldId, form)
        .then((response) => {
          const newFields = [...fields];
          let searchedField = newFields.filter((row) => {
            return row.fieldId === response.data.fieldId;
          });
          searchedField[0].shortName = response.data.shortName;
          searchedField[0].acreage = response.data.acreage;
          searchedField[0].isActive = response.data.isActive;
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onChangingMode = () => {
    let newMode = mode === "create" ? (newMode = "update") : "create";
    setMode(newMode);
    if (selectedFieldId) {
      setSelectedFieldId(null);
    }
  };

  const onSelectingField = (fieldId) => {
    if (selectedFieldId !== fieldId) {
      setShape(null);
      setSelectedFieldId(fieldId);
      onGetFieldDetails(fieldId);
      setMode("update");
    } else {
      setSelectedFieldId(null);
    }
  };

  const onGetFieldDetails = (fieldId) => {
    getFieldDetails(fieldId)
      .then((response) => {
        setCentroid(null);
        setFieldData(response.data);
        setMode("update");
        getFieldCroppings(fieldId);
        if (response.data.shapeId) {
          onGetFieldShape(response.data.shapeId);
        } else {
          setShape(null);
        }
        response.data.pointId === null
          ? setMarker(null)
          : getShapePoint(response.data.pointId);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onGetFieldShape = (shapeId) => {
    getShape(shapeId)
      .then((response) => {
        response.data.parent.shape.coordinates[0].pop();
        if (response.data.children.length) {
          response.data.children.forEach((child) => {
            child.shape.coordinates[0].pop();
          });
        }
        getCentralPoint(shapeId);
        getShapeLine(shapeId);
        setShape(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onCreatingPolygon = (form) => {
    form.parent.coordinates[0].push(form.parent.coordinates[0][0]);
    return new Promise((resolve) => {
      createPolygonToField(fieldData.fieldId, form)
        .then((response) => {
          onGetFieldDetails(fieldData.fieldId);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onUpdatingPolygon = (form) => {
    if (form.parent !== null) {
      form.parent.coordinates[0].push(form.parent.coordinates[0][0]);
    }
    if (form.children?.length) {
      form.children.forEach((row) => {
        row.shape.coordinates[0].push(row.shape.coordinates[0][0]);
      });
    }
    return new Promise((resolve) => {
      updatePolygonToField(fieldData.fieldId, form)
        .then((response) => {
          if (response.data.parent) {
            getCentralPoint(fieldData.shapeId || shape.parent.shapeId);
          }
          setShape(null);
          setTimeout(() => {
            onGetFieldDetails(fieldData.fieldId);
          });
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const getCentralPoint = (shapeId) => {
    getCentroidPoint(shapeId)
      .then((response) => {
        const centroidPoints = {
          lat: response.data.coordinates[0],
          lng: response.data.coordinates[1],
        };
        setCentroid(centroidPoints);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getShapeLine = (shapeId) => {
    getShapeLongestLine(shapeId)
      .then((response) => {
        setFieldLongestLine(response.data.coordinates);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getShapePoint = (shapeId) => {
    getPoint(shapeId)
      .then((response) => {
        setMarker({
          position: {
            lat: response.data.coordinates[1],
            lng: response.data.coordinates[0],
          },
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const deleteRow = () => {
    return new Promise((resolve) => {
      deleteCreatedField(selectedFieldId)
        .then((response) => {
          const newFields = fields.filter((field) => {
            return field.fieldId !== selectedFieldId;
          });
          setFields(newFields);
          setSelectedFieldId(null);
          setFieldData(null);
          setCroppings([]);
          setShape(null);
          setCentroid(null);
          setFieldLongestLine(null);
          setMarker(null);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onUpdatingFarmSharingPlan = (form) => {
    return new Promise((resolve) => {
      createFieldSharingByBPartner(localStorage.getItem("activeFarmId"), form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getSharingFieldDataPlan();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onNoFarmSelectedError = () => {
    displayFeedback({
      type: "error",
      message: `${intl.formatMessage({ id: "FEEDBACK.FARM_NOT_SELECTED" })}`,
    });
  };

  const onOpeningOrClosingModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    getAllSpecies()
      .then((response) => {
        dispatch({
          type: "fields/setSpecies",
          payload: response.data?.map((species) => ({
            value: species.id,
            label: species.desc,
          })),
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }, [dispatch, intl]);

  return (
    <Row>
      <ShareFieldModal
        open={modalOpen}
        close={onOpeningOrClosingModal}
        businessPartners={[...businessPartners]}
        fields={[...fields]}
        onUpdatingFarmSharingPlan={onUpdatingFarmSharingPlan}
        onNoFarmSelectedError={onNoFarmSelectedError}
      />
      <Col md={12} lg={4} xxl={4}>
        <FieldsTable
          mode={mode}
          fields={fields}
          selectedRow={selectedFieldId}
          onChangingMode={onChangingMode}
          onSelectingRow={onSelectingField}
          deleteRow={deleteRow}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <Row>
          <Col xs={12}>
            <FieldsGeneralData
              mode={mode}
              field={fieldData}
              createField={onCreatingField}
              updateField={onUpdatingField}
            />
          </Col>
          {mode !== "create" && (
            <>
              <Col xs={12}>
                <FieldsMap
                  fieldData={fieldData}
                  fieldShape={shape}
                  fieldMarker={marker}
                  onCreatingPolygon={onCreatingPolygon}
                  onUpdatingPolygon={onUpdatingPolygon}
                  centroid={centroid}
                  fieldLongestLine={fieldLongestLine}
                />
              </Col>
              <Col xs={12}>
                <FieldsRelatedCroppings croppings={croppings} />
              </Col>
            </>
          )}
        </Row>
        <aside className="aside_buttons_container">
          <AsideButton
            parentFunction={onOpeningOrClosingModal}
            tooltipMessage="SHARE_DATA"
            iconImage="flaticon-network"
          />
        </aside>
      </Col>
    </Row>
  );
}
