import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CroppingsTieCrop } from "./CroppingsTieCrop";
import { CroppingRelatedFieldsTable } from "./CroppingRelatedFieldsTable";
import { CroppingsMap } from "./CroppingsMap";

export function CroppingsRelatedFields(props) {
  const [key, setKey] = useState("relatedFields");

  useEffect(() => {
    if (props.cropping?.assignedFields.length === 0) {
      setKey("relatedFields");
    }
  }, [props.cropping?.assignedFields]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.RELATED_FIELDS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        {props.fields?.length > 0 ? (
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab
              eventKey="relatedFields"
              title={<FormattedMessage id="GENERAL.RELATED_FIELDS" />}
            >
              <Row className="mt-5">
                <Col xs={12} className="mb-5 mb-md-0">
                  <CroppingsTieCrop
                    fields={props.fields}
                    cropping={props.cropping}
                    onTyingFieldWithCropping={props.onTyingFieldWithCropping}
                    onMappingFieldShapeOnCropping={
                      props.mapFieldShapeOnCropping
                    }
                  />
                </Col>
                <Col xs={12}>
                  {props.cropping?.assignedFields.length > 0 && (
                    <CroppingRelatedFieldsTable
                      assignedFields={props.cropping.assignedFields}
                      onDeletingFieldRelationWithCropping={
                        props.onDeletingFieldRelationWithCropping
                      }
                    />
                  )}
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey="drawCropping"
              title={<FormattedMessage id="GENERAL.DRAW_CROPPING" />}
              disabled={props.cropping?.assignedFields.length === 0}
            >
              <CroppingsMap
                activeTab={key}
                assignedFields={props.cropping?.assignedFields}
                croppingShape={props.croppingShape}
                croppingMarker={props.croppingMarker}
                fieldLongestLine={props.fieldLongestLine}
                onCreatingPolygon={props.onCreatingPolygon}
                onUpdatingPolygon={props.onUpdatingPolygon}
              />
            </Tab>
          </Tabs>
        ) : (
          <Row>
            <Col xs={12} className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_RELATED_FIELDS" />
              </h6>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
