import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export function ModuleAdvertismentCard(props) {
  const { module, userSubscriptions } = props;
  const dispatch = useDispatch()
  const resetPricingRedux = () => {
    dispatch({
      type: "pricing/reset"
    })
  }

  const [blockBuyButton, setBlockBuyButton] = useState(false);

  useEffect(() => {
    userSubscriptions.forEach((sub) => {
      if (sub.orgentity2Subscription.isActivePayment && sub.orgentity2Subscription.subscription.application.symbol === module.symbol) setBlockBuyButton(true)
    })
  }, [module.symbol, userSubscriptions])

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title card-title--center">
          <h3 className="card-label mr-0">{module.title}</h3>
        </div>
      </div>
      <div className="card-body">
        <div className="card-logo">
          <img
            alt="Logo"
            src={toAbsoluteUrl(`/media/logos/${module.logo}`)}
            width={150}
            height={125}
            style={{ objectFit: "contain" }}
          />
        </div>
        <p className="card-paragraph">{module.description}.</p>
        <div className="d-flex justify-content-center">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={module.url}
            className="btn btn-primary"
            style={{ width: "75px" }}
          >
            <FormattedMessage id="GENERAL.MORE" />
          </a>
          {module.buyId && (
            <Link
              to={`/user/subscriptions/buy/${module.buyId}`}
              className={`btn btn-primary ml-2 ${blockBuyButton ? "disabled" : ""}`}
              style={{ width: "75px" }}
              onClick={resetPricingRedux}
            >
              <FormattedMessage id="GENERAL.BUY" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
