import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getWorkersFormData,
  getEmployeeToOrgentity,
  createEmployee,
  getEmployeeDetails,
  updateEmployee,
  generateLabelToEmployee,
  resendConfirmMailWithPassword,
  updateEmployeeIsActiveStatus,
} from "../../../services/brigadier/workersCrud";
import {
  WorkersTableCard,
  WorkerGeneralDataCard,
  WorkersLabelsCard,
  GenerateWorkerNewLabelModal,
  GenerateWorkersLabelsModal,
} from "../index";
import { Row, Col } from "react-bootstrap";
import { TariffCustomRateCard } from "../components/tariffCustomRate/TariffCustomRateCard";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";
import { BulkStatusChangeModal } from "../modals/BulkStatusChangeModal";

export function WorkersPage() {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState();
  const [workers, setWorkers] = useState([]);
  const [selectedWorkerId, setSelectedWorkerId] = useState();
  const [workerData, setWorkerData] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [labelsModalVisible, setLabelsModalVisible] = useState(false);
  const [
    bulkStatusChangeModalVisible,
    setBulkStatusChangeModalVisible,
  ] = useState(false);
  const [workersWithLabelsToPrint, setWorkersWithLabelsToPrint] = useState([]);

  function getFormData() {
    getWorkersFormData()
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  function getWorkers(refresh) {
    getEmployeeToOrgentity()
      .then((response) => {
        const newWorkers = response.data;
        if (
          response.data.length &&
          newWorkers.filter((worker) => worker.isActive).length > 0
        ) {
          if (refresh) {
            const selectedWorker = workers.find((worker) => worker.isSelected);
            getWorkerDetails(selectedWorker.id);
            setSelectedWorkerId(selectedWorker.id);
            newWorkers.filter(
              (worker) => worker.id === selectedWorker.id
            )[0].isSelected = true;
          } else {
            const workerId = newWorkers.filter((worker) => worker.isActive)[0]
              .id;
            getWorkerDetails(workerId);
            setSelectedWorkerId(workerId);
            newWorkers.filter((worker) => worker.isActive)[0].isSelected = true;
          }
          setWorkers(newWorkers);
          setMode("update");
        } else if (response.data.length) {
          setWorkers(newWorkers);
          setMode("create");
        } else {
          setMode("create");
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  const getWorkerDetails = (workerId) => {
    getEmployeeDetails(workerId)
      .then((response) => {
        setWorkerData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onChangingMode = () => {
    if (mode === "create") {
      setMode("update");
    } else {
      setWorkersWithLabelsToPrint([]);
      setSelectedWorkerId(null);
      setMode("create");
    }
  };

  const onSelectingRow = (id) => {
    const newWorkers = [...workers];
    newWorkers.forEach((worker) => {
      if (worker.id === id) {
        setSelectedWorkerId(id);
        getWorkerDetails(id);
        setMode("update");
      }
    });
    setWorkersWithLabelsToPrint([]);
    setWorkers(newWorkers);
  };

  function onCreatingWorker(form) {
    return new Promise((resolve) => {
      createEmployee(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          setWorkerData(response.data);
          response.data.isSelected = true;
          const newWorkers = [...workers];
          newWorkers.push(response.data);
          setWorkers(newWorkers);
          setMode("update");
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onUpdatingWorker(workerId, form) {
    return new Promise((resolve) => {
      updateEmployee(workerId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          updateObject(workerId, response.data);
          setWorkerData(response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  const updateObject = (workerId, updatedWorker) => {
    const newWorkers = [...workers];
    const searchedWorker = newWorkers.find((worker) => {
      return worker.id === workerId;
    });
    searchedWorker.firstName = updatedWorker.firstName;
    searchedWorker.lastName = updatedWorker.lastName;
    searchedWorker.badge = updatedWorker.badge;
    searchedWorker.item = updatedWorker.item;
    searchedWorker.isActive = updatedWorker.isActive;
    if (updatedWorker.login) searchedWorker.login = updatedWorker.login;
    setWorkers(newWorkers);
  };

  const openOrCloseModal = () => {
    setModalVisible(!modalVisible);
  };

  const onGeneratingNewEmployeeLabel = () => {
    return new Promise((resolve) => {
      generateLabelToEmployee(workerData.id)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          setWorkerData(response.data);
          updateObject(workerData.id, response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onOpeningOrClosingLabelsModal = () => {
    setLabelsModalVisible(!labelsModalVisible);
  };

  const onOpeningOrClosingBulkStatusChangeModal = () => {
    setBulkStatusChangeModalVisible(!bulkStatusChangeModalVisible);
  };

  const onGeneratingEmployeeLabels = (workersForWhomToGenerateLabels) => {
    setWorkersWithLabelsToPrint(workersForWhomToGenerateLabels);
    const msg = {
      messagesuccess: "responsesuccess.WORKERS_LABELS_GENERATED_SUCCESSFULLY",
    };
    displayFeedbackFromHeaders(msg);
  };

  const onChangingStatuses = (workerIds, status) => {
    const form = workerIds.map((id) => ({
      employeeId: id,
      isActive: status === "true" ? true : false,
    }));
    updateEmployeeIsActiveStatus(form)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        getWorkers(true);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onResendingEmail = (employeeId) => {
    return new Promise((resolve) => {
      resendConfirmMailWithPassword(employeeId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  useEffectOnce(getFormData);
  useEffectOnce(getWorkers);

  return (
    <Row>
      <GenerateWorkerNewLabelModal
        open={modalVisible}
        close={openOrCloseModal}
        onGeneratingNewEmployeeLabel={onGeneratingNewEmployeeLabel}
      />
      <GenerateWorkersLabelsModal
        open={labelsModalVisible}
        close={onOpeningOrClosingLabelsModal}
        workers={workers.filter((worker) => {
          return worker.isActive;
        })}
        onGeneratingEmployeeLabels={onGeneratingEmployeeLabels}
      />
      <BulkStatusChangeModal
        open={bulkStatusChangeModalVisible}
        close={onOpeningOrClosingBulkStatusChangeModal}
        workers={workers}
        onChangingStatuses={onChangingStatuses}
      />
      <Col md={12} lg={4} xxl={4}>
        <WorkersTableCard
          mode={mode}
          workers={workers}
          onSelectingRow={onSelectingRow}
          onChangingMode={onChangingMode}
          onOpeningOrClosingLabelsModal={onOpeningOrClosingLabelsModal}
          onOpeningOrClosingBulkStatusChangeModal={
            onOpeningOrClosingBulkStatusChangeModal
          }
          selectedWorkerId={selectedWorkerId}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <WorkerGeneralDataCard
          mode={mode}
          formData={formData}
          activeWorker={workerData}
          onCreatingWorker={onCreatingWorker}
          onUpdatingWorker={onUpdatingWorker}
          openOrCloseModal={openOrCloseModal}
          onResendingEmail={onResendingEmail}
        />
        {mode === "update" && (
          <>
            <TariffCustomRateCard
              formData={formData}
              workerId={workerData?.id}
            />
            <WorkersLabelsCard
              activeWorker={workerData}
              workersWithLabelsToPrint={workersWithLabelsToPrint}
            />
          </>
        )}
      </Col>
    </Row>
  );
}

export default WorkersPage;
