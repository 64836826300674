import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import {
  getAttributeToLoginAndSubscription,
  getReportsToLoginAndSubscription,
  createReport,
  sendReportData,
  exportReportToExcel,
} from "../services/reportCrud";
import {
  ReportTypesCard,
  ReportContainer,
  ShareReportModal,
} from "../modules/reports";
import { Row, Col } from "react-bootstrap";
import displayFeedback from "../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../components/helpers/displayFeedbackFromHeaders";

export function ReportsPage() {
  const intl = useIntl();
  const [reportTypes, setReportTypes] = useState([]);
  const [formData, setFormData] = useState();
  const [reportData, setReportData] = useState();
  const [showModal, setShowModal] = useState(false);

  const getFormData = () => {
    getAttributeToLoginAndSubscription(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getReportTypes = () => {
    getReportsToLoginAndSubscription()
      .then((response) => {
        const newReports = response.data.reports.concat(
          response.data.reportsSubscription
        );
        if (response.data.reports.length) {
          newReports[0].isSelected = true;
          setReportTypes(newReports);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onCreatingReport = (form) => {
    setReportData(null);
    form.reportId = [...reportTypes].find((reportType) => {
      return reportType.isSelected;
    }).id;
    // form.reportId = selectedReportId;
    return new Promise((resolve) => {
      createReport(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          setReportData(response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onOpeningModal = () => {
    setShowModal(true);
  };

  const onClosingModal = () => {
    setShowModal(false);
  };

  const onSendingReport = (form) => {
    return new Promise((resolve) => {
      sendReportData(reportData.reportHistoryId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onExportingReportToXLS = (reportHistoryId) => {
    return new Promise((resolve) => {
      exportReportToExcel(reportHistoryId)
        .then((response) => {
          const fileName = `operations-${new Date().toISOString()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onSelectingRow = (id) => {
    const newReportTypes = [...reportTypes];
    newReportTypes.forEach((reportType) => {
      if (reportType.id === id) {
        reportType.isSelected = true;
      } else {
        reportType.isSelected = false;
      }
    });
    setReportData();
    setReportTypes(newReportTypes);
  };

  useEffectOnce(getFormData);
  useEffectOnce(getReportTypes);

  return (
    <Row>
      <ShareReportModal
        open={showModal}
        close={onClosingModal}
        onSendingReport={onSendingReport}
      />
      <Col md={12} lg={4} xxl={4}>
        <ReportTypesCard
          reportTypes={reportTypes}
          onSelectingRow={onSelectingRow}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        {formData && (
          <ReportContainer
            activeReportType={[...reportTypes].find((reportType) => {
              return reportType.isSelected;
            })}
            formData={formData}
            reportData={reportData}
            onCreatingReport={onCreatingReport}
            onOpeningModal={onOpeningModal}
            onExportingReportToXLS={onExportingReportToXLS}
          />
        )}
      </Col>
    </Row>
  );
}
