import axios from "axios";

export const SUBSCRIPTIONS_URL = `${process.env.REACT_APP_URL}subscriptions`;

export function getSubscriptionToLoginAdmin() {
  return axios.get(`${SUBSCRIPTIONS_URL}/admin`);
}

export function getSubscriptionToLogin() {
  return axios.get(`${SUBSCRIPTIONS_URL}/login/`);
}

export function getSubscriptionWithAttribute() {
  return axios.get(`${SUBSCRIPTIONS_URL}`);
}
