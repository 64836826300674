import { useEffect, useState } from "react";
import _, { toInteger } from "lodash";
import store from "../../redux/store";

function useSubscriptionsList() {
  const [list, setList] = useState([]);
  const storeState = store.getState();

  useEffect(() => {
    const groupedSubscriptions = _.chain(storeState.auth.user.subscriptions)
      .groupBy("subscriptionId")
      .map((value, key) => ({
        subscriptionId: toInteger(key),
        orgEntity: value.filter((val) => {
          return val.symbol !== "GM";
        }),
      }))
      .filter((subscription) => {
        return subscription.orgEntity.length > 0;
      })
      .forEach((subscription) => {
        const filteredOrgEntity = subscription.orgEntity.filter((org) => {
          if (!subscription.subscriptionBuyer) {
            subscription.subscriptionBuyer = org.mainOrgentityDesc;
          }
          return true;
        });
        subscription.orgEntity = filteredOrgEntity;
      })
      .value();
    setList(groupedSubscriptions);
  }, [storeState.auth.user.subscriptions]);

  return list;
}

export default useSubscriptionsList;
