import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { InviteNewFarmMemberForm } from "./InviteNewFarmMemberForm";
import { InvitationsTable } from "./InvitationsTable";
import smoothscroll from 'smoothscroll-polyfill';

export const FarmInvitationsCard = ({
  formData,
  invitations,
  sendInvitation,
  reSendInvitation,
  deleteInvitation,
}) => {
  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      if (window.location.hash === "#farm-invitations") {
        smoothscroll.polyfill()
        const scrollDiv = document.getElementById("farm-invitations").offsetTop
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
      }
    }, 1000)
    return () => clearTimeout(scrollTimer)
  }, [])

  return (
    <div className="card card-custom gutter-b" id="farm-invitations">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/User.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.FARM_INVITATIONS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <InviteNewFarmMemberForm
          formData={formData}
          sendInvitation={sendInvitation}
        />
        {invitations.length > 0 && (
          <InvitationsTable
            invitations={invitations}
            sendInvitation={reSendInvitation}
            deleteInvitation={deleteInvitation}
          />
        )}
      </div>
    </div>
  );
};
