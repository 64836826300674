import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import displayFeedback from "../../../../components/helpers/displayFeedback";
import errorHandler from "../../../../shared/errorHandler";
import {
  createTariffGroup,
  deleteTariffGroup,
  updateTariffGroupTariffs,
} from "../../../../services/brigadier/tariffiesCrud";
import getAvailableTariffs from "../../helpers/getAvailableTariffs";
import ReactSelect from "react-select";
import displayFeedbackFromHeaders from "../../../../components/helpers/displayFeedbackFromHeaders";

export default function TariffCustomRateGroupEditModal({ formData, workerId }) {
  const isOpen = useSelector(
    (state) => state.brigadierCustomTariffRate.groupEditModalOpen
  );
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    control,
    watch,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const [formMode, setFormMode] = useState(null);
  const [selectedTariffs, setSelectedTariffs] = useState();
  const intl = useIntl();
  const [tariffsLoaded, setTariffsLoaded] = useState(false);

  const close = () => {
    setFormMode(null);
    dispatch({
      type: "brigadier/customTariffRates/setGroupEditModalOpen",
      payload: {
        groupEditModalOpen: false,
      },
    });
  };
  const useStyles = makeStyles({
    table: {
      minWidth: 0,
    },
  });

  const rows = useSelector(
    (state) => state.brigadierCustomTariffRate.tariffGroups
  );
  const tariffs = useSelector(
    (state) => state.brigadierCustomTariffRate.tariffs
  );
  const defaultRates = useSelector(
    (state) => state.brigadierCustomTariffRate.workerDefaultRates?.tariffGroups
  );
  const availableTariffs = useMemo(() => {
    if (tariffs?.length > 0)
      return getAvailableTariffs(
        selectedTariffs,
        rows,
        tariffs,
        selectedRow !== null ? rows[selectedRow] : undefined,
        "tariffGroup"
      );
    /*return tariffs.map((tariff, index) => ({
        ...tariff,
        value: tariff.tariffId,
        label: tariff.name,
        isDisabled: !!tariff.tariffGroupId
      }));*/
  }, [rows, selectedRow, selectedTariffs, tariffs]);

  const classes = useStyles();

  const handleRowClick = (index) => {
    setFormMode("edit");
    setSelectedRow(index);
  };

  const handleNewGroupButtonClick = () => {
    setFormMode("add");
    setSelectedRow(null);
    setSelectedTariffs(null);
  };

  const onAddingTariffGroup = () => {
    if (selectedTariffs) {
      setLoading(true);
      const form = {
        tariffIds: selectedTariffs.map((tariff) => tariff.tariffId),
        name: getValues("groupName"),
      };
      createTariffGroup(workerId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type: "brigadier/customTariffRates/addTariffGroup",
            payload: {
              tariffGroup: response.data,
            },
          });
          setSelectedTariffs(null);
          setValue("groupName", "");
          setLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({
                id: errorOptions.message,
              })}`,
            });
          }
          setLoading(false);
        });
    }
  };

  const onUpdatingTariffGroupTariffs = () => {
    if (selectedTariffs && selectedRow !== null) {
      setLoading(true);
      const form = selectedTariffs.map((t) => t.tariffId);
      updateTariffGroupTariffs(rows[selectedRow].tariffGroupId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type: "brigadier/customTariffRates/updateTariffGroup",
            payload: {
              tariffGroup: response.data,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({
                id: errorOptions.message,
              })}`,
            });
          }
          setLoading(false);
        });
    }
  };

  const onDeletingTariffGroup = () => {
    if (selectedRow !== null) {
      setLoading(true);
      deleteTariffGroup(rows[selectedRow].tariffGroupId)
        .then((response) => {
          setFormMode(null);
          setSelectedTariffs(null);
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type: "brigadier/customTariffRates/deleteTariffGroup",
            payload: {
              tariffGroupId: rows[selectedRow].tariffGroupId,
            },
          });
          if (
            defaultRates.length > 0 &&
            defaultRates[0].tariffGroupId === rows[selectedRow].tariffGroupId
          ) {
            dispatch({
              type:
                "brigadier/customTariffRates/deleteWorkerDefaultTariffGroupRate",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({
                id: errorOptions.message,
              })}`,
            });
          }
          setLoading(false);
        });
    }
  };

  const handleSaveButtonClick = () => {
    if (formMode === "edit" && selectedRow !== null)
      onUpdatingTariffGroupTariffs();
    else if (formMode === "add") onAddingTariffGroup();
  };

  useEffect(() => {
    setTariffsLoaded(false);
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow !== null) {
      if (formMode && availableTariffs?.length > 0 && !tariffsLoaded) {
        if (rows[selectedRow]?.tariffList?.length > 0)
          setSelectedTariffs(
            availableTariffs.filter((tariff) =>
              rows[selectedRow]?.tariffList.find(
                (tariffInList) => tariff.tariffId === tariffInList.tariffId
              )
            )
          );
        else setSelectedTariffs(null);
        setTariffsLoaded(true);
      }
    }
  }, [
    availableTariffs,
    formMode,
    rows,
    selectedRow,
    selectedTariffs,
    setValue,
    tariffsLoaded,
  ]);

  const tariffDetails = useMemo(() => {
    if (
      selectedRow !== null &&
      rows.length > 0 &&
      rows[selectedRow]?.tariffList?.length > 0 &&
      formData
    )
      return {
        tariffTypeValue: rows[selectedRow].tariffList[0].tariffTypeValue,
        uomName: formData.uom.find(
          (uom) => uom.id === rows[selectedRow].tariffList[0].uomId
        )?.desc,
        currencyName: formData.currencies.find(
          (currency) =>
            currency.id === rows[selectedRow].tariffList[0].currencyId
        )?.name,
      };
    else if (selectedTariffs?.length > 0 && formData)
      return {
        tariffTypeValue: selectedTariffs[0].tariffTypeValue,
        uomName: formData.uom.find((uom) => uom.id === selectedTariffs[0].uomId)
          ?.desc,
        currencyName: formData.currencies.find(
          (currency) => currency.id === selectedTariffs[0].currencyId
        )?.name,
      };
    else return undefined;
  }, [formData, rows, selectedRow, selectedTariffs]);

  const displayTariffsInRow = useCallback((rowData) => {
    if (rowData.tariffList?.length > 0)
      return rowData.tariffList.map((listItem) => listItem.name).join(", ");
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      className="mui-dialog brigadier-theme"
      classes={{
        paper: "h-100",
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <span className="mui-dialog-popup__title">
          <FormattedMessage id="GENERAL.EDIT_GROUPS" />
        </span>
        <button
          className="bg-transparent border-0 mui-dialog-popup__close-btn"
          onClick={close}
        >
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
        </button>
      </DialogTitle>
      <DialogContent>
        {rows?.length > 0 && (
          <TableContainer component={Paper} style={{ maxHeight: 300 }}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "10%" }}>
                    <FormattedMessage id="GENERAL.ITEM" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="PLACEHOLDER.NAME" />
                  </TableCell>
                  <TableCell style={{ width: "50%" }}>
                    <FormattedMessage id="MENU.TARIFFS" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={`tariffCustomRateGroupEditModal-tableRow-${index}`}
                    onClick={() => handleRowClick(index)}
                    selected={selectedRow === index}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{displayTariffsInRow(row)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {(rows?.length === 0 || !rows) && (
          <div>
            <h6 className="text-center">
              <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
            </h6>
          </div>
        )}
        <div className="mt-4 d-flex justify-content-end">
          <Button size="sm" onClick={handleNewGroupButtonClick}>
            <FormattedMessage id="GENERAL.NEW_GROUP" />
          </Button>
        </div>
        <hr />
        {formMode !== null && (
          <div>
            <h6 className="mb-3 d-flex justify-content-between align-items-center">
              {formMode === "add" && (
                <span>
                  <FormattedMessage id="GENERAL.NEW_GROUP" />
                </span>
              )}
              {formMode === "edit" && selectedRow !== null && (
                <>
                  <span>
                    <FormattedMessage id="GENERAL.GROUP_EDIT" /> {"- "}
                    {rows[selectedRow].name}
                  </span>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={onDeletingTariffGroup}
                    disabled={loading}
                  >
                    <i
                      className="fa fa-trash pr-0"
                      style={{ fontSize: "1rem" }}
                    />
                  </Button>
                </>
              )}
            </h6>
            {formMode === "add" && (
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="PLACEHOLDER.NAME" /> *
                </Form.Label>
                <Form.Control
                  type="text"
                  className={`${errors.groupName ? "is-invalid" : ""}`}
                  name="groupName"
                  ref={register({ required: true })}
                />
                {errors.groupName && errors.groupName?.type === "required" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-2">
              <Form.Label>
                <FormattedMessage id="MENU.TARIFFS" /> *
              </Form.Label>
              <ReactSelect
                options={availableTariffs}
                value={selectedTariffs}
                onChange={setSelectedTariffs}
                placeholder={`${intl.formatMessage({
                  id: "GENERAL.SELECT_TARIFFS",
                })}...`}
                isMulti
              />
            </Form.Group>
            {tariffDetails && (
              <div className="d-flex">
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.TARIFF_TYPE" />
                  {": "}
                  <FormattedMessage
                    id={`GENERAL.${tariffDetails.tariffTypeValue}`}
                  />
                </p>
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" />
                  {": "}
                  {tariffDetails.uomName}
                </p>
                <p className="mr-4">
                  <FormattedMessage id="GENERAL.CURRENCY" />
                  {": "}
                  {tariffDetails.currencyName}
                </p>
              </div>
            )}
            <div className="d-flex justify-content-end mb-3">
              <Button
                disabled={
                  loading ||
                  Object.keys(errors).length !== 0 ||
                  selectedTariffs?.length === 0 ||
                  !selectedTariffs
                }
                onClick={handleSaveButtonClick}
              >
                {formMode === "add" && <FormattedMessage id="GENERAL.SAVE" />}
                {formMode === "edit" && (
                  <FormattedMessage id="GENERAL.UPDATE" />
                )}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
