import React, { useEffect, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { PartnersTable } from "../../../components/PartnersTable";
import { ShareDataTable } from "../../../components/ShareDataTable";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

export function ShareFieldModal(props) {
  const {
    open,
    close,
    businessPartners,
    fields,
    onUpdatingFarmSharingPlan,
    onNoFarmSelectedError
  } = props;
  const [loading, setLoading] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [activeBusinessPartner, setActiveBusinessPartner] = useState(null);

  const filteredFields = useMemo(() => {
    if (fields.length > 0) return fields.filter(field => field.isActive)
    else return []
  }, [fields])

  useEffect(() => {
    if (open) {
      const newPartnersList = businessPartners.map((bPartner) => {
        return {
          bpartnerId: bPartner.bpartnerId,
          name: bPartner.bpartnerFullName,
          fields: bPartner.fields,
        };
      });
      setPartnersList(newPartnersList);
    }
  }, [open, businessPartners]);

  const onCloseModal = () => {
    close();
    setTimeout(() => {
      setActiveBusinessPartner(null);
    }, 200);
  }

  const onSelectingBusinessPartner = (bpartnerId) => {
    const searchedBusinessPartner = partnersList.find((row) => {
      return row.bpartnerId === bpartnerId;
    });
    setActiveBusinessPartner(searchedBusinessPartner);
  };
  
  const changeSpatialDataAllowed = (field) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    const searchedField = searchedBusinessPartner.fields.find((row) => {
      return row.fieldId === field.fieldId;
    });
    searchedField.isSpatialDataAllowed = !searchedField.isSpatialDataAllowed;
    setPartnersList(newPartnersList);
  };

  const addSpatialDataAllowed = (field) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    searchedBusinessPartner.fields.push({
      fieldId: field.fieldId,
      isSpatialDataAllowed: true,
      isTreatmentDataAllowed: false,
    });
    setPartnersList(newPartnersList);
  };

  const changeTreatmentDataAllowed = (field) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    const searchedField = searchedBusinessPartner.fields.find((row) => {
      return row.fieldId === field.fieldId;
    });
    searchedField.isTreatmentDataAllowed = !searchedField.isTreatmentDataAllowed;
    setPartnersList(newPartnersList);
  };

  const addTreatmentDataAllowed = (field) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    searchedBusinessPartner.fields.push({
      fieldId: field.fieldId,
      isSpatialDataAllowed: false,
      isTreatmentDataAllowed: true,
    });
    setPartnersList(newPartnersList);
  };

  async function onUpdatingSharingCroppingsData() {
    setLoading(true);
    if (activeBusinessPartner) {
      const updatedPartnersList = [...partnersList].filter((bPartner) => {
        if (bPartner.fields.length) {
          return bPartner;
        }
      });
      const callback = await onUpdatingFarmSharingPlan(updatedPartnersList);
      if (callback === "OK") {
        setLoading(false);
        onCloseModal();
      } else {
        setLoading(false);
      }
    } else {
      onNoFarmSelectedError();
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.SHARE_FIELD_DATA" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="GENERAL.SHARE_FIELD_DATA_MESSAGE" />.
        </DialogContentText>
        <Row>
          <Col xs={5} className="modal__table">
            <PartnersTable
              partners={partnersList}
              activePartner={activeBusinessPartner}
              onSelectingPartner={onSelectingBusinessPartner}
            />
          </Col>
          <Col xs={7} className="modal__table">
            <ShareDataTable
              data={[...filteredFields]}
              activeBusinessPartner={activeBusinessPartner}
              changeSpatialDataAllowed={changeSpatialDataAllowed}
              addSpatialDataAllowed={addSpatialDataAllowed}
              changeTreatmentDataAllowed={changeTreatmentDataAllowed}
              addTreatmentDataAllowed={addTreatmentDataAllowed}
              disableTreatmentColumn={true}
              isFields={true}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={loading}
          onClick={onUpdatingSharingCroppingsData}
        >
          <FormattedMessage id="GENERAL.ACCEPT" />
        </Button>
        <Button disabled={loading} onClick={onCloseModal}>
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
