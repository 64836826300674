import React, { useState } from "react";
// Services
// Dumb components
import { AvailableModules } from "../modules/modules";
// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function ModulesPage() {
  const [modules] = useState([
    {
      name: "Brigadier",
      description:
        "lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
      link: "link",
      icon: "brigadier-logo",
      price: 19,
    },
  ]);

  return (
    <Row>
      <Col sm={12}>
        <AvailableModules modules={modules} />
      </Col>
    </Row>
  );
}
