export default function getAvailableTariffs(
  selectedTariffs,
  tariffGroups,
  tariffs,
  selectedGroup,
  mode
) {
  const activeTariffs = tariffs.filter((tariff) => tariff.isActive);
  const usedTariffIds =
    tariffGroups?.length > 0
      ? [
          ...new Set(
            [].concat(
              ...tariffGroups
                ?.filter((group) => {
                  if (mode === "tariffLevelGroup")
                    return (
                      group.tariffLevelGroupId !==
                      selectedGroup?.tariffLevelGroupId
                    );
                  if (mode === "tariffGroup")
                    return group.tariffGroupId !== selectedGroup?.tariffGroupId;
                  return null;
                })
                .map((group) => {
                  if (mode === "tariffLevelGroup") return group.tariffIds;
                  if (mode === "tariffGroup")
                    return group.tariffList.map((t) => t.tariffId);
                })
            )
          ),
        ]
      : undefined;
  if ((!selectedTariffs || selectedTariffs?.length === 0) && !selectedGroup) {
    return activeTariffs.map((tariff) => ({
      ...tariff,
      value: tariff.tariffId,
      label: tariff.name,
      isDisabled: usedTariffIds?.includes(tariff.tariffId),
    }));
  } else if (selectedGroup !== undefined && mode === "tariffLevelGroup") {
    return activeTariffs.map((tariff) => ({
      ...tariff,
      value: tariff.tariffId,
      label: tariff.name,
      isDisabled: !!(
        !(
          tariff.tariffTypeId === selectedGroup?.tariffType?.id &&
          tariff.uomId === selectedGroup.uom.id &&
          tariff.currencyId === selectedGroup.currency.id
        ) || usedTariffIds?.includes(tariff.tariffId)
      ),
    }));
  } else if (selectedGroup !== undefined && mode === "tariffGroup") {
    return activeTariffs.map((tariff) => ({
      ...tariff,
      value: tariff.tariffId,
      label: tariff.name,
      isDisabled: !!(
        !(
          tariff.tariffTypeId === selectedGroup?.tariffList[0].tariffTypeId &&
          tariff.uomId === selectedGroup.tariffList[0].uomId &&
          tariff.currencyId === selectedGroup.tariffList[0].currencyId
        ) || usedTariffIds?.includes(tariff.tariffId)
      ),
    }));
  } else {
    return activeTariffs.map((tariff) => ({
      ...tariff,
      value: tariff.tariffId,
      label: tariff.name,
      isDisabled: !!(
        !(
          tariff.tariffTypeId === selectedTariffs[0].tariffTypeId &&
          tariff.uomId === selectedTariffs[0].uomId &&
          tariff.currencyId === selectedTariffs[0].currencyId
        ) || usedTariffIds?.includes(tariff.tariffId)
      ),
    }));
  }
}
