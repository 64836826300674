import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function WorkerCount({ className, data, isOverseer }) {
  return (
    <>
      <div className={`card card-custom ${className ? className : ""}`}>
        <div className="card-body">
          {!isOverseer && (
            <span className="svg-icon svg-icon-3x svg-icon-success">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
              />
            </span>
          )}
          {isOverseer && (
            <i
              className={`fa fa-user-tie text-warning`}
              style={{ fontSize: "35px" }}
            ></i>
          )}
          <div className="text-dark font-weight-bolder font-size-h2 mt-3">
            {data?.length ? data?.length : 0}
          </div>

          <Link
            to="/brigadier/folders/workers"
            className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            {!isOverseer && (
              <FormattedMessage id="BRIGADIER.DASHBOARD.WORKERS_ON_FIELD" />
            )}
            {isOverseer && (
              <FormattedMessage id="BRIGADIER.DASHBOARD.OVERSEERS_ON_FIELD" />
            )}
          </Link>
        </div>
      </div>
    </>
  );
}
