import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { WorkerBarcodePDF, WorkersBarcodesPDF } from "../index";
import { Tabs, Tab } from "react-bootstrap";

export function WorkersLabelsCard({ activeWorker, workersWithLabelsToPrint }) {
  const [key, setKey] = useState("workerLabel");

  useEffect(() => {
    workersWithLabelsToPrint.length === 0
      ? setKey("workerLabel")
      : setKey("workersLabels");
  }, [workersWithLabelsToPrint]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="workerLabel"
            title={<FormattedMessage id="GENERAL.WORKER_LABEL" />}
          >
            {activeWorker && key === "workerLabel" && (
              <WorkerBarcodePDF activeWorker={activeWorker} />
            )}
          </Tab>
          <Tab
            eventKey="workerLabelQr"
            title={<FormattedMessage id="GENERAL.WORKER_LABEL_QR" />}
          >
            {activeWorker && key === "workerLabelQr" && (
              <WorkerBarcodePDF activeWorker={activeWorker} isQrCodePDF />
            )}
          </Tab>
          <Tab
            eventKey="workersLabels"
            title={<FormattedMessage id="GENERAL.WORKERS_LABELS" />}
            disabled={workersWithLabelsToPrint.length === 0}
          >
            {
              (workersWithLabelsToPrint.length > 0 && key === "workersLabels" && (
                <WorkersBarcodesPDF workers={workersWithLabelsToPrint} />
              ))
            }
          </Tab>
          <Tab
            eventKey="workersLabelsQr"
            title={<FormattedMessage id="GENERAL.WORKERS_LABELS_QR" />}
            disabled={workersWithLabelsToPrint.length === 0}
          >
            {
              (workersWithLabelsToPrint.length > 0 && key === "workersLabelsQr" && (
                <WorkersBarcodesPDF workers={workersWithLabelsToPrint} isQrCodePDF />
              ))
            }
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
