import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import RemoveSaleOfferModal from "../modals/RemoveSaleOfferModal";
import ConfirmPaymentModal from "../modals/ConfirmPaymentModal";
import AcceptBuyOfferModal from "../modals/AcceptBuyOfferModal";
import NegotiateBuyOfferModal from "../modals/NegotiateBuyOfferModal";
import RejectBuyOfferModal from "../modals/RejectBuyOfferModal";
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

export function GrowersOfferDetails(props) {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveSaleOfferModal, setRemoveSaleOfferModalOpen] = useState(
    false
  );
  const [openAcceptanceModal, setAcceptanceModalOpen] = useState(false);
  const [openNegotiateModal, setNegotiateModalOpen] = useState(false);
  const [openRejectModal, setRejectModalOpen] = useState(false);
  const [key, setKey] = useState("details");
  const [loading, setLoading] = useState(false);
  const [activeRow, setActiveRow] = useState();
  const [sellOfferId, setSellOfferId] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.refresh) {
      const searchedRow = props.offer.buyOffers.filter((buyOffer) => {
        return buyOffer.buyOfferId === activeRow.buyOfferId;
      });
      setActiveRow(searchedRow[0]);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  useEffect(() => {
    if (activeRow?.buyOfferId) {
      props.offer.buyOffers.forEach((buyOffer) => {
        if (buyOffer.buyOfferId === activeRow.buyOfferId) {
          setActiveRow(buyOffer);
        }
      });
    }
    if (sellOfferId === props.offer?.sellOfferId) {
      setTimeout(() => {
        triggerValidation();
      });
    } else {
      setValue("primaryWeight", props.offer.primaryWeight);
      setValue("price", props.offer.price);
      setValue("comment", props.offer.comment);
      setActiveRow(null);
      setKey("details");
      setSellOfferId(props.offer.sellOfferId);
      setTimeout(() => {
        triggerValidation();
      });
    }
    // eslint-disable-next-line
  }, [props.offer]);

  async function onSubmit(data) {
    setLoading(true);
    const callback = await props.onUpdatingOffer(data);
    if (callback === "OK" || callback === "error") {
      setLoading(false);
    }
  }

  const onRowClick = (row) => {
    if (activeRow?.buyOfferId !== row.buyOfferId) {
      setActiveRow(row);
    } else {
      setActiveRow(null);
    }
  };

  function onOpeningOrClosingRemoveSaleOfferModal() {
    setRemoveSaleOfferModalOpen(!openRemoveSaleOfferModal);
  }

  function onAcceptingOffer() {
    setAcceptanceModalOpen(!openAcceptanceModal);
  }

  function onNegotiatingOffer() {
    setNegotiateModalOpen(!openNegotiateModal);
  }

  function onRejectingOffer() {
    setRejectModalOpen(!openRejectModal);
  }

  const onChangingModalVisibility = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <RemoveSaleOfferModal
        open={openRemoveSaleOfferModal}
        close={onOpeningOrClosingRemoveSaleOfferModal}
        onDeleteOffer={props.onDeleteOffer}
      />
      <ConfirmPaymentModal
        open={openModal}
        close={onChangingModalVisibility}
        licenceId={activeRow?.licenceId}
        onEnteringPhoneNumberForPayment={props.onEnteringPhoneNumberForPayment}
        onEnteringPinForPayment={props.onEnteringPinForPayment}
      />
      <NegotiateBuyOfferModal
        open={openNegotiateModal}
        close={onNegotiatingOffer}
        buyOfferId={activeRow?.buyOfferId}
        onNegotiatingOffer={props.onNegotiatingOffer}
      />
      <RejectBuyOfferModal
        open={openRejectModal}
        close={onRejectingOffer}
        buyOfferId={activeRow?.buyOfferId}
        onRejectingOffer={props.onRejectingOffer}
      />
      <AcceptBuyOfferModal
        open={openAcceptanceModal}
        close={onAcceptingOffer}
        buyOfferId={activeRow?.buyOfferId}
        onAcceptingOffer={props.onAcceptingOffer}
      />
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab
              eventKey="details"
              title={<FormattedMessage id="GENERAL.DETAILS" />}
            >
              <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.WEIGHT" /> *
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="primaryWeight"
                        type="number"
                        className={`${errors.primaryWeight?.type ===
                          "required" && "is-invalid"} ${errors.primaryWeight
                          ?.type === "min" && "is-invalid"} ${errors
                          .primaryWeight?.type === "max" &&
                          "is-invalid"} ${!errors.primaryWeight && "is-valid"}`}
                        placeholder={`${intl.formatMessage({
                          id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                        })}`}
                        step="0.01"
                        disabled={props.offer?.status !== "A"}
                        ref={register({ required: true, min: 0.1 })}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>t</InputGroup.Text>
                      </InputGroup.Append>
                      {errors.primaryWeight?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.primaryWeight?.type === "min" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.PRICE" /> *
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="price"
                        type="number"
                        className={`${(errors.price?.type === "required" ||
                          errors.price?.type === "min") &&
                          "is-invalid"} ${!errors.price && "is-valid"}`}
                        placeholder={`${intl.formatMessage({
                          id: "PLACEHOLDER.PRICE_PER_TON",
                        })}`}
                        step="0.01"
                        disabled={props.offer?.status !== "A"}
                        ref={register({ required: true, min: 0.1 })}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>PLN/t</InputGroup.Text>
                      </InputGroup.Append>
                      {errors.price?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.price?.type === "min" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.COMMENT" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="comment"
                      disabled={props.offer?.status !== "A"}
                      ref={register({ required: false })}
                    />
                  </Form.Group>
                  {props.offer?.status === "A" ? (
                    <div className="col-xxl-12 d-flex align-items-center justify-content-between mb-2">
                      <Button type="submit" disabled={loading}>
                        <FormattedMessage id="GENERAL.SAVE" />
                      </Button>
                      <Button
                        type="button"
                        variant="secondary"
                        disabled={loading}
                        onClick={() => onOpeningOrClosingRemoveSaleOfferModal()}
                      >
                        <FormattedMessage id="GENERAL.DELETE" />
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <h6>
                          <FormattedMessage id="PE.OFFER_HAS_BEEN" />
                          {props.offer?.status === "C" && (
                            <span className="text-lowercase">
                              {" "}
                              <FormattedMessage id="GENERAL.REMOVED" />
                            </span>
                          )}
                          {props.offer?.status === "E" && (
                            <span className="text-lowercase">
                              {" "}
                              <FormattedMessage id="PE.CANCELED_DUE_EXPIRATION" />
                            </span>
                          )}
                          {props.offer?.status === "F" && (
                            <span className="text-lowercase">
                              {" "}
                              <FormattedMessage id="GENERAL.FINISHED" />
                            </span>
                          )}
                        </h6>
                      </div>
                    </>
                  )}
                </Form.Row>
              </Form>
            </Tab>
            <Tab
              eventKey="proposals"
              title={<FormattedMessage id="PE.PURCHASE_OFFERS" />}
              disabled={props.offer?.buyOffers.length <= 0}
            >
              <Table className="mt-5" striped bordered hover responsive>
                <thead className="text-center">
                  <tr>
                    <th>
                      <FormattedMessage id="GENERAL.BUYER" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.WEIGHT" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.PRICE" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.STATUS" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.offer?.buyOffers.length > 0 &&
                    props.offer.buyOffers.map((offer) => (
                      <tr
                        key={offer.buyOfferId}
                        className={`${
                          activeRow?.buyOfferId === offer.buyOfferId
                            ? "offer--active"
                            : ""
                        }`}
                        onClick={() => onRowClick(offer)}
                      >
                        <td className="text-center">
                          {offer.contractor.bpartnerFullName}
                        </td>
                        <td className="text-right">
                          {offer.primaryWeight.toFixed(2)}
                        </td>
                        <td className="text-right">{offer.price.toFixed(2)}</td>
                        <td className="text-center">
                          {offer.offerStatus === "W" && (
                            <Badge pill className="ml-2" variant="info">
                              <FormattedMessage id="GENERAL.WAITING" />
                            </Badge>
                          )}
                          {offer.offerStatus === "A" && (
                            <Badge pill className="ml-2" variant="success">
                              <FormattedMessage id="GENERAL.ACCEPTED" />
                            </Badge>
                          )}
                          {offer.offerStatus === "N" && (
                            <Badge pill className="ml-2" variant="warning">
                              <FormattedMessage id="GENERAL.NEGOTIATION" />
                            </Badge>
                          )}
                          {offer.offerStatus === "R" && (
                            <Badge pill className="ml-2" variant="danger">
                              <FormattedMessage id="GENERAL.REJECTED" />
                            </Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {activeRow && (
                <div className="row">
                  <div className="col-xxl-12">
                    <h6>
                      <FormattedMessage id="GENERAL.CONTACT_INFORMATION" />
                    </h6>
                    <div className="row">
                      <div className="col-xxl-12">
                        {activeRow.contractor && (
                          <>
                            {activeRow.contractor?.contacts.map((contact) => (
                              <div
                                key={contact.contactId}
                                className="d-flex align-items-center justify-content-between mb-2"
                              >
                                <span className="font-weight-bold mr-2">
                                  {contact.type === "T" && (
                                    <FormattedMessage id="GENERAL.PHONE" />
                                  )}
                                  {contact.type === "C" && (
                                    <FormattedMessage id="GENERAL.MOBILE" />
                                  )}
                                  {contact.type === "F" && (
                                    <FormattedMessage id="GENERAL.FAX" />
                                  )}
                                  {contact.type === "M" && (
                                    <FormattedMessage id="GENERAL.EMAIL" />
                                  )}
                                  :
                                </span>
                                <span style={{ fontWeight: "700" }}>
                                  {contact.desc}
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    <h6>
                      <FormattedMessage id="GROWERS.PURCHASE_OFFER" />
                    </h6>
                    <Form>
                      <Form.Row>
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.WEIGHT" />
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="primaryWeight"
                              type="number"
                              className="is-valid"
                              placeholder="Weight in tonnes"
                              step="0.01"
                              disabled
                              value={activeRow.primaryWeight.toFixed(2)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>t</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={6}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.PRICE" />
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="price"
                              type="number"
                              className="is-valid"
                              placeholder="Price per ton"
                              step="0.01"
                              disabled
                              value={activeRow.price.toFixed(2)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>pln/t</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.COMMENT" />
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name="comment"
                            disabled
                            value={activeRow.comment}
                          />
                        </Form.Group>
                      </Form.Row>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        {activeRow.offerStatus === "W" && (
                          <>
                            <Button
                              type="button"
                              disabled={loading}
                              onClick={() => onAcceptingOffer()}
                            >
                              <FormattedMessage id="GENERAL.ACCEPT" />
                            </Button>
                            <Button
                              type="button"
                              disabled={loading}
                              onClick={() => onNegotiatingOffer()}
                            >
                              <FormattedMessage id="GENERAL.NEGOTIATION" />
                            </Button>
                            <Button
                              type="button"
                              variant="secondary"
                              disabled={loading}
                              onClick={() => onRejectingOffer()}
                            >
                              <FormattedMessage id="GENERAL.REJECT" />
                            </Button>
                          </>
                        )}
                        {activeRow.offerStatus && (
                          <h6>
                            {activeRow.offerStatus === "A" && (
                              <>
                                <FormattedMessage id="PE.OFFER_HAS_BEEN_ACCEPTED" />{" "}
                              </>
                            )}
                            {activeRow.offerStatus === "R" && (
                              <>
                                <FormattedMessage id="PE.OFFER_HAS_BEEN_REJECTED" />{" "}
                              </>
                            )}
                            {activeRow.offerStatus !== "W" && (
                              <>
                                {moment(activeRow.statusCreatedAt).format(
                                  "DD.MM.YYYY, HH:mm"
                                )}
                              </>
                            )}
                            {activeRow.offerStatus === "N" && (
                              <>
                                {" "}
                                <FormattedMessage id="PE.NEGOTIATION_STARTED" />
                              </>
                            )}
                          </h6>
                        )}
                      </div>
                      {activeRow.offerStatus === "A" &&
                        activeRow.symbol !== "P" && (
                          <div className="text-right mb-2">
                            <Button
                              type="button"
                              disabled={loading || activeRow.symbol === "P"}
                              onClick={() => onChangingModalVisibility()}
                            >
                              <FormattedMessage id="GROWERS.CHANGE_STATUS_TO_PAID" />
                            </Button>
                          </div>
                        )}
                      {activeRow.symbol === "P" && (
                        <h6>
                          <FormattedMessage id="GROWERS.CHANGED_STATUS_TO_PAID" />
                        </h6>
                      )}
                    </Form>
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
