import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function InvitationRow({
  invitation,
  sendInvitation,
  deleteInvitation,
}) {
  return (
    <tr
      className={`text-center ${invitation.isAccepted === true &&
        "offer--active"} ${invitation.isAccepted === false && "row__factory"}`}
    >
      <td>{invitation.loginShortResDTO.login}</td>
      <td>{invitation.bpartnerRoleResDTO.desc}</td>
      <td>
        {invitation.isAccepted === null && (
          <FormattedMessage id="GENERAL.PENDING" />
        )}
        {invitation.isAccepted === true && (
          <FormattedMessage id="GENERAL.ACCEPTED" />
        )}
        {invitation.isAccepted === false && (
          <FormattedMessage id="GENERAL.REJECTED" />
        )}
      </td>
      <td>
        <div className="d-flex">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="user-tooltip">
                <FormattedMessage id="GENERAL.RE_SEND" />
              </Tooltip>
            }
          >
            <Button
              className="mr-2"
              type="button"
              variant="primary"
              onClick={() => sendInvitation(invitation.invitationId)}
            >
              <i className="far fa-envelope icon-nm pr-0"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="user-tooltip">
                <FormattedMessage id="GENERAL.DELETE" />
              </Tooltip>
            }
          >
            <Button
              type="button"
              variant="secondary"
              onClick={() => deleteInvitation(invitation.invitationId)}
            >
              <i className="fa fa-trash icon-nm pr-0"></i>
            </Button>
          </OverlayTrigger>
        </div>
      </td>
    </tr>
  );
}

InvitationRow.propTypes = {
  invitation: PropTypes.object,
};
