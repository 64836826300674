/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import useEffectOnce from "../../../../../app/hooks/useEffectOnce";
import SVG from "react-inlinesvg";
// import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { getProfileToCurrentLogin } from "../../../../../app/services/usersCrud";
import {
  changePaswordByUser,
  deleteAccountInit,
} from "../../../../../app/modules/auth/_redux/authCrud";
import errorHandler from "../../../../../app/shared/errorHandler";
import { ChangePassword } from "../../../../../app/components/ChangePassword";
import { DeleteAccount } from "../../../../../app/components/DeleteAccount";
import { signOut } from "../../../../../app/modules/auth/_redux/authCrud";
import ToggleTutorials from "../../../../../app/components/ToggleTutorials";
import displayFeedback from "../../../../../app/components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../../../app/components/helpers/displayFeedbackFromHeaders";

export function QuickUser() {
  // const history = useHistory();
  const intl = useIntl();
  const [profileData, setProfileData] = useState();

  // const logoutClick = () => {
  //   const toggle = document.getElementById("kt_quick_user_toggle");
  //   if (toggle) {
  //     toggle.click();
  //   }
  //   history.push("/logout");
  // };

  const getUserProfile = () => {
    getProfileToCurrentLogin()
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getUserProfile);

  const onChangingPassword = (form) => {
    return new Promise((resolve) => {
      changePaswordByUser(form)
        .then((response) => {
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onDeletingAccount = (form) => {
    return new Promise((resolve) => {
      deleteAccountInit(form)
        .then(() => {
          signOut()
            .then(() => {
              localStorage.removeItem("persist:ui");
              localStorage.removeItem("persist:auth");
              localStorage.removeItem("activeFarmId");
              window.location = "/auth/delete-account-info";
            })
            .catch(() => {
              localStorage.removeItem("persist:ui");
              localStorage.removeItem("persist:auth");
              localStorage.removeItem("activeFarmId");
              window.location = "/auth/delete-account-info";
            });
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onLinkClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          <FormattedMessage id="GENERAL.USER_PROFILE" />
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5 symbol-light-success">
            <span className="symbol-label font-size-h1 font-weight-bold">
              {profileData?.firstName ? profileData?.firstName.charAt(0) : "A"}
            </span>
            {/* <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/users/300_21.jpg"
                )})`,
              }}
            /> */}
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {profileData?.firstName} {profileData?.lastName}
            </a>
            {/* <div className="text-muted mt-1">Gurubashi Arena Competitor</div> */}
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {profileData?.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button> */}
          </div>
        </div>

        <div>
          <div className="separator separator-dashed mt-8 mb-5" />
          <div className="navi navi-spacer-x-0 p-0">
            <NavLink
              className="navi-item"
              to="/user/profile"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notification2.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.MY_PROFILE" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="GENERAL.ACCOUNT_SETTINGS_AND_MORE" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/farm"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <i className="fa fa-money-bill-wheat text-warning" />
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.MY_FARM" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="GENERAL.FARM_SETTINGS_AND_MORE" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/provided-information"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Files/Pictures1.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.PROVIDED_INFORMATION" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="GENERAL.SHARED_INFORMATION_SETTINGS" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/subscriptions"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.SUBSCRIPTIONS" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="GENERAL.INFORMATION_ABOUT_SUBSCRIPTIONS" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/help"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-dark-75">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Question-circle.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.HELP_CENTER" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/manuals"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-dark-75">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.MANUALS" />
                  </div>
                </div>
              </div>
            </NavLink>
            <NavLink
              className="navi-item"
              to="/user/orders"
              onClick={onLinkClick}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-dark-75">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Cart1.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <FormattedMessage id="GENERAL.ORDERS" />
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="ml-4 mb-n5">
          <ToggleTutorials />
        </div>
        <div>
          {profileData && (
            <>
              {profileData.bpartners.map((profile, index) => (
                <React.Fragment key={index}>
                  {(profile.groOwnerId || profile.supCustId) && (
                    <div className="separator separator-dashed mt-8 mb-5" />
                  )}
                  {profile.groOwnerId && (
                    <div>
                      <h5 className="mb-5">
                        <FormattedMessage id="GENERAL.GROWER_PROFILE" />
                      </h5>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">
                          <FormattedMessage id="GENERAL.NAME" />:
                        </span>
                        <span style={{ fontWeight: "700" }}>
                          {profile.bpartnerFullName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">
                          <FormattedMessage id="GENERAL.SHORT_NAME" />:
                        </span>
                        <span style={{ fontWeight: "700" }}>
                          {profile.bpartnerShortName}
                        </span>
                      </div>
                      {profile?.growerNo && (
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="font-weight-bold mr-2">
                            <FormattedMessage id="PE.GROWER_NUMBER" />:
                          </span>
                          <span style={{ fontWeight: "700" }}>
                            {profile.growerNo}
                          </span>
                        </div>
                      )}
                      {profile?.pesel && (
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="font-weight-bold mr-2">PESEL:</span>
                          <span style={{ fontWeight: "700" }}>
                            {profile.pesel}
                          </span>
                        </div>
                      )}
                      {profile?.vatin && (
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="font-weight-bold mr-2">NIP:</span>
                          <span style={{ fontWeight: "700" }}>
                            {profile.vatin}
                          </span>
                        </div>
                      )}
                      <h5>
                        <FormattedMessage id="GENERAL.CONTACT_INFORMATION" />
                      </h5>
                      {profile?.contacts.map((contact) => (
                        <div
                          key={contact.contactId}
                          className="d-flex align-items-center justify-content-between mb-2"
                        >
                          <span className="font-weight-bold mr-2">
                            {contact.type === "T" && (
                              <>
                                <i
                                  className="fa fa-phone-alt icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.PHONE" />
                              </>
                            )}
                            {contact.type === "C" && (
                              <>
                                <i
                                  className="fa fa-mobile-alt icon-nm"
                                  style={{ marginRight: "9px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.MOBILE" />
                              </>
                            )}
                            {contact.type === "F" && (
                              <>
                                <i
                                  className="fa fa-fax icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.FAX" />
                              </>
                            )}
                            {contact.type === "M" && (
                              <>
                                <i
                                  className="fa fa-at icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.EMAIL" />
                              </>
                            )}
                            :
                          </span>
                          <span style={{ fontWeight: "700" }}>
                            {contact.desc}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  {profile.supCustId && (
                    <div>
                      <h5 className="mb-5">
                        <FormattedMessage id="GENERAL.CONTRACTOR_PROFILE" />
                      </h5>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">
                          <FormattedMessage id="GENERAL.NAME" />:
                        </span>
                        <span style={{ fontWeight: "700" }}>
                          {profile.bpartnerFullName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">
                          <FormattedMessage id="GENERAL.SHORT_NAME" />:
                        </span>
                        <span style={{ fontWeight: "700" }}>
                          {profile.bpartnerShortName}
                        </span>
                      </div>
                      {profile?.pesel && (
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="font-weight-bold mr-2">PESEL:</span>
                          <span style={{ fontWeight: "700" }}>
                            {profile.pesel}
                          </span>
                        </div>
                      )}
                      {profile?.vatin && (
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <span className="font-weight-bold mr-2">NIP:</span>
                          <span style={{ fontWeight: "700" }}>
                            {profile.vatin}
                          </span>
                        </div>
                      )}
                      <h5>
                        <FormattedMessage id="GENERAL.CONTACT_INFORMATION" />
                      </h5>
                      {profile?.contacts.map((contact) => (
                        <div
                          key={contact.contactId}
                          className="d-flex align-items-center justify-content-between mb-2"
                        >
                          <span className="font-weight-bold mr-2">
                            {contact.type === "T" && (
                              <>
                                <i
                                  className="fa fa-phone-alt icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.PHONE" />
                              </>
                            )}
                            {contact.type === "C" && (
                              <>
                                <i
                                  className="fa fa-mobile-alt icon-nm"
                                  style={{ marginRight: "9px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.MOBILE" />
                              </>
                            )}
                            {contact.type === "F" && (
                              <>
                                <i
                                  className="fa fa-fax icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.FAX" />
                              </>
                            )}
                            {contact.type === "M" && (
                              <>
                                <i
                                  className="fa fa-at icon-nm"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <FormattedMessage id="GENERAL.EMAIL" />
                              </>
                            )}
                            :
                          </span>
                          <span style={{ fontWeight: "700" }}>
                            {contact.desc}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </div>

        <div>
          <div className="separator separator-dashed mt-8 mb-5" />
          <div>
            <h5 className="mb-5">
              <FormattedMessage id="GENERAL.PASSWORD_CHANGE" />
            </h5>
            <div>
              <ChangePassword onChangingPassword={onChangingPassword} />
            </div>
          </div>
        </div>

        <div>
          <div className="separator separator-dashed mt-8 mb-5" />
          <div>
            <h5 className="mb-5">
              <FormattedMessage id="AUTH.DELETE_ACCOUNT" />
            </h5>
            <div>
              <DeleteAccount onDeletingAccount={onDeletingAccount} />
            </div>
          </div>
        </div>

        {/* <div className="navi navi-spacer-x-0 p-0">
          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">
                  Account settings and more{" "}
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Chart-bar1.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/Selected-file.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a>
        </div> */}

        {/* <div className="separator separator-dashed my-7"></div> */}

        {/* <div>
          <h5 className="mb-5">Recent Notifications</h5>

          <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Another purpose persuade
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-success mr-5">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Would be to people
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-success py-1 font-size-lg">
              +50%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
                className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Purpose would be to persuade
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">
              -27%
            </span>
          </div>

          <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                The best product
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">
              +8%
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
