import React from "react";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { signOut } from "../modules/auth/_redux/authCrud";

export function LogoutButton() {
  const onSignOut = () => {
    signOut()
      .then(() => {
        localStorage.removeItem("persist:ui");
        localStorage.removeItem("persist:auth");
        localStorage.removeItem("activeFarmId");
        window.location.reload();
      })
      .catch(() => {
        localStorage.removeItem("persist:ui");
        localStorage.removeItem("persist:auth");
        localStorage.removeItem("activeFarmId");
        window.location.reload();
      });
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="logout-tooltip">
          <FormattedMessage id="GENERAL.LOGOUT" />
        </Tooltip>
      }
    >
      <div className="topbar-item" onClick={() => onSignOut()}>
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
          <>
            <i className="fas fa-sign-out-alt"></i>
          </>
        </div>
      </div>
    </OverlayTrigger>
  );
}
