import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  GoogleMap,
  DrawingManager,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { createPolygonStructureWithHoles } from "../../../utils/mapUtils";

const googleMapStyle = {
  width: "100%",
  height: "340px",
};

const drawingManagerOptions = {
  drawingControl: false,
  drawingControlOptions: {
    drawingModes: ["polygon"],
  },
  polygonOptions: {
    fillColor: "#A52A2A",
    fillOpacity: "0.45",
    strokeColor: "#A52A2A",
    strokeWeight: "3",
    editable: true,
    zIndex: 2,
  },
};

const parentPolygonOptions = {
  fillColor: "#32CD32",
  fillOpacity: "0.45",
  strokeColor: "#32CD32",
  strokeWeight: "3",
  visible: true,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const treatmentPolygonOptions = {
  fillColor: "#A52A2A",
  fillOpacity: "0.45",
  strokeColor: "#A52A2A",
  strokeWeight: "3",
  visible: true,
  editable: true,
  zIndex: 2,
};

export function CroppingsTreatmentsMap(props) {
  const [loading, setLoading] = useState(false);
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [googleMap, setGoogleMap] = useState();
  const [drawingMode, setDrawingMode] = useState("polygon");
  const [drawingManager, setDrawingManager] = useState();
  const [selectedShape, setSelectedShape] = useState();
  const [croppingExist, setCroppingExist] = useState(false);
  const [temporaryChildrenPolygons, setTemporaryChildrenPolygons] = useState(
    []
  );
  const [shapeMap, setShapeMap] = useState();
  const [centerPoint, setCenterPoint] = useState({ lat: 52, lng: 20 });
  const [zoom, setZoom] = useState(6);
  const [croppingsCoordinates, setCroppingsCoordinates] = useState([]);
  const [croppingPolygons, setCroppingPolygons] = useState([]);
  const [canDelete, setCanDelete] = useState(false);
  const [
    mapCroppingShapeAsTreatment,
    setMapCroppingShapeAsTreatment,
  ] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION,
  });

  useEffect(() => {
    setMapCenter();
  }, [props.croppingShape, props.fieldLongestLine]);

  useEffect(() => {
    if (props.resetShape) {
      handleDelete();
    }
  }, [props.resetShape]);

  useEffect(() => {
    if (selectedShape) {
      props.setTreatmentMap(selectedShape);
    }
  }, [selectedShape]);

  useEffect(() => {
    resetStates();
    if (props.croppingShape?.length) {
      setCroppingExist(true);
      onSettingDrawingManager("show");
      onCreatingCroppingPolygons();
    } else {
      setCroppingExist(false);
      onSettingDrawingManager("hide");
    }
  }, [props.croppingShape, drawingManager]);

  const setMapCenter = () => {
    const latLngList = [];
    setTimeout(() => {
      if (props.fieldLongestLine && props.croppingShape) {
        props.fieldLongestLine.forEach((coordinate) => {
          latLngList.push(
            new window.google.maps.LatLng(coordinate[1], coordinate[0])
          );
        });
        let bounds = new window.google.maps.LatLngBounds();
        latLngList.forEach((row) => {
          bounds.extend(row);
        });
        googleMap.setCenter(bounds.getCenter());
        googleMap.fitBounds(bounds);
        googleMap.setZoom(googleMap.getZoom() - 1);
      }
    });
  };

  const resetStates = () => {
    setCanDelete(false);
    if (selectedShape) {
      selectedShape.setMap(null);
      setSelectedShape();
    }
    if (temporaryChildrenPolygons.length) {
      temporaryChildrenPolygons.forEach((child) => {
        child.setMap(null);
      });
      setTemporaryChildrenPolygons([]);
    }
    if (croppingsCoordinates.length) {
      setCroppingsCoordinates([]);
    }
    if (croppingPolygons.length) {
      setCroppingPolygons([]);
    }
  };

  const onSettingDrawingManager = (operationType) => {
    if (drawingManager) {
      if (operationType === "show") {
        drawingManager.setDrawingMode("polygon");
        drawingManager.setOptions({
          drawingControl: true,
        });
      } else {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
      }
    }
  };

  const onCreatingCroppingPolygons = () => {
    let setCenterAndZoom = false;
    let croppingsList = [];
    if (props.croppingShape) {
      props.croppingShape.forEach((child) => {
        const outerCoordinates = createPolygonStructureWithHoles(
          child.shape.coordinates
        );
        croppingsList.push(outerCoordinates);
      });
    }
    props.croppingShape.forEach((cropping) => {
      cropping.shape.coordinates.forEach((row) => {
        row.forEach((coordinate) => {
          if (!setCenterAndZoom) {
            setCenterPoint({ lat: coordinate[1], lng: coordinate[0] });
            setZoom(13);
            setCenterAndZoom = true;
          }
        });
      });
    });
    setCroppingsCoordinates(croppingsList);
  };

  const onDrawingManagerLoad = (drawMan) => {
    setDrawingManager(drawMan);
  };

  const onOverlayComplete = (newOverlay) => {
    let newShape = newOverlay.overlay;
    newShape.type = newOverlay.type;
    window.google.maps.event.addListener(newShape, "click", function() {
      onSettingSelection(newShape);
    });
    onSettingSelection(newShape);
    onSettingDrawingManager("hide");
    setCanDelete(true);
  };

  const onSettingSelection = (shape) => {
    onClearingSelection();
    setSelectedShape(shape);
    setCanDelete(true);
  };

  const onClearingSelection = () => {
    if (selectedShape) {
      setSelectedShape(null);
    }
  };

  const onParentPolygonLoad = (polygon) => {
    let newCroppingPolygons = croppingPolygons;
    newCroppingPolygons.push(polygon);
    setCroppingPolygons(newCroppingPolygons);
  };

  const handleDelete = () => {
    setLoading(true);
    if (selectedShape) {
      setMapCroppingShapeAsTreatment(false);
      setShapeMap(selectedShape.getMap());
      selectedShape.setMap(null);
      onSettingDrawingManager("show");
      setCanDelete(false);
      setLoading(false);
      props.setTreatmentMap(null);
      setDrawingMode("polygon");
    }
  };

  const handleCroppingShapeAsTreatmentBtnClick = () => {
    setMapCroppingShapeAsTreatment(true);
    setDrawingMode(null);
  };

  useEffect(() => {
  }, [mapCroppingShapeAsTreatment, selectedShape])

  const onTreatmentPolygonLoad = (polygon) => {
    if (mapCroppingShapeAsTreatment && selectedShape) {
      selectedShape.setMap(null)
    }
    setSelectedShape(polygon);
    setCanDelete(true);
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      setGoogleMap(mapInstance);
      mapInstance.setOptions({ mapTypeControl: false });
    }
    return (
      <GoogleMap
        mapContainerStyle={googleMapStyle}
        center={centerPoint}
        zoom={zoom}
        mapTypeId={"hybrid"}
        onLoad={onLoad}
      >
        <DrawingManager
          drawingMode={drawingMode}
          options={drawingManagerOptions}
          onLoad={onDrawingManagerLoad}
          onOverlayComplete={onOverlayComplete}
        />
        {croppingsCoordinates.length > 0 && (
          <>
            {croppingsCoordinates.map((coordinates, index) => (
              <Polygon
                key={index}
                paths={coordinates}
                options={parentPolygonOptions}
                onLoad={onParentPolygonLoad}
              />
            ))}
          </>
        )}
        {croppingsCoordinates.length > 0 && mapCroppingShapeAsTreatment && (
          <Polygon
            paths={croppingsCoordinates[0]}
            options={treatmentPolygonOptions}
            onLoad={onTreatmentPolygonLoad}
          />
        )}
      </GoogleMap>
    );
  };

  return (
    <Row>
      <Col xs={12}>{isLoaded ? renderMap() : null}</Col>
      <Col className="text-right mt-5" xs={12}>
        <Button
          variant="secondary"
          disabled={!croppingsCoordinates.length}
          onClick={handleCroppingShapeAsTreatmentBtnClick}
          className="mr-2"
        >
          <FormattedMessage id="GENERAL.MAP_SHAPE" />
        </Button>
        <Button
          variant="secondary"
          disabled={loading || !canDelete}
          onClick={handleDelete}
        >
          <FormattedMessage id="GENERAL.DELETE" />
        </Button>
      </Col>
    </Row>
  );
}
