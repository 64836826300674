import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function PolicyModal(props) {
  function handleClose() {
    props.close();
  }

  function handleClick() {
    props.accept();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="MENU.PRIVACY_POLICY" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{whiteSpace: "pre-wrap"}}>
           
          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§1. "}<FormattedMessage id="POLICY.ADMINISTATION" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.ADMINISTATION_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.ADMINISTATION_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.ADMINISTATION_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="POLICY.ADMINISTATION_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="POLICY.ADMINISTATION_FIVE" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§2. "}<FormattedMessage id="POLICY.PERSONAL_DATA" />
          </span>
          <br />
          <span>
          <FormattedMessage id="POLICY.PERSONAL_DATA_DESC" />
          </span>

          <br />
          <br />

           <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§3. "}<FormattedMessage id="POLICY.RECIPIENT_OF_DATA" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.RECIPIENT_OF_DATA_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.RECIPIENT_OF_DATA_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.RECIPIENT_OF_DATA_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="POLICY.RECIPIENT_OF_DATA_FOUR" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§4. "}<FormattedMessage id="POLICY.PERIOD_DATA_STORAGE" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.PERIOD_DATA_STORAGE_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.PERIOD_DATA_STORAGE_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.PERIOD_DATA_STORAGE_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="POLICY.PERIOD_DATA_STORAGE_FOUR" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§5. "}<FormattedMessage id="POLICY.POLICY_PERMISSION" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.POLICY_PERMISSION_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.POLICY_PERMISSION_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.POLICY_PERMISSION_THREE" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§6. "}<FormattedMessage id="POLICY.DECISION" />
          </span>
          <br />
          <span>
          <FormattedMessage id="POLICY.DECISION_DESC" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center" >
          {"§7. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="POLICY.GOOGLE_ANALYTICS_FIVE" />
          </span>

          <br />
          <br />
          
          <span className="font-weight-bold text-dark-50 pr-1 modal__center" >
          {"§8. "}<FormattedMessage id="POLICY.HOTJAR" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.HOTJAR_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.HOTJAR_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="POLICY.HOTJAR_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="POLICY.HOTJAR_FOUR" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center" >
          {"§9. "}<FormattedMessage id="POLICY.INSPECTLET" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="POLICY.INSPECTLET_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="POLICY.INSPECTLET_TWO" />
          </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
          {props.acceptVisible && (
            <Button onClick={handleClick} color="primary">
              <FormattedMessage id="GENERAL.ACCEPT" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
