import axios from "axios";

export const AGRONOMISTS_URL = `${process.env.REACT_APP_URL}cropmanager/agronomist`;

export function getAllAgronomistToOrgentity() {
  return axios.get(`${AGRONOMISTS_URL}/all`)
}

export function createAgronomist(form) {
  return axios.post(AGRONOMISTS_URL, form)
}

export function deleteAgronomist(loginId) {
  return axios.delete(`${AGRONOMISTS_URL}/${loginId}`)
}