import axios from "axios";

export const WORKERS_URL = `${process.env.REACT_APP_URL}brigadier/employees`;
export const DEFAULT_RATES_URL = `${process.env.REACT_APP_URL}brigadier/defaultRates`;

export function getWorkersFormData() {
  return axios.get(`${WORKERS_URL}/attribute`);
}

export function getEmployeeToOrgentity() {
  return axios.get(`${WORKERS_URL}`);
}

export function createEmployee(form) {
  return axios.post(`${WORKERS_URL}`, form);
}

export function getEmployeeDetails(employeeId) {
  return axios.get(`${WORKERS_URL}/${employeeId}`);
}

export function updateEmployee(employeeId, form) {
  return axios.put(`${WORKERS_URL}/${employeeId}`, form);
}

export function generateLabelToEmployee(employeeId) {
  return axios.post(`${WORKERS_URL}/${employeeId}/label/generate`);
}

export function resendConfirmMailWithPassword(employeeId) {
  return axios.put(`${WORKERS_URL}/${employeeId}/resend`);
}

export function updateEmployeeIsActiveStatus(form) {
  return axios.put(`${WORKERS_URL}/active`, form)
}

export function getEmployeeDefaultRates(employeeId) {
  return axios.get(`${DEFAULT_RATES_URL}/employee/${employeeId}/get`)
}

export function addDefaultRateToEmployee(employeeId, form) {
  return axios.post(`${DEFAULT_RATES_URL}/employee/${employeeId}/create`, form)
}

export function updateDefaultRate(rateId, form) {
  return axios.put(`${DEFAULT_RATES_URL}/${rateId}/update`, form)
}

export function deleteDefaultRate(rateId) {
  return axios.delete(`${DEFAULT_RATES_URL}/${rateId}/delete`)
}
