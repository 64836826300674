import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getFileListToSubscription,
  getFileDetails,
  uploadFile,
  deleteFile,
  resendFile,
  resendFileToBPartner,
} from "../../../services/fileCrud";
import { getAllByPartners } from "../../../services/businessPartnersCrud";
import { DocumentsCard } from "../components/DocumentsCard";
import { DocumentDetails } from "../components/DocumentDetails";
import { Row, Col } from "react-bootstrap";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

const DocumentsPage = () => {
  const intl = useIntl();
  const [documents, setDocuments] = useState([]);
  const [documentData, setDocumentData] = useState();
  const [businessPartners, setBusinessPartners] = useState([]);
  const [mode, setMode] = useState("create");

  const getAllDocuments = () => {
    getFileListToSubscription()
      .then((response) => {
        const newDocuments = modifyRespone(response.data);
        setDocuments(newDocuments);
        if (newDocuments.length) {
          getDocumentDetails(newDocuments[0].id);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const modifyRespone = (collection) => {
    return collection.map((row, index) => {
      return { ...row, isSelected: index === 0 ? true : false };
    });
  };

  useEffectOnce(getAllDocuments);

  const getAllBusinessPartners = () => {
    getAllByPartners()
      .then((response) => {
        setBusinessPartners(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getAllBusinessPartners);

  const getDocumentDetails = (id) => {
    return new Promise((resolve) => {
      getFileDetails(id)
        .then((response) => {
          setDocumentData(response.data);
          setMode("preview");
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const handleRowClick = (id) => {
    const newDocuments = [...documents];
    newDocuments.forEach((document) => {
      if (document.id === id) {
        document.isSelected = true;
        getDocumentDetails(document.id);
      } else {
        document.isSelected = false;
      }
    });
    setDocuments(newDocuments);
  };

  const handleCreateButtonClick = () => {
    const newDocuments = [...documents];
    newDocuments.forEach((document) => {
      document.isSelected = false;
    });
    setDocuments(newDocuments);
    setMode("create");
  };

  const handleDocumentCreate = (form) => {
    return new Promise((resolve) => {
      uploadFile(form)
        .then((response) => {
          const newDocument = { ...response.data, isSelected: true };
          const newDocuments = [...documents];
          newDocuments.forEach((document) => {
            document.isSelected = false;
          });
          newDocuments.push(newDocument);
          setDocuments(newDocuments);
          getDocumentDetails(newDocument.id);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const handleDocumentSend = (id) => {
    return new Promise((resolve) => {
      resendFileToBPartner(id)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const handleDocumentsSend = (id) => {
    return new Promise((resolve) => {
      resendFile(id)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const handleDocumentDelete = (id) => {
    return new Promise((resolve) => {
      deleteFile(id)
        .then((response) => {
          let newDocuments = [...documents].filter((document) => {
            return document.isSelected === false;
          });
          newDocuments[0].isSelected = true;
          setDocuments(newDocuments);
          getDocumentDetails(newDocuments[0].id);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  return (
    <Row>
      <Col md={12} lg={4} xxl={4}>
        <DocumentsCard
          mode={mode}
          documents={documents}
          handleRowClick={handleRowClick}
          handleCreateButtonClick={handleCreateButtonClick}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <DocumentDetails
          mode={mode}
          document={documentData}
          businessPartners={businessPartners}
          handleDocumentCreate={handleDocumentCreate}
          handleDocumentSend={handleDocumentSend}
          handleDocumentsSend={handleDocumentsSend}
          handleDocumentDelete={handleDocumentDelete}
        />
      </Col>
    </Row>
  );
};

export default DocumentsPage;
