import React from "react";
import ReactGA from "react-ga";
import { usePreferences } from "../../../hooks/usePreferences";
import usePulpExchangePermission from "../../../hooks/usePulpExchangePermission";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import GrowersPage from "./GrowersPage";
import OffersPage from "./OffersPage";
import StockDepartmentPage from "./StockDepartmentPage";
import LicencePage from "./LicencePage";

const trackingId = "UA-179931973-1";
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

export function PulpExchangePage() {
  const showPulpExchange = usePreferences("EXCHANGE");
  const isPlanter = usePulpExchangePermission("ROLE_PLANTER");
  const isMaterialRepresentat = usePulpExchangePermission(
    "ROLE_MATERIAL_REPRESENTANT"
  );

  return (
    <>
      {showPulpExchange !== null && (
        <>
          <Switch>
            {showPulpExchange ? (
              <>
                <ContentRoute
                  path="/pulp-exchange/offers"
                  component={OffersPage}
                />
                <ContentRoute
                  path="/pulp-exchange/licence"
                  component={LicencePage}
                />
                {isPlanter && (
                  <ContentRoute
                    path="/pulp-exchange/growers"
                    component={GrowersPage}
                  />
                )}
                {isMaterialRepresentat && (
                  <ContentRoute
                    path="/pulp-exchange/stock-department"
                    component={StockDepartmentPage}
                  />
                )}
              </>
            ) : (
              <Redirect to="/dashboard" />
            )}
          </Switch>
        </>
      )}
    </>
  );
}
