// /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import store from "../../redux/store";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../_metronic/_partials/dropdowns";

function FarmSwitch() {
  const storeState = store.getState();
  const activeFarmId = localStorage.getItem("activeFarmId") || null;
  const [activeFarm, setActiveFarm] = useState(null);
  const [farmList, setFarmList] = useState([]);

  useEffect(() => {
    if (storeState.auth.user && !farmList.length) {
      setFarmList(storeState.auth.user.farms);
      if (activeFarmId) {
        setActiveFarm(
          storeState.auth.user.farms.filter((farm) => {
            return farm.bpartnerId === parseInt(activeFarmId);
          })[0]
        );
      } else {
        setActiveFarm(storeState.auth.user.farms[0]);
        localStorage.setItem(
          "activeFarmId",
          storeState.auth.user.farms[0].bpartnerId
        );
      }
    }
  }, [farmList]);

  const setActiveFarmContext = (farm) => {
    localStorage.setItem("activeFarmId", farm.bpartnerId);
    window.location.reload();
  };

  return (
    <>
      {activeFarm && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-active-farm"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="farm-switch-tooltip">
                  <FormattedMessage id="GENERAL.FARM" />:{" "}
                  {activeFarm.bpartnerFullName}
                </Tooltip>
              }
            >
              <div className="topbar-item">
                <div
                  className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="farm_toggle"
                >
                  <>
                    <span className="symbol symbol-35">
                      <span className="symbol-label font-size-h5">
                        <i className="fa fa-money-bill-wheat text-dark-75" />
                      </span>
                    </span>
                  </>
                </div>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
            <p className="font-weight-bold px-5 my-4">
              <FormattedMessage id="GENERAL.SELECT_FARM" />:
            </p>
            <ul className="navi navi-hover pb-4">
              {farmList.map((farm) => (
                <li key={farm.bpartnerId} className="farm-item">
                  <Dropdown drop="down" alignRight>
                    <Dropdown.Toggle
                      as={DropdownTopbarItemToggler}
                      id="dropdown-toggle-active-test"
                    >
                      <span
                        className={clsx("navi-item", {
                          active: farm.bpartnerId === parseInt(activeFarmId),
                        })}
                      >
                        <a
                          href="#"
                          className="navi-link menu-text"
                          onClick={() => setActiveFarmContext(farm)}
                        >
                          {farm.bpartnerFullName || "Anonymous"}
                        </a>
                      </span>
                    </Dropdown.Toggle>
                  </Dropdown>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default FarmSwitch;
