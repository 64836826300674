import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CroppingsTable } from "./CroppingsTable";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export function CroppingsShortcut({
  croppings,
  onCenteringOnMap,
  showOnlyActiveFieldsAndCroppings,
}) {
  const filteredCroppings = useMemo(() => {
    if (croppings.length > 0 && showOnlyActiveFieldsAndCroppings)
      return croppings.filter((cropping) => cropping.isActive);
    else return croppings;
  }, [croppings, showOnlyActiveFieldsAndCroppings]);

  return (
    <>
      {croppings.length > 0 && (
        <>
          <CroppingsTable
            croppings={filteredCroppings}
            onCenteringOnMap={onCenteringOnMap}
          />
        </>
      )}
      {croppings.length === 0 && (
        <>
          <Col xs={12} className="text-center mt-5">
            <h6>
              <FormattedMessage id="GENERAL.NO_CROPPINGS" />
            </h6>
          </Col>
        </>
      )}
      <Col xs={12} className="mt-2 text-right">
        <Button type="button" variant="primary">
          <Link
            to={`/farm/croppings`}
            target="_blank"
            className="button__link--primary"
          >
            <FormattedMessage id="GENERAL.ADD_CROPPING" />
          </Link>
        </Button>
      </Col>
    </>
  );
}
