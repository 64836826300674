import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";

export function Dropzone(props) {
  const { disabled, file, onAddingFile } = props;
  const [highlight, setHighlight] = useState(false);
  const input = useRef(null);

  const onOpeningDialog = () => {
    if (disabled) return;
    input.current.click();
  };

  const onDragOver = (e) => {
    e.preventDefault();
    if (disabled) return;
    setHighlight(true);
  };

  const onDragLeave = () => {
    setHighlight(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (disabled) return;
    const newFile = e.dataTransfer.files[0];
    if (typeof newFile === "undefined") {
      setHighlight(false);
      return;
    }
    onAddingFile(newFile);
    setHighlight(false);
  };

  const onFileChange = (e) => {
    const newFile = e.target.files[0];
    if (typeof newFile === "undefined") return;
    onAddingFile(newFile);
  };

  return (
    <div
      className={`dropzone ${highlight && "highlight"} ${!disabled &&
        "cursor__pointer"}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onOpeningDialog}
    >
      <input
        className="dropzone__input"
        type="file"
        ref={input}
        onChange={onFileChange}
      />
      <i className="fas fa-upload fa-3x" />
      <span>
        {file ? (
          <>{file.name}</>
        ) : (
          <FormattedMessage id="GENERAL.UPLOAD_FILE" />
        )}
      </span>
    </div>
  );
}
