import React, { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTariffies,
  getTariffGroups,
} from "../../../../services/brigadier/tariffiesCrud";
import displayFeedback from "../../../../components/helpers/displayFeedback";
import errorHandler from "../../../../shared/errorHandler";
import { getEmployeeDefaultRates } from "../../../../services/brigadier/workersCrud";
import { TariffCustomRateFormForTariffGroup } from "./TariffCustomRateFormForTariffGroup";
import TariffCustomRateGroupEditModal from "./TariffCustomRateGroupEditModal";
import { NewTariffCustomRateForm } from "./NewTariffCustomRateForm";

export function TariffCustomRateCard({ formData, workerId }) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const defaultRates = useSelector(
    (state) => state.brigadierCustomTariffRate.workerDefaultRates?.tariffGroups
  );

  const getTariffs = useCallback(() => {
    getAllTariffies()
      .then((response) => {
        if (response.data.length) {
          dispatch({
            type: "brigadier/customTariffRates/setTariffs",
            payload: { tariffs: response.data },
          });
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }, [dispatch, intl]);

  const getTariffGroupsToState = useCallback(
    (workerId) => {
      getTariffGroups(workerId)
        .then((response) => {
          dispatch({
            type: "brigadier/customTariffRates/setTariffGroups",
            payload: { tariffGroups: response.data },
          });
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    },
    [dispatch, intl]
  );

  const getWorkerDefaultRatesToState = useCallback(
    (workerId) => {
      getEmployeeDefaultRates(workerId)
        .then((response) => {
          dispatch({
            type: "brigadier/customTariffRates/setWorkerDefaultRates",
            payload: { workerDefaultRates: response.data },
          });
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    },
    [dispatch, intl]
  );

  useEffect(() => {
    if (workerId) {
      getTariffs();
      getTariffGroupsToState(workerId);
    }
  }, [dispatch, getTariffGroupsToState, getTariffs, workerId]);
  useEffect(() => {
    if (workerId) getWorkerDefaultRatesToState(workerId);
  }, [getWorkerDefaultRatesToState, workerId]);

  return (
    <>
      <TariffCustomRateGroupEditModal formData={formData} workerId={workerId} />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="agrico-svg-icon svg-icon svg-icon-dark-75 menu-icon mr-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
            <h3 className="card-label">
              <FormattedMessage id="BRIGADIER.INDIVIDUAL_TARIFF_RATES" />
            </h3>
          </div>
        </div>
        <div className={`card-body ${defaultRates?.length > 0 ? "mb-n3" : "mb-n7"}`}>
          <NewTariffCustomRateForm formData={formData} workerId={workerId} />
          {defaultRates?.length > 0 &&
            defaultRates.map((rate, index) => (
              <TariffCustomRateFormForTariffGroup
                key={`tariffCustomRateGroup-${rate.defaultRateId}`}
                rate={rate}
                formData={formData}
                workerId={workerId}
              />
            ))}
        </div>
      </div>
    </>
  );
}
