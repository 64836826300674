import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

export function GrowersTransactionsReport(props) {
  return (
    <>
      {props.report ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="GENERAL.STATUS" />
              </th>
              <th>
                <FormattedMessage id="PE.SUGAR_FACTORY" />
              </th>
              <th>
                <FormattedMessage id="PE.GROWER_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.GROWER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.STATUS_SYMBOL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.AUTHORIZATION_CODE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.COMMENT" />
              </th>
              <th>
                <FormattedMessage id="PE.TRANSACTION_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.LIMIT" /> [t]
              </th>
              <th>
                <FormattedMessage id="PE.BUY_OFFER_ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.OFFER_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.EXPIRATION_DATE" />
              </th>
              <th>
                <FormattedMessage id="PE.CONTRACTOR_ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.CONTRACTOR" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.NIP" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PESEL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ADDRESS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.TELEPHONE_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.WEIGHT" /> [t]
              </th>
              <th>
                <FormattedMessage id="GENERAL.PRICE" /> [PLN/t]
              </th>
            </tr>
          </thead>
          <tbody>
            {props.report.map((row, index) => (
              <tr key={index}>
                <td className="text-center">
                  {row.sellOfferStatus === "A" && (
                    <Badge pill variant="info">
                      <FormattedMessage id="GENERAL.PUBLISHED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "C" && (
                    <Badge pill variant="danger">
                      <FormattedMessage id="GENERAL.REMOVED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "E" && (
                    <Badge pill variant="dark">
                      <FormattedMessage id="GENERAL.EXPIRED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "F" && (
                    <Badge pill variant="success">
                      <FormattedMessage id="GENERAL.FINISHED" />
                    </Badge>
                  )}
                </td>
                <td className="text-center">{row.orgentityDesc}</td>
                <td className="text-center">{row.growerNo}</td>
                <td className="text-center">{row.groOwnerFullName}</td>
                <td className="text-center">
                  {row.sellOfferStatus === "A" && (
                    <Badge pill variant="info">
                      A
                    </Badge>
                  )}
                  {row.sellOfferStatus === "C" && (
                    <Badge pill variant="danger">
                      C
                    </Badge>
                  )}
                  {row.sellOfferStatus === "E" && (
                    <Badge pill variant="dark">
                      E
                    </Badge>
                  )}
                  {row.sellOfferStatus === "F" && (
                    <Badge pill variant="success">
                      F
                    </Badge>
                  )}
                </td>
                <td className="text-center">{row.pin}</td>
                <td className="text-center">{row.comment}</td>
                <td className="text-center">
                  {moment(row.buyOfferCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                {/*  */}
                <td className="text-right">{row.limit}</td>
                <td className="text-right">{row.buyOfferId}</td>
                <td className="text-center">
                  {moment(row.buyOfferCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">
                  {moment(row.expiredDate).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">{row.supCustId}</td>
                <td className="text-center">{row.supCustFullName}</td>
                <td className="text-center">{row.vatin}</td>
                <td className="text-center">{row.pesel}</td>
                <td className="text-center">
                  {row.postalCode} {row.address1}, {row.address2}
                </td>
                <td className="text-right">{row.phoneNumber}</td>
                <td className="text-right">{row.buyOfferWeight}</td>
                <td className="text-right">{row.buyOfferPrice}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2">
          <h6>
            <FormattedMessage id="PE.NO_REPORTS" />
          </h6>
        </div>
      )}
    </>
  );
}
