import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getBPartnerNumbersAll,
  updateBPartnerNumber,
} from "../../../services/crop-manager/partnerNumberCrud";
import { FarmsCard, PartnerGeneralCard } from "../index";
import { Row, Col } from "react-bootstrap";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

const GrowersPage = () => {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [farms, setFarms] = useState([]);

  const getAllBPartnersNumber = () => {
    getBPartnerNumbersAll()
      .then((response) => {
        const newFarms = modifyRespone(response.data);
        setFarms(newFarms);
        newFarms.length && setMode("update");
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const modifyRespone = (collection) => {
    return collection.map((row, index) => {
      return { ...row, isSelected: index === 0 ? true : false };
    });
  };

  const handleRowClick = (id) => {
    const newFarms = [...farms];
    newFarms.forEach((farm) => {
      if (farm.bpartnerId !== id) {
        farm.isSelected = false;
      } else {
        farm.isSelected = true;
        setMode("update");
      }
    });
    setFarms(newFarms);
  };

  const onAssigningPartnerNumber = (form) => {
    return new Promise((resolve) => {
      updateBPartnerNumber(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          updateFarm(form);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const updateFarm = (form) => {
    const newFarms = [...farms];
    const searchedFarm = newFarms.find((farm) => {
      return farm.bpartnerId === form.bpartnerId;
    });
    searchedFarm.number = form.number;
    setFarms(newFarms);
  };

  useEffectOnce(getAllBPartnersNumber);

  return (
    <Row>
      <Col md={12} lg={4} xxl={4}>
        <FarmsCard farms={farms} handleRowClick={handleRowClick} />
      </Col>
      {mode === "update" && (
        <Col md={12} lg={8} xxl={8}>
          <PartnerGeneralCard
            mode={mode}
            activeFarm={[...farms].find((farm) => {
              return farm.isSelected;
            })}
            onAssigningPartnerNumber={onAssigningPartnerNumber}
          />
        </Col>
      )}
    </Row>
  );
};

export default GrowersPage;
