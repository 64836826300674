import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "react-bootstrap";
import { ExclusiveFieldDataRequestsTable } from "./ExclusiveFieldDataRequestsTable";
import { HintText } from "./HintText";

export function ExclusiveFieldDataRequestsContainer({
  exclusiveRequests,
  acceptExclusiveRequest,
  rejectExclusiveRequest,
}) {
  return (
    <Row className="mb-7">
      <Col xs={12}>
        <h6 className="mb-3">
          <FormattedMessage id="GENERAL.EXCLUSIVE_DATA_REQUESTS" />:
        </h6>
      </Col>
      <Col xs={12}>
        {exclusiveRequests?.length > 0 ? (
          <ExclusiveFieldDataRequestsTable
            exclusiveRequests={exclusiveRequests}
            acceptExclusiveRequest={acceptExclusiveRequest}
            rejectExclusiveRequest={rejectExclusiveRequest}
          />
        ) : (
          <div className="text-center bg-light p-3 rounded">
            <h6 className="mb-0">
              <FormattedMessage id="GENERAL.NO_EXCLUSIVE_DATA_REQUESTS" />
            </h6>
          </div>
        )}
        {exclusiveRequests?.length > 0 && <HintText label="TABLE_ROW_HINT" />}
      </Col>
    </Row>
  );
}
