import React, { useEffect, useState, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import plLocale from "@fullcalendar/core/locales/pl";
import enLocale from "@fullcalendar/core/locales/en-au";
import deLocale from "@fullcalendar/core/locales/de";
import ruLocale from "@fullcalendar/core/locales/ru";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import getTreatmentDetails from "../helpers/getTreatmentDetails";
import { addDays } from "date-fns";

export function Calendar() {
  const intl = useIntl();
  const agricoCalendar = useRef(null);
  const [locale, setLocale] = useState(plLocale);
  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.events);
  const eventsLoading = useSelector((state) => state.calendar.eventsLoading);
  const [headerToolbar, setHeaderToolbar] = useState(null);

  const customButtons = {
    add: {
      click: function() {
        const date1 = new Date().toISOString().slice(0, 10);
        const date2 = addDays(new Date(), 1).toISOString().slice(0, 10);;
        onOpeningModalForNewTreatment(date1, date2);
      },
      icon: "plus fa fa-plus"
    },
  };

  const setHeaderToolbarType = (width) => {
    if (width > 768)
      setHeaderToolbar({
        start: "prevYear prev",
        center: "title add",
        end: "today next nextYear",
      });
    else
      setHeaderToolbar({
        start: "prevYear prev today next nextYear",
        center: "title add",
        end: null,
      });
  };

  const handleResize = useCallback((e) => {
    setHeaderToolbarType(e.target.innerWidth);
  }, []);

  useEffect(() => {
    setHeaderToolbarType(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      setLocale(enLocale);
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      setLocale(plLocale);
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      setLocale(deLocale);
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      setLocale(ruLocale);
    }
    // eslint-disable-next-line
  }, []);

  const onOpeningModalForNewTreatment = (start, end) => {
    dispatch({
      type: "calendar/setFormDates",
      payload: [start, end],
    });
    dispatch({
      type: "calendar/setCreateModalOpen",
      payload: true,
    });
  };

  const handleClick = (info) => {
    onOpeningModalForNewTreatment(info.startStr, info.endStr);
  };

  const onClickingEventForUpdating = (eventInfo) => {
    getTreatmentDetails(eventInfo.event._def.extendedProps.treatmentHeaderId);
  };

  const onChangingDates = (dateInfo) => {
    dispatch({
      type: "calendar/setDates",
      payload: dateInfo,
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body position-relative">
        {eventsLoading && (
          <div className="calendar__loading-screen">
            <CircularProgress />
          </div>
        )}
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          locale={locale}
          headerToolbar={headerToolbar}
          customButtons={customButtons}
          buttonText={{
            today: `${intl.formatMessage({
              id: "GENERAL.TODAY",
            })}`,
            month: "month",
            week: "week",
            day: "day",
            list: "list",
          }}
          height="64vh"
          initialView="dayGridMonth"
          fixedWeekCount={true}
          showNonCurrentDates={true}
          firstDay={1}
          navLinks={false}
          weekNumbers={true}
          weekText=""
          dayMaxEventRows={(true, 2)}
          events={events}
          selectable={true}
          datesSet={(dateInfo) => onChangingDates(dateInfo)}
          select={(selectionInfo) => handleClick(selectionInfo)}
          eventClick={(eventClickInfo) =>
            onClickingEventForUpdating(eventClickInfo)
          }
          ref={agricoCalendar}
        />
      </div>
    </div>
  );
}

// https://fullcalendar.io/docs/navLinks - do zastanowienia się
// https://fullcalendar.io/docs/v4/react
// https://preview.keenthemes.com/metronic/demo4/features/calendar/basic.html
// https://stackoverflow.com/questions/56548754/creating-fullcalendar-custom-buttons-in-react
// https://stackoverflow.com/questions/57674268/cant-go-to-a-specific-date-on-full-calendar
