import axios from "axios";

export const FIELDS_URL = `${process.env.REACT_APP_URL}fields`;

export function getFieldsToLogin(farmId) {
  return axios.get(`${FIELDS_URL}/bPartner/${farmId}/all`);
}

export function createField(farmId, form) {
  return axios.post(`${FIELDS_URL}/bPartner/${farmId}/create`, form);
}

export function getFieldDetails(fieldId) {
  return axios.get(`${FIELDS_URL}/${fieldId}`);
}

export function updateField(fieldId, form) {
  return axios.put(`${FIELDS_URL}/${fieldId}/update`, form);
}

export function getCroppingsToField(fieldId) {
  return axios.get(`${FIELDS_URL}/${fieldId}/croppings`);
}

export function getFieldsToBPartnerWithShape(farmId, showPoints) {
  return axios.get(
    `${FIELDS_URL}/bPartner/${farmId}/shape/all?withPoints=${showPoints}&withNoActive=true`
  );
}

export function deleteCreatedField(fieldId) {
  return axios.delete(`${FIELDS_URL}/${fieldId}/delete`);
}
