import React from "react";
import { FormattedMessage } from "react-intl";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function FieldsTable({ fields, onCenteringOnMap }) {
  return (
    <Table className="mt-5" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.SHORT_NAME" />
          </th>
          {/* <th>
            <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
          </th> */}
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field) => (
          <tr key={field.fieldId} className="text-center">
            <td>{field.shortName}</td>
            {/* <td>{field.shapeArea.toFixed(2)}</td> */}
            <td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.SHOW_ON_MAP" />
                  </Tooltip>
                }
              >
                <Button
                  onClick={() =>
                    onCenteringOnMap(
                      field.shape
                        ? field.shape.parent.shapeId
                        : field.point.shapeId
                    )
                  }
                >
                  <i
                    className="flaticon2-location"
                    style={{ paddingLeft: "4px" }}
                  />
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
