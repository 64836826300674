import axios from "axios";

export const CHEMISTRY_URL = `${process.env.REACT_APP_URL}chemistries`;

export function getAllFertilizerChemistry() {
  return axios.get(`${CHEMISTRY_URL}/fertilizer`);
}

export function getAllPlantProtectionChemistry() {
  return axios.get(`${CHEMISTRY_URL}/sor`);
}

export function getChemistryDetails(chemistryId) {
  return axios.get(`${CHEMISTRY_URL}/${chemistryId}`);
}
