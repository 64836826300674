import React from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { OperationsFilterForm } from "./OperationsFilterForm";
import { OperationRow } from "./OperationRow";
import Table from "react-bootstrap/Table";

export function WorkerOperationsTable({
  operations,
  getWorkerOperations,
  onSelectingBalanceValue,
}) {
  return (
    <div>
      <div>
        <OperationsFilterForm getWorkerOperations={getWorkerOperations} />
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th scope="colgroup" colSpan={3}>
              {operations.length > 0 && (
                <>
                  {moment(operations[0].dateFrom)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD")}
                </>
              )}
            </th>
            <th scope="colgroup" colSpan={3}>
              {operations.length > 0 && (
                <>
                  {operations[0].dateFrom} - {operations[0].dateTo}
                </>
              )}
            </th>
            <th scope="colgroup" colSpan={3}>
              {operations.length > 0 && (
                <>{moment(new Date()).format("YYYY-MM-DD")}</>
              )}
            </th>
            <th scope="col" rowSpan={2}>
              <FormattedMessage id="GENERAL.CURRENCY" />
            </th>
          </tr>
          <tr>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_DUE" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_PAID" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.BALANCE" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_DUE" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_PAID" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.BALANCE" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_DUE" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.AMOUNT_PAID" />
            </th>
            <th scope="col">
              <FormattedMessage id="GENERAL.BALANCE" />
            </th>
          </tr>
        </thead>
        <tbody>
          {operations.length > 0 && (
            <>
              {operations.map((operation, index) => (
                <OperationRow
                  key={`operation-${index}`}
                  operation={operation}
                  index={index}
                  onSelectingBalanceValue={onSelectingBalanceValue}
                />
              ))}
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
}
