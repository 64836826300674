import React, { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { PackagingLabelsTable } from "../index";
import { BarcodesPDF } from "../index";
import {
  Tabs,
  Tab,
  Form,
  Button,
  ButtonGroup,
  Row,
  Col,
} from "react-bootstrap";

export function PackagingLabelsCard({
  activePackaging,
  barcodesLabels,
  onSelectingLabelsToPrint,
  onDeselectingLabelsToPrint,
  onDeletingLabels,
  onGeneratingLabelsBarcodes,
  onGeneratingLabelsToExcel,
  isQrCodePDF,
}) {
  const intl = useIntl();
  const [key, setKey] = useState("labelsTable");
  const [loading, setLoading] = useState(false);
  const [
    deleteSelectedButtonBlocked,
    setDeleteSelectedButtonBlocked,
  ] = useState(false);
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [filteredData, setFilteredData] = useState(
    activePackaging.labels || []
  );
  const [showAll, setShowAll] = useState(true);
  const [anyLabelSelected, setAnyLabelSelected] = useState(false);

  useEffect(() => {
    barcodesLabels.length === 0 ? setKey("labelsTable") : setKey("barcodes");
  }, [barcodesLabels]);

  const filterDataset = useCallback(
    (filterFrom, filterTo) => {
      return activePackaging.labels.filter((label) => {
        if (filterFrom && filterTo) {
          if (
            Number(label.item) >= Number(filterFrom) &&
            Number(label.item) <= Number(filterTo)
          ) {
            return label;
          }
        } else if (filterFrom || filterTo) {
          if (label.item.includes(filterFrom ? filterFrom : filterTo)) {
            return label;
          }
        } else return label;
      });
    },
    [activePackaging.labels]
  );

  useEffect(() => {
    if (activePackaging.labels?.length) {
      const filteredArray = filterDataset(filterFrom, filterTo);
      !showAll
        ? setFilteredData(filterToPrintDataset(filteredArray))
        : setFilteredData(filteredArray);
    }
  }, [activePackaging, filterFrom, filterTo, filterDataset, showAll]);

  useEffect(() => {
    setAnyLabelSelected(false);
  }, [activePackaging.id]);

  useEffect(() => {
    if (filteredData.filter((label) => label.print).length > 0)
      setAnyLabelSelected(true);
    else setAnyLabelSelected(false);
  }, [filteredData]);

  const onChangingShowAllLabels = () => {
    const filteredArray = filterDataset(filterFrom, filterTo);
    let filteredToPrintArray;
    if (showAll) {
      filteredToPrintArray = filterToPrintDataset(filteredArray);
    }
    setFilteredData(
      filteredToPrintArray ? filteredToPrintArray : filteredArray
    );
    setShowAll(!showAll);
  };

  const filterToPrintDataset = (filteredArray) => {
    return filteredArray.filter((label) => {
      return label.printerCount === 0;
    });
  };

  const onSelectingAllLabels = () => {
    const labelIds = filteredData.map((label) => {
      return label.id;
    });
    onSelectingLabelsToPrint(labelIds);
    setAnyLabelSelected(true);
  };

  const onDeselectingAllLabels = () => {
    const labelIds = filteredData.map((label) => {
      return label.id;
    });
    onDeselectingLabelsToPrint(labelIds);
    setAnyLabelSelected(false);
  };

  async function generateLabelsBarcodes(isQr) {
    setLoading(true);
    const callback = await onGeneratingLabelsBarcodes(isQr);
    if (callback) {
      setLoading(false);
      setAnyLabelSelected(false);
    }
  }

  async function generateLabelsToExcel() {
    setLoading(true);
    const callback = await onGeneratingLabelsToExcel();
    if (callback) {
      setLoading(false);
      setAnyLabelSelected(false);
    }
  }

  async function handleDeleteSelectedButtonClick() {
    setDeleteSelectedButtonBlocked(true);
    const callback = await onDeletingLabels(
      filteredData.filter((label) => label.print).map((label) => label.id)
    );
    if (callback) {
      setDeleteSelectedButtonBlocked(false);
    }
  }

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Row>
            <Col sm={6} md={3} className="mb-4">
              <Search
                onChange={(e) => setFilterFrom(e.target.value)}
                value={filterFrom}
                width={100}
                disabled={key === "barcodes" ? true : false}
                className="mr-2"
                placeholder="GENERAL.SEARCH_FROM"
              />
            </Col>
            <Col sm={6} md={3} className="mb-4">
              <Search
                onChange={(e) => setFilterTo(e.target.value)}
                value={filterTo}
                width={100}
                disabled={key === "barcodes" ? true : false}
                placeholder="GENERAL.SEARCH_TO"
              />
            </Col>
            <Col sm={6} md={3} className="mb-4">
              <Form.Check
                type="switch"
                id="showAllForPrinting"
                checked={!showAll}
                label={`${intl.formatMessage({
                  id: "PLACEHOLDER.SHOW_LABELS_FOR_PRINTING",
                })}`}
                onChange={onChangingShowAllLabels}
                disabled={key === "barcodes"}
              />
            </Col>
            <Col sm={6} md={3} className="mb-4">
              <ButtonGroup>
                <Button
                  onClick={() =>
                    !anyLabelSelected
                      ? onSelectingAllLabels()
                      : onDeselectingAllLabels()
                  }
                  disabled={key === "barcodes"}
                >
                  {!anyLabelSelected ? (
                    <FormattedMessage id="GENERAL.SELECT_ALL" />
                  ) : (
                    <FormattedMessage id="GENERAL.DESELECT_ALL" />
                  )}
                </Button>
                <Button
                  onClick={handleDeleteSelectedButtonClick}
                  disabled={!anyLabelSelected || deleteSelectedButtonBlocked}
                  variant="light"
                >
                  <FormattedMessage id="GENERAL.DELETE_SELECTED" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="labelsTable"
            title={<FormattedMessage id="GENERAL.PACKAGING_LABELS" />}
          >
            {!activePackaging.labels ||
            activePackaging.labels?.length === 0 ||
            filteredData?.length === 0 ? (
              <div className="text-center">
                <h6 className="my-5">
                  <FormattedMessage id="GENERAL.NO_PACKAGING_LABELS" />
                </h6>
              </div>
            ) : (
              <PackagingLabelsTable
                data={filteredData}
                onSelectingLabelsToPrint={onSelectingLabelsToPrint}
                onDeletingLabels={onDeletingLabels}
              />
            )}
          </Tab>
          <Tab
            eventKey="barcodes"
            title={<FormattedMessage id="GENERAL.GENERATED_CODES" />}
            disabled={barcodesLabels.length === 0}
          >
            <BarcodesPDF
              activePackaging={activePackaging}
              barcodesLabels={barcodesLabels}
              isQrCodePDF={isQrCodePDF}
            />
          </Tab>
        </Tabs>

        <div className="mt-5 d-flex justify-content-between flex-column flex-sm-row">
          <Button
            className="mr-4 mb-4 mb-sm-0"
            disabled={
              !activePackaging?.labels?.length || loading || key === "barcodes"
            }
            onClick={() => generateLabelsToExcel()}
          >
            <FormattedMessage id="GENERAL.SAVE_TO_EXCEL" />
          </Button>
          <div className="d-inline-flex align-items-center">
            <p className="my-0 mr-4">
              <FormattedMessage id="GENERAL.GENERATE_BARCODES" />:
            </p>
            <ButtonGroup>
              <Button
                disabled={
                  !activePackaging?.labels?.length ||
                  loading ||
                  key === "barcodes"
                }
                onClick={() => generateLabelsBarcodes()}
              >
                <FormattedMessage id="GENERAL.GENERATE_BARCODES.BARCODES" />
              </Button>
              <Button
                disabled={
                  !activePackaging?.labels?.length ||
                  loading ||
                  key === "barcodes"
                }
                onClick={() => generateLabelsBarcodes(true)}
              >
                <FormattedMessage id="GENERAL.GENERATE_BARCODES.QR" />
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
}
