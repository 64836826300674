import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getConfigToOrgentity,
  updateConfigToOrgentity,
} from "../../../services/orgentityCrud";
import { DataArchivizationCard } from "../index";
import { Row, Col } from "react-bootstrap";
import SettingsParametersCard from "../components/SettingsParametersCard";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

function SettingsPage() {
  const intl = useIntl();
  const [lastArchivizationDate, setLastArchivizationDate] = useState(null);

  const getLastArchivizationDate = () => {
    getConfigToOrgentity()
      .then((response) => {
        response.data.historicalDate &&
          setLastArchivizationDate(response.data.historicalDate);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getLastArchivizationDate);

  const onArchivingData = (form) => {
    return new Promise((resolve) => {
      updateConfigToOrgentity(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  return (
    <Row>
      <Col lg={6}>
        <DataArchivizationCard
          lastArchivizationDate={lastArchivizationDate}
          onArchivingData={onArchivingData}
        />
      </Col>
      <Col lg={6}>
        <SettingsParametersCard />
      </Col>
    </Row>
  );
}

export default SettingsPage;
