const errorHandler = (error) => {
  let errorOptions;
  if (error.response?.status === 500) {
    const isFeedbackError = (error.response?.data.message).includes(
      "responseError"
    );
    if (isFeedbackError) {
      const errorMessage =
        "FEEDBACK" + error.response?.data.message?.substring(13);
      errorOptions = {
        type: "error",
        message: errorMessage,
      };
    } else {
      errorOptions = { type: "none", message: null };
    }
  } else if (error.response?.status === 401) {
    localStorage.removeItem("persist:ui");
    localStorage.removeItem("persist:auth");
    window.location.reload();
  } else {
    const errorMessage = "FEEDBACK.RESPOND_TAKING_TO_LONG";
    errorOptions = {
      type: "error",
      message: errorMessage,
    };
  }
  return errorOptions;
};

export default errorHandler;
