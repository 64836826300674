import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import usePulpExchangePermission from "../../../hooks/usePulpExchangePermission";
import _ from "lodash";
import { CoordinatorInformationBar } from "../../../components/CoordinatorInformationBar";
import { OffersOffers } from "../components/OffersOffers";
import { JoinPulpExchangeRequest } from "../components/JoinPulpExchangeRequest";
import { OffersGrowerOfferDetails } from "../components/OffersGrowerOfferDetails";
import { OffersContractorOfferDetails } from "../components/OffersContractorOfferDetails";
import {
  getSaleOffers,
  getContractorAssignedToOrgentity,
  sendRequestToEnterTheExchange,
  getSellOffersToOrgentity,
  getSupCustOffers,
  createBuyOfferToSellOffer,
  deleteBuyOffer,
  updateBuyOffer,
  initAcceptBySMS,
  getContactsToSellOffer,
} from "../../../services/contractorsCrud";
import { getAllCountries } from "../../../services/countryCrud";
import { getChildToOrgentity } from "../../../services/orgentityCrud";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SockJsClient from "react-stomp";
import store from "../../../../redux/store";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

export function OffersPage() {
  const intl = useIntl();
  const [saleOffers, setSaleOffers] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState();
  const [factories, setFactories] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [activeGrowerOffer, setActiveGrowerOffer] = useState();
  const [buyOffers, setBuyOffers] = useState([]);
  const [activeContractorOffer, setActiveContractorOffer] = useState();
  const [contacts, setContacts] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const storeState = store.getState();
  const isPlanter = usePulpExchangePermission("ROLE_PLANTER");
  const isMaterialRepresentant = usePulpExchangePermission(
    "ROLE_MATERIAL_REPRESENTANT"
  );

  useEffect(() => {
    if (applicationStatus !== "A") {
      getExampleSaleOffers();
    } else {
      getAllSaleOffers();
      if (applicationStatus === "A") {
        getContractorOffers();
      }
    }
    // eslint-disable-next-line
  }, [applicationStatus]);

  const getExampleSaleOffers = () => {
    getSaleOffers()
      .then((response) => {
        setSaleOffers(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getAllSaleOffers = (form) => {
    const priceFrom = form?.priceFrom ? form.priceFrom : -1;
    const priceTo = form?.priceTo ? form.priceTo : -1;
    const weightFrom = form?.weightFrom ? form.weightFrom : -1;
    const weightTo = form?.weightTo ? form.weightTo : -1;
    getSellOffersToOrgentity(priceFrom, priceTo, weightFrom, weightTo)
      .then((response) => {
        setSaleOffers([response.data]);
        // setActiveGrowerOffer(null);
        if (activeGrowerOffer?.sellOfferId) {
          const arr = [response.data];
          arr.forEach((factory) => {
            factory.groOwners.forEach((grower) => {
              grower.sellOffers.forEach((sellOffer) => {
                if (sellOffer.sellOfferId === activeGrowerOffer.sellOfferId) {
                  setActiveGrowerOffer(sellOffer);
                }
              });
            });
          });
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getContractorConnectionsWithFactories = () => {
    getContractorAssignedToOrgentity()
      .then((response) => {
        if (response.data.length === 0) {
          setApplicationStatus("U");
        } else if (response.data) {
          setApplicationStatus(response.data[0].supCustStatusSymbol);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getContractorConnectionsWithFactories);

  const getContractorOffers = () => {
    if (storeState.ui.userData?.bbartner?.supCustId) {
      getSupCustOffers()
        .then((response) => {
          setBuyOffers(response.data);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    }
  };

  const getSugarFactories = () => {
    getChildToOrgentity()
      .then((response) => {
        setFactories(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getSugarFactories);

  const getPostalCodes = () => {
    getAllCountries()
      .then((response) => {
        setPostalCodes(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getPostalCodes);

  const onSendingJoinApplication = (form) => {
    sendRequestToEnterTheExchange(form)
      .then((response) => {
        setApplicationStatus(response.data.supCustStatusSymbol);
        displayFeedbackFromHeaders(response.headers);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onSelectingGrowerOffer = (growerOffer) => {
    setActiveGrowerOffer(growerOffer);
    setActiveContractorOffer(null);
    if (growerOffer?.sellOfferId) {
      getGrowerContacts(growerOffer.sellOfferId);
    }
  };

  const getGrowerContacts = (sellOfferId) => {
    getContactsToSellOffer(sellOfferId)
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onSelectingContractorOffer = (contractorOffer) => {
    setActiveGrowerOffer(null);
    setActiveContractorOffer(contractorOffer);
  };

  const onCreatingBuyOffer = (sellOfferId, form) => {
    return new Promise((resolve) => {
      createBuyOfferToSellOffer(sellOfferId, form)
        .then((response) => {
          const newBuyOffers = [...buyOffers, response.data];
          setBuyOffers(newBuyOffers);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onDeletingBuyOffer = (buyOfferId) => {
    deleteBuyOffer(buyOfferId)
      .then((response) => {
        removeBuyOffer(buyOfferId);
        displayFeedbackFromHeaders(response.headers);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const removeBuyOffer = (buyOfferId) => {
    setActiveContractorOffer(null);
    const newBuyOffers = _.cloneDeep(buyOffers);
    const filteredBuyOffers = newBuyOffers.filter((buyOffer) => {
      return buyOffer.buyOfferId !== buyOfferId;
    });
    setBuyOffers(filteredBuyOffers);
  };

  const onUpdatingBuyOffer = (buyOfferId, form) => {
    return new Promise((resolve) => {
      updateBuyOffer(buyOfferId, form)
        .then((response) => {
          modifyBuyOffer(buyOfferId, form);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyBuyOffer = (buyOfferId, form) => {
    const newBuyOffers = _.cloneDeep(buyOffers);
    const searchedBuyOffer = newBuyOffers.find((buyOffer) => {
      return buyOffer.buyOfferId === buyOfferId;
    });
    searchedBuyOffer.primaryWeight = form.primaryWeight;
    searchedBuyOffer.price = form.price;
    searchedBuyOffer.comment = form.comment;
    searchedBuyOffer.offerStatus = "W";
    setBuyOffers(newBuyOffers);
    newBuyOffers.forEach((buyOffer) => {
      if (buyOffer.buyOfferId === buyOfferId) {
        setActiveContractorOffer(buyOffer);
      }
    });
  };

  const onAcceptingPhoneNumber = (form) => {
    return new Promise((resolve) => {
      initAcceptBySMS(form)
        .then(() => {
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onEnteringConfirmationCode = (form) => {
    delete form.phone;
    return new Promise((resolve) => {
      sendRequestToEnterTheExchange(form)
        .then((response) => {
          setApplicationStatus(response.data.supCustStatusSymbol);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onReceivingBuyOffersResponse = (msg) => {
    const newBuyOffers = [...buyOffers];
    if (msg.status === "BR" || msg.status === "BA" || msg.status === "BN") {
      newBuyOffers.forEach((buyOffer) => {
        if (buyOffer.buyOfferId === msg.buyOfferId) {
          buyOffer.offerStatus = msg.buyOfferStatus;
          buyOffer.groOwnerComment = msg.groOwnerComment;
          buyOffer.licenceId = msg.licenceId;
          buyOffer.symbol = msg.licenceSymbol;
          buyOffer.price = msg.price;
          buyOffer.primaryWeight = msg.primaryWeight;
          buyOffer.statusCreatedAt = msg.statusCreatedAt;
        }
      });
      setBuyOffers(newBuyOffers);
      newBuyOffers.forEach((buyOffer) => {
        if (
          activeContractorOffer?.buyOfferId &&
          buyOffer.buyOfferId === activeContractorOffer.buyOfferId
        ) {
          setActiveContractorOffer(buyOffer);
        }
      });
    }
  };

  const onReceivingPulpExchangeResponse = (msg) => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    });
  };

  return (
    <>
      <Row>
        {storeState?.ui?.userData?.bbartner?.bpartnerId &&
          !isPlanter &&
          !isMaterialRepresentant && (
            <>
              <SockJsClient
                url={`${process.env.REACT_APP_WS_URL}`}
                topics={[
                  `/responseQueue/bPartner/${storeState.ui.userData.bbartner.bpartnerId}`,
                ]}
                onMessage={(msg) => onReceivingBuyOffersResponse(msg)}
              />
              <SockJsClient
                url={`${process.env.REACT_APP_WS_URL}`}
                topics={[
                  `/responseQueue/orgentity/${storeState.ui.organization.orgentityId}`,
                ]}
                onMessage={(msg) => onReceivingPulpExchangeResponse(msg)}
              />
            </>
          )}
        <Col md={12}>
          <CoordinatorInformationBar />
        </Col>
        <Col md={12} lg={7} xxl={7}>
          <OffersOffers
            saleOffers={saleOffers}
            applicationStatus={applicationStatus}
            buyOffers={buyOffers}
            onSelectingGrowerOffer={onSelectingGrowerOffer}
            onSelectingContractorOffer={onSelectingContractorOffer}
            getFilteredSaleOffers={getAllSaleOffers}
            refresh={refresh}
          />
        </Col>
        <Col md={12} lg={5} xxl={5}>
          <Row>
            <Col xs={12}>
              {storeState.auth.user.isAnonymous !== true &&
                !isPlanter &&
                !isMaterialRepresentant && (
                  <>
                    {(applicationStatus === "U" ||
                      applicationStatus === "E" ||
                      applicationStatus === "R") && (
                      <JoinPulpExchangeRequest
                        factories={factories}
                        postalCodes={postalCodes}
                        applicationStatus={applicationStatus}
                        onAcceptingPhoneNumber={onAcceptingPhoneNumber}
                        onEnteringConfirmationCode={onEnteringConfirmationCode}
                        onSendingJoinApplication={onSendingJoinApplication}
                      />
                    )}
                  </>
                )}
              {activeGrowerOffer && (
                <OffersGrowerOfferDetails
                  offer={activeGrowerOffer}
                  contacts={contacts}
                  onCreatingBuyOffer={onCreatingBuyOffer}
                />
              )}
              {activeContractorOffer && (
                <OffersContractorOfferDetails
                  offer={activeContractorOffer}
                  onDeletingBuyOffer={onDeletingBuyOffer}
                  onUpdatingBuyOffer={onUpdatingBuyOffer}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default OffersPage;
