import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Overlay, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function CanceledStateInfoTooltip() {
  const infoTooltipSvg = useRef(null);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  return (
    <>
      <div
        ref={infoTooltipSvg}
        onMouseEnter={() => setShowInfoTooltip(!showInfoTooltip)}
        onMouseLeave={() => setShowInfoTooltip(!showInfoTooltip)}
        className="ml-3 d-inline-block"
      >
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")}
          className="no-pointer-events"
          style={{ width: "16px", height: "16px", marginTop: "-1px" }}
        />
      </div>
      <Overlay
        target={infoTooltipSvg.current}
        show={showInfoTooltip}
        placement="bottom"
      >
        {(props) => (
          <Tooltip {...{...props, show: props.show.toString()}}>
            <FormattedMessage id="ORDERS.CANCELED_INFO_TOOLTIP" />
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

export default CanceledStateInfoTooltip;
