import React from "react";

export function CoordinatorInformationBar() {
  return (
    <div
      className="alert alert-custom alert-white alert-shadow gutter-b"
      style={{
        backgroundColor: "rgba(39, 173, 122, 0.67)",
        borderColor: "rgba(39, 173, 122, 0.67)",
      }}
    >
      <div
        className="alert-text d-flex flex-column justify-content-center align-items-center"
        style={{ fontWeight: "600" }}
      >
        <span>Pomoc i infolinia - Główny Koordynator ds. Wysłodków</span>
        <span>
          Jolanta Wojtowicz, Jolanta.Wojtowicz@suedzucker.pl, 607-210-773
        </span>
      </div>
    </div>
  );
}
