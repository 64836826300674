import React from "react";
import { FormattedMessage } from "react-intl";
import { FarmRow } from "./FarmRow";
import { Table } from "react-bootstrap";

export function FarmsTable({ data, handleRowClick }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.PARTNER_NUMBER" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((farm) => (
          <FarmRow
            key={`farm-${farm.bpartnerId}`}
            farm={farm}
            handleClick={handleRowClick}
          />
        ))}
      </tbody>
    </Table>
  );
}
