import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function NavigationList({ profile }) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-60 symbol-xxl-100 symbol-light-success mr-5 align-self-start align-self-xxl-center ">
            <span className="symbol-label font-size-h1 font-weight-bold">
              {profile?.firstName ? profile.firstName.charAt(0) : "A"}
            </span>
            <i className="symbol-badge bg-success"></i>
          </div>
          <div>
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {profile?.firstName ? profile.firstName : "Anonymous"}{" "}
              {profile?.lastName ? profile.lastName : ""}
            </a>
          </div>
        </div>
        <div className="py-9">
          {profile?.contacts && profile?.contacts.length > 0 && (
            <>
              {profile.contacts.map((contact) => (
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  key={`contact-${contact.contactId}`}
                >
                  <div className="font-weight-bold mr-2">
                    {contact.type === "T" && (
                      <FormattedMessage id="GENERAL.PHONE" />
                    )}
                    {contact.type === "C" && (
                      <FormattedMessage id="GENERAL.MOBILE" />
                    )}
                    {contact.type === "F" && (
                      <FormattedMessage id="GENERAL.FAX" />
                    )}
                    {contact.type === "M" && (
                      <FormattedMessage id="GENERAL.EMAIL" />
                    )}
                    :
                  </div>
                  <a href="#" className="text-muted text-hover-primary">
                    {contact.desc}
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
          <div className="navi-item mb-2">
            <NavLink to="/user/profile" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.PROFILE" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/farm" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <i className="fa fa-money-bill-wheat ml-1" />
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.FARM" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/provided-information" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/Pictures1.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.PROVIDED_INFORMATION" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/subscriptions" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.SUBSCRIPTIONS" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/help" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.HELP_CENTER" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/manuals" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.MANUALS" />
              </span>
            </NavLink>
          </div>
          <div className="navi-item mb-2">
            <NavLink to="/user/orders" className="navi-link py-4">
              <span className="navi-icon mr-2">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}
                  ></SVG>
                </span>
              </span>
              <span className="navi-text font-size-lg">
                <FormattedMessage id="GENERAL.ORDERS" />
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
