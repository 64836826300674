import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function OffersFilter(props) {
  const intl = useIntl();
  const { register, handleSubmit, getValues } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.refresh) {
      const form = {
        weightFrom: getValues("weightFrom"),
        weightTo: getValues("weightTo"),
        priceFrom: getValues("priceFrom"),
        priceTo: getValues("priceTo"),
      };
      props.getFilteredSaleOffers(form);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  function onSubmit(data) {
    props.getFilteredSaleOffers(data);
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
        <Form.Row>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              <FormattedMessage id="GENERAL.WEIGHT_FROM" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="weightFrom"
                type="number"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                })}`}
                step="0.01"
                ref={register({ required: false, min: 0.1 })}
              />
            </InputGroup>
          </Form.Group>
          <Col
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            -
          </Col>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              <FormattedMessage id="GENERAL.WEIGHT_TO" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="weightTo"
                type="number"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                })}`}
                step="0.01"
                ref={register({ required: false, min: 0.1 })}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              <FormattedMessage id="GENERAL.PRICE_FROM" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="priceFrom"
                type="number"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.PRICE_PER_TON",
                })}`}
                step="0.01"
                ref={register({ required: false, min: 0.1 })}
              />
            </InputGroup>
          </Form.Group>
          <Col
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            -
          </Col>
          <Form.Group as={Col} md={2}>
            <Form.Label>
              <FormattedMessage id="GENERAL.PRICE_TO" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="priceTo"
                type="number"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.PRICE_PER_TON",
                })}`}
                step="0.01"
                ref={register({ required: false, min: 0.1 })}
              />
            </InputGroup>
          </Form.Group>
          <Col
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button type="submit">
              <FormattedMessage id="GENERAL.FILTER" />
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
}
