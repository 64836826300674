import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import DeactivateContractorModal from "../modals/DeactivateContractorModal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";

export function ActiveUsers(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [pagesLimit] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [fullName, setFullName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [supCustID, setSupCustID] = useState();

  useEffect(() => {
    if (props.totalRecords > 0) {
      const pagesCount = Math.ceil(props.totalRecords / pagesLimit);
      setTotalPages(pagesCount);
      createPagesArray(pagesCount);
    }
    // eslint-disable-next-line
  }, [props.totalRecords]);

  useEffect(() => {
    if (props.refresh) {
      createPagesArray(totalPages);
      props.getPaginationItemsCount(fullName);
    }
    // eslint-disable-next-line
  }, [props.refresh]);

  const createPagesArray = (pagesCount) => {
    const maxPage = pagesCount > 9 ? 9 : pagesCount;
    const arr = [];
    for (let i = 0; i < maxPage; i++) {
      arr.push({ id: i + 1 });
    }
    setPages(arr);
  };

  const onGoToPage = (pageId) => {
    setCurrentPage(pageId);
    changePage(pageId);
  };

  const changePage = (activePage) => {
    const arr = [];
    if (activePage <= totalPages) {
      if (activePage - pagesLimit > 0 && activePage + pagesLimit < totalPages) {
        for (let i = activePage - 4; i < activePage + 4; i++) {
          arr.push({ id: i });
        }
        setPages(arr);
      } else if (activePage - pagesLimit <= 0) {
        createPagesArray(totalPages);
      } else if (activePage + pagesLimit > totalPages) {
        for (let i = totalPages - 9; i < totalPages; i++) {
          arr.push({ id: i + 1 });
        }
        setPages(arr);
      }
      props.getUsers(activePage - 1, fullName);
    }
  };

  const onEnteringFilter = (e) => {
    setFullName(e.target.value);
    setCurrentPage(1);
    createPagesArray(totalPages);
    props.getPaginationItemsCount(e.target.value);
  };

  const onOpeningModalWithData = (supCustId) => {
    setSupCustID(supCustId);
    setOpenModal(true);
  };
  const onOpeningOrClosingModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <DeactivateContractorModal
        open={openModal}
        close={onOpeningOrClosingModal}
        supCustID={supCustID}
        onChangingContractorAccountStatusToDisactive={
          props.onChangingContractorAccountStatusToDisactive
        }
      />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="card-icon">
              <i className="flaticon-users"></i>
            </span>
            <h3 className="card-label">
              <FormattedMessage id="GENERAL.ACTIVE_USERS" />
            </h3>
          </div>
        </div>
        <div className="card-body">
          <Form className="mb-10">
            <Form.Row>
              <InputGroup>
                <Form.Control
                  name="fullName"
                  type="text"
                  placeholder="Search ..."
                  onChange={(e) => onEnteringFilter(e)}
                />
              </InputGroup>
            </Form.Row>
          </Form>
          {props.users.length > 0 ? (
            <>
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>
                      <FormattedMessage id="GENERAL.CONTRACTOR" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.SHORT_NAME" />
                    </th>
                    <th>
                      <FormattedMessage id="PE.SUGAR_FACTORY" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.APPLICATION_SUBMITTING_DATE" />
                    </th>
                    <th>PESEL</th>
                    <th>
                      <FormattedMessage id="GENERAL.NIP" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.CITY" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.POSTAL_CODE" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.COMMENT" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.ACTIONS" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.users.map((user) => (
                    <tr key={user.supCustId} className="text-center">
                      <td>{user.bpartnerFullName}</td>
                      <td>{user.bpartnerShortName}</td>
                      <td>{user.orgentityDesc}</td>
                      <td>
                        {moment(user.applicationAt).format("DD.MM.YYYY HH:mm")}
                      </td>
                      <td>{user.pesel}</td>
                      <td>{user.vatin}</td>
                      <td>{user.cityName}</td>
                      <td>{user.postalCode}</td>
                      <td>{user.comment}</td>
                      <td>
                        {user?.symbol === "A" && (
                          <Button
                            type="button"
                            variant="secondary"
                            onClick={() =>
                              onOpeningModalWithData(user.supCustId)
                            }
                          >
                            <FormattedMessage id="GENERAL.DEACTIVATE" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {pages.length > 0 && (
                <Pagination>
                  {totalPages > 9 && (
                    <Pagination.First
                      disabled={currentPage === 1}
                      onClick={() => onGoToPage(1)}
                    />
                  )}
                  <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => onGoToPage(currentPage - 1)}
                  />
                  {pages.map((page, index) => (
                    <Pagination.Item
                      key={index}
                      active={currentPage === page.id}
                      onClick={() => onGoToPage(page.id)}
                    >
                      {page.id}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    disabled={currentPage === totalPages}
                    onClick={() => onGoToPage(currentPage + 1)}
                  />
                  {totalPages > 9 && (
                    <Pagination.Last
                      disabled={currentPage === totalPages}
                      onClick={() => onGoToPage(totalPages)}
                    />
                  )}
                </Pagination>
              )}
            </>
          ) : (
            <div className="text-center">
              <h6>
                <FormattedMessage id="PE.NO_USERS" />
              </h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
