import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { validateUserToken, changePassword } from "../_redux/authCrud";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

function ResetPassword(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [componentVisible, setComponentVisibility] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    formState,
    getValues,
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let changePasswordRedirectTimeout;
    if (passwordChanged) {
      changePasswordRedirectTimeout = setTimeout(function() {
        props.history.push("/auth/login");
      }, 6500);
    }

    return () => {
      clearTimeout(changePasswordRedirectTimeout);
    };
    // eslint-disable-next-line
  }, [passwordChanged]);

  const onEnteringPage = () => {
    let redirectTimeout;
    const params = new URLSearchParams(props.location.search);
    const userId = params.get("id");
    const activationToken = params.get("key");
    validateUserToken(activationToken, userId)
      .then(() => {
        setLoading(false);
        setComponentVisibility(true);
      })
      .catch(() => {
        redirectTimeout = setTimeout(function() {
          props.history.push("/auth/login");
        });
      });

    return () => {
      clearTimeout(redirectTimeout);
    };
  };

  useEffectOnce(onEnteringPage);

  const onSubmit = (data) => {
    delete data.passwordConfirmation;
    const params = new URLSearchParams(props.location.search);
    const userId = params.get("id");
    const activationToken = params.get("key");
    setLoading(true);
    changePassword(activationToken, userId, data)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        // setComponentVisibility(false);
        setPasswordChanged(true);
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  };

  return (
    <>
      {componentVisible && (
        <div className="auth__form">
          <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
            <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
          </div>
          <div className="auth__heading">
            <h3>
              <FormattedMessage id="AUTH.RESET_PASSWORD" />
            </h3>
          </div>
          {passwordChanged ? (
            <>
              <div
                className="text-center mb-10 mb-lg-10"
                style={{ padding: "0 40px" }}
              >
                <FormattedMessage id="AUTH.PASSWORD_CHANGED" />.
              </div>
              <div>
                <Link
                  to="/auth/login"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  <FormattedMessage id="AUTH.GO_TO_LOGIN" />
                </Link>
              </div>
            </>
          ) : (
            <form
              className="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <div>
                  <input
                    name="password"
                    type="password"
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.ENTER_PASSWORD",
                    })}`}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      formState.touched.password && errors.password
                        ? "is-invalid"
                        : ""
                    }${
                      formState.touched.password && !errors.password
                        ? "is-valid"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      minLength: 8,
                      validate: (value) => {
                        if (
                          (value === getValues()["passwordConfirmation"] &&
                            value.length >= 8) ||
                          (getValues()["passwordConfirmation"].length &&
                            value.length >= 8)
                        ) {
                          triggerValidation("passwordConfirmation");
                          return true;
                        } else if (value.length >= 8) {
                          return true;
                        }
                      },
                    })}
                  />
                  {errors.password?.type === "required" && (
                    <div className="error invalid-feedback">
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div>
                  <input
                    name="passwordConfirmation"
                    type="password"
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.RE_ENTER_YOUR_PASSWORD",
                    })}`}
                    className={`form-control form-control-solid h-auto py-5 px-6 ${
                      formState.touched.passwordConfirmation &&
                      errors.passwordConfirmation
                        ? "is-invalid"
                        : ""
                    }${
                      formState.touched.passwordConfirmation &&
                      !errors.passwordConfirmation
                        ? "is-valid"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      validate: (value) => {
                        return value === getValues()["password"];
                      },
                    })}
                  />
                  {errors.passwordConfirmation?.type === "required" && (
                    <div className="error invalid-feedback">
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.passwordConfirmation?.type === "validate" && (
                    <div className="error invalid-feedback">
                      <FormattedMessage id="ERROR.INCORRECT_PASSWORDS" />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="form-group text-center button_bar flex-wrap"
                style={{ marginBottom: "4px", justifyContent: "space-evenly" }}
              >
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={!formState.isValid || loading}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3 button_auth button--auth_background_primary`}
                >
                  <span>
                    <FormattedMessage id="GENERAL.SUBMIT" />
                  </span>
                </button>
                <Link
                  to="/auth/login"
                  className="button_link btn btn-secondary button_auth font-weight-bold px-9 py-4 my-3"
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </Link>
              </div>
            </form>
          )}
        </div>
      )}
      {/* {componentVisible && (
        <div className="login-form login-signin form_auth">
          <div className="text-center mb-10 mb-lg-10">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.RESET_PASSWORD" />
            </h3>
          </div>

          <form
            className="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group">
              <div>
                <input
                  name="password"
                  type="password"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.ENTER_PASSWORD",
                  })}`}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    formState.touched.password && errors.password
                      ? "is-invalid"
                      : ""
                  }${
                    formState.touched.password && !errors.password
                      ? "is-valid"
                      : ""
                  }`}
                  ref={register({
                    required: true,
                    minLength: 8,
                    validate: (value) => {
                      if (
                        (value === getValues()["passwordConfirmation"] &&
                          value.length >= 8) ||
                        (getValues()["passwordConfirmation"].length &&
                          value.length >= 8)
                      ) {
                        triggerValidation("passwordConfirmation");
                        return true;
                      } else if (value.length >= 8) {
                        return true;
                      }
                    },
                  })}
                />
                {errors.password?.type === "required" && (
                  <div className="error invalid-feedback">
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
              </div>
            </div>
            <div className="form-group">
              <div>
                <input
                  name="passwordConfirmation"
                  type="password"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.RE_ENTER_YOUR_PASSWORD",
                  })}`}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    formState.touched.passwordConfirmation &&
                    errors.passwordConfirmation
                      ? "is-invalid"
                      : ""
                  }${
                    formState.touched.passwordConfirmation &&
                    !errors.passwordConfirmation
                      ? "is-valid"
                      : ""
                  }`}
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return value === getValues()["password"];
                    },
                  })}
                />
                {errors.passwordConfirmation?.type === "required" && (
                  <div className="error invalid-feedback">
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
                {errors.passwordConfirmation?.type === "validate" && (
                  <div className="error invalid-feedback">
                    <FormattedMessage id="ERROR.INCORRECT_PASSWORDS" />
                  </div>
                )}
              </div>
            </div>
            <div
              className="form-group button_bar flex-wrap"
              style={{ marginBottom: "0" }}
            >
              <button
                type="submit"
                disabled={!formState.isValid || loading}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 button_auth button--auth_background_primary"
              >
                <span>
                  <FormattedMessage id="GENERAL.SUBMIT" />
                </span>
              </button>

              <Link to="/auth/login" className="button_auth">
                <button
                  type="button"
                  disabled={loading}
                  className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`}
                  style={{ width: "100%" }}
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}

      {passwordChanged && (
        <div className="login-form login-signin" style={{ width: "50%" }}>
          <div className="text-center mb-10 mb-lg-10">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.RESET_PASSWORD" />
            </h3>
            <p className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.PASSWORD_CHANGED" />.
            </p>
            <div>
              <Link
                to="/auth/login"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GO_TO_LOGIN" />
              </Link>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default ResetPassword;
