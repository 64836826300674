import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useIntl } from "react-intl";
import localePl from "../../../shared/agGridLocales/locale.pl";
import localeDe from "../../../shared/agGridLocales/locale.de";
import localeRu from "../../../shared/agGridLocales/locale.ru";
import localeEs from "../../../shared/agGridLocales/locale.es";

export function ReceivablesWorkersTable({ data, onSelectingRow, filter }) {
  const intl = useIntl();

  const [gridApi, setGridApi] = useState(null);
  const [columnDefs] = useState([
    {
      field: "firstName",
      headerName: intl.formatMessage({ id: "GENERAL.FIRSTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: intl.formatMessage({ id: "GENERAL.LASTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "item",
      headerName: intl.formatMessage({ id: "GENERAL.LABEL" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
    },
    {
      field: "badge",
      headerName: intl.formatMessage({ id: "GENERAL.INTERNAL_CODE" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
    },
  ]);

  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getLocale = () => {
    if (languageCode === "pl") return localePl;
    if (languageCode === "de") return localeDe;
    if (languageCode === "ru") return localeRu;
    if (languageCode === "es") return localeEs;
    return null;
  };

  const getRowClass = (params) => {
    if (!params.data.isActive) {
      return "row__factory";
    }
  };

  const onRowSelected = (event) => {
    if (event.node.isSelected()) onSelectingRow(event.node.data.id);
  };

  const onFirstDataRendered = (event) => {
    event.api.sizeColumnsToFit();
  };

  useEffect(() => {
    if (filter != null && gridApi) {
      gridApi.setQuickFilter(filter)
    }
  }, [filter, gridApi]);

  return (
    <div
      className="ag-theme-alpine mb-3"
      style={{ height: window.innerHeight * 0.5, width: "auto" }}
    >
      <AgGridReact
        rowData={data}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        columnDefs={columnDefs}
        localeText={getLocale()}
        rowHeight={24}
        pivotMode="false"
        suppressPivotMode
        suppressReactUi
        rowClass="ag-row-selectable"
        getRowClass={getRowClass}
        rowSelection="single"
        onRowSelected={(e) => onRowSelected(e)}
        suppressCellFocus
        suppressScrollOnNewData
        suppressRowVirtualisation
      />
    </div>
  );
}
