import React from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import robotoFont from "../../../../fonts/Roboto-Light.ttf";
import { Row, Col, Button } from "react-bootstrap";

Font.register({ family: "Robot", src: robotoFont });

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 45,
    paddingHorizontal: 35,
    fontFamily: "Robot",
  },
  wrapper: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
  topView: {
    flexDirection: "row",
    marginBottom: 20,
  },
  topViewTable: {
    width: "25vw",
    height: 55,
    fontSize: 10,
    border: "1px solid black",
  },
  topViewTableHeader: {
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 5,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  fontWhite: {
    color: "white",
  },
  topViewTableCellRow: {
    display: "block",
    alignItems: "center",
  },
  block: {
    height: 55,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "gray",
    fontSize: 14,
  },
  topCell: {
    height: 27.5,
  },
  topGrayHeader: {
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  topRow: {
    alignItems: "center",
  },
  topRowWithBorder: {
    alignItems: "center",
    borderBottom: "1px solid black",
  },
  middleView: {
    flexDirection: "column",
  },
  middleViewTable: {
    width: "100%",
    flexDirection: "column",
  },
  tableWrapper: {
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    border: "1px solid black",
  },
  smallColumnHeader: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  largeColumnHeader: {
    width: "50%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  mediumColumnHeader: {
    width: "30%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  smallColumnHeaderWithoutBorder: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
  },
  smallColumn: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  largeColumn: {
    width: "50%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  mediumColumn: {
    width: "30%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  smallColumnWithoutBorder: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
  },
  tableRowWrapper: {
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  bottomView: {
    flexDirection: "row",
    marginTop: 20,
  },
  bottomViewTableSmall: {
    width: "20vw",
    height: 55,
    fontSize: 10,
    border: "1px solid black",
  },
  bottomViewTableMedium: {
    width: "40vw",
    height: 55,
    fontSize: 10,
    border: "1px solid black",
  },
  bottomViewTableHeader: {
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 5,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  bottomViewTableHeaderWithoutMargin: {
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  tableContainer: {
    width: "100%",
    flexDirection: "row",
  },
  containerColumnLeft: {
    width: "50%",
    borderRight: "1px solid black",
  },
  containerColumnRight: {
    width: "50%",
  },
  columnHeader: {
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  columnContentLeft: {
    height: "69%",
  },
  commentView: {
    flexDirection: "row",
    marginTop: 20,
  },
  commentViewTable: {
    width: "100vw",
    height: 55,
    fontSize: 10,
    border: "1px solid black",
  },
  commentViewTableHeader: {
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 5,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
});

const PayoutDocument = ({ activeWorker, operation }) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.wrapper}>
        <View style={styles.topView}>
          <View style={styles.topViewTable}>
            <View style={styles.topViewTableHeader}>
              <Text style={styles.fontWhite}>Komu</Text>
            </View>
            <View style={styles.topViewTableCellRow}>
              <Text style={styles.fontBold}>{activeWorker.firstName}</Text>
            </View>
            <View style={styles.topViewTableCellRow}>
              <Text style={styles.fontBold}>{activeWorker.lastName}</Text>
            </View>
          </View>
          <View style={styles.topViewTable}>
            <View style={styles.topViewTableHeader}>
              <Text style={styles.fontWhite}>Dowód</Text>
            </View>
            <View style={styles.topViewTableCellRow}>
              <Text style={styles.fontBold}>Kasa Główna</Text>
            </View>
          </View>
          <View style={styles.topViewTable}>
            <View style={styles.block}>
              <Text style={styles.fontWhite}>
                {operation.__payoutTypeValue === "PAYOUT" ||
                operation.__payoutTypeValue === "ADVANCE" ? (
                  <>KW</>
                ) : (
                  <>KP</>
                )}
              </Text>
            </View>
          </View>
          <View style={styles.topViewTable}>
            <View style={styles.topCell}>
              <View style={styles.topGrayHeader}>
                <Text style={styles.fontWhite}>Data wystawienia</Text>
              </View>
              <View style={styles.topRowWithBorder}>
                <Text>{moment(operation.registerAt).format("YYYY-MM-DD")}</Text>
              </View>
            </View>
            <View style={styles.topCell}>
              <View style={styles.topGrayHeader}>
                <Text style={styles.fontWhite}>Numer potwierdzenia</Text>
              </View>
              <View style={styles.topRow}>
                <Text>{operation.documentNo}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.middleView}>
          <View style={styles.middleViewTable}>
            <View style={styles.tableWrapper}>
              <View style={styles.smallColumnHeader}>
                <Text style={styles.fontWhite}>Lp.</Text>
              </View>
              <View style={styles.largeColumnHeader}>
                <Text style={styles.fontWhite}>Za co</Text>
              </View>
              <View style={styles.mediumColumnHeader}>
                <Text style={styles.fontWhite}>Wartość</Text>
              </View>
              <View style={styles.smallColumnHeaderWithoutBorder}>
                <Text style={styles.fontWhite}>Waluta</Text>
              </View>
            </View>
            <View style={styles.tableRowWrapper}>
              <View style={styles.smallColumn}>
                <Text>1</Text>
              </View>
              <View style={styles.largeColumn}>
                <Text>{operation.payoutTypeName}</Text>
              </View>
              <View style={styles.mediumColumn}>
                <Text>{operation.value}</Text>
              </View>
              <View style={styles.smallColumnWithoutBorder}>
                <Text>{operation.currencyName}</Text>
              </View>
            </View>
            <View style={styles.tableRowWrapper}>
              <View style={styles.smallColumn}>
                <Text>2</Text>
              </View>
              <View style={styles.largeColumn}></View>
              <View style={styles.mediumColumn}></View>
              <View style={styles.smallColumnWithoutBorder}></View>
            </View>
            <View style={styles.tableRowWrapper}>
              <View style={styles.smallColumn}>
                <Text>3</Text>
              </View>
              <View style={styles.largeColumn}></View>
              <View style={styles.mediumColumn}></View>
              <View style={styles.smallColumnWithoutBorder}></View>
            </View>
          </View>
        </View>
        <View style={styles.bottomView}>
          <View style={styles.bottomViewTableSmall}>
            <View style={styles.bottomViewTableHeader}>
              <Text style={styles.fontWhite}>Wystawił</Text>
            </View>
          </View>
          <View style={styles.bottomViewTableSmall}>
            <View style={styles.bottomViewTableHeader}>
              <Text style={styles.fontWhite}>Sprawdził</Text>
            </View>
          </View>
          <View style={styles.bottomViewTableSmall}>
            <View style={styles.bottomViewTableHeader}>
              <Text style={styles.fontWhite}>Zatwierdził</Text>
            </View>
          </View>
          <View style={styles.bottomViewTableMedium}>
            <View style={styles.bottomViewTableHeaderWithoutMargin}>
              <Text style={styles.fontWhite}>Kwotę powyższą</Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.containerColumnLeft}>
                <View style={styles.columnHeader}>
                  <Text style={styles.fontWhite}>Wypłaciłem</Text>
                </View>
                <View style={styles.columnContentLeft}></View>
              </View>
              <View style={styles.containerColumnRight}>
                <View style={styles.columnHeader}>
                  <Text style={styles.fontWhite}>Otrzymałem</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.commentView}>
          <View style={styles.commentViewTable}>
            <View style={styles.commentViewTableHeader}>
              <Text style={styles.fontWhite}>Komentarz</Text>
            </View>
            <Text>{operation.comment}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export function OperationPDF({ activeWorker, operation }) {
  return (
    <>
      {isMobile ? (
        <Col
          md={12}
          lg={12}
          xxl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center">
            <PDFDownloadLink
              document={
                <PayoutDocument
                  activeWorker={activeWorker}
                  operation={operation}
                />
              }
              fileName={`document-${moment().format("DD.MM.YYYY_HH:mm")}.pdf`}
            >
              <Button>
                <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF" />
              </Button>
            </PDFDownloadLink>
          </div>
        </Col>
      ) : (
        <>
          <Row>
            <Col md={12} lg={12} xxl={12} style={{ minHeight: "50vh" }}>
              <PDFViewer style={{ width: "100%", height: "100%" }}>
                <PayoutDocument
                  activeWorker={activeWorker}
                  operation={operation}
                />
              </PDFViewer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
