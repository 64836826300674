import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Search } from "../../../components/Search";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DeleteFieldOrCroppingModal } from "../../../components/DeleteFieldOrCroppingModal";

export function CroppingsTable(props) {
  const [filterValue, setFilterValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showOnlyActiveFields, setShowOnlyActiveFields] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setTableData(props.croppings);
    const newTableData = props.croppings.filter((row) => {
      if (showOnlyActiveFields)
        return (
          row.shortName.toLowerCase().includes(filterValue.toLowerCase()) &&
          row.isActive
        );
      else
        return row.shortName.toLowerCase().includes(filterValue.toLowerCase());
    });
    setTableData(newTableData);
  }, [
    props.croppings,
    props,
    tableData.length,
    filterValue,
    showOnlyActiveFields,
  ]);

  const onChangingMode = () => {
    props.onChangingMode();
  };

  const onSelectingRow = (fieldId) => {
    props.onSelectingRow(fieldId);
  };

  const onOpeningOrClosingModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <DeleteFieldOrCroppingModal
        open={isModalOpen}
        close={onOpeningOrClosingModal}
        screen={"croppings"}
        onRemovingFieldOrCropping={() => props.deleteRow()}
      />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-toolbar" style={{ width: "100%" }}>
            <Search
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              value={filterValue}
            />
          </div>
        </div>
        <div className="card-body">
          {props.croppings.length === 0 ? (
            <div className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_CROPPINGS" />
              </h6>
            </div>
          ) : (
            <div className="card__table">
              <Table striped bordered hover responsive>
                <thead>
                  <tr className="text-center">
                    <th>
                      <FormattedMessage id="GENERAL.ITEM" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.SHORT_NAME" />
                    </th>
                    <th>
                      <FormattedMessage id="GENERAL.ACREAGE" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((cropping, index) => (
                    <tr
                      key={cropping.croppingId}
                      className={`text-center cursor--pointer ${
                        cropping.croppingId === props.selectedRow
                          ? "offer--active"
                          : ""
                      } ${
                        !cropping.isActive &&
                        cropping.croppingId !== props.selectedRow
                          ? "row__factory"
                          : ""
                      }`}
                      onClick={() => onSelectingRow(cropping.croppingId)}
                    >
                      <td>{index + 1}</td>
                      <td>{cropping.shortName}</td>
                      <td>
                        {cropping.shapeArea === null
                          ? cropping.areaDeclared
                          : cropping.shapeArea.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="secondary"
                  checked={showOnlyActiveFields}
                  onChange={() =>
                    setShowOnlyActiveFields(!showOnlyActiveFields)
                  }
                />
              }
              label={`${intl.formatMessage({
                id: "GENERAL.SHOW_ACTIVE_ONLY",
              })}`}
              labelPlacement="end"
            />
            <div className="text-right flex-shrink-0 mt-4">
              <Button
                disabled={props.mode === "create"}
                onClick={() => onChangingMode()}
                className="mr-1"
              >
                <FormattedMessage id="GENERAL.ADD" />
              </Button>
              <Button
                disabled={props.mode === "create" || !props.selectedRow}
                onClick={() => setIsModalOpen(true)}
              >
                <FormattedMessage id="GENERAL.DELETE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
