import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import { getCroppingAndFieldToOrgentity } from "../../../services/crop-manager/farmSharingCrud";
import { getShapeLongestLine } from "../../../services/shapesCrud";
import { MapFilter } from "../components/MapFilter";
import { Map } from "../components/Map";
import { Row, Col, Button } from "react-bootstrap";
import displayFeedback from "../../../components/helpers/displayFeedback";
import { useDispatch, useSelector } from "react-redux";

const MapsPage = (props) => {
  const intl = useIntl();
  const [line, setLine] = useState(null);
  const [fields, setFields] = useState([]);
  const [croppings, setCroppings] = useState([]);
  const [filter, setFilter] = useState("");
  const [
    fieldsAndCroppingsVisibility,
    setFieldsAndCroppingsVisibility,
  ] = useState({ showFields: true, showCroppings: true });
  const [showShapesAsMarkers, setShowShapesAsMarkers] = useState(false);
  const [showOnlyEcoCroppings, setShowOnlyEcoCroppings] = useState(false);
  const [
    showOnlyActiveFieldsAndCroppings,
    setShowOnlyActiveFieldsAndCroppings,
  ] = useState(true);
  const [
    showOnlyUserFieldsAndCroppings,
    setShowOnlyUserFieldsAndCroppings,
  ] = useState(false);
  const [shapeIdToSelect, setShapeIdToSelect] = useState(0);
  const [tabToSelect, setTabToSelect] = useState(null);

  const getPartnersFieldsAndCroppings = (from, to) => {
    const fromDate = from
      ? moment(from).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const toDate = to
      ? moment(to).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    getCroppingAndFieldToOrgentity(fromDate, toDate)
      .then((response) => {
        setFields(response.data.fields);
        setCroppings(response.data.croppings);
        var paramsExists = selectFieldFromURL();
        if (!paramsExists) {
          setLine(response.data.longestLine);
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const setMapShapesVisibility = (showFieldsAndCroppings) => {
    setFieldsAndCroppingsVisibility(showFieldsAndCroppings);
  };

  const getCroppingOrFieldLongestLine = (shapeId) => {
    getShapeLongestLine(shapeId)
      .then((response) => {
        setLine(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onTogglingEcoCroppings = () => {
    setShowOnlyEcoCroppings(!showOnlyEcoCroppings);
  };

  const onTogglingActiveFCOnly = () => {
    setShowOnlyActiveFieldsAndCroppings(!showOnlyActiveFieldsAndCroppings);
  };

  const onTogglingUserFCOnly = () => {
    setShowOnlyUserFieldsAndCroppings(!showOnlyUserFieldsAndCroppings);
  };

  const setShapesAsMarkers = () => {
    setShowShapesAsMarkers(!showShapesAsMarkers);
  };

  const selectFieldFromURL = () => {
    const { type, id } = props.match.params;
    if (type && type.length && id && parseInt(id)) {
      setShapeIdToSelect(parseInt(id));
      setTabToSelect(type);
      return true;
    }

    return false;
  };

  useEffectOnce(getPartnersFieldsAndCroppings);

  const userLogin = useSelector((state) => state.ui.userData.login);

  const filteredFields = useMemo(() => {
    if (fields?.length > 0 && fieldsAndCroppingsVisibility.showFields) {
      return fields.filter((field) => {
        const fieldNameExist = field.shortName
          ? field.shortName.toLowerCase().includes(filter.toLowerCase())
          : false;
        const fullNameExist = field.bpartner.bpartnerFullName
          ? field.bpartner.bpartnerFullName
              .toLowerCase()
              .includes(filter.toLowerCase())
          : false;
        const numberExist = field.bpartner.number
          ? field.bpartner.number.toLowerCase().includes(filter.toLowerCase())
          : false;
        const supplierExist = field.supplier?.fullName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const loginExist = field.sharedBy?.login
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesExist = field.species?.desc
          .toLowerCase()
          .includes(filter.toLowerCase());
        const varietyExist = field.variety?.desc
          .toLowerCase()
          .includes(filter.toLowerCase());

        return (
          !!(showOnlyUserFieldsAndCroppings
            ? field.sharedBy?.login === userLogin
            : true) &&
          !!(showOnlyActiveFieldsAndCroppings ? field.isActive : true) &&
          !!(filter.length > 0
            ? fieldNameExist ||
              fullNameExist ||
              numberExist ||
              supplierExist ||
              loginExist ||
              speciesExist ||
              varietyExist
            : true)
        );
      });
    } else return [];
  }, [
    fields,
    fieldsAndCroppingsVisibility.showFields,
    showOnlyUserFieldsAndCroppings,
    userLogin,
    showOnlyActiveFieldsAndCroppings,
    filter,
  ]);

  const filteredCroppings = useMemo(() => {
    if (croppings?.length > 0 && fieldsAndCroppingsVisibility.showCroppings) {
      return croppings.filter((cropping) => {
        const croppingNameExist = cropping.shortName
          ? cropping.shortName.toLowerCase().includes(filter.toLowerCase())
          : false;
        const fullNameExist = cropping.bpartner.bpartnerFullName
          ? cropping.bpartner.bpartnerFullName
              .toLowerCase()
              .includes(filter.toLowerCase())
          : false;
        const numberExist = cropping.bpartner.number
          ? cropping.bpartner.number
              .toLowerCase()
              .includes(filter.toLowerCase())
          : false;
        const varietyExist = cropping.variety.shortName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesExist = cropping.variety.speciesDesc
          .toLowerCase()
          .includes(filter.toLowerCase());
        const supplierExist = cropping.supplier?.fullName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const loginExist = cropping.sharedBy?.login
          .toLowerCase()
          .includes(filter.toLowerCase());
        return (
          !!(showOnlyUserFieldsAndCroppings
            ? cropping.sharedBy?.login === userLogin
            : true) &&
          !!(showOnlyActiveFieldsAndCroppings ? cropping.isActive : true) &&
          !!(showOnlyEcoCroppings ? cropping.isEco : true) &&
          !!(filter.length > 0
            ? croppingNameExist ||
              fullNameExist ||
              numberExist ||
              varietyExist ||
              speciesExist ||
              supplierExist ||
              loginExist
            : true)
        );
      });
    } else return [];
  }, [
    croppings,
    fieldsAndCroppingsVisibility.showCroppings,
    showOnlyUserFieldsAndCroppings,
    userLogin,
    showOnlyActiveFieldsAndCroppings,
    showOnlyEcoCroppings,
    filter,
  ]);

  const dispatch = useDispatch();
  const showFilters = useSelector((state) => state.cmMaps.showFilters);
  const isMobile = useSelector((state) => state.misc.isMobile);
  const setFiltersOpen = useCallback(
    (payload) => {
      dispatch({
        type: "cmMaps/setShowFilters",
        payload,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isMobile) setFiltersOpen(true);
    else setFiltersOpen(false);
  }, [isMobile, setFiltersOpen]);

  return (
    <>
      <Map
        line={line}
        fields={filteredFields}
        croppings={filteredCroppings}
        shapeIdToSelect={shapeIdToSelect}
        shapeTypeToSelect={tabToSelect}
        showShapesAsMarkers={showShapesAsMarkers}
        centerOnMap={getCroppingOrFieldLongestLine}
      />
      <Row className="maps-menu__container">
        {!showFilters && (
          <Button
            onClick={() => setFiltersOpen(true)}
            variant="light"
            className="position-relative mt-22 mt-sm-9 mt-lg-n4 ml-3"
            data-testid="display-filters"
          >
            <i className="fa fa-filter" />
            <FormattedMessage id="GENERAL.FILTERS" />
          </Button>
        )}

        <Col md={12} lg={4} xxl={3} className="to-parent-height">
          <MapFilter
            fields={filteredFields}
            croppings={filteredCroppings}
            showShapesAsMarkers={showShapesAsMarkers}
            filterFieldsAndCroppings={setFilter}
            activeTab={tabToSelect}
            getPartnersFieldsAndCroppings={getPartnersFieldsAndCroppings}
            setMapShapesVisibility={setMapShapesVisibility}
            centerOnMap={getCroppingOrFieldLongestLine}
            setShapesAsMarkers={setShapesAsMarkers}
            onTogglingEcoCroppings={onTogglingEcoCroppings}
            onTogglingActiveFCOnly={onTogglingActiveFCOnly}
            showOnlyUserFieldsAndCroppings={showOnlyUserFieldsAndCroppings}
            onTogglingUserFCOnly={onTogglingUserFCOnly}
            showFieldsAndCroppings={fieldsAndCroppingsVisibility}
            showOnlyEcoCroppings={showOnlyEcoCroppings}
            showOnlyActiveFieldsAndCroppings={showOnlyActiveFieldsAndCroppings}
          />
        </Col>
      </Row>
    </>
  );
};

export default MapsPage;
