import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, InputGroup, Col } from "react-bootstrap";
import { getAllCountries } from "../../../../services/countryCrud";
import errorHandler from "../../../../shared/errorHandler";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Controller, useForm } from "react-hook-form";
import MaskedInput from "react-maskedinput";
import displayFeedback from "../../../../components/helpers/displayFeedback";

export default function Step3() {
  const selectedPlan = useSelector((state) => state.pricing.selectedPlan);
  const addresses = useSelector((state) => state.pricing.addresses);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [countryCodes, setCountryCodes] = useState();
  const [hasDeliveryAddress, setHasDeliveryAddress] = useState();
  const [wantInvoice, setWantInvoice] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    errors,
    formState,
    setValue,
    getValues,
  } = useForm({
    mode: "onMouseLeave",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getAllCountries()
      .then((response) => {
        setCountryCodes(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
    if (
      selectedPlan.appName === "BRIGADIER" &&
      (selectedPlan.data.parameters.find((p) => p.name === "COURIER_WEIGHT")
        .value > 0 ||
        selectedPlan.data.parameters.find(
          (p) => p.name === "COURIER_SMARTPHONE"
        ).value > 0 ||
        selectedPlan.data.parameters.find(
          (p) => p.name === "COURIER_ITEM_IDENTIFIER"
        ).value > 0)
    ) {
      setHasDeliveryAddress(true);
    }
  }, [selectedPlan.appName, selectedPlan.data.parameters, intl]);

  useEffect(() => {
    if (countryCodes && countryCodes.length > 0 && addresses) {
      if (addresses.contactDetail) setValue("contactDetail", addresses.contactDetail);
      if (addresses.address) setValue("address", addresses.address);
      if (addresses.invoiceAddress.companyName) {
        setWantInvoice(true);
        setTimeout(() => {
          setValue("invoiceAddress", addresses.invoiceAddress);
        }, 200);
      }
      if (addresses.basicData) setValue("basicData", addresses.basicData);
    }
  }, [countryCodes, addresses, setValue]);

  const onSubmit = (data) => {
    dispatch({
      type: "pricing/setAddresses",
      payload: {
        address: {
          ...data.address,
          countryIdName: data.address?.countryId
            ? countryCodes.find(
                (cc) => cc.id === Number(data.address.countryId)
              )?.code
            : undefined,
        },
        invoiceAddress: {
          ...data.invoiceAddress,
          countryIdName: data.invoiceAddress?.countryId
            ? countryCodes.find(
                (cc) => cc.id === Number(data.invoiceAddress.countryId)
              )?.code
            : undefined,
        },
        contactDetail: {
          ...data.contactDetail,
          countryIdName: data.contactDetail?.countryId
            ? countryCodes.find(
                (cc) => cc.id === Number(data.contactDetail.countryId)
              )?.code
            : undefined,
        },
        basicData: data.basicData,
      },
    });
    dispatch({
      type: "pricing/setStep",
      payload: {
        step: 4,
      },
    });
  };

  const copyContactDetail = (target) => {
    setValue(target, getValues({ nest: true }).contactDetail);
  };

  if (countryCodes)
    return (
      <>
        <div className="card-body">
          <div>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.EMAIL" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="basicData.email"
                      type="email"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                        },
                      })}
                      className={`${
                        formState.touched.basicData?.email &&
                        errors.basicData?.email
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.basicData?.email &&
                        !errors.basicData?.email
                          ? "is-valid"
                          : ""
                      }`}
                    />
                  </InputGroup>
                  {errors.basicData?.email?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.basicData?.email?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INCORRECT_EMAIL" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="PLACEHOLDER.PHONE_NUMBER" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="basicData.phone"
                      type="tel"
                      ref={register({
                        required: true,
                        pattern: { value: /^[\+]?[0-9 -]*$/ },
                        maxLength: 30,
                      })}
                      className={`${
                        formState.touched.basicData?.phone &&
                        errors.basicData?.phone
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.basicData?.phone &&
                        !errors.basicData?.phone
                          ? "is-valid"
                          : ""
                      }`}
                      maxLength={30}
                    />
                  </InputGroup>
                  {errors.basicData?.phone?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.basicData?.phone?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              <div className="mb-4">
                <h6>
                  <FormattedMessage id="PRICING.CONTACT_DATA" />
                </h6>
              </div>
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.FIRSTNAME" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="contactDetail.firstName"
                      type="text"
                      ref={register({
                        required: true,
                        pattern: { value: /^\p{L}+$/u },
                      })}
                      className={`${
                        formState.touched.contactDetail?.firstName &&
                        errors.contactDetail?.firstName
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.contactDetail?.firstName &&
                        !errors.contactDetail?.firstName
                          ? "is-valid"
                          : ""
                      }`}
                    />
                  </InputGroup>
                  {errors.contactDetail?.firstName?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.firstName?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.LASTNAME" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="contactDetail.lastName"
                      type="text"
                      ref={register({
                        required: true,
                        pattern: { value: /^[\p{L}-]+$/u },
                      })}
                      className={`${
                        formState.touched.contactDetail?.lastName &&
                        errors.contactDetail?.lastName
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.contactDetail?.lastName &&
                        !errors.contactDetail?.lastName
                          ? "is-valid"
                          : ""
                      }`}
                    />
                  </InputGroup>
                  {errors.contactDetail?.lastName?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.lastName?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={8}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.STREET_ADDRESS" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="contactDetail.street"
                      type="text"
                      ref={register({
                        required: true,
                        pattern: { value: /^[\p{L}0-9- ]+$/u },
                      })}
                      className={`${
                        formState.touched.contactDetail?.street &&
                        errors.contactDetail?.street
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.contactDetail?.street &&
                        !errors.contactDetail?.street
                          ? "is-valid"
                          : ""
                      }`}
                    />
                  </InputGroup>
                  {errors.contactDetail?.street?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.street?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.HOUSE_NUMBER" /> *
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="contactDetail.houseNumber"
                      type="text"
                      ref={register({
                        required: true,
                        pattern: { value: /^[\p{L}0-9\/]+$/u },
                      })}
                      className={`${
                        formState.touched.contactDetail?.houseNumber &&
                        errors.contactDetail?.houseNumber
                          ? "is-invalid"
                          : ""
                      }${
                        formState.touched.contactDetail?.houseNumber &&
                        !errors.contactDetail?.houseNumber
                          ? "is-valid"
                          : ""
                      }`}
                    />
                  </InputGroup>
                  {errors.contactDetail?.houseNumber?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.houseNumber?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.APARTAMENT_NUMBER" />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="contactDetail.aptNumber"
                      type="text"
                      ref={register({
                        pattern: { value: /^[\p{L}0-9\/]+$/u },
                      })}
                    />
                  </InputGroup>
                  {errors.contactDetail?.aptNumber?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.COUNTRY_CODE" /> *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="contactDetail.countryId"
                    ref={register()}
                  >
                    {countryCodes.map((countryCode) => (
                      <option key={countryCode.id} value={countryCode.id}>
                        {countryCode.code}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.POSTAL_CODE" /> *
                  </Form.Label>
                  <Controller
                    as={MaskedInput}
                    name="contactDetail.postalCode"
                    control={control}
                    mask="11-111"
                    className={`form-control ${
                      formState.touched.contactDetail?.postalCode &&
                      errors.contactDetail?.postalCode
                        ? "is-invalid"
                        : ""
                    }${
                      formState.touched.contactDetail?.postalCode &&
                      !errors.contactDetail?.postalCode
                        ? "is-valid"
                        : ""
                    }`}
                    rules={{
                      required: true,
                      pattern: /[0-9]{2}-[0-9]{3}/,
                      minLength: 6,
                    }}
                  ></Controller>
                  {errors.contactDetail?.postalCode?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.postalCode?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.CITY" /> *
                  </Form.Label>
                  <Form.Control
                    name="contactDetail.city"
                    type="text"
                    ref={register({
                      required: true,
                      pattern: { value: /^[\p{L}. -]+$/u },
                    })}
                    className={`${
                      formState.touched.contactDetail?.city && errors.contactDetail?.city
                        ? "is-invalid"
                        : ""
                    }${
                      formState.touched.contactDetail?.city && !errors.contactDetail?.city
                        ? "is-valid"
                        : ""
                    }`}
                  />
                  {errors.contactDetail?.city?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.contactDetail?.city?.type === "pattern" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INVALID_FORMAT" />
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              {hasDeliveryAddress && (
                <>
                  <div className="mb-4 d-flex justify-content-between align-items-end">
                    <h6>
                      <FormattedMessage id="PRICING.DELIVERY_ADDRESS" />
                    </h6>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => copyContactDetail("address")}
                    >
                      <FormattedMessage id="PRICING.COPY_CONTACT_DATA" />
                    </Button>
                  </div>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.FIRSTNAME" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="address.firstName"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^\p{L}+$/u },
                          })}
                          className={`${
                            formState.touched.address?.firstName &&
                            errors.address?.firstName
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.address?.firstName &&
                            !errors.address?.firstName
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.address?.firstName?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.firstName?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.LASTNAME" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="address.lastName"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^[\p{L}-]+$/u },
                          })}
                          className={`${
                            formState.touched.address?.lastName &&
                            errors.address?.lastName
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.address?.lastName &&
                            !errors.address?.lastName
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.address?.lastName?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.lastName?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={8}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.STREET_ADDRESS" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="address.street"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^[\p{L}0-9- ]+$/u },
                          })}
                          className={`${
                            formState.touched.address?.street &&
                            errors.address?.street
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.address?.street &&
                            !errors.address?.street
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.address?.street?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.street?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.HOUSE_NUMBER" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="address.houseNumber"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^[\p{L}0-9\/]+$/u },
                          })}
                          className={`${
                            formState.touched.address?.houseNumber &&
                            errors.address?.houseNumber
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.address?.houseNumber &&
                            !errors.address?.houseNumber
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.address?.houseNumber?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.houseNumber?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.APARTAMENT_NUMBER" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="address.aptNumber"
                          type="text"
                          ref={register({
                            pattern: { value: /^[\p{L}0-9\/]+$/u },
                          })}
                        />
                      </InputGroup>
                      {errors.address?.aptNumber?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.COUNTRY_CODE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="address.countryId"
                        ref={register()}
                      >
                        {countryCodes.map((countryCode) => (
                          <option key={countryCode.id} value={countryCode.id}>
                            {countryCode.code}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.POSTAL_CODE" /> *
                      </Form.Label>
                      <Controller
                        as={MaskedInput}
                        name="address.postalCode"
                        control={control}
                        mask="11-111"
                        className={`form-control ${
                          formState.touched.address?.postalCode &&
                          errors.address?.postalCode
                            ? "is-invalid"
                            : ""
                        }${
                          formState.touched.address?.postalCode &&
                          !errors.address?.postalCode
                            ? "is-valid"
                            : ""
                        }`}
                        rules={{
                          required: true,
                          pattern: /[0-9]{2}-[0-9]{3}/,
                          minLength: 6,
                        }}
                      ></Controller>
                      {errors.address?.postalCode?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.postalCode?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.CITY" /> *
                      </Form.Label>
                      <Form.Control
                        name="address.city"
                        type="text"
                        ref={register({
                          required: true,
                          pattern: { value: /^[\p{L}. -]+$/u },
                        })}
                        className={`${
                          formState.touched.address?.city &&
                          errors.address?.city
                            ? "is-invalid"
                            : ""
                        }${
                          formState.touched.address?.city &&
                          !errors.address?.city
                            ? "is-valid"
                            : ""
                        }`}
                      />
                      {errors.address?.city?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.address?.city?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                </>
              )}
              <Form.Row>
                <Col md={7} className="d-flex align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={wantInvoice}
                        onChange={(e) =>
                          setWantInvoice(e.currentTarget.checked)
                        }
                        name="wantInvoice"
                      />
                    }
                    label={intl.formatMessage({ id: "PRICING.WANNA_VAT" })}
                  />
                </Col>
                <Col
                  md={5}
                  className="d-flex justify-content-end align-items-center"
                >
                  {wantInvoice && (
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => copyContactDetail("invoiceAddress")}
                    >
                      <FormattedMessage id="PRICING.COPY_CONTACT_DATA" />
                    </Button>
                  )}
                </Col>
              </Form.Row>
              {wantInvoice && (
                <>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="PRICING.COMPANY_NAME" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="invoiceAddress.companyName"
                          type="text"
                          ref={register({ required: true })}
                          className={`${
                            formState.touched.invoiceAddress?.companyName &&
                            errors.invoiceAddress?.companyName
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.invoiceAddress?.companyName &&
                            !errors.invoiceAddress?.companyName
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.invoiceAddress?.companyName?.type ===
                        "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={3}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.NIP" /> *
                      </Form.Label>
                      <Controller
                        as={MaskedInput}
                        name="invoiceAddress.nip"
                        control={control}
                        mask="111-111-11-11"
                        className={`form-control ${
                          formState.touched.invoiceAddress?.nip &&
                          errors.invoiceAddress?.nip
                            ? "is-invalid"
                            : ""
                        }${
                          formState.touched.invoiceAddress?.nip &&
                          !errors.invoiceAddress?.nip
                            ? "is-valid"
                            : ""
                        }`}
                        rules={{
                          required: true,
                          pattern: /[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}/,
                          minLength: 13,
                        }}
                      ></Controller>
                      {errors.invoiceAddress?.nip?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.invoiceAddress?.nip?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={8}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.STREET_ADDRESS" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="invoiceAddress.street"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^[\p{L}0-9- ]+$/u },
                          })}
                          className={`${
                            formState.touched.invoiceAddress?.street &&
                            errors.invoiceAddress?.street
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.invoiceAddress?.street &&
                            !errors.invoiceAddress?.street
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.invoiceAddress?.street?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.invoiceAddress?.street?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.HOUSE_NUMBER" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="invoiceAddress.houseNumber"
                          type="text"
                          ref={register({
                            required: true,
                            pattern: { value: /^[\p{L}0-9\/]+$/u },
                          })}
                          className={`${
                            formState.touched.invoiceAddress?.houseNumber &&
                            errors.invoiceAddress?.houseNumber
                              ? "is-invalid"
                              : ""
                          }${
                            formState.touched.invoiceAddress?.houseNumber &&
                            !errors.invoiceAddress?.houseNumber
                              ? "is-valid"
                              : ""
                          }`}
                        />
                      </InputGroup>
                      {errors.invoiceAddress?.houseNumber?.type ===
                        "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.invoiceAddress?.houseNumber?.type ===
                        "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.APARTAMENT_NUMBER" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="invoiceAddress.aptNumber"
                          type="text"
                          ref={register({
                            pattern: { value: /^[\p{L}0-9\/]+$/u },
                          })}
                        />
                      </InputGroup>
                      {errors.invoiceAddress?.aptNumber?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.COUNTRY_CODE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="invoiceAddress.countryId"
                        ref={register()}
                      >
                        {countryCodes.map((countryCode) => (
                          <option key={countryCode.id} value={countryCode.id}>
                            {countryCode.code}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.POSTAL_CODE" /> *
                      </Form.Label>
                      <Controller
                        as={MaskedInput}
                        name="invoiceAddress.postalCode"
                        control={control}
                        mask="11-111"
                        className={`form-control ${
                          formState.touched.invoiceAddress?.postalCode &&
                          errors.invoiceAddress?.postalCode
                            ? "is-invalid"
                            : ""
                        }${
                          formState.touched.invoiceAddress?.postalCode &&
                          !errors.invoiceAddress?.postalCode
                            ? "is-valid"
                            : ""
                        }`}
                        rules={{
                          required: true,
                          pattern: /[0-9]{2}-[0-9]{3}/,
                          minLength: 1,
                        }}
                      ></Controller>
                      {errors.invoiceAddress?.postalCode?.type ===
                        "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.invoiceAddress?.postalCode?.type ===
                        "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={4}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.CITY" /> *
                      </Form.Label>
                      <Form.Control
                        name="invoiceAddress.city"
                        type="text"
                        ref={register({
                          required: true,
                          pattern: { value: /^[\p{L}. -]+$/u },
                        })}
                        className={`${
                          formState.touched.invoiceAddress?.city &&
                          errors.invoiceAddress?.city
                            ? "is-invalid"
                            : ""
                        }${
                          formState.touched.invoiceAddress?.city &&
                          !errors.invoiceAddress?.city
                            ? "is-valid"
                            : ""
                        }`}
                      />
                      {errors.invoiceAddress?.city?.type === "required" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                        </div>
                      )}
                      {errors.invoiceAddress?.city?.type === "pattern" && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INVALID_FORMAT" />
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                </>
              )}
              <div className="d-flex justify-content-end">
                <Button type="submit">
                  <FormattedMessage id="GENERAL.NEXT" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  else
    return (
      <div className="d-flex justify-content-center mt-10">
        <CircularProgress />
      </div>
    );
}
