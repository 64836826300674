import store from "../../../redux/store";
import { getSuppliers } from "../../services/suppliersCrud";
import errorHandler from "../../shared/errorHandler";
import displayFeedback from "./displayFeedback";
import getIntl from "./getIntl";

export default function getSuppliersToRedux() {
  const intl = getIntl()
  const activeFarmId = localStorage.getItem("activeFarmId") || null;
  const farmList = store.getState().auth.user?.farms;
  let bPartnerId = null;
  if (farmList.length > 0 && activeFarmId) {
    if (activeFarmId) {
      bPartnerId = farmList.find((farm) => {
        return farm.bpartnerId === parseInt(activeFarmId);
      }).bpartnerId;
    }
  }

  store.dispatch({
    type: "suppliers/setLoading",
    payload: true,
  });
  getSuppliers(bPartnerId)
    .then((response) => {
      store.dispatch({
        type: "suppliers/set",
        payload: response.data,
      });
      store.dispatch({
        type: "suppliers/setLoading",
        payload: false,
      });
    })
    .catch((error) => {
      const errorOptions = errorHandler(error);
      if (errorOptions.type === "error") {
        displayFeedback({
          type: errorOptions.type,
          message: `${intl.formatMessage({ id: errorOptions.message })}`,
        });
      }
    });
}
