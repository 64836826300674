import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { Form, Col, Button } from "react-bootstrap";

export function DataArchivizationForm(props) {
  const { lastArchivizationDate, onArchivingData } = props;
  const { handleSubmit } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState(pl);
  const [date, setDate] = useState(null);

  const setLocaleLanguage = () => {
    switch (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang) {
      case "en":
        registerLocale("enGB", enGB);
        setDatepickerLocale(enGB);
        break;
      case "pl":
        registerLocale("pl", pl);
        setDatepickerLocale(pl);
        break;
      case "de":
        registerLocale("de", de);
        setDatepickerLocale(de);
        break;
      case "ru":
        registerLocale("ru", ru);
        setDatepickerLocale(ru);
        break;
      default:
        break;
    }
  };

  useEffect(setLocaleLanguage, []);

  useEffect(() => {
    lastArchivizationDate && setDate(new Date(lastArchivizationDate));
  }, [lastArchivizationDate]);

  async function onSubmit() {
    const form = { historicalDate: moment(date).format("YYYY-MM-DD") };
    setLoading(true);
    let callback = await onArchivingData(form);
    callback && setLoading(false);
  }

  return (
    <Form className="mt-1" onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} lg={6} md={6} sm={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE" /> *
          </Form.Label>
          <DatePicker
            name="dateTo"
            selected={date}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => setDate(date)}
          />
        </Form.Group>
        <div className="col-xxl-12 text-right">
          <Button type="submit" disabled={loading || date === null}>
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </div>
      </Form.Row>
    </Form>
  );
}
