import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function MapCroppingsTable({ croppings, centerOnMap }) {
  return (
    <>
      {croppings.length > 0 ? (
        <Col xs={12} className="text-center mt-2 table-full-height">
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>
                  <FormattedMessage id="GENERAL.SHORT_NAME" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.ACTIONS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {croppings.map((cropping, index) => (
                <tr
                  key={`${cropping.croppingId}-${index}`}
                  className="text-center"
                >
                  <td>{cropping.shortName}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-tooltip">
                          <FormattedMessage id="GENERAL.SHOW_ON_MAP" />
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() =>
                          centerOnMap(
                            cropping.shape
                              ? cropping.shape.parent.shapeId
                              : cropping.point.shapeId
                          )
                        }
                      >
                        <i
                          className="flaticon2-location"
                          style={{ paddingLeft: "4px" }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      ) : (
        <Col xs={12} className="text-center mt-5">
          <h6>
            <FormattedMessage id="GENERAL.NO_CROPPINGS" />
          </h6>
        </Col>
      )}
    </>
  );
}
