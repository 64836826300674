import { useEffect, useState } from "react";
import store from "../../redux/store";

export function usePreferences(applicationSymbol) {
  const [show, setShow] = useState(null);
  const storeState = store.getState();

  useEffect(() => {
    const searchedApplication = storeState.auth.user.applications.find(
      (application) => {
        return application.symbol === applicationSymbol;
      }
    );

    setShow(searchedApplication ? true : false);
    // eslint-disable-next-line
  }, [storeState.auth?.user?.applications]);

  return show;
}
