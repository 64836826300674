import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
import "./ag-icons.scss"; // Agrico icons
// import "./icons.css"; // Agrico IcoMoons
import "./agrico-styles.scss"; // Agrico custom styles
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import { MetronicI18nProvider } from "./_metronic/i18n";
import {
  MetronicLayoutProvider,
  MetronicSubheaderProvider,
  MetronicSplashScreenProvider,
} from "./_metronic/layout";
// AG Grid Enterprise
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { LicenseManager } from  'ag-grid-enterprise'
// Toastify
import 'react-toastify/dist/ReactToastify.css';

LicenseManager.setLicenseKey("CompanyName=Seth Software sp. z o.o.,LicensedGroup=Cropchart,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-025675,ExpiryDate=22_November_2022_[v2]_MTY2OTA3NTIwMDAwMA==0df6762b65b67e37489d4cd27b628f52")


const { PUBLIC_URL } = process.env;
// _redux.mockAxios(axios);
_redux.setupAxios(axios, store);

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
