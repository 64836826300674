import React from "react";
import moment from "moment";

export function DocumentRow(props) {
  const { document, handleClick } = props;

  return (
    <tr
      className={`text-center cursor--pointer ${
        document.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => handleClick(document.id)}
    >
      <td>{document.name}</td>
      <td>{document.type}</td>
      <td>{moment(document.createdAt).format("YYYY-MM-DD")}</td>
    </tr>
  );
}
