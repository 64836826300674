import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { TariffsTable } from "../index";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "react-bootstrap";

export function TariffsTableCard({
  mode,
  tariffs,
  onSelectingRow,
  onChangingMode,
}) {
  const intl = useIntl();
  const [filter, setFilter] = useState("");
  const [checked, setChecked] = useState(true);
  const [filteredData, setFilteredData] = useState(tariffs || []);

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariffs, checked]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (tariffs.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return tariffs.filter((tarif) => {
      const nameIncludes = tarif.name
        .toLowerCase()
        .includes(text.toLowerCase());
      const valueIncludes = tarif.value
        ? tarif.value.toString().includes(text.toLowerCase())
        : false;
      if (checked) {
        if ((nameIncludes || valueIncludes) && tarif.isActive) {
          return tarif;
        }
      } else {
        if (nameIncludes || valueIncludes) {
          return tarif;
        }
      }
    });
  };

  const handleChange = (event) => {
    setChecked(event.currentTarget.checked);
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {tariffs.length === 0 ? (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_TARIFFS" />
            </h6>
          </div>
        ) : (
          <div className="card__table card__table--brigadier">
            <TariffsTable data={filteredData} onSelectingRow={onSelectingRow} />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                name="showActive"
              />
            }
            label={`${intl.formatMessage({
              id: "GENERAL.SHOW_ACTIVE_ONLY",
            })}`}
          />
          <Button disabled={mode === "create"} onClick={() => onChangingMode()}>
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
        </div>
      </div>
    </div>
  );
}
