import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import robotoFont from "../../../../fonts/Roboto-Light.ttf";
import { Row, Col, Button } from "react-bootstrap";

Font.register({ family: "Robot", src: robotoFont });

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 45,
    paddingHorizontal: 35,
    fontFamily: "Robot",
  },
  wrapper: {
    flexDirection: "column",
    flexWrap: "wrap",
  },
  topView: {
    flexDirection: "column",
    marginBottom: 20,
  },
  topViewTable: {
    width: "25vw",
    height: 55,
    fontSize: 10,
    border: "1px solid black",
  },
  topViewTableHeader: {
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom: 5,
    alignItems: "center",
    backgroundColor: "gray",
    borderBottom: "1px solid black",
  },
  fontWhite: {
    color: "white",
  },
  topViewTableCellRow: {
    display: "block",
    alignItems: "center",
  },
  fontBold: {
    fontWeight: "bold",
  },
  middleView: {
    flexDirection: "column",
  },
  middleViewTable: {
    width: "100%",
    flexDirection: "column",
  },
  tableWrapper: {
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    border: "1px solid black",
  },
  tableRowWrapper: {
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  smallColumnHeader: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  largeColumnHeader: {
    width: "30%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  mediumColumnHeader: {
    width: "15%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
    borderRight: "1px solid black",
  },
  mediumColumnHeaderWithoutBorder: {
    width: "15%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
  },
  smallColumn: {
    width: "10%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  largeColumn: {
    width: "30%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  mediumColumn: {
    width: "15%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    borderRight: "1px solid black",
  },
  mediumColumnWithoutBorder: {
    width: "15%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
  },
  threeColumnSpan: {
    width: "55%",
    borderRight: "1px solid black",
  },
  mediumColumnWithBackground: {
    width: "15%",
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: "center",
    backgroundColor: "gray",
  },
});

const PayoutsDocument = ({ activeWorker, operations, summary }) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.wrapper}>
        <View style={styles.topView}>
          <View style={styles.topViewTable}>
            <View style={styles.topViewTableHeader}>
              <Text style={styles.fontWhite}>Komu</Text>
            </View>
            <View style={styles.topViewTableCellRow}>
              <Text style={styles.fontBold}>{activeWorker.firstName}</Text>
            </View>
            <View style={styles.topViewTableCellRow}>
              <Text style={styles.fontBold}>{activeWorker.lastName}</Text>
            </View>
          </View>
        </View>

        <View style={styles.middleView}>
          <View style={styles.middleViewTable}>
            <View style={styles.tableWrapper}>
              <View style={styles.smallColumnHeader}>
                <Text style={styles.fontWhite}>Lp.</Text>
              </View>
              <View style={styles.largeColumnHeader}>
                <Text style={styles.fontWhite}>Za co</Text>
              </View>
              <View style={styles.mediumColumnHeader}>
                <Text style={styles.fontWhite}>Dokument</Text>
              </View>
              <View style={styles.mediumColumnHeader}>
                <Text style={styles.fontWhite}>Data</Text>
              </View>
              <View style={styles.mediumColumnHeader}>
                <Text style={styles.fontWhite}>Wartość</Text>
              </View>
              <View style={styles.mediumColumnHeaderWithoutBorder}>
                <Text style={styles.fontWhite}>Waluta</Text>
              </View>
            </View>
            {operations.map((operation, index) => (
              <React.Fragment key={`operation-${index}`}>
                {operation.__level__ === 0 && (
                  <View style={styles.tableRowWrapper}>
                    <View style={styles.smallColumn}>
                      <Text>{index + 1}</Text>
                    </View>
                    <View style={styles.largeColumn}>
                      <Text>{operation.payoutTypeName}</Text>
                    </View>
                    <View style={styles.mediumColumn}>
                      <Text>{operation.documentNo}</Text>
                    </View>
                    <View style={styles.mediumColumn}>
                      <Text>
                        {moment(operation.registerAt).format("YYYY-MM-DD")}
                      </Text>
                    </View>
                    <View style={styles.mediumColumn}>
                      <Text>{operation.value}</Text>
                    </View>
                    <View style={styles.mediumColumnWithoutBorder}>
                      <Text>{operation.currencyName}</Text>
                    </View>
                  </View>
                )}
              </React.Fragment>
            ))}
            {summary.map((row, index) => (
              <View key={`row-${index}`} style={styles.tableRowWrapper}>
                <View style={styles.threeColumnSpan}></View>
                <View style={styles.mediumColumnWithBackground}>
                  {index === 0 && <Text style={styles.fontWhite}>Razem:</Text>}
                </View>
                <View style={styles.mediumColumnWithBackground}>
                  <Text style={styles.fontWhite}>{row.sum}</Text>
                </View>
                <View style={styles.mediumColumnWithBackground}>
                  <Text style={styles.fontWhite}>{row.currencyName}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export function OperationsPDF({ activeWorker, operations }) {
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    if (operations.length) {
      calculateSummary();
    }
  }, [activeWorker, operations]);

  const calculateSummary = () => {
    let currenciesArray = [];
    operations.forEach((row) => {
      if (row.__level__ === 0) {
        if (typeof currenciesArray[row.__currencyId] === "undefined") {
          currenciesArray[row.__currencyId] = {
            currencyName: row.currencyName,
            sum: row.value,
          };
        } else {
          currenciesArray[row.__currencyId].sum += row.value;
        }
      }
    });
    let sortedArray = [];
    for (let key in currenciesArray) {
      sortedArray.push(currenciesArray[key]);
    }
    setSummary(sortedArray);
  };

  return (
    <>
      {isMobile ? (
        <Col
          md={12}
          lg={12}
          xxl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center">
            <PDFDownloadLink
              document={
                <PayoutsDocument
                  activeWorker={activeWorker}
                  operations={operations}
                  summary={summary}
                />
              }
              fileName={`document-${moment().format("DD.MM.YYYY_HH:mm")}.pdf`}
            >
              <Button>
                <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF" />
              </Button>
            </PDFDownloadLink>
          </div>
        </Col>
      ) : (
        <>
          <Row>
            <Col md={12} lg={12} xxl={12} style={{ minHeight: "50vh" }}>
              <PDFViewer style={{ width: "100%", height: "100%" }}>
                <PayoutsDocument
                  activeWorker={activeWorker}
                  operations={operations}
                  summary={summary}
                />
              </PDFViewer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
