import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import localePl from "../../../shared/agGridLocales/locale.pl";
import localeDe from "../../../shared/agGridLocales/locale.de";
import localeRu from "../../../shared/agGridLocales/locale.ru";
import localeEs from "../../../shared/agGridLocales/locale.es";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "react-bootstrap";

export function WorkersModalTable({
  workers,
  selectedWorkerIds,
  setSelectedWorkerIds,
  filter,
}) {
  const intl = useIntl();
  const [gridApi, setGridApi] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [columnDefs] = useState([
    {
      field: "firstName",
      headerName: intl.formatMessage({ id: "GENERAL.FIRSTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: intl.formatMessage({ id: "GENERAL.LASTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "item",
      headerName: intl.formatMessage({ id: "GENERAL.LABEL" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
    },
    {
      field: "badge",
      headerName: intl.formatMessage({ id: "GENERAL.INTERNAL_CODE" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
    },
  ]);

  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = (event) => {
    setSelectedWorkerIds(
      event.api.getSelectedNodes().map((node) => node.data.id)
    );
  };

  const getLocale = () => {
    if (languageCode === "pl") return localePl;
    if (languageCode === "de") return localeDe;
    if (languageCode === "ru") return localeRu;
    if (languageCode === "es") return localeEs;
    return null;
  };

  useEffect(() => {
    if (filter != null && gridApi) {
      gridApi.setQuickFilter(filter);
    }
  }, [filter, gridApi]);

  const anyWorkerSelected = useMemo(() => {
    return !!selectedWorkerIds.length > 0;
  }, [selectedWorkerIds.length]);

  const selectInGrid = useCallback(
    (ids) => {
      if (gridApi)
        gridApi.forEachNode((node) => {
          if (ids.includes(node.data.id)) node.setSelected(true);
          else node.setSelected(false);
        });
    },
    [gridApi]
  );

  const selectAll = () => {
    const ids = [];
    if (gridApi)
      gridApi.forEachNode((node) => {
        if (node.data.id && node.displayed) ids.push(node.data.id);
      });
    selectInGrid(ids);
  };

  const deselectAll = () => {
    selectInGrid([]);
  };

  const onFilterChanged = (event) => {
    if (event.api.getDisplayedRowCount() === 0) setBtnDisabled(true);
    else setBtnDisabled(false);
  };

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: window.innerHeight * 0.4, width: "auto" }}
      >
        <AgGridReact
          rowData={workers}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          localeText={getLocale()}
          rowHeight={24}
          pivotMode="false"
          suppressPivotMode
          suppressReactUi
          rowClass="ag-row-selectable"
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={(e) => onSelectionChanged(e)}
          suppressCellFocus
          suppressScrollOnNewData
          suppressRowVirtualisation
          onFilterChanged={onFilterChanged}
        />
      </div>
      <Button
        onClick={() => (!anyWorkerSelected ? selectAll() : deselectAll())}
        className="mt-4"
        disabled={
          (selectedWorkerIds.length === 0 && btnDisabled) ||
          workers.length === 0
        }
      >
        {!anyWorkerSelected ? (
          <FormattedMessage id="GENERAL.SELECT_ALL" />
        ) : (
          <FormattedMessage id="GENERAL.DESELECT_ALL" />
        )}
      </Button>
    </>
  );
}
