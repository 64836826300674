import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { AuthPage } from "./modules/auth";
import { Layout } from "../_metronic/layout";
import { SzTransportRodo, SzTransportRegulations } from "./modules/terms";
import { ConfirmationPage } from "./modules/confirmation";
import { DownloadPage } from "./modules/download";
import { SubscriptionsPage } from "./modules/payments";
import BasePage from "./BasePage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  let location = useLocation();
  const locationGoesToSubscriptionPricing = location.pathname.includes(
    "/user/subscriptions/buy/"
  )
    ? true
    : false;

  const redirectTo = new URLSearchParams(location.search)?.get("redirectTo");

  return (
    <Switch>
      {!isAuthorized ? (
        <Route path="/auth">
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to={redirectTo ? redirectTo : "/"} />
      )}

      <Route path="/terms/sz-transport-rodo" component={SzTransportRodo} />
      <Route
        path="/terms/sz-transport-regulations"
        component={SzTransportRegulations}
      />
      <Route path="/confirmation" component={ConfirmationPage} />
      <Route path="/download" component={DownloadPage} />
      <Route path="/subscriptions" component={SubscriptionsPage} />

      {!isAuthorized ? (
        <>
          {locationGoesToSubscriptionPricing && (
            <Redirect to={`/auth/login?redirectTo=${location.pathname}`} />
          )}
          {!locationGoesToSubscriptionPricing && <Redirect to="/auth/login" />}
        </>
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
