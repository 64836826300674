import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_URL}gw/logins/login2Subscription`;
export const CONTRACTOR_LOGIN_URL = `${process.env.REACT_APP_URL}gw/supCusts/supCust2Status`;

export function acceptFirstLoginInit(form) {
  return axios.post(`${LOGIN_URL}/init`, form);
}

export function acceptFirstLoginFinish(form) {
  return axios.post(`${LOGIN_URL}/finish`, form);
}

export function initAcceptBySMS(supCust2StatusId, form) {
  return axios.post(`${CONTRACTOR_LOGIN_URL}/${supCust2StatusId}/init`, form);
}

export function finishAcceptBySMS(login2subscriptionId, form) {
  return axios.post(
    `${CONTRACTOR_LOGIN_URL}/${login2subscriptionId}/finish`,
    form
  );
}