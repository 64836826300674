import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { PackagingForm } from "./PackagingForm";

export function PackagingGeneralDataCard({
  mode,
  formData,
  activePackaging,
  onCreatingPackaging,
  onUpdatingPackaging,
  openOrCloseModal,
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            {mode === "create" ? (
              <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
            ) : (
              <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
            )}
          </span>
          <h3 className="card-label">
            {mode === "create" ? (
              <FormattedMessage id="GENERAL.ADD_PACKAGING" />
            ) : (
              <>{activePackaging.name}</>
            )}
          </h3>
        </div>
      </div>
      <div className="card-body">
        <PackagingForm
          mode={mode}
          formData={formData}
          activePackaging={activePackaging}
          onCreatingPackaging={onCreatingPackaging}
          onUpdatingPackaging={onUpdatingPackaging}
          openOrCloseModal={openOrCloseModal}
        />
      </div>
    </div>
  );
}
