import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ReportForm } from "./ReportForm";
import { Preview } from "./Preview";
import { Report } from "./Report";
import { Tabs, Tab, Row } from "react-bootstrap";

export function ReportContainer({
  activeReportType,
  formData,
  reportData,
  onCreatingReport,
  onOpeningModal,
  onExportingReportToXLS,
}) {
  const [key, setKey] = useState("generateReport");

  useEffect(() => {
    if (activeReportType?.canExportToExcel && reportData?.result.length > 0) {
      setKey("preview");
    } else if (
      !activeReportType?.canExportToExcel &&
      reportData?.result.length > 0
    ) {
      setKey("report");
    } else {
      setKey("generateReport");
    }
  }, [reportData]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="generateReport"
            title={<FormattedMessage id="GENERAL.GENERATE_REPORT" />}
          >
            <ReportForm
              selectedReportType={activeReportType?.group}
              formData={formData}
              croppingsWithTreatmentDataAllowed={formData.sharedCroppings.filter(
                (item) => {
                  return item.isTreatmentDataAllowed;
                }
              )}
              onCreatingReport={onCreatingReport}
            />
          </Tab>
          <Tab
            eventKey="preview"
            title={<FormattedMessage id="GENERAL.PREVIEW" />}
            disabled={
              !reportData ||
              reportData?.result.length <= 0 ||
              !activeReportType?.canExportToExcel
            }
          >
            {activeReportType?.canExportToExcel && (
              <Preview
                selectedReportType={activeReportType}
                reportData={reportData}
                onExportingReportToXLS={onExportingReportToXLS}
              />
            )}
          </Tab>
          <Tab
            eventKey="report"
            title={<FormattedMessage id="GENERAL.REPORT" />}
            disabled={
              !reportData ||
              reportData?.result.length <= 0 ||
              !activeReportType?.canExportToPdf
            }
          >
            <Row>
              {key === "report" && (
                <Report
                  selectedReportType={activeReportType?.group}
                  reportData={reportData}
                  onOpeningModal={onOpeningModal}
                />
              )}
            </Row>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
