import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import { getAllAgronomistToOrgentity, createAgronomist, deleteAgronomist } from "../../../services/crop-manager/agronomistsCrud"
import { AgronomistsList } from "../components/AgronomistsList";
import { Row, Col } from "react-bootstrap";
import { AddNewAgronomistForm } from "../components/AddNewAgronomistForm";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function AgronomistsPage() {
  const intl = useIntl();
  const [agronomists, setAgronomists] = useState([]);
  const [agronomistsLoaded, setAgronomistsLoaded] = useState(false)

  const getAgronomists = () => {
    getAllAgronomistToOrgentity()
      .then((response) => {
        setAgronomists(response.data);
        setAgronomistsLoaded(true)
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onAddingAgronomist = (form) => {
    return new Promise((resolve) => {
      createAgronomist(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getAgronomists();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onDeletingAgronomist = (loginId) => {
    return new Promise((resolve) => {
      deleteAgronomist(loginId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getAgronomists();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  useEffectOnce(getAgronomists);

  return (
    <Row>
      <Col md={12} lg={5}>
        <AddNewAgronomistForm
          onAddingAgronomist={onAddingAgronomist}
        />
      </Col>
      <Col md={12} lg={7}>
        <AgronomistsList
          agronomists={agronomists}
          agronomistsLoaded={agronomistsLoaded}
          onAddingAgronomist={onAddingAgronomist}
          onDeletingAgronomist={onDeletingAgronomist}
        />
      </Col>
    </Row>
  );
}

export default AgronomistsPage;
