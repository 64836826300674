const initialState = {
  events: [],
  treatmentTypes: [],
  treatmentTypesList: undefined,
  dates: undefined,
  eventsLoading: false,
  eventStatuses: [],
  dimSizes: [],
  varieties: [],
  formDates: undefined,
  croppings: undefined,
  croppingShape: null,
  treatmentDetails: null,
  treatmentHeadId: null,
  fertilizers: [],
  plantProtectionProducts: [],
  unitOfMeasurements: [],
  openModals: {
    create: false,
    update: false,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "calendar/setEvents": {
      return { ...state, events: action.payload };
    }

    case "calendar/setTreatmentTypes": {
      return {
        ...state,
        treatmentTypes: action.payload.map((type) => ({
          ...type,
          checked: true,
        })),
      };
    }

    case "calendar/setTreatmentTypeChecked": {
      return {
        ...state,
        treatmentTypes: state.treatmentTypes.map((type) => {
          if (type.treatmentTypeId !== action.payload.treatmentTypeId)
            return type;
          return {
            ...type,
            checked: action.payload.checked,
          };
        }),
      };
    }

    case "calendar/setAllTreatmentTypesChecked": {
      return {
        ...state,
        treatmentTypes: state.treatmentTypes.map((type) => {
          return {
            ...type,
            checked: action.payload,
          };
        }),
      };
    }

    case "calendar/setTreatmentTypesList": {
      return { ...state, treatmentTypesList: action.payload };
    }

    case "calendar/setDates": {
      return { ...state, dates: action.payload };
    }

    case "calendar/setEventsLoading": {
      return { ...state, eventsLoading: action.payload };
    }

    case "calendar/setEventStatuses": {
      return {
        ...state,
        eventStatuses: action.payload.map((eventStatus) => ({
          ...eventStatus,
          checked: true,
        })),
      };
    }

    case "calendar/setEventStatusChecked": {
      return {
        ...state,
        eventStatuses: state.eventStatuses.map((eventStatus) => {
          if (eventStatus.symbol !== action.payload.symbol) return eventStatus;
          return {
            ...eventStatus,
            checked: action.payload.checked,
          };
        }),
      };
    }

    case "calendar/setDimSizes": {
      return { ...state, dimSizes: action.payload };
    }

    case "calendar/setVarieties": {
      return { ...state, varieties: action.payload };
    }

    case "calendar/setFormDates": {
      return { ...state, formDates: action.payload };
    }

    case "calendar/setCroppings": {
      return { ...state, croppings: action.payload };
    }

    case "calendar/setCroppingShape": {
      return { ...state, croppingShape: action.payload };
    }

    case "calendar/setTreatmentDetails": {
      return { ...state, treatmentDetails: action.payload };
    }

    case "calendar/setTreatmentHeadId": {
      return { ...state, treatmentHeadId: action.payload };
    }

    case "calendar/setFertilizers": {
      return { ...state, fertilizers: action.payload };
    }

    case "calendar/setPlantProtectionProducts": {
      return { ...state, plantProtectionProducts: action.payload };
    }

    case "calendar/setUOM": {
      return { ...state, unitOfMeasurements: action.payload };
    }

    case "calendar/setCreateModalOpen": {
      return {
        ...state,
        openModals: { ...state.openModals, create: action.payload },
      };
    }

    case "calendar/setUpdateModalOpen": {
      return {
        ...state,
        openModals: { ...state.openModals, update: action.payload },
      };
    }

    default:
      return state;
  }
};
