import React, { useEffect, useState } from "react";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { TextField } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";

export default function CroppingsSearch(props) {
  const { onChange } = props
  const intl = useIntl();
  const [showSearchBackground, setShowSearchBackground] = useState(false);
  const [searchTerm, setSearchTerm] = useState(props.searchTerm)

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 24) {
      setShowSearchBackground(true);
    }
    else {
      setShowSearchBackground(false);
    }
  }

  const onSearchTermChange = (value) => {
    setSearchTerm(value)
  }

  useEffectOnce(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    const timer = () => setTimeout(() => onChange(searchTerm) , 200);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm, onChange]);

  return (
    <div className={`dashboard-search ${showSearchBackground ? "show-bg" : ""}`}>
      <div className="bg-white rounded-lg d-flex justify-content-center mx-auto px-4 py-2 py-lg-4 dashboard-search__container d-flex align-items-center">
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} className="mr-4" />
        <TextField
          placeholder={`${intl.formatMessage({
            id: "PLACEHOLDER.SEARCH",
          })}...`}
          value={searchTerm}
          onChange={(e) => {onSearchTermChange(e.target.value)}}
          color="primary"
        />
        <button
          className={`ml-4 p-0 bg-white border-0 dashboard-search__container__delete-btn ${searchTerm ? "active" : ""}`}
          disabled={!searchTerm}
          onClick={() => onSearchTermChange("")}
        >
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Backspace.svg")} />
        </button>
      </div>
    </div>
  )
}
