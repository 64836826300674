import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import displayFeedback from "../components/helpers/displayFeedback";
import { refreshCredential } from "../modules/auth/_redux/authCrud";
import { getPaymentDetails } from "../services/paymentsCrud";
import errorHandler from "../shared/errorHandler";

export default function SubscriptionThankYouPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const paymentId = useQuery().get("id");

  useEffect(() => {
    if (paymentId) {
      setLoading(true);
      getPaymentDetails(paymentId)
        .then((response) => {
          setDetails(response.data);
          refreshCredential()
            .then((response) => {
              const user = {
                canLogin: response.data.canLogin,
                email: response.data.email,
                id: response.data.id,
                isAnonymous: response.data.isAnonymous,
                login: response.data.login,
                permissions: response.data.permissions,
                person: response.data.person,
                subscriptions: response.data.subscriptions,
                applications: response.data.applications,
                farms: response.data.bpartners,
                showTutorials: response.data.showTutorials,
              };
              dispatch({
                type: "[User Data] Refresh",
                payload: {
                  user
                },
              });
              dispatch({
                type: "[User Data Subscription Refresh] Action",
                payload: {
                  userData: response.data
                }
              })
            })
            .catch((error) => {
              const errorOptions = errorHandler(error);
              if (errorOptions.type === "error") {
                displayFeedback({
                  type: errorOptions.type,
                  message: `${intl.formatMessage({ id: errorOptions.message })}`,
                });
                setLoading(false);
              }
            });
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            setLoading(false);
          }
        });
    }
  }, [dispatch, intl, paymentId]);

  if (details)
    return (
      <>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="bg-white rounded-lg shadow p-7">
              {details.isSuccessFinished === true && (
                <>
                  <div className="symbol symbol-100 symbol-green d-flex justify-content-center">
                    <span className="symbol-label font-weight-bold rounded-circle">
                      <i
                        className="fa fa-check"
                        style={{ color: "inherit", fontSize: "3rem" }}
                      />
                    </span>
                  </div>
                  <h1 className="text-center my-4">
                    <FormattedMessage id="PRICING.THANKS_FOR_PURCHASE" />
                  </h1>
                  <p className="text-center">
                    <FormattedMessage id="PRICING.MORE_INFO_15_MIN" />.
                  </p>
                </>
              )}
              {details.isSuccessFinished === false && details.isSuccessInit && (
                <>
                  <div className="symbol symbol-100 symbol-danger d-flex justify-content-center">
                    <span className="symbol-label font-weight-bold rounded-circle">
                      <i
                        className="fa fa-exclamation"
                        style={{ color: "inherit", fontSize: "3rem" }}
                      />
                    </span>
                  </div>
                  <h1 className="text-center mt-4 mb-8">
                    <FormattedMessage id="PRICING.PAYMENT_FAILED" />
                  </h1>
                </>
              )}
              {details.isSuccessFinished === null && details.isSuccessInit && (
                <>
                  <div className="symbol symbol-100 symbol-warning d-flex justify-content-center">
                    <span className="symbol-label font-weight-bold rounded-circle">
                      <i
                        className="fa fa-clock"
                        style={{ color: "inherit", fontSize: "3rem" }}
                      />
                    </span>
                  </div>
                  <h1 className="text-center mt-4 mb-8">
                    <FormattedMessage id="PRICING.PAYMENT_IN_PROGRESS" />
                  </h1>
                </>
              )}
              <Link to="/user/subscriptions" className="mx-auto d-table mt-7">
                <Button>
                  <FormattedMessage id="PRICING.BACK_TO_CROPCHART" />
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </>
    );
  else if (loading)
    return (
      <>
        <div className="d-flex justify-content-center mt-10">
          <CircularProgress />
        </div>
      </>
    );
  else return null;
}
