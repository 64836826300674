import axios from "axios";

export const SPECIES_URL = `${process.env.REACT_APP_URL}species`;
export const VARIETIES_URL = `${process.env.REACT_APP_URL}varieties`;

export function getAllSpecies() {
  return axios.get(`${SPECIES_URL}/all`);
}

export function getSpeciesDetails(id) {
  return axios.get(`${SPECIES_URL}/${id}/details`);
}

export function getSpeciesInUserCropping(farmId) {
  return axios.get(`${SPECIES_URL}/cropping/bPartner/${farmId}`);
}

export function getSpeciesInUserCroppingAndField(farmId) {
  return axios.get(`${SPECIES_URL}/map/bPartner/${farmId}`);
}
