import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function OffersNewOffer(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    formState,
    reset,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [props.sellOfferId])

  useEffect(() => {
    if (props.copyOffer) {
      setValue(
        "primaryWeight",
        (
          props.growerOffer?.primaryWeight - props.growerOffer?.weightSold
        ).toFixed(2)
      );
      setValue("price", props.growerOffer?.price.toFixed(2));
    }
  });

  async function onSubmit(data) {
    setLoading(true);
    const callback = await props.onCreatingBuyOffer(props.sellOfferId, data);
    if (callback === "OK") {
      resetForm();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const resetForm = () => {
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Col xs={12}>
          <h6>
            <FormattedMessage id="PE.NEW_OFFER" />
          </h6>
        </Col>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.WEIGHT" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="primaryWeight"
              type="number"
              className={`${
                formState.touched.primaryWeight && errors.primaryWeight
                  ? "is-invalid"
                  : ""
              } ${
                formState.touched.primaryWeight && !errors.primaryWeight
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.WEIGHT_IN_TONNES",
              })}`}
              step="0.01"
              ref={register({ required: true, min: 0.1 })}
            />
            <InputGroup.Append>
              <InputGroup.Text>t</InputGroup.Text>
            </InputGroup.Append>
            {errors.primaryWeight?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.primaryWeight?.type === "min" &&
              formState.touched.primaryWeight && (
                <div className="error invalid-feedback">
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                </div>
              )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PRICE" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="price"
              type="number"
              className={`${
                formState.touched.price && errors.price ? "is-invalid" : ""
              } ${formState.touched.price && !errors.price ? "is-valid" : ""}`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.PRICE_PER_TON",
              })}`}
              step="0.01"
              ref={register({ required: true, min: 0.1 })}
            />
            <InputGroup.Append>
              <InputGroup.Text>PLN/t</InputGroup.Text>
            </InputGroup.Append>
            {errors.price?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.price?.type === "min" && formState.touched.price && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.COMMENT" />
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="comment"
            ref={register({ required: false })}
          />
        </Form.Group>
        <div className="col-xxl-12 text-right">
          <Button type="submit" disabled={loading}>
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </div>
      </Form.Row>
    </Form>
  );
}
