import React from "react";

export function PackagingRow({ bundle, onSelectingRow }) {
  return (
    <tr
      className={`text-center cursor--pointer ${
        bundle.isSelected === true ? "offer--active" : ""
      } ${!bundle.isActive && !bundle.isSelected ? "row__factory" : ""}`}
      onClick={() => onSelectingRow(bundle.id)}
    >
      <td>{bundle.name}</td>
      <td>
        {bundle.quantity ? `${bundle.quantity} ${bundle.uom.symbol}` : ""}
      </td>
    </tr>
  );
}
