import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { OffersUpdateOffer } from "./OffersUpdateOffer";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function OffersContractorOfferDetails(props) {
  const intl = useIntl();
  const { register, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "primaryWeight",
      (
        props.offer?.sellOffer.primaryWeight - props.offer?.sellOffer.weightSold
      ).toFixed(2)
    );
    setValue("price", props.offer?.sellOffer.price.toFixed(2));
    setValue("comment", props.offer?.sellOffer.comment);
    if (props.offer?.groOwnerComment) {
      setValue("growerComment", props.offer?.groOwnerComment);
    }
    // eslint-disable-next-line
  }, [props.offer]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Documents.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GROWERS.PURCHASE_OFFER" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Form>
          <Form.Row>
            <Col xs={12}>
              <h6>
                <FormattedMessage id="OFFERS.SALE_OFFER" />
              </h6>
            </Col>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.WEIGHT" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="primaryWeight"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: true, min: 0.1 })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.PRICE" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="price"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.PRICE_PER_TON",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: true, min: 0.1 })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>PLN/t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.COMMENT" />
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="comment"
                disabled
                ref={register({ required: false })}
              />
            </Form.Group>
            {props.offer?.groOwnerComment && (
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.GROWER_COMMENT" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="growerComment"
                    type="text"
                    placeholder="Grower comment"
                    disabled
                    ref={register({ required: false })}
                  />
                </InputGroup>
              </Form.Group>
            )}
          </Form.Row>
        </Form>
        <h6>
          <FormattedMessage id="GENERAL.CONTACT_INFORMATION" />
        </h6>
        <Row>
          <Col md={12}>
            {props.offer?.contacts.map((contact) => (
              <div
                key={contact.contactId}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <span className="font-weight-bold mr-2">
                  {contact.type === "T" && (
                    <FormattedMessage id="GENERAL.PHONE" />
                  )}
                  {contact.type === "C" && (
                    <FormattedMessage id="GENERAL.MOBILE" />
                  )}
                  {contact.type === "F" && (
                    <FormattedMessage id="GENERAL.FAX" />
                  )}
                  {contact.type === "M" && (
                    <FormattedMessage id="GENERAL.EMAIL" />
                  )}
                  :
                </span>
                <span style={{ fontWeight: "700" }}>{contact.desc}</span>
              </div>
            ))}
          </Col>
        </Row>
        <OffersUpdateOffer
          offer={props.offer}
          maxWeight={(
            props.offer?.sellOffer.primaryWeight -
            props.offer?.sellOffer.weightSold
          ).toFixed(2)}
          onDeletingBuyOffer={props.onDeletingBuyOffer}
          onUpdatingBuyOffer={props.onUpdatingBuyOffer}
        />
      </div>
    </div>
  );
}
