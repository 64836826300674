import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { I18nProvider } from "../_metronic/i18n";
import { Routes } from "./Routes";
import "../_metronic/i18n/i18next";
import { getSelectedLang } from "../_metronic/i18n/getSelectedLang";
import { Slide, ToastContainer } from "react-toastify";
import PageTitle from "./components/PageTitle";

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    document.documentElement.lang = getSelectedLang().selectedLang;
  }, []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <Router basename={basename}>
            <MaterialThemeProvider>
              <I18nProvider>
                <ToastContainer
                  autoClose={6000}
                  theme="colored"
                  transition={Slide}
                />
                <PageTitle />
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </Router>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
