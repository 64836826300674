import enMessages from "../../../_metronic/i18n/messages/en";
import plMessages from "../../../_metronic/i18n/messages/pl";
import deMessages from "../../../_metronic/i18n/messages/de";
import ruMessages from "../../../_metronic/i18n/messages/ru";
import esMessages from "../../../_metronic/i18n/messages/es";
import { createIntl, createIntlCache } from "react-intl";
import { getSelectedLang } from "../../../_metronic/i18n/getSelectedLang";

export default function getIntl() {
  const cache = createIntlCache();
  const languageCode = getSelectedLang().selectedLang;
  const allMessages = {
    en: enMessages,
    pl: plMessages,
    de: deMessages,
    ru: ruMessages,
    es: esMessages,
  };

  return createIntl(
    {
      locale: languageCode,
      messages: allMessages[languageCode],
    },
    cache
  );
}
