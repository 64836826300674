import React from "react";
import { FormattedMessage } from "react-intl";

export function HintText({ label }) {
  return (
    <span className="form-text text-muted">
      <FormattedMessage id={`GENERAL.${label}`} />.
    </span>
  );
}
