import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Chart from "react-apexcharts";
import moment from "moment";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export function CroppingWeatherForecast({ weather }) {
  const intl = useIntl();
  const [now, setNow] = useState();
  const [timeline, setTimeline] = useState([]);
  const [chartVisible, setChartVisible] = useState(false);
  const [series, setSeries] = useState([
    { name: `${intl.formatMessage({ id: "GENERAL.TEMPERATURE" })}`, data: [] },
  ]);
  const [options, setOptions] = useState({
    chart: {
      id: "weather-chart",
      events: {
        markerClick: function(
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) {
          onSelectingDay(series[seriesIndex].data[dataPointIndex]);
        },
      },
      groupData: null,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    xaxis: { categories: [] },
    colors: ["#27AD7A"],
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD HH:mm:ss");
    const searchedDay = weather.list.find((day) => {
      let closestDate = false;
      if (!closestDate && moment(today).isBefore(day.dt_txt)) {
        const desc = day.weather[0].description;
        day.weather[0].description = desc.replaceAll(" ","_").toUpperCase();
        closestDate = true;
      }
      return closestDate ? day : "";
    });
    setChartVisible(true);
    setNow(searchedDay);
    createTimeline();
  }, [weather]);

  const createTimeline = () => {
    const timelineArray = [];
    weather.list.forEach((day) => {
      const objectExist = timelineArray.find((ele) => {
        return ele.date === moment(day.dt_txt).format("YYYY-MM-DD");
      });
      if (objectExist) {
        if (objectExist.tempMax < day.main.temp_max) {
          objectExist.tempMax = day.main.temp_max;
        }
        if (objectExist.tempMin > day.main.temp_min) {
          objectExist.tempMin = day.main.temp_min;
        }
        objectExist.days.push(day);
      } else {
        timelineArray.push({
          date: moment(day.dt_txt).format("YYYY-MM-DD"),
          days: [day],
          tempMax: day.main.temp_max,
          tempMin: day.main.temp_min,
        });
      }
    });
    timelineArray.forEach((group) => {
      const middle = Math.floor(group.days.length - 1 / 2);
      group.icon = group.days[middle].weather[0].icon;
    });
    setTimeline(timelineArray);
    if (!options.xaxis.categories.length) {

      let date = moment().format("YYYY-MM-DD");
      if(timelineArray.length > 0)
        date = moment(timelineArray[0].date).format("YYYY-MM-DD");    

      const searchedGroup = timelineArray.find((group) => {
        return group.date === date;
      });
      onBuildingChart(searchedGroup);
    }
  };

  const onChangingWeatherDetails = (group) => {
    let firstDay = group.days[0];
    const desc = firstDay.weather[0].description;
    firstDay.weather[0].description = desc.replaceAll(" ","_").toUpperCase();
    setNow(firstDay);
    onBuildingChart(group);
  };

  const onBuildingChart = (group) => {
    setChartVisible(false);
    let newOptions = { ...options };
    newOptions.chart.groupData = group;
    if (newOptions.xaxis.categories.length) {
      newOptions.xaxis.categories = [];
    }
    let newSeries = [...series];
    if (newSeries[0].data.length) {
      newSeries[0].data = [];
    }
    group.days.forEach((day) => {
      newOptions.xaxis.categories.push(moment(day.dt_txt).format("HH:mm"));
      newSeries[0].data.push(day.main.temp);
    });
    setOptions(newOptions);
    setSeries(newSeries);
    setTimeout(() => {
      setChartVisible(true);
    });
  };

  const onSelectingDay = (temperature) => {
    const searchedDay = options.chart.groupData.days.find((day) => {
      if (temperature === day.main.temp) {
        const desc = day.weather[0].description;
        day.weather[0].description = desc.replaceAll(" ","_").toUpperCase();
      }
      return temperature === day.main.temp;
    });
    setNow(searchedDay);
  };

  return (
    <Row>
      {now && (
        <>
          <Col xs={12}>
            {moment(now.dt_txt).format("dddd") === "Monday" && (
              <FormattedMessage id="GENERAL.MONDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Tuesday" && (
              <FormattedMessage id="GENERAL.TUESDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Wednesday" && (
              <FormattedMessage id="GENERAL.WEDNESDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Thursday" && (
              <FormattedMessage id="GENERAL.THURSDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Friday" && (
              <FormattedMessage id="GENERAL.FRIDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Saturday" && (
              <FormattedMessage id="GENERAL.SATURDAY" />
            )}
            {moment(now.dt_txt).format("dddd") === "Sunday" && (
              <FormattedMessage id="GENERAL.SUNDAY" />
            )}{" "}
            {moment(now.dt_txt).format("HH:mm")}
          </Col>
          <Col className="weather__description text-muted" xs={12}>
            {/* {now.weather[0]?.description} */}
               {/* {now.weather[0]?.description.replaceAll(" ","_").toUpperCase()} */}
            <FormattedMessage id={`WEATHER.${now.weather[0]?.description}`} />
       
          </Col>
          <Col xs={6}>
            <img
              height="64"
              width="64"
              src={`http://openweathermap.org/img/wn/${now.weather[0].icon}@2x.png`}
            />
            {now.main.temp.toFixed(0)} &#8451;
          </Col>
          <Col xs={6}>
            <div>
              <FormattedMessage id="GENERAL.RAINFALL" />:{" "}
              {now.rain ? <>{now?.rain["3h"]}</> : <>0</>}mm
            </div>
            <div>
              <FormattedMessage id="GENERAL.HUMIDITY" />: {now.main.humidity}%
            </div>
            <div>
              <FormattedMessage id="GENERAL.WIND" />:{" "}
              {now.wind.speed.toFixed(1)}m/s
            </div>
          </Col>
          <Col xs={12}>
            {chartVisible && (
              <Chart series={series} options={options} type="area" />
            )}
          </Col>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {timeline.map((day, index) => (
                <Button
                  key={index}
                  variant="secondary"
                  className="weather__button"
                  onClick={() => onChangingWeatherDetails(day)}
                >
                  <div className="weather__button_text text-center">
                    {moment(day.date).format("dddd") === "Monday" && (
                      <FormattedMessage id="GENERAL.MO" />
                    )}
                    {moment(day.date).format("dddd") === "Tuesday" && (
                      <FormattedMessage id="GENERAL.TU" />
                    )}
                    {moment(day.date).format("dddd") === "Wednesday" && (
                      <FormattedMessage id="GENERAL.WE" />
                    )}
                    {moment(day.date).format("dddd") === "Thursday" && (
                      <FormattedMessage id="GENERAL.TH" />
                    )}
                    {moment(day.date).format("dddd") === "Friday" && (
                      <FormattedMessage id="GENERAL.FR" />
                    )}
                    {moment(day.date).format("dddd") === "Saturday" && (
                      <FormattedMessage id="GENERAL.SA" />
                    )}
                    {moment(day.date).format("dddd") === "Sunday" && (
                      <FormattedMessage id="GENERAL.SU" />
                    )}
                    .
                  </div>
                  <div className="text-center">
                    <img
                      height="32"
                      width="32"
                      src={`http://openweathermap.org/img/wn/${day.icon}@2x.png`}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <span className="text--bold" style={{ fontSize: "8px" }}>
                      {day.tempMax.toFixed(0)}&#186;
                    </span>
                    <span className="text--muted" style={{ fontSize: "8px" }}>
                      {day.tempMin.toFixed(0)}&#186;
                    </span>
                  </div>
                </Button>
              ))}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
}
