import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GoogleMap,
  Marker,
  Polygon,
  StandaloneSearchBox,
  useJsApiLoader,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Row, Col } from "react-bootstrap";
import { createPolygonStructureWithHoles } from "../../../utils/mapUtils";

const googleMapStyle = {
  width: "100%",
  height: "78vh",
};

const fieldPolygonOptions = {
  fillColor: "#b8cf67",
  fillOpacity: "0.45",
  strokeColor: "#b8cf67",
  strokeWeight: "3",
  clickable: true,
  visible: true,
  zIndex: 1,
};

const croppingPolygonOptions = {
  fillColor: "#32CD32",
  fillOpacity: "0.45",
  strokeColor: "#32CD32",
  strokeWeight: "3",
  clickable: true,
  visible: true,
  zIndex: 2,
};

const treatmentPolygonOptions = {
  fillColor: "#A52A2A",
  fillOpacity: "0.45",
  strokeColor: "#A52A2A",
  strokeWeight: "3",
  clickable: true,
  visible: true,
  zIndex: 3,
};

const markerClustererOptions = {
  imagePath: "/media/maps/m",
};

export function Map(props) {
  const {
    line,
    fields,
    croppings,
    showShapesAsMarkers,
    treatments,
    showTreatments,
    details,
    onGetFieldDetails,
    onGetCroppingDetails,
    onGetTreatmentDetails,
  } = props;
  const intl = useIntl();
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [center, setCenter] = useState({ lat: 52, lng: 20 });
  const [googleMap, setGoogleMap] = useState(null);
  const [searchbox, setSearchbox] = useState(null);
  const [fieldMarkers, setFieldMarkers] = useState([]);
  const [fieldPolygons, setFieldPolygons] = useState([]);
  const [cropMarkers, setCropMarkers] = useState([]);
  const [cropPolygons, setCropPolygons] = useState([]);
  const [showWindowBox, setShowWindowBox] = useState(false);
  const [windowBoxPosition, setWindowBoxPosition] = useState();
  const [windowBoxContent, setWindowBoxContent] = useState();
  const [clickedShapeDetails, setClickedShapeDetails] = useState();
  const [mapFieldShapes, setMapFieldShapes] = useState([]);
  const [mapCroppingsShapes, setMapCroppingsShapes] = useState([]);
  const [treatmentsShapes, setTreatmentsShapes] = useState([]);
  const [treatmentPolygons, setTreatmentPolygons] = useState([]);
  const [shapeMap, setShapeMap] = useState(null);
  const [clusterWindowBoxData, setClusterWindowBoxData] = useState();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION,
  });

  const setCenterAndZoom = () => {
    if (googleMap && line) {
      const latLngList = [];
      line.coordinates.forEach((coordinate) => {
        latLngList.push(
          new window.google.maps.LatLng(coordinate[1], coordinate[0])
        );
      });
      let bounds = new window.google.maps.LatLngBounds();
      latLngList.forEach((row) => {
        bounds.extend(row);
      });
      googleMap.setCenter(bounds.getCenter());
      googleMap.fitBounds(bounds);
      googleMap.setZoom(googleMap.getZoom() - 1);
    } else if (googleMap) {
      googleMap.setZoom(6);
    }
  };

  useEffect(setCenterAndZoom, [googleMap, line]);

  useEffect(() => {
    if (details) {
      setWindowBoxContent(details);
    }
  }, [details]);

  const drawFields = () => {
    if (googleMap /*&& line*/ && fields) {
      const newFieldMarkers = [];
      const newFieldPolygons = [];
      fields.forEach((field) => {
        if (showShapesAsMarkers || (field.point && !field.shape)) {
          newFieldMarkers.push(field);
        } else {
          newFieldPolygons.push(field);
        }
      });
      if (newFieldMarkers.length > 0) {
        drawFieldMarkers(newFieldMarkers);
      } else setFieldMarkers([]);
      if (newFieldPolygons.length) {
        drawFieldPolygons(newFieldPolygons);
      } else {
        setFieldPolygons([]);
      }
    }
  };

  const drawFieldMarkers = (arr) => {
    const points = arr.map((field) => {
      if (field.shape && showShapesAsMarkers)
        return {
          ...field,
          position: {
            lat: field.shape.parent.shape.coordinates[0][0][1],
            lng: field.shape.parent.shape.coordinates[0][0][0],
          },
        };
      return {
        ...field,
        position: {
          lat: field.point.point.coordinates[1],
          lng: field.point.point.coordinates[0],
        },
      };
    });
    setFieldMarkers(points);
  };

  const drawFieldPolygons = (arr) => {
    const polygons = arr.map((field) => {
      const coordinates = createFieldShape(field.shape);
      return {
        ...field,
        coordinates: coordinates,
      };
    });
    setFieldPolygons(polygons);
  };

  const createFieldShape = (shape) => {
    let fieldCoordinates = [];
    let outerCoordinates = [];
    shape.parent.shape.coordinates.forEach((coordinate) => {
      coordinate.forEach((point) => {
        outerCoordinates.push({ lat: point[1], lng: point[0] });
      });
    });
    fieldCoordinates.push(outerCoordinates);
    if (shape.children.length) {
      shape.children.forEach((row) => {
        let innerCoordinates = [];
        row.shape.coordinates.forEach((coordinate) => {
          coordinate.forEach((point) => {
            innerCoordinates.push({ lat: point[1], lng: point[0] });
          });
          fieldCoordinates.push(innerCoordinates);
        });
      });
    }
    return fieldCoordinates;
  };

  useEffect(drawFields, [googleMap, line, fields, showShapesAsMarkers]);

  const drawCroppings = () => {
    if (googleMap && /*line &&*/ croppings) {
      const newCroppingMarkers = [];
      const newCroppingPolygons = [];
      croppings.forEach((cropping) => {
        if (
          showShapesAsMarkers ||
          (cropping.shape === null && cropping.point)
        ) {
          newCroppingMarkers.push(cropping);
        } else {
          newCroppingPolygons.push(cropping);
        }
      });

      if (newCroppingMarkers.length > 0) {
        drawCropMarkers(newCroppingMarkers);
      } else setCropMarkers([]);

      if (newCroppingPolygons.length > 0) {
        drawCropPolygons(newCroppingPolygons);
      } else {
        setCropPolygons([]);
      }
    }
  };

  const drawCropMarkers = (crops) => {
    const points = crops.map((cropping) => {
      if (cropping.shape && showShapesAsMarkers)
        return {
          ...cropping,
          position: {
            lat: cropping.shape.children[0].shape.coordinates[0][0][1],
            lng: cropping.shape.children[0].shape.coordinates[0][0][0],
          },
        };
      return {
        ...cropping,
        position: {
          lat: cropping.point.point.coordinates[1],
          lng: cropping.point.point.coordinates[0],
        },
      };
    });
    setCropMarkers(points);
  };

  const drawCropPolygons = (crops) => {
    let cropsPolygons = [];
    crops.forEach((cropping) => {
      cropping.shape.children.forEach((shape) => {
        cropsPolygons.push({
          ...cropping,
          shape: shape,
        });
      });
    });
    const polygons = cropsPolygons.map((cropping) => {
      const coordinates = createPolygonStructureWithHoles(
        cropping.shape.shape.coordinates
      );

      return {
        ...cropping,
        coordinates: coordinates,
      };
    });
    setCropPolygons(polygons);
  };

  useEffect(drawCroppings, [googleMap, line, croppings, showShapesAsMarkers]);

  const onPlacesChanged = () => {
    const searchedPlace = searchbox.getPlaces()[0];
    setCenter({
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    });
  };

  const handleFieldLoad = (field, data) => {
    field.data = data;
    const newMapFieldShapes = mapFieldShapes;
    if (!mapFieldShapes.find((fs) => fs?.data?.fieldId === data.fieldId))
      newMapFieldShapes.push(field);
    setMapFieldShapes(newMapFieldShapes);
  };

  const handleCroppingLoad = (cropping, data) => {
    cropping.data = data;
    const newMapCroppingsShapes = mapCroppingsShapes;
    if (
      !mapCroppingsShapes.find(
        (cs) => cs?.data?.croppingId === data.croppingId && data.shapeArea
      )
    )
      newMapCroppingsShapes.push(cropping);
    setMapCroppingsShapes(newMapCroppingsShapes);
  };

  const onSelectingField = (e, fieldId) => {
    if (
      clickedShapeDetails?.id === fieldId &&
      clickedShapeDetails?.shapeType === "field"
    ) {
      setShowWindowBox(false);
      setClickedShapeDetails();
    } else {
      setClickedShapeDetails({ id: fieldId, shapeType: "field" });
      setWindowBoxPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setWindowBoxContent();
      setClusterWindowBoxData(null);
      setTimeout(() => {
        setShowWindowBox(true);
      }, 1);
      onGetFieldDetails(fieldId);
    }
  };

  const onSelectingCropping = (e, croppingId) => {
    if (
      clickedShapeDetails?.id === croppingId &&
      clickedShapeDetails?.shapeType === "cropping"
    ) {
      setShowWindowBox(false);
      setClickedShapeDetails();
    } else {
      setClickedShapeDetails({ id: croppingId, shapeType: "cropping" });
      setWindowBoxPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setWindowBoxContent();
      setClusterWindowBoxData(null);
      setTimeout(() => {
        setShowWindowBox(true);
      }, 1);
      onGetCroppingDetails(croppingId);
    }
  };

  const handleClusterClick = (clustersData) => {
    if (clustersData.markerClusterer.clusters.length === 1) {
      setWindowBoxPosition({
        lat: clustersData.center.lat(),
        lng: clustersData.center.lng(),
      });
      setTimeout(() => {
        setShowWindowBox(true);
      }, 1);
      setWindowBoxContent(null);
      setClusterWindowBoxData(clustersData.markerClusterer.clusters[0].markers);
    }
  };

  useEffect(() => {
    treatmentsShapes.forEach((treatment) => {
      if (!shapeMap) {
        setShapeMap(treatment.getMap());
      }
      if (!showTreatments) {
        treatmentsShapes.forEach((treatment) => {
          treatment.setMap(null);
        });
        setTreatmentsShapes([]);
        setTreatmentPolygons([]);
      }
    });
  }, [showTreatments]);

  useEffect(() => {
    if (treatments.length > 0) {
      createTreatmentsPolygons();
    } else {
      setTreatmentPolygons([]);
    }
  }, [treatments]);

  const createTreatmentsPolygons = () => {
    const treatmentsPolygonsOnMap = [];
    treatments.forEach((treatment) => {
      treatment.shape.children.forEach((child) => {
        const treatmentCoordinates = createPolygonStructureWithHoles(
          child.shape.coordinates
        );
        const treatmentPolygonOnMap = {
          treatmentHeaderId: treatment.treatmentHeaderId,
          treatmentCoordinates,
        };
        treatmentsPolygonsOnMap.push(treatmentPolygonOnMap);
      });
    });
    setTreatmentPolygons(treatmentsPolygonsOnMap);
  };

  const onTreatmentPolygonLoad = (polygon) => {
    const newTreatmentsShapes = treatmentsShapes;
    if (
      !treatmentsShapes.find(
        (ts) => ts?.data?.treatmentHeaderId === polygon.data?.treatmentHeaderId
      )
    )
      newTreatmentsShapes.push(polygon);
    setTreatmentsShapes(newTreatmentsShapes);
  };

  const onSelectingTreatment = (e, treatmentId) => {
    if (
      clickedShapeDetails?.id === treatmentId &&
      clickedShapeDetails?.shapeType === "treatment"
    ) {
      setShowWindowBox(false);
      setClickedShapeDetails();
    } else {
      setClickedShapeDetails({ id: treatmentId, shapeType: "treatment" });
      setWindowBoxPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setClusterWindowBoxData(null);
      setWindowBoxContent();
      setTimeout(() => {
        setShowWindowBox(true);
      }, 1);
      onGetTreatmentDetails(treatmentId);
    }
  };

  const timeout = () => {
    setTimeout(() => {
      setShowWindowBox(false);
      setClickedShapeDetails();
    }, 1);
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      mapInstance.setOptions({ mapTypeControl: false });
      setGoogleMap(mapInstance);
    }
    return (
      <GoogleMap
        mapContainerClassName="map map-mobile"
        mapContainerStyle={googleMapStyle}
        mapTypeId={"hybrid"}
        center={center}
        onLoad={onLoad}
        onClick={timeout}
      >
        <StandaloneSearchBox
          onLoad={(searcher) => setSearchbox(searcher)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.SEARCH",
            })}...`}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        <MarkerClusterer
          options={markerClustererOptions}
          onClick={(e) => {
            handleClusterClick(e);
          }}
        >
          {function(clusterer) {
            const clusteredFieldMarkers = fieldMarkers.map((marker) => ({
              ...marker,
              clusterType: "field",
            }));
            const clusteredCropMarkers = cropMarkers.map((marker) => ({
              ...marker,
              clusterType: "cropping",
            }));
            const markers = [...clusteredFieldMarkers, ...clusteredCropMarkers];
            setTimeout(() => {
              clusterer.repaint();
            }, 1);
            return markers.map((marker, index) => {
              if (marker.clusterType === "field")
                return (
                  <Marker
                    key={`field-marker-${marker.fieldId}-${marker.shortName}-${index}`}
                    position={marker.position}
                    onClick={(e) => onSelectingField(e, marker.fieldId)}
                    onLoad={(e) => handleFieldLoad(e, marker)}
                    clusterer={clusterer}
                  />
                );
              else if (marker.clusterType === "cropping")
                return (
                  <Marker
                    key={`crop-marker-${marker.croppingId}-${marker.variety.varietyId}-${index}`}
                    position={marker.position}
                    onClick={(e) => onSelectingCropping(e, marker.croppingId)}
                    onLoad={(e) => handleCroppingLoad(e, marker)}
                    clusterer={clusterer}
                  />
                );
              else if (marker.clusterType === "treatment-polygons")
                return (
                  <Marker
                    key={`$polygon-as-marker-${marker.treatmentHeaderId}-${index}`}
                    position={marker.treatmentCoordinates[0][0]}
                    onLoad={(e) => {
                      e.data = marker;
                      onTreatmentPolygonLoad(e);
                    }}
                    onClick={(e) =>
                      onSelectingTreatment(e, marker.treatmentHeaderId)
                    }
                    clusterer={clusterer}
                  />
                );
            });
          }}
        </MarkerClusterer>
        {fieldPolygons.map((polygon, index) => (
          <Polygon
            key={`${polygon.fieldId}-${index}`}
            paths={polygon.coordinates}
            options={fieldPolygonOptions}
            onClick={(e) => onSelectingField(e, polygon.fieldId)}
            onLoad={(e) => handleFieldLoad(e, polygon)}
          />
        ))}
        {cropPolygons.map((polygon, index) => (
          <Polygon
            key={`${polygon.croppingId}-${index}`}
            paths={polygon.coordinates}
            options={croppingPolygonOptions}
            onClick={(e) => onSelectingCropping(e, polygon.croppingId)}
            onLoad={(e) => handleCroppingLoad(e, polygon)}
          />
        ))}
        {treatmentPolygons.map((treatment, index) => (
          <Polygon
            key={`${treatment.treatmentHeaderId}-${index}`}
            paths={treatment.treatmentCoordinates}
            options={treatmentPolygonOptions}
            onLoad={onTreatmentPolygonLoad}
            onClick={(e) =>
              onSelectingTreatment(e, treatment.treatmentHeaderId)
            }
          />
        ))}
        {showWindowBox && clusterWindowBoxData && (
          <InfoWindow position={windowBoxPosition}>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "0.32rem",
                boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
              }}
            >
              <h6 className="windowbox__heading mb-5">
                <FormattedMessage id="GENERAL.CHOOSE_A_PIN" />
              </h6>
              <div className="d-flex flex-column">
                {clusterWindowBoxData.map((marker) => {
                  if (
                    marker.data.clusterType === "field" ||
                    marker.data.clusterType === "field-as-marker"
                  )
                    return (
                      <button
                        key={`${marker.data.clusterType}-${marker.data.shortName}`}
                        className="mb-3 border-0 p-2 text-dark"
                        onClick={(e) =>
                          onSelectingField(
                            {
                              latLng: {
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                              },
                            },
                            marker.data.fieldId
                          )
                        }
                      >
                        <FormattedMessage id="GENERAL.FIELD" />
                        {" - "}
                        {marker.data.shortName}
                      </button>
                    );
                  else if (
                    marker.data.clusterType === "cropping" ||
                    marker.data.clusterType === "cropping-as-marker"
                  )
                    return (
                      <button
                        key={`${marker.data.clusterType}-${marker.data.shortName}`}
                        className="mb-3 border-0 p-2 text-dark"
                        onClick={(e) =>
                          onSelectingCropping(
                            {
                              latLng: {
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                              },
                            },
                            marker.data.croppingId
                          )
                        }
                      >
                        <FormattedMessage id="GENERAL.CROPPING" />
                        {" - "}
                        {marker.data.shortName}
                      </button>
                    );
                  else if (marker.data.clusterType === "treatment-polygons")
                    return (
                      <button
                        key={`${marker.data.clusterType}-${marker.data.treatmentHeaderId}`}
                        className="mb-3 border-0 p-2 text-dark"
                        onClick={(e) =>
                          onSelectingTreatment(
                            {
                              latLng: {
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                              },
                            },
                            marker.data.treatmentHeaderId
                          )
                        }
                      >
                        <FormattedMessage id="GENERAL.TREATMENT" />
                        {" - "}
                        {marker.data.treatmentHeaderId}
                      </button>
                    );
                })}
              </div>
            </div>
          </InfoWindow>
        )}
        {showWindowBox &&
          (windowBoxContent?.fieldId ||
            windowBoxContent?.cropping?.croppingId ||
            windowBoxContent?.treatmentHeaderId) && (
            <InfoWindow position={windowBoxPosition}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "1rem",
                  borderRadius: "0.32rem",
                  boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
                }}
              >
                <div style={{ fontSize: "1rem", fontColor: `#464E5F` }}>
                  {windowBoxContent?.fieldId && (
                    <>
                      <h6 className="windowbox__heading windowbox__heading--field mb-5">
                        <FormattedMessage id="GENERAL.FIELD" /> -{" "}
                        {windowBoxContent.shortName}
                      </h6>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.IRRIGATION" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">{`${
                          windowBoxContent.isIrrigated
                            ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                            : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                        }`}</span>
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.MELIORATION" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">{`${
                          windowBoxContent.isMeliorated
                            ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                            : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                        }`}</span>
                      </p>
                      <p className="windowbox__paragraph">
                        {windowBoxContent.shapeArea ? (
                          <>
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
                              :
                            </span>{" "}
                            <span className="text--bold">
                              {windowBoxContent.shapeArea.toFixed(2)} ha
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.DECLARED_ACREAGE" />
                              :
                            </span>{" "}
                            <span className="text--bold">
                              {windowBoxContent.acreage.toFixed(2)} ha
                            </span>
                          </>
                        )}
                      </p>
                      {windowBoxContent.species && (
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.SPECIES" />:
                          </span>{" "}
                          <span className="text--bold">
                            {windowBoxContent.species.desc}
                          </span>
                        </p>
                      )}
                      {windowBoxContent.variety && (
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.VARIETY" />:
                          </span>{" "}
                          <span className="text--bold">
                            {windowBoxContent.variety.desc}
                          </span>
                        </p>
                      )}
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.NUMBER_OF_CROPS" />:
                        </span>{" "}
                        <span className="text--bold">
                          {windowBoxContent.assignedCroppings.length}
                        </span>
                      </p>
                      {windowBoxContent.supplier?.fullName && (
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.SUPPLIER" />:
                          </span>{" "}
                          <span className="text--bold">
                            {windowBoxContent.supplier.fullName}
                          </span>
                        </p>
                      )}
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.DESCRIPTION" />:
                        </span>{" "}
                        <span className="text--bold">
                          {windowBoxContent.desc}
                        </span>
                      </p>
                      <Link
                        to={`/farm/fields?id=${windowBoxContent.fieldId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="button__link--primary"
                      >
                        <Button
                          type="button"
                          variant="secondary"
                          style={{ width: "100%" }}
                        >
                          <FormattedMessage id="GENERAL.GO_TO_FIELD" />
                        </Button>
                      </Link>
                    </>
                  )}
                  {windowBoxContent?.cropping?.croppingId && (
                    <>
                      <h6 className="windowbox__heading windowbox__heading--cropping mb-5">
                        <FormattedMessage id="GENERAL.CROPPING" /> -{" "}
                        {windowBoxContent.cropping.shortName}
                      </h6>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.IRRIGATION_CROPPING" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">{`${
                          windowBoxContent.cropping.isIrrigated
                            ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                            : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                        }`}</span>
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.CONTRACTUAL_CROPPING" />
                          :
                        </span>{" "}
                        <span className="text--bold text--uppercase">{`${
                          windowBoxContent.cropping.isContractual
                            ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                            : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                        }`}</span>
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.ECOLOGICAL_CROPPING" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">{`${
                          windowBoxContent.cropping.isEco
                            ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                            : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                        }`}</span>
                      </p>
                      <p className="windowbox__paragraph">
                        {windowBoxContent.cropping.shapeArea ? (
                          <>
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
                              :
                            </span>{" "}
                            <span className="text--bold">
                              {windowBoxContent.cropping.shapeArea.toFixed(2)}{" "}
                              ha
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.DECLARED_ACREAGE" />
                              :
                            </span>{" "}
                            <span className="text--bold">
                              {windowBoxContent.cropping.areaDeclared.toFixed(
                                2
                              )}{" "}
                              ha
                            </span>
                          </>
                        )}
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.CULTIVATED_SPECIES" />:
                        </span>{" "}
                        <span className="text--bold">
                          {windowBoxContent.cropping.variety.speciesDesc}
                        </span>
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.CULTIVATED_VARIETY" />:
                        </span>{" "}
                        <span className="text--bold">
                          {windowBoxContent.cropping.variety.shortName}
                        </span>
                      </p>
                      {windowBoxContent.cropping.supplier?.fullName && (
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.SUPPLIER" />:
                          </span>{" "}
                          <span className="text--bold">
                            {windowBoxContent.cropping.supplier.fullName}
                          </span>
                        </p>
                      )}
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.DESCRIPTION" />:
                        </span>{" "}
                        <span className="text--bold">
                          {windowBoxContent.cropping.desc}
                        </span>
                      </p>
                      <div className="text-right">
                        <Link
                          to={`/farm/croppings?id=${windowBoxContent.cropping.croppingId}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="button__link--primary"
                        >
                          <Button
                            type="button"
                            variant="secondary"
                            style={{ width: "100%" }}
                          >
                            <FormattedMessage id="GENERAL.GO_TO_CROPPING" />
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                  {windowBoxContent?.treatmentHeaderId && (
                    <>
                      <h6 className="windowbox__heading windowbox__heading--treatment mb-5">
                        <FormattedMessage id="GENERAL.PLANNED_TREATMENTS_ON_CULTIVATION" />
                      </h6>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.START_DATE" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">
                          {moment(windowBoxContent.dateStart).format(
                            "DD.MM.YYYY"
                          )}
                        </span>
                      </p>
                      <p className="windowbox__paragraph">
                        <span className="text-muted">
                          <FormattedMessage id="GENERAL.END_DATE" />:
                        </span>{" "}
                        <span className="text--bold text--uppercase">
                          {moment(windowBoxContent.dateEnd).format(
                            "DD.MM.YYYY"
                          )}
                        </span>
                      </p>
                      <p className="windowbox__paragraph">
                        <Row style={{ width: "-webkit-fill-available" }}>
                          <Col xs={6}>
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.TREATMENTS" />:
                            </span>
                          </Col>
                          <Col
                            xs={6}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            {windowBoxContent.treatments.map((treatment) => (
                              <div
                                key={treatment.treatmentHeaderId}
                                style={{ marginBottom: "0.4rem" }}
                              >
                                <FormattedMessage
                                  id={`TREATMENT.${treatment.dtreatmentType}`}
                                />
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </p>
                      {(windowBoxContent.isCanceled ||
                        windowBoxContent.isFinished) && (
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.STATUS" />:
                          </span>
                          <span
                            className={`text--bold text--uppercase ${
                              windowBoxContent.isCanceled
                                ? "text--red"
                                : "text--green"
                            }`}
                          >
                            {windowBoxContent.isCanceled && (
                              <FormattedMessage id="GENERAL.TREATMENT_CANCELED" />
                            )}
                            {windowBoxContent.isFinished && (
                              <FormattedMessage id="GENERAL.TREATMENT_FINISHED" />
                            )}
                          </span>
                        </p>
                      )}
                      <div className="text-right">
                        <Link
                          to={`/calendar?treatmentId=${windowBoxContent.treatmentHeaderId}`}
                          className="button__link--primary"
                        >
                          <Button
                            type="button"
                            variant="secondary"
                            style={{ width: "100%" }}
                          >
                            <FormattedMessage id="GENERAL.GO_TO_TREATMENT" />
                          </Button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </InfoWindow>
          )}
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}
