import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getTariffiesFormData,
  getAllTariffies,
  createTariff,
  updateTariff,
  createTariffLevelToOrgentity,
  getTariffLevelGroups,
  deleteTariffLevelGroup,
  updateTariffsToGroup,
  updateLevelsToGroup,
} from "../../../services/brigadier/tariffiesCrud";
import {
  TariffsTableCard,
  TarrifGeneralDataCard,
  TariffBonusCard,
} from "../index";
import { Row, Col } from "react-bootstrap";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function TariffsPage() {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState();
  const [tariffs, setTariffs] = useState([]);
  const [tariffLevelGroups, setTariffLevelGroups] = useState();

  function getFormData() {
    getTariffiesFormData()
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  function getTariffs() {
    getAllTariffies()
      .then((response) => {
        const newTariffs = response.data.sort(sortByName);
        if (
          response.data.length &&
          newTariffs.filter((tariff) => tariff.isActive).length > 0
        ) {
          newTariffs.filter((tariff) => tariff.isActive)[0].isSelected = true;
          setTariffs(newTariffs);
          setMode("update");
        } else if (response.data.length) {
          setTariffs(newTariffs);
          setMode("create");
        } else {
          setMode("create");
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  function getTariffLevelGroupsToState() {
    getTariffLevelGroups()
      .then((response) => {
        setTariffLevelGroups(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  const onChangingMode = () => {
    if (mode === "create") {
      setMode("update");
    } else {
      const newTarrifs = [...tariffs];
      newTarrifs.forEach((tariff) => {
        tariff.isSelected = false;
      });
      setTariffs(newTarrifs);
      setMode("create");
    }
  };

  const onSelectingRow = (id) => {
    const newTarrifs = [...tariffs];
    newTarrifs.forEach((tariff) => {
      if (tariff.tariffId === id) {
        tariff.isSelected = true;
        setMode("update");
      } else {
        tariff.isSelected = false;
      }
    });
    setTariffs(newTarrifs);
  };

  function onCreatingTariff(form) {
    return new Promise((resolve) => {
      createTariff(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          response.data.isSelected = true;
          const newTariffs = [...tariffs];
          newTariffs.push(response.data);
          setTariffs(newTariffs);
          setMode("update");
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onUpdatingTariff(tariffId, form) {
    return new Promise((resolve) => {
      updateTariff(tariffId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          updateObject(tariffId, response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onCreatingTariffLevelGroup(selectedTariffs) {
    return new Promise((resolve) => {
      const form = {
        currencyId: selectedTariffs[0].currencyId,
        tariffTypeId: selectedTariffs[0].tariffTypeId,
        uomId: selectedTariffs[0].uomId,
        tariffIds: selectedTariffs.map((tariff) => tariff.tariffId),
      };
      createTariffLevelToOrgentity(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getTariffLevelGroupsToState();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onDeletingTariffLevelGroup(groupId) {
    return new Promise((resolve) => {
      deleteTariffLevelGroup(groupId)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
          getTariffLevelGroupsToState();
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onUpdatingTariffLevelGroup(
    groupId,
    selectedTariffs,
    chargeBonus,
    levelForm
  ) {
    return new Promise((resolve) => {
      const form = {
        tariffIds: selectedTariffs.map((tariff) => tariff.tariffId),
        chargeBonus,
      };
      updateTariffsToGroup(groupId, form)
        .then(() => {
          updateLevelsToGroup(groupId, levelForm)
            .then((response) => {
              displayFeedbackFromHeaders(response.headers);
              resolve("OK");
              getTariffLevelGroupsToState();
            })
            .catch((error) => {
              const errorOptions = errorHandler(error);
              if (errorOptions.type === "error") {
                displayFeedback({
                  type: errorOptions.type,
                  message: `${intl.formatMessage({
                    id: errorOptions.message,
                  })}`,
                });
              }
              resolve("error");
            });
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  const sortByName = (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  const updateObject = (tariffId, updatedTariff) => {
    const newTariffs = [...tariffs];
    const searchedTariff = newTariffs.find((tariff) => {
      return tariff.tariffId === tariffId;
    });
    searchedTariff.name = updatedTariff.name;
    searchedTariff.isActive = updatedTariff.isActive;
    searchedTariff.value = updatedTariff.value;
    searchedTariff.currencyId = updatedTariff.currencyId;
    searchedTariff.tariffTypeId = updatedTariff.tariffTypeId;
    searchedTariff.uomId = updatedTariff.uomId;
    searchedTariff.converters = updatedTariff.converters;
    setTariffs(newTariffs);
  };

  useEffectOnce(getFormData);
  useEffectOnce(getTariffs);
  useEffectOnce(getTariffLevelGroupsToState);

  return (
    <Row>
      <Col md={12} lg={4} xxl={4}>
        <TariffsTableCard
          mode={mode}
          tariffs={tariffs}
          onSelectingRow={onSelectingRow}
          onChangingMode={onChangingMode}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <TarrifGeneralDataCard
          mode={mode}
          formData={formData}
          activeTariff={[...tariffs].find((tariff) => {
            return tariff.isSelected;
          })}
          onCreatingTariff={onCreatingTariff}
          onUpdatingTariff={onUpdatingTariff}
          tariffLevelGroups={tariffLevelGroups}
        />
        {mode === "update" && (
          <TariffBonusCard
            formData={formData}
            tariffs={tariffs}
            tariffLevelGroups={tariffLevelGroups}
            onCreatingTariffLevelGroup={onCreatingTariffLevelGroup}
            onDeletingTariffLevelGroup={onDeletingTariffLevelGroup}
            onUpdatingTariffLevelGroup={onUpdatingTariffLevelGroup}
          />
        )}
      </Col>
    </Row>
  );
}

export default TariffsPage;
