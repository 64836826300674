import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { Form, Col, InputGroup, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

export function WorkerOperationForm({
  formData,
  newValue,
  createReceivableTransaction,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [currentDate, setCurrentDate] = useState(new Date());
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    newValue && setValue("value", newValue);
    triggerValidation();
  }, [newValue]);

  async function onSubmit(data) {
    setLoading(true);
    const form = data;
    form.details = [
      {
        currencyId: form.currencyId,
        value: form.value,
      },
    ];
    form.currencyId = null;
    form.value = null;
    form.registerAt = moment(moment(currentDate)).format("YYYY-MM-DD");
    const callback = createReceivableTransaction(form);
    if (callback) {
      reset();
      setCurrentDate(new Date());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const checkIfButtonShouldBeDisabled = () => {
    return Object.keys(errors).length ? true : false;
  };

  return (
    <Form className="mt-1" onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.OPERATION_REGISTER_DATE" /> *
          </Form.Label>
          <DatePicker
            name="currentDate"
            selected={currentDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => setCurrentDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.OPERATION_TYPE" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="payoutTypeId"
            ref={register({ required: true })}
          >
            {formData && (
              <>
                {formData.payoutTypes.map((payout) => (
                  <option key={`payout-${payout.id}`} value={payout.id}>
                    {payout.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.AMOUNT" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="value"
              type="number"
              className={`${
                errors.value || getValues("value") === (null || undefined || 0)
                  ? "is-invalid"
                  : ""
              } ${!errors.value ? "is-valid" : ""}`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.AMOUNT",
              })}`}
              step="0.01"
              ref={register({ required: true, min: 0.01 })}
            />
          </InputGroup>
          {errors.value?.type === "required" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
          {errors.value?.type === "min" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_01" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CURRENCY" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="currencyId"
            ref={register({ required: true })}
          >
            {formData && (
              <>
                {formData.currencies.map((currency) => (
                  <option key={`currency-${currency.id}`} value={currency.id}>
                    {currency.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.OPERATION_COMMENT" />
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="desc"
            ref={register({ required: false })}
          />
        </Form.Group>
        <Col md={12} className="text-right">
          <Button
            type="submit"
            disabled={loading || checkIfButtonShouldBeDisabled()}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
