import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  View,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import robotoLightFont from "../../../../fonts/Roboto-Light.ttf";
import robotoMediumFont from "../../../../fonts/Roboto-Medium.ttf";
import { Row, Col, Button } from "react-bootstrap";
import QRCode from "qrcode";

Font.register({ family: "Roboto Light", src: robotoLightFont });
Font.register({ family: "Roboto Medium", src: robotoMediumFont });

const styles = StyleSheet.create({
  body: {
    paddingHorizontal: 35,
    fontFamily: "Roboto Light",
  },
  wrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  label: {
    width: 230,
    height: 120,
    border: "1px solid #000",
  },
  label__box: {
    marginTop: 15,
    marginBottom: 15,
    alignItems: "center",
  },
  label__packaging_orgentity: {
    fontSize: 12,
    marginBottom: 10,
  },
  label__packaging: {
    fontSize: 16,
    fontFamily: "Roboto Medium",
  },
  label__qrItem: {
    fontSize: 16,
    marginRight: "auto"
  },
  barcode: {
    width: 200,
    height: 80,
    marginTop: 15,
  },
  qrCode: {
    width: 150,
    height: 150,
    marginTop: 10,
    objectFit: "contain",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    width: "90%",
  },
  badge: {
    display: "block",
    fontSize: 16,
    marginLeft: "auto"
  },
});

const BarcodesDocument = ({ barcodes, activeWorker, isQrCodePDF }) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.wrapper}>
        {barcodes.map((bar, index) => (
          <View key={`barcode-${index}`} style={styles.label}>
            <View style={styles.label__box}>
              <Text style={styles.label__packaging_orgentity}>
                {activeWorker.orgentity.bpartner !== null &&
                activeWorker.orgentity?.bpartner?.bpartnerFullName?.length
                  ? activeWorker.orgentity.bpartner.bpartnerFullName
                  : activeWorker.orgentity.orgentityDesc}
              </Text>
              <Text style={styles.label__packaging}>
                {`${activeWorker.firstName} ${activeWorker.lastName}`}
              </Text>
              <Image
                style={isQrCodePDF ? styles.qrCode : styles.barcode}
                src={bar.barcode}
              />
              <View style={styles.bottom}>
                {isQrCodePDF && (
                  <Text style={styles.label__qrItem}>{bar.item}</Text>
                )}
                <Text style={styles.badge}>{activeWorker.badge}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export function WorkerBarcodePDF({ activeWorker, isQrCodePDF }) {
  const [barcodes, setBarcodes] = useState([]);

  useEffect(() => {
    const newBarcodes = [];
    let canvas = document.createElement("canvas");
    if (isQrCodePDF) {
      QRCode.toCanvas(canvas, activeWorker.item, function(error) {
        if (error) console.error(error);
      });
    } else JsBarcode(canvas, activeWorker.item);
    const barcode = canvas.toDataURL();
    newBarcodes.push({ barcode, item: activeWorker.item });
    setBarcodes(newBarcodes);
  }, [activeWorker.item, isQrCodePDF]);

  return (
    <>
      {isMobile ? (
        <Col
          md={12}
          lg={12}
          xxl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center mt-5">
            <PDFDownloadLink
              document={
                <BarcodesDocument
                  barcodes={barcodes}
                  activeWorker={activeWorker}
                  isQrCodePDF={isQrCodePDF}
                />
              }
              fileName={`barcodes-${moment().format("DD.MM.YYYY_HH:mm")}.pdf`}
            >
              <Button>
                <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF" />
              </Button>
            </PDFDownloadLink>
          </div>
        </Col>
      ) : (
        <>
          <Row>
            <Col md={12} lg={12} xxl={12} style={{ minHeight: "50vh" }}>
              <PDFViewer style={{ width: "100%", height: "100%" }}>
                <BarcodesDocument
                  barcodes={barcodes}
                  activeWorker={activeWorker}
                  isQrCodePDF={isQrCodePDF}
                />
              </PDFViewer>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
