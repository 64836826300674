import { toast } from "react-toastify";

export default function displayFeedback(data) {
  switch (data?.type) {
    case "success": {
      toast.success(data.message);
      break;
    }
    case "error": {
      toast.error(data.message);
      break;
    }
    case "info": {
      toast.info(data.message);
      break;
    }
    case "warning": {
      toast.warning(data.message);
      break;
    }
    case "black": {
      toast(data.message, {
        theme: "dark",
      });
      break;
    }
    default:
      toast(data.message, {
        theme: "light",
      });
  }
}
