import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useSelector } from "react-redux";
import getModuleName from "../utils/getModuleName";

export function ModuleName() {
  const location = useLocation();

  const isMobile = useSelector((state) => state.misc.isMobile);

  const moduleName = useMemo(() => {
    return getModuleName(location);
  }, [location]);

  const showPageName = useMemo(() => {
    if (
      isMobile &&
      (moduleName.type === "BRIGADIER" ||
        moduleName.type === "CROP-MANAGER" ||
        moduleName.type === "PULP-EXCHANGE")
    )
      return true;
    if (moduleName.type === "basic") return true;
    return false;
  }, [isMobile, moduleName.type]);

  if (moduleName.name)
    return (
      <h5 className="text-dark font-weight-bold my-3 mr-5 d-flex align-items-center">
        {moduleName.type === "BRIGADIER" && (
          <>
            <SVG
              src={toAbsoluteUrl("/media/logos/brigadier__logo.svg")}
              style={{ height: "25px", width: "auto" }}
              className="mr-3"
            />
          </>
        )}
        {moduleName.type === "CROP-MANAGER" && (
          <>
            <SVG
              src={toAbsoluteUrl("/media/logos/cropmanager__logo.svg")}
              style={{ height: "35px", width: "auto" }}
              className="mr-3"
            />
          </>
        )}
        {moduleName.type === "PULP-EXCHANGE" && (
          <FormattedMessage id={`MENU.PULP-EXCHANGE`} />
        )}
        {showPageName && (
          <>
            {isMobile && moduleName.type !== "basic" && " > "}
            <FormattedMessage
              id={`MENU.${moduleName.name.replace("-", "_")}`}
            />
          </>
        )}
      </h5>
    );
  else return null;
}
