import React, { useState, useEffect, useCallback } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import Select from "react-select";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import SuppliersSelect from "../../../components/SuppliersSelect";
import getSuppliersToRedux from "../../../components/helpers/getSuppliersToRedux";

export function CroppingsGeneralData(props) {
  const intl = useIntl();
  const {
    register,
    setValue,
    getValues,
    errors,
    formState,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const { touched, submitCount } = formState;
  const [irrigated, setIrrigated] = useState(false);
  const [contractual, setContractual] = useState(false);
  const [ecological, setEcological] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [varietiesList, setVarietiesList] = useState([]);
  const [varietyIndex, setVarietyIndex] = useState();
  const [selectedVarietyId, setSelectedVarietyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectedSupplier = useSelector(
    (state) => state.suppliers.selectedSupplier
  );
  const setSelectedSupplier = useCallback(
    (data) => {
      dispatch({
        type: "suppliers/setSelectedSupplier",
        payload: data,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let list = [];
    const isVarietyDisabled = (variety) => {
      if (props.mode === "update") {
        if (props.cropping?.assignedFields?.length > 0) {
          if (
            props.cropping.assignedFields[0].species &&
            props.cropping.assignedFields[0].variety
          )
            return !(
              variety.speciesId ===
                props.cropping.assignedFields[0].species.id &&
              variety.varietyId ===
                props.cropping.assignedFields[0].variety.varietyId
            );
          if (
            props.cropping.assignedFields[0].species &&
            !props.cropping.assignedFields[0].variety
          )
            return !(
              variety.speciesId === props.cropping.assignedFields[0].species.id
            );
        }
      }
      return false;
    };
    if (props.varieties.length) {
      props.varieties.forEach((variety, index) => {
        list.push({
          label: `${variety.speciesDesc} : ${variety.desc}`,
          value: variety.varietyId,
          isDisabled: isVarietyDisabled(variety),
        });
        if (props.cropping?.cropping.variety.varietyId === variety.varietyId) {
          setVarietyIndex(index);
          setSelectedVarietyId(variety.varietyId);
        }
      });
      setVarietiesList(list);
    }
  }, [
    props.varieties,
    props.cropping?.assignedFields,
    props.mode,
    props.cropping?.cropping.variety.varietyId,
  ]);

  useEffect(() => {
    if (props.cropping) {
      setIrrigated(props.cropping.cropping.isIrrigated);
      setContractual(props.cropping.cropping.isContractual);
      setEcological(props.cropping.cropping.isEco);
      setFromDate(
        props.cropping.cropping.dateFrom
          ? new Date(props.cropping.cropping.dateFrom)
          : new Date()
      );
      setToDate(
        props.cropping.cropping.dateFrom
          ? new Date(props.cropping.cropping.dateTo)
          : new Date()
      );
      setValue("shortName", props.cropping.cropping.shortName);
      setValue("isActive", props.cropping.cropping.isActive);
      setValue("areaDeclared", props.cropping.cropping.areaDeclared);
      setValue(
        "shapeArea",
        props.cropping.cropping.shapeArea
          ? props.cropping.cropping.shapeArea.toFixed(2)
          : props.cropping.cropping.shapeArea
      );
      setValue("quantityDeclared", props.cropping.cropping.quantityDeclared);
      setValue("quantityContr", props.cropping.cropping.quantityContr);
      setValue("desc", props.cropping.cropping.desc);
      if (varietiesList.length) {
        setSelectedVarietyId(props.cropping.cropping.variety.varietyId);
        const index = varietiesList.findIndex((variety) => {
          return variety.value === props.cropping.cropping.variety.varietyId;
        });
        setVarietyIndex(index);
      }
      if (props.cropping.cropping.supplier)
        setSelectedSupplier({
          value: props.cropping.cropping.supplier.fullName,
          label: props.cropping.cropping.supplier.fullName,
        });
      else setSelectedSupplier(null);
    }
  }, [props.cropping]);

  useEffect(() => {
    if (props.mode === "create") {
      reset();
      setIrrigated(false);
      setContractual(false);
      setEcological(false);
      setFromDate(new Date());
      setToDate(new Date());
      setSelectedSupplier(null);
    }
  }, [props.mode, reset]);

  useEffect(() => {
    if (props.mode === "create") {
      setVarietyIndex(0);
      varietiesList.length > 0
        ? setSelectedVarietyId(varietiesList[0]?.value)
        : setSelectedVarietyId();
    }
  }, [props.mode, varietiesList]);

  async function onSubmit(data) {
    const form = data;
    form.isIrrigated = irrigated;
    form.isContractual = contractual;
    form.isEco = ecological;
    form.varietyId = selectedVarietyId;
    form.dateFrom = moment(fromDate).format("YYYY-MM-DD");
    form.dateTo = moment(toDate).format("YYYY-MM-DD");
    if (selectedSupplier) form.supplier = selectedSupplier.label;
    setLoading(true);
    let callback;
    if (props.mode === "create") {
      callback = await props.createCropping(form);
    } else {
      callback = await props.updateCropping(form);
      setLoading(false);
    }
    if (callback) {
      setLoading(false);
    }
    getSuppliersToRedux();
  }

  const onChangeSelectedVariety = (option) => {
    const index = varietiesList.findIndex((variety) => {
      return variety.value === option.value;
    });
    setVarietyIndex(index);
    setSelectedVarietyId(option.value);
  };

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            {props.mode === "create" ? (
              <SVG src={toAbsoluteUrl("/media/icons/Add_Cropping.svg")} />
            ) : (
              <SVG src={toAbsoluteUrl("/media/icons/Cropping.svg")} />
            )}
          </span>
          <h3 className="card-label">
            {props.mode === "create" ? (
              <FormattedMessage id="GENERAL.ADD_CROPPING" />
            ) : (
              <>
                <FormattedMessage id="GENERAL.CROPPING" /> -{" "}
                {props.cropping?.cropping.shortName}
              </>
            )}
          </h3>
        </div>
        <div className="card-toolbar">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.IRRIGATION_CROPPING" />
                </Tooltip>
              }
            >
              <Button
                variant={`${irrigated ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setIrrigated(!irrigated);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-drop pr-0"
                  style={{ padding: "none" }}
                />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CONTRACTUAL_CROPPING" />
                </Tooltip>
              }
            >
              <Button
                variant={`${contractual ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setContractual(!contractual);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-list pr-0"
                  style={{ padding: "none" }}
                />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.ECOLOGICAL_CROPPING" />
                </Tooltip>
              }
            >
              <Button
                variant={`${ecological ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setEcological(!ecological);
                  e.currentTarget.blur();
                }}
                disabled={loading}
              >
                <i
                  className="flaticon-earth-globe pr-0"
                  style={{ padding: "none" }}
                />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="card-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.SHORT_NAME" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="shortName"
                  type="text"
                  className={`${
                    (touched?.shortName &&
                      getValues("shortName").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("shortName")?.length === 0) ||
                    errors.shortName ||
                    (touched?.shortName &&
                      getValues("shortName").length >= 51) ||
                    (submitCount >= 1 && getValues("shortName")?.length >= 51)
                      ? "is-invalid"
                      : ""
                  } ${
                    !errors.shortName && getValues("shortName")?.length >= 1
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.SHORT_NAME",
                  })}`}
                  ref={register({ required: true, maxLength: 50 })}
                />
              </InputGroup>
              {(errors.shortName?.required ||
                (touched?.shortName && getValues("shortName").length === 0) ||
                (submitCount >= 1 && getValues("shortName")?.length === 0)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {(errors.shortName?.maxLength ||
                (touched?.shortName && getValues("shortName").length >= 51) ||
                (submitCount >= 1 && getValues("shortName")?.length >= 51)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.INCORRECT_FIELD_OR_CROPPING_SHORT_NAME_LENGTH" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" /> *
              </Form.Label>
              <Form.Control
                as="select"
                name="isActive"
                ref={register({ required: true })}
              >
                {status.map((stat) => (
                  <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                    {(message) => <option value={stat.value}>{message}</option>}
                  </FormattedMessage>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.VALID_FROM" /> *
              </Form.Label>
              <DatePicker
                name="dateFrom"
                selected={fromDate}
                dateFormat="dd.MM.yyyy"
                locale={datepickerLocale}
                showMonthDropdown
                showYearDropdown
                onChange={(date) => onChangingDate("from", date)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.TO" /> *
              </Form.Label>
              <DatePicker
                name="dateTo"
                selected={toDate}
                dateFormat="dd.MM.yyyy"
                locale={datepickerLocale}
                showMonthDropdown
                showYearDropdown
                onChange={(date) => onChangingDate("to", date)}
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DECLARED_ACREAGE" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="areaDeclared"
                  type="number"
                  className={`${
                    (touched?.areaDeclared && !getValues("areaDeclared")) ||
                    (submitCount >= 1 && getValues("areaDeclared") < 0.1) ||
                    errors.areaDeclared
                      ? "is-invalid"
                      : ""
                  } ${
                    !errors.areaDeclared && getValues("areaDeclared") >= 0.1
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.CROPPING_ACREAGE",
                  })}`}
                  step="0.01"
                  ref={register({ required: true, min: 0.1, max: 9999.99 })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>ha</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {(errors.areaDeclared?.type === "required" ||
                (touched?.areaDeclared &&
                  !getValues("areaDeclared").length)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.areaDeclared?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                </div>
              )}
              {errors.areaDeclared?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_10000" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="shapeArea"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.CALCULATED_FIELD_ACREAGE",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: false })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>ha</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.PLANNED_HARVEST" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="quantityDeclared"
                  type="number"
                  className={`${
                    (errors.quantityDeclared &&
                      getValues("quantityDeclared")) ||
                    (touched?.quantityDeclared &&
                      getValues("quantityDeclared") &&
                      getValues("quantityDeclared") < 0.1)
                      ? "is-invalid"
                      : ""
                  } ${getValues("quantityDeclared") >= 0.1 ? "is-valid" : ""}`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.PLANNED_HARVEST",
                  })}`}
                  step="0.01"
                  ref={register({
                    required: false,
                    min: 0.1,
                    max: 99999999.99,
                  })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {errors.quantityDeclared?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                </div>
              )}
              {errors.quantityDeclared?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_100000000" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.CONTRACTED_QUANTITY" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="quantityContr"
                  type="number"
                  className={`${
                    (errors.quantityContr && getValues("quantityContr")) ||
                    (touched?.quantityContr &&
                      getValues("quantityContr") &&
                      getValues("quantityContr") < 0)
                      ? "is-invalid"
                      : ""
                  } ${getValues("quantityContr") >= 0.1 ? "is-valid" : ""}`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.CONTRACTED_QUANTITY",
                  })}`}
                  step="0.01"
                  ref={register({
                    required: false,
                    min: 0.1,
                    max: 99999999.99,
                  })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>t</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {errors.quantityContr?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                </div>
              )}
              {errors.quantityContr?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_100000000" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.VARIETY" /> *
              </Form.Label>
              <Select
                name="varietyId"
                className="input__select--search"
                defaultValue={
                  varietiesList.length > 0 ? varietiesList[0] : null
                }
                value={
                  varietyIndex != null ? varietiesList[varietyIndex] : null
                }
                options={varietiesList}
                required={true}
                onChange={onChangeSelectedVariety}
                isDisabled={!!(varietiesList.length === 0)}
                isLoading={!!(varietiesList.length === 0)}
                placeholder={
                  !!(varietiesList.length === 0)
                    ? `${intl.formatMessage({ id: "GENERAL.LOADING" })}...`
                    : `${intl.formatMessage({ id: "GENERAL.SELECT" })}...`
                }
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DESCRIPTION" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="desc"
                  as="textarea"
                  rows="1"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.DESCRIPTION",
                  })}`}
                  ref={register({ required: false })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.SUPPLIER" />
              </Form.Label>
              <SuppliersSelect />
            </Form.Group>
            <Col md={12} className="text-right">
              <Button type="submit" disabled={loading || !selectedVarietyId}>
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}
