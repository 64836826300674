import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DataProcessingModal(props) {
  function handleClose() {
    props.close();
  }

  function handleClick() {
    props.accept();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="PRICING.CONSENTS" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
            <span className="font-weight-bold text-dark-50 pr-1 modal__center">
              {"1. "}
              <FormattedMessage id="PRICING.CONSENTS.1.HEADER" />
            </span>
            <br />
            <span>
              <FormattedMessage id="PRICING.CONSENTS.1.TEXT" />
            </span>
            <br />
            <br />
            <span className="font-weight-bold text-dark-50 pr-1 modal__center">
              {"2. "}
              <FormattedMessage id="PRICING.CONSENTS.2.HEADER" />
            </span>
            <br />
            <span>
              <FormattedMessage id="PRICING.CONSENTS.2.TEXT" />
            </span>
            <br />
            <br />
            <span className="font-weight-bold text-dark-50 pr-1 modal__center">
              {"3. "}
              <FormattedMessage id="PRICING.CONSENTS.3.HEADER" />
            </span>
            <br />
            <span>
              <FormattedMessage id="PRICING.CONSENTS.3.TEXT" />
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>
          {props.acceptVisible && (
            <Button onClick={handleClick} color="primary">
              <FormattedMessage id="GENERAL.ACCEPT" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
