/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import { ResultsTable } from "../ResultsTable";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function LatestResults({ className, data }) {
  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className ? className : ""}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <Link
                to="/brigadier/results"
                className="font-weight-bolder text-dark text-hover-primary"
              >
                <FormattedMessage id="BRIGADIER.DASHBOARD.LATEST_RESULTS" />
                <span className="svg-icon svg-icon-dark-75 ml-3 d-inline-block">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Right-2.svg"
                    )}
                    style={{ width: "auto", height: "auto", position: "relative", bottom: "1px" }}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-8 pt-4">
          {/* begin::Chart */}
          {data?.length > 0 && (
            <ResultsTable data={data} onSelectingRow={() => {}} />
          )}
          {(!data || data?.length === 0) && (
            <div className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
              </h6>
            </div>
          )}
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
