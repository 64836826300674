import React from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function DeleteAccountInfo() {
  return (
    <div className="auth__form">
      <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
        <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
      </div>
      <div className="auth__heading">
        <h3>
          <FormattedMessage id="AUTH.DELETE_ACCOUNT" />
        </h3>
      </div>
      <div className="text-center mb-10 mb-lg-10" style={{ padding: "0 40px" }}>
        <FormattedMessage id="AUTH.DELETING_ACCOUNT_SUCCESSFULLY_INIT" />
      </div>
    </div>
  );
}

export default DeleteAccountInfo;
