import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { enGB, de, pl, ru } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { Form, Col } from "react-bootstrap";

export function RangeFilter({ title, dateChange }) {
  const [datepickerLocale, setDatepickerLocale] = useState(pl);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const setLocaleLanguage = () => {
    switch (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang) {
      case "en":
        registerLocale("enGB", enGB);
        setDatepickerLocale(enGB);
        break;
      case "pl":
        registerLocale("pl", pl);
        setDatepickerLocale(pl);
        break;
      case "de":
        registerLocale("de", de);
        setDatepickerLocale(de);
        break;
      case "ru":
        registerLocale("ru", ru);
        setDatepickerLocale(ru);
        break;
      default:
        break;
    }
  };

  useEffect(setLocaleLanguage, []);

  const onRangeChange = () => {
    dateChange(fromDate, toDate);
  };

  useEffect(onRangeChange, [fromDate, toDate]);

  const onChangingFromDate = (date) => {
    setFromDate(date);
    moment(date).isAfter(toDate) && setToDate(date);
  };

  const onChangingToDate = (date) => {
    setToDate(date);
    moment(date).isBefore(fromDate) && setFromDate(date);
  };

  return (
    <>
      {title && (
        <Form.Group as={Col} md={12} className="mt-4 mb-2">
          <span className="text--bold">
            <FormattedMessage id={title} />
          </span>
        </Form.Group>
      )}
      <Form.Group as={Col} md={5} lg={12} xl={5} className="mb-4">
        <Form.Label>
          <FormattedMessage id="GENERAL.FROM" /> *
        </Form.Label>
        <DatePicker
          name="dateFrom"
          selected={fromDate}
          dateFormat="dd.MM.yyyy"
          locale={datepickerLocale}
          showMonthDropdown
          showYearDropdown
          onChange={(date) => onChangingFromDate(date)}
        />
      </Form.Group>
      <Form.Group as={Col} md={5} lg={12} xl={5} className="mb-4 pl-xl-0">
        <Form.Label>
          <FormattedMessage id="GENERAL.TO" /> *
        </Form.Label>
        <DatePicker
          name="dateTo"
          selected={toDate}
          dateFormat="dd.MM.yyyy"
          locale={datepickerLocale}
          showMonthDropdown
          showYearDropdown
          onChange={(date) => onChangingToDate(date)}
        />
      </Form.Group>
    </>
  );
}
