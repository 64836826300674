import axios from "axios";

export const ORGENTITY_URL = `${process.env.REACT_APP_URL}gw/orgentities`;
export const ORGENTITY_BRIGADIER_URL = `${process.env.REACT_APP_URL}brigadier/orgentities`;

export function getChildToOrgentity() {
  return axios.get(`${ORGENTITY_URL}/child`);
}

export function getConfigToOrgentity() {
  return axios.get(`${ORGENTITY_BRIGADIER_URL}`);
}

export function updateConfigToOrgentity(form) {
  return axios.put(`${ORGENTITY_BRIGADIER_URL}`, form);
}
