import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {getSelectedLang} from "./getSelectedLang"

import enMessages from "./messages/en";
import plMessages from "./messages/pl";
import deMessages from "./messages/de";
import ruMessages from "./messages/ru";
import esMessages from "./messages/es";


const allMessages = {
  en: {translation: enMessages},
  pl: {translation: plMessages},
  de: {translation: deMessages},
  ru: {translation: ruMessages},
  es: {translation: esMessages},
};

const selectedLang = getSelectedLang().selectedLang;

i18n
  .use(initReactI18next)
  .init({
    resources: allMessages,
    lng: selectedLang,
    fallbackLng: "pl",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
