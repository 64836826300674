import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { Form, Col, InputGroup, Button } from "react-bootstrap";

export function ResultsFilterForm({
  results,
  formData,
  resetFilter,
  onGettingResults,
  onSelectingOrDeselectingAll,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    reset,
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });
  const { touched, submitCount } = formState;
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allMarked, setAllMarked] = useState(false);
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetFilter && setAllMarked(false);
  }, [resetFilter]);

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  const resetFilterForm = () => {
    reset();
    setFromDate(new Date());
    setToDate(new Date());
  };

  useEffect(() => {
    if (
      results &&
      results.length > 0 &&
      !results.find((result) => result.isSelected)
    )
      setAllMarked(false);
    else if (results && results.length > 0) setAllMarked(true);
  }, [results]);

  const selectOrDeselectAll = () => {
    onSelectingOrDeselectingAll(allMarked);
  };

  async function onSubmit(data) {
    setLoading(true);
    let form = data;
    form.dateFrom = moment(fromDate).format("YYYY-MM-DD");
    form.dateTo = moment(toDate).format("YYYY-MM-DD");
    form.employee = data.employee.toLowerCase();
    form.currencyId = null;
    form.localizationId = null;
    const callback = await onGettingResults(form);
    if (callback) {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Form.Row>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_FROM" /> *
          </Form.Label>
          <DatePicker
            name="fromDate"
            selected={fromDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("from", date)}
          />
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_TO" /> *
          </Form.Label>
          <DatePicker
            name="toDate"
            selected={toDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("to", date)}
          />
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.EMPLOYEE" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="employee"
              type="text"
              className={`${
                errors.employee ||
                (touched?.employee && getValues("employee").length >= 51) ||
                (submitCount >= 1 && getValues("employee")?.length >= 51)
                  ? "is-invalid"
                  : ""
              } ${
                !errors.employee && getValues("employee")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.EMPLOYEE",
              })}`}
              ref={register({ required: false, maxLength: 50 })}
            />
          </InputGroup>
          {(errors.employee?.maxLength ||
            (touched?.employee && getValues("employee").length >= 51) ||
            (submitCount >= 1 && getValues("employee")?.length >= 51)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_FIRSTNAME_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.OVERSEER" />
          </Form.Label>
          <Form.Control
            as="select"
            name="loginId"
            ref={register({ required: false })}
          >
            <FormattedMessage id={`GENERAL.EVERYONE`}>
              {(message) => <option value={0}>{message}</option>}
            </FormattedMessage>
            {formData && (
              <>
                {formData.brigadierList.map((overseer) => (
                  <option
                    key={`overseer-${overseer.login.loginId}`}
                    value={overseer.login.loginId}
                  >
                    {`${overseer.firstName} ${overseer.lastName}`}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CROPPING" />
          </Form.Label>
          <Form.Control
            as="select"
            name="croppingId"
            ref={register({ required: false })}
          >
            <FormattedMessage id={`GENERAL.ALL`}>
              {(message) => <option value={0}>{message}</option>}
            </FormattedMessage>
            {formData && (
              <>
                {formData.croppings.map((cropping) => (
                  <option
                    key={`cropping-${cropping.croppingId}`}
                    value={cropping.croppingId}
                  >
                    {cropping.shortName}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} lg={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.TARIFF" />
          </Form.Label>
          <Form.Control
            as="select"
            name="tariffId"
            ref={register({ required: false })}
          >
            <FormattedMessage id={`GENERAL.ALL`}>
              {(message) => <option value={0}>{message}</option>}
            </FormattedMessage>
            {formData && (
              <>
                {formData.tariffs.map((tariff) => (
                  <option
                    key={`tariff-${tariff.tariffId}`}
                    value={tariff.tariffId}
                  >
                    {tariff.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group
          className="d-flex flex-wrap mb-2 w-100 mx-auto justify-content-center"
        >
          <Button
            type="submit"
            disabled={loading || Object.keys(errors).length !== 0}
            className="mb-2 mx-2"
          >
            <FormattedMessage id="GENERAL.FILTER" />
          </Button>

          <Button
            type="button"
            disabled={loading}
            onClick={() => resetFilterForm()}
            className="mb-2 mx-2"
          >
            <FormattedMessage id="GENERAL.REMOVE_FILTER" />
          </Button>

          <Button
            type="button"
            onClick={() => selectOrDeselectAll()}
            disabled={loading || !results.length}
            className="mb-2 mx-2"
          >
            {!allMarked ? (
              <FormattedMessage id="GENERAL.SELECT_ALL" />
            ) : (
              <FormattedMessage id="GENERAL.DESELECT_ALL" />
            )}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
