import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getReceivablesFormData,
  getPayoutsToEmployees,
  createPayoutHeader,
  getPayoutToEmployee,
  getPayoutToEmployeeExcel,
  createPayoutHeaderGroup,
  getPayoutHeaderSign,
} from "../../../services/brigadier/receivablesCrud";
import { getEmployeeDetails } from "../../../services/brigadier/workersCrud";
import {
  ReceivablesWorkersTableCard,
  RegisterOperationCard,
  HistoricalOperationsCard,
  WorkersOperationsModal,
  SignatureModal,
} from "../index";
import { Row, Col } from "react-bootstrap";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function ReceivablesPage() {
  const intl = useIntl();
  const [formData, setFormData] = useState();
  const [workers, setWorkers] = useState([]);
  const [workerData, setWorkerData] = useState();
  const [operations, setOperations] = useState([]);
  const [historicalOperations, setHistoricalOperations] = useState([]);
  const [lastForm, setLastForm] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [signature, setSignature] = useState(null);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);

  function getFormData() {
    getReceivablesFormData()
      .then((response) => {
        const data = response.data;
        data.employees.length &&
          (data.employees = addIsSelectedField(data.employees));
        setFormData(data);
        setWorkers(data.employees);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  const addIsSelectedField = (employees) => {
    employees.forEach((worker) => {
      worker.isSelected = false;
    });
    return employees;
  };

  const onSelectingRow = (id) => {
    const newWorkers = [...workers];
    newWorkers.forEach((worker) => {
      if (worker.id === id) {
        getWorkerDetails(id);
      }
    });
    setWorkers(newWorkers);
  };

  const getWorkerDetails = (workerId) => {
    getEmployeeDetails(workerId)
      .then((response) => {
        setWorkerData(response.data);
        setOperations([]);
        setHistoricalOperations([]);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getWorkerOperations = (form) => {
    form.employeeId = workerData.id;
    return new Promise((resolve) => {
      getPayoutsToEmployees(form)
        .then((response) => {
          setOperations(response.data);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const createReceivableTransaction = (form) => {
    form.employeeId = workerData.id;
    return new Promise((resolve) => {
      createPayoutHeader(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const getWorkerHistoricalOperations = (form) => {
    return new Promise((resolve) => {
      getPayoutToEmployee(
        workerData.id,
        form.dateFrom,
        form.dateTo,
        form.payoutTypeId
      )
        .then((response) => {
          setLastForm(form);
          setHistoricalOperations(response.data);
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const createWorkerOperationsXLSFile = () => {
    return new Promise((resolve) => {
      getPayoutToEmployeeExcel(
        workerData.id,
        lastForm.dateFrom,
        lastForm.dateTo,
        lastForm.payoutTypeId
      )
        .then((response) => {
          const fileName = `operations-${new Date().toISOString()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const createReceivablesTransactions = (form) => {
    return new Promise((resolve) => {
      createPayoutHeaderGroup(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onSelectingHistoricalOperationRow = (id) => {
    const newHistoricalOperations = [...historicalOperations];
    newHistoricalOperations.forEach((operation) => {
      if (operation.__payoutDetailsId === id) {
        operation.isSelected = true;
      } else {
        operation.isSelected = false;
      }
    });
    setHistoricalOperations(newHistoricalOperations);
  };

  const onOpeningOrClosingModal = () => {
    setModalVisible(!modalVisible);
  };

  const getPayoutSignature = (payoutHeaderId) => {
    let pixels = 500;
    setSignatureModalVisible(false);
    return new Promise((resolve) => {
      getPayoutHeaderSign(payoutHeaderId, pixels, pixels)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let image = URL.createObjectURL(blob);
          setSignature(image);
          setSignatureModalVisible(true);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  useEffectOnce(getFormData);

  return (
    <Row>
      <WorkersOperationsModal
        open={modalVisible}
        close={onOpeningOrClosingModal}
        formData={formData}
        workers={workers}
        createReceivablesTransactions={createReceivablesTransactions}
      />
      <SignatureModal
        open={signatureModalVisible}
        close={setSignatureModalVisible}
        signature={signature}
      />
      <Col md={12} lg={4} xxl={4}>
        <ReceivablesWorkersTableCard
          workers={workers}
          onSelectingRow={onSelectingRow}
          onOpeningModal={onOpeningOrClosingModal}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <RegisterOperationCard
          formData={formData}
          activeWorker={workerData}
          operations={operations}
          getWorkerOperations={getWorkerOperations}
          createReceivableTransaction={createReceivableTransaction}
        />
        {workerData && (
          <HistoricalOperationsCard
            formData={formData}
            activeWorker={workerData}
            historicalOperations={historicalOperations}
            getWorkerHistoricalOperations={getWorkerHistoricalOperations}
            onSelectingRow={onSelectingHistoricalOperationRow}
            createWorkerOperationsXLSFile={createWorkerOperationsXLSFile}
            getPayoutSignature={getPayoutSignature}
          />
        )}
      </Col>
    </Row>
  );
}

export default ReceivablesPage;
