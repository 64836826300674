import axios from "axios";

export const WORKFLOW_URL = `${process.env.REACT_APP_URL}brigadier/workflow`;


export function getAllWorkFlow(form) {
  return axios.post(`${WORKFLOW_URL}/employee/all`, form);
}

export function getAllWorkFlowExcel(form) {
    return axios.post(`${WORKFLOW_URL}/employee/all/excel`, form, {
      responseType: "blob",
    });
}

