import React, { useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export function CroppingsTieCrop({
  cropping,
  fields,
  onTyingFieldWithCropping,
  onMappingFieldShapeOnCropping,
}) {
  const { register, handleSubmit, getValues } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);

  const filteredFields = useMemo(() => {
    if (fields.length > 0) return fields.filter((field) => field.isActive);
    else return [];
  }, [fields]);

  async function onSubmit(data) {
    setLoading(true);
    let callback;
    callback = await onTyingFieldWithCropping(data.fieldId);
    if (callback) {
      setLoading(false);
    }
  }

  const shouldDisable = (field) => {
    if (field.species && field.variety) {
      return !(
        field.species.id === cropping?.cropping?.variety.speciesId &&
        field.variety.varietyId === cropping?.cropping?.variety.varietyId
      );
    } else if (field.species) {
      return !(field.species.id === cropping?.cropping?.variety.speciesId);
    } else return false;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
      <Form.Row>
        <Form.Group as={Col} md={9}>
          <Form.Label>
            <FormattedMessage id="GENERAL.FIELDS" />
          </Form.Label>
          <Form.Control
            as="select"
            name="fieldId"
            ref={register({ required: true })}
          >
            {filteredFields.length > 0 && (
              <>
                {filteredFields.map((field) => (
                  <option
                    key={field.fieldId}
                    value={field.fieldId}
                    disabled={shouldDisable(field)}
                  >
                    {field.shortName}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Col md={3} className="d-flex align-items-center justify-content-end">
          <Button className="mr-1" type="submit" disabled={loading}>
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
          <Button
            type="button"
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              const callback = await onMappingFieldShapeOnCropping(
                getValues("fieldId")
              );
              if (callback) {
                setLoading(false);
              } else {
                setLoading(false);
              }
            }}
          >
            <FormattedMessage id="GENERAL.MAP_SHAPE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
