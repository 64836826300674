import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_URL}logins`;

export function getProfileToCurrentLogin() {
  return axios.get(`${LOGIN_URL}/profile`);
}

export function updateProfileToCurrentLogin(form) {
  return axios.put(`${LOGIN_URL}/profile`, form);
}