import React from "react";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export function AsideButton({ parentFunction, tooltipMessage, iconImage }) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="user-tooltip">
          <FormattedMessage id={`GENERAL.${tooltipMessage}`} />
        </Tooltip>
      }
    >
      <Button
        variant="primary"
        className="aside_buttons_container__button rounded-circle mb-4 shadow-lg"
        onClick={parentFunction}
      >
        <i className={`aside_buttons_container__icon ${iconImage}`} />
      </Button>
    </OverlayTrigger>
  );
}
