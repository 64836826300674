import React from "react";
import { FormattedMessage } from "react-intl";
import { PackagingLabelRow } from "../index";
import Table from "react-bootstrap/Table";

export function PackagingLabelsTable({
  data,
  onSelectingLabelsToPrint,
  onDeletingLabels,
}) {
  return (
    <div className="packaging__table">
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>
              <FormattedMessage id="GENERAL.PRINT" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.LABEL" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.ACTIONS" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((label) => (
            <PackagingLabelRow
              key={`label-${label.id}`}
              label={label}
              onSelectingLabelsToPrint={onSelectingLabelsToPrint}
              onDeletingLabels={onDeletingLabels}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}
