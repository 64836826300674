import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export function HistoricalOperationsFilterForm({
  formData,
  getWorkerHistoricalOperations,
}) {
  const { register, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  async function onSubmit(data) {
    let form = data;
    setLoading(true);
    form.dateFrom = moment(fromDate).format("YYYY-MM-DD");
    form.dateTo = moment(toDate).format("YYYY-MM-DD");
    const callback = await getWorkerHistoricalOperations(form);
    if (callback) {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Form.Row>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_FROM" /> *
          </Form.Label>
          <DatePicker
            name="fromDate"
            selected={fromDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("from", date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_TO" /> *
          </Form.Label>
          <DatePicker
            name="toDate"
            selected={toDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("to", date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.OPERATION_TYPE" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="payoutTypeId"
            ref={register({ required: true })}
          >
            <FormattedMessage id={`GENERAL.ALL`}>
              {(message) => <option value={0}>{message}</option>}
            </FormattedMessage>
            {formData && (
              <>
                {formData.payoutTypes.map((payout) => (
                  <option key={`payout-${payout.id}`} value={payout.id}>
                    {payout.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Col
          md={3}
          className="text-right"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button type="submit" disabled={loading}>
            <FormattedMessage id="GENERAL.FILTER" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
