import React from "react";
import { FormattedMessage } from "react-intl";
import { HistoricalOperationRow } from "./HistoricalOperationRow";
import { OperationSummaryRows } from "./OperationSummaryRows";
import { Table } from "react-bootstrap";

export function HistoricalOperationsTable({
  data,
  onSelectingRow,
  getPayoutSignature,
}) {
  return (
    <div className="packaging__table">
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>
              <FormattedMessage id="GENERAL.ITEM" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.DOCUMENT" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.OPERATION" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.DATE" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.AMOUNT" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.BILLING_CURRENCY" />
            </th>
            <th>
              <FormattedMessage id="GENERAL.ACTIONS" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((operation) => {
              return operation.__level__ === 0;
            })
            .map((operation, index) => (
              <HistoricalOperationRow
                key={`historicalOperation-${index}`}
                index={index}
                operation={operation}
                onSelectingRow={onSelectingRow}
                getPayoutSignature={getPayoutSignature}
              />
            ))}
          <OperationSummaryRows data={data} />
        </tbody>
      </Table>
    </div>
  );
}
