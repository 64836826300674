import axios from "axios";

export const SHAPES_URL = `${process.env.REACT_APP_URL}shapes`;

export function getShape(shapeId) {
  return axios.get(`${SHAPES_URL}/${shapeId}`);
}

export function getCentroidPoint(shapeId) {
  return axios.get(`${SHAPES_URL}/${shapeId}/centroid`);
}

export function createPolygonToField(fieldId, form) {
  return axios.post(`${SHAPES_URL}/field/${fieldId}/create`, form);
}

export function updatePolygonToField(fieldId, form) {
  return axios.put(`${SHAPES_URL}/field/${fieldId}/update`, form);
}

export function createPolygonToCropping(croppingId, form) {
  return axios.post(`${SHAPES_URL}/cropping/${croppingId}/create`, form);
}

export function updatePolygonToCropping(croppingId, form) {
  return axios.put(`${SHAPES_URL}/cropping/${croppingId}/update`, form);
}

export function getShapeLongestLine(shapeId) {
  return axios.get(`${SHAPES_URL}/${shapeId}/longestLine`);
}

export function getShapesLongestLine(form) {
  return axios.post(`${SHAPES_URL}/longestLine`);
}

export function getPoint(shapeId) {
  return axios.get(`${SHAPES_URL}/${shapeId}/point`);
}
