import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "react-bootstrap";
import { SubscriptionRow } from "./SubscriptionRow";

export function UserSubscriptionsTable(props) {
  const { userSubscriptions } = props;
  return (
    <Table className="mt-1 mb-3" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.TYPE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.STATUS" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.START_DATE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.SUBSCRIPTION_EXPIRATION_DATE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.BILLED" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {userSubscriptions.map((subscription, index) => (
          <SubscriptionRow
            key={`userSubscriptionRow-${subscription.orgentity2Subscription.subscription.id}-${index}`}
            subscription={subscription}
            shared={false}
          />
        ))}
      </tbody>
    </Table>
  );
}
