import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { FarmsTable } from "./FarmsTable";

export const FarmsCard = ({ farms, handleRowClick }) => {
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState(farms || []);

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (farms.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return farms.filter((farm) => {
      const fullNameExist = farm.fullName
        ? farm.fullName.toLowerCase().includes(text.toLowerCase())
        : false;
      const numberExist = farm.number
        ? farm.number.toLowerCase().includes(text.toLowerCase())
        : false;
      if (!farm.fullName && !farm.number) return farm;
      return fullNameExist || numberExist;
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {farms.length > 0 ? (
          <div className="card__table">
            <FarmsTable data={filteredData} handleRowClick={handleRowClick} />
          </div>
        ) : (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_FARMS_TO_SHOW" />
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};
