import React, { useState, useEffect, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import getAvailableTariffs from "../../helpers/getAvailableTariffs";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";

export function SingleTariffBonus({
  formData,
  group,
  tariffs,
  tariffLevelGroups,
  onDeletingTariffLevelGroup,
  onUpdatingTariffLevelGroup,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    control,
    watch,
  } = useForm({ mode: "onChange" });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "levels",
    }
  );

  const [deleteBtnBlocked, setDeleteBtnBlocked] = useState(false);
  const [saveBtnBlocked, setSaveBtnBlocked] = useState(false);

  const [selectedTariffs, setSelectedTariffs] = useState();
  const [tariffsLoaded, setTariffsLoaded] = useState();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);

  const [chargeBonus, setChargeBonus] = useState(true);

  const availableTariffs = useMemo(() => {
    return getAvailableTariffs(
      selectedTariffs,
      tariffLevelGroups,
      tariffs,
      group,
      "tariffLevelGroup"
    );
  }, [group, selectedTariffs, tariffLevelGroups, tariffs]);

  const numberDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "DECIMAL_PLACE"
      )?.numericValue
  );
  const numberDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "DECIMAL_PLACE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });
  const rateDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "RATE"
      )?.numericValue
  );
  const rateDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "RATE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });

  useEffect(() => {
    if (availableTariffs && !selectedTariffs && !tariffsLoaded)
      setSelectedTariffs(
        availableTariffs.filter((tariff) =>
          group.tariffIds.includes(tariff.tariffId)
        )
      );
    setTariffsLoaded(true);
  }, [group.tariffIds, availableTariffs, selectedTariffs, tariffsLoaded]);

  async function handleDeleteTariffLevelGroup() {
    setDeleteBtnBlocked(true);
    const callback = await onDeletingTariffLevelGroup(group.tariffLevelGroupId);
    if (callback) {
      setDeleteBtnBlocked(false);
    }
  }

  async function handleUpdateTariffLevelGroup() {
    setSaveBtnBlocked(true);
    const callback = await onUpdatingTariffLevelGroup(
      group.tariffLevelGroupId,
      selectedTariffs,
      chargeBonus,
      getValues({ nest: true }).levels ? getValues({ nest: true }).levels : []
    );
    if (callback) {
      setSaveBtnBlocked(false);
    }
  }

  useEffect(() => {
    setChargeBonus(group.chargeBonus);
  }, [group.chargeBonus]);

  useEffect(() => {
    if (
      (!fields || !fields.length) &&
      group.levels?.length > 0 &&
      !fieldsLoaded
    ) {
      append(group.levels);
      setFieldsLoaded(true);
    }
  }, [append, fields, fieldsLoaded, group.levels]);

  const addGroup = () => {
    append({
      infimum: 0,
      value: 0,
    });
  };

  const removeGroup = (index) => {
    remove(index);
  };

  return (
    <Col xs={12} key={`tariffLevelGroup-${group.tariffLevelGroupId}`}>
      <div className="border rounded py-2 px-4 mb-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="mb-0">
            <FormattedMessage id="GENERAL.GROUP" />
            {" #"}
            {group.tariffLevelGroupId}
          </h6>
          <Button
            size="sm"
            onClick={handleDeleteTariffLevelGroup}
            variant="danger"
            disabled={deleteBtnBlocked}
          >
            <i className="fa fa-trash pr-0" style={{ fontSize: "1rem" }} />
          </Button>
        </div>
        <Form.Group className="mb-2">
          <Form.Label>
            <strong>
              <FormattedMessage id="MENU.TARIFFS" />
            </strong>
          </Form.Label>
          <Select
            isMulti
            options={availableTariffs}
            value={selectedTariffs}
            onChange={setSelectedTariffs}
            placeholder={`${intl.formatMessage({
              id: "GENERAL.SELECT_TARIFFS",
            })}...`}
          />
        </Form.Group>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={chargeBonus}
                onChange={() => setChargeBonus(!chargeBonus)}
                color="secondary"
              />
            }
            label={intl.formatMessage({
              id: "BRIGADIER.BONUS_THRESHOLDS.CHARGE_BONUS",
            })}
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          <p className="mb-0 mr-2">
            <strong>
              <FormattedMessage id="BRIGADIER.BONUS_THRESHOLDS" />
            </strong>
          </p>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={addGroup}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </div>
        {fields.map((field, index) => (
          <div key={`tariffBonus-${group.tariffLevelGroupId}-group-${index}`}>
            <Form.Row>
              <Col md={8} className="d-flex align-items-end">
                <div className="d-flex align-items-end flex-wrap flex-md-nowrap">
                  <Form.Group
                    style={{ width: "100%", maxWidth: "8rem" }}
                    className="mr-3"
                  >
                    <Form.Label>
                      <FormattedMessage id="GENERAL.QUANTITY" /> *
                    </Form.Label>
                    <Form.Control
                      name={`levels[${index}].infimum`}
                      type="number"
                      min={numberDecimalStep}
                      step={numberDecimalStep}
                      className={`${
                        errors.levels && errors.levels[index]?.infimum
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={register({
                        required: true,
                        min: numberDecimalStep,
                        validate: (value) => {
                          const split = value.toString().split(".");
                          if (split.length > 1) {
                            if (split[1].length > numberDecimalValue) return false;
                            else return true;
                          } else return true;
                        },
                      })}
                      defaultValue={field.infimum ? field.infimum : 1}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{ width: "100%", maxWidth: "8rem" }}
                    className="mr-3"
                  >
                    <Form.Label>
                      <FormattedMessage id="BRIGADIER.BASIC_MEASUREMENT" />
                    </Form.Label>
                    <Form.Control as="select" disabled value={group.uom.id}>
                      {formData && (
                        <>
                          {formData.uom.map((unit) => (
                            <option
                              key={`tariffBonus-${group.tariffLevelGroupId}-group-${field.id}-unit-${unit.id}`}
                              value={unit.id}
                            >
                              {unit.desc}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    style={{ width: "100%", maxWidth: "8rem" }}
                    className="mr-3"
                  >
                    <Form.Label>
                      <FormattedMessage id="BRIGADIER.RATE" /> *
                    </Form.Label>
                    <Form.Control
                      name={`levels[${index}].value`}
                      type="number"
                      min={rateDecimalStep}
                      step={rateDecimalStep}
                      className={`${
                        errors.levels && errors.levels[index]?.value
                          ? "is-invalid"
                          : ""
                      }`}
                      ref={register({
                        required: true,
                        min: rateDecimalStep,
                        validate: (value) => {
                          const split = value.toString().split(".");
                          if (split.length > 1) {
                            if (split[1].length > rateDecimalValue) return false;
                            else return true;
                          } else return true;
                        },
                      })}
                      defaultValue={field.value ? field.value : 1}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{ width: "100%", maxWidth: "8rem" }}
                    className="mr-3"
                  >
                    <Form.Label>
                      <FormattedMessage id="GENERAL.CURRENCY" />{" "}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={group.currency.id}
                      disabled
                    >
                      {formData && (
                        <>
                          {formData.currencies.map((currency) => (
                            <option
                              key={`tariffBonus-${group.tariffLevelGroupId}-group-${field.id}-currency-${currency.id}`}
                              value={currency.id}
                            >
                              {currency.name}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Button
                      onClick={() => removeGroup(index)}
                      variant="outline-danger"
                    >
                      <i
                        className="fa fa-trash pr-0"
                        style={{ fontSize: "1rem" }}
                      />
                    </Button>
                  </Form.Group>
                </div>
              </Col>
            </Form.Row>
            <div className="mt-n5 mb-12 mb-md-5">
              {errors.levels && errors.levels[index]?.infimum?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                  {` ${numberDecimalStep}`}
                </div>
              )}
              {errors.levels &&
                errors.levels[index]?.infimum?.type === "validate" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                    <FormattedMessage id={`ERROR.MAX_${numberDecimalValue}_DECIMAL_PLACES`} />
                  </div>
                )}
              {errors.levels &&
                errors.levels[index]?.infimum?.type === "required" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
              {errors.levels && errors.levels[index]?.value?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                  {` ${rateDecimalStep}`}
                </div>
              )}
              {errors.levels &&
                errors.levels[index]?.value?.type === "validate" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                    <FormattedMessage id={`ERROR.MAX_${rateDecimalValue}_DECIMAL_PLACES`} />
                  </div>
                )}
              {errors.levels &&
                errors.levels[index]?.value?.type === "required" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
            </div>
          </div>
        ))}
        <div className="text-right">
          <Button
            onClick={handleUpdateTariffLevelGroup}
            disabled={
              saveBtnBlocked ||
              !selectedTariffs ||
              selectedTariffs?.length === 0 ||
              Object.keys(errors).length !== 0
            }
            className="mb-4"
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </div>
      </div>
    </Col>
  );
}
