import React from "react";
import { FormattedMessage } from "react-intl";
import { WorkTimeFilterForm } from "./WorkTimeFilterForm";
import { WorkTimeResultsTable } from "./WorkTimeResultsTable";
import { Button } from "react-bootstrap";

export function WorkTimeTableCard({
  results,
  formData,
  resetFilter,
  onGettingResults,
  onSelectingRow,
  onSelectingOrDeselectingAll,
  onClosingOpeningAddingResultModal,
  onClosingOpeningUpdatingResultsModal,
  onClosingOpeningBulkEditResultsModal,
  onClosingOpeningRemovingResultsModal,
  onClosingOpeningExportingResultsModal,
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <WorkTimeFilterForm
            results={results}
            formData={formData}
            resetFilter={resetFilter}
            onGettingResults={onGettingResults}
            onSelectingOrDeselectingAll={onSelectingOrDeselectingAll}
          />
        </div>
      </div>
      <div className="card-body">
        {results.length === 0 ? (
          <div className="text-center mb-5">
            <h6>
              <FormattedMessage id="GENERAL.NO_RESULTS" />
            </h6>
          </div>
        ) : (
          <div className="card__table--small card__table--brigadier">
            <WorkTimeResultsTable data={results} onSelectingRow={onSelectingRow} />
          </div>
        )}
        <div className="d-flex mt-2 flex-wrap mb-n2 w-100 mx-auto justify-content-center">
          <Button
            onClick={() => onClosingOpeningAddingResultModal(false)}
            disabled={true}
            className="mb-2 mx-2"
          >
            <FormattedMessage id="GENERAL.EDIT" />
          </Button>
          <Button
            onClick={() => onClosingOpeningRemovingResultsModal()}
            // disabled={
            //   results.find((result) => result.isSelected) ? false : true
            // }
            disabled={true}
            className="mb-2 mx-2"
          >
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>
          <Button
            onClick={() => onClosingOpeningExportingResultsModal()}
            disabled={!results.length}
            className="mb-2 mx-2"
          >
            <FormattedMessage id="GENERAL.EXPORT" />
          </Button>
        </div>
      </div>
    </div>
  );
}
