import * as React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import getSuppliersToRedux from "./helpers/getSuppliersToRedux";

export default function SuppliersSelect() {
  const intl = useIntl();
  const dispatch = useDispatch();

  const suppliersData = useSelector((state) => state.suppliers?.data);
  const suppliersLoading = useSelector((state) => state.suppliers?.loading);
  const selectedSupplier = useSelector(
    (state) => state.suppliers?.selectedSupplier
  );
  const suppliers = React.useMemo(() => {
    if (
      suppliersData &&
      suppliersData.suppliers &&
      suppliersData.orgentitySuppliers
    ) {
      return [
        ...new Set(
          []
            .concat(
              suppliersData.suppliers.map((supplier) => supplier.fullName)
            )
            .concat(
              suppliersData.orgentitySuppliers.map(
                (supplier) => supplier.fullName
              )
            )
        ),
      ].map((supplier) => ({
        value: supplier,
        label: supplier,
      }));
    }
  }, [suppliersData]);

  React.useEffect(() => getSuppliersToRedux(), []);

  const setSelectedSupplier = (data) => {
    dispatch({
      type: "suppliers/setSelectedSupplier",
      payload: data,
    });
  };

  return (
    <CreatableSelect
      className="input__select--search"
      isClearable
      options={suppliers}
      isDisabled={suppliersLoading}
      isLoading={suppliersLoading}
      placeholder={
        suppliersLoading
          ? `${intl.formatMessage({ id: "GENERAL.LOADING" })}...`
          : ``
      }
      formatCreateLabel={(inputValue) => {
        return `${intl.formatMessage({
          id: "GENERAL.ADD",
        })} "${inputValue}"`;
      }}
      noOptionsMessage={() =>
        intl.formatMessage({
          id: "GENERAL.NO_SUPPLIERS_ADD",
        })
      }
      value={selectedSupplier}
      onChange={setSelectedSupplier}
    />
  );
}
