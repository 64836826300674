import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import displayFeedback from "../../../components/helpers/displayFeedback";
import { FormattedMessage } from "react-intl";
import { updateSettingsParameters } from "../../../services/brigadier/settingsCrud";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  MenuItem,
  Select,
  Switch,
  withStyles,
} from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import { useTheme } from "@material-ui/core/styles";
import BrigadierCircularProgress from "./BrigadierCircularProgress";

function SettingsParametersCard() {
  const intl = useIntl();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const settingsParameters = useSelector(
    (state) => state.brigadierSettingsParameters
  );

  const {
    control,
    register,
    triggerValidation,
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "brigadierSettingsParameters",
  });

  const dispatch = useDispatch();

  const onUpdatingSettingsParameters = () => {
    setSaving(true);
    updateSettingsParameters(
      getValues({ nest: true }).brigadierSettingsParameters.map(
        (field, index) => ({
          symbol: fields[index].parameter.symbol,
          booleanValue: field.booleanValue,
          numericValue: field.numericValue,
        })
      )
    )
      .then((response) => {
        dispatch({
          type: "brigadier/settingsParameters/set",
          payload: response.data,
        });
        setSaving(false);
        displayFeedbackFromHeaders(response.headers);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    if (fields.length === 0 && settingsParameters?.length > 0)
      append(
        settingsParameters.map((param) => ({
          ...param,
          booleanValue:
            param.booleanValue === null ? false : param.booleanValue,
        }))
      );
  }, [append, fields.length, settingsParameters]);

  const BrigadierThemeSwitch = withStyles({
    switchBase: {
      color: theme.palette.brigadier.main,
      "&$checked": {
        color: theme.palette.brigadier.main,
      },
      "&$checked + $track": {
        backgroundColor: theme.palette.brigadier.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon svg-icon-dark-75 menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="BRIGADIER.SETTINGS.PARAMETERS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        {!loading && fields.length > 0 && (
          <>
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="d-flex align-items-center justify-content-between mb-3"
                style={{ minHeight: "38px" }}
              >
                <h6 className="mb-0">
                  <span>
                    <FormattedMessage id={`${field.parameter.name}`} />
                  </span>
                </h6>
                {field.parameter.isBoolean && (
                  <Controller
                    as={BrigadierThemeSwitch}
                    control={control}
                    name={`brigadierSettingsParameters[${index}].booleanValue`}
                    defaultValue={field.booleanValue}
                    color="primary"
                  />
                )}
                {field.parameter.isNumeric && (
                  <Controller
                    as={
                      <Select className="mr-2" color="secondary">
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    }
                    control={control}
                    name={`brigadierSettingsParameters[${index}].numericValue`}
                    defaultValue={field.numericValue ? field.numericValue : 2}
                  />
                )}
              </div>
            ))}
            <div className="text-right mt-5">
              <Button
                type="submit"
                disabled={saving}
                onClick={onUpdatingSettingsParameters}
              >
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </div>
          </>
        )}
        {!loading && fields.length === 0 && (
          <div className="d-flex justify-content-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
            </h6>
          </div>
        )}
        {loading && (
          <div className="d-flex justify-content-center">
            <BrigadierCircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default SettingsParametersCard;
