import React, { useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getPackagingsFormData,
  getAllPackagings,
  createPackage,
  updatePackage,
  generatePackagingLabels,
  deleteLabels,
  incrementPrinterCount,
  createExcelWithLabels,
} from "../../../services/brigadier/packagingCrud";
import {
  PackagingTableCard,
  PackagingGeneralDataCard,
  PackagingLabelsCard,
  GeneratePackagingLabelsModal,
} from "../index";
import { Row, Col } from "react-bootstrap";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function PackagingPage() {
  const intl = useIntl();
  const [mode, setMode] = useState("create");
  const [formData, setFormData] = useState();
  const [packaging, setPackaging] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [barcodesLabels, setBarcodesLabels] = useState([]);
  const [isQrCodePDF, setIsQrCodePDF] = useState(false);

  function getFormData() {
    getPackagingsFormData()
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  function getPackaging() {
    getAllPackagings()
      .then((response) => {
        const newPackaging = setPackagingAdditionalFields(response.data).sort(
          sortByName
        );
        if (
          response.data.length &&
          newPackaging.filter((packaging) => packaging.isActive).length > 0
        ) {
          newPackaging.filter(
            (packaging) => packaging.isActive
          )[0].isSelected = true;
          setPackaging(newPackaging);
          setMode("update");
        } else if (response.data.length) {
          setPackaging(newPackaging);
          setMode("create");
        } else {
          setMode("create");
        }
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  const setPackagingAdditionalFields = (receivedPackagings) => {
    receivedPackagings.forEach((bundle) => {
      if (bundle.labels.length) {
        bundle.labels.forEach((label) => {
          label.print = false;
        });
      }
    });
    return receivedPackagings;
  };

  const onChangingMode = () => {
    if (mode === "create") {
      setMode("update");
    } else {
      const newPackaging = [...packaging];
      newPackaging.forEach((bundle) => {
        bundle.isSelected = false;
      });
      setPackaging(newPackaging);
      setMode("create");
    }
  };

  const onSelectingRow = (id) => {
    const newPackaging = [...packaging];
    newPackaging.forEach((bundle) => {
      if (bundle.id === id) {
        bundle.isSelected = true;
        setBarcodesLabels([]);
        setMode("update");
      } else {
        bundle.isSelected = false;
      }
    });
    setPackaging(newPackaging);
  };

  function onCreatingPackaging(form) {
    return new Promise((resolve) => {
      createPackage(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          response.data.isSelected = true;
          const newPackaging = [...packaging];
          newPackaging.push(response.data);
          setPackaging(newPackaging);
          setBarcodesLabels([]);
          setMode("update");
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  function onUpdatingPackaging(packagingId, form) {
    return new Promise((resolve) => {
      updatePackage(packagingId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          updateObject(packagingId, response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  }

  const sortByName = (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  const updateObject = (packagingId, updatedPackage) => {
    const newPackaging = [...packaging];
    const searchedPackage = newPackaging.find((bundle) => {
      return bundle.id === packagingId;
    });
    searchedPackage.name = updatedPackage.name;
    searchedPackage.isActive = updatedPackage.isActive;
    searchedPackage.quantity = updatedPackage.quantity;
    searchedPackage.uom = updatedPackage.uom;
    searchedPackage.desc = updatedPackage.desc;
    searchedPackage.weight = updatedPackage.weight;
    setPackaging(newPackaging);
  };

  const openOrCloseModal = () => {
    setModalVisible(!modalVisible);
  };

  const onGeneratingPackagingLabels = (quantity) => {
    const searchedPackaging = [...packaging].find((bundle) => {
      return bundle.isSelected;
    });
    return new Promise((resolve) => {
      generatePackagingLabels(searchedPackaging.id, quantity)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          updateObjectLabels(searchedPackaging.id, response.data);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const updateObjectLabels = (packagingId, labels) => {
    const newPackaging = _.cloneDeep(packaging);
    const searchedPackage = newPackaging.find((bundle) => {
      return bundle.id === packagingId;
    });
    labels.forEach((label) => {
      label.print = false;
    });
    if (!searchedPackage.labels) {
      searchedPackage.labels = [];
    }
    searchedPackage.labels = searchedPackage.labels.concat(labels);
    setPackaging(newPackaging);
  };

  const onSelectingLabelsToPrint = (labelIds) => {
    const newPackaging = _.cloneDeep(packaging);
    const searchedPackage = newPackaging.find((bundle) => {
      return bundle.isSelected;
    });
    const searchedLabels = searchedPackage.labels.filter((label) => {
      const isSelected = labelIds.find((lab) => {
        return lab === label.id;
      });
      return isSelected;
    });
    searchedLabels.forEach((label) => {
      if (label.print) {
        if (searchedLabels.length > 1) {
          label.print = true;
        } else {
          label.print = !label.print;
        }
      } else {
        label.print = true;
      }
    });
    setPackaging(newPackaging);
  };

  const onDeselectingLabelsToPrint = (labelIds) => {
    const newPackaging = _.cloneDeep(packaging);
    const searchedPackage = newPackaging.find((bundle) => {
      return bundle.isSelected;
    });
    const searchedLabels = searchedPackage.labels.filter((label) => {
      const isSelected = labelIds.find((lab) => {
        return lab === label.id;
      });
      return isSelected;
    });
    searchedLabels.forEach((label) => {
      label.print = false;
    });
    setPackaging(newPackaging);
  };

  const onDeletingLabels = (labelIds) => {
    return new Promise((resolve) => {
      deleteLabels(labelIds)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          removeObjectLabel(labelIds);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const removeObjectLabel = (labelIds) => {
    const newPackaging = _.cloneDeep(packaging);
    const searchedPackaging = newPackaging.find((bundle) => {
      return bundle.isSelected;
    });
    labelIds.forEach((id) => {
      const index = searchedPackaging.labels.findIndex((label) => {
        return label.id === id;
      });
      searchedPackaging.labels.splice(index, 1);
    });
    setPackaging(newPackaging);
  };

  const onGeneratingLabelsBarcodes = (isQr) => {
    const searchedPackaging = [...packaging].find((bundle) => {
      return bundle.isSelected;
    });
    const selectedLabelsIds = searchedPackaging.labels
      .filter((label) => {
        return label.print;
      })
      .map((label) => {
        return label.id;
      });
    return new Promise((resolve) => {
      incrementPrinterCount(selectedLabelsIds)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          incrementLabelsPrintCount();
          generateBarcodesPDF(selectedLabelsIds, isQr);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const onGeneratingLabelsToExcel = () => {
    const searchedPackaging = [...packaging].find((bundle) => {
      return bundle.isSelected;
    });
    const selectedLabelsIds = searchedPackaging.labels
      .filter((label) => {
        return label.print;
      })
      .map((label) => {
        return label.id;
      });
    return new Promise((resolve) => {
      createExcelWithLabels(selectedLabelsIds)
        .then((response) => {
          const fileName = `labels-${new Date().toISOString()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          displayFeedbackFromHeaders(response.headers);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const incrementLabelsPrintCount = () => {
    const newPackaging = _.cloneDeep(packaging);
    const searchedPackaging = newPackaging.find((bundle) => {
      return bundle.isSelected;
    });
    searchedPackaging.labels
      .filter((label) => {
        return label.print;
      })
      .map((label) => {
        label.printerCount++;
        label.print = false;
      });
    setPackaging(newPackaging);
  };

  const generateBarcodesPDF = (selectedLabelsIds, isQr) => {
    const searchedPackaging = [...packaging].find((bundle) => {
      return bundle.isSelected;
    });
    const searchedLabels = searchedPackaging.labels.filter((label) => {
      return selectedLabelsIds.includes(label.id);
    });
    setIsQrCodePDF(isQr);
    setBarcodesLabels(searchedLabels);
  };

  useEffectOnce(getFormData);
  useEffectOnce(getPackaging);

  return (
    <Row>
      <GeneratePackagingLabelsModal
        open={modalVisible}
        close={openOrCloseModal}
        onGeneratingPackagingLabels={onGeneratingPackagingLabels}
      />
      <Col md={12} lg={4} xxl={4}>
        <PackagingTableCard
          mode={mode}
          packaging={packaging}
          onSelectingRow={onSelectingRow}
          onChangingMode={onChangingMode}
        />
      </Col>
      <Col md={12} lg={8} xxl={8}>
        <PackagingGeneralDataCard
          mode={mode}
          formData={formData}
          activePackaging={[...packaging].find((bundle) => {
            return bundle.isSelected;
          })}
          onCreatingPackaging={onCreatingPackaging}
          onUpdatingPackaging={onUpdatingPackaging}
          openOrCloseModal={openOrCloseModal}
        />
        {mode === "update" && (
          <PackagingLabelsCard
            activePackaging={[...packaging].find((bundle) => {
              return bundle.isSelected;
            })}
            barcodesLabels={barcodesLabels}
            onSelectingLabelsToPrint={onSelectingLabelsToPrint}
            onDeselectingLabelsToPrint={onDeselectingLabelsToPrint}
            onDeletingLabels={onDeletingLabels}
            onGeneratingLabelsBarcodes={onGeneratingLabelsBarcodes}
            onGeneratingLabelsToExcel={onGeneratingLabelsToExcel}
            isQrCodePDF={isQrCodePDF}
          />
        )}
      </Col>
    </Row>
  );
}

export default PackagingPage;
