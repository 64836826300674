const AG_GRID_LOCALE_ES = {
  // Set Filter
  selectAll: '(Seleccionar todo)',
  selectAllSearchResults: '(Seleccione todos los resultados de la búsqueda)',
  searchOoo: 'Buscar...',
  blanks: '(Espacios en blanco)',
  noMatches: 'No hay coincidencias',

  // Number Filter & Text Filter
  filterOoo: 'Filtrar...',
  equals: 'Igual que',
  notEqual: 'Distinto a',
  blank: 'En blanco',
  notBlank: 'No está en blanco',
  empty: 'Elija uno',

  // Number Filter
  lessThan: 'Menos de',
  greaterThan: 'Mayor que',
  lessThanOrEqual: 'Menos o igual que',
  greaterThanOrEqual: 'Mayor o igual que',
  inRange: 'En el rango',
  inRangeStart: 'desde',
  inRangeEnd: 'hasta',

  // Text Filter
  contains: 'Contiene',
  notContains: 'No contiene',
  startsWith: 'Empieza con',
  endsWith: 'Termina con',

  // Date Filter
  dateFormatOoo: 'dd-mm-yyyy',

  // Filter Conditions
  andCondition: 'Y',
  orCondition: 'O',

  // Filter Buttons
  applyFilter: 'Aplicar',
  resetFilter: 'Reiniciar',
  clearFilter: 'Limpiar',
  cancelFilter: 'Cancelar',

  // Filter Titles
  textFilter: 'Filtrar texto',
  numberFilter: 'Filtrar numeros',
  dateFilter: 'Filtrar fechas',
  setFilter: 'Aplicar filtros',

  // Side Bar
  columns: 'Columnas',
  filters: 'Filtros',

  // columns tool panel
  pivotMode: 'Modo Pivote',
  groups: 'Grupos de filas',
  rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer grupos de filas',
  values: 'Valores',
  valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
  pivots: 'Etiquetas de las columnas',
  pivotColumnsEmptyMessage: 'Arrastre aquí para establecer las etiquetas de las columnas',

  // Header of the Default Group Column
  group: 'Grupo',

  // Row Drag
  rowDragRows:'filas',

  // Other
  loadingOoo: 'Cargando...',
  noRowsToShow: 'No hay filas que mostrar',
  enabled: 'Habilitado',

  // Menu
  pinColumn: 'Columna Pin',
  pinLeft: 'Pin izquierda',
  pinRight: 'Pin derecha',
  noPin: 'Sin Pin',
  valueAggregation: 'Agregación de valores',
  autosizeThiscolumn: 'Autodimensionar esta columna',
  autosizeAllColumns: 'Autodimensionar todas las columnas',
  groupBy: 'Agrupar por',
  ungroupBy: 'Desagrupar por',
  addToValues: 'Añadir ${variable} a los valores',
  removeFromValues: 'Eliminar ${variable} de los valores',
  addToLabels: 'Añadir ${variable} a las etiquetas',
  removeFromLabels: 'Eliminar ${variable} de las etiquetas',
  resetColumns: 'Resetear columnas',
  expandAll: 'Ampliar todo',
  collapseAll: 'Cerrar todo',
  copy: 'Copiar',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copiar con cabeceras',
  copyWithHeaderGroups: 'Copiar con grupos de cabecera',
  paste: 'Pegar',
  ctrlV: 'Ctrl+V',
  export: 'Exportar',
  csvExport: 'Exportar CSV',
  excelExport: 'Exportar Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Suma',
  min: 'Min',
  max: 'Max',
  none: 'Ninguno',
  count: 'Contar',
  avg: 'Media',
  filteredRows: 'Filtrado',
  selectedRows: 'Seleccionado',
  totalRows: 'Filas totales',
  totalAndFilteredRows: 'Filas',
  more: 'Mas',
  to: 'a',
  of: 'de',
  page: 'Página',
  nextPage: 'Página siguiente',
  lastPage: 'Ultima página',
  firstPage: 'Primera página',
  previousPage: 'Oágina anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Gráfico pivote y modo pivote',
  pivotChart: 'Gráfico pivote',
  chartRange: 'Rango del gráfico',

  columnChart: 'Columna',
  groupedColumn: 'Agrupado',
  stackedColumn: 'Apilado',
  normalizedColumn: '100% Apilado',

  barChart: 'Barra',
  groupedBar: 'Agrupado',
  stackedBar: 'Apilado',
  normalizedBar: '100% Apilado',

  pieChart: 'Tarta',
  pie: 'Tarta',
  doughnut: 'Donuts',

  line: 'Linea',

  xyChart: 'X Y (Dispersado)',
  scatter: 'Dispersado',
  bubble: 'Burbuja',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Apilado',
  normalizedArea: '100% Apilado',

  histogramChart: 'Histograma',

  combinationChart: 'Combinación',
  columnLineCombo: 'Columna y línea',
  AreaColumnCombo: 'Área y Columna',

  // Charts
  pivotChartTitle: 'Gráfico pivote',
  rangeChartTitle: 'Rango del grafico',
  settings: 'Ajustes',
  data: 'Datos',
  format: 'Formato',
  categories: 'Categorias',
  defaultCategory: '(Ninguna)',
  series: 'Serie',
  xyValues: 'Valores X Y',
  paired: 'Modo emparejamiento',
  axis: 'Eje',
  navigator: 'Navegador',
  color: 'Color',
  thickness: 'Grosor',
  xType: 'Tipo X',
  automatic: 'Automatico',
  category: 'Categoria',
  number: 'Numero',
  time: 'Tiempo',
  xRotation: 'Rotacion X',
  yRotation: 'Rotación Y',
  ticks: 'Ticks',
  width: 'Anchura',
  height: 'Altura',
  length: 'Longitud',
  padding: 'Padding',
  spacing: 'Separación',
  chart: 'Grafico',
  title: 'Titulo',
  titlePlaceholder: 'Título del gráfico - doble clic para editarlo',
  background: 'Fondo',
  font: 'Fuente',
  top: 'Arriba',
  right: 'Derecha',
  bottom: 'Abajo',
  left: 'Izquierda',
  labels: 'Etiquetas',
  size: 'Tamaño',
  minSize: 'Tamaño minimo',
  maxSize: 'Tamaño maximo',
  legend: 'Leyenda',
  position: 'Posición',
  markerSize: 'Tamaño del marcador',
  markerStroke: 'Marcador de carrera',
  markerPadding: 'Padding del marcador',
  itemSpacing: 'Espaciado de objetos',
  itemPaddingX: 'Padding de objetos en el eje X',
  itemPaddingY: 'Padding de objetos en el eje Y',
  layoutHorizontalSpacing: 'Espaciado horizontal',
  layoutVerticalSpacing: 'Espaciado vertical',
  strokeWidth: 'Anchura del trazo',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Información sobre herramientas',
  callout: 'Llamada',
  markers: 'Marcadores',
  shadow: 'Sombra',
  blur: 'Desenfoque',
  xOffset: 'Inclinación X',
  yOffset: 'Inclinación Y',
  lineWidth: 'Ancho de línea',
  normal: 'Normal',
  bold: 'Negrita',
  italic: 'Cursiva',
  boldItalic: 'Cursiva en negrita',
  predefined: 'Predefinido',
  fillOpacity: 'Opacidad de relleno',
  strokeOpacity: 'Opacidad de la línea',
  histogramBinCount: 'Recuento de contenedores',
  columnGroup: 'Columnas',
  barGroup: 'Barra',
  pieGroup: 'Pastel',
  lineGroup: 'Línea',
  scatterGroup: 'X Y (Dispersado)',
  areaGroup: 'Area',
  histogramGroup: 'Histograma',
  combinationGroup: 'Combinación',
  groupedColumnTooltip: 'Agrupado',
  stackedColumnTooltip: 'Apilado',
  normalizedColumnTooltip: '100% Apilado',
  groupedBarTooltip: 'Agrupado',
  stackedBarTooltip: 'Apilado',
  normalizedBarTooltip: '100% Apilado',
  pieTooltip: 'Pastel',
  doughnutTooltip: 'Donuts',
  lineTooltip: 'Lineal',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Apilado',
  normalizedAreaTooltip: '100% Apilado',
  scatterTooltip: 'Dispersado',
  bubbleTooltip: 'Burbuja',
  histogramTooltip: 'Histograma',
  columnLineComboTooltip: 'Columna y línea',
  areaColumnComboTooltip: 'Area y Columna',
  customComboTooltip: 'Combinación personalizada',
  noDataToChart: 'No hay datos para poder insertar',
  pivotChartRequiresPivotMode: 'El gráfico pivote requiere que el modo pivote esté activado.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Vinculado a Grid',
  chartUnlinkToolbarTooltip: 'No vinculado a Grid',
  chartDownloadToolbarTooltip: 'Descargar Grafico',
  seriesChartType: 'Tipo de gráfico de la serie',
  seriesType: 'Tipo de serie',
  secondaryAxis: 'Eje secundario',

  // ARIA
  ariaHidden: 'oculto',
  ariaVisible: 'visible',
  ariaChecked: 'comprobado',
  ariaUnchecked: 'sin comprobar',
  ariaIndeterminate:'Indeterminado',
  ariaDefaultListName: 'Lista',
  ariaColumnSelectAll: 'Alternar la selección de todas las columnas',
  ariaInputEditor: 'Editor de entradas',
  ariaDateFilterInput: 'Entrada del filtro de fechas',
  ariaFilterList: 'Lista de filtros',
  ariaFilterInput: 'Entrada del filtro',
  ariaFilterColumnsInput: 'Columnas de filtrado Entrada',
  ariaFilterValue: 'Valor del filtro',
  ariaFilterFromValue: 'Filtrar por valor',
  ariaFilterToValue: 'Filtrar a valor',
  ariaFilteringOperator: 'Operador de filtrado',
  ariaColumn: 'Columna',
  ariaColumnList: 'Lista de columnas',
  ariaColumnGroup: 'Grupo de columnas',
  ariaRowSelect: 'Pulsa espacio para seleccionar esta fila',
  ariaRowDeselect: 'Pulsa espacio para deseleccionar esta fila',
  ariaRowToggleSelection: 'Pulse la barra espaciadora para alternar la selección de filas',
  ariaRowSelectAll: 'Pulsar la barra espaciadora para activar la selección de todas las filas',
  ariaToggleVisibility: 'Pulse la barra espaciadora para cambiar la visibilidad',
  ariaSearch: 'Buscar',
  ariaSearchFilterValues: 'Valores del filtro de búsqueda',

  ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
  ariaValuesDropZonePanelLabel: 'Valores',
  ariaPivotDropZonePanelLabel: 'Etiquetas de las columnas',
  ariaDropZoneColumnComponentDescription: 'Pulsa DELETE para eliminar',
  ariaDropZoneColumnValueItemDescription: 'Pulse ENTER para cambiar el tipo de agregación',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Menú de columnas',
  ariaLabelCellEditor: 'Editor de celdas',
  ariaLabelDialog: 'Diálogo',
  ariaLabelSelectField: 'Seleccione el campo',
  ariaLabelTooltip: 'Información sobre herramientas',
  ariaLabelContextMenu: 'Menú de contexto',
  ariaLabelSubMenu: 'SubMenú',
  ariaLabelAggregationFunction: 'Función de agregación',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.'

}

export default AG_GRID_LOCALE_ES
