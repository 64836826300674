import React, { useState, useMemo, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  addDefaultRateToEmployee,
  deleteDefaultRate,
  updateDefaultRate,
} from "../../../../services/brigadier/workersCrud";
import errorHandler from "../../../../shared/errorHandler";
import displayFeedback from "../../../../components/helpers/displayFeedback";
import { useForm } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import displayFeedbackFromHeaders from "../../../../components/helpers/displayFeedbackFromHeaders";

export function TariffCustomRateFormForTariffGroup({
  formData,
  workerId,
  rate,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    control,
    watch,
  } = useForm({ mode: "onChange" });
  const value = watch("tariffCustomRate_tariffGroup_value");

  const tariffGroups = useSelector(
    (state) => state.brigadierCustomTariffRate.tariffGroups
  );
  const rateDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "RATE"
      )?.numericValue
  );
  const rateDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "RATE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });

  const [selectedTariffGroup, setSelectedTariffGroup] = useState();
  const [includeRate, setIncludeRate] = useState(true);

  useEffect(() => {
    if (rate) setIncludeRate(rate?.includeRate);
    else setIncludeRate(true);
  }, [rate, rate?.includeRate]);

  const availableTariffGroups = useMemo(() => {
    if (tariffGroups?.length > 0)
      return tariffGroups.map((tariffGroup, index) => ({
        ...tariffGroup,
        value: tariffGroup.tariffGroupId,
        label: tariffGroup.name,
      }));
  }, [tariffGroups]);

  async function handleUpdateDefaultRate() {
    setLoading(true);
    if (selectedTariffGroup) {
      const form = {
        tariffGroupId: selectedTariffGroup.tariffGroupId,
        value: getValues("tariffCustomRate_tariffGroup_value"),
        includeRate,
      };
      updateDefaultRate(rate.defaultRateId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type:
              "brigadier/customTariffRates/updateWorkerDefaultTariffGroupRate",
            payload: {
              data: response.data,
            },
          });
          setLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({
                id: errorOptions.message,
              })}`,
            });
          }
          setLoading(false);
        });
    }
  }

  async function handleDeleteDefaultRate() {
    setLoading(true);
    deleteDefaultRate(rate.defaultRateId)
      .then((response) => {
        displayFeedbackFromHeaders(response.headers);
        dispatch({
          type:
            "brigadier/customTariffRates/deleteWorkerDefaultTariffGroupRate",
          payload: {
            defaultRateId: rate.defaultRateId,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({
              id: errorOptions.message,
            })}`,
          });
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    if (
      tariffGroups?.length === 0 ||
      !tariffGroups?.find(
        (group) => group.tariffGroupId === selectedTariffGroup?.tariffGroupId
      )
    )
      setSelectedTariffGroup(null);
  }, [selectedTariffGroup?.tariffGroupId, tariffGroups]);

  const handleSaveButtonClick = () => {
    if (rate) handleUpdateDefaultRate();
  };

  const selectedTariffGroupDetails = useMemo(() => {
    if (
      selectedTariffGroup &&
      selectedTariffGroup?.tariffList?.length > 0 &&
      formData
    )
      return {
        tariffTypeValue: selectedTariffGroup.tariffList[0].tariffTypeValue,
        uomName: formData.tariffUom.find(
          (uom) => uom.id === selectedTariffGroup.tariffList[0].uomId
        )?.desc,
        currencyName: formData.currencies.find(
          (currency) =>
            currency.id === selectedTariffGroup.tariffList[0].currencyId
        )?.name,
      };
    else return undefined;
  }, [formData, selectedTariffGroup]);

  useEffect(() => {
    if (rate && availableTariffGroups?.length > 0) {
      setSelectedTariffGroup(
        availableTariffGroups.find(
          (tariffGroup) => tariffGroup.tariffGroupId === rate?.tariffGroupId
        )
      );
    }
  }, [availableTariffGroups, rate, rate?.tariffGroupId, setValue]);

  //const [valueLoaded, setValueLoaded] = useState();
  useEffect(() => {
    if (selectedTariffGroup && rate) {
      setValue("tariffCustomRate_tariffGroup_value", rate.value);
      //setValueLoaded(true);
    }
  }, [rate, rate?.value, selectedTariffGroup, setValue]);

  return (
    <>
      <div className="border rounded py-2 px-4 mb-4">
        <Form>
          <Form.Row>
            <Col xs={12} className="mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mr-4 my-0">
                  <FormattedMessage id="BRIGADIER.INDIVIDUAL_RATE_FOR_TARIFF_GROUP" />{" "}
                  {` #${rate.defaultRateId}`}
                </h6>
                {rate && (
                  <Button
                    onClick={handleDeleteDefaultRate}
                    variant="outline-danger"
                    size="sm"
                    disabled={loading}
                  >
                    <i
                      className="fa fa-trash pr-0"
                      style={{ fontSize: "1rem" }}
                    />
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={12} className="mb-4">
              <div className="d-flex">
                <Select
                  options={availableTariffGroups}
                  value={selectedTariffGroup}
                  onChange={setSelectedTariffGroup}
                  placeholder={`${intl.formatMessage({
                    id: "GENERAL.SELECT_TARIFF_GROUP",
                  })}...`}
                  className="w-100"
                />
              </div>
            </Col>
            {selectedTariffGroupDetails && (
              <Col xs={12} className="mb-2">
                <div className="d-flex">
                  <p className="mr-4 mb-0">
                    <FormattedMessage id="GENERAL.TARIFF_TYPE" />
                    {": "}
                    <FormattedMessage
                      id={`GENERAL.${selectedTariffGroupDetails.tariffTypeValue}`}
                    />
                  </p>
                  <p className="mr-4 mb-0">
                    <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" />
                    {": "}
                    {selectedTariffGroupDetails.uomName}
                  </p>
                  <p className="mr-4 mb-0">
                    <FormattedMessage id="GENERAL.CURRENCY" />
                    {": "}
                    {selectedTariffGroupDetails.currencyName}
                  </p>
                </div>
              </Col>
            )}
            {selectedTariffGroup && (
              <>
                <Col xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeRate}
                        onChange={() => setIncludeRate(!includeRate)}
                        color="secondary"
                      />
                    }
                    label={intl.formatMessage({
                      id: "BRIGADIER.INCLUDE_RATE",
                    })}
                  />
                </Col>
                <Col xs={12} className="d-flex align-items-end">
                  <div className="d-flex align-items-end flex-wrap flex-md-nowrap">
                    <Form.Group
                      style={{ width: "100%", maxWidth: "12rem" }}
                      className="mr-3"
                    >
                      <Form.Label>
                        <FormattedMessage id="BRIGADIER.RATE" /> *
                      </Form.Label>
                      <Form.Control
                        name="tariffCustomRate_tariffGroup_value"
                        type="number"
                        min={rateDecimalStep}
                        step={rateDecimalStep}
                        className={`${
                          errors.tariffCustomRate_tariffGroup_value
                            ? "is-invalid"
                            : ""
                        }`}
                        ref={register({
                          required: true,
                          min: rateDecimalStep,
                          validate: (value) => {
                            const split = value.toString().split(".");
                            if (split.length > 1) {
                              if (split[1].length > rateDecimalValue) return false;
                              else return true;
                            } else return true;
                          },
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      style={{ width: "100%", maxWidth: "8rem" }}
                      className="mr-3"
                    >
                      <Form.Label>
                        <FormattedMessage id="GENERAL.CURRENCY" />{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedTariffGroupDetails?.currencyName}
                        disabled
                      >
                        {formData && (
                          <>
                            {formData.currencies.map((currency) => (
                              <option
                                key={`tariffCustomRateForm-tariffGroup-currency-${currency.id}`}
                                value={currency.name}
                              >
                                {currency.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} className="mt-n5 mb-12 mb-md-5">
                  {errors.tariffCustomRate_tariffGroup_value &&
                    errors.tariffCustomRate_tariffGroup_value?.type ===
                      "min" && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                        <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                        {` ${rateDecimalStep}`}
                      </div>
                    )}
                  {errors.tariffCustomRate_tariffGroup_value &&
                    errors.tariffCustomRate_tariffGroup_value?.type ===
                      "validate" && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                        <FormattedMessage id={`ERROR.MAX_${rateDecimalValue}_DECIMAL_PLACES`} />
                      </div>
                    )}
                  {errors.tariffCustomRate_tariffGroup_value &&
                    errors.tariffCustomRate_tariffGroup_value?.type ===
                      "required" && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                        <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                      </div>
                    )}
                </Col>
              </>
            )}
            <Col xs={12} className="text-right">
              <Button
                onClick={handleSaveButtonClick}
                disabled={
                  loading ||
                  !selectedTariffGroup ||
                  selectedTariffGroup?.length === 0 ||
                  !value ||
                  Object.keys(errors).length !== 0
                }
                className="mb-4"
              >
                {rate && <FormattedMessage id="GENERAL.UPDATE" />}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </>
  );
}
