import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function PackagingForm({
  mode,
  formData,
  activePackaging,
  onCreatingPackaging,
  onUpdatingPackaging,
  openOrCloseModal,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
  } = useForm({ mode: "onChange" });
  const { touched, submitCount } = formState;
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    mode === "create" ? reset() : setFormParameters();
  }, [activePackaging, mode]);

  const onSubmit = (data) => {
    setLoading(true);
    mode === "create" ? createPackaging(data) : updatePackaging(data);
  };

  const setFormParameters = () => {
    setTimeout(() => {
      setValue("name", activePackaging.name);
      setValue("isActive", activePackaging.isActive);
      setValue("quantity", activePackaging.quantity);
      setValue("uomId", activePackaging.uom.id);
      setValue("desc", activePackaging.desc);
      setValue("weight", activePackaging.weight);
      triggerValidation();
    }, 50);
  };

  async function createPackaging(form) {
    const callback = await onCreatingPackaging(form);
    if (callback) {
      setLoading(false);
    }
  }

  async function updatePackaging(form) {
    const callback = await onUpdatingPackaging(activePackaging.id, form);
    if (callback) {
      setLoading(false);
    }
  }

  const onGeneratingLabels = () => {
    openOrCloseModal();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.NAME" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="name"
              type="text"
              className={`${
                (touched?.name && getValues("name").length === 0) ||
                (submitCount >= 1 && getValues("name")?.length === 0) ||
                errors.name ||
                (touched?.name && getValues("name").length >= 51) ||
                (submitCount >= 1 && getValues("name")?.length >= 51)
                  ? "is-invalid"
                  : ""
              } ${
                !errors.shortName && getValues("name")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.NAME",
              })}`}
              ref={register({ required: true, maxLength: 50 })}
            />
          </InputGroup>
          {(errors.name?.required ||
            (touched?.name && getValues("name").length === 0) ||
            (submitCount >= 1 && getValues("name")?.length === 0)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
          {(errors.name?.maxLength ||
            (touched?.name && getValues("name").length >= 51) ||
            (submitCount >= 1 && getValues("name")?.length >= 51)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_NAME_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.STATUS" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="isActive"
            ref={register({ required: true })}
          >
            {status.map((stat) => (
              <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                {(message) => <option value={stat.value}>{message}</option>}
              </FormattedMessage>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="BRIGADIER.CAPACITY" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="quantity"
              type="number"
              className={`${errors.quantity ? "is-invalid" : ""} ${
                !errors.quantity ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "BRIGADIER.CAPACITY",
              })}`}
              step="0.001"
              ref={register({ required: false, min: 0.001, max: 1000 })}
            />
          </InputGroup>
          {errors.quantity?.type === "min" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_001" />
            </div>
          )}
          {errors.quantity?.type === "max" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="uomId"
            ref={register({ required: true })}
          >
            {formData && (
              <>
                {formData.uom.map((unit) => (
                  <option key={`unit-${unit.id}`} value={unit.id}>
                    {unit.desc}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.WEIGHT_OF_PACKAGING" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="weight"
              type="number"
              className={`${errors.weight ? "is-invalid" : ""} ${
                !errors.weight ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.WEIGHT_OF_PACKAGING",
              })}`}
              step="0.01"
              ref={register({
                required: false,
                min: 0.01,
                validate: (value) => {
                  const split = value.toString().split(".");

                  if (split.length > 1) {
                    if (split[1].length > 2) return false;
                    else return true;
                  } else return true;
                },
              })}
            />
            <InputGroup.Append>
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {errors.weight?.type === "min" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_01" />
            </div>
          )}
          {errors.weight?.type === "validate" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.MAX_2_DECIMAL_PLACES" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DESCRIPTION" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="desc"
              as="textarea"
              rows="1"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.DESCRIPTION",
              })}`}
              ref={register({ required: false })}
            />
          </InputGroup>
        </Form.Group>
        <Col md={12} className="text-right">
          {mode === "update" && (
            <Button
              className="mr-2"
              onClick={() => onGeneratingLabels()}
              disabled={loading}
            >
              <FormattedMessage id="GENERAL.GENERATE_LABELS" />
            </Button>
          )}
          <Button
            type="submit"
            disabled={loading || Object.keys(errors).length !== 0}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
