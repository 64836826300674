import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";

export function OffersContractorOffersTable(props) {
  const [activeOffer, setActiveOffer] = useState();

  const onSelectingOffer = (buyOffer) => {
    if (activeOffer?.buyOfferId === buyOffer.buyOfferId) {
      setActiveOffer(null);
      props.onSelectingOffer(null);
    } else {
      setActiveOffer(buyOffer);
      props.onSelectingOffer(buyOffer);
    }
  };

  return (
    <Table className="mt-5" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.ID" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CONTRACTOR" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.WEIGHT" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.PRICE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.STATUS" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {props.offers.map((buyOffer, index) => (
          <tr
            key={buyOffer.buyOfferId}
            className={`text--not_selectable cursor--pointer ${
              activeOffer?.buyOfferId === buyOffer.buyOfferId
                ? "offer--active"
                : ""
            }`}
            onClick={() => onSelectingOffer(buyOffer)}
          >
            <td className="text-center">{buyOffer.buyOfferId}</td>
            <td className="text-center">
              {buyOffer?.sellOffer?.groOwner?.bpartnerFullName}
            </td>
            <td className="text-right">{buyOffer.primaryWeight}</td>
            <td className="text-right">{buyOffer.price}</td>
            <td className="text-center">
              {buyOffer.offerStatus === "W" && (
                <Badge pill variant="info">
                  <FormattedMessage id="GENERAL.WAITING" />
                </Badge>
              )}
              {buyOffer.offerStatus === "A" && (
                <Badge pill variant="success">
                  <FormattedMessage id="GENERAL.ACCEPTED" />
                </Badge>
              )}
              {buyOffer.offerStatus === "N" && (
                <Badge pill variant="warning">
                  <FormattedMessage id="GENERAL.NEGOTIATION" />
                </Badge>
              )}
              {buyOffer.offerStatus === "R" && (
                <Badge pill variant="danger">
                  <FormattedMessage id="GENERAL.REJECTED" />
                </Badge>
              )}
            </td>
            <td className="text-center">
              {buyOffer.offerStatus === "A" &&
                buyOffer.licenceId &&
                buyOffer.symbol === "P" && (
                  <Button
                    type="button"
                    variant="primary"
                    className="button--gw_active_background"
                  >
                    <Link
                      to={`/pulp-exchange/licence?id=${buyOffer.licenceId}`}
                      target="_blank"
                      className="button__link--primary"
                    >
                      <FormattedMessage id="GENERAL.PRINT_LICENSING" />
                    </Link>
                  </Button>
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

// <Button
//   type="button"
//   variant="primary"
//   className={`${
//     !buyOffer.licenceId || buyOffer.symbol !== "P"
//       ? "button--gw_disabled_background"
//       : "button--gw_active_background"
//   }`}
//   disabled={!buyOffer.licenceId || buyOffer.symbol !== "P"}
// >
//   {!buyOffer.licenceId || buyOffer.symbol !== "P" ? (
//     <FormattedMessage id="GENERAL.PRINT_LICENSING" />
//   ) : (
//     <Link
//       to={`/pulp-exchange/licence?id=${buyOffer.licenceId}`}
//       target="_blank"
//       className="button__link--primary"
//     >
//       <FormattedMessage id="GENERAL.PRINT_LICENSING" />
//     </Link>
//   )}
// </Button>
