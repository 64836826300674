import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CroppingWeatherForecast } from "./CroppingWeatherForecast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function CroppingShortcut({ data }) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Cropping.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.CROPPING" /> - {data.shortName}
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center mb-10">
              <div className="symbol symbol-40 symbol-light-primary mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-primary">
                    <SVG
                      className="h-75 align-self-end"
                      src={toAbsoluteUrl("/media/icons/Cropping.svg")}
                    ></SVG>
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <Link
                  to={`/farm/croppings?id=${data.croppingId}`}
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                 <FormattedMessage id={`${data.variety.sourceLink}`} />         
                </Link>
                <span className="text-muted">{data.variety.shortName}</span>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex align-items-center mb-10">
              <div className="symbol symbol-40 symbol-light-warning mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-warning">
                    ha
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <Link
                  to={`/farm/croppings?id=${data.croppingId}`}
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                >
                  <FormattedMessage id="GENERAL.ACREAGE" />
                </Link>
                <span className="text-muted">
                  {data.shapeArea
                    ? data.shapeArea.toFixed(2)
                    : data.areaDeclared.toFixed(2)}{" "}
                  ha
                </span>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <CroppingWeatherForecast weather={data.weather} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
