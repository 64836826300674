import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import displayFeedback from "../../../components/helpers/displayFeedback";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { getAllApplications } from "../../../services/applicationCrud";
import errorHandler from "../../../shared/errorHandler";

export function Step1() {
  return (
    <div>
      <h2 className="onboarding-card__h2 mb-8">
        <FormattedHTMLMessage id="ONBOARDING.STEP1.WELCOME_HTML" />
      </h2>
      <h6 className="mb-3">
        <FormattedMessage id="ONBOARDING.STEP1.FIRST_TIME" />
      </h6>
      <p>
        <FormattedMessage id="ONBOARDING.STEP1.TUTORIAL" />{" "}
        <Link to="/user/help">
          <FormattedMessage id="ONBOARDING.STEP1.START_LINK" />
        </Link>
      </p>
    </div>
  );
}

export function Step2() {
  return (
    <div>
      <h2 className="onboarding-card__h2 mb-8">
        <FormattedMessage id="ONBOARDING.STEP2.DIGITALIZE" />
      </h2>
      <h6 className="onboarding-card__h6 mb-20">
        <FormattedMessage id="ONBOARDING.STEP2.PLATFORM" />
      </h6>
      <p>
        <FormattedMessage id="ONBOARDING.STEP2.CHECK_APPS" />
        <br />
        <Link to="/user/subscriptions">
          <FormattedMessage id="ONBOARDING.STEP2.PANEL_LINK" />
        </Link>
      </p>
    </div>
  );
}

export function Step3() {
  return (
    <div>
      <h1 className="onboarding-card__h1 mb-8">
        <FormattedMessage id="ONBOARDING.STEP3.LETS_START" />
      </h1>
      <h6 className="onboarding-card__h6 mb-10">
        <FormattedMessage id="ONBOARDING.STEP3.START_WORK" />
      </h6>
      <h6 className="onboarding-card__h6 mb-10">
        <Link to="/farm/croppings">
          <FormattedMessage id="ONBOARDING.STEP3.DEFINE_CROP" />
        </Link>
      </h6>
      <p>
        <FormattedMessage id="ONBOARDING.STEP3.FEEDBACK_MAIL" />
        <br />
        <a
          href="mailto:hello@cropchart.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          hello@cropchart.net
        </a>
      </p>
    </div>
  );
}

export function Step4() {
  const [applications, setApplications] = useState();
  const intl = useIntl();
  const getApplications = () => {
    getAllApplications()
      .then((response) => {
        setApplications(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };
  useEffectOnce(getApplications);
  return (
    <>
      <div>
        <h2 className="onboarding-card__h2 mb-8">
          <FormattedMessage id="ONBOARDING.STEP4.SUBSCRIBE" />
        </h2>
        <h6 className="onboarding-card__h6 mb-20">
          <FormattedMessage id="ONBOARDING.STEP4.APP" />
        </h6>
        <h6 className="onboarding-card__h6 mb-10">
          <Link
            to={`/user/subscriptions/buy/${
              applications?.find((app) => app.name === "CropManager")
                ?.applicationId
            }`}
          >
            <FormattedMessage id="ONBOARDING.STEP4.FIND_PLAN" />
          </Link>
        </h6>
      </div>
    </>
  );
}

export default function Onboarding() {
  const [step, setStep] = useState(1);

  return (
    <div
      className="card card-custom gutter-b mt-lg-8 onboarding-card"
      style={{ backgroundImage: "url('media/onboarding/Bg.svg')" }}
    >
      <div className="card-body p-4">
        <Row>
          <Col sm={6} className="p-16">
            <div className="d-flex flex-column justify-content-between h-100">
              {step === 1 && <Step1 />}
              {step === 2 && <Step2 />}
              {step === 3 && <Step3 />}
              {step === 4 && <Step4 />}
              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0">
                  <strong>{step}</strong>{" "}
                  <span className="opacity-75">
                    <span className="mx-4">&bull;</span> 4
                  </span>
                </p>
                <div>
                  <button
                    className={`border-0 bg-white mr-4 onboarding-card__arrow-button ${
                      step > 1 ? "" : "opacity-0"
                    }`}
                    onClick={() => setStep(step - 1)}
                    disabled={!(step > 1)}
                  >
                    <InlineSVG
                      src={toAbsoluteUrl(`/media/onboarding/arrow-left.svg`)}
                    />
                  </button>
                  <button
                    className={`border-0 bg-white onboarding-card__arrow-button ${
                      step < 4 ? "" : "opacity-0"
                    }`}
                    onClick={() => setStep(step + 1)}
                    disabled={!(step < 4)}
                  >
                    <InlineSVG
                      src={toAbsoluteUrl(`/media/onboarding/arrow-right.svg`)}
                    />
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} style={{ minHeight: "439px" }}>
            <InlineSVG
              src={toAbsoluteUrl(`/media/onboarding/rightSide${step}.svg`)}
              className="w-100 h-auto d-block"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
