import axios from "axios";

export const PAYMENTS_URL = `${process.env.REACT_APP_URL}payment`;
export const DISCOUNT_URL = `${process.env.REACT_APP_URL}discountCodes`;

export function getSubscriptionPriceList() {
  return axios.get(`${PAYMENTS_URL}/currency/1/prices`);
}

export function registerPaymentToPrzelewy24(data) {
  return axios.post(`${PAYMENTS_URL}/przelewy24/register`, data);
}

export function getPaymentDetails(paymentId) {
  return axios.get(`${PAYMENTS_URL}/${paymentId}/details`)
}

export function getAllPayments() {
  return axios.get(`${PAYMENTS_URL}/all?from=2021-01-01&to=${new Date().toISOString().slice(0,10)}`)
}

export function checkDiscountCode(code, appSymbol) {
  return axios.get(`${DISCOUNT_URL}/${code}/check?application=${appSymbol}`)
}
