import React from "react";

export function FarmRow({ farm, handleClick }) {
  return (
    <tr
      className={`text-center cursor--pointer ${
        farm.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => handleClick(farm.bpartnerId)}
    >
      <td>{farm.fullName}</td>
      <td>{farm.number}</td>
    </tr>
  );
}
