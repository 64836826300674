import moment from "moment";
import store from "../../../../redux/store";
import displayFeedback from "../../../components/helpers/displayFeedback";
import getIntl from "../../../components/helpers/getIntl";
import { getTreatment } from "../../../services/treatmentsCrud";
import errorHandler from "../../../shared/errorHandler";

export default function getTreatmentDetails(treatmentHeaderId) {
  const intl = getIntl()
  const croppings = store.getState().calendar.croppings

  return new Promise((resolve) => {
    getTreatment(treatmentHeaderId)
      .then((response) => {
        resolve("OK");
        store.dispatch({
          type: "calendar/setFormDates",
          payload: [
            moment(response.data.dateStart).format("YYYY-MM-DD"),
            moment(response.data.dateEnd).format("YYYY-MM-DD"),
          ],
        });
        const searchedCropping = [...croppings].find((cropping) => {
          return (
            cropping.shape.parent.shapeId ===
            response.data.croppingShapeIds[0]
          );
        });
        if (searchedCropping) {
          store.dispatch({
            type: "calendar/setTreatmentHeadId",
            payload: treatmentHeaderId,
          });
          store.dispatch({
            type: "calendar/setCroppingShape",
            payload: searchedCropping.shape.children,
          });
        }
        store.dispatch({
          type: "calendar/setTreatmentDetails",
          payload: response.data,
        });
        store.dispatch({
          type: "calendar/setUpdateModalOpen",
          payload: true,
        });
      })
      .catch((error) => {
        resolve("error");
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  });
}
