import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GoogleMap,
  Marker,
  DrawingManager,
  Polygon,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { DeleteFieldOrCroppingShapeModal } from "../../../components/DeleteFieldOrCroppingShapeModal";

const googleMapStyle = {
  width: "100%",
  height: "400px",
};

const drawingManagerOptions = {
  drawingControl: true,
  drawingControlOptions: {
    drawingModes: ["polygon"],
  },
  polygonOptions: {
    fillColor: "#b8cf67",
    fillOpacity: "0.45",
    strokeColor: "#b8cf67",
    strokeWeight: "3",
    editable: true,
  },
};

const parentPolygonOptions = {
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const childrenPolygonOptions = {
  visible: false,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

let temporaryGlobalChildrens;

export function FieldsMap(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [googleMap, setGoogleMap] = useState();
  const [searchbox, setSearchbox] = useState();
  const [drawingMode, setDrawingMode] = useState("polygon");
  const [drawingManager, setDrawingManager] = useState();
  const [selectedShape, setSelectedShape] = useState();
  const [fieldExist, setFieldExist] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [parentPaths, setParentPaths] = useState();
  const [parentPolygon, setParentPolygon] = useState();
  const [mode, setMode] = useState("add");
  const [canRestore, setCanRestore] = useState(false);
  const [temporaryChildrenPolygons, setTemporaryChildrenPolygons] = useState(
    []
  );
  const [childrenPaths, setChildrenPaths] = useState();
  const [childrenPolygons, setChildrenPolygons] = useState([]);
  const [shapeMap, setShapeMap] = useState();
  const [centerPoint, setCenterPoint] = useState({ lat: 52, lng: 20 });
  const [zoom, setZoom] = useState(6);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marker, setMarker] = useState();
  const [markerShape, setMarkerShape] = useState();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION,
  });

  useEffect(() => {
    if (props.fieldShape) {
      setCentroid(props.centroid);
    } else {
      setCentroid(null);
    }
  }, [props.centroid, props.fieldLongestLine]);

  useEffect(() => {
    if (props.fieldShape) {
      onResettingMap();
      setTimeout(() => {
        onCreatingPolygons();
      });
    } else {
      onResettingMap();
    }
  }, [props.fieldShape, props.fieldData]);

  useEffect(() => {
    props.fieldMarker
      ? setTimeout(() => {
          setMarker(props.fieldMarker);
        })
      : setMarker(null);
  }, [props.fieldMarker]);

  const setCentroid = (coordinates) => {
    if (props.centroid) {
      setCenterPoint({ lat: props.centroid?.lng, lng: props.centroid?.lat });
      setTimeout(() => {
        const latLngList = [];
        if (props.fieldLongestLine) {
          props.fieldLongestLine.forEach((coordinate) => {
            latLngList.push(
              new window.google.maps.LatLng(coordinate[1], coordinate[0])
            );
          });
          let bounds = new window.google.maps.LatLngBounds();
          latLngList.forEach((row) => {
            bounds.extend(row);
          });
          if (googleMap) {
            googleMap.setCenter(bounds.getCenter());
            googleMap.fitBounds(bounds);
            googleMap.setZoom(googleMap.getZoom() - 1);
          }
        }
      });
    } else if (
      coordinates === null &&
      (centerPoint.lat !== 52 || centerPoint.lng !== 20)
    ) {
      setCentroid({ lat: 52, lng: 20 });
      setZoom(6);
    }
  };

  const onResettingMap = () => {
    if (selectedShape) {
      handleDelete();
    }
    if (props.fieldShape) {
      setFieldExist(true);
      setMode("manage");
      setTimeout(() => {
        if (drawingManager) {
          drawingManager.setDrawingMode(null);
          drawingManager.setOptions({
            drawingControl: false,
          });
        }
      });
    } else {
      setFieldExist(false);
      setMode("add");
      if (drawingManager) {
        setTimeout(() => {
          if (drawingManager) {
            drawingManager.setDrawingMode("polygon");
            drawingManager.setOptions({
              drawingControl: true,
            });
          }
        });
      }
    }
    if (markerShape) {
      markerShape.setMap(null);
      setMarkerShape();
      setMarker(null);
    }
    setCanSave(false);
    setCanDelete(false);
    setParentPaths(false);
    if (parentPolygon) {
      parentPolygon.setMap(null);
      setParentPolygon();
    }
    setCanRestore(false);
    if (temporaryChildrenPolygons.length) {
      temporaryChildrenPolygons.forEach((temporaryChildPolygon) => {
        temporaryChildPolygon.setMap(null);
      });
    }
    setTemporaryChildrenPolygons([]);
    setChildrenPolygons([]);
  };

  const onCreatingPolygons = () => {
    let fullParentCoordinates = [];
    let outerCoordinates = [];
    let childrenCoordinates = [];
    props.fieldShape.parent.shape.coordinates.forEach((row) => {
      row.forEach((coordinate) => {
        outerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
      });
    });
    fullParentCoordinates.push(outerCoordinates);
    if (props.fieldShape.children.length) {
      props.fieldShape.children.forEach((child) => {
        const innerCoordinates = [];
        child.shape.coordinates.forEach((row) => {
          row.forEach((coordinate) => {
            innerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
          });
        });
        fullParentCoordinates.push(innerCoordinates);
        childrenCoordinates.push(innerCoordinates);
      });
    }
    setParentPaths(fullParentCoordinates);
    setChildrenPaths(childrenCoordinates);
  };

  const onSearchBoxLoad = (searcher) => {
    setSearchbox(searcher);
  };

  const onPlacesChanged = () => {
    const searchedPlace = searchbox.getPlaces()[0];
    setCenterPoint({
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    });
  };

  const onDrawingManagerLoad = (drawMan) => {
    setDrawingManager(drawMan);
  };

  const onOverlayComplete = (newOverlay) => {
    if (mode === "add" || mode === "exclude") {
      let newShape = newOverlay.overlay;
      newShape.type = newOverlay.type;
      if (mode === "exclude") {
        window.google.maps.event.addListener(newShape, "click", function() {
          onSettingSelection(newShape);
        });
      }
      if (!fieldExist) {
        onSettingSelection(newShape);
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
      } else {
        onSettingSelection(newShape);
        let newTemporaryChildrenPolygons = [...temporaryChildrenPolygons];
        newTemporaryChildrenPolygons.push(newShape);
        setTemporaryChildrenPolygons(newTemporaryChildrenPolygons);
        temporaryGlobalChildrens = newTemporaryChildrenPolygons;
        selectNewPolygonAndUnselectOldPolygons(
          newShape,
          temporaryGlobalChildrens
        );
      }
      setCanDelete(true);
      setCanSave(true);
    } else newOverlay.overlay.setMap(null);
  };

  const onSettingSelection = (shape) => {
    onClearingSelection();
    setSelectedShape(shape);
    if (mode === "exclude") {
      shape.setEditable(true);
      setCanDelete(true);
      selectNewPolygonAndUnselectOldPolygons(shape, temporaryGlobalChildrens);
    }
  };

  const onClearingSelection = () => {
    if (selectedShape) {
      if (mode !== "exclude") {
        selectedShape.setEditable(false);
      }
      setSelectedShape(null);
    }
  };

  const selectNewPolygonAndUnselectOldPolygons = (
    shape,
    newlyCreatedChildrenPolygons
  ) => {
    if (childrenPolygons.length) {
      childrenPolygons.forEach((polygon) => {
        if (polygon.fillColor === "#990099") {
          polygon.setOptions({
            fillColor: "#FF00FF",
            strokeColor: "#FF00FF",
          });
        }
      });
    }
    if (typeof newlyCreatedChildrenPolygons !== "undefined") {
      if (newlyCreatedChildrenPolygons.length) {
        newlyCreatedChildrenPolygons.forEach((polygon) => {
          if (polygon.fillColor === "#990099") {
            polygon.setOptions({
              fillColor: "#FF00FF",
              strokeColor: "#FF00FF",
            });
          }
        });
      }
    }
    shape.setOptions({
      fillColor: "#990099",
      strokeColor: "#990099",
    });
  };

  const onParentPolygonLoad = (polygon) => {
    setParentPolygon(polygon);
  };

  const calculateAcreage = (operationType) => {
    let calculatedAcreage = 0;
    if (operationType === "create") {
      calculatedAcreage += parseFloat(
        (
          window.google.maps.geometry.spherical.computeArea(
            selectedShape.getPath()
          ) / 10000
        ).toFixed(2)
      );
    } else {
      calculatedAcreage += parseFloat(
        (
          window.google.maps.geometry.spherical.computeArea(
            parentPolygon.getPath()
          ) / 10000
        ).toFixed(2)
      );
      let childrenAccreage = 0;
      if (childrenPolygons.length) {
        childrenPolygons.forEach((child) => {
          if (child.map !== null) {
            childrenAccreage += window.google.maps.geometry.spherical.computeArea(
              child.getPath()
            );
          }
        });
      }
      if (temporaryChildrenPolygons.length) {
        temporaryChildrenPolygons.forEach((child) => {
          if (child.map !== null) {
            childrenAccreage += window.google.maps.geometry.spherical.computeArea(
              child.getPath()
            );
          }
        });
      }
      childrenAccreage = parseFloat((childrenAccreage / 10000).toFixed(2));
      calculatedAcreage = calculatedAcreage - childrenAccreage;
    }
    return calculatedAcreage;
  };

  const onChildPolygonLoad = (polygon) => {
    let newChildrenPolygons = childrenPolygons;
    newChildrenPolygons.push(polygon);
    setChildrenPolygons(newChildrenPolygons);
  };

  const onSelectingChildPolygon = (index) => {
    if (selectedShape) {
      selectedShape.setOptions({
        fillColor: "#FF00FF",
        strokeColor: "#FF00FF",
      });
    }
    setSelectedShape(childrenPolygons[index]);
    selectNewPolygonAndUnselectOldPolygons(
      childrenPolygons[index],
      temporaryChildrenPolygons
    );
    setCanDelete(true);
  };

  const onPolygonModification = () => {
    setCanSave(true);
    setCanRestore(true);
  };

  async function handleSave() {
    setLoading(true);
    const form = {
      parent: {
        type: "Polygon",
        coordinates: [
          selectedShape
            .getPath()
            .getArray()
            .map((p) => {
              return [p.lng(), p.lat()];
            }),
        ],
      },
      shapeArea: calculateAcreage("create"),
    };
    const callback = await props.onCreatingPolygon(form);
    if (callback) {
      setLoading(false);
    }
  }

  const handleDelete = () => {
    setLoading(true);
    if (selectedShape) {
      setShapeMap(selectedShape.getMap());
      selectedShape.setMap(null);
      if (!fieldExist) {
        setSelectedShape(null);
        drawingManager.setDrawingMode("polygon");
        drawingManager.setOptions({
          drawingControl: true,
        });
        setCanSave(false);
      }
      if (mode === "exclude") {
        setCanSave(true);
      }
      setCanDelete(false);
      setLoading(false);
    }
  };

  const handleChangeMode = (modeToSet) => {
    setMode(modeToSet);
    if (modeToSet === "update") {
      prepareParentForModification(modeToSet);
    } else {
      prepareParentForModification(modeToSet);
      prepareChildrenForModification();
    }
  };

  const prepareParentForModification = (modeToSet) => {
    let fullParentCoordinates = [];
    let outerCoordinates = [];
    props.fieldShape.parent.shape.coordinates.forEach((row) => {
      row.forEach((coordinate) => {
        outerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
      });
    });
    fullParentCoordinates.push(outerCoordinates);
    setParentPaths(fullParentCoordinates);
    if (modeToSet === "update") {
      setSelectedShape(parentPolygon);
      parentPolygon.setOptions({
        fillColor: "#b8cf67",
        fillOpacity: "0.45",
        strokeColor: "#b8cf67",
        strokeWeight: "3",
        editable: true,
      });
      setTimeout(() => {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
      });
    } else {
      parentPolygon.setOptions({
        fillColor: "#b8cf67",
        fillOpacity: "0.45",
        strokeColor: "#b8cf67",
        strokeWeight: "3",
        editable: false,
      });
    }
  };

  const prepareChildrenForModification = () => {
    if (childrenPolygons.length) {
      childrenPolygons.forEach((childPolygon) => {
        childPolygon.setOptions({
          fillColor: "#FF00FF",
          fillOpacity: "0.45",
          strokeColor: "#FF00FF",
          strokeWeight: "3",
          clickable: true,
          editable: true,
          visible: true,
        });
      });
    }
    setTimeout(() => {
      drawingManager.setDrawingMode("polygon");
      drawingManager.setOptions({
        drawingControl: true,
        polygonOptions: {
          fillColor: "#FF00FF",
          fillOpacity: "0.45",
          strokeColor: "#FF00FF",
          strokeWeight: "3",
          editable: false,
          zIndex: 2,
        },
      });
    });
  };

  async function handleRemoveField() {
    setLoading(true);
    const form = { children: null, parent: null };
    const callback = await props.onUpdatingPolygon(form);
    if (callback) {
      setLoading(false);
    }
  }

  async function handleUpdate() {
    setLoading(true);
    const parentGeoJSON = {
      type: "Polygon",
      coordinates: [
        parentPolygon
          .getPath()
          .getArray()
          .map((p) => {
            return [p.lng(), p.lat()];
          }),
      ],
    };
    let newChildren = [];
    if (childrenPolygons.length) {
      childrenPolygons.forEach((childPolygon) => {
        if (childPolygon.map !== null) {
          newChildren.push({
            shape: {
              type: "Polygon",
              coordinates: [
                childPolygon
                  .getPath()
                  .getArray()
                  .map((p) => {
                    return [p.lng(), p.lat()];
                  }),
              ],
            },
          });
        }
      });
    }
    if (temporaryChildrenPolygons.length) {
      temporaryChildrenPolygons.forEach((temporaryChildPolygon) => {
        if (temporaryChildPolygon.map !== null) {
          newChildren.push({
            shape: {
              type: "Polygon",
              coordinates: [
                temporaryChildPolygon
                  .getPath()
                  .getArray()
                  .map((p) => {
                    return [p.lng(), p.lat()];
                  }),
              ],
            },
          });
        }
      });
    }
    const form = {
      children: newChildren,
      parent: parentGeoJSON,
      shapeArea: calculateAcreage("update"),
    };
    const callback = await props.onUpdatingPolygon(form);
    if (callback) {
      setLoading(false);
    }
  }

  const handleRestore = () => {
    setLoading(true);
    if (mode === "update") {
      setParentPaths(null);
      let fullParentCoordinates = [];
      let outerCoordinates = [];
      props.fieldShape.parent.shape.coordinates.forEach((row) => {
        row.forEach((coordinate) => {
          outerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
        });
      });
      fullParentCoordinates.push(outerCoordinates);
      setParentPaths(fullParentCoordinates);
    } else {
      if (childrenPolygons.length) {
        childrenPolygons.forEach((childPolygon) => {
          if (childPolygon.map === null) {
            childPolygon.setMap(shapeMap);
          }
        });
      }
      let childrenCoordinates = [];
      if (props.fieldShape.children.length) {
        props.fieldShape.children.forEach((child) => {
          const innerCoordinates = [];
          child.shape.coordinates.forEach((row) => {
            row.forEach((coordinate) => {
              innerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
            });
          });
          childrenCoordinates.push(innerCoordinates);
        });
      }
      setChildrenPaths(childrenCoordinates);
    }
    deselectFigure();
    setCanSave(false);
    setCanRestore(false);
    setLoading(false);
  };

  const handleCancel = () => {
    if (canSave) {
      handleRestore();
    }
    parentPolygon.setOptions({
      fillColor: "#000",
      fillOpacity: "0.45",
      strokeColor: "#000",
      strokeWeight: "3",
      editable: false,
    });
    if (mode === "exclude") {
      temporaryChildrenPolygons.forEach((temporaryChildPolygon) => {
        temporaryChildPolygon.setMap(null);
      });
      setTemporaryChildrenPolygons([]);
      if (childrenPolygons.length) {
        childrenPolygons.forEach((childPolygon) => {
          childPolygon.setOptions({
            fillColor: "#FF00FF",
            fillOpacity: "0.45",
            strokeColor: "#FF00FF",
            strokeWeight: "3",
            clickable: false,
            editable: false,
            visible: false,
          });
        });
      }
    }
    setParentPaths(null);
    let fullParentCoordinates = [];
    let outerCoordinates = [];
    props.fieldShape.parent.shape.coordinates.forEach((row) => {
      row.forEach((coordinate) => {
        outerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
      });
    });
    fullParentCoordinates.push(outerCoordinates);
    if (props.fieldShape.children.length) {
      props.fieldShape.children.forEach((child) => {
        const innerCoordinates = [];
        child.shape.coordinates.forEach((row) => {
          row.forEach((coordinate) => {
            innerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
          });
        });
        fullParentCoordinates.push(innerCoordinates);
      });
    }
    setTimeout(() => {
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: false,
      });
    });
    setCanSave(false);
    setCanDelete(false);
    setCanRestore(false);
    setParentPaths(fullParentCoordinates);
    setSelectedShape(null);
    setMode("manage");
  };

  const onOpeningOrClosingModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onMarkerLoad = (marker) => {
    setMarkerShape(marker);
  };

  const deselectFigure = () => {
    selectedShape.setOptions({
      fillColor: "#FF00FF",
      fillOpacity: "0.45",
      strokeColor: "#FF00FF",
      strokeWeight: "3",
    });
    setCanDelete(false);
    setSelectedShape(null);
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      setGoogleMap(mapInstance);
      mapInstance.setOptions({ mapTypeControl: false });
    }
    return (
      <GoogleMap
        mapContainerStyle={googleMapStyle}
        center={centerPoint}
        zoom={zoom}
        mapTypeId={"hybrid"}
        onLoad={onLoad}
      >
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.SEARCH",
            })}...`}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        {!parentPaths && marker && (
          <Marker onLoad={onMarkerLoad} position={marker.position} />
        )}
        <DrawingManager
          drawingMode={drawingMode}
          options={drawingManagerOptions}
          onLoad={onDrawingManagerLoad}
          onOverlayComplete={onOverlayComplete}
        />
        {parentPaths && (
          <>
            <Polygon
              paths={parentPaths}
              options={parentPolygonOptions}
              onLoad={onParentPolygonLoad}
              onMouseUp={onPolygonModification}
            />
            {childrenPaths && (
              <>
                {childrenPaths.map((child, index) => (
                  <Polygon
                    key={index}
                    paths={childrenPaths[index]}
                    options={childrenPolygonOptions}
                    onLoad={onChildPolygonLoad}
                    onClick={() => onSelectingChildPolygon(index)}
                    onMouseUp={onPolygonModification}
                  />
                ))}
              </>
            )}
          </>
        )}
      </GoogleMap>
    );
  };

  return (
    <div className="card card-custom gutter-b">
      <DeleteFieldOrCroppingShapeModal
        open={isModalOpen}
        close={onOpeningOrClosingModal}
        screen={"fields"}
        onRemovingFieldOrCropping={handleRemoveField}
      />
      <div className="card-header">
        <div className="card-title">
          <span className="card-icon">
            <i className="flaticon-map-location"></i>
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.MAP" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Row>
          <Col sm={4} className="mb-5 mb-sm-0">
            {!fieldExist && (
              <>
                <Button
                  style={{ width: "100%" }}
                  onClick={handleSave}
                  disabled={loading || !canSave}
                >
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
                <Button
                  variant="secondary"
                  style={{ width: "100%" }}
                  className="mt-5"
                  onClick={handleDelete}
                  disabled={loading || !canDelete}
                >
                  <FormattedMessage id="GENERAL.DELETE" />
                </Button>
              </>
            )}
            {fieldExist && (
              <>
                {mode === "manage" && (
                  <>
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => handleChangeMode("update")}
                    >
                      <FormattedMessage id="GENERAL.MODIFY_FIELD" />
                    </Button>
                    <Button
                      style={{ width: "100%" }}
                      className="mt-5"
                      onClick={() => handleChangeMode("exclude")}
                    >
                      <FormattedMessage id="GENERAL.DRAW_EXCLUSIONS" />
                    </Button>
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      className="mt-5"
                      onClick={onOpeningOrClosingModal}
                    >
                      <FormattedMessage id="GENERAL.DELETE" />
                    </Button>
                  </>
                )}
                {(mode === "update" ||
                  mode === "exclude" ||
                  mode === "add") && (
                  <>
                    <Button
                      style={{ width: "100%" }}
                      onClick={handleUpdate}
                      disabled={loading || !canSave}
                    >
                      <FormattedMessage id="GENERAL.SAVE" />
                    </Button>
                    <Button
                      style={{ width: "100%" }}
                      className="mt-5"
                      onClick={handleRestore}
                      disabled={loading || !canRestore}
                    >
                      <FormattedMessage id="GENERAL.RESTORE" />
                    </Button>
                    {mode === "exclude" && (
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        className="mt-5"
                        disabled={loading || !canDelete}
                        onClick={handleDelete}
                      >
                        <FormattedMessage id="GENERAL.DELETE" />
                      </Button>
                    )}
                    <Button
                      variant="secondary"
                      style={{ width: "100%" }}
                      className="mt-5"
                      onClick={handleCancel}
                    >
                      <FormattedMessage id="GENERAL.CANCEL" />
                    </Button>
                  </>
                )}
              </>
            )}
          </Col>
          <Col sm={8}>{isLoaded ? renderMap() : null}</Col>
        </Row>
      </div>
    </div>
  );
}

// https://stackoverflow.com/questions/25072069/export-geojson-data-from-google-maps
// https://stackoverflow.com/questions/14166546/google-maps-drawing-manager-limit-to-1-polygon
// NAJWAŻNIEJSZE
// https://react-google-maps-api-docs.netlify.app/#googlemap
// https://stackoverflow.com/questions/61208272/get-details-of-all-the-markers-covered-under-a-drawn-polygon-or-any-shape-using
// https://stackoverflow.com/questions/17264989/how-to-manually-reload-google-map-with-javascript
// https://simplemaps.com/resources/shapes-google-maps
