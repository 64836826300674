import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import SuppliersSelect from "../../../components/SuppliersSelect";
import getSuppliersToRedux from "../../../components/helpers/getSuppliersToRedux";
import ReactSelect from "react-select";
import { getSpeciesDetails } from "../../../services/speciesCrud";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function FieldsGeneralData(props) {
  const intl = useIntl();
  const {
    register,
    setValue,
    getValues,
    errors,
    formState,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const { touched, submitCount } = formState;
  const [irrigated, setIrrigated] = useState(false);
  const [meliorated, setMeliorated] = useState(false);
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectedSupplier = useSelector(
    (state) => state.suppliers.selectedSupplier
  );
  const setSelectedSupplier = useCallback(
    (data) => {
      dispatch({
        type: "suppliers/setSelectedSupplier",
        payload: data,
      });
    },
    [dispatch]
  );

  const speciesList = useSelector((state) => state.fields.species);
  const [speciesIndex, setSpeciesIndex] = useState();
  const onChangeSelectedSpecies = useCallback(
    (option) => {
      if (option) {
        const index = speciesList.findIndex((species) => {
          return species.value === option.value;
        });
        if (index > -1) {
          setSpeciesIndex(index);
          setVarietyList([]);
          setVarietyIndex(null);
        }
      } else {
        setSpeciesIndex(null);
        setVarietyList([]);
        setVarietyIndex(null);
      }
    },
    [speciesList]
  );
  const selectedSpeciesId = useMemo(() => {
    if (speciesIndex != null) return speciesList[speciesIndex].value;
    return null;
  }, [speciesIndex, speciesList]);
  useEffect(() => {
    if (props.mode === "update" && speciesList.length > 0) {
      if (props.field?.species?.id)
        onChangeSelectedSpecies({ value: props.field.species.id });
      else setSpeciesIndex(null);
    } else if (props.mode === "create") setSpeciesIndex(null);
  }, [onChangeSelectedSpecies, props.field, props.mode, speciesList.length]);

  const [varietyList, setVarietyList] = useState([]);
  const [varietyIndex, setVarietyIndex] = useState();
  const [varietySet, setVarietySet] = useState(false);
  const [varietyLoading, setVarietyLoading] = useState(false);
  const selectedVarietyId = useMemo(() => {
    if (varietyIndex != null) return varietyList[varietyIndex].value;
    return null;
  }, [varietyIndex, varietyList]);
  const onChangeSelectedVariety = useCallback(
    (option) => {
      if (option) {
        const index = varietyList.findIndex((variety) => {
          return variety.value === option.value;
        });
        if (index > -1) setVarietyIndex(index);
      } else setVarietyIndex(null);
    },
    [varietyList]
  );
  useEffect(() => {
    if (
      props.mode === "update" &&
      props.field &&
      varietyList.length > 0 &&
      !varietySet
    ) {
      onChangeSelectedVariety({ value: props.field.variety?.varietyId });
      setVarietySet(true);
    }
  }, [
    onChangeSelectedVariety,
    props.field,
    props.mode,
    varietyList.length,
    varietySet,
  ]);

  useEffect(() => {
    if (speciesIndex != null && varietyList.length === 0) {
      setVarietyIndex(null);
      setVarietyLoading(true);
      getSpeciesDetails(speciesList[speciesIndex].value)
        .then((response) => {
          setVarietyList(
            response.data?.varietyList?.map((variety) => ({
              value: variety.varietyId,
              label: variety.desc,
            }))
          );
          setVarietyLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          setVarietyLoading(false);
        });
    }
  }, [intl, speciesIndex, speciesList, varietyList.length]);

  useEffect(() => {
    if (props.field) {
      setIrrigated(props.field.isIrrigated);
      setMeliorated(props.field.isMeliorated);
      setValue("shortName", props.field.shortName);
      setValue("isActive", props.field.isActive);
      setValue("acreage", props.field.acreage);
      setValue(
        "shapeArea",
        props.field.shapeArea
          ? props.field.shapeArea.toFixed(2)
          : props.field.shapeArea
      );
      setValue("desc", props.field.desc);
      setVarietyList([]);
      setVarietyIndex(null);
      setVarietySet(false);
      if (props.field.supplier)
        setSelectedSupplier({
          value: props.field.supplier.fullName,
          label: props.field.supplier.fullName,
        });
      else setSelectedSupplier(null);
    }
  }, [
    onChangeSelectedSpecies,
    props.field,
    setSelectedSupplier,
    setValue,
    speciesList.length,
  ]);

  useEffect(() => {
    if (props.mode === "create") {
      reset();
      setIrrigated(false);
      setMeliorated(false);
      setSelectedSupplier(null);
      setSpeciesIndex(null);
      setVarietyList([]);
      setVarietyIndex(null);
      setVarietySet(false);
    }
  }, [props.mode]);

  async function onSubmit(data) {
    const form = data;
    form.isIrrigated = irrigated;
    form.isMeliorated = meliorated;
    form.speciesId = selectedSpeciesId;
    form.varietyId = selectedVarietyId;
    if (selectedSupplier) form.supplier = selectedSupplier.label;
    setLoading(true);
    let callback;
    if (props.mode === "create") {
      callback = await props.createField(form);
    } else {
      callback = await props.updateField(form);
      setLoading(false);
    }
    if (callback) {
      setLoading(false);
    }
    getSuppliersToRedux();
  }

  const speciesFieldsBlocked = useMemo(() => {
    return props.mode === "update" && props.field?.assignedCroppings?.length > 0;
  }, [props.field?.assignedCroppings?.length, props.mode]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            {props.mode === "create" ? (
              <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
            ) : (
              <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
            )}
          </span>
          <h3 className="card-label">
            {props.mode === "create" ? (
              <FormattedMessage id="GENERAL.ADD_FIELD" />
            ) : (
              <>
                <FormattedMessage id="GENERAL.FIELD" /> -{" "}
                {props.field?.shortName}
              </>
            )}
          </h3>
        </div>
        <div className="card-toolbar">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.IRRIGATION" />
                </Tooltip>
              }
            >
              <Button
                variant={`${irrigated ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setIrrigated(!irrigated);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                active={irrigated}
                className="mr-4"
              >
                <i
                  className="flaticon2-drop pr-0"
                  style={{ padding: "none" }}
                />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.MELIORATION" />
                </Tooltip>
              }
            >
              <Button
                variant={`${meliorated ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setMeliorated(!meliorated);
                  e.currentTarget.blur();
                }}
                disabled={loading}
              >
                <i
                  className="flaticon2-down pr-0"
                  style={{ padding: "none" }}
                />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="card-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.SHORT_NAME" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="shortName"
                  type="text"
                  className={`${
                    (touched?.shortName &&
                      getValues("shortName").length === 0) ||
                    (submitCount >= 1 &&
                      getValues("shortName")?.length === 0) ||
                    errors.shortName ||
                    (touched?.shortName &&
                      getValues("shortName").length >= 51) ||
                    (submitCount >= 1 && getValues("shortName")?.length >= 51)
                      ? "is-invalid"
                      : ""
                  } ${
                    !errors.shortName && getValues("shortName")?.length >= 1
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.SHORT_NAME",
                  })}`}
                  ref={register({ required: true, maxLength: 50 })}
                />
              </InputGroup>
              {(errors.shortName?.required ||
                (touched?.shortName && getValues("shortName").length === 0) ||
                (submitCount >= 1 && getValues("shortName")?.length === 0)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {(errors.shortName?.maxLength ||
                (touched?.shortName && getValues("shortName").length >= 51) ||
                (submitCount >= 1 && getValues("shortName")?.length >= 51)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.INCORRECT_FIELD_OR_CROPPING_SHORT_NAME_LENGTH" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" /> *
              </Form.Label>
              <Form.Control
                as="select"
                name="isActive"
                ref={register({ required: true })}
              >
                {status.map((stat) => (
                  <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                    {(message) => <option value={stat.value}>{message}</option>}
                  </FormattedMessage>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DECLARED_ACREAGE" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="acreage"
                  type="number"
                  className={`${
                    (touched?.acreage && !getValues("acreage")) ||
                    (submitCount >= 1 && getValues("acreage") < 0.1) ||
                    errors.acreage
                      ? "is-invalid"
                      : ""
                  } ${
                    !errors.acreage && getValues("acreage") >= 0.1
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.FIELD_ACREAGE",
                  })}`}
                  step="0.01"
                  ref={register({ required: true, min: 0.1, max: 9999.99 })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>ha</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              {(errors.acreage?.type === "required" ||
                (touched?.acreage && !getValues("acreage").length)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.acreage?.type === "min" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                </div>
              )}
              {errors.acreage?.type === "max" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_10000" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="shapeArea"
                  type="number"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.CALCULATED_FIELD_ACREAGE",
                  })}`}
                  step="0.01"
                  disabled
                  ref={register({ required: false })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>ha</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.DESCRIPTION" />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="desc"
                  as="textarea"
                  rows="1"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.DESCRIPTION",
                  })}`}
                  ref={register({ required: false })}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.SUPPLIER" />
              </Form.Label>
              <SuppliersSelect />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.SPECIES" />
              </Form.Label>
              <ReactSelect
                name="speciesId"
                className="input__select--search"
                defaultValue={speciesList.length > 0 ? speciesList[0] : null}
                value={speciesIndex != null ? speciesList[speciesIndex] : null}
                options={speciesList}
                required={true}
                onChange={onChangeSelectedSpecies}
                isDisabled={
                  !!(speciesList.length === 0) || speciesFieldsBlocked
                }
                isLoading={!!(speciesList.length === 0)}
                placeholder={
                  !!(speciesList.length === 0)
                    ? `${intl.formatMessage({ id: "GENERAL.LOADING" })}...`
                    : `${intl.formatMessage({ id: "GENERAL.SELECT" })}...`
                }
                isClearable
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.VARIETY" />
              </Form.Label>
              <ReactSelect
                name="varietyId"
                className="input__select--search"
                defaultValue={varietyList.length > 0 ? varietyList[0] : null}
                value={varietyIndex != null ? varietyList[varietyIndex] : null}
                options={varietyList}
                required={true}
                onChange={onChangeSelectedVariety}
                isDisabled={
                  !!(varietyList.length === 0) ||
                  varietyLoading ||
                  speciesFieldsBlocked
                }
                isLoading={varietyLoading}
                placeholder={
                  varietyLoading
                    ? `${intl.formatMessage({ id: "GENERAL.LOADING" })}...`
                    : `${intl.formatMessage({ id: "GENERAL.SELECT" })}...`
                }
                isClearable
              />
            </Form.Group>
            <Col md={12} className="text-right">
              <Button type="submit" disabled={loading}>
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}
