import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm, useFieldArray } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function FarmForm({ profile, updateFarmProfile }) {
  const intl = useIntl();
  const {
    control,
    register,
    errors,
    triggerValidation,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });
  const [checkboxActive, setCheckboxActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function setForm() {
      if (profile?.bpartners?.length) {
        setValue("bpartnerFullName", profile.bpartners[0].bpartnerFullName);
        setValue("bpartnerShortName", profile.bpartners[0].bpartnerShortName);
        setCheckboxActive(profile.bpartners[0].isPublic);
        if (profile.bpartners[0].contacts.length > 0) {
          profile.bpartners[0].contacts.forEach((contact) => {
            setTimeout(() => {
              append({ type: contact.type, desc: contact.desc });
            });
          });
        }
      }
    }
    setForm();
  }, [profile]);

  const onChangingContactType = (index, type) => {
    fields[index].type = type;
    triggerValidation();
  };

  const onCheckboxClick = (e) => {
    setCheckboxActive(e.target.checked);
  };

  async function onSubmit(data) {
    setLoading(true);
    let form = { ...data };
    if (form.contacts) {
      form.contacts.forEach((contact, index) => {
        contact.type = fields[index].type;
      });
    } else {
      form.contacts = [];
    }
    form.isPublic = checkboxActive;
    const callback = await updateFarmProfile(form);
    if (callback) {
      reset();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.FARM_NAME" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="bpartnerFullName"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "GENERAL.FARM_NAME",
              })}`}
              ref={register({ required: false })}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.SHORT_NAME" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="bpartnerShortName"
              type="text"
              className={errors.bpartnerShortName ? "is-invalid" : ""}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.SHORT_NAME",
              })}`}
              ref={register({ required: false, maxLength: 30 })}
            />
          </InputGroup>
          {errors.bpartnerShortName && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_SHORT_NAME_LENGTH_2" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CONTACTS" />
          </Form.Label>
          {fields.length === 0 && (
            <Form.Group as={Col} sm={12} className="text-center">
              <h6>
                <FormattedMessage id="GENERAL.NO_CONTACTS" />
              </h6>
            </Form.Group>
          )}
          {fields.map((item, index) => (
            <Form.Group as={Col} sm={12} key={item.id}>
              <Form.Label>
                {item.type === "M" && <FormattedMessage id="GENERAL.EMAIL" />}
                {item.type === "T" && <FormattedMessage id="GENERAL.PHONE" />}
                {item.type === "C" && <FormattedMessage id="GENERAL.MOBILE" />}
                {item.type === "F" && <FormattedMessage id="GENERAL.FAX" />}
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <Button
                    variant={
                      item.type === "M" ? "primary" : "outline-secondary"
                    }
                    onClick={() => onChangingContactType(index, "M")}
                  >
                    <i className="fa fa-at icon-nm pr-0"></i>
                  </Button>
                  <Button
                    variant={
                      item.type === "T" ? "primary" : "outline-secondary"
                    }
                    onClick={() => onChangingContactType(index, "T")}
                  >
                    <i className="fa fa-phone-alt icon-nm pr-0"></i>
                  </Button>
                  <Button
                    variant={
                      item.type === "C" ? "primary" : "outline-secondary"
                    }
                    onClick={() => onChangingContactType(index, "C")}
                  >
                    <i className="fa fa-mobile-alt icon-nm pr-0"></i>
                  </Button>
                  <Button
                    variant={
                      item.type === "F" ? "primary" : "outline-secondary"
                    }
                    onClick={() => onChangingContactType(index, "F")}
                  >
                    <i className="fa fa-fax icon-nm pr-0"></i>
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => remove(index)}
                  >
                    <i className="fa fa-trash icon-nm pr-0"></i>
                  </Button>
                </InputGroup.Prepend>
                <Form.Control
                  name={`contacts[${index}].desc`}
                  type="text"
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.EMAIL_OR_PHONE",
                  })}`}
                  defaultValue={item.desc}
                  ref={register()}
                />
              </InputGroup>
            </Form.Group>
          ))}
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="isPublic"
                ref={register({ required: false })}
                checked={checkboxActive}
                onChange={(e) => onCheckboxClick(e)}
              />
            }
            label={`${intl.formatMessage({
              id: "PLACEHOLDER.IS_FARM_PUBLIC_DESCRIPTION",
            })}.`}
          />
        </Form.Group>
        <Col md={12} className="text-right">
          <Button
            className="mr-2"
            variant="primary"
            type="button"
            disabled={loading}
            onClick={() => append({ type: "M", desc: "sample@sm.sm" })}
          >
            <FormattedMessage id="GENERAL.ADD_CONTACT" />
          </Button>
          <Button type="submit" disabled={loading || errors.bpartnerShortName}>
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
