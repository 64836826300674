import React, { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { subDays, formatISO } from "date-fns";
import { getImagesCropViewToCropping } from "../../../services/cropViewCrud";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";
import CropViewDatePickers from "./components/CropViewDatePickers";
import { CircularProgress } from "@material-ui/core";
import CropViewImages from "./components/CropViewImages";
import CropViewImagePreview from "./components/CropViewImagePreview";

export default function CropViewModal({ selectedCroppingId }) {
  const intl = useIntl();

  const open = useSelector((state) => state.cropViewModal.open);
  const initialized = useSelector((state) => state.cropViewModal.initialized);
  const loading = useSelector((state) => state.cropViewModal.loading);
  const selectedCroppingIdInRedux = useSelector(
    (state) => state.cropViewModal.selectedCroppingId
  );
  const dispatch = useDispatch();
  const dateFrom = useSelector((state) => state.cropViewModal.dateFrom);
  const dateTo = useSelector((state) => state.cropViewModal.dateTo);
  const cameras = useSelector((state) => state.cropViewModal.cameras);
  const selectedImage = useSelector(
    (state) => state.cropViewModal.selectedImage
  );

  const close = () => {
    dispatch({
      type: "cropViewModal/reset",
    });
  };

  const setLoading = useCallback(
    (value) => {
      dispatch({
        type: "cropViewModal/setLoading",
        payload: value,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      open &&
      dateFrom &&
      dateTo &&
      !initialized &&
      selectedCroppingIdInRedux
    ) {
      setLoading(true);
      getImagesCropViewToCropping(
        selectedCroppingIdInRedux,
        formatISO(dateFrom, { representation: "date" }),
        formatISO(dateTo, { representation: "date" })
      )
        .then((response) => {
          setLoading(false);
          dispatch({
            type: "cropViewModal/setCameras",
            payload: response.data,
          });
          dispatch({
            type: "cropViewModal/setInitialized",
            payload: true,
          });
        })
        .catch((error) => {
          setLoading(false);
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    }
  }, [
    dateFrom,
    dateTo,
    dispatch,
    initialized,
    intl,
    open,
    selectedCroppingId,
    selectedCroppingIdInRedux,
    setLoading,
  ]);

  const setDateFrom = useCallback(
    (date) => {
      dispatch({
        type: "cropViewModal/setDateFrom",
        payload: date,
      });
    },
    [dispatch]
  );

  const setDateTo = useCallback(
    (date) => {
      dispatch({
        type: "cropViewModal/setDateTo",
        payload: date,
      });
    },
    [dispatch]
  );

  const setSelectedCroppingId = useCallback(
    (date) => {
      dispatch({
        type: "cropViewModal/setSelectedCroppingId",
        payload: date,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (open) {
      setDateFrom(subDays(new Date(), 7));
      setDateTo(new Date());
      setSelectedCroppingId(selectedCroppingId);
    }
  }, [open, selectedCroppingId, setDateFrom, setDateTo, setSelectedCroppingId]);

  return (
    <Dialog open={open} onClose={close} scroll={"paper"} fullScreen>
      {
        <>
          <DialogTitle className="mui-dialog-title__relative shadow-sm">
            <span className="mui-dialog-popup__title">
              <FormattedMessage id="GENERAL.PHOTOS_FROM_CROPPING" />
            </span>
            <button
              className="bg-transparent border-0 mui-dialog-popup__close-btn"
              onClick={close}
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </button>
          </DialogTitle>
          <DialogContent className="pt-6">
            {selectedImage && <CropViewImagePreview />}
            {!selectedImage && (
              <>
                <CropViewDatePickers />

                {loading && (
                  <div className="d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                )}
                {!loading && cameras.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <h6>
                      <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
                    </h6>
                  </div>
                )}
                {!loading && cameras.length > 0 && <CropViewImages />}
              </>
            )}
          </DialogContent>
        </>
      }
    </Dialog>
  );
}
