import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

function Notification(props) {
  const { notification, handleClick } = props;

  return (
    <div
      key={notification.alertAddressedToId}
      className="d-flex align-items-center mb-6"
      onClick={() => handleClick(notification)}
    >
      <div
        className={`symbol symbol-40 mr-5         ${
          notification.symbol === "BA" || notification.symbol === "SF"
            ? "symbol-light-success"
            : null
        }
        ${
          notification.symbol === "BR" || notification.symbol === "SE"
            ? "symbol-light-danger"
            : null
        }
          ${notification.symbol === "BN" ? "symbol-light-warning" : null}
          ${notification.symbol === "SA" ? "symbol-light-primary" : null}`}
      >
        <span className="symbol-label">
          {notification.symbol === "BA" && <>S</>}
          {notification.symbol === "BN" && <>W</>}
          {(notification.symbol === "BR" || notification.symbol === "SE") && (
            <>N</>
          )}
          {notification.symbol === "SA" && <>I</>}
          {notification.symbol === "SF" && <>F</>}

          {notification.symbol === "ES" && (
            <>{notification.subscription.subscriptionSymbol}</>
          )}
        </span>
      </div>
      <div className="d-flex flex-column">
        <span className="text-dark text-hover-primary">
          <span className="font-weight-bold">
            {moment(notification.timeArised).format("DD.MM.YYYY HH:mm")}
          </span>
          :{" "}
          {notification.symbol === "BA" && (
            <>
              <FormattedMessage id="PE.BUY_OFFER_ACCEPTED_NOTIFICATION" />{" "}
              <span className="font-weight-bold">
                {notification.bpartner.bpartnerFullName}
              </span>
            </>
          )}
          {notification.symbol === "BN" && (
            <>
              <FormattedMessage id="PE.BUY_OFFER_NEGOTIATION_NOTIFICATION" />{" "}
              <span className="font-weight-bold">
                {notification.bpartner.bpartnerFullName}
              </span>
            </>
          )}
          {notification.symbol === "BR" && (
            <>
              <FormattedMessage id="PE.BUY_OFFER_REJECTED_NOTIFICATION" />{" "}
              <span className="font-weight-bold">
                {notification.bpartner.bpartnerFullName}
              </span>
            </>
          )}
          {notification.symbol === "SA" && (
            <>
              <FormattedMessage id="PE.BUY_OFFER_RECEIVED_NOTIFICATION" />{" "}
              <span className="font-weight-bold">
                {notification.bpartner.bpartnerFullName}
              </span>
            </>
          )}
          {notification.symbol === "SE" && (
            <FormattedMessage id="PE.SELL_OFFER_EXPIRED_NOTIFICATION" />
          )}
          {notification.symbol === "SF" && (
            <FormattedMessage id="PE.SALE_OFFER_FINSHED_NOTIFICATION" />
          )}
          {notification.symbol === "ES" && (
            <>
              <FormattedMessage id="NOTIFICATION.SUBSCRIPTION_TO_MODULE" />{" "}
              <span className="font-weight-bold">
                {notification.subscription.applicationSymbol ===
                  "BRIGADIER" && <>Brygadier</>}
                {notification.subscription.applicationSymbol ===
                  "CROPMANAGER" && <>Cropchart Manager</>}
              </span>
              , <FormattedMessage id="NOTIFICATION.ENDS_IN" />:{" "}
              {moment(notification.subscription.dateTo).diff(
                moment(notification.timeArised),
                "days"
              ) + 1}{" "}
              <FormattedMessage id="GENERAL.DAYS" />
            </>
          )}
        </span>
      </div>
    </div>
  );
}

export default Notification;
