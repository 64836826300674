import axios from "axios";

export const RECEIVABLES_URL = `${process.env.REACT_APP_URL}brigadier/payouts`;

export function getReceivablesFormData() {
  return axios.get(`${RECEIVABLES_URL}/attribute`);
}

export function getPayoutsToEmployees(form) {
  return axios.post(`${RECEIVABLES_URL}/employee/all`, form);
}

export function createPayoutHeader(form) {
  return axios.post(`${RECEIVABLES_URL}`, form);
}

export function getPayoutToEmployee(employeeId, from, to, payoutTypeIds) {
  return axios.get(
    `${RECEIVABLES_URL}/${employeeId}?from=${from}&to=${to}&payoutTypeIds=${payoutTypeIds}`
  );
}

export function getPayoutToEmployeeExcel(employeeId, from, to, payoutTypeIds) {
  return axios.post(
    `${RECEIVABLES_URL}/${employeeId}/excel?from=${from}&to=${to}&payoutTypeIds=${payoutTypeIds}`,
    null,
    {
      responseType: "blob",
    }
  );
}

export function createPayoutHeaderGroup(form) {
  return axios.post(`${RECEIVABLES_URL}/group`, form);
}

export function getPayoutHeaderSign(payoutHeaderId, width, height) {
  return axios.get(
    `${RECEIVABLES_URL}/${payoutHeaderId}/sign?width=${width}&height=${height}`,
    {
      responseType: "arraybuffer",
    }
  );
}
