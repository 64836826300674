import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

export function GrowersQuantitiesReport(props) {
  return (
    <>
      {props.report ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="PE.GROWER_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.GROWER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.LIMIT" /> [t]
              </th>
              <th>
                <FormattedMessage id="PE.SALE_OFFER_ID" />
              </th>
              <th>
                <FormattedMessage id="PE.SUGAR_FACTORY" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.OFFER_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.STATUS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.WEIGHT" /> [t]
              </th>
              <th>
                <FormattedMessage id="GENERAL.PRICE" /> [PLN/t]
              </th>
            </tr>
          </thead>
          <tbody>
            {props.report.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{row.groOwnerNo}</td>
                <td className="text-center">{row.bpartnerFullName}</td>
                <td className="text-right">{row.limit}</td>
                <td className="text-center">{row.sellOfferId}</td>
                <td className="text-center">{row.orgentityDesc}</td>
                <td className="text-center">
                  {moment(row.sellOfferCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">
                  {row.sellOfferStatus === "A" && (
                    <Badge pill variant="info">
                      <FormattedMessage id="GENERAL.PUBLISHED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "C" && (
                    <Badge pill variant="danger">
                      <FormattedMessage id="GENERAL.REMOVED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "E" && (
                    <Badge pill variant="dark">
                      <FormattedMessage id="GENERAL.EXPIRED" />
                    </Badge>
                  )}
                  {row.sellOfferStatus === "F" && (
                    <Badge pill variant="success">
                      <FormattedMessage id="GENERAL.FINISHED" />
                    </Badge>
                  )}
                </td>
                <td className="text-right">{row.primaryWeight}</td>
                <td className="text-right">{row.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2">
          <h6>
            <FormattedMessage id="PE.NO_REPORTS" />
          </h6>
        </div>
      )}
    </>
  );
}
