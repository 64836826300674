import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeactivateContractorModal(props) {
  const [loading, setLoading] = useState(false);

  function handleClose() {
    props.close();
  }

  async function handleClick() {
    setLoading(true);
    const callback = await props.onChangingContractorAccountStatusToDisactive(
      props.supCustID
    );
    if (callback === "OK") {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="GENERAL.USER_DEACTIVATION_CONFIRMATION" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="GENERAL.USER_DEACTIVATION_CONFIRMATION_MESSAGE" />
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="primary" disabled={loading}>
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button onClick={handleClose} color="default" disabled={loading}>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
