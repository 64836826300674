/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chart from "react-apexcharts";

export function StatsByVariety({ className, chartColor = "warning", data }) {
  const uiService = useHtmlClassService();
  const intl = useIntl();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  const chartId = "brigadier-dashboard-statsByVariety";

  const [selectedUom, setSelectedUom] = useState();
  const availableUoms = useMemo(() => {
    let uoms = [];
    data.forEach((variety) => {
      variety.uomValue.forEach((uom) => {
        if (uoms.indexOf(uom.uom.desc) < 0) uoms.push(uom.uom.desc);
      });
    });
    return uoms;
  }, [data]);
  useEffect(() => {
    if (availableUoms?.length > 0) setSelectedUom(availableUoms[0]);
  }, [availableUoms]);

  const chartOptions = useMemo(() => {
    let seriesData = [];
    let xAxisData = [];
    if (data?.length > 0 && selectedUom)
      data.forEach((variety) => {
        variety.uomValue.forEach((uom) => {
          if (uom.uom.desc === selectedUom) {
            seriesData.push(uom.value);
            xAxisData.push(`${variety.variety.desc} [${uom.uom.desc}]`);
          }
        });
      });
    const options = {
      series: [
        {
          name: intl.formatMessage({ id: "GENERAL.QUANTITY" }),
          data: seriesData,
        },
      ],
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        id: chartId,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [layoutProps.colorsThemeBaseColor],
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [layoutProps.colorsThemeBaseColor],
      },
      xaxis: {
        categories: xAxisData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
      },
      yaxis: {
        min: 0,
        labels: {
          show: true,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
          formatter: function(val) {
            return val.toLocaleString(languageCode);
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
        y: {
          formatter: function(val) {
            return val.toLocaleString(languageCode);
          },
        },
      },
      colors: [layoutProps.colorsThemeLightColor],
      markers: {
        colors: [layoutProps.colorsThemeLightColor],
        strokeColor: [layoutProps.colorsThemeBaseColor],
        strokeWidth: 3,
      },
    };
    return options;
  }, [
    data,
    intl,
    languageCode,
    layoutProps.colorsGrayGray500,
    layoutProps.colorsThemeBaseColor,
    layoutProps.colorsThemeLightColor,
    layoutProps.fontFamily,
    selectedUom,
  ]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className ? className : ""}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label d-flex align-items-center">
              <Link
                to="/brigadier/results"
                className="font-weight-bolder text-dark text-hover-primary"
              >
                <FormattedMessage id="BRIGADIER.DASHBOARD.COLLECTED_ASSORTMENT" />
                <span className="svg-icon svg-icon-dark-75 ml-3 mr-5 d-inline-block">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Right-2.svg"
                    )}
                    style={{
                      width: "auto",
                      height: "auto",
                      position: "relative",
                      bottom: "1px",
                    }}
                  />
                </span>
              </Link>
              {availableUoms?.length > 0 && (
                <ButtonGroup
                  aria-label="small outlined button group"
                  color="secondary"
                >
                  {availableUoms.map((uom, index) => (
                    <Button
                      key={`${chartId}-uomBtn-${index}`}
                      variant={selectedUom === uom ? "contained" : ""}
                      onClick={() => setSelectedUom(uom)}
                    >
                      {uom}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0 pt-0">
          {/* begin::Chart */}
          {data?.length > 0 && selectedUom && (
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type={chartOptions.chart.type}
              height={chartOptions.chart.height}
              className="px-4"
            />
          )}
          {(!data || data?.length === 0 || !selectedUom) && (
            <div className="text-center mt-5">
              <h6>
                <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
              </h6>
            </div>
          )}
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
