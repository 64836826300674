import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function MapFieldsTable({ fields, centerOnMap }) {
  return (
    <>
      {fields.length > 0 ? (
        <Col xs={12} className="text-center mt-2 table-full-height">
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>
                  <FormattedMessage id="GENERAL.SHORT_NAME" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.ACTIONS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field) => (
                <tr key={field.fieldId} className="text-center">
                  <td>{field.shortName}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-tooltip">
                          <FormattedMessage id="GENERAL.SHOW_ON_MAP" />
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() =>
                          centerOnMap(
                            field.shape
                              ? field.shape.parent.shapeId
                              : field.pointId
                          )
                        }
                      >
                        <i
                          className="flaticon2-location"
                          style={{ paddingLeft: "4px" }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      ) : (
        <Col xs={12} className="text-center mt-5">
          <h6>
            <FormattedMessage id="GENERAL.NO_FIELDS" />
          </h6>
        </Col>
      )}
    </>
  );
}
