const initialCustomRateState = {
  tariffGroups: undefined,
  workerDefaultRates: undefined,
  tariffs: undefined,
  groupEditModalOpen: false,
};

export const reducer = (state = initialCustomRateState, action) => {
  switch (action.type) {
    case "brigadier/customTariffRates/setTariffs": {
      const { tariffs } = action.payload;

      return { ...state, tariffs };
    }

    case "brigadier/customTariffRates/setTariffGroups": {
      const { tariffGroups } = action.payload;

      return { ...state, tariffGroups };
    }

    case "brigadier/customTariffRates/addTariffGroup": {
      const { tariffGroup } = action.payload;

      return { ...state, tariffGroups: [...state.tariffGroups, tariffGroup] };
    }

    case "brigadier/customTariffRates/updateTariffGroup": {
      const { tariffGroup } = action.payload;

      return {
        ...state,
        tariffGroups: state.tariffGroups.map((group) => {
          if (group.tariffGroupId === tariffGroup.tariffGroupId)
            return tariffGroup;
          else return group;
        }),
      };
    }

    case "brigadier/customTariffRates/deleteTariffGroup": {
      const { tariffGroupId } = action.payload;

      return {
        ...state,
        tariffGroups: state.tariffGroups.filter(
          (group) => group.tariffGroupId !== tariffGroupId
        ),
      };
    }

    case "brigadier/customTariffRates/setWorkerDefaultRates": {
      const { workerDefaultRates } = action.payload;

      return { ...state, workerDefaultRates };
    }

    case "brigadier/customTariffRates/addWorkerDefaultTariffGroupRate": {
      const { data } = action.payload;

      return {
        ...state,
        workerDefaultRates: {
          ...state.workerDefaultRates,
          tariffGroups: [...state.workerDefaultRates.tariffGroups, data],
        },
      };
    }

    case "brigadier/customTariffRates/updateWorkerDefaultTariffGroupRate": {
      const { data } = action.payload;

      return {
        ...state,
        workerDefaultRates: {
          ...state.workerDefaultRates,
          tariffGroups: state.workerDefaultRates.tariffGroups.map((group) => {
            if (group.defaultRateId === data.tariffGroupId) return data;
            else return group;
          }),
        },
      };
    }

    case "brigadier/customTariffRates/deleteWorkerDefaultTariffGroupRate": {
      const { defaultRateId } = action.payload;

      return {
        ...state,
        workerDefaultRates: {
          ...state.workerDefaultRates,
          tariffGroups: state.workerDefaultRates.tariffGroups.filter(
            (group) => group.defaultRateId !== defaultRateId
          ),
        },
      };
    }

    case "brigadier/customTariffRates/reset": {
      return initialCustomRateState;
    }

    case "brigadier/customTariffRates/setGroupEditModalOpen": {
      const { groupEditModalOpen } = action.payload;

      return { ...state, groupEditModalOpen };
    }

    default:
      return state;
  }
};
