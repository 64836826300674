import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm, useFieldArray } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { getChemistryDetails } from "../../../services/chemistryCrud";
import { TreatmentUpdateMap } from "../components/TreatmentUpdateMap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapButton from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import { updateTreatmetHeader } from "../../../services/treatmentsCrud";
import getEventsToRedux from "../helpers/getEventsToRedux";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";
import closeModals from "../helpers/closeModals";

let pestsArray = [];
let treatmentsNumber = 0;
let previousFieldsLength = 0;

export function UpdateTreatmentModal() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "plantProtectionProduct",
  });
  const [displaySoilCultivation, setDisplaySoilCultivation] = useState(false);
  const [displayPlanting, setDisplayPlanting] = useState(false);
  const [displayIrrigation, setDisplayIrrigation] = useState(false);
  const [displayFertilization, setDisplayFertilization] = useState(false);
  const [displayPlantProtection, setDisplayPlantProtection] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [plantingOrigins] = useState([
    { id: 1, desc: "FSS", value: true },
    { id: 2, desc: "kwalifikat", value: false },
  ]);
  const [qualificationsDegree] = useState([
    { id: 1, desc: "SE" },
    { id: 2, desc: "E" },
    { id: 3, desc: "A" },
    { id: 4, desc: "B" },
  ]);
  const [rowWidths] = useState([
    { id: 1, desc: "75 cm", value: 75 },
    { id: 2, desc: "85 cm", value: 85 },
    { id: 3, desc: "90 cm", value: 90 },
  ]);
  const [pestsList, setPestsList] = useState([]);
  const [treatmentMap, setTreatmentMap] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [resetShape, setResetShape] = useState(false);
  const [stopPropagation, setStopPropagation] = useState(false);
  registerLocale("pl", pl);

  const open = useSelector((state) => state.calendar.openModals.update);
  const formDates = useSelector((state) => state.calendar.formDates);
  const soilCultivationList = useSelector(
    (state) => state.calendar.treatmentTypesList?.soilCultivation
  );
  const fertilizationList = useSelector(
    (state) => state.calendar.treatmentTypesList?.fertilization
  );
  const plantProtectionList = useSelector(
    (state) => state.calendar.treatmentTypesList?.plantProtection
  );
  const varieties = useSelector((state) => state.calendar.varieties);
  const dimSizes = useSelector((state) => state.calendar.dimSizes);
  const fertilizers = useSelector((state) => state.calendar.fertilizers);
  const plantProtectionProducts = useSelector(
    (state) => state.calendar.plantProtectionProducts
  );
  const unitOfMeasurements = useSelector(
    (state) => state.calendar.unitOfMeasurements
  );
  const treatmentDetails = useSelector(
    (state) => state.calendar.treatmentDetails
  );
  const croppingShape = useSelector((state) => state.calendar.croppingShape);
  const treatmentHeadId = useSelector(
    (state) => state.calendar.treatmentHeadId
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (treatmentDetails && open) {
      setBasicInformation();
      setTreatmentsInformation();
    }
  }, [treatmentDetails, open]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshData && !stopPropagation) {
      setTimeout(() => {
        fields.forEach((field, index) => {
          setValue(`plantProtectionProduct[${index}].pestIds`, field.pestIds);
        });
      });
      setStopPropagation(true);
      previousFieldsLength = fields.length;
    }
  }, [refreshData]);

  useEffect(() => {
    if (previousFieldsLength > fields.length) {
      previousFieldsLength = fields.length;
      return;
    }

    if (fields.length && !refreshData) {
      setPlantProtectionPestsInformation();
    }

    if (fields.length >= 1 && refreshData) {
      async function fetchChemData() {
        const chemData = await getPlantProtectionProductDetails(
          plantProtectionProducts[0].id
        );
        if (chemData.id) {
          setTimeout(() => {
            setValue(
              `plantProtectionProduct[${fields.length - 1}].prevention`,
              chemData.preventionDays
            );
            setValue(
              `plantProtectionProduct[${fields.length - 1}].gracePeriod`,
              chemData.graceDays
            );
            const newPestsList = [...pestsList];
            newPestsList.push({
              id: fields[fields.length - 1].id,
              pests: chemData.pests,
            });
            setPestsList(newPestsList);
            triggerValidation();
          }, 50);
        }
      }
      fetchChemData();
    }
  }, [fields]);

  useEffect(() => {
    if (treatmentDetails) {
      const plantProtectionTreatments = treatmentDetails.treatments.find(
        (treatment) =>
          treatment.dtreatmentType === "SPRAYING" ||
          treatment.dtreatmentType === "FOGGING" ||
          treatment.dtreatmentType === "DRESSING"
      );
      if (!displayPlantProtection) {
        if (fields.length) remove(0);
      } else if (
        plantProtectionProducts.length &&
        !fields.length &&
        !plantProtectionTreatments
      ) {
        append({
          plantProtectionProduct: "",
          prevention: "0, 0, 0",
          gracePeriod: "5",
        });
      }
    }
  }, [plantProtectionProducts, displayPlantProtection, treatmentDetails]);

  const setBasicInformation = () => {
    setFromDate(new Date(formDates[0]));
    setToDate(new Date(formDates[1]));
    setTimeout(() => {
      setValue("croppingName", treatmentDetails.croppingShortNames[0]);
      setValue("area", treatmentDetails.shapeArea.toFixed(2));
      setValue("comment", treatmentDetails.growerComment);
    }, 50);
  };

  const setTreatmentsInformation = () => {
    let reproducedTreatments = [];
    treatmentDetails.treatments.forEach((treatment, index) => {
      if (
        treatment.dtreatmentType === "PLOWING" ||
        treatment.dtreatmentType === "SUBSOILING" ||
        treatment.dtreatmentType === "HARROWING" ||
        treatment.dtreatmentType === "CULTIVATING" ||
        treatment.dtreatmentType === "THREADING" ||
        treatment.dtreatmentType === "ROLLING" ||
        treatment.dtreatmentType === "PLATEOUT" ||
        treatment.dtreatmentType === "REDRAWING" ||
        treatment.dtreatmentType === "DESCALING" ||
        treatment.dtreatmentType === "WITH_CULTIVATOR" ||
        treatment.dtreatmentType === "WITH_ROTARY_TILLER" ||
        treatment.dtreatmentType === "WITH_ACTIVE_HARROW"
      ) {
        setDisplaySoilCultivation(true);
        setTimeout(() => {
          setSoilCultivationInformation(treatment);
        });
      } else if (treatment.dtreatmentType === "PLANTING") {
        setDisplayPlanting(true);
        setTimeout(() => {
          setPlantingInformation(treatment);
        });
      } else if (treatment.dtreatmentType === "IRRIGATION") {
        setDisplayIrrigation(true);
        setTimeout(() => {
          setIrrigationInformation(treatment);
        });
      } else if (
        treatment.dtreatmentType === "LIQUID_FERTILIZATION" ||
        treatment.dtreatmentType === "ROW_FERTILIZATION" ||
        treatment.dtreatmentType === "BROADCAST_FERTILIZATION"
      ) {
        setDisplayFertilization(true);
        setTimeout(() => {
          setFertilizationInformation(treatment);
        });
      } else if (
        treatment.dtreatmentType === "SPRAYING" ||
        treatment.dtreatmentType === "FOGGING" ||
        treatment.dtreatmentType === "DRESSING"
      ) {
        reproducedTreatments.push(treatment);
        treatmentsNumber++;
        setDisplayPlantProtection(true);
      }
    });
    if (reproducedTreatments.length > 0) {
      setPlantProtectionInformation(reproducedTreatments);
    }
  };

  const setSoilCultivationInformation = (treatment) => {
    setValue("soilCultivationTreatmentId", treatment.dtreatmentId);
  };

  const setPlantingInformation = (treatment) => {
    setValue("origin", treatment.details[0].origin);
    setValue("varietyId", treatment.details[0].variety.varietyId);
    setValue("batch", treatment.details[0].batch);
    setValue("dimSizeId", treatment.details[0].dimSizeId);
    setValue("qualificationDegree", treatment.details[0].qualification);
    setValue("rowWidth", treatment.details[0].interRowDistance);
    setValue("rowDistance", treatment.details[0].inRowDistance);
    setValue("quantity", treatment.details[0].quantity);
  };

  const setIrrigationInformation = (treatment) => {
    setValue("waterDose", treatment.details[0].dose);
  };

  const setFertilizationInformation = (treatment) => {
    setValue("fertilizationTreatmentId", treatment.dtreatmentId);
    setValue("fertilizerId", treatment.details[0].chemistry.id);
    setValue("dose", treatment.details[0].dose);
    setValue("dosePerArea", treatment.details[0].dosePerArea);
  };

  const setPlantProtectionInformation = (treatments) => {
    setTimeout(() => {
      treatments.forEach((treatment) => {
        append({
          plantProtectionProduct: "",
          dtreatmentId: treatment.dtreatmentId,
          chemistryId: treatment.details[0].chemistry.id,
          dose: treatment.details[0].dose,
          pestIds: treatment.details[0].pests.map((pest) => {
            return pest.id.toString();
          }),
          pests: treatment.details[0].mainChemistry.pests,
          uomId: treatment.details[0].uom.id,
          prevention:
            treatment.details[0].mainChemistry.preventionDays === ""
              ? "Brak danych"
              : treatment.details[0].mainChemistry.preventionDays,
          gracePeriod:
            treatment.details[0].mainChemistry.graceDays === null
              ? "Brak danych"
              : treatment.details[0].mainChemistry.graceDays,
          remarks: treatment.details[0].remarks,
        });
      });
    }, treatments.length * 25);
  };

  const setPlantProtectionPestsInformation = () => {
    pestsArray.push({
      id: fields[fields.length - 1].id,
      pests: fields[fields.length - 1].pests,
    });
    treatmentsNumber--;
    if (treatmentsNumber === 0) {
      setPestsList(pestsArray);
      pestsArray = [];
      setRefreshData(true);
    }
  };

  async function onSelectingPlantProtectionProduct(e, index, field) {
    const chemData = await getPlantProtectionProductDetails(e.target.value);
    if (chemData.id) {
      setValue(
        `plantProtectionProduct[${index}].prevention`,
        chemData.preventionDays === "" ? "Brak danych" : chemData.preventionDays
      );
      setValue(
        `plantProtectionProduct[${index}].gracePeriod`,
        chemData.graceDays === null ? "Brak danych" : chemData.graceDays
      );
      const newPestsList = [...pestsList];
      const searchedObjExist = newPestsList.find((ele) => {
        return ele.id === field.id;
      });
      searchedObjExist
        ? (searchedObjExist.pests = chemData.pests)
        : newPestsList.push({ id: field.id, pests: chemData.pests });
      setPestsList(newPestsList);
    }
  }

  const onRemovingPlantProtectionProduct = (arrId, index) => {
    setTimeout(() => {
      remove(index);
      const newPestsList = [...pestsList];
      const filteredPestsList = newPestsList.filter((ele) => {
        return ele.id !== arrId;
      });
      setPestsList(filteredPestsList);
      triggerValidation();
    });
  };

  const getPlantProtectionProductDetails = (chemId) => {
    return new Promise((resolve) => {
      getChemistryDetails(chemId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve("error");
        });
    });
  };

  const onClosingModal = () => {
    closeModals();
    setResetShape(true);
    setTimeout(() => {
      pestsArray = [];
      treatmentsNumber = 0;
      previousFieldsLength = 0;
      reset();
      setDisplaySoilCultivation(false);
      setDisplayPlanting(false);
      setDisplayIrrigation(false);
      setDisplayFertilization(false);
      setDisplayPlantProtection(false);
      setFromDate(new Date());
      setToDate(new Date());
      setPestsList([]);
      setTreatmentMap(null);
      setRefreshData(false);
      setResetShape(false);
      setStopPropagation(false);
    });
  };

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  const onAddingPlantProtectionProduct = () => {
    ++previousFieldsLength;
    append({ plantProtectionProduct: "" });
  };

  const checkIfButtonShouldBeDisabled = () => {
    let shouldDisable = true;
    if (
      displaySoilCultivation ||
      displayPlanting ||
      displayIrrigation ||
      displayFertilization ||
      displayPlantProtection
    ) {
      shouldDisable = false;
    }
    return shouldDisable;
  };

  const createGeoJSONFromTreatmentMap = () => {
    const latLngKey = Object.keys(treatmentMap.latLngs)[0];
    const coords2 = treatmentMap.latLngs[latLngKey][0][latLngKey].map((p) => {
      return [p.lng(), p.lat()];
    });
    coords2.push([
      treatmentMap.latLngs[latLngKey][0][latLngKey][0].lng(),
      treatmentMap.latLngs[latLngKey][0][latLngKey][0].lat(),
    ]);
    const shape = {
      type: "Polygon",
      coordinates: [
        treatmentMap.latLngs[latLngKey][0][latLngKey].map((p) => {
          return [p.lng(), p.lat()];
        }),
        coords2,
      ],
    };
    return shape;
  };

  const onUpdatingTreatment = (form) => {
    form.shape.coordinates[0].push(form.shape.coordinates[0][0]);
    return new Promise((resolve) => {
      updateTreatmetHeader(treatmentHeadId, form)
        .then((response) => {
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type: "calendar/setTreatmentDetails",
            payload: null,
          });
          getEventsToRedux();
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  async function onSubmit(data) {
    setLoading(true);
    const form = data;
    delete form.croppingName;
    form.dateStart = fromDate;
    form.dateEnd = toDate;
    form.treatments = [];
    if (data.soilCultivationTreatmentId) {
      form.treatments.push({ dtreatmentId: data.soilCultivationTreatmentId });
    }
    if (data.origin) {
      form.treatments.push({
        dtreatmentId: 7,
        origin: data.origin == "true" ? true : false,
        varietyId: data.varietyId,
        batch: data.batch,
        dimSizeId: data.dimSizeId,
        qualificationDegree: data.qualificationDegree,
        rowWidth: data.rowWidth,
        rowDistance: data.rowDistance,
        quantity: data.quantity,
      });
    }
    if (data.waterDose) {
      form.treatments.push({ dtreatmentId: 10, waterDose: data.waterDose });
    }
    if (data.fertilizationTreatmentId) {
      form.treatments.push({
        dtreatmentId: data.fertilizationTreatmentId,
        chemistry: [
          {
            chemistryId: data.fertilizerId,
            dose: data.dose,
            dosePerArea: data.dosePerArea,
          },
        ],
      });
    }
    if (displayPlantProtection && data.plantProtectionProduct?.length > 0) {
      data.plantProtectionProduct.forEach((chem) => {
        let pushedChem = { ...chem };
        delete pushedChem.dtreatmentId;
        delete pushedChem.prevention;
        delete pushedChem.gracePeriod;
        form.treatments.push({
          dtreatmentId: chem.dtreatmentId,
          chemistry: [pushedChem],
        });
      });
    }
    delete data.soilCultivationTreatmentId;
    delete data.origin;
    delete data.varietyId;
    delete data.batch;
    delete data.dimSizeId;
    delete data.qualificationDegree;
    delete data.rowWidth;
    delete data.rowDistance;
    delete data.quantity;
    delete data.waterDose;
    delete data.fertilizationTreatmentId;
    delete data.dose;
    delete data.dosePerArea;
    delete data.fertilizerId;
    delete data.plantProtectionProduct;
    form.shape = createGeoJSONFromTreatmentMap();
    const callback = await onUpdatingTreatment(form);
    if (callback === "OK") {
      setLoading(false);
      onClosingModal();
    } else {
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title" className="calendar__modal">
        <div className="d-flex flex-wrap w-100 justify-content-between mb-n2">
          <div className="d-flex align-items-center mb-2 mr-4">
            <FormattedMessage id="GENERAL.MODIFY_TREATMENT" />
            {treatmentDetails && (
              <>
                {!treatmentDetails.isCanceled &&
                  !treatmentDetails.isFinished && (
                    <i className="mx-2 far fa-check-circle"></i>
                  )}
                {treatmentDetails.isCanceled && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="user-tooltip">
                        <FormattedMessage id="GENERAL.TREATMENT_HAS_BEEN_CANCELED" />
                      </Tooltip>
                    }
                  >
                    <i
                      className="mx-2 far fa-times-circle"
                      style={{ color: "#F64E60" }}
                    ></i>
                  </OverlayTrigger>
                )}
                {treatmentDetails.isFinished && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="user-tooltip">
                        <FormattedMessage id="GENERAL.TREATMENT_HAS_BEEN_FINISHED" />
                      </Tooltip>
                    }
                  >
                    <i
                      className="mx-2 far fa-check-circle"
                      style={{ color: "#1BC5BD" }}
                    ></i>
                  </OverlayTrigger>
                )}
              </>
            )}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mb-2">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CHOOSE_SOIL_CULTIVATION_TREATMENT" />
                </Tooltip>
              }
            >
              <BootstrapButton
                variant={`${
                  displaySoilCultivation ? "primary" : "outline-primary"
                }`}
                onClick={(e) => {
                  setDisplaySoilCultivation(!displaySoilCultivation);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-world pr-0"
                  style={{ padding: "none" }}
                />
              </BootstrapButton>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CHOOSE_PLANTING_TREATMENT" />
                </Tooltip>
              }
            >
              <BootstrapButton
                variant={`${displayPlanting ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setDisplayPlanting(!displayPlanting);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-plus-1 pr-0"
                  style={{ padding: "none" }}
                />
              </BootstrapButton>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CHOOSE_IRRIGATION_TREATMENT" />
                </Tooltip>
              }
            >
              <BootstrapButton
                variant={`${displayIrrigation ? "primary" : "outline-primary"}`}
                onClick={(e) => {
                  setDisplayIrrigation(!displayIrrigation);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-drop pr-0"
                  style={{ padding: "none" }}
                />
              </BootstrapButton>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CHOOSE_FERTILIZATION_TREATMENT" />
                </Tooltip>
              }
            >
              <BootstrapButton
                variant={`${
                  displayFertilization ? "primary" : "outline-primary"
                }`}
                onClick={(e) => {
                  setDisplayFertilization(!displayFertilization);
                  e.currentTarget.blur();
                }}
                disabled={loading}
                className="mr-4"
              >
                <i
                  className="flaticon2-layers-2 pr-0"
                  style={{ padding: "none" }}
                />
              </BootstrapButton>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-tooltip">
                  <FormattedMessage id="GENERAL.CHOOSE_PLANT_PROTECTION_TREATMENT" />
                </Tooltip>
              }
            >
              <BootstrapButton
                variant={`${
                  displayPlantProtection ? "primary" : "outline-primary"
                }`}
                onClick={(e) => {
                  setDisplayPlantProtection(!displayPlantProtection);
                  e.currentTarget.blur();
                }}
              >
                <i
                  className="flaticon2-protection pr-0"
                  style={{ padding: "none" }}
                />
              </BootstrapButton>
            </OverlayTrigger>
          </div>
        </div>
      </DialogTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers={true}>
          <Row>
            <Col md={5}>
              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.CROPPINGS" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="croppingName"
                    ref={register({ required: false })}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.DURATION_FROM" /> *
                  </Form.Label>
                  <DatePicker
                    name="dateFrom"
                    selected={fromDate}
                    dateFormat="dd.MM.yyyy"
                    locale={datepickerLocale}
                    showMonthDropdown
                    showYearDropdown
                    onChange={(date) => onChangingDate("from", date)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.DURATION_TO" /> *
                  </Form.Label>
                  <DatePicker
                    name="dateTo"
                    selected={toDate}
                    dateFormat="dd.MM.yyyy"
                    locale={datepickerLocale}
                    showMonthDropdown
                    showYearDropdown
                    onChange={(date) => onChangingDate("to", date)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.AREA" />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="area"
                      type="number"
                      className={`${
                        errors.area &&
                        getValues("area") >=
                          parseFloat(treatmentDetails?.shapeArea.toFixed(2))
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder={`${intl.formatMessage({
                        id: "PLACEHOLDER.AREA",
                      })}`}
                      step="0.01"
                      min="0"
                      onBlur={(e) =>
                        (e.currentTarget.value = Math.abs(
                          Math.ceil(e.currentTarget.value * 100) / 100
                        ))
                      }
                      ref={register({
                        required: false,
                        min: 0,
                        max: treatmentDetails?.shapeArea.toFixed(2)
                          ? parseFloat(treatmentDetails?.shapeArea.toFixed(2))
                          : 99999,
                      })}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ha</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  {errors.area &&
                    getValues("area") >=
                      parseFloat(treatmentDetails?.shapeArea.toFixed(2)) && (
                      <div
                        className="error invalid-feedback"
                        style={{ display: "block" }}
                      >
                        <FormattedMessage id="GENERAL.AREA_CANNOT_BE_LARGER_THAN_CROPPING_CALCULATED_AREA" />
                      </div>
                    )}
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.COMMENT" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="comment"
                    ref={register({ required: false })}
                  />
                </Form.Group>
                {displaySoilCultivation && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <div>
                        <span className="text--bold">
                          <FormattedMessage id="GENERAL.SOIL_CULTIVATION" />
                        </span>
                      </div>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.TREATMENT_TYPE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="soilCultivationTreatmentId"
                        ref={register({ required: true })}
                      >
                        {soilCultivationList.length && (
                          <>
                            {soilCultivationList.map((treatmentType) => (
                              <FormattedMessage
                                id={`TREATMENT.${treatmentType.type}`}
                                key={`TREATMENT.${treatmentType.type}`}
                              >
                                {(message) => (
                                  <option value={treatmentType.dtreatmentId}>
                                    {message}
                                  </option>
                                )}
                              </FormattedMessage>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </>
                )}
                {displayPlanting && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <div>
                        <span className="text--bold">
                          <FormattedMessage id="GENERAL.PLANTING" />
                        </span>
                      </div>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.ORIGIN" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="origin"
                        ref={register({ required: true })}
                      >
                        {plantingOrigins.map((origin) => (
                          <option key={origin.id} value={origin.value}>
                            {origin.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.VARIETY" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="varietyId"
                        ref={register({ required: true })}
                      >
                        {varieties.length && (
                          <>
                            {varieties.map((variety) => (
                              <option
                                key={variety.varietyId}
                                value={variety.varietyId}
                              >
                                {variety.desc}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.BATCH" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="batch"
                          type="text"
                          className={`${
                            errors.batch && getValues("batch").length > 100
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.BATCH",
                          })}`}
                          ref={register({ required: false, maxLength: 100 })}
                        />
                      </InputGroup>
                      {errors.batch && getValues("batch").length > 100 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.INCORRECT_BATCH_LENGTH" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.SIZES" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="dimSizeId"
                        ref={register({ required: true })}
                      >
                        {dimSizes.length && (
                          <>
                            {dimSizes.map((size) => (
                              <option key={size.id} value={size.id}>
                                {size.desc}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.QUALIFICATION_DEGREE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="qualificationDegree"
                        ref={register({ required: true })}
                      >
                        {qualificationsDegree.map((qualification) => (
                          <option
                            key={qualification.id}
                            value={qualification.desc}
                          >
                            {qualification.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.ROW_WIDTH" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="rowWidth"
                        ref={register({ required: true })}
                      >
                        {rowWidths.map((width) => (
                          <option key={width.id} value={width.value}>
                            {width.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.DISTANCE_IN_ROW" />
                      </Form.Label>
                      <Form.Control
                        name="rowDistance"
                        type="number"
                        className={`${
                          errors.rowDistance && getValues("rowDistance") > 99999
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder={`${intl.formatMessage({
                          id: "PLACEHOLDER.DISTANCE_IN_ROW",
                        })}`}
                        step="0.1"
                        min="0"
                        max="99999"
                        onBlur={(e) =>
                          (e.currentTarget.value = Math.abs(
                            Math.ceil(e.currentTarget.value * 10) / 10
                          ))
                        }
                        ref={register({
                          required: false,
                          min: 0.1,
                          max: 99999,
                        })}
                      />
                      {errors.rowDistance && getValues("rowDistance") > 99999 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_100000" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.QUANTITY" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="quantity"
                          type="number"
                          className={`${
                            errors.quantity && getValues("quantity") > 999999
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.QUANTITY",
                          })}`}
                          step="0.1"
                          min="0"
                          max="99999"
                          onBlur={(e) =>
                            (e.currentTarget.value = Math.abs(
                              Math.ceil(e.currentTarget.value * 10) / 10
                            ))
                          }
                          ref={register({
                            required: false,
                            min: 0.1,
                            max: 999999,
                          })}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>t/ha</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.quantity && getValues("quantity") > 999999 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                        </div>
                      )}
                    </Form.Group>
                  </>
                )}
                {displayIrrigation && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <div>
                        <span className="text--bold">
                          <FormattedMessage id="GENERAL.IRRIGATION_TREATMENT" />
                        </span>
                      </div>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.WATER_DOSE" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="waterDose"
                          type="number"
                          className={`${
                            errors.waterDose && getValues("waterDose") > 999999
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.WATER_DOSE",
                          })}`}
                          step="0.1"
                          min="0"
                          max="99999"
                          onBlur={(e) =>
                            (e.currentTarget.value = Math.abs(
                              Math.ceil(e.currentTarget.value * 10) / 10
                            ))
                          }
                          ref={register({
                            required: true,
                            min: 0.1,
                            max: 999999,
                          })}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>mm</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.waterDose && getValues("waterDose") > 999999 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                        </div>
                      )}
                    </Form.Group>
                  </>
                )}
                {displayFertilization && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <div>
                        <span className="text--bold">
                          <FormattedMessage id="GENERAL.FERTILIZATION" />
                        </span>
                      </div>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.FERTILIZATION_TYPE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="fertilizationTreatmentId"
                        ref={register({ required: true })}
                      >
                        {fertilizationList.length && (
                          <>
                            {fertilizationList.map((fertilizationType) => (
                              <option
                                key={fertilizationType.dtreatmentId}
                                value={fertilizationType.dtreatmentId}
                              >
                                {fertilizationType.dtreatmentDesc}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.FERTILIZER_TYPE" /> *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="fertilizerId"
                        ref={register({ required: true })}
                      >
                        {fertilizers.length && (
                          <>
                            {fertilizers.map((fertilizer) => (
                              <option key={fertilizer.id} value={fertilizer.id}>
                                {fertilizer.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.DOSE" /> *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="dose"
                          type="number"
                          className={`${
                            errors.dose && getValues("dose") > 999999
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.DOSE",
                          })}`}
                          step="0.1"
                          min="0"
                          max="99999"
                          onBlur={(e) =>
                            (e.currentTarget.value = Math.abs(
                              Math.ceil(e.currentTarget.value * 10) / 10
                            ))
                          }
                          ref={register({
                            required: true,
                            min: 0.1,
                            max: 999999,
                          })}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>ha</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.dose && getValues("dose") > 999999 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>
                        <FormattedMessage id="GENERAL.DOSE_PER_AREA" />
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="dosePerArea"
                          type="number"
                          className={`${
                            errors.dosePerArea &&
                            getValues("dosePerArea") > 9999999999
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder={`${intl.formatMessage({
                            id: "PLACEHOLDER.DOSE_PER_AREA",
                          })}`}
                          step="0.1"
                          min="0"
                          max="99999"
                          onBlur={(e) =>
                            (e.currentTarget.value = Math.abs(
                              Math.ceil(e.currentTarget.value * 10) / 10
                            ))
                          }
                          ref={register({
                            required: false,
                            min: 0.1,
                            max: 9999999999,
                          })}
                        />
                      </InputGroup>
                      {errors.dosePerArea &&
                        getValues("dosePerArea") > 9999999999 && (
                          <div
                            className="error invalid-feedback"
                            style={{ display: "block" }}
                          >
                            <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_10000000000" />
                          </div>
                        )}
                    </Form.Group>
                  </>
                )}
                {displayPlantProtection && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <div>
                        <span className="text--bold">
                          <FormattedMessage id="GENERAL.PLANT_PROTECTION_PRODUCT" />
                        </span>
                      </div>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                    {fields.map((field, index) => (
                      <React.Fragment key={field.id}>
                        {index > 0 && (
                          <Form.Group as={Col} md={12}>
                            <div className="separator separator-dashed mt-5 mb-5" />
                          </Form.Group>
                        )}
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.TREATMENT_TYPE" /> *
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name={`plantProtectionProduct[${index}].dtreatmentId`}
                            defaultValue={field.dtreatmentId}
                            ref={register({ required: true })}
                          >
                            {plantProtectionList.length && (
                              <>
                                {plantProtectionList.map((treatment) => (
                                  <FormattedMessage
                                    id={`TREATMENT.${treatment.type}`}
                                    key={`TREATMENT.${treatment.type}`}
                                  >
                                    {(message) => (
                                      <option value={treatment.dtreatmentId}>
                                        {message}
                                      </option>
                                    )}
                                  </FormattedMessage>
                                ))}
                              </>
                            )}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.CHEMISTRY_TYPE" /> *
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name={`plantProtectionProduct[${index}].chemistryId`}
                            defaultValue={field.chemistryId}
                            ref={register({ required: true })}
                            onChange={(e) =>
                              onSelectingPlantProtectionProduct(e, index, field)
                            }
                          >
                            {plantProtectionProducts.length && (
                              <>
                                {plantProtectionProducts.map(
                                  (plantProtectionProduct) => (
                                    <option
                                      key={plantProtectionProduct.id}
                                      value={plantProtectionProduct.id}
                                    >
                                      {plantProtectionProduct.name}
                                    </option>
                                  )
                                )}
                              </>
                            )}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.DOSE" /> *
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name={`plantProtectionProduct[${index}].dose`}
                              type="number"
                              className={`${
                                `errors.plantProtectionProduct[${index}].dose` &&
                                getValues(
                                  `plantProtectionProduct[${index}].dose`
                                ) > 999999
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder={`${intl.formatMessage({
                                id: "PLACEHOLDER.DOSE",
                              })}`}
                              step="0.1"
                              min="0"
                              max="99999"
                              onBlur={(e) =>
                                (e.currentTarget.value = Math.abs(
                                  Math.ceil(e.currentTarget.value * 10) / 10
                                ))
                              }
                              defaultValue={field.dose}
                              ref={register({
                                required: true,
                                min: 0.1,
                                max: 999999,
                              })}
                            />
                          </InputGroup>
                          {`errors.plantProtectionProduct[${index}].dose` &&
                            getValues(`plantProtectionProduct[${index}].dose`) >
                              999999 && (
                              <div
                                className="error invalid-feedback"
                                style={{ display: "block" }}
                              >
                                <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                              </div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" /> *
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name={`plantProtectionProduct[${index}].uomId`}
                            defaultValue={field.uomId}
                            ref={register({ required: true })}
                          >
                            {unitOfMeasurements.length && (
                              <>
                                {unitOfMeasurements.map((uom) => (
                                  <option key={uom.id} value={uom.id}>
                                    {uom.desc}
                                  </option>
                                ))}
                              </>
                            )}
                          </Form.Control>
                        </Form.Group>
                        {pestsList.length > 0 && (
                          <>
                            {pestsList.map((list, index) => (
                              <React.Fragment key={index}>
                                {list.id === field.id &&
                                  list.pests?.length > 0 && (
                                    <>
                                      <Form.Label>
                                        <FormattedMessage id="GENERAL.PEST" />
                                      </Form.Label>
                                      <Form.Control
                                        as="select"
                                        name={`plantProtectionProduct[${index}].pestIds`}
                                        defaultValue={field.pestIds}
                                        ref={register({
                                          required: false,
                                        })}
                                        multiple
                                      >
                                        {list.pests.map((pest) => (
                                          <option key={pest.id} value={pest.id}>
                                            {pest.desc}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </>
                                  )}
                              </React.Fragment>
                            ))}
                          </>
                        )}
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.PREVENTION" />
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name={`plantProtectionProduct[${index}].prevention`}
                            defaultValue={field.prevention}
                            ref={register({ required: false })}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.GRACE_PERIOD" />
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name={`plantProtectionProduct[${index}].gracePeriod`}
                            defaultValue={field.gracePeriod}
                            ref={register({ required: false })}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                          <Form.Label>
                            <FormattedMessage id="GENERAL.COMMENTS" />
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name={`plantProtectionProduct[${index}].remarks`}
                            defaultValue={field.remarks}
                            ref={register({ required: false })}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {index === fields.length - 1 && (
                            <BootstrapButton
                              type="button"
                              variant="primary"
                              style={{
                                width: `${index === 0 ? "100%" : "45%"}`,
                              }}
                              onClick={() => onAddingPlantProtectionProduct()}
                            >
                              <FormattedMessage id="GENERAL.ADD" />
                            </BootstrapButton>
                          )}
                          {index > 0 && (
                            <BootstrapButton
                              type="button"
                              variant="secondary"
                              style={{
                                width: `${
                                  index === fields.length - 1 ? "45%" : "100%"
                                }`,
                              }}
                              onClick={() =>
                                onRemovingPlantProtectionProduct(
                                  field.id,
                                  index
                                )
                              }
                            >
                              <FormattedMessage id="GENERAL.DELETE" />
                            </BootstrapButton>
                          )}
                        </Form.Group>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Form.Row>
            </Col>
            <Col md={7}>
              <TreatmentUpdateMap
                croppingShape={croppingShape}
                treatmentShape={treatmentDetails?.shape?.children}
                resetShape={resetShape}
                setTreatmentMap={setTreatmentMap}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            disabled={
              loading ||
              treatmentMap === null ||
              Object.keys(errors).length !== 0 ||
              checkIfButtonShouldBeDisabled()
                ? true
                : false
            }
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button
            color="default"
            disabled={loading}
            onClick={() => onClosingModal()}
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
