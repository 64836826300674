import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function TermsModal(props) {
  function handleClose() {
    props.close();
  }

  function handleClick() {
    props.accept();
  }

   return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="GENERAL.TERMS_OF_SERVICE" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{whiteSpace: "pre-wrap"}}>
           
          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§1. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_FIVE" />
          </span>
          <br />
          <span>
          {"6. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_SIX" />
          </span>
          <br />
          <span>
          {"7. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_SEVEN" />
          </span>
          <br />
          <span>
          {"8. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_EIGHT" />
          </span>
          <br />
          <span>
          {"9. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_NINE" />
          </span>
          <br />
          <span>
          {"10. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_TEN" />
          </span>
          <br />
          <span>
          {"11. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_ELEVEN" />
          </span>
          <br />
          <span>
          {"12. "}<FormattedMessage id="TERMS_OF_SERVICE.DEFINITION_TWELVE" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§2. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_FIVE" />
          </span>
          <br />
          <span>
          {"6. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_SIX" />
          </span>
          <br />
          <span>
          {"7. "}<FormattedMessage id="TERMS_OF_SERVICE.GENERAL_SEVEN" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§3. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_FIVE" />
          </span>
          <br />
          <span>
          {"6. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_SIX" />
          </span>
          <br />
          <span>
          {"7. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_SEVEN" />
          </span>
          <br />
          <span>
          {"8. "}<FormattedMessage id="TERMS_OF_SERVICE.CONDITION_EIGHT" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§4. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_FIVE" />
          </span>
          <br />
          <span>
          {"6. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_SIX" />
          </span>
          <br />
          <span>
          {"7. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_SEVEN" />
          </span>
          <br />
          <span>
          {"8. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_EIGHT" />
          </span>
          <br />
          <span>
          {"9. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_NINE" />
          </span>
          <br />
          <span>
          {"10. "}<FormattedMessage id="TERMS_OF_SERVICE.AGREEMENT_TEN" />
          </span>

          <br />
          <br />
          
          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§5. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.PAYMENT_FIVE" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§6. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_FIVE" />
          </span>
          <br />
          <span>
          {"6. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_SIX" />
          </span>
          <br />
          <span>
          {"7. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_SEVEN" />
          </span>
          <br />
          <span>
          {"8. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_EIGHT" />
          </span>
          <br />
          <span>
          {"9. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_NINE" />
          </span>
          <br />
          <span>
          {"10. "}<FormattedMessage id="TERMS_OF_SERVICE.RULES_TEN" />
          </span>

          <br />
          <br />

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§7. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.COMPLAINT_FIVE" />
          </span>

          <br />
          <br />          

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§8. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.RESPONSIBILITY_FIVE" />
          </span>

          <br />
          <br />   

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§9. "}<FormattedMessage id="TERMS_OF_SERVICE.CONTARCT_TERMINATION" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.CONTARCT_TERMINATION_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.CONTARCT_TERMINATION_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.CONTARCT_TERMINATION_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.CONTARCT_TERMINATION_FOUR" />
          </span>

          <br />
          <br />   

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§10. "}<FormattedMessage id="TERMS_OF_SERVICE.PROPERTY" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.PROPERTY_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.PROPERTY_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.PROPERTY_THREE" />
          </span>

          <br />
          <br />   

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§11. "}<FormattedMessage id="TERMS_OF_SERVICE.PERSONAL_DATA" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.PERSONAL_DATA_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.PERSONAL_DATA_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.PERSONAL_DATA_THREE" />
          </span>

          <span className="font-weight-bold text-dark-50 pr-1 modal__center">
          {"§12. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING" />
          </span>
          <br />
          <span>
          {"1. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING_ONE" />
          </span>
          <br />
          <span>
          {"2. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING_TWO" />
          </span>
          <br />
          <span>
          {"3. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING_THREE" />
          </span>
          <br />
          <span>
          {"4. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING_FOUR" />
          </span>
          <br />
          <span>
          {"5. "}<FormattedMessage id="TERMS_OF_SERVICE.ENDING_FIVE" />
          </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
          {props.acceptVisible && (
            <Button onClick={handleClick} color="primary">
              <FormattedMessage id="GENERAL.ACCEPT" />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
