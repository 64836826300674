import axios from "axios";

export const ONBOARDINGS_URL = `${process.env.REACT_APP_URL}onboardings`;
export const SHOW_TUTORIALS_URL = `${process.env.REACT_APP_URL}tutorials/showTutorials`;
export const SHOW_ONBOARDINGS_URL = `${process.env.REACT_APP_URL}onboardings/showOnboardings`;

export function getOnboardings() {
  return axios.get(`${ONBOARDINGS_URL}/all`);
}

export function getWatchedOnboardings() {
  return axios.get(`${ONBOARDINGS_URL}/watched`);
}

export function setOnboardingAsWatched(id) {
  return axios.post(`${ONBOARDINGS_URL}/${id}`);
}

export function setShowTutorials(value) {
  if (value) return axios.put(`${SHOW_TUTORIALS_URL}/true`);
  else if (!value) return axios.put(`${SHOW_TUTORIALS_URL}/false`);
}

export function setShowOnboardings(value) {
  if (value) return axios.put(`${SHOW_ONBOARDINGS_URL}/true`);
  else if (!value) return axios.put(`${SHOW_ONBOARDINGS_URL}/false`);
}

export function resetWathedOnboardings() {
  return axios.put(`${ONBOARDINGS_URL}/reset`);
}
