import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export function OperationsFilterForm({ getWorkerOperations }) {
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  async function onFilteringWorkerOperations(e) {
    e.preventDefault();
    setLoading(true);
    const form = {
      dateFrom: moment(fromDate).format("YYYY-MM-DD"),
      dateTo: moment(toDate).format("YYYY-MM-DD"),
    };
    const callback = await getWorkerOperations(form);
    callback && setLoading(false);
  }

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} md={5}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_FROM" /> *
          </Form.Label>
          <DatePicker
            name="fromDate"
            selected={fromDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("from", date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={5}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_TO" /> *
          </Form.Label>
          <DatePicker
            name="toDate"
            selected={toDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("to", date)}
          />
        </Form.Group>
        <Col
          md={2}
          className="text-right mb-6 mb-md-0"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            type="submit"
            disabled={loading}
            onClick={(e) => onFilteringWorkerOperations(e)}
          >
            <FormattedMessage id="GENERAL.FILTER" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
