import { toast } from "react-toastify";
import getIntl from "./getIntl";

export default function displayFeedbackFromHeaders(headers) {
  const intl = getIntl();

  if (headers) {
    const message = "FEEDBACK" + headers.messagesuccess?.substring(15);
    if (message === "FEEDBACK.REPORT_CREATED_EMPTY")
      toast.error(intl.formatMessage({ id: message }));
    else toast.success(intl.formatMessage({ id: message }));
  }
}
