import React from "react";
import { FormattedMessage } from "react-intl";
import { WorkTimeResultRow } from "./WorkTimeResultRow";
import { Table } from "react-bootstrap";

export function WorkTimeResultsTable({ data, onSelectingRow }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.ITEM" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.EMPLOYEE_FIRSTNAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.EMPLOYEE_LASTNAME" />
          </th>
          <th>
            <FormattedMessage id="PREVIEW.item" />
          </th>
          <th>
            <FormattedMessage id="PREVIEW.badge" />
          </th>
          <th>
            <FormattedMessage id="PREVIEW.brigadeName" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.OVERSEER_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.TYPE" />
          </th>
          <th style={{ minWidth: "120px" }}>
            <FormattedMessage id="GENERAL.DURATION_FROM" />
          </th>
          <th style={{ minWidth: "120px" }}>
            <FormattedMessage id="GENERAL.DURATION_TO" />
          </th>
          <th style={{ minWidth: "120px" }}>
            <FormattedMessage id="GENERAL.DURATION" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.RESULTS" />
          </th>
          <th style={{ minWidth: "100px" }}>
            <FormattedMessage id="GENERAL.TARIFF" />
          </th>
      
        </tr>
      </thead>
      <tbody>
        {data.map((result, index) => (
          <WorkTimeResultRow
            key={`result-${result.__workFlowId}`}
            result={result}
            index={index}
            onSelectingRow={onSelectingRow}
          />
        ))}
      </tbody>
    </Table>
  );
}
