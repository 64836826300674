import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/auth/_redux/authRedux";
import * as ui from "../app/components/_redux/uiRedux";
import * as pricing from "../app/modules/user/redux/appPricingReducer";
import * as brigadierCustomTariffRate from "../app/modules/brigadier/redux/customTariffRateReducer";
import * as brigadierSettingsParameters from "../app/modules/brigadier/redux/settingsParametersReducer";
import * as cropViewModal from "../app/modules/croppings/redux/cropViewModalReducer";
import * as suppliers from "../app/components/_redux/suppliersRedux";
import * as calendar from "../app/modules/calendar/redux/calendarReducer";
import * as misc from "./miscReducer";
import * as maps from "../app/modules/maps/redux/mapsReducer";
import * as cmMaps from "../app/modules/crop-manager/redux/cmMapsReducer";
import * as cmDashboard from "../app/modules/crop-manager/redux/cmDashboardReducer";
import fieldsSlice from "../app/modules/fields/redux/fieldsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ui: ui.reducer,
  pricing: pricing.reducer,
  brigadierCustomTariffRate: brigadierCustomTariffRate.reducer,
  brigadierSettingsParameters: brigadierSettingsParameters.reducer,
  cropViewModal: cropViewModal.reducer,
  suppliers: suppliers.reducer,
  calendar: calendar.reducer,
  misc: misc.reducer,
  maps: maps.reducer,
  cmMaps: cmMaps.reducer,
  cmDashboard: cmDashboard.reducer,
  fields: fieldsSlice,
});

export function* rootSaga() {
  yield all([auth.saga(), ui.saga()]);
}
