import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

export function MobileDiagnosticReport(props) {
  return (
    <>
      {props.report ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="GENERAL.DEVICE_ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.STATUS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_REGISTER_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.FIRSTNAME" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.LASTNAME" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PHONE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.CITY" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_SYNCHRONIZATION_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.UNLOADING_DATE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.LONGTITUDE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.LATITUDE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.IS_FAKING_LOCATION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.HAS_INTERNET" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.NETWORK_TYPE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.CELLULAR_GENERATION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.APP_VERSION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.APP_BUILD_VERSION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_MODEL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_BATTERY_LEVEL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_OS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_OS_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_CARRIER_NAME" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DEVICE_TYPE" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.IP_ADDRESS" />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.report.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{row.deviceId}</td>
                <td className="text-center">
                  {row.deviceStatus === 0 && (
                    <Badge pill variant="danger">
                      <FormattedMessage id="GENERAL.INACTIVE" />
                    </Badge>
                  )}
                  {row.deviceStatus === 1 && (
                    <Badge pill variant="success">
                      <FormattedMessage id="GENERAL.ACTIVE" />
                    </Badge>
                  )}
                </td>
                <td className="text-center">
                  {moment(row.deviceCreatedAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-center">{row.firstName}</td>
                <td className="text-center">{row.lastName}</td>
                <td className="text-center">{row.phone}</td>
                <td className="text-center">{row.locationGoogleName}</td>
                <td className="text-center">
                  {moment(row.synchronizedAt).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </td>
                <td className="text-center">
                  {moment(row.createdAt).format("DD.MM.YYYY HH:mm")}
                </td>
                <td className="text-right">{row.longitude}</td>
                <td className="text-right">{row.latitude}</td>
                <td className="text-center">{row.isFakingLocation ? '1': '0'}</td>
                <td className="text-center">{row.hasInternet ? '1': '0'}</td>                
                <td className="text-center">{row.networkType}</td>
                <td className="text-center">{row.cellularGeneration}</td>
                <td className="text-center">{row.deviceAppVersion}</td>
                <td className="text-center">{row.deviceAppBuild}</td>
                <td className="text-center">{row.deviceModel}</td>
                <td className="text-center">{row.deviceBatteryLevel}</td>
                <td className="text-center">{row.deviceOS}</td>
                <td className="text-center">{row.deviceOSNumber}</td>
                <td className="text-center">{row.deviceCarrierName}</td>
                <td className="text-center">{row.deviceType}</td>
                <td className="text-center">{row.deviceIPAddress}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2">
          <h6>
            <FormattedMessage id="PE.NO_REPORTS" />
          </h6>
        </div>
      )}
    </>
  );
}
