import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function GrowersNewOffer(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    triggerValidation,
    getValues,
    errors,
    formState,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "primaryWeight",
      props.growerLimit ? props.growerLimit.toFixed(2) : 0
    );
    setTimeout(() => {
      triggerValidation("primaryWeight");
    });
    // eslint-disable-next-line
  }, [props.growerLimit]);

  async function onSubmit(data) {
    setLoading(true);
    const callback = await props.onCreatingOffer(data);
    if (callback === "OK") {
      resetForm();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const resetForm = () => {
    reset();
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Documents.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="PE.NEW_OFFER" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.WEIGHT" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="primaryWeight"
                  type="number"
                  className={`${errors.primaryWeight?.type === "required" &&
                    "is-invalid"} ${errors.primaryWeight?.type === "min" &&
                    "is-invalid"} ${errors.primaryWeight?.type === "max" &&
                    "is-invalid"} ${!errors.primaryWeight && "is-valid"}`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.WEIGHT_IN_TONNES",
                  })}`}
                  step="0.01"
                  ref={register({
                    required: true,
                    min: 0.1,
                    max: parseFloat(props.growerLimit),
                  })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>t</InputGroup.Text>
                </InputGroup.Append>
                {errors.primaryWeight?.type === "required" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
                {errors.primaryWeight?.type === "min" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                  </div>
                )}
                {errors.primaryWeight?.type === "max" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_AVAIABLE_PULP" />
                  </div>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.PRICE" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  name="price"
                  type="number"
                  className={`${(errors.price?.type === "required" ||
                    errors.price?.type === "min" ||
                    (formState.touched.price && getValues("price") <= 0)) &&
                    "is-invalid"} ${!errors.price &&
                    getValues("price") >= 0.1 &&
                    "is-valid"}`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.PRICE_PER_TON",
                  })}`}
                  step="0.01"
                  ref={register({ required: true, min: 0.1 })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>PLN/t</InputGroup.Text>
                </InputGroup.Append>
                {errors.price?.type === "required" && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                  </div>
                )}
                {(errors.price?.type === "min" ||
                  (formState.touched.price && getValues("price") <= 0)) && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
                  </div>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <Form.Label>
                <FormattedMessage id="GENERAL.COMMENT" />
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="comment"
                ref={register({ required: false })}
              />
            </Form.Group>
            <div className="col-xxl-12 text-right">
              <Button
                type="submit"
                disabled={!props.availableProductId || loading}
              >
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </div>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}
