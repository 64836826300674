import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { ReportsForm } from "./ReportsForm";
import { ContractorsReport } from "./ContractorsReport";
import { GrowersQuantitiesReport } from "./GrowersQuantitiesReport";
import { ContractorsTransactionsReport } from "./ContractorsTransactionsReport";
import { GrowersTransactionsReport } from "./GrowersTransactionsReport";
import { MobileDeliveryReport } from "./MobileDeliveryReport";
import { MobileDiagnosticReport } from "./MobileDiagnosticReport";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function Reports(props) {
  const [key, setKey] = useState("contractors");

  const onChangingTab = (k) => {
    props.cleanReport();
    setKey(k);
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span
            className="agrico-svg-icon svg-icon menu-icon mr-2"
            style={{ marginRight: "0.4rem" }}
          >
            <SVG src={toAbsoluteUrl("/media/icons/Report_2.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.REPORTS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => onChangingTab(k)}>
          <Tab
            eventKey="contractors"
            title={<FormattedMessage id="GENERAL.CONTRACTORS" />}
          >
            <ReportsForm
              type={"CONTRACTOR_DATA"}
              status={props.status}
              onCreatingReport={props.onCreatingReport}
              report={props.report}
              onExportingReportToExcel={props.onExportingReportToExcel}
            />
            <ContractorsReport report={props.report} />
          </Tab>
          <Tab
            eventKey="quantities"
            title={<FormattedMessage id="PE.QUANTITIES_OFFERED_BY_GROWERS" />}
          >
            <ReportsForm
              type={"GROOWNERS_SELL_OFFER"}
              onCreatingReport={props.onCreatingReport}
              report={props.report}
              onExportingReportToExcel={props.onExportingReportToExcel}
            />
            <GrowersQuantitiesReport report={props.report} />
          </Tab>
          <Tab
            eventKey="contractorsTransactions"
            title={<FormattedMessage id="PE.CONTRACTORS_TRANSACTIONS" />}
          >
            <ReportsForm
              type={"CONTRACTOR_BUY_OFFER"}
              onCreatingReport={props.onCreatingReport}
              report={props.report}
              onExportingReportToExcel={props.onExportingReportToExcel}
            />
            <ContractorsTransactionsReport report={props.report} />
          </Tab>
          <Tab
            eventKey="growersTransactions"
            title={<FormattedMessage id="PE.GROWERS_TRANSACTIONS" />}
          >
            <ReportsForm
              type={"GROOWNERS_BUY_OFFER"}
              onCreatingReport={props.onCreatingReport}
              report={props.report}
              onExportingReportToExcel={props.onExportingReportToExcel}
            />
            <GrowersTransactionsReport report={props.report} />
          </Tab>
          {!props.isCoordinator && (
            <Tab
              eventKey="unloading"
              title={<FormattedMessage id="GENERAL.UNLOADING" />}
            >
              <ReportsForm
                type={"MOBILE_DELIVERY"}
                onCreatingReport={props.onCreatingReport}
                report={props.report}
                onExportingReportToExcel={props.onExportingReportToExcel}
              />
              <MobileDeliveryReport report={props.report} />
            </Tab>
          )}
          {!props.isCoordinator && (
            <Tab
              eventKey="diagnostic"
              title={<FormattedMessage id="GENERAL.MOBILE_DIAGNOSTIC" />}
            >
              <ReportsForm
                type={"MOBILE_DIAGNOSTIC"}
                onCreatingReport={props.onCreatingReport}
                report={props.report}
                onExportingReportToExcel={props.onExportingReportToExcel}
              />
              <MobileDiagnosticReport report={props.report} />
            </Tab>
          )}          
        </Tabs>
      </div>
    </div>
  );
}
