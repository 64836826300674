import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GoogleMap,
  Marker,
  Polygon,
  StandaloneSearchBox,
  useJsApiLoader,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { createPolygonStructureWithHoles } from "../../../utils/mapUtils";
import { useSelector } from "react-redux";

const googleMapStyle = {
  width: "100%",
  height: "78vh",
};

const fieldPolygonOptions = {
  fillColor: "#b8cf67",
  fillOpacity: "0.45",
  strokeColor: "#b8cf67",
  strokeWeight: "3",
  clickable: true,
  visible: true,
  zIndex: 1,
};
const sharedByUserFieldPolygonOptions = {
  ...fieldPolygonOptions,
  strokeColor: "#f542d1",
};

const croppingPolygonOptions = {
  fillColor: "#32CD32",
  fillOpacity: "0.45",
  strokeColor: "#32CD32",
  strokeWeight: "3",
  clickable: true,
  visible: true,
  zIndex: 2,
  backgroundColor: "#000",
};
const sharedByUserCroppingPolygonOptions = {
  ...croppingPolygonOptions,
  strokeColor: "#f542d1",
};

const markerClustererOptions = {
  imagePath: "/media/maps/m",
};

const sharedByUserFieldMarkerIcon = {
  url: "/media/maps/shared-by-user-field.png",
  scaledSize: {
    height: 24,
    width: 24,
  },
};
const sharedByUserCroppingMarkerIcon = {
  url: "/media/maps/shared-by-user-cropping.png",
  scaledSize: {
    height: 24,
    width: 24,
  },
};

export function Map(props) {
  const {
    line,
    fields,
    croppings,
    shapeIdToSelect,
    shapeTypeToSelect,
    showShapesAsMarkers,
    centerOnMap,
  } = props;
  const intl = useIntl();
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [center, setCenter] = useState({ lat: 52, lng: 20 });
  const [googleMap, setGoogleMap] = useState(null);
  const [searchbox, setSearchbox] = useState(null);
  const [fieldMarkers, setFieldMarkers] = useState([]);
  const [fieldPolygons, setFieldPolygons] = useState([]);
  const [cropMarkers, setCropMarkers] = useState([]);
  const [cropPolygons, setCropPolygons] = useState([]);
  const [hideWindowBox, setHideWindowBox] = useState(true);
  const [windowBox, setWindowBox] = useState({
    position: { lat: 52, lng: 20 },
  });
  const [content, setContent] = useState(null);
  const [mapFieldShapes, setMapFieldShapes] = useState([]);
  const [mapCroppingsShapes, setMapCroppingsShapes] = useState([]);
  const [clusterInfoBoxData, setClusterInfoBoxData] = useState();
  const userLogin = useSelector((state) => state.ui.userData.login);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION,
  });

  const setCenterAndZoom = () => {
    if (googleMap && line) {
      const latLngList = [];
      line.coordinates.forEach((coordinate) => {
        latLngList.push(
          new window.google.maps.LatLng(coordinate[1], coordinate[0])
        );
      });
      let bounds = new window.google.maps.LatLngBounds();
      latLngList.forEach((row) => {
        bounds.extend(row);
      });
      googleMap.setCenter(bounds.getCenter());
      googleMap.fitBounds(bounds);
      googleMap.setZoom(googleMap.getZoom() - 1);
    } else if (googleMap) {
      googleMap.setZoom(6);
    }
  };

  useEffect(setCenterAndZoom, [googleMap, line]);

  const drawFields = () => {
    if (googleMap && line && fields) {
      const newFieldMarkers = [];
      const newFieldPolygons = [];
      fields.forEach((field) => {
        if (showShapesAsMarkers || (field.point && !field.shape)) {
          newFieldMarkers.push(field);
        } else {
          newFieldPolygons.push(field);
        }
      });
      if (newFieldMarkers.length > 0) {
        drawFieldMarkers(newFieldMarkers);
      } else setFieldMarkers([]);
      if (newFieldPolygons.length) {
        drawFieldPolygons(newFieldPolygons);
      } else {
        setFieldPolygons([]);
      }
    }
  };

  const drawFieldMarkers = (arr) => {
    const points = arr.map((field) => {
      if (field.shape && showShapesAsMarkers)
        return {
          ...field,
          position: {
            lat: field.shape.parent.shape.coordinates[0][0][1],
            lng: field.shape.parent.shape.coordinates[0][0][0],
          },
        };
      return {
        ...field,
        position: {
          lat: field.point.point.coordinates[1],
          lng: field.point.point.coordinates[0],
        },
      };
    });
    setFieldMarkers(points);
  };

  const drawFieldPolygons = (arr) => {
    const polygons = arr.map((field) => {
      const coordinates = createFieldShape(field.shape);
      return {
        ...field,
        coordinates: coordinates,
      };
    });
    setFieldPolygons(polygons);
  };

  const createFieldShape = (shape) => {
    let fieldCoordinates = [];
    let outerCoordinates = [];
    shape.parent.shape.coordinates.forEach((coordinate) => {
      coordinate.forEach((point) => {
        outerCoordinates.push({ lat: point[1], lng: point[0] });
      });
    });
    fieldCoordinates.push(outerCoordinates);
    if (shape.children.length) {
      shape.children.forEach((row) => {
        let innerCoordinates = [];
        row.shape.coordinates.forEach((coordinate) => {
          coordinate.forEach((point) => {
            innerCoordinates.push({ lat: point[1], lng: point[0] });
          });
          fieldCoordinates.push(innerCoordinates);
        });
      });
    }
    return fieldCoordinates;
  };

  useEffect(drawFields, [googleMap, line, fields, showShapesAsMarkers]);

  const drawCroppings = () => {
    if (googleMap && line && croppings) {
      const newCroppingMarkers = [];
      const newCroppingPolygons = [];
      croppings.forEach((cropping) => {
        if (
          showShapesAsMarkers ||
          (cropping.shape === null && cropping.point)
        ) {
          newCroppingMarkers.push(cropping);
        } else {
          newCroppingPolygons.push(cropping);
        }
      });

      if (newCroppingMarkers.length > 0) {
        drawCropMarkers(newCroppingMarkers);
      } else setCropMarkers([]);

      if (newCroppingPolygons.length > 0) {
        drawCropPolygons(newCroppingPolygons);
      } else {
        setCropPolygons([]);
      }
    }
  };

  const drawCropMarkers = (crops) => {
    const points = crops.map((cropping) => {
      if (cropping.shape && showShapesAsMarkers)
        return {
          ...cropping,
          position: {
            lat: cropping.shape.children[0].shape.coordinates[0][0][1],
            lng: cropping.shape.children[0].shape.coordinates[0][0][0],
          },
        };
      return {
        ...cropping,
        position: {
          lat: cropping.point.point.coordinates[1],
          lng: cropping.point.point.coordinates[0],
        },
      };
    });
    setCropMarkers(points);
  };

  const drawCropPolygons = (crops) => {
    let cropsPolygons = [];
    crops.forEach((cropping) => {
      cropping.shape.children.forEach((shape) => {
        cropsPolygons.push({
          ...cropping,
          shape: shape,
        });
      });
    });
    const polygons = cropsPolygons.map((cropping) => {
      const coordinates = createPolygonStructureWithHoles(
        cropping.shape.shape.coordinates
      );

      return {
        ...cropping,
        coordinates: coordinates,
      };
    });
    setCropPolygons(polygons);
  };

  useEffect(drawCroppings, [googleMap, line, croppings, showShapesAsMarkers]);

  const onPlacesChanged = () => {
    const searchedPlace = searchbox.getPlaces()[0];
    setCenter({
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    });
  };

  const handleFieldLoad = (field, data) => {
    field.data = data;
    const newMapFieldShapes = mapFieldShapes;
    if (!mapFieldShapes.find((fs) => fs?.data?.fieldId === data.fieldId))
      newMapFieldShapes.push(field);
    setMapFieldShapes(newMapFieldShapes);
  };

  const handleCroppingLoad = (cropping, data) => {
    cropping.data = data;
    const newMapCroppingsShapes = mapCroppingsShapes;
    if (
      !mapCroppingsShapes.find(
        (cs) => cs?.data?.croppingId === data.croppingId && data.shapeArea
      )
    )
      newMapCroppingsShapes.push(cropping);
    setMapCroppingsShapes(newMapCroppingsShapes);
  };

  const handleMarkerClick = (e, marker, isFromCluster) => {
    if (isFromCluster) setContent(marker.data);
    else setContent(marker);
    setWindowBox({
      ...windowBox,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
    setClusterInfoBoxData(null);
    setTimeout(() => {
      setHideWindowBox(false);
    }, 1);
  };

  const handlePolygonClick = (e, polygon) => {
    setContent(polygon);
    setWindowBox({
      ...windowBox,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
    setClusterInfoBoxData(null);
    setTimeout(() => {
      setHideWindowBox(false);
    }, 1);
  };

  const handleClusterClick = (clustersData) => {
    if (clustersData.markerClusterer.clusters.length === 1) {
      setWindowBox({
        ...windowBox,
        position: {
          lat: clustersData.center.lat(),
          lng: clustersData.center.lng(),
        },
      });
      setTimeout(() => {
        setHideWindowBox(false);
      }, 1);
      setContent(null);
      setClusterInfoBoxData(clustersData.markerClusterer.clusters[0].markers);
    }
  };

  const timeout = () => {
    setTimeout(() => setHideWindowBox(true), 1);
  };

  const getCroppingPosition = (cropping) => {
    if (cropping.shape) {
      return {
        lat: cropping.shape.children[0].shape.coordinates[0][0][1],
        lng: cropping.shape.children[0].shape.coordinates[0][0][0],
      };
    }

    if (cropping.point) {
      return {
        lat: cropping.point.point.coordinates[1],
        lng: cropping.point.point.coordinates[0],
      };
    }
  };

  useEffect(() => {
    if (!shapeTypeToSelect) {
      return;
    }

    let marker;

    if (shapeTypeToSelect === "field") {
      marker = fields.find((row) => row.fieldId === shapeIdToSelect);
    } else {
      marker = croppings.find((row) => row.croppingId === shapeIdToSelect);
    }

    if (marker) {
      setContent(marker);
      const position = getCroppingPosition(marker);
      setWindowBox({
        ...windowBox,
        position: { lat: position.lat, lng: position.lng },
      });
      setTimeout(() => {
        setHideWindowBox(false);
        centerOnMap(
          marker.shape ? marker.shape.parent.shapeId : marker.point.shapeId
        );
      }, 1);
    }
  }, [shapeIdToSelect, shapeTypeToSelect]);

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      mapInstance.setOptions({ mapTypeControl: false });
      setGoogleMap(mapInstance);
    }

    return (
      <GoogleMap
        mapContainerClassName="map map-mobile"
        mapContainerStyle={googleMapStyle}
        mapTypeId={"hybrid"}
        center={center}
        onLoad={onLoad}
        onClick={timeout}
      >
        <StandaloneSearchBox
          onLoad={(searcher) => setSearchbox(searcher)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.SEARCH",
            })}...`}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        <MarkerClusterer
          options={markerClustererOptions}
          onClick={(e) => {
            handleClusterClick(e);
          }}
        >
          {function(clusterer) {
            const clusteredFieldMarkers = fieldMarkers.map((marker) => ({
              ...marker,
              clusterType: "field",
            }));
            const clusteredCropMarkers = cropMarkers.map((marker) => ({
              ...marker,
              clusterType: "cropping",
            }));
            const markers = [...clusteredFieldMarkers, ...clusteredCropMarkers];
            return markers.map((marker) => {
              if (marker.clusterType === "field")
                return (
                  <Marker
                    key={`field-marker-${marker.fieldId}-${marker.shortName}`}
                    position={marker.position}
                    onClick={(e) => handleMarkerClick(e, marker)}
                    onLoad={(e) => handleFieldLoad(e, marker)}
                    clusterer={clusterer}
                    icon={
                      marker.sharedBy?.login === userLogin
                        ? sharedByUserFieldMarkerIcon
                        : ""
                    }
                  />
                );
              else if (marker.clusterType === "cropping")
                return (
                  <Marker
                    key={`crop-marker-${marker.croppingId}-${marker.variety.varietyId}`}
                    position={marker.position}
                    onClick={(e) => handleMarkerClick(e, marker)}
                    onLoad={(e) => handleCroppingLoad(e, marker)}
                    clusterer={clusterer}
                    icon={
                      marker.sharedBy?.login === userLogin
                        ? sharedByUserCroppingMarkerIcon
                        : ""
                    }
                  />
                );
            });
          }}
        </MarkerClusterer>
        {fieldPolygons.map((polygon, index) => (
          <Polygon
            key={`${polygon.fieldId}-${index}`}
            paths={polygon.coordinates}
            options={
              polygon.sharedBy?.login === userLogin
                ? sharedByUserFieldPolygonOptions
                : fieldPolygonOptions
            }
            onClick={(e) => handlePolygonClick(e, polygon)}
            onLoad={(e) => handleFieldLoad(e, polygon)}
          />
        ))}
        {cropPolygons.map((polygon, index) => (
          <Polygon
            key={`${polygon.croppingId}-${index}`}
            paths={polygon.coordinates}
            options={
              polygon.sharedBy?.login === userLogin
                ? sharedByUserCroppingPolygonOptions
                : croppingPolygonOptions
            }
            onClick={(e) => handlePolygonClick(e, polygon)}
            onLoad={(e) => handleCroppingLoad(e, polygon)}
          />
        ))}
        {!hideWindowBox && clusterInfoBoxData && (
          <InfoWindow position={windowBox.position}>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "0.32rem",
                boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
              }}
            >
              <h6 className="windowbox__heading mb-5">
                <FormattedMessage id="GENERAL.CHOOSE_A_PIN" />
              </h6>
              <div className="d-flex flex-column">
                {clusterInfoBoxData.map((marker) => {
                  if (
                    marker.data.clusterType === "field" ||
                    marker.data.clusterType === "field-as-marker"
                  )
                    return (
                      <button
                        key={`${marker.data.clusterType}-${marker.data.shortName}`}
                        className="mb-3 border-0 p-2 text-dark"
                        onClick={(e) =>
                          handleMarkerClick(
                            {
                              latLng: {
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                              },
                            },
                            marker,
                            true
                          )
                        }
                      >
                        <FormattedMessage id="GENERAL.FIELD" />
                        {" - "}
                        {marker.data.shortName}
                      </button>
                    );
                  else if (
                    marker.data.clusterType === "cropping" ||
                    marker.data.clusterType === "cropping-as-marker"
                  )
                    return (
                      <button
                        key={`${marker.data.clusterType}-${marker.data.shortName}`}
                        className="mb-3 border-0 p-2 text-dark"
                        onClick={(e) =>
                          handleMarkerClick(
                            {
                              latLng: {
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                              },
                            },
                            marker,
                            true
                          )
                        }
                      >
                        <FormattedMessage id="GENERAL.CROPPING" />
                        {" - "}
                        {marker.data.shortName}
                      </button>
                    );
                })}
              </div>
            </div>
          </InfoWindow>
        )}
        {!hideWindowBox && content && (
          <InfoWindow position={windowBox.position}>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: "0.32rem",
                boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
              }}
            >
              <div style={{ fontSize: "1rem", fontColor: `#464E5F` }}>
                <h6
                  className={`windowbox__heading mb-5 ${
                    content.fieldId
                      ? "windowbox__heading--field"
                      : "windowbox__heading--cropping"
                  }`}
                >
                  {content.fieldId ? (
                    <FormattedMessage id="GENERAL.FIELD" />
                  ) : (
                    <FormattedMessage id="GENERAL.CROPPING" />
                  )}{" "}
                  - {content.shortName}
                </h6>
                <p className="windowbox__paragraph">
                  <span className="text-muted">
                    {content.fieldId ? (
                      <FormattedMessage id="GENERAL.IRRIGATION" />
                    ) : (
                      <FormattedMessage id="GENERAL.IRRIGATION_CROPPING" />
                    )}
                    :
                  </span>{" "}
                  <span className="text--bold text--uppercase">{`${
                    content.isIrrigated
                      ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                      : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                  }`}</span>
                </p>
                <p className="windowbox__paragraph">
                  {content.fieldId ? (
                    <>
                      <span className="text-muted">
                        <FormattedMessage id="GENERAL.MELIORATION" />:
                      </span>{" "}
                      <span className="text--bold text--uppercase">{`${
                        content.isMeliorated
                          ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                          : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                      }`}</span>
                    </>
                  ) : (
                    <>
                      <span className="text-muted">
                        <FormattedMessage id="GENERAL.CONTRACTUAL_CROPPING" />:
                      </span>{" "}
                      <span className="text--bold text--uppercase">{`${
                        content.isContractual
                          ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                          : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                      }`}</span>
                    </>
                  )}
                </p>
                {content.croppingId && (
                  <p className="windowbox__paragraph">
                    <span className="text-muted">
                      <FormattedMessage id="GENERAL.ECOLOGICAL_CROPPING" />:
                    </span>{" "}
                    <span className="text--bold text--uppercase">{`${
                      content.isEco
                        ? `${intl.formatMessage({ id: "GENERAL.YES" })}`
                        : `${intl.formatMessage({ id: "GENERAL.NO" })}`
                    }`}</span>
                  </p>
                )}
                {content.fieldId && content.species && (
                  <p className="windowbox__paragraph">
                    <span className="text-muted">
                      <FormattedMessage id="GENERAL.SPECIES" />:
                    </span>{" "}
                    <span className="text--bold">{content.species.desc}</span>
                  </p>
                )}
                {content.fieldId && content.variety && (
                  <p className="windowbox__paragraph">
                    <span className="text-muted">
                      <FormattedMessage id="GENERAL.VARIETY" />:
                    </span>{" "}
                    <span className="text--bold">{content.variety.desc}</span>
                  </p>
                )}
                <p className="windowbox__paragraph">
                  <span className="text-muted">
                    <FormattedMessage id="GENERAL.OWNER" />:
                  </span>
                  <span className="text--bold text--uppercase">
                    {content.bpartner.bpartnerFullName}
                  </span>
                </p>
                <p className="windowbox__paragraph">
                  <span className="text-muted">
                    <FormattedMessage id="GENERAL.PARTNER_NUMBER" />:
                  </span>
                  <span className="text--bold text--uppercase">
                    {content.bpartner.number}
                  </span>
                </p>
                {content.supplier?.fullName && (
                  <p className="windowbox__paragraph">
                    <span className="text-muted">
                      <FormattedMessage id="GENERAL.SUPPLIER" />:
                    </span>{" "}
                    <span className="text--bold">
                      {content.supplier.fullName}
                    </span>
                  </p>
                )}
                {content.sharedBy?.login && (
                  <p className="windowbox__paragraph">
                    <span className="text-muted">
                      <FormattedMessage id="GENERAL.SHARED_BY" />:
                    </span>{" "}
                    <span className="text--bold">{content.sharedBy.login}</span>
                  </p>
                )}
                <p className="windowbox__paragraph">
                  <span className="text-muted">
                    {content.shapeArea ? (
                      <FormattedMessage id="GENERAL.CALCULATED_ACREAGE" />
                    ) : (
                      <FormattedMessage id="GENERAL.DECLARED_ACREAGE" />
                    )}
                    :
                  </span>
                  <span className="text--bold">
                    {content.fieldId &&
                      (content.shapeArea
                        ? content.shapeArea.toFixed(2)
                        : content.acreage.toFixed(2))}
                    {content.croppingId &&
                      (content.shapeArea
                        ? content.shapeArea.toFixed(2)
                        : content.areaDeclared.toFixed(2))}
                    ha
                  </span>
                </p>
                {content.croppingId ? (
                  <>
                    <p className="windowbox__paragraph">
                      <span className="text-muted">
                        <FormattedMessage id="GENERAL.CULTIVATED_SPECIES" />:
                      </span>{" "}
                      <span className="text--bold">
                        {content.variety.speciesDesc}
                      </span>
                    </p>
                    <p className="windowbox__paragraph">
                      <span className="text-muted">
                        <FormattedMessage id="GENERAL.CULTIVATED_VARIETY" />:
                      </span>{" "}
                      <span className="text--bold">
                        {content.variety.shortName}
                      </span>
                    </p>
                  </>
                ) : (
                  <></>
                )}
               <p className="windowbox__paragraph">
                  <span className="text-muted">
                    <FormattedMessage id="GENERAL.DESCRIPTION" />:
                  </span>
                  <span className="text--bold text--uppercase">
                    {content.desc}
                  </span>
                </p>
                {content.treatments && (
                  <>
                    {content.treatments.length > 0 && (
                      <>
                        <h6 className="windowbox__heading windowbox__heading--treatment mb-5">
                          <FormattedMessage id="GENERAL.PLANNED_TREATMENTS_ON_CULTIVATION" />
                        </h6>
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.START_DATE" />:
                          </span>{" "}
                          <span className="text--bold text--uppercase">
                            {moment(content.treatments[0].dateStart).format(
                              "DD.MM.YYYY"
                            )}
                          </span>
                        </p>
                        <p className="windowbox__paragraph">
                          <span className="text-muted">
                            <FormattedMessage id="GENERAL.END_DATE" />:
                          </span>{" "}
                          <span className="text--bold text--uppercase">
                            {moment(content.treatments[0].dateEnd).format(
                              "DD.MM.YYYY"
                            )}
                          </span>
                        </p>
                        <p className="windowbox__paragraph">
                          <Row style={{ width: "-webkit-fill-available" }}>
                            <Col xs={6}>
                              <span className="text-muted">
                                <FormattedMessage id="GENERAL.TREATMENTS" />:
                              </span>
                            </Col>
                            <Col
                              xs={6}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {content.treatments[0].treatments.map(
                                (treatment) => (
                                  <div
                                    key={treatment.treatmentHeaderId}
                                    style={{ marginBottom: "0.4rem" }}
                                  >
                                    <FormattedMessage
                                      id={`TREATMENT.${treatment.dtreatmentType}`}
                                    />
                                  </div>
                                )
                              )}
                            </Col>
                          </Row>
                        </p>
                        {(content.treatments[0].isCanceled ||
                          content.treatments[0].isFinished) && (
                          <p className="windowbox__paragraph">
                            <span className="text-muted">
                              <FormattedMessage id="GENERAL.STATUS" />:
                            </span>
                            <span
                              className={`text--bold text--uppercase ${
                                content.treatments[0].isCanceled
                                  ? "text--red"
                                  : "text--green"
                              }`}
                            >
                              {content.treatments[0].isCanceled && (
                                <FormattedMessage id="GENERAL.TREATMENT_CANCELED" />
                              )}
                              {content.treatments[0].isFinished && (
                                <FormattedMessage id="GENERAL.TREATMENT_FINISHED" />
                              )}
                            </span>
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}
