import axios from "axios";

export const FARM_SHARING_URL = `${process.env.REACT_APP_URL}farmSharing`;

export function getDataSharingToBPartnerByLogin(farmId) {
  return axios.get(`${FARM_SHARING_URL}/bPartner/${farmId}/croppings`);
}

export function createDataSharingByLogin(farmId, form) {
  return axios.put(
    `${FARM_SHARING_URL}/bPartner/${farmId}/croppings/update`,
    form
  );
}

export function getDataSharingForBPartner(farmId) {
  return axios.get(`${FARM_SHARING_URL}/bPartner/${farmId}/croppings/shared/group`);
}

export function getOwnCroppingExclusive(farmId) {
  return axios.get(
    `${FARM_SHARING_URL}/bPartner/${farmId}/croppings/exclusive`
  );
}

export function acceptFarmSharingData(farmSharingId) {
  return axios.put(`${FARM_SHARING_URL}/${farmSharingId}/accept`);
}

export function rejectFarmSharingData(farmSharingId) {
  return axios.delete(`${FARM_SHARING_URL}/${farmSharingId}/reject`);
}

export function createIsExclusivePlanter(farmSharingId) {
  return axios.put(`${FARM_SHARING_URL}/${farmSharingId}/exclusive`);
}

export function acceptIsExclusiveByPlanter(farmSharingId) {
  return axios.put(`${FARM_SHARING_URL}/${farmSharingId}/exclusive/accept`);
}

export function rejectIsExclusiveByPlanter(farmSharingId) {
  return axios.delete(`${FARM_SHARING_URL}/${farmSharingId}/exclusive/reject`);
}

export function getFieldSharingByBPartner(farmId) {
  return axios.get(`${FARM_SHARING_URL}/bPartner/${farmId}/fields`);
}

export function createFieldSharingByBPartner(farmId, form) {
  return axios.put(
    `${FARM_SHARING_URL}/bPartner/${farmId}/fields/update`,
    form
  );
}

export function getOwnWaitingFieldToExclusive(farmId) {
  return axios.get(`${FARM_SHARING_URL}/bPartner/${farmId}/fields/exclusive`);
}

export function getDataSharingFieldToBPartner(farmId) {
  return axios.get(`${FARM_SHARING_URL}/bPartner/${farmId}/fields/shared/group`);
}
