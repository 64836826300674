import axios from "axios";

export const INVITATION_URL = `${process.env.REACT_APP_URL}invitations`;

export function getAttributeToInvitation() {
  return axios.get(`${INVITATION_URL}/attribute`);
}

export function createInvitationForPerson(form) {
  return axios.post(`${INVITATION_URL}/bPartner`, form);
}

export function getInvitationsToBPartner() {
  return axios.get(`${INVITATION_URL}/bPartner`);
}

export function resendInvitationByMail(invitationId) {
  return axios.post(`${INVITATION_URL}/resendInv/${invitationId}`, null);
}

export function deleteCreatedInvitation(invitationId) {
  return axios.delete(`${INVITATION_URL}/delete/${invitationId}`);
}

export function acceptInvitation(invitationId, key) {
  return axios.put(`${INVITATION_URL}/${invitationId}/key/${key}`);
}
