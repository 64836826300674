// /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import useSubscriptionsList from "../hooks/useSubscriptionsList";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../_metronic/_partials/dropdowns";
import { compose } from "redux";
import { connect } from "react-redux";
import * as auth from "../modules/auth/_redux/authRedux";
import * as ui from "./_redux/uiRedux";
import { setPortal } from "./_redux/portalCrud";
import store from "../../redux/store";
import errorHandler from "../shared/errorHandler";
import displayFeedback from "./helpers/displayFeedback";
import InlineSVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

function OrganizationSwitch(props) {
  const intl = useIntl();
  const organizationsList = useSubscriptionsList();
  const [organization, setOrganization] = useState();
  const storeState = store.getState();

  useEffect(() => {
    if (organizationsList.length && !storeState.ui.organization) {
      setOrganization(organizationsList[0].orgEntity[0]);
      onSettingPortal(organizationsList[0].orgEntity[0].login2SubscriptionId);
      props.organizationSet(organizationsList[0].orgEntity[0]);
    } else if (organizationsList.length && storeState.ui.organization) {
      setOrganization(storeState.ui.organization);
    }
  }, [organizationsList]);

  const onSettingPortal = (login2SubscriptionId) => {
    setPortal(login2SubscriptionId)
      .then((response) => {
        const userCopy = storeState.auth.user;
        props.login(response.data.accessToken, userCopy);
        props.userDataSet(response.data);
        window.location.reload();
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const setActiveOrganization = (org) => {
    if (organization?.login2SubscriptionId !== org.login2SubscriptionId) {
      setOrganization(org);
      onSettingPortal(org.login2SubscriptionId);
      props.organizationSet(org);
    }
  };

  return (
    <>
      {organizationsList.length > 0 && organization && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-active-organization"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="organization-switch-tooltip">
                  <FormattedMessage id="GENERAL.ORGANIZATION" />:{" "}
                  {organization.orgentityDesc}
                </Tooltip>
              }
            >
              <div className="topbar-item">
                <div
                  className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="organization_toggle"
                >
                  <>
                    <span className="symbol symbol-35 bg-light">
                      <div className="symbol-label">
                        <span className="svg-icon svg-icon-md svg-icon-dark-75">
                          <InlineSVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Home/Building.svg"
                            )}
                          ></InlineSVG>
                        </span>
                      </div>
                    </span>
                  </>
                </div>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
            <p className="font-weight-bold px-5 my-4">
              <FormattedMessage id="GENERAL.SELECT_ORGANIZATION" />:
            </p>
            <ul className="navi navi-hover pb-4">
              {organizationsList.map((organizationItem) => (
                <li
                  key={organizationItem.subscriptionId}
                  className="organization-item"
                >
                  <Dropdown drop="down" alignRight>
                    <Dropdown.Toggle
                      as={DropdownTopbarItemToggler}
                      id="dropdown-toggle-active-test"
                    >
                      <span
                        className={clsx("navi-item", {
                          active:
                            organization.subscriptionId ===
                            organizationItem.subscriptionId,
                        })}
                      >
                        <a href="#" className="navi-link menu-text">
                          {organizationItem.subscriptionBuyer}
                        </a>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                      <ul className="navi navi-hover py-4">
                        {organizationItem.orgEntity.map((org) => (
                          <li
                            key={org.login2SubscriptionId}
                            className={clsx("navi-item", {
                              active:
                                organization.subscriptionId ===
                                org.subscriptionId,
                            })}
                          >
                            <a
                              href="#"
                              onClick={() => setActiveOrganization(org)}
                              className="navi-link"
                            >
                              <span className="symbol symbol-35 symbol-light-success mr-3">
                                <span className="symbol-label font-size-h5 font-weight-bold">
                                  {org.symbol}
                                </span>
                              </span>
                              <span className="navi-text">
                                {org.orgentityDesc}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

// export default connect(null, ui.actions)(OrganizationSwitch);
export default compose(
  connect(null, ui.actions),
  connect(null, auth.actions)
)(OrganizationSwitch);
