import axios from "axios";

export const EVENTS_URL = `${process.env.REACT_APP_URL}events/`;

export function getEventsToLoginBetweenDates(
  farmId,
  from,
  to,
  treatmentTypeIds,
  eventStatusSymbols
) {
  if (treatmentTypeIds.length > 0) {
    return axios.get(
      `${EVENTS_URL}bPartner/${farmId}/from/${from}/to/${to}/?treatmentTypeIds=${treatmentTypeIds}${
        eventStatusSymbols.length > 0
          ? `&eventStatuses=${eventStatusSymbols}`
          : ""
      }`
    );
  } else {
    return axios.get(`${EVENTS_URL}bPartner/${farmId}/from/${from}/to/${to}`);
  }
}

export function getOldestEventsToLogin(activeBPartnerId) {
  return axios.get(`${EVENTS_URL}bPartner/${activeBPartnerId}/old`);
}

export function cancelEvent(eventId) {
  return axios.put(`${EVENTS_URL}${eventId}/cancel`, null);
}

export function finishEvent(eventId) {
  return axios.put(`${EVENTS_URL}${eventId}/finish`, null);
}

export function activateEvent(eventId) {
  return axios.put(`${EVENTS_URL}${eventId}/active`, null);
}

export function getEventStatuses() {
  return axios.get(`${EVENTS_URL}attribute`);
}
