import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function RemoveSaleOfferModal(props) {
  function handleClose() {
    props.close();
  }

  function handleClick() {
    props.onDeleteOffer();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="GENERAL.REMOVE_OFFER" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="GENERAL.REMOVE_OFFER_MESSAGE" />?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="primary">
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button onClick={handleClose} color="default">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
