const initialPricingState = {
  priceList: [],
  selectedApplication: undefined,
  step: 1,
  selectedPlan: undefined,
  cmTime: "monthly",
  addresses: undefined,
  discount: undefined,
};

export const reducer = (state = initialPricingState, action) => {
  switch (action.type) {
    case "pricing/setPriceList": {
      const { priceList } = action.payload;

      return { ...state, priceList };
    }
    case "pricing/setSelectedApplication": {
      const { selectedApplication } = action.payload;

      return { ...state, selectedApplication };
    }
    case "pricing/setStep": {
      const { step } = action.payload;

      return { ...state, step };
    }
    case "pricing/setSelectedPlan": {
      const { selectedPlan } = action.payload;

      return { ...state, selectedPlan };
    }
    case "pricing/setCmTime": {
      const { cmTime } = action.payload;

      return { ...state, cmTime };
    }
    case "pricing/setSelectedPlanBrigadierCount": {
      const { brigadierCount } = action.payload;

      return {
        ...state,
        selectedPlan: { ...state.selectedPlan, brigadierCount },
      };
    }
    case "pricing/setSelectedPlanParameterCount": {
      const { count, paramId } = action.payload;
      const newParametersData = state.selectedPlan.data.parameters.map(
        (param) => {
          if (param.sparameterId === paramId) return { ...param, value: count };
          else return { ...param };
        }
      );
      newParametersData.forEach((param) => {
        if (param.symbol.includes("COURIER")) param.value = 0;
      });
      let courierType;
      if (newParametersData.find((param) => param.symbol === "WEIGHT").value)
        courierType = "COURIER_WEIGHT";
      else if (
        newParametersData.find(
          (param) => param.symbol === "INDUSTRIAL_SMARTPHONE"
        ).value
      )
        courierType = "COURIER_SMARTPHONE";
      else if (
        newParametersData.find((param) => param.symbol === "PACKAGE_ITEM")
          .value ||
        newParametersData.find(
          (param) => param.symbol === "BRIGADIER_IDENTIFIER"
        ).value
      )
        courierType = "COURIER_ITEM_IDENTIFIER";
      let courierId;
      if (courierType)
        courierId = newParametersData.findIndex(
          (param) => param.symbol === courierType
        );
      if (courierId) newParametersData[courierId].value = 1;

      return {
        ...state,
        selectedPlan: {
          ...state.selectedPlan,
          data: { ...state.selectedPlan.data, parameters: newParametersData },
        },
      };
    }
    case "pricing/setAddresses": {
      const { address, invoiceAddress, contactDetail, basicData } = action.payload;

      return {
        ...state,
        addresses: { address, invoiceAddress, contactDetail, basicData },
      };
    }
    case "pricing/setDiscount": {
      const { discount } = action.payload;

      return {
        ...state,
        discount,
      };
    }
    case "pricing/reset": {
      return initialPricingState;
    }

    default:
      return state;
  }
};
