import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { UserSubscriptionsTable } from "../components/UserSubscriptionsTable";
import { SharedWithUserSubscriptionsTable } from "../components/SharedWithUserSubscriptionsTable";
import { ModuleAdvertismentCard } from "../../../components/ModuleAdvertismentCard";
import { Row, Col } from "react-bootstrap";
import { Switch, useRouteMatch, Route } from "react-router";
import ApplicationPricing from "../components/ApplicationPricing";

export function SubscriptionsPage(props) {
  const { userSubscriptions, sharedWithUserSubscriptions, applications } = props;
  const modules = [
    {
      id: 1,
      title: "Brigadier",
      logo: "brigadier__logo.png",
      description: <FormattedMessage id="BRIGADIER.MODULE_DESCRIPTION" />,
      url: "https://cropchart.net/brigadier",
      buyId: applications?.find(app => app.name === "Brigadier")?.applicationId,
      symbol: "BRIGADIER"
    },
    {
      id: 2,
      title: "Cropchart Manager",
      logo: "cropmanager__logo.png",
      description: <FormattedMessage id="CROPMANAGER.MODULE_DESCRIPTION" />,
      url: "https://cropchart.net/cropchart-manager",
      buyId: applications?.find(app => app.name === "CropManager")?.applicationId,
      symbol: "CROPMANAGER"
    },
  ];

  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/buy/:applicationId`}>
        <ApplicationPricing />
      </Route>
      <Route path={match.path}>
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <span className="agrico-svg-icon svg-icon menu-icon mr-2">
                <SVG src={toAbsoluteUrl("/media/icons/User.svg")} />
              </span>
              <h3 className="card-label">
                <FormattedMessage id="GENERAL.SUBSCRIPTIONS" />
              </h3>
            </div>
          </div>
          <div className="card-body">
            <FormattedMessage id="GENERAL.USER_SUBSCRIPTIONS_MESSAGE" />.
            <UserSubscriptionsTable userSubscriptions={userSubscriptions} />
            <FormattedMessage id="GENERAL.SHARED_SUBSCRIPTIONS_MESSAGE" />.
            <SharedWithUserSubscriptionsTable
              sharedWithUserSubscriptions={sharedWithUserSubscriptions}
            />
            <Row>
              {modules.map((module) => (
                <Col key={`module-${module.id}`} sm={6}>
                  <ModuleAdvertismentCard module={module} userSubscriptions={userSubscriptions} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Route>
    </Switch>
  );
}

export default SubscriptionsPage;
