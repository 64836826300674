import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

export function ContractorsReport(props) {
  return (
    <>
      {props.report ? (
        <Table striped bordered hover responsive className="mt-2">
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="GENERAL.ID" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.STATUS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.APPLICATION_SUBMITTING_DATE" />
              </th>
              <th>
                <FormattedMessage id="PE.AUTHORIZING_DEPARTMENT" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.CONTRACTOR" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.NIP" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PESEL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ADDRESS" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.TELEPHONE_NUMBER" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.EMAIL" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.COMMENT" />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.report.map((row, index) => (
              <tr key={index} className="text-center">
                <td>{row.supCustId}</td>
                <td>
                  {row.supCustStatus === "A" && (
                    <Badge pill variant="success">
                      <FormattedMessage id="GENERAL.ACTIVE" />
                    </Badge>
                  )}
                  {row.supCustStatus === "I" && (
                    <Badge pill variant="danger">
                      <FormattedMessage id="GENERAL.INACTIVE" />
                    </Badge>
                  )}
                  {row.supCustStatus === "E" && (
                    <Badge pill variant="info">
                      <FormattedMessage id="GENERAL.WAITING" />
                    </Badge>
                  )}
                  {row.supCustStatus === "R" && (
                    <Badge pill variant="warning">
                      <FormattedMessage id="GENERAL.REJECTED" />
                    </Badge>
                  )}
                </td>
                <td>{moment(row.applicationAt).format("DD.MM.YYYY HH:mm")}</td>
                <td>{row.orgentityDesc}</td>
                <td>{row.supCustFullName}</td>
                <td>{row.vatin}</td>
                <td>{row.pesel}</td>
                <td>
                  {row.postalCode} {row.address1}, {row.address2}
                </td>
                <td>{row.phoneNumber}</td>
                <td style={{minWidth: "280px"}}>{row.email}</td>
                <td>{row.comment}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center mt-2">
          <h6>
            <FormattedMessage id="PE.NO_REPORTS" />
          </h6>
        </div>
      )}
    </>
  );
}
