import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { CoordinatorInformationBar } from "../../../components/CoordinatorInformationBar";
import { GrowersTable } from "../components/GrowersTable";
import { GrowersNewOffer } from "../components/GrowersNewOffer";
import { GrowersOfferDetails } from "../components/GrowersOfferDetails";
import FirstVisitToPulpExchangeModal from "../modals/FirstVisitToPulpExchangeModal";
import {
  getGrowerLimits,
  getGrowerOffers,
  createSellOffer,
  updateSellOffer,
  deleteSellOffer,
  acceptBuyOffer,
  negotiateBuyOffer,
  rejectBuyOffer,
} from "../../../services/growersCrud";
import {
  acceptFirstLoginInit,
  acceptFirstLoginFinish,
} from "../../../services/loginCrud";
import {
  getPinToAcceptPaidLicence,
  acceptPaidLicenceFinish,
} from "../../../services/licenceCrud";
import { connect } from "react-redux";
import * as ui from "../../../components/_redux/uiRedux";
import Col from "react-bootstrap/Col";
import SockJsClient from "react-stomp";
import store from "../../../../redux/store";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

export function GrowersPage(props) {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [growerLimit, setGrowerLimit] = useState();
  const [availableProductId, setAvailableProductId] = useState();
  const [growerOffers, setGrowerOffers] = useState([]);
  const [activeOffer, setActiveOffer] = useState();
  const [createMode, setCreateMode] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const storeState = store.getState();

  const checkIfTermsAcceptedByGrower = () => {
    storeState.ui.userData._subscriptions.forEach((subscription) => {
      if (subscription.symbol === "GW") {
        subscription.orgentities[0].orgentities.forEach((organization) => {
          if (
            storeState.ui.userData.activeOrgentity.id ===
            organization.orgentityId
          ) {
            !organization.isFirstLogin
              ? setOpenModal(false)
              : setOpenModal(true);
          }
        });
      }
    });
  };

  useEffectOnce(checkIfTermsAcceptedByGrower);

  const getAllGrowerLimits = () => {
    getGrowerLimits()
      .then((response) => {
        const realLimit = response.data.limit - response.data.limitUsed;
        setGrowerLimit(parseFloat(realLimit.toFixed(2)));
        setAvailableProductId(response.data.availableProductId);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getAllGrowerLimits);

  const getAllGrowerOffers = () => {
    getGrowerOffers()
      .then((response) => {
        response.data.forEach((row) => {
          row.primaryWeight = row.primaryWeight.toFixed(2);
          row.price = row.price.toFixed(2);
        });
        setGrowerOffers(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getAllGrowerOffers);

  const onSelectingOffer = (offerId) => {
    if (offerId) {
      const searchedOffer = growerOffers.filter((offer) => {
        return offer.sellOfferId === offerId;
      });
      setActiveOffer(searchedOffer[0]);
      setCreateMode(false);
    } else {
      setActiveOffer(null);
      setCreateMode(true);
    }
  };

  const onChangingMode = () => {
    setCreateMode(true);
  };

  const addSellOffer = (form) => {
    return new Promise((resolve) => {
      createSellOffer(availableProductId, form)
        .then((response) => {
          resolve("OK");
          let newOffer = response.data;
          newOffer.primaryWeight = parseFloat(newOffer.primaryWeight).toFixed(
            2
          );
          newOffer.price = parseFloat(newOffer.price).toFixed(2);
          setGrowerOffers([...growerOffers, newOffer]);
          getAllGrowerLimits();
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifySellOffer = (form) => {
    return new Promise((resolve) => {
      updateSellOffer(activeOffer.sellOfferId, form)
        .then((response) => {
          resolve("OK");
          modifyOffer(form);
          getAllGrowerLimits();
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyOffer = (form) => {
    form.primaryWeight = parseFloat(form.primaryWeight).toFixed(2);
    form.price = parseFloat(form.price).toFixed(2);
    const newGrowerOffers = [...growerOffers];
    let newActiveOffer = { ...activeOffer };
    newActiveOffer.primaryWeight = form.primaryWeight;
    newActiveOffer.price = form.price;
    newActiveOffer.comment = form.comment;
    setActiveOffer(newActiveOffer);
    let searchedOffer = newGrowerOffers.find((offer) => {
      return offer.sellOfferId === activeOffer.sellOfferId;
    });
    searchedOffer.primaryWeight = form.primaryWeight;
    searchedOffer.price = form.price;
    searchedOffer.comment = form.comment;
  };

  const removeSellOffer = () => {
    deleteSellOffer(activeOffer.sellOfferId)
      .then((response) => {
        setActiveOffer(null);
        getAllGrowerLimits();
        getAllGrowerOffers();
        displayFeedbackFromHeaders(response.headers);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const acceptContractorOffer = (buyOfferId) => {
    return new Promise((resolve) => {
      acceptBuyOffer(buyOfferId)
        .then((response) => {
          modifyBuyOffer(response.data);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const negotiateContractorOffer = (buyOfferId, form) => {
    return new Promise((resolve) => {
      negotiateBuyOffer(buyOfferId, form)
        .then((response) => {
          modifyBuyOffer(response.data);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const rejectContractorOffer = (buyOfferId, form) => {
    return new Promise((resolve) => {
      rejectBuyOffer(buyOfferId, form)
        .then((response) => {
          modifyBuyOffer(response.data);
          resolve("OK");
          displayFeedbackFromHeaders(response.headers);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyBuyOffer = (modifiedOffer) => {
    setActiveOffer(modifiedOffer);
    const newGrowerOffers = [...growerOffers];
    let searchedOffer = newGrowerOffers.find((offer) => {
      return offer.sellOfferId === activeOffer.sellOfferId;
    });
    searchedOffer.status = modifiedOffer.status;
    searchedOffer.buyOffers = modifiedOffer.buyOffers;
  };

  const onClosingModal = () => {
    setOpenModal(false);
    setTimeout(() => {
      props.history.push("/dashboard");
    });
  };

  const onAcceptingPhoneNumber = (form) => {
    return new Promise((resolve) => {
      acceptFirstLoginInit(form)
        .then(() => {
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onEnteringConfirmationCode = (form) => {
    delete form.phone;
    return new Promise((resolve) => {
      acceptFirstLoginFinish(form)
        .then(() => {
          modifyStore();
          resolve("OK");
          setOpenModal(false);
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyStore = () => {
    const uiOrganization = storeState.ui.organization;
    const uiUserData = storeState.ui.userData;
    uiUserData._subscriptions.forEach((subscription) => {
      if (subscription.symbol === "GW") {
        subscription.orgentities[0].orgentities.forEach((organization) => {
          if (uiUserData.activeOrgentity.id === organization.orgentityId) {
            organization.isFirstLogin = false;
          }
        });
      }
    });
    localStorage.removeItem("persist:ui");
    setTimeout(() => {
      props.organizationSet(uiOrganization);
    }, 50);
    setTimeout(() => {
      props.userDataSet(uiUserData);
    }, 100);
  };

  const onEnteringPhoneNumberForPayment = (licenceId) => {
    return new Promise((resolve) => {
      getPinToAcceptPaidLicence(licenceId)
        .then(() => {
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const onEnteringPinForPayment = (licenceId, form) => {
    return new Promise((resolve) => {
      acceptPaidLicenceFinish(licenceId, form)
        .then(() => {
          modifyBuyOfferSymbol(licenceId);
          resolve("OK");
        })
        .catch((error) => {
          resolve("error");
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    });
  };

  const modifyBuyOfferSymbol = (licenceId) => {
    const newGrowerOffers = [...growerOffers];
    newGrowerOffers.forEach((growerOffer) => {
      growerOffer.buyOffers.forEach((buyOffer) => {
        if (buyOffer.licenceId === licenceId) {
          buyOffer.symbol = "P";
        }
      });
    });
  };

  const onReceivingResponse = (msg) => {
    const newGrowerOffers = [...growerOffers];
    if (msg.status === "SA") {
      const newGrowerOffers = [...growerOffers];
      const newOffer = createBuyOfferModel(msg);
      newGrowerOffers.forEach((sellOffer) => {
        if (sellOffer.sellOfferId === msg.sellOfferId) {
          sellOffer.buyOffers.push(newOffer);
        }
      });
      setGrowerOffers(newGrowerOffers);
    } else if (msg.status === "BU") {
      newGrowerOffers.forEach((growerOffer) => {
        if (growerOffer.sellOfferId === msg.sellOfferId) {
          growerOffer.buyOffers.forEach((buyOffer) => {
            if (buyOffer.buyOfferId === msg.buyOfferId) {
              buyOffer.comment = msg.buyOfferComment;
              buyOffer.price = msg.price;
              buyOffer.primaryWeight = msg.primaryWeight;
              buyOffer.statusCreatedAt = msg.statusCreatedAt;
              buyOffer.offerStatus = msg.buyOfferStatus;
            }
          });
        }
      });
      setGrowerOffers(newGrowerOffers);
      setRefresh(true);
      setTimeout(() => {
        setRefresh(false);
      });
    }
  };

  const createBuyOfferModel = (data) => {
    const obj = {
      buyOfferId: data.buyOfferId,
      comment: data.buyOfferComment,
      contractor: {
        bpartnerFullName: data.bpartnerFullName,
        bpartnerId: data.bpartnerId,
        bpartnerShortName: data.bpartnerShortName,
        contacts: data.contacts || [],
        supCustId: data.supCustId,
      },
      createdAt: null,
      finalPrice: null,
      finalWeight: null,
      licenceId: data.licenceId,
      offerStatus: "W",
      price: data.price,
      primaryWeight: data.primaryWeight,
      statusCreatedAt: data.statusCreatedAt || null,
      symbol: "S",
    };
    return obj;
  };

  return (
    <>
      <div className="row">
        <SockJsClient
          url={`${process.env.REACT_APP_WS_URL}`}
          topics={[
            `/responseQueue/bPartner/${storeState.ui.userData.bbartner.bpartnerId}`,
          ]}
          onMessage={(msg) => onReceivingResponse(msg)}
        />
        <FirstVisitToPulpExchangeModal
          open={openModal}
          close={onClosingModal}
          onAcceptingPhoneNumber={onAcceptingPhoneNumber}
          onEnteringConfirmationCode={onEnteringConfirmationCode}
        />
        <Col md={12}>
          <CoordinatorInformationBar />
        </Col>
        <div className="col-md-12 col-lg-7 col-xxl-7">
          <GrowersTable
            growerLimit={growerLimit}
            offers={growerOffers}
            onSelectingOffer={onSelectingOffer}
            createMode={createMode}
            onChangingMode={onChangingMode}
          />
        </div>
        <div className="col-md-12 col-lg-5 col-xxl-5">
          {!activeOffer && (
            <GrowersNewOffer
              growerLimit={growerLimit}
              availableProductId={availableProductId}
              onCreatingOffer={addSellOffer}
            />
          )}
          {activeOffer && (
            <GrowersOfferDetails
              offer={activeOffer}
              onUpdatingOffer={modifySellOffer}
              onDeleteOffer={removeSellOffer}
              onAcceptingOffer={acceptContractorOffer}
              onNegotiatingOffer={negotiateContractorOffer}
              onRejectingOffer={rejectContractorOffer}
              onEnteringPhoneNumberForPayment={onEnteringPhoneNumberForPayment}
              onEnteringPinForPayment={onEnteringPinForPayment}
              refresh={refresh}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default connect(null, ui.actions)(GrowersPage);
