import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export default function RejectBuyOfferModal(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  async function onSubmit(data) {
    setLoading(true);
    const callback = await props.onRejectingOffer(props.buyOfferId, data);
    if (callback === "OK") {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
    setLoading(false);
  }

  function handleClose() {
    props.close();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="scroll-dialog-title">
            <FormattedMessage id="PE.REJECTION_CONFIRMATION" />
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>
              <FormattedMessage id="PE.REJECTION_CONFIRMATION_MESSAGE" />.
            </DialogContentText>

            <Form.Row>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.COMMENT" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="comment"
                    type="text"
                    placeholder={`${intl.formatMessage({
                      id: "GENERAL.COMMENT",
                    })}`}
                    ref={register({
                      required: false,
                    })}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" disabled={loading}>
              <FormattedMessage id="GENERAL.ACCEPT" />
            </Button>
            <Button color="default" disabled={loading} onClick={handleClose}>
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
