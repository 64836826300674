import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";

export function FieldsRelatedCroppingsTable({ croppings }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.CROPPING" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACREAGE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.DESCRIPTION" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {croppings.map((cropping) => (
          <tr key={cropping.croppingId} className="text-center">
            <td>{cropping.shortName}</td>
            <td>
              {cropping.shapeArea === null
                ? cropping.areaDeclared
                : cropping.shapeArea.toFixed(2)}
            </td>
            <td>{cropping.desc}</td>
            <td>
              <Link
                to={`/farm/croppings?id=${cropping.croppingId}`}
                className="button__link--primary"
              >
                <Button type="button" variant="secondary">
                  <FormattedMessage id="GENERAL.GO_TO_CROPPING" />
                </Button>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
