import React from "react";
import { FormattedMessage } from "react-intl";
import { Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function CroppingsTable({ croppings, onCenteringOnMap }) {
  return (
    <Table className="mt-5" striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.SHORT_NAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.ACTIONS" />
          </th>
        </tr>
      </thead>
      <tbody>
        {croppings.map((cropping) => (
          <tr key={cropping.croppingId} className="text-center">
            <td>{cropping.shortName}</td>
            <td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="user-tooltip">
                    <FormattedMessage id="GENERAL.SHOW_ON_MAP" />
                  </Tooltip>
                }
              >
                <Button
                  onClick={() =>
                    onCenteringOnMap(
                      cropping.shape
                        ? cropping.shape.children[0].shapeId
                        : cropping.point.shapeId
                    )
                  }
                >
                  <i
                    className="flaticon2-location"
                    style={{ paddingLeft: "4px" }}
                  />
                </Button>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
