import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { getTutorialLinks } from "../../../services/tutorialsCrud"
import { FormattedMessage } from "react-intl";
import ToggleTutorials from "../../../components/ToggleTutorials";

export function HelpCenterPage() {
  const [tutorialLinks, setTutorialLinks] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)

  useEffect(() => {
    getTutorialLinks()
      .then((response) => {
        setTutorialLinks(response.data)
      })
  }, [])

  const getVideoId = (url) => {
    return new URL(url).searchParams.get("v")
  }

  return (
    <>
      {selectedVideo && (
        <Dialog
          open={!!selectedVideo}
          onClose={() => setSelectedVideo(null)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title-selected-video"
          maxWidth={false}
        >
          <DialogTitle id="scroll-dialog-title-selected-video">
            <span className="mui-dialog-popup__title">
              <FormattedMessage id={selectedVideo.name} />  
            </span>
            <button className="bg-transparent border-0 mui-dialog-popup__close-btn" onClick={() => setSelectedVideo(null)}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
            </button>
          </DialogTitle>
          <DialogContent>
            <div className="video-popup-wrapper">
              <iframe
                src={`https://www.youtube.com/embed/${getVideoId(selectedVideo.source)}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
                title={selectedVideo.title}
              ></iframe>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className="card card-custom gutter-b">
        <div className="card-body py-3 mb-n2">
          <ToggleTutorials />
        </div>
      </div>
      {tutorialLinks && tutorialLinks.mainLinks?.length > 0 && (
        <React.Fragment>
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">
                  Cropchart
                </h3>
              </div>
            </div>
            <div className="card-body">
              <Row className="mb-n4">
                {tutorialLinks.mainLinks.map((video, videoIndex) => (
                  <Col sm={4} className="mb-4" key={`mainLinks-video-${videoIndex}-${video.id}`}>
                    <div className="d-flex flex-column align-items-center">
                      <button className="border-0 p-0 position-relative" onClick={() => setSelectedVideo(video)}>
                        <img src={`https://img.youtube.com/vi/${getVideoId(video.source)}/maxresdefault.jpg`} alt="" className="d-block w-100 h-100 rounded" style={{width: "100%", height: "auto", objectFit: "cover"}} />
                        <span className="d-block position-absolute mui-dialog-thumbnail__play-btn">▶</span>
                      </button>
                      <p className="mt-2 text-center">{video.name}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </React.Fragment>
      )}
      {tutorialLinks && tutorialLinks.applicationLinks?.length > 0 && tutorialLinks.applicationLinks.map((app, appIndex) => (
        <React.Fragment key={`applicationLinks-${app.application.applicationId}-${appIndex}`}>
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">
                  {app.application.name}
                </h3>
              </div>
            </div>
            <div className="card-body">
              <Row className="mb-n4">
                {app.links.map((video, videoIndex) => (
                  <Col sm={4} className="mb-4" key={`applicationLinks-${app.application.applicationId}-${appIndex}-video-${videoIndex}`}>
                    <div className="d-flex flex-column align-items-center">
                      <button className="border-0 p-0 position-relative" onClick={() => setSelectedVideo(video)}>
                        <img src={`https://img.youtube.com/vi/${getVideoId(video.source)}/maxresdefault.jpg`} alt="" className="d-block w-100 h-100 rounded" style={{width: "100%", height: "auto", objectFit: "cover"}} />
                        <span className="d-block position-absolute mui-dialog-thumbnail__play-btn">▶</span>
                      </button>
                      <p className="mt-2 text-center">{video.name}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  )
}

export default HelpCenterPage;
