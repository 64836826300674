import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { SharedFieldDataContainer } from "../components/SharedFieldDataContainer";
import { SharedCroppingDataContainer } from "../components/SharedCroppingDataContainer";
import { ExclusiveFieldDataRequestsContainer } from "../components/ExclusiveFieldDataRequestsContainer";
import { ExclusiveDataRequestsContainer } from "../components/ExclusiveDataRequestsContainer";

export function ProvidedInformationPage({
  sharedFieldData,
  sharedData,
  acceptSharedData,
  rejectSharedData,
  exclusiveFieldRequests,
  exclusiveRequests,
  requestExclusivitySharedData,
  acceptExclusiveRequest,
  rejectExclusiveRequest,
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Documents.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.PROVIDED_INFORMATION" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <SharedFieldDataContainer
          sharedData={sharedFieldData}
          acceptSharedData={acceptSharedData}
          rejectSharedData={rejectSharedData}
          requestExclusivitySharedData={requestExclusivitySharedData}
        />
        <SharedCroppingDataContainer
          sharedData={sharedData}
          acceptSharedData={acceptSharedData}
          rejectSharedData={rejectSharedData}
          requestExclusivitySharedData={requestExclusivitySharedData}
        />
        <ExclusiveFieldDataRequestsContainer
          exclusiveRequests={exclusiveFieldRequests}
          acceptExclusiveRequest={acceptExclusiveRequest}
          rejectExclusiveRequest={rejectExclusiveRequest}
        />
        <ExclusiveDataRequestsContainer
          exclusiveRequests={exclusiveRequests}
          acceptExclusiveRequest={acceptExclusiveRequest}
          rejectExclusiveRequest={rejectExclusiveRequest}
        />
      </div>
    </div>
  );
}

export default ProvidedInformationPage;
