const createPolygonStructureWithHoles = (coordinates) => {
  let cropCoordinates = [];
  let outerCoordinates = [];
  coordinates.forEach((coordinate, index) => {
    if (index === 0) {
      coordinate.forEach((row) => {
        outerCoordinates.push({ lat: row[1], lng: row[0] });
      });
      cropCoordinates.push(outerCoordinates);
    } else {
      let innerCoordinates = [];
      coordinate.forEach((row) => {
        innerCoordinates.push({ lat: row[1], lng: row[0] });
      });
      cropCoordinates.push(innerCoordinates);
    }
  });
  return cropCoordinates;
}

export {createPolygonStructureWithHoles};
