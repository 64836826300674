import React, { useEffect } from "react";
import { usePreferences } from "../../../hooks/usePreferences";
import { useSubscriptionRole } from "../../../hooks/useSubscriptionRole";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import TariffsPage from "./TariffsPage";
import PackagingPage from "./PackagingPage";
import WorkersPage from "./WorkersPage";
import WorkTimeEditingPage from "./WorkTimeEditingPage";
import ResultsEditingPage from "./ResultsEditingPage";
import ReceivablesPage from "./ReceivablesPage";
import SettingsPage from "./SettingsPage";
import DashboardPage from "./DashboardPage";
import { getSettingsParameters } from "../../../services/brigadier/settingsCrud";
import errorHandler from "../../../shared/errorHandler";
import displayFeedback from "../../../components/helpers/displayFeedback";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

export function BrigadierPage() {
  const intl = useIntl()
  const showBrigadier = usePreferences("BRIGADIER");
  const isAdmin = useSubscriptionRole(["BT", "BB", "BS", "BP"], "ROLE_ADMIN");
  const isExtendedOverseer = useSubscriptionRole(
    ["BT", "BB", "BS", "BP"],
    "ROLE_BRIGADIER_EXTEND"
  );
  const isOverseer = useSubscriptionRole(
    ["BT", "BB", "BS", "BP"],
    "ROLE_BRIGADIER"
  );

  const dispatch = useDispatch()

  useEffect(() => {
    getSettingsParameters()
      .then((response) => {
        dispatch({
          type: "brigadier/settingsParameters/set",
          payload: response.data
        })
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }, [dispatch, intl])

  return (
    <>
      {showBrigadier !== null && (
        <div className="brigadier-theme">
          <Switch>
            {showBrigadier && isOverseer === false ? (
              <>
                <ContentRoute
                  path="/brigadier/dashboard"
                  component={DashboardPage}
                />
                <ContentRoute
                  path="/brigadier/folders/tariffs"
                  component={TariffsPage}
                />
                <ContentRoute
                  path="/brigadier/folders/packaging"
                  component={PackagingPage}
                />
                <ContentRoute
                  path="/brigadier/folders/workers"
                  component={WorkersPage}
                />
                <ContentRoute
                  path="/brigadier/results"
                  component={ResultsEditingPage}
                />
                <ContentRoute
                  path="/brigadier/worktime"
                  component={WorkTimeEditingPage}
                />
                <ContentRoute
                  path="/brigadier/receivables"
                  component={ReceivablesPage}
                />
                {(isAdmin || isExtendedOverseer) && (
                  <ContentRoute
                    path="/brigadier/settings"
                    component={SettingsPage}
                  />
                )}
              </>
            ) : (
              <Redirect to="/dashboard" />
            )}
          </Switch>
        </div>
      )}
    </>
  );
}
