import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import AcceptOrRejectContractorModal from "../modals/AcceptOrRejectContractorModal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export function JoinRequests(props) {
  const [openModal, setOpenModal] = useState(false);
  const [supCust2statusID, setSupCust2statusID] = useState();

  const onOpeningModalWithData = (supCust2statusId) => {
    setSupCust2statusID(supCust2statusId);
    setOpenModal(true);
  };
  const onOpeningOrClosingModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <AcceptOrRejectContractorModal
        open={openModal}
        close={onOpeningOrClosingModal}
        supCust2statusID={supCust2statusID}
        onAcceptingOrRejectingApplication={
          props.onAcceptingOrRejectingApplication
        }
      />
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="card-icon">
              <i className="flaticon-user-add"></i>
            </span>
            <h3 className="card-label">
              <FormattedMessage id="PE.JOIN_REQUESTS" />
            </h3>
          </div>
        </div>
        <div className="card-body">
          {props.applications.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr className="text-center">
                  <th>
                    <FormattedMessage id="GENERAL.CONTRACTOR" />
                  </th>
                  <th>
                    <FormattedMessage id="GENERAL.SHORT_NAME" />
                  </th>
                  <th>
                    <FormattedMessage id="PE.SUGAR_FACTORY" />
                  </th>
                  <th>
                    <FormattedMessage id="GENERAL.APPLICATION_SUBMITTING_DATE" />
                  </th>
                  <th>PESEL</th>
                  <th>
                    <FormattedMessage id="GENERAL.NIP" />
                  </th>
                  <th>
                    <FormattedMessage id="GENERAL.CITY" />
                  </th>
                  <th>
                    <FormattedMessage id="GENERAL.POSTAL_CODE" />
                  </th>
                  <th>
                    <FormattedMessage id="GENERAL.ACTIONS" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.applications.map((application) => (
                  <tr
                    key={application.supCust2statusId}
                    className="text-center"
                  >
                    <td>{application.bpartnerFullName}</td>
                    <td>{application.bpartnerShortName}</td>
                    <td>{application.orgentityDesc}</td>
                    <td>
                      {moment(application.applicationAt).format(
                        "DD.MM.YYYY HH:mm"
                      )}
                    </td>
                    <td>{application.pesel}</td>
                    <td>{application.vatin}</td>
                    <td>{application.cityName}</td>
                    <td>{application.postalCode}</td>
                    <td className="d-flex justify-content-around">
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={() =>
                          onOpeningModalWithData(application.supCust2statusId)
                        }
                      >
                        <FormattedMessage id="GENERAL.DECISION" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">
              <h6>
                <FormattedMessage id="PE.NO_JOIN_REQUESTS" />
              </h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
