import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { getChemistryDetails } from "../../../services/chemistryCrud";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

export function CroppingsTreatmentsForm({
  soilCultivationList,
  fertilizationList,
  plantingList,
  irrigationList,
  plantProtectionList,
  varieties,
  dimSizes,
  fertilizers,
  plantProtectionProducts,
  unitOfMeasurements,
  croppingData,
  displaySoilCultivation,
  displayPlanting,
  displayIrrigation,
  displayFertilization,
  displayPlantProtection,
  resetForm,
  onSavingTreatment,
  treatmentMap
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    triggerValidation,
  } = useForm({
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "plantProtectionProduct",
  });
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [plantingOrigins] = useState([
    { id: 1, desc: "FSS", value: true },
    { id: 2, desc: "kwalifikat", value: false },
  ]);
  const [qualificationsDegree] = useState([
    { id: 1, desc: "SE" },
    { id: 2, desc: "E" },
    { id: 3, desc: "A" },
    { id: 4, desc: "B" },
  ]);
  const [rowWidths] = useState([
    { id: 1, desc: "75 cm", value: 75 },
    { id: 2, desc: "85 cm", value: 85 },
    { id: 3, desc: "90 cm", value: 90 },
  ]);
  const [pestsList, setPestsList] = useState([]);
  const [refreshData, setRefreshData] = useState(true);
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fields.length >= 1 && refreshData) {
      async function fetchChemData() {
        const chemData = await getPlantProtectionProductDetails(
          plantProtectionProducts[0].id
        );
        if (chemData.id) {
          setTimeout(() => {
            setValue(
              `plantProtectionProduct[${fields.length - 1}].prevention`,
              chemData.preventionDays
            );
            setValue(
              `plantProtectionProduct[${fields.length - 1}].gracePeriod`,
              chemData.graceDays
            );
            const newPestsList = [...pestsList];
            newPestsList.push({
              id: fields[fields.length - 1].id,
              pests: chemData.pests,
            });
            setPestsList(newPestsList);
            triggerValidation();
          }, 50);
        }
      }
      fetchChemData();
    }
  }, [fields]);

  useEffect(() => {
    if (plantProtectionProducts.length) {
      append({
        plantProtectionProduct: "",
        prevention: "0, 0, 0",
        gracePeriod: "5",
      });
    }
  }, [plantProtectionProducts]);

  useEffect(() => {
    if (croppingData ?.cropping) {
      setValue(
        "area",
        croppingData.cropping.shapeArea
          ? croppingData.cropping.shapeArea.toFixed(2)
          : 0
      );
    }
  }, [croppingData]);

  useEffect(() => {
    if (resetForm) {
      reset();
      if (croppingData ?.cropping) {
        setTimeout(() => {
          setValue(
            "area",
            croppingData.cropping.shapeArea
              ? croppingData.cropping.shapeArea.toFixed(2)
              : 0
          );
          setPestsList([]);
          append({ plantProtectionProduct: "" });
        });
      }
    }
  }, [resetForm]);

  function onSubmit(data) {
    const form = data;
    form.dateStart = fromDate;
    form.dateEnd = toDate;
    form.treatments = [];
    if (data.soilCultivationTreatmentId) {
      form.treatments.push({ dtreatmentId: data.soilCultivationTreatmentId });
    }
    if (data.origin) {
      form.treatments.push({
        dtreatmentId: 7,
        origin: data.origin == "true" ? true : false,
        varietyId: data.varietyId,
        batch: data.batch,
        dimSizeId: data.dimSizeId,
        qualificationDegree: data.qualificationDegree,
        rowWidth: data.rowWidth,
        rowDistance: data.rowDistance,
        quantity: data.quantity,
      });
    }
    if (data.waterDose) {
      form.treatments.push({ dtreatmentId: 10, waterDose: data.waterDose });
    }
    if (data.fertilizationTreatmentId) {
      form.treatments.push({
        dtreatmentId: data.fertilizationTreatmentId,
        chemistry: [
          {
            chemistryId: data.fertilizerId,
            dose: data.dose,
            dosePerArea: data.dosePerArea,
          },
        ],
      });
    }
    if (data.plantProtectionProduct ?.length > 0) {
      data.plantProtectionProduct.forEach((chem) => {
        let pushedChem = { ...chem };
        delete pushedChem.dtreatmentId;
        delete pushedChem.prevention;
        delete pushedChem.gracePeriod;
        form.treatments.push({
          dtreatmentId: chem.dtreatmentId,
          chemistry: [pushedChem],
        });
      });
    }
    delete data.soilCultivationTreatmentId;
    delete data.origin;
    delete data.varietyId;
    delete data.batch;
    delete data.dimSizeId;
    delete data.qualificationDegree;
    delete data.rowWidth;
    delete data.rowDistance;
    delete data.quantity;
    delete data.waterDose;
    delete data.fertilizationTreatmentId;
    delete data.dose;
    delete data.dosePerArea;
    delete data.fertilizerId;
    delete data.plantProtectionProduct;
    onSavingTreatment(data);
  }

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  const getPlantProtectionProductDetails = (chemId) => {
    return new Promise((resolve) => {
      getChemistryDetails(chemId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve("error");
        });
    });
  };

  async function onSelectingPlantProtectionProduct(e, index, field) {
    const chemData = await getPlantProtectionProductDetails(e.target.value);
    if (chemData.id) {
      setValue(
        `plantProtectionProduct[${index}].prevention`,
        chemData.preventionDays === "" ? "Brak danych" : chemData.preventionDays
      );
      setValue(
        `plantProtectionProduct[${index}].gracePeriod`,
        chemData.graceDays === null ? "Brak danych" : chemData.graceDays
      );
      const newPestsList = [...pestsList];
      const searchedObjExist = newPestsList.find((ele) => {
        return ele.id === field.id;
      });
      searchedObjExist
        ? (searchedObjExist.pests = chemData.pests)
        : newPestsList.push({ id: field.id, pests: chemData.pests });
      setPestsList(newPestsList);
    }
  }

  const onRemovingPlantProtectionProduct = (arrId, index) => {
    setRefreshData(false);
    setTimeout(() => {
      remove(index);
      const newPestsList = [...pestsList];
      const filteredPestsList = newPestsList.filter((ele) => {
        return ele.id !== arrId;
      });
      setPestsList(filteredPestsList);
      setRefreshData(true);
      triggerValidation();
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.DURATION_FROM" /> *
            </Form.Label>
            <DatePicker
              name="dateFrom"
              selected={fromDate}
              dateFormat="dd.MM.yyyy"
              locale={datepickerLocale}
              showMonthDropdown
              showYearDropdown
              onChange={(date) => onChangingDate("from", date)}
            />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.DURATION_TO" /> *
            </Form.Label>
            <DatePicker
              name="dateTo"
              selected={toDate}
              dateFormat="dd.MM.yyyy"
              locale={datepickerLocale}
              showMonthDropdown
              showYearDropdown
              onChange={(date) => onChangingDate("to", date)}
            />
          </Form.Group>
          {/* <Form.Group as={Col} md={12}></Form.Group>
          <Form.Group as={Col} md={12}></Form.Group>
          <Form.Group as={Col} md={12}></Form.Group> */}
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.AREA" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="area"
                type="number"
                className={`${
                  errors.area &&
                    getValues("area") >=
                    parseFloat(croppingData ?.cropping.shapeArea.toFixed(2))
                    ? "is-invalid"
                    : ""
                  }`}
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.AREA",
                })}`}
                step="0.01"
                min="0"
                onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 100) / 100)}
                ref={register({
                  required: false,
                  min: 0,
                  max: croppingData ?.cropping.shapeArea
                    ? parseFloat(croppingData ?.cropping.shapeArea.toFixed(2))
                    : 99999,
                })}
              />
              <InputGroup.Append>
                <InputGroup.Text>ha</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {errors.area &&
              getValues("area") >=
              parseFloat(croppingData ?.cropping.shapeArea.toFixed(2)) && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.AREA_CANNOT_BE_LARGER_THAN_CROPPING_CALCULATED_AREA" />
                </div>
              )}
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.COMMENT" />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="comment"
              ref={register({ required: false })}
            />
          </Form.Group>
          {displaySoilCultivation && (
            <>
              <Form.Group as={Col} md={12}>
                <div>
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.SOIL_CULTIVATION" />
                  </span>
                </div>
                <div className="separator separator-dashed mt-5 mb-5" />
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.TREATMENT_TYPE" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="soilCultivationTreatmentId"
                  ref={register({ required: true })}
                >
                  {soilCultivationList.length && (
                    <>
                      {soilCultivationList.map((treatmentType) => (

                        <FormattedMessage
                          id={`TREATMENT.${treatmentType.type}`}
                          key={`TREATMENT.${treatmentType.type}`}
                        >
                          {(message) => (
                            <option key={treatmentType.dtreatmentId} value={treatmentType.dtreatmentId}>{message}</option>
                          )}
                        </FormattedMessage>

                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            </>
          )}
          {displayPlanting && (
            <>
              <Form.Group as={Col} md={12}>
                <div>
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.PLANTING" />
                  </span>
                </div>
                <div className="separator separator-dashed mt-5 mb-5" />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.ORIGIN" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="origin"
                  ref={register({ required: true })}
                >
                  {plantingOrigins.map((origin) => (
                    <option key={origin.id} value={origin.value}>
                      {origin.desc}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.VARIETY" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="varietyId"
                  ref={register({ required: true })}
                >
                  {varieties.length && (
                    <>
                      {varieties.map((variety) => (
                        <option
                          key={variety.varietyId}
                          value={variety.varietyId}
                        >
                          {variety.desc}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.BATCH" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="batch"
                    type="text"
                    className={`${
                      errors.batch && getValues("batch").length > 100
                        ? "is-invalid"
                        : ""
                      }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.BATCH",
                    })}`}
                    ref={register({ required: false, maxLength: 100 })}
                  />
                </InputGroup>
                {errors.batch && getValues("batch").length > 100 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.INCORRECT_BATCH_LENGTH" />
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.SIZES" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="dimSizeId"
                  ref={register({ required: true })}
                >
                  {dimSizes.length && (
                    <>
                      {dimSizes.map((size) => (
                        <option key={size.id} value={size.id}>
                          {size.desc}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.QUALIFICATION_DEGREE" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="qualificationDegree"
                  ref={register({ required: true })}
                >
                  {qualificationsDegree.map((qualification) => (
                    <option key={qualification.id} value={qualification.desc}>
                      {qualification.desc}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.ROW_WIDTH" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="rowWidth"
                  ref={register({ required: true })}
                >
                  {rowWidths.map((width) => (
                    <option key={width.id} value={width.value}>
                      {width.desc}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.DISTANCE_IN_ROW" />
                </Form.Label>
                <Form.Control
                  name="rowDistance"
                  type="number"
                  className={`${
                    errors.rowDistance && getValues("rowDistance") > 99999
                      ? "is-invalid"
                      : ""
                    }`}
                  placeholder={`${intl.formatMessage({
                    id: "PLACEHOLDER.DISTANCE_IN_ROW",
                  })}`}
                  step="0.1"
                  min="0"
                  max="99999"
                  onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                  ref={register({ required: false, min: 0.1, max: 99999 })}
                />
                {errors.rowDistance && getValues("rowDistance") > 99999 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_100000" />
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.QUANTITY" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="quantity"
                    type="number"
                    className={`${
                      errors.quantity && getValues("quantity") > 999999
                        ? "is-invalid"
                        : ""
                      }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.QUANTITY",
                    })}`}
                    step="0.1"
                    min="0"
                    max="999999"
                    onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                    ref={register({ required: false, min: 0.1, max: 999999 })}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>t/ha</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {errors.quantity && getValues("quantity") > 999999 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                  </div>
                )}
              </Form.Group>
            </>
          )}
          {displayIrrigation && (
            <>
              <Form.Group as={Col} md={12}>
                <div>
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.IRRIGATION_TREATMENT" />
                  </span>
                </div>
                <div className="separator separator-dashed mt-5 mb-5" />
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.WATER_DOSE" /> *
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="waterDose"
                    type="number"
                    className={`${
                      errors.waterDose && getValues("waterDose") > 999999
                        ? "is-invalid"
                        : ""
                      }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.WATER_DOSE",
                    })}`}
                    step="0.1"
                    min="0"
                    max="999999"
                    onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                    ref={register({ required: true, min: 0.1, max: 999999 })}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>mm</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {errors.waterDose && getValues("waterDose") > 999999 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                  </div>
                )}
              </Form.Group>
            </>
          )}
          {displayFertilization && (
            <>
              <Form.Group as={Col} md={12}>
                <div>
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.FERTILIZATION" />
                  </span>
                </div>
                <div className="separator separator-dashed mt-5 mb-5" />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.FERTILIZATION_TYPE" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="fertilizationTreatmentId"
                  ref={register({ required: true })}
                >
                  {fertilizationList.length && (
                    <>
                      {fertilizationList.map((fertilizationType) => (
                        <option
                          key={fertilizationType.dtreatmentId}
                          value={fertilizationType.dtreatmentId}
                        >
                          {fertilizationType.dtreatmentDesc}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.FERTILIZER_TYPE" /> *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="fertilizerId"
                  ref={register({ required: true })}
                >
                  {fertilizers.length && (
                    <>
                      {fertilizers.map((fertilizer) => (
                        <option key={fertilizer.id} value={fertilizer.id}>
                          {fertilizer.name}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.DOSE" /> *
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="dose"
                    type="number"
                    className={`${
                      errors.dose && getValues("dose") > 999999
                        ? "is-invalid"
                        : ""
                      }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.DOSE",
                    })}`}
                    step="0.1"
                    min="0"
                    max="999999"
                    onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                    ref={register({ required: true, min: 0.1, max: 999999 })}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>ha</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {errors.dose && getValues("dose") > 999999 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md={12}>
                <Form.Label>
                  <FormattedMessage id="GENERAL.DOSE_PER_AREA" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="dosePerArea"
                    type="number"
                    className={`${
                      errors.dosePerArea &&
                        getValues("dosePerArea") > 9999999999
                        ? "is-invalid"
                        : ""
                      }`}
                    placeholder={`${intl.formatMessage({
                      id: "PLACEHOLDER.DOSE_PER_AREA",
                    })}`}
                    step="0.1"
                    min="0"
                    max="9999999999"
                    onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                    ref={register({
                      required: false,
                      min: 0.1,
                      max: 9999999999,
                    })}
                  />
                </InputGroup>
                {errors.dosePerArea && getValues("dosePerArea") > 9999999999 && (
                  <div
                    className="error invalid-feedback"
                    style={{ display: "block" }}
                  >
                    <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_10000000000" />
                  </div>
                )}
              </Form.Group>
            </>
          )}
          {displayPlantProtection && (
            <>
              <Form.Group as={Col} md={12}>
                <div>
                  <span className="text--bold">
                    <FormattedMessage id="GENERAL.PLANT_PROTECTION_PRODUCT" />
                  </span>
                </div>
                <div className="separator separator-dashed mt-5 mb-5" />
              </Form.Group>
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  {index > 0 && (
                    <Form.Group as={Col} md={12}>
                      <div className="separator separator-dashed mt-5 mb-5" />
                    </Form.Group>
                  )}
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.TREATMENT_TYPE" /> *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name={`plantProtectionProduct[${index}].dtreatmentId`}
                      ref={register({ required: true })}
                    >
                      {plantProtectionList.length && (
                        <>
                          {plantProtectionList.map((treatment) => (
                            <FormattedMessage
                              id={`TREATMENT.${treatment.type}`}
                              key={`TREATMENT.${treatment.type}`}
                            >
                              {(message) => (
                                <option key={treatment.dtreatmentId} value={treatment.dtreatmentId}>{message}</option>
                              )}
                            </FormattedMessage>
                          ))}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.CHEMISTRY_TYPE" /> *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name={`plantProtectionProduct[${index}].chemistryId`}
                      ref={register({ required: true })}
                      onChange={(e) =>
                        onSelectingPlantProtectionProduct(e, index, field)
                      }
                    >
                      {plantProtectionProducts.length && (
                        <>
                          {plantProtectionProducts.map(
                            (plantProtectioncProduct) => (
                              <option
                                key={plantProtectioncProduct.id}
                                value={plantProtectioncProduct.id}
                              >
                                {plantProtectioncProduct.name}
                              </option>
                            )
                          )}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.DOSE" /> *
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        name={`plantProtectionProduct[${index}].dose`}
                        type="number"
                        className={`${
                          `errors.plantProtectionProduct[${index}].dose` &&
                            getValues(`plantProtectionProduct[${index}].dose`) >
                            999999
                            ? "is-invalid"
                            : ""
                          }`}
                        placeholder={`${intl.formatMessage({
                          id: "PLACEHOLDER.DOSE",
                        })}`}
                        step="0.1"
                        min="0"
                        max="999999"
                        onBlur={(e) => e.currentTarget.value = Math.abs(Math.ceil(e.currentTarget.value * 10) / 10)}
                        ref={register({
                          required: true,
                          min: 0.1,
                          max: 999999,
                        })}
                      />
                    </InputGroup>
                    {`errors.plantProtectionProduct[${index}].dose` &&
                      getValues(`plantProtectionProduct[${index}].dose`) >
                      999999 && (
                        <div
                          className="error invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000000" />
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" /> *
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name={`plantProtectionProduct[${index}].uomId`}
                      ref={register({ required: true })}
                    >
                      {unitOfMeasurements.length && (
                        <>
                          {unitOfMeasurements.map((uom) => (
                            <option key={uom.id} value={uom.id}>
                              {uom.desc}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                  {pestsList.length > 0 && (
                    <>
                      {pestsList.map((list, index) => (
                        <React.Fragment key={index}>
                          {list.id === field.id && list.pests.length > 0 && (
                            <>
                              <Form.Label>
                                <FormattedMessage id="GENERAL.PEST" />
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name={`plantProtectionProduct[${index}].pestIds`}
                                ref={register({
                                  required: false,
                                })}
                                multiple
                              >
                                {list.pests.map((pest) => (
                                  <option key={pest.id} value={pest.id}>
                                    {pest.desc}
                                  </option>
                                ))}
                              </Form.Control>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.PREVENTION" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name={`plantProtectionProduct[${index}].prevention`}
                      defaultValue={field.prevention}
                      ref={register({ required: false })}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.GRACE_PERIOD" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name={`plantProtectionProduct[${index}].gracePeriod`}
                      defaultValue={field.gracePeriod}
                      ref={register({ required: false })}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      <FormattedMessage id="GENERAL.COMMENTS" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name={`plantProtectionProduct[${index}].remarks`}
                      ref={register({ required: false })}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {index === fields.length - 1 && (
                      <Button
                        type="button"
                        variant="primary"
                        style={{ width: `${index === 0 ? "100%" : "45%"}` }}
                        onClick={() => append({ plantProtectionProduct: "" })}
                      >
                        <FormattedMessage id="GENERAL.ADD" />
                      </Button>
                    )}
                    {index > 0 && (
                      <Button
                        type="button"
                        variant="secondary"
                        style={{
                          width: `${
                            index === fields.length - 1 ? "45%" : "100%"
                            }`,
                        }}
                        onClick={() =>
                          onRemovingPlantProtectionProduct(field.id, index)
                        }
                      >
                        <FormattedMessage id="GENERAL.DELETE" />
                      </Button>
                    )}
                  </Form.Group>
                </React.Fragment>
              ))}
            </>
          )}
          {(displaySoilCultivation ||
            displayPlanting ||
            displayIrrigation ||
            displayFertilization ||
            displayPlantProtection) && (
              <Form.Group as={Col} md={12}>
                <Button
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={loading || treatmentMap === null}
                >
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
              </Form.Group>
            )}
        </Form.Row>
      </Form>
    </>
  );
}
