import axios from "axios";

export const GW_REPORTS_URL = `${process.env.REACT_APP_URL}gw/reports`;
export const REPORTS_URL = `${process.env.REACT_APP_URL}reports`;

export function getReportData(form) {
  return axios.post(`${GW_REPORTS_URL}`, form);
}

export function exportReportDataToExcel(form) {
  return axios.post(`${GW_REPORTS_URL}/excel`, form, { responseType: "blob" });
}

export function getAttributeToLoginAndSubscription(farmId) {
  return axios.get(`${REPORTS_URL}/bPartner/${farmId}/attribute`);
}

export function getReportsToLoginAndSubscription() {
  return axios.get(`${REPORTS_URL}/all`);
}

export function createReport(form) {
  return axios.post(`${REPORTS_URL}/get`, form);
}

export function sendReportData(reportId, form) {
  return axios.post(`${REPORTS_URL}/history/${reportId}`, form);
}

export function exportReportToExcel(reportHistoryId) {
  return axios.post(`${REPORTS_URL}/history/${reportHistoryId}/excel`, null, {
    responseType: "blob",
  });
}
