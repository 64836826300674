import React from "react";

export function OperationRow({ operation, onSelectingBalanceValue }) {
  return (
    <tr className="text-center">
      <td>{operation.valueToDate}</td>
      <td>{operation.valueToDatePaid}</td>
      <td onClick={() => onSelectingBalanceValue(operation.valueToDateBalance)}>
        {operation.valueToDateBalance}
      </td>
      <td>{operation.valueBetweenDates}</td>
      <td>{operation.valueBetweenDatesPaid}</td>
      <td
        className="offer--active"
        onClick={() =>
          onSelectingBalanceValue(operation.valueBetweenDatesBalance)
        }
      >
        {operation.valueBetweenDatesBalance}
      </td>
      <td>{operation.valueSummary}</td>
      <td>{operation.valueSummaryPaid}</td>
      <td
        className="offer--active"
        onClick={() => onSelectingBalanceValue(operation.valueSummaryBalance)}
      >
        {operation.valueSummaryBalance}
      </td>
      <td>{operation.currencyName}</td>
    </tr>
  );
}
