import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getAttributeToInvitation,
  createInvitationForPerson,
  getInvitationsToBPartner,
  resendInvitationByMail,
  deleteCreatedInvitation,
} from "../../../services/invitationCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { FarmForm } from "../components/FarmForm";
import { FarmInvitationsCard } from "../components/FarmInvitationsCard";
import displayFeedback from "../../../components/helpers/displayFeedback";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";

export function FarmPage({ profile, updateFarmProfile }) {
  const intl = useIntl();
  const [formData, setFormData] = useState(null);
  const [invitations, setInvitations] = useState([]);

  const getFormData = () => {
    getAttributeToInvitation()
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getInvitations = () => {
    getInvitationsToBPartner()
      .then((response) => {
        setInvitations(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const sendInvitation = (form) => {
    return new Promise((resolve) => {
      createInvitationForPerson(form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getInvitations();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const reSendInvitation = (id) => {
    return new Promise((resolve) => {
      resendInvitationByMail(id)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getInvitations();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  const deleteInvitation = (id) => {
    return new Promise((resolve) => {
      deleteCreatedInvitation(id)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          getInvitations();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
          resolve("error");
        });
    });
  };

  useEffectOnce(getFormData);
  useEffectOnce(getInvitations);

  return (
    <div>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/icons/User.svg")} />
            </span>
            <h3 className="card-label">
              <FormattedMessage id="GENERAL.FARM" />
            </h3>
          </div>
        </div>
        <div className="card-body">
          <FarmForm profile={profile} updateFarmProfile={updateFarmProfile} />
        </div>
      </div>
      <FarmInvitationsCard
        formData={formData}
        invitations={invitations}
        sendInvitation={sendInvitation}
        reSendInvitation={reSendInvitation}
        deleteInvitation={deleteInvitation}
      />
    </div>
  );
}

export default FarmPage;
