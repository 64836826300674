import React from "react";
import { FormattedMessage } from "react-intl";
import { PackagingRow } from "../index";
import Table from "react-bootstrap/Table";

export function PackagingTable({ data, onSelectingRow }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.NAME" />
          </th>
          <th>
            <FormattedMessage id="BRIGADIER.CAPACITY" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((bundle) => (
          <PackagingRow
            key={`package-${bundle.name}`}
            bundle={bundle}
            onSelectingRow={onSelectingRow}
          />
        ))}
      </tbody>
    </Table>
  );
}
