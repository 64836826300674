import React, {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
  MarkerClusterer, InfoBox
} from "@react-google-maps/api";

const googleMapStyle = {
  width: "100%",
  height: "78vh",
};

const markerClustererOptions = {
  imagePath: '/media/maps/m'
}

export function DashboardMap(props) {
  const {
    croppings,
    allCroppings,
    redirectToCropDetails,
  } = props;
  const intl = useIntl();
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [center, setCenter] = useState({lat: 52, lng: 20});
  const [googleMap, setGoogleMap] = useState(null);
  const [searchbox, setSearchbox] = useState(null);
  const [clusterInfoBoxData, setClusterInfoBoxData] = useState();
  const [hideWindowBox, setHideWindowBox] = useState(true);
  const [windowBox, setWindowBox] = useState({
    options: {closeBoxURL: "", enableEventPropagation: true},
    position: {lat: 52, lng: 20},
  });

  const [
    croppingsPresentedAsMarkers,
    setCroppingsPresentedAsMarkers,
  ] = useState([]);

  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION
  })

  const setCenterAndZoomAtAllCroppings = () => {
    if (googleMap && allCroppings.length) {
      const coords = allCroppings.map((row) => {
        const position = getCroppingPosition(row);
        return new window.google.maps.LatLng(position.lat, position.lng);
      });
      let bounds = new window.google.maps.LatLngBounds();
      coords.forEach((row) => {
        bounds.extend(row);
      });
      googleMap.setCenter(bounds.getCenter());
      googleMap.fitBounds(bounds);
    }
  };

  useEffect(setCenterAndZoomAtAllCroppings, [googleMap, allCroppings]);


  const drawCroppings = () => {
    if (googleMap && croppings) {
      if (Array.isArray(croppings) && croppings.length) {
        setTimeout(() => {
          drawCropsAsMarkers(croppings)
        });
      } else {
        setCenterAndZoomAtAllCroppings();
        setCroppingsPresentedAsMarkers([]);
      }
    }
  };

  const setBoundsToCroppings = (polygons) => {
    if (!polygons.length) {
      return;
    }
    const latLngList = [];

    polygons.forEach((point) => {
      latLngList.push(
        new window.google.maps.LatLng(point.position.lat, point.position.lng)
      );
    })


    let bounds = new window.google.maps.LatLngBounds();
    latLngList.forEach((row) => {
      bounds.extend(row);
    });

    googleMap.setCenter(bounds.getCenter());
    googleMap.fitBounds(bounds);
  }

  const drawCropsAsMarkers = (crops) => {
    const points = crops
      .filter((cropping) => {
        return (cropping.shape && cropping.shape.children.length) ||
          (cropping.point && cropping.point.point.coordinates.length);
      })
      .map((cropping) => {
        return {
          croppingId: cropping.croppingId,
          fieldId: cropping.fieldId,
          bpartner: cropping.bpartner,
          isIrrigated: cropping.isIrrigated,
          isContractual: cropping.isContractual,
          isEco: cropping.isEco,
          position: getCroppingPosition(cropping),
          shapeId: cropping.point ? cropping.point.shapeId : cropping.shape.parent.shapeId,
          areaDeclared: cropping.areaDeclared,
          shapeArea: cropping.shapeArea,
          desc: cropping.desc,
          shortName: cropping.shortName,
          variety: cropping.variety,
          treatments: cropping.treatments,
        };
      });
    setCroppingsPresentedAsMarkers(points);
    setBoundsToCroppings(points);
  };

  const getCroppingPosition = (cropping) => {
    if (cropping.shape) {
      return {
        lat: cropping.shape.children[0].shape.coordinates[0][0][1],
        lng: cropping.shape.children[0].shape.coordinates[0][0][0],
      };
    }

    if (cropping.point) {
      return {
        lat: cropping.point.point.coordinates[1],
        lng: cropping.point.point.coordinates[0],
      };
    }
  }

  useEffect(drawCroppings, [googleMap, croppings]);

  const onPlacesChanged = () => {
    const searchedPlace = searchbox.getPlaces()[0];
    setCenter({
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    });
  };

  const handleMarkerClick = (e, marker) => {
    let params;

    if (marker.fieldId) {
      params = [marker.fieldId, 'field'];
    } else {
      params = [marker.croppingId, 'crop'];
    }

    redirectToCropDetails(...params);
  };

  const handleClusterClick = (clustersData) => {
    const markers = clustersData.getMarkers();
    const currentZoom = googleMap.getZoom();
    const service = new window.google.maps.MaxZoomService();

    setTimeout(() => {
      service
        .getMaxZoomAtLatLng(
          {lat: clustersData.center.lat(), lng: clustersData.center.lng()},
          (result) => {
            if (result.status !== "OK") {
              return;
            }

            if (markers.length && currentZoom >= result.zoom) {
              setWindowBox({
                ...windowBox,
                position: {lat: clustersData.center.lat(), lng: clustersData.center.lng()},
              });
              setTimeout(() => {
                setHideWindowBox(false)
              }, 1);
              setClusterInfoBoxData(markers)
            }
          });

    });

  }

  const handleShapeLoad = (field, data) => {
    field.data = data;
  };

  const setWindowBoxHidden = () => {
    setTimeout(() =>
      setHideWindowBox(true)
    );
  }

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      mapInstance.setOptions({mapTypeControl: false, zoom: 6});
      setGoogleMap(mapInstance);
    }

    return (
      <GoogleMap
        mapContainerClassName="map map-mobile"
        mapContainerStyle={googleMapStyle}
        mapTypeId={"hybrid"}
        center={center}
        onLoad={onLoad}
        onClick={() => setWindowBoxHidden()}
      >
        <StandaloneSearchBox
          onLoad={(searcher) => setSearchbox(searcher)}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.SEARCH",
            })}...`}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        <MarkerClusterer
          options={markerClustererOptions}
          onClick={(e) => {
            handleClusterClick(e)
          }}
        >
          {function (clusterer) {
            const markers = [...croppingsPresentedAsMarkers];
            return markers.map((marker) => {
                return (
                  <Marker
                    key={`crop-as-marker-${marker.croppingId ? 'c-' + marker.croppingId : 'f-' + marker.fieldId}`}
                    position={marker.position}
                    onClick={(e) => handleMarkerClick(e, marker, clusterer)}
                    onLoad={(e) => handleShapeLoad(e, marker)}
                    clusterer={clusterer}
                  />
                )
              }
            )
          }}
        </MarkerClusterer>
        {!hideWindowBox && clusterInfoBoxData && (
          <InfoBox options={windowBox.options} position={windowBox.position}>
            <div
              style={{
                minWidth: "220px",
                backgroundColor: "#fff",
                padding: "2rem 2.25rem",
                borderRadius: "0.32rem",
                boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
              }}
            >
              <h6 className="windowbox__heading mb-5">
                Wybierz pinezkę
              </h6>
              <div className="d-flex flex-column">
                {clusterInfoBoxData.map(marker => {
                  if (marker.data.fieldId) return (
                    <button key={`field-${marker.data.fieldId}`} className="mb-3 border-0 p-2"
                            onClick={(e) => {
                              handleMarkerClick(e, marker.data)
                            }}>
                      <FormattedMessage id="GENERAL.FIELD"/> - {marker.data.shortName}
                    </button>
                  )
                  else if (marker.data.croppingId) return (
                    <button key={`cropping-${marker.data.croppingId}`} className="mb-3 border-0 p-2"
                            onClick={(e) => {
                              handleMarkerClick(e, marker.data)
                            }}>
                      <FormattedMessage id="GENERAL.CROPPING"/> - {marker.data.shortName}
                    </button>
                  )
                })}
              </div>
            </div>
          </InfoBox>
        )}
      </GoogleMap>
    )
  }

  return isLoaded ? renderMap() : null
}
