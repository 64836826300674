import axios from "axios";

export const AUTH_URL = `${process.env.REACT_APP_URL}auth`;

export function signIn(form) {
  return axios.post(AUTH_URL, form);
}

export function signInAnonymously(form) {
  return axios.post(`${AUTH_URL}/anonymous`, form);
}

export function signInWithSocial(socialMedia, form) {
  return axios.post(`${AUTH_URL}/social/${socialMedia}`, form);
}

export function signUp(form) {
  return axios.post(`${AUTH_URL}/register`, form);
}

export function resetPassword(form) {
  return axios.post(`${AUTH_URL}/reset-password`, form);
}

export function validateUserToken(token, userId) {
  return axios.get(`${AUTH_URL}/token/${token}/id/${userId}`);
}

export function changePassword(token, userId, form) {
  return axios.post(
    `${AUTH_URL}/reset-password/token/${token}/id/${userId}`,
    form
  );
}

export function changePaswordByUser(form) {
  return axios.post(`${AUTH_URL}/change-password`, form);
}

export function deleteAccountInit(form) {
  return axios.put(`${AUTH_URL}/delete-account/init`, form);
}

export function deleteAccount(id, key) {
  return axios.delete(`${AUTH_URL}/delete-account?id=${id}&key=${key}`);
}

export function signOut() {
  return axios.post(`${AUTH_URL}/logout`, null);
}

export const ME_URL = "api/me";

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function refreshCredential() {
  return axios.get(`${AUTH_URL}/refresh`)
}
