import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { PartnersTable } from "../../../components/PartnersTable";
import { ShareCroppingsTable } from "../";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

export function ShareCroppingsDataModal({
  open,
  close,
  businessPartners,
  croppings,
  onUpdatingFarmSharingPlan,
  onNoFarmSelectedError,
}) {
  const [loading, setLoading] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [activeBusinessPartner, setActiveBusinessPartner] = useState();

  const filteredCroppings = useMemo(() => {
    if (croppings.length > 0)
      return croppings.filter((cropping) => cropping.isActive);
    else return [];
  }, [croppings]);

  useEffect(() => {
    if (open) {
      const newPartnersList = businessPartners.map((bPartner) => {
        return {
          bpartnerId: bPartner.bpartnerId,
          name: bPartner.bpartnerFullName,
          croppings: bPartner.croppings,
        };
      });
      setPartnersList(newPartnersList);
    }
  }, [open, businessPartners]);

  const onCloseModal = () => {
    close();
    setTimeout(() => {
      setActiveBusinessPartner(null);
      setPartnersList([]);
    }, 200);
  };

  const onSelectingBusinessPartner = (bpartnerId) => {
    const searchedBusinessPartner = partnersList.find((row) => {
      return row.bpartnerId === bpartnerId;
    });
    setActiveBusinessPartner(searchedBusinessPartner);
  };

  const changeSpatialDataAllowed = (cropping) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    const searchedCropping = searchedBusinessPartner.croppings.find((row) => {
      return row.croppingId === cropping.croppingId;
    });
    searchedCropping.isSpatialDataAllowed = !searchedCropping.isSpatialDataAllowed;
    setPartnersList(newPartnersList);
  };

  const addSpatialDataAllowed = (cropping) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    searchedBusinessPartner.croppings.push({
      croppingId: cropping.croppingId,
      isSpatialDataAllowed: true,
      isTreatmentDataAllowed: false,
    });
    setPartnersList(newPartnersList);
  };

  const changeTreatmentDataAllowed = (cropping) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    const searchedCropping = searchedBusinessPartner.croppings.find((row) => {
      return row.croppingId === cropping.croppingId;
    });
    searchedCropping.isTreatmentDataAllowed = !searchedCropping.isTreatmentDataAllowed;
    setPartnersList(newPartnersList);
  };

  const addTreatmentDataAllowed = (cropping) => {
    const newPartnersList = [...partnersList];
    const searchedBusinessPartner = newPartnersList.find((row) => {
      return row.bpartnerId === activeBusinessPartner.bpartnerId;
    });
    searchedBusinessPartner.croppings.push({
      croppingId: cropping.croppingId,
      isSpatialDataAllowed: false,
      isTreatmentDataAllowed: true,
    });
    setPartnersList(newPartnersList);
  };

  async function onUpdatingSharingCroppingsData() {
    setLoading(true);
    if (activeBusinessPartner) {
      const updatedPartnersList = [...partnersList].filter((bPartner) => {
        if (bPartner.croppings.length) {
          return bPartner;
        }
      });
      const callback = await onUpdatingFarmSharingPlan(updatedPartnersList);
      if (callback === "OK") {
        setLoading(false);
        onCloseModal();
      } else {
        setLoading(false);
      }
    } else {
      onNoFarmSelectedError();
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseModal}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          <FormattedMessage id="GENERAL.SHARE_CROP_DATA" />
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="GENERAL.SHARE_CROP_DATA_MESSAGE" />.
          </DialogContentText>
          <Row>
            <Col xs={5} className="modal__table">
              <PartnersTable
                partners={partnersList}
                activePartner={activeBusinessPartner}
                onSelectingPartner={onSelectingBusinessPartner}
              />
            </Col>
            <Col xs={7} className="modal__table">
              <ShareCroppingsTable
                croppings={[...filteredCroppings]}
                activeBusinessPartner={activeBusinessPartner}
                changeSpatialDataAllowed={changeSpatialDataAllowed}
                addSpatialDataAllowed={addSpatialDataAllowed}
                changeTreatmentDataAllowed={changeTreatmentDataAllowed}
                addTreatmentDataAllowed={addTreatmentDataAllowed}
              />
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={loading}
            onClick={onUpdatingSharingCroppingsData}
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button disabled={loading} onClick={onCloseModal}>
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
