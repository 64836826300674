import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CroppingTreatmentsTable } from "./CroppingTreatmentsTable";

export function CroppingTreatments({
  historicalTreatments,
  onFinishingTreatment,
  onCancelingTreatment,
  onActivatingTreatment
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">
            <span className="agrico-svg-icon svg-icon menu-icon mr-2">
              <SVG src={toAbsoluteUrl("/media/icons/Treatment.svg")} />
            </span>
            <FormattedMessage id="GENERAL.TREATMENTS" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <CroppingTreatmentsTable
          historicalTreatments={historicalTreatments}
          onFinishingTreatment={onFinishingTreatment}
          onCancelingTreatment={onCancelingTreatment}
          onActivatingTreatment={onActivatingTreatment}
        />
      </div>
    </div>
  );
}
