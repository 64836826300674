import React, { useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useSubscription from "../../../hooks/useSubscription";

export function ManualsPage() {
  const [key, setKey] = useState("cropchart");
  const showCropManager = useSubscription(["CM", "CS", "CP", "CE"]);
  const showBrigadier = useSubscription(["BB", "BS", "BP", "BT"]);

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="cropchart"
            title="Cropchart"
          >
            <iframe src="/media/manuals/cropchart.pdf" title="Cropchart" className="manual-pdf-iframe" />
            <a href="/media/manuals/cropchart.pdf" download className="d-table ml-auto mt-4">
              <Button>
                <FormattedMessage id="GENERAL.DOWNLOAD" />
              </Button>
            </a>
          </Tab>
          {showBrigadier && (<Tab
            eventKey="cropchart-brigadier"
            title="Brigadier"
          >
            <iframe src="/media/manuals/cropchart-brigadier.pdf" title="Brigadier" className="manual-pdf-iframe" />
            <a href="/media/manuals/cropchart-brigadier.pdf" download className="d-table ml-auto mt-4">
              <Button>
                <FormattedMessage id="GENERAL.DOWNLOAD" />
              </Button>
            </a>
          </Tab>)}
          {showCropManager && (<Tab
            eventKey="cropchart-manager"
            title="Cropchart Manager"
          >
            <iframe src="/media/manuals/cropchart-manager.pdf" title="Cropchart Manager" className="manual-pdf-iframe" />
            <a href="/media/manuals/cropchart-manager.pdf" download className="d-table ml-auto mt-4">
              <Button>
                <FormattedMessage id="GENERAL.DOWNLOAD" />
              </Button>
            </a>
          </Tab>)}
          <Tab
            eventKey="cropchart-mobile"
            title="Cropchart Mobile"
          >
            <iframe src="/media/manuals/cropchart-mobile.pdf" title="Cropchart Mobile" className="manual-pdf-iframe" />
            <a href="/media/manuals/cropchart-mobile.pdf" download className="d-table ml-auto mt-4">
              <Button>
                <FormattedMessage id="GENERAL.DOWNLOAD" />
              </Button>
            </a>
          </Tab>
          {showCropManager && (<Tab
            eventKey="cropchart-mobile-manager"
            title="Cropchart Manager Mobile"
          >
            <iframe src="/media/manuals/cropchart-mobile-manager.pdf" title="Cropchart Manager Mobile" className="manual-pdf-iframe" />
            <a href="/media/manuals/cropchart-mobile-manager.pdf" download className="d-table ml-auto mt-4">
              <Button>
                <FormattedMessage id="GENERAL.DOWNLOAD" />
              </Button>
            </a>
          </Tab>)}
        </Tabs>
      </div>
    </div>
  );
}

export default ManualsPage;
