import React from "react"
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Switch from '@material-ui/core/Switch';

export default function CMTimeSwitch() {
  const cmTime = useSelector(state => state.pricing.cmTime)
  const dispatch = useDispatch()

  const getChecked = () => {
    if (cmTime === "monthly") return false
    else if (cmTime === "yearly") return true
  }

  const handleChange = (e) => {
    dispatch({
      type: "pricing/setCmTime",
      payload: {
        cmTime: e.target.checked ? "yearly" : "monthly"
      }
    })
  }

  return (
    <div className="d-flex justify-content-center align-items-center mt-8">
      <span>
        <FormattedMessage id="PRICING.CM_TIME_SWITCH.MONTHLY" />
      </span>
      <Switch
        checked={getChecked()}
        onChange={handleChange}
        name="checkedA"
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <span>
        <FormattedMessage id="PRICING.CM_TIME_SWITCH.YEARLY" />
      </span>
      <span className="ml-4 cm-time-switch-label">
        <FormattedMessage id="PRICING.CM_TIME_SWITCH.DISCOUNT" />
      </span>
    </div>
  )
}