import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function OffersUpdateOffer(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    errors,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue(
      "primaryWeight",
      parseFloat(props.offer?.primaryWeight).toFixed(2)
    );
    setValue("price", parseFloat(props.offer?.price).toFixed(2));
    setValue("comment", props.offer?.comment);
    // eslint-disable-next-line
  }, [props.offer]);

  async function onSubmit(data) {
    setLoading(true);
    const callback = await props.onUpdatingBuyOffer(
      props.offer.buyOfferId,
      data
    );
    if (callback === "OK" || callback === "error") {
      setLoading(false);
    }
  }

  function onDeletingOffer() {
    props.onDeletingBuyOffer(props.offer.buyOfferId);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Col xs={12}>
          <h6>
            <FormattedMessage id="GENERAL.YOUR_OFFER" />
          </h6>
        </Col>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.WEIGHT" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="primaryWeight"
              type="number"
              className={`${errors.primaryWeight ? "is-invalid" : ""} ${
                !errors.primaryWeight ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.WEIGHT_IN_TONNES",
              })}`}
              step="0.01"
              disabled={props.offer?.offerStatus !== "N"}
              ref={register({
                required: true,
                min: 0.1,
                max: parseFloat(props.maxWeight),
              })}
            />
            <InputGroup.Append>
              <InputGroup.Text>t</InputGroup.Text>
            </InputGroup.Append>
            {errors.primaryWeight?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.primaryWeight?.type === "min" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
              </div>
            )}
            {errors.primaryWeight?.type === "max" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_WEIGHT_OF_SALE_OFFER" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PRICE" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="price"
              type="number"
              className={`${errors.price ? "is-invalid" : ""} ${
                !errors.price ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.PRICE_PER_TON",
              })}`}
              step="0.01"
              disabled={props.offer?.offerStatus !== "N"}
              ref={register({ required: true, min: 0.1 })}
            />
            <InputGroup.Append>
              <InputGroup.Text>PLN/t</InputGroup.Text>
            </InputGroup.Append>
            {errors.price?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.price?.type === "min" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_1" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.COMMENT" />
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="comment"
            disabled={props.offer?.offerStatus !== "N"}
            ref={register({ required: false })}
          />
        </Form.Group>
        <div className="col-xxl-12 d-flex align-items-center justify-content-between mb-2">
          <Button
            type="submit"
            disabled={props.offer?.offerStatus !== "N" || loading}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
          <Button
            type="button"
            variant="secondary"
            disabled={loading}
            onClick={() => onDeletingOffer()}
          >
            <FormattedMessage id="GENERAL.DELETE" />
          </Button>
        </div>
      </Form.Row>
    </Form>
  );
}
