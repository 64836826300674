import React from "react";

export function TariffRow({ tarrif, onSelectingRow }) {
  return (
    <tr
      className={`text-center cursor--pointer ${
        tarrif.isSelected === true ? "offer--active" : ""
      } ${!tarrif.isActive && !tarrif.isSelected ? "row__factory" : ""}`}
      onClick={() => onSelectingRow(tarrif.tariffId)}
    >
      <td>{tarrif.name}</td>
      <td>{tarrif.value}</td>
    </tr>
  );
}
