/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { usePreferences } from "../../../../../app/hooks/usePreferences";
import usePulpExchangePermission from "../../../../../app/hooks/usePulpExchangePermission";
import { useSubscriptionRole } from "../../../../../app/hooks/useSubscriptionRole";
// import SVG from "react-inlinesvg";
import { checkIsActive } from "../../../../_helpers";
// toAbsoluteUrl

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const showBrigadier = usePreferences("BRIGADIER");
  const showCropManager = usePreferences("CROPMANAGER");
  const showPulpExchange = usePreferences("EXCHANGE");

  const isPlanter = usePulpExchangePermission("ROLE_PLANTER");
  const isMaterialRepresentant = usePulpExchangePermission(
    "ROLE_MATERIAL_REPRESENTANT"
  );
  const isAdmin = useSubscriptionRole(["BT", "BB", "BS", "BP"], "ROLE_ADMIN");
  const isExtendedOverseer = useSubscriptionRole(
    ["BT", "BB", "BS", "BP"],
    "ROLE_BRIGADIER_EXTEND"
  );
  const isOverseer = useSubscriptionRole(
    ["BT", "BB", "BS", "BP"],
    "ROLE_BRIGADIER"
  );
  const isCropManagerAdmin = useSubscriptionRole(
    ["CM", "CS", "CP", "CE"],
    "ROLE_ADMIN"
  );
  const isCropManagerExtendedAgronomist = useSubscriptionRole(
    ["CM", "CS", "CP", "CE"],
    "ROLE_AGRONOMIST_EXTEND"
  );

  const isMobile = useSelector((state) => state.misc.isMobile);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/calendar"
          )}`}
        >
          <NavLink className="menu-link" to="/calendar">
            <span className="menu-text">
              <FormattedMessage id="MENU.CALENDAR" />
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel ${getMenuItemActive("/maps")}`}>
          <NavLink className="menu-link" to="/maps">
            <span className="menu-text">
              <FormattedMessage id="MENU.MAPS" />
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/documents"
          )}`}
        >
          <a className="menu-link menu-toggle" role="button">
            <span className="menu-text">
              <FormattedMessage id="MENU.FARM" />
            </span>
            <i className="menu-arrow"></i>
          </a>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/farm/fields")}`}>
                <NavLink className="menu-link" to="/farm/fields">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.FIELDS" />
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/farm/croppings")}`}
              >
                <NavLink className="menu-link" to="/farm/croppings">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.CROPPINGS" />
                  </span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/user/farm")}`}>
                <NavLink className="menu-link" to="/user/farm#farm-invitations">
                  <span className="menu-text">
                    <FormattedMessage id="GENERAL.FARM_INVITATIONS" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-rel ${getMenuItemActive("/reports")}`}
        >
          <NavLink className="menu-link" to="/reports">
            <span className="menu-text">
              <FormattedMessage id="MENU.REPORTS" />
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>

        {!isMobile && (
          <>
            {(showBrigadier || showCropManager || showPulpExchange) && (
              <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                  "/applications"
                )}`}
              >
                <a className="menu-link menu-toggle" role="button">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.APPLICATIONS" />
                  </span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                    {showBrigadier && isOverseer === false && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/brigadier"
                        )}`}
                      >
                        <NavLink
                          className="menu-link"
                          to="/brigadier/dashboard"
                        >
                          <span className="menu-text">
                            <FormattedMessage id="MENU.BRIGADIER" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {showCropManager &&
                      (isCropManagerAdmin ||
                        isCropManagerExtendedAgronomist) && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/crop-manager"
                          )}`}
                        >
                          <NavLink
                            className="menu-link"
                            to="/crop-manager/dashboard"
                          >
                            <span className="menu-text">
                              <FormattedMessage id="MENU.CROP_MANAGER" />
                            </span>
                          </NavLink>
                        </li>
                      )}
                    {showPulpExchange && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/pulp-exchange"
                        )}`}
                      >
                        <NavLink
                          className="menu-link"
                          to="/pulp-exchange/offers"
                        >
                          <span className="menu-text">
                            <FormattedMessage id="MENU.PULP_EXCHANGE" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
          </>
        )}

        {isMobile && (
          <>
            {showBrigadier && isOverseer === false && (
              <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                  "/brigadier"
                )}`}
              >
                <NavLink className="menu-link menu-toggle" to="/brigadier">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.BRIGADIER" />
                  </span>
                  <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/dashboard"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/brigadier/dashboard">
                        <span className="menu-text">
                          <FormattedMessage id="MENU.DASHBOARD" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/results"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/brigadier/results">
                        <span className="menu-text">
                          <FormattedMessage id="MENU.RESULTS" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/receivables"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/brigadier/receivables"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.RECEIVABLES" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/folders/workers"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/brigadier/folders/workers"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.WORKERS" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/folders/packaging"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/brigadier/folders/packaging"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.PACKAGING" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/brigadier/folders/tariffs"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/brigadier/folders/tariffs"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.TARIFFS" />
                        </span>
                      </NavLink>
                    </li>
                    {(isAdmin || isExtendedOverseer) && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/brigadier/settings"
                        )}`}
                      >
                        <NavLink
                          className="menu-link"
                          to="/brigadier/settings"
                        >
                          <span className="menu-text">
                            <FormattedMessage id="MENU.SETTINGS" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            {showCropManager && isCropManagerAdmin && (
              <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                  "/crop-manager"
                )}`}
              >
                <NavLink className="menu-link menu-toggle" to="/crop-manager">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.CROP_MANAGER" />
                  </span>
                  <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/crop-manager/dashboard"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/crop-manager/dashboard"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.DASHBOARD" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/crop-manager/shared-farmland"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/crop-manager/shared-farmland"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.SHARED_FARMLAND" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/crop-manager/growers"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/crop-manager/growers">
                        <span className="menu-text">
                          <FormattedMessage id="MENU.GROWERS" />
                        </span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/crop-manager/documents"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/crop-manager/documents"
                      >
                        <span className="menu-text">
                          <FormattedMessage id="MENU.DOCUMENTS" />
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {showPulpExchange && (
              <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
                  "/pulp-exchange"
                )}`}
              >
                <NavLink className="menu-link menu-toggle" to="/pulp-exchange">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.PULP_EXCHANGE" />
                  </span>
                  <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/pulp-exchange/offers"
                      )}`}
                    >
                      <NavLink className="menu-link" to="/pulp-exchange/offers">
                        <span className="menu-text">
                          <FormattedMessage id="MENU.OFFERS" />
                        </span>
                      </NavLink>
                    </li>
                    {isPlanter && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/pulp-exchange/growers"
                        )}`}
                      >
                        <NavLink
                          className="menu-link"
                          to="/pulp-exchange/growers"
                        >
                          <span className="menu-text">
                            <FormattedMessage id="MENU.GROWERS" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {isMaterialRepresentant && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/pulp-exchange/stock-department"
                        )}`}
                      >
                        <NavLink
                          className="menu-link"
                          to="/pulp-exchange/stock-department"
                        >
                          <span className="menu-text">
                            <FormattedMessage id="MENU.STOCK_DEPARTMENT" />
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {/*end::2 Level*/}
                  </ul>
                </div>
              </li>
            )}
          </>
        )}

        {/* <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/documents"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/documents">
            <span className="menu-text">
              <FormattedMessage id="MENU.DOCUMENTS" />
            </span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/documents/folders"
                )}`}
              >
                <NavLink className="menu-link" to="/documents/folders">
                  <span className="menu-text">
                    <FormattedMessage id="MENU.FOLDERS" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/subscriptions"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/subscriptions">
            <span className="menu-text">Subscriptions</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/subscriptions/pulp-exchange"
                )}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/subscriptions/pulp-exchange"
                >
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-top-panel-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Pulp Exchange</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div
                  className={`menu-submenu menu-submenu-classic menu-submenu-right`}
                >
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/subscriptions/pulp-exchange/growers"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/subscriptions/pulp-exchange/growers"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Growers</span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/subscriptions/pulp-exchange/test1"
                      )}`}
                    >
                      <NavLink
                        className="menu-link"
                        to="/subscriptions/pulp-exchange/test1"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Test1</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li> */}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
