import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Table, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function DashboardBPartnersTable({ bPartners, centerOnMap }) {
  return (
    <>
      {bPartners.length > 0 ? (
        <Col xs={12} className="text-center mt-2 table-full-height">
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>
                  <FormattedMessage id="GENERAL.NAME" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.ACTIONS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {bPartners.map((bPartner, index) => (
                <tr
                  key={`${bPartner.bpartnerId}-${index}`}
                  className="text-center"
                >
                  <td>{bPartner.bpartnerFullName}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-tooltip">
                          <FormattedMessage id="GENERAL.SHOW_ON_MAP" />
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={() =>
                          centerOnMap(
                            bPartner.bpartnerId
                          )
                        }
                      >
                        <i
                          className="flaticon2-location"
                          style={{ paddingLeft: "4px" }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      ) : (
        <Col xs={12} className="text-center mt-5">
          <h6>
            <FormattedMessage id="GENERAL.NO_CROPPINGS" />
          </h6>
        </Col>
      )}
    </>
  );
}
