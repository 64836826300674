import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SingleApplicationPricing from "../SingleApplicationPricing";
import CMTimeSwitch from "./CMTimeSwitch";

export default function Step1() {
  const selectedApplication = useSelector(
    (state) => state.pricing.selectedApplication
  );

  return (
    <div className="card-body">
      {selectedApplication.symbol === "BRIGADIER" && (
        <img
          src={toAbsoluteUrl(`/media/logos/brigadier__logo.png`)}
          alt={selectedApplication.name}
          className="d-block mx-auto"
          width={150}
          height={80}
          style={{ objectFit: "cover" }}
        />
      )}
      {selectedApplication.symbol === "CROPMANAGER" && (
        <img
          src={toAbsoluteUrl(`/media/logos/cropmanager__logo.png`)}
          alt={selectedApplication.name}
          className="d-block mx-auto"
          width={150}
          height={80}
          style={{ objectFit: "cover" }}
        />
      )}
      <p className="text-center">
        {selectedApplication.symbol === "BRIGADIER" && (
          <FormattedMessage id="PRICING.CHOOSE_PLAN.BRIGADIER" />
        )}
        {selectedApplication.symbol === "CROPMANAGER" && (
          <FormattedMessage id="PRICING.CHOOSE_PLAN.MANAGER" />
        )}
        <br /> <br />
        {selectedApplication.symbol === "BRIGADIER" && (
          <a
            href="https://cropchart.net/brigadier/#main"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="PRICING.KNOW_MORE.BRIGADIER" />
          </a>
        )}
        {selectedApplication.symbol === "CROPMANAGER" && (
          <a
            href="https://cropchart.net/cropchart-manager/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="PRICING.KNOW_MORE.MANAGER" />
          </a>
        )}
      </p>
      {selectedApplication.symbol === "CROPMANAGER" && <CMTimeSwitch />}
      <div className="row -m-4 justify-content-center">
        {selectedApplication.prices.map((sub, index) => (
          <SingleApplicationPricing
            appName={selectedApplication.symbol}
            pricing={sub}
            key={`singleAppPricing-${index}`}
          />
        ))}
      </div>
      {selectedApplication.symbol === "BRIGADIER" && (
        <p className="text-center mt-6">
          <FormattedMessage id="PRICING.DONT_KNOW_PLAN.TEXT" />?{" "}
          <a
            href="https://cropchart.net/about-2/#sales-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>
              <FormattedMessage id="PRICING.DONT_KNOW_PLAN.LINK" />.
            </strong>
          </a>
        </p>
      )}
    </div>
  );
}
