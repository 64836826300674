import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { registerPaymentToPrzelewy24 } from "../../../../services/paymentsCrud";
import errorHandler from "../../../../shared/errorHandler";
import { Checkbox } from "@material-ui/core";
import TermsModal from "./modals/TermsModal";
import PolicyModal from "./modals/PolicyModal";
import DataProcessingModal from "./modals/DataProcessingModal";
import DiscountCodeInput from "./DiscountCodeInput";
import displayFeedback from "../../../../components/helpers/displayFeedback";

export default function Step4() {
  const selectedPlan = useSelector((state) => state.pricing.selectedPlan);
  const addresses = useSelector((state) => state.pricing.addresses);
  const discount = useSelector((state) => state.pricing.discount);
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const intl = useIntl();
  const [brigadierPrice, setBrigadierPrice] = useState(0);
  const [brigadierPriceBrutto, setBrigadierPriceBrutto] = useState(0);
  const [brigadierDiscountPrice, setBrigadierDiscountPrice] = useState(0);
  const [
    brigadierDiscountPriceBrutto,
    setBrigadierDiscountPriceBrutto,
  ] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [finalPriceBrutto, setFinalPriceBrutto] = useState(0);
  const [finalDiscountPrice, setFinalDiscountPrice] = useState(0);
  const [finalDiscountPriceBrutto, setFinalDiscountPriceBrutto] = useState(0);
  const [comment, setComment] = useState("");
  const [courierTabIndex, setCourierTabIndex] = useState(0);
  const [consent1Accepted, setConsent1Accepted] = useState(false);
  const [consent2Accepted, setConsent2Accepted] = useState(false);
  const [consent3Accepted, setConsent3Accepted] = useState(false);
  const [showConsent1Modal, setShowConsent1Modal] = useState(false);
  const [showConsent2Modal, setShowConsent2Modal] = useState(false);
  const [showConsent3Modal, setShowConsent3Modal] = useState(false);

  const tabIndex = selectedPlan.cmTimeIndex ? selectedPlan.cmTimeIndex : 0;

  const getParamPriceBasedOnQuantity = (param, isBrutto) => {
    let priceToReturn = 0;
    if (isBrutto) {
      if (param.prices.length === 1)
        priceToReturn = param.prices[0].priceBrutto * param.value;
      else if (param.prices.length > 1)
        param.prices.forEach((price) => {
          if (
            param.value >= price.quantityFrom &&
            param.value <= price.quantityTo
          )
            priceToReturn = price.priceBrutto * param.value;
        });
    } else {
      if (param.prices.length === 1)
        priceToReturn = param.prices[0].price * param.value;
      else if (param.prices.length > 1)
        param.prices.forEach((price) => {
          if (
            param.value >= price.quantityFrom &&
            param.value <= price.quantityTo
          )
            priceToReturn = price.price * param.value;
        });
    }
    return priceToReturn;
  };

  const getParamUnitPriceBasedOnQuantity = (param) => {
    let unitPriceToReturn = 0;
    if (param.prices.length === 1) unitPriceToReturn = param.prices[0].price;
    else if (param.prices.length > 1)
      param.prices.forEach((price) => {
        if (
          param.value >= price.quantityFrom &&
          param.value <= price.quantityTo
        )
          unitPriceToReturn = price.price;
      });
    return unitPriceToReturn;
  };

  useEffect(() => {
    if (selectedPlan.appName === "BRIGADIER") {
      const brigadierOnlyPrice =
        (selectedPlan.brigadierCount - selectedPlan.data.parameters[0].value) *
          selectedPlan.data.parameters[0].price.price +
        selectedPlan.data.prices[0].amount;
      setBrigadierPrice(brigadierOnlyPrice);
      const brigadierOnlyPriceBrutto =
        (selectedPlan.brigadierCount - selectedPlan.data.parameters[0].value) *
          selectedPlan.data.parameters[0].price.priceBrutto +
        selectedPlan.data.prices[0].amountBrutto;
      setBrigadierPriceBrutto(brigadierOnlyPriceBrutto);
      let paramsPrice = 0;
      selectedPlan.data.parameters.forEach((param) => {
        if (param.name === "BRIGADIER_NUMBER") return;
        else paramsPrice += getParamPriceBasedOnQuantity(param);
      });
      setFinalPrice(brigadierOnlyPrice + paramsPrice);
      let paramsPriceBrutto = 0;
      selectedPlan.data.parameters.forEach((param) => {
        if (param.name === "BRIGADIER_NUMBER") return;
        else paramsPriceBrutto += getParamPriceBasedOnQuantity(param, true);
      });
      setFinalPriceBrutto(brigadierOnlyPriceBrutto + paramsPriceBrutto);
      if (discount) {
        setBrigadierDiscountPrice(
          (brigadierOnlyPrice * (100 - discount.percent)) / 100
        );
        setBrigadierDiscountPriceBrutto(
          (brigadierOnlyPriceBrutto * (100 - discount.percent)) / 100
        );
        setFinalDiscountPrice(
          (brigadierOnlyPrice * (100 - discount.percent)) / 100 + paramsPrice
        );
        setFinalDiscountPriceBrutto(
          (brigadierOnlyPriceBrutto * (100 - discount.percent)) / 100 +
            paramsPriceBrutto
        );
      } else {
        setFinalDiscountPrice(null);
        setFinalDiscountPriceBrutto(null);
      }
    } else if (selectedPlan.appName === "CROPMANAGER") {
      setFinalPrice(selectedPlan.data.prices[tabIndex].amount);
      setFinalPriceBrutto(selectedPlan.data.prices[tabIndex].amountBrutto);
      if (discount) {
        setFinalDiscountPrice(
          (selectedPlan.data.prices[tabIndex].amount *
            (100 - discount.percent)) /
            100
        );
        setFinalDiscountPriceBrutto(
          (selectedPlan.data.prices[tabIndex].amountBrutto *
            (100 - discount.percent)) /
            100
        );
      } else {
        setFinalDiscountPrice(null);
        setFinalDiscountPriceBrutto(null);
      }
    }
  }, [
    discount,
    selectedPlan.appName,
    selectedPlan.brigadierCount,
    selectedPlan.data.parameters,
    selectedPlan.data.prices,
    tabIndex,
  ]);

  const goToPayment = () => {
    const parameters = selectedPlan.data.parameters.map((param, index) => {
      if (param.name === "BRIGADIER_NUMBER")
        return { ...param, value: selectedPlan.brigadierCount };
      else return param;
    });
    const dataToSend = {
      contactDetail: addresses.contactDetail.firstName
        ? {
            firstName: addresses.contactDetail.firstName,
            lastName: addresses.contactDetail.lastName,
            address1: addresses.contactDetail.street,
            address2: `${addresses.contactDetail.houseNumber}${
              addresses.contactDetail.aptNumber
                ? `/${addresses.contactDetail.aptNumber}`
                : ""
            }`,
            countryId: Number(addresses.contactDetail.countryId),
            postalCode: addresses.contactDetail.postalCode,
            postalCodeName: addresses.contactDetail.city,
          }
        : undefined,
      address: addresses.address.firstName
        ? {
            firstName: addresses.address.firstName,
            lastName: addresses.address.lastName,
            address1: addresses.address.street,
            address2: `${addresses.address.houseNumber}${
              addresses.address.aptNumber
                ? `/${addresses.address.aptNumber}`
                : ""
            }`,
            countryId: Number(addresses.address.countryId),
            postalCode: addresses.address.postalCode,
            postalCodeName: addresses.address.city,
          }
        : undefined,
      invoiceAddress: addresses.invoiceAddress.companyName
        ? {
            name: addresses.invoiceAddress.companyName,
            address1: addresses.invoiceAddress.street,
            address2: `${addresses.invoiceAddress.houseNumber}${
              addresses.invoiceAddress.aptNumber
                ? `/${addresses.invoiceAddress.aptNumber}`
                : ""
            }`,
            countryId: Number(addresses.invoiceAddress.countryId),
            postalCode: addresses.invoiceAddress.postalCode,
            postalCodeName: addresses.invoiceAddress.city,
            vatin: addresses.invoiceAddress.nip,
          }
        : undefined,
      isInvoice: !!addresses.invoiceAddress.companyName,
      parameters,
      subscriptionPrice: selectedPlan.data.prices[tabIndex].value,
      comment,
      mail: addresses.basicData.email,
      phone: addresses.basicData.phone,
      discountCode: discount ? discount.discountCode : undefined,
    };
    registerPaymentToPrzelewy24(dataToSend)
      .then((response) => {
        window.location = response.data;
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffect(() => {
    const params = selectedPlan.data.parameters;
    if (selectedPlan.appName === "BRIGADIER") {
      if (params.find((param) => param.name === "COURIER_WEIGHT").value)
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_WEIGHT")
        );
      else if (
        params.find((param) => param.name === "COURIER_SMARTPHONE").value
      )
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_SMARTPHONE")
        );
      else if (
        params.find((param) => param.name === "COURIER_ITEM_IDENTIFIER").value
      )
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_ITEM_IDENTIFIER")
        );
      else setCourierTabIndex(0);
    }
  }, [selectedPlan.data.parameters, selectedPlan.appName]);

  return (
    <>
      <DataProcessingModal
        open={showConsent1Modal}
        close={() => setShowConsent1Modal(false)}
        acceptVisible
        accept={() => {
          setShowConsent1Modal(false);
          setConsent1Accepted(true);
        }}
      />
      <PolicyModal
        open={showConsent2Modal}
        close={() => setShowConsent2Modal(false)}
        acceptVisible
        accept={() => {
          setShowConsent2Modal(false);
          setConsent2Accepted(true);
        }}
      />
      <TermsModal
        open={showConsent3Modal}
        close={() => setShowConsent3Modal(false)}
        acceptVisible
        accept={() => {
          setShowConsent3Modal(false);
          setConsent3Accepted(true);
        }}
      />
      <div className="card-body">
        <div className="mb-10">
          <h6>
            <FormattedMessage id="PRICING.INFORMATIONS" />
          </h6>
          <p>
            <FormattedMessage id="PRICING.SELECTED_SUBSCRIPTION" />:{" "}
            <strong>{selectedPlan.data.desc}</strong>
          </p>
          {selectedPlan.appName === "CROPMANAGER" && (
            <p className="mt-n3">
              <FormattedMessage id="PRICING.AGRONOMIST_COUNT" />:{" "}
              <strong>{selectedPlan.data.parameters[0].value}</strong>
            </p>
          )}
          <p className="mt-n3">
            <FormattedMessage id="PRICING.SUBSCRIPTION_TIME" />:{" "}
            {selectedPlan.data.prices[tabIndex].month && (
              <strong>
                {selectedPlan.data.prices[tabIndex].month}{" "}
                <FormattedMessage id="PRICING.TIME.MONTHS" />
              </strong>
            )}
            {selectedPlan.data.prices[tabIndex].days && (
              <strong>
                {selectedPlan.data.prices[tabIndex].days}{" "}
                <FormattedMessage id="PRICING.TIME.DAYS" />
              </strong>
            )}
          </p>
          {selectedPlan.appName === "BRIGADIER" && (
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <div className="d-flex">
                <label className="flex-shrink-0 mb-0 mr-2">
                  <FormattedMessage id="PRICING.BRIGADIER_COUNT" />:
                </label>
                {selectedPlan.brigadierCount}
              </div>
              <div className="d-flex justify-content-center flex-column">
                <h6 className="m-0 mb-1">
                  <span
                    className={
                      discount ? "text-muted line-through d-block" : ""
                    }
                  >
                    {brigadierPrice.toLocaleString(languageCode, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                  {discount && brigadierDiscountPrice && (
                    <span>
                      {brigadierDiscountPrice.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                  )}
                  {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                  <FormattedMessage id="PRICING.NETTO" />
                </h6>
                <h6 className="m-0">
                  <strong>
                    <span
                      className={
                        discount ? "text-muted line-through d-block" : ""
                      }
                    >
                      {brigadierPriceBrutto.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                    {discount && brigadierDiscountPrice && (
                      <span>
                        {brigadierDiscountPriceBrutto.toLocaleString(
                          languageCode,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                      </span>
                    )}
                    {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                    <FormattedMessage id="PRICING.BRUTTO" />
                  </strong>
                </h6>
              </div>
            </div>
          )}
          {selectedPlan.appName === "CROPMANAGER" && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center">
                <h6 className="m-0">
                  <strong>
                    <span
                      className={
                        discount ? "text-muted line-through d-block" : ""
                      }
                    >
                      {(
                        selectedPlan.data.prices[tabIndex].amount /
                        selectedPlan.data.prices[tabIndex].month
                      ).toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                    {discount && finalDiscountPrice && (
                      <span>
                        {(
                          ((selectedPlan.data.prices[tabIndex].amount /
                            selectedPlan.data.prices[tabIndex].month) *
                            (100 - discount.percent)) /
                          100
                        ).toLocaleString(languageCode, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </span>
                    )}
                    {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                    <FormattedMessage id="PRICING.NETTO" />
                    /<FormattedMessage id="PRICING.TIME.MONTH" />
                  </strong>
                </h6>
              </div>
            </div>
          )}
        </div>
        {selectedPlan.appName === "BRIGADIER" && (
          <div className="mb-10">
            <h6>
              <FormattedMessage id="PRICING.ADDITIONAL_PARAMETERS" />
            </h6>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="PLACEHOLDER.NAME" />
                  </th>
                  <th>
                    <FormattedMessage id="PRICING.PRICE.NETTO" />
                  </th>
                  <th>
                    <FormattedMessage id="PLACEHOLDER.QUANTITY" />
                  </th>
                  <th className="text-right">
                    <FormattedMessage id="PRICING.VALUE.NETTO" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedPlan.data.parameters.map((param, index) => {
                  if (index === 0 || param.symbol.includes("COURIER"))
                    return null;
                  else
                    return (
                      <tr key={`step2-params-${index}`}>
                        <td className="align-middle">
                          <FormattedMessage
                            id={`PRICING.PRODUCT.${param.name}`}
                          />
                        </td>
                        <td className="align-middle">
                          {getParamUnitPriceBasedOnQuantity(
                            param
                          ).toLocaleString(languageCode, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          {selectedPlan.data.prices[0].currency.name}/
                          <FormattedMessage id="PRICING.PCS" />
                        </td>
                        <td className="align-middle">
                          {param.value} <FormattedMessage id="PRICING.PCS" />
                        </td>
                        <td
                          className="align-middle text-right"
                          style={{ width: "15%" }}
                        >
                          <strong>
                            {getParamPriceBasedOnQuantity(param).toLocaleString(
                              languageCode,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            {selectedPlan.data.prices[0].currency.name}
                          </strong>
                        </td>
                      </tr>
                    );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {selectedPlan.appName === "BRIGADIER" &&
          courierTabIndex > 0 &&
          selectedPlan.data.parameters[courierTabIndex] &&
          selectedPlan.data.parameters[courierTabIndex].value > 0 && (
            <div className="mb-10">
              <h6 className="mb-4">
                <FormattedMessage id="PRICING.DELIVERY" />
              </h6>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-50">
                  <div className="symbol-label">
                    <i className="fa fa-truck" />
                  </div>
                </div>
                <div className="ml-4">
                  <p className="m-0">
                    <FormattedMessage id="PRICING.DHL_COURIER" />
                  </p>
                  <p className="m-0 font-weight-bold">
                    {selectedPlan.data.parameters[
                      courierTabIndex
                    ].price.price.toLocaleString(languageCode, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {selectedPlan.data.prices[tabIndex].currency.name}
                  </p>
                </div>
              </div>
            </div>
          )}
        <div className="mb-6">
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <h6>
              <FormattedMessage id="PRICING.FINAL_PRICE" />
            </h6>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="m-0 mb-1">
                {!discount && (
                  <span>
                    {finalPrice.toLocaleString(languageCode, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                )}
                {discount && finalDiscountPrice && (
                  <span>
                    {finalDiscountPrice.toLocaleString(languageCode, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                )}
                {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                <FormattedMessage id="PRICING.NETTO" />
              </h6>
              <h6>
                <strong>
                  {!discount && (
                    <span>
                      {finalPriceBrutto.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                  )}
                  {discount && finalDiscountPrice && (
                    <span>
                      {finalDiscountPriceBrutto.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                  )}
                  {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                  <FormattedMessage id="PRICING.BRUTTO" />
                </strong>
              </h6>
            </div>
          </div>
        </div>
        <div>
          <DiscountCodeInput />
        </div>
        {addresses && (
          <div>
            <Row>
              {addresses.basicData?.email && (
                <Col md={4} className="mb-10">
                  <h6>
                    <FormattedMessage id="PRICING.BASIC_DATA" />
                  </h6>
                  <p>{addresses.basicData.email}</p>
                  <p>
                    <FormattedMessage id="PRICING.TELEPHONE_SHORT" />{" "}
                    {addresses.basicData.phone}
                  </p>
                  {addresses.contactDetail?.street && (
                    <>
                      <p>
                        {addresses.contactDetail.street}{" "}
                        {addresses.contactDetail.houseNumber}
                        {addresses.contactDetail.aptNumber
                          ? `/${addresses.contactDetail.aptNumber}`
                          : ""}
                      </p>
                      <p>
                        {addresses.contactDetail.countryIdName}{" "}
                        {addresses.contactDetail.postalCode}{" "}
                        {addresses.contactDetail.city}
                      </p>
                    </>
                  )}
                </Col>
              )}
              {addresses.address?.firstName && (
                <Col md={4} className="mb-10">
                  <h6>
                    <FormattedMessage id="PRICING.DELIVERY_ADDRESS" />
                  </h6>
                  <p>
                    {addresses.address.firstName} {addresses.address.lastName}
                  </p>
                  <p>
                    {addresses.address.street} {addresses.address.houseNumber}
                    {addresses.address.aptNumber
                      ? `/${addresses.address.aptNumber}`
                      : ""}
                  </p>
                  <p>
                    {addresses.address.countryIdName}{" "}
                    {addresses.address.postalCode} {addresses.address.city}
                  </p>
                </Col>
              )}
              {addresses.invoiceAddress?.companyName && (
                <Col md={4} className="mb-10">
                  <h6>
                    <FormattedMessage id="PRICING.INVOICE_DATA" />
                  </h6>
                  <p>{addresses.invoiceAddress.companyName}</p>
                  <p>
                    {addresses.invoiceAddress.street}{" "}
                    {addresses.invoiceAddress.houseNumber}
                    {addresses.invoiceAddress.aptNumber
                      ? `/${addresses.invoiceAddress.aptNumber}`
                      : ""}
                  </p>
                  <p>
                    {addresses.invoiceAddress.countryIdName}{" "}
                    {addresses.invoiceAddress.postalCode}{" "}
                    {addresses.invoiceAddress.city}
                  </p>
                  <p>
                    <FormattedMessage id="GENERAL.NIP" />:{" "}
                    {addresses.invoiceAddress.nip}
                  </p>
                </Col>
              )}
            </Row>
          </div>
        )}
        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <FormattedMessage id="REPORTS.COMMENTS" />
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </Form.Group>
        </Form>
        <div>
          <div className="d-flex align-items-start">
            <Checkbox
              checked={consent1Accepted}
              onChange={() => setConsent1Accepted(!consent1Accepted)}
            />
            <p className="ml-4" style={{ marginTop: "9px" }}>
              <strong className="text-danger mr-1">*</strong>
              <FormattedMessage id="PRICING.CONSENT.1" />
              <button
                className="ml-2 btn-link bg-white border-0"
                onClick={() => setShowConsent1Modal(true)}
              >
                <FormattedMessage id="PRICING.CONSENT.FULL_CONTENT" />
              </button>
            </p>
          </div>
          <div className="d-flex align-items-start">
            <Checkbox
              checked={consent2Accepted}
              onChange={() => setConsent2Accepted(!consent2Accepted)}
            />
            <p className="ml-4" style={{ marginTop: "9px" }}>
              <strong className="text-danger mr-1">*</strong>
              <FormattedMessage id="PRICING.CONSENT.2" />
              <button
                className="ml-2 btn-link bg-white border-0"
                onClick={() => setShowConsent2Modal(true)}
              >
                <FormattedMessage id="PRICING.CONSENT.FULL_CONTENT" />
              </button>
            </p>
          </div>
          <div className="d-flex align-items-start">
            <Checkbox
              checked={consent3Accepted}
              onChange={() => setConsent3Accepted(!consent3Accepted)}
            />
            <p className="ml-4" style={{ marginTop: "9px" }}>
              <strong className="text-danger mr-1">*</strong>
              <FormattedMessage id="PRICING.CONSENT.3" />
              <button
                className="ml-2 btn-link bg-white border-0"
                onClick={() => setShowConsent3Modal(true)}
              >
                <FormattedMessage id="PRICING.CONSENT.FULL_CONTENT" />
              </button>
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => goToPayment()}
            disabled={
              !!(!consent1Accepted || !consent2Accepted || !consent3Accepted)
            }
          >
            <FormattedMessage id="PRICING.GO_TO_PAYMENT" />
          </Button>
        </div>
        <div>
          <InlineSVG
            src={toAbsoluteUrl("/media/payments/flaga_wszystkie.svg")}
          />
        </div>
      </div>
    </>
  );
}
