const AG_GRID_LOCALE_PL = {
  // Set Filter
  selectAll: "(Zaznacz wszystkie)",
  selectAllSearchResults: "(Zaznacz wszystkie wyniki wyszukiwania)",
  searchOoo: "Szukaj...",
  blanks: "(Puste)",
  noMatches: "Brak dopasowań",

  // Number Filter & Text Filter
  filterOoo: "Filtruj...",
  equals: "Równe",
  notEqual: "Nie równa się",
  blank: "Puste",
  notBlank: "Nie puste",
  empty: "Wybierz jedno",

  // Number Filter
  lessThan: "Mniejsze niż",
  greaterThan: "Większe niż",
  lessThanOrEqual: "Mniejsze lub równe",
  greaterThanOrEqual: "Większe lub równe",
  inRange: "Pomiędzy",
  inRangeStart: "od",
  inRangeEnd: "do",

  // Text Filter
  contains: "Zawiera",
  notContains: "Nie zawiera",
  startsWith: "Zaczyna się od",
  endsWith: "Kończy się na",

  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",

  // Filter Conditions
  andCondition: "I",
  orCondition: "LUB",

  // Filter Buttons
  applyFilter: "Zastosuj filtr",
  resetFilter: "Reset",
  clearFilter: "Wyczyść filtr",
  cancelFilter: "Anuluj",

  // Filter Titles
  textFilter: "Filtr tekstu",
  numberFilter: "Filtr liczb",
  dateFilter: "Filtr daty",
  setFilter: "Filtr wyboru",

  // Side Bar
  columns: "Kolumny",
  filters: "Filtry",

  // columns tool panel
  groups: "Grupy wierszy",
  rowGroupColumnsEmptyMessage: "Przeciągnij tutaj, aby ustawić grupy wierszy",
  values: "Wartości",
  valueColumnsEmptyMessage: "Przeciągnij tutaj, aby zagregować",
  pivots: "Etykiety kolumn",
  pivotColumnsEmptyMessage: "Przeciągnij tutaj, aby ustawić etykiety kolumn",

  // Header of the Default Group Column
  group: "Grupa",

  // Other
  loadingOoo: "Ładowanie...",
  noRowsToShow: "Brak danych do pokazania",
  enabled: "Włączone",

  // Menu
  pinColumn: "Przypnij kolumnę",
  pinLeft: "Przypnij na lewo",
  pinRight: "Przypnij na prawo",
  noPin: "Odepnij",
  valueAggregation: "Agregacja wartości",
  autosizeThiscolumn: "Dopasuj rozmiar tej kolumny",
  autosizeAllColumns: "Dopasuj rozmiar wszystkich kolumn",
  groupBy: "Grupuj według",
  ungroupBy: "Wyłącz grupowanie według",
  addToValues: "Dodaj ${variable} do wartości",
  removeFromValues: "Usuń ${variable} z wartości",
  addToLabels: "Dodaj ${variable} do etykiet",
  removeFromLabels: "Usuń ${variable} z etykiet",
  resetColumns: "Zresetuj kolumny",
  expandAll: "Rozwiń wszystko",
  collapseAll: "Zwiń wszystko",
  copy: "Kopiuj",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "Kopiuj z nagłówkami",
  copyWithGroupHeaders: "Kopiuj z grupami nagłówków",
  paste: "Wklej",
  ctrlV: "Ctrl+V",
  export: "Eksportuj",
  csvExport: "Eksportuj do CSV",
  excelExport: "Eksportuj do Excela (.xlsx)",

  // Enterprise Menu Aggregation and Status Bar
  sum: "Suma",
  min: "Min",
  max: "Max",
  none: "Nic",
  count: "Policz",
  avg: "Średnia",
  filteredRows: "Filtrowane",
  selectedRows: "Zaznaczone",
  totalRows: "Razem wierszy",
  totalAndFilteredRows: "Wiersze",
  more: "Więcej",
  to: "do",
  of: "z",
  page: "Strona",
  nextPage: "Następna strona",
  lastPage: "Ostatnia strona",
  firstPage: "Pierwsza strona",
  previousPage: "Poprzednia strona",

  // Pivoting
  pivotColumnGroupTotals: "Razem",

  // Enterprise Menu (Charts) - not implemented in app
  /*pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',*/

  // ARIA
  ariaHidden: "ukryty",
  ariaVisible: "widoczny",
  ariaChecked: "zaznaczony",
  ariaUnchecked: "odznaczony",
  ariaIndeterminate: "nieokreślony",
  ariaDefaultListName: "Lista",
  ariaColumnSelectAll: "Przełączanie Wybierz wszystkie kolumny",
  ariaInputEditor: "Edytor danych wejściowych",
  ariaDateFilterInput: "Wprowadzanie filtra daty",
  ariaFilterList: "Filtruj listę",
  ariaFilterInput: "Filtruj dane wejściowe",
  ariaFilterColumnsInput: "Filtruj dane wejściowe kolumn",
  ariaFilterValue: "Filtruj wartość",
  ariaFilterFromValue: "Filtruj od wartości",
  ariaFilterToValue: "Filtruj do wartości",
  ariaFilteringOperator: "Operator filtrujący",
  ariaColumn: "Kolumna",
  ariaColumnList: "Lista kolumn",
  ariaColumnGroup: "Grupa kolumn",
  ariaRowSelect: "Wciśnij spację aby zaznaczyć ten wiersz",
  ariaRowDeselect: "Wciśnij spację aby odznaczyć ten wiersz",
  ariaRowToggleSelection: "Wciśnij spację by przełączyć zaznaczanie wierszy",
  ariaRowSelectAll:
    "Wciśnij spację by przełączyć zaznaczanie wszystkich wierszy",
  ariaToggleVisibility: "Wciśnij spację by przełączyć widoczność",
  ariaSearch: "Szukaj",
  ariaSearchFilterValues: "Szukaj wartości filtrów",

  ariaRowGroupDropZonePanelLabel: "Grupy wierszy",
  ariaValuesDropZonePanelLabel: "Wartości",
  ariaPivotDropZonePanelLabel: "Etykiety kolumn",
  ariaDropZoneColumnComponentDescription: "Wciśnij Delete aby usunąć",
  ariaDropZoneColumnValueItemDescription:
    "Wciśnij Enter aby zmienić typ agregacji",

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: "Menu kolumn",
  ariaLabelCellEditor: "Edytor komórek",
  ariaLabelDialog: "Dialog",
  ariaLabelSelectField: "Wybierz pole",
  ariaLabelTooltip: "Tooltip",
  ariaLabelContextMenu: "Menu kontekstowe",
  ariaLabelSubMenu: "Podmenu",
  ariaLabelAggregationFunction: "Funkcja agregacyjna",

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: " ",
  decimalSeparator: ",",
};

export default AG_GRID_LOCALE_PL;
