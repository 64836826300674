import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";

export function CalendarFilters() {
  const intl = useIntl();
  const [showTreatments, setShowTreatments] = useState(true);
  const treatmentTypes = useSelector(state => state.calendar.treatmentTypes)
  const eventStatuses = useSelector(state => state.calendar.eventStatuses)
  const dispatch = useDispatch()

  const onShowingOrHidingTreatments = (e) => {
    setShowTreatments(e.target.checked);
    dispatch({
      type: "calendar/setAllTreatmentTypesChecked",
      payload: e.target.checked
    })
  };

  const onShowingOrHidingTreatmentType = (event, treatment) => {
    if (showTreatments === false && event.target.checked) {
      setShowTreatments(true);
    }
    dispatch({
      type: "calendar/setTreatmentTypeChecked",
      payload: {
        treatmentTypeId: treatment.treatmentTypeId,
        checked: event.target.checked
      }
    })
  };

  const onShowingOrHidingEventStatus = (event, eventStatus) => {
    dispatch({
      type: "calendar/setEventStatusChecked",
      payload: {
        symbol: eventStatus.symbol,
        checked: event.target.checked
      }
    })
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <Form.Row className="mb-6">
          <Form.Group as={Col} md={12} className="mb-0">
            <span className="text--bold">
              <FormattedMessage id="GENERAL.LEGEND" />
            </span>
          </Form.Group>
          <Form.Group as={Col} md={12} className="mb-0">
            <FormControlLabel
              className={`checkbox__fields--no_margin_bottom checkbox__treatments ${
                showTreatments ? "checkbox--active " : ""
              }`}
              control={
                <Checkbox
                  checked={showTreatments}
                  onChange={(e) => onShowingOrHidingTreatments(e)}
                />
              }
              label={`${intl.formatMessage({
                id: "PLACEHOLDER.SHOW_TREATMENTS",
              })}`}
            />
          </Form.Group>
          {treatmentTypes.length > 0 && (
            <>
              {treatmentTypes.map((treatment) => (
                <Form.Group
                  key={treatment.treatmentTypeId}
                  as={Col}
                  md={12}
                  className="mb-0 ml-5"
                >
                  <FormControlLabel
                    className={`checkbox__fields--no_margin_bottom ${treatment.treatmentTypeId ===
                      1 &&
                      "checkbox__soil_cultivation"} ${treatment.treatmentTypeId ===
                      2 &&
                      "checkbox__fertilization"} ${treatment.treatmentTypeId ===
                      3 && "checkbox__planting"} ${treatment.treatmentTypeId ===
                      5 &&
                      "checkbox__plant_protection"} ${treatment.treatmentTypeId ===
                      6 && "checkbox__irrigation"} ${
                      treatment.checked ? "checkbox--active " : ""
                    }`}
                    control={
                      <Checkbox
                        checked={treatment.checked}
                        onChange={(e) =>
                          onShowingOrHidingTreatmentType(e, treatment)
                        }
                      />
                    }
                    label={`${intl.formatMessage({
                      id: `TREATMENT.${treatment.treatmentTypeDesc}`,
                    })}`}
                  />
                </Form.Group>
              ))}
            </>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={12} className="mb-0">
            <span className="text--bold">
              <FormattedMessage id="GENERAL.STATUS" />
            </span>
          </Form.Group>
          {eventStatuses.length > 0 && (
            <>
              {eventStatuses.map((eventStatus) => (
                <Form.Group
                  key={eventStatus.symbol}
                  as={Col}
                  md={12}
                  className="mb-0 ml-5"
                >
                  <FormControlLabel
                    className="checkbox__fields--no_margin_bottom"
                    control={
                      <Checkbox
                        checked={eventStatus.checked}
                        onChange={(e) =>
                          onShowingOrHidingEventStatus(e, eventStatus)
                        }
                      />
                    }
                    label={`${intl.formatMessage({
                      id: `GENERAL.${eventStatus.value}`,
                    })}`}
                  />
                </Form.Group>
              ))}
            </>
          )}
        </Form.Row>
      </div>
    </div>
  );
}
