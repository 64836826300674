import axios from "axios";

export const GROWERS_URL = `${process.env.REACT_APP_URL}gw/groOwners`;
export const SELL_OFFER_URL = `${process.env.REACT_APP_URL}gw/sellOffers`;

export function getGrowerLimits() {
  return axios.get(`${GROWERS_URL}/limit`);
}

export function getGrowerOffers() {
  return axios.get(`${GROWERS_URL}/offer`);
}

export function createSellOffer(availableProductId, form) {
  return axios.post(
    `${SELL_OFFER_URL}/availableProduct/${availableProductId}`,
    form
  );
}

export function updateSellOffer(sellOfferId, form) {
  return axios.put(`${SELL_OFFER_URL}/${sellOfferId}`, form);
}

export function deleteSellOffer(sellOfferId) {
  return axios.delete(`${SELL_OFFER_URL}/${sellOfferId}`);
}

export function acceptBuyOffer(buyOfferId) {
  return axios.put(`${SELL_OFFER_URL}/buyOffer/${buyOfferId}/accept`, null);
}

export function negotiateBuyOffer(buyOfferId, form) {
  return axios.put(`${SELL_OFFER_URL}/buyOffer/${buyOfferId}/negotiate`, form);
}

export function rejectBuyOffer(buyOfferId, form) {
  return axios.put(`${SELL_OFFER_URL}/buyOffer/${buyOfferId}/reject`, form);
}

// export function getGroOwnersToOrgentity() {
//   return axios.get(`${GROWERS_URL}`);
// }
