import React, { useEffect, useState, useRef } from "react"
import { Row, Col } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { CroppingShortcut } from ".."
import { CircularProgress } from "@material-ui/core"

export default function CroppingsList(props) {
  const { croppingsData, searchTerm } = props
  const settings = {
    itemsToLoad: window.innerHeight >= 2400 ? 8
                : window.innerWidth < 768 ? 2
                : 6,
    itemsToAdd: window.innerWidth < 768 ? 2 : 4
  }
  const [filteredCroppings, setFilteredCroppings] = useState(croppingsData)
  const [croppingsToShow, setCroppingsToShow] = useState({
    page: 1,
    data: filteredCroppings?.slice(0,6)
  })
  const [isBottom, setIsBottom] = useState(false);
  const listRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0,0)
    const filteredCroppingsData = croppingsData.filter(cropping => {
      return (
        cropping.shortName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cropping.variety.shortName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cropping.variety.speciesDesc.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })
    setFilteredCroppings(filteredCroppingsData)
    setCroppingsToShow({
      page: 1,
      data: filteredCroppingsData?.slice(0, settings.itemsToLoad)
    })
  }, [croppingsData, searchTerm, settings.itemsToLoad])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    const scrollTop = (document.documentElement
      && document.documentElement.scrollTop)
      || document.body.scrollTop;
    const scrollHeight = (listRef.current
      && listRef.current.scrollHeight)
      || document.body.scrollHeight;
    if (scrollTop + window.innerHeight >= scrollHeight && scrollTop > 0){
      setIsBottom(true);
    }
  }

  useEffect(() => {
    if (isBottom) {
      if (filteredCroppings.length !== 0 && croppingsToShow.data.length !== filteredCroppings.length) {
        setCroppingsToShow(prevState => ({
          page: prevState.page + 1,
          data: prevState.data.concat(
            filteredCroppings.slice(
              (prevState.page) * (prevState.page === 1 ? settings.itemsToLoad : settings.itemsToAdd),
              (prevState.page + 1) * settings.itemsToAdd,
            ),
          ),
        }));
        setIsBottom(false);
      }
      else setIsBottom(false)
    }
  }, [isBottom, filteredCroppings, croppingsToShow.data.length, settings]);

  return (
    <Row className="mt-4" ref={listRef}>
      {croppingsToShow.data.length ? croppingsToShow.data.map((cropping, index) => (
        <Col key={`${cropping.croppingId}-${cropping.shortName}-${index}`} xs={12} md={6}>
          <CroppingShortcut data={cropping} />
        </Col>
      )) : (
        <Col xs={12}>
          <p className="font-weight-bold text-center mb-16"><FormattedMessage id="GENERAL.NO_RESULTS" /></p>
        </Col>
      )}
      {isBottom && (filteredCroppings.length !== 0 && croppingsToShow.data.length !== filteredCroppings.length) && (
        <Col xs={12} className="d-flex justify-content-center mb-16">
          <CircularProgress disableShrink />
        </Col>
      )}
    </Row>
  )
}
