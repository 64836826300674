/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { FormattedMessage, useIntl } from "react-intl";

export function ResultsToTariff({ className, chartColor = "primary", data }) {
  const uiService = useHtmlClassService();
  const intl = useIntl();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  const chartId = "brigadier-dashboard-resultsToTariff"

  const chartOptions = useMemo(() => {
    let seriesData = [];
    let xAxisData = [];
    if (data?.length > 0) data.forEach((tariff) => {
      seriesData.push(tariff.quantity);
      xAxisData.push(`${tariff.tariff.name} [${tariff.uom.uom.desc}]`);
    });
    const options = {
      series: [
        {
          name: intl.formatMessage({ id: "GENERAL.QUANTITY" }),
          data: seriesData,
        },
      ],
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [layoutProps.colorsThemeBaseColor],
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [layoutProps.colorsThemeBaseColor],
      },
      xaxis: {
        categories: xAxisData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
      },
      yaxis: {
        min: 0,
        labels: {
          show: true,
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
          formatter: function(val) {
            return val.toLocaleString(languageCode);
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
        y: {
          formatter: function(val) {
            return val.toLocaleString(languageCode);
          },
        },
      },
      colors: [layoutProps.colorsThemeLightColor],
      markers: {
        colors: [layoutProps.colorsThemeLightColor],
        strokeColor: [layoutProps.colorsThemeBaseColor],
        strokeWidth: 3,
      },
    };
    return options;
  }, [
    data,
    intl,
    languageCode,
    layoutProps.colorsGrayGray500,
    layoutProps.colorsThemeBaseColor,
    layoutProps.colorsThemeLightColor,
    layoutProps.fontFamily,
  ]);

  useEffect(() => {
    const element = document.getElementById(chartId);

    if (!element) {
      return;
    }

    const options = chartOptions;
    const chart = new ApexCharts(element, options);
    if (data?.length > 0) chart.render();
    return function cleanUp() {
      if (data?.length > 0) chart.destroy();
    };
  }, [chartOptions, data?.length, layoutProps]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className ? className : ""}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div
                className="font-weight-bolder text-dark"
              >
                <FormattedMessage id="BRIGADIER.DASHBOARD.RESULTS_TO_TARIFF" />
              </div>
            </div>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0 pt-0">
          {/* begin::Chart */}
          {data?.length > 0 && (
            <div
              id={chartId}
              data-color={chartColor}
              style={{ height: "400px" }}
              className="px-4"
            />
          )}
          {(!data || data?.length === 0) && (
            <div className="text-center mt-5">
              <h6>
                <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY" />
              </h6>
            </div>
          )}
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
