import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import GetLanguageId from "../../../shared/getLanguageId";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import {
  Form,
  Col,
  InputGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

export function WorkerForm({
  mode,
  formData,
  activeWorker,
  onCreatingWorker,
  onUpdatingWorker,
  onResendingEmail,
  openOrCloseModal,
}) {
  const intl = useIntl();
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    watch,
    clearError,
  } = useForm({ mode: "onChange" });
  const { touched, submitCount } = formState;
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [loading, setLoading] = useState(false);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [birthDate, setBirthDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [emailInputVisible, setEmailInputVisible] = useState(false);
  const [emailInputBlocked, setEmailInputBlocked] = useState(false);
  const [resendEmailBtnBlocked, setResendEmailBtnBlocked] = useState(false);
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === "create") {
      reset();
      setBirthDate(new Date());
      setFromDate(new Date());
      setToDate(new Date());
      setEmailInputVisible(false);
      setEmailInputBlocked(false);
    } else if (mode === "update" && activeWorker) {
      setFormParameters();
      setEmailInputBlocked(false);
    }
  }, [activeWorker, mode]);

  useEffect(() => {
    if (mode === "update" && activeWorker?.login?.email && emailInputVisible) {
      setValue("email", activeWorker.login.email);
      setEmailInputBlocked(true);
      clearError();
    }
  }, [mode, activeWorker, emailInputVisible]);

  const onChangingDate = (which, date) => {
    if (which === "from") {
      setFromDate(date);
      if (moment(date).isAfter(toDate)) {
        setToDate(date);
      }
    } else {
      setToDate(date);
      if (moment(date).isBefore(fromDate)) {
        setFromDate(date);
      }
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    mode === "create" ? createWorker(data) : updateWorker(data);
  };

  const setFormParameters = () => {
    setValue("firstName", activeWorker.firstName);
    setValue("lastName", activeWorker.lastName);
    setValue("login", activeWorker.login ? activeWorker.login.login : "");
    setValue("item", activeWorker.item);
    setValue("address2", activeWorker.address2);
    setValue("address1", activeWorker.address1);
    if (activeWorker.postalCode) {
      setValue("postalCode", activeWorker.postalCode.split("-")[0]);
      setValue("postalCode2", activeWorker.postalCode.split("-")[1]);
    } else {
      setValue("postalCode", null);
      setValue("postalCode2", null);
    }
    setBirthDate(new Date(activeWorker.birthDate));
    setValue("pesel", activeWorker.pesel);
    setValue("contactNumber", activeWorker.contactNumber);
    setValue("badge", activeWorker.badge);
    setValue("passport", activeWorker.passport);
    setFromDate(new Date(activeWorker.dateFrom));
    setToDate(new Date(activeWorker.dateTo));
    setValue("contractTypeId", activeWorker.contractType.id);
    setValue("workSiteId", activeWorker.workSite.id);
    if (activeWorker.workSite.id === 1 || activeWorker.workSite.id === 4) {
      setEmailInputVisible(false);
      setValue("email", "");
    } else {
      setEmailInputVisible(true);
      setTimeout(() => {
        if (activeWorker.login.email) {
          setValue("email", activeWorker.login.email, true);
          setEmailInputBlocked(true);
        } else setValue("email", "");
      });
    }
    setValue("isActive", activeWorker.isActive);
    setValue("comment", activeWorker.comment);
  };

  useEffect(() => {
    triggerValidation();
    clearError();
  }, [activeWorker, clearError, mode, triggerValidation])

  const onChangingJobPosition = (e) => {
    if (e.target.value === "1" || e.target.value === "4") {
      setEmailInputVisible(false);
    } else setEmailInputVisible(true);
  };

  async function createWorker(form) {
    const modifiedForm = createWorkerForm(form);
    const callback = await onCreatingWorker(modifiedForm);
    if (callback) {
      setLoading(false);
    }
  }

  async function updateWorker(form) {
    const modifiedForm = createWorkerForm(form);
    const callback = await onUpdatingWorker(activeWorker.id, modifiedForm);
    if (callback) {
      setLoading(false);
      if (modifiedForm.workSiteId !== "1" || modifiedForm.workSiteId !== "4")
        setEmailInputBlocked(false);
      else if (callback !== "error") setEmailInputBlocked(true);
    }
  }

  const createWorkerForm = (form) => {
    if (form.workSiteId === "1" || form.workSiteId === "4") {
      form.login = null;
    } else {
      form.login = {
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        privacyPolicyAccepted: true,
        languageId: GetLanguageId(
          formData.languages,
          JSON.parse(localStorage.getItem("i18nConfig")).selectedLang
        ).id,
        roles: [
          form.workSiteId === "2" ? "ROLE_BRIGADIER" : "ROLE_BRIGADIER_EXTEND",
        ],
      };
    }
    form.birthDate = birthDate;
    form.dateFrom = fromDate;
    form.dateTo = toDate;
    form.postalCode =
      form.postalCode && form.postalCode2
        ? `${form.postalCode}-${form.postalCode2}`
        : "";
    form.postalCode2 = null;
    return form;
  };

  const onGeneratingNewLabel = () => {
    openOrCloseModal();
  };

  async function handleResetEmailButtonClick() {
    setResendEmailBtnBlocked(true)
    const callback = await onResendingEmail(activeWorker.id)
    if (callback) setResendEmailBtnBlocked(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.FIRSTNAME" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="firstName"
              type="text"
              className={`${
                (touched?.firstName && getValues("firstName").length === 0) ||
                (submitCount >= 1 && getValues("firstName").length === 0) ||
                errors.firstName ||
                (touched?.firstName && getValues("firstName").length >= 51) ||
                (submitCount >= 1 && getValues("firstName")?.length >= 51)
                  ? "is-invalid"
                  : ""
              } ${
                !errors.firstName && getValues("firstName")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.FIRSTNAME",
              })}`}
              ref={register({ required: true, maxLength: 50 })}
            />
          </InputGroup>
          {(errors.firstName?.required ||
            (touched?.firstName && getValues("firstName").length === 0) ||
            (submitCount >= 1 && getValues("firstName")?.length === 0)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
          {(errors.firstName?.maxLength ||
            (touched?.firstName && getValues("firstName").length >= 51) ||
            (submitCount >= 1 && getValues("firstName")?.length >= 51)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_FIRSTNAME_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.LASTNAME" /> *
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="lastName"
              type="text"
              className={`${
                (touched?.lastName && getValues("lastName").length === 0) ||
                (submitCount >= 1 && getValues("lastName").length === 0) ||
                errors.lastName ||
                (touched?.lastName && getValues("lastName").length >= 51) ||
                (submitCount >= 1 && getValues("lastName")?.length >= 51)
                  ? "is-invalid"
                  : ""
              } ${
                !errors.lastName && getValues("lastName")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.LASTNAME",
              })}`}
              ref={register({ required: true, maxLength: 50 })}
            />
          </InputGroup>
          {(errors.lastName?.required ||
            (touched?.lastName && getValues("lastName").length === 0) ||
            (submitCount >= 1 && getValues("lastName")?.length === 0)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
            </div>
          )}
          {(errors.lastName?.maxLength ||
            (touched?.lastName && getValues("lastName").length >= 51) ||
            (submitCount >= 1 && getValues("lastName")?.length >= 51)) && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_LASTNAME_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="PLACEHOLDER.LOGIN" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="login"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.LOGIN",
              })}`}
              ref={register({ required: false })}
              disabled
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.LABEL" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="item"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "GENERAL.LABEL",
              })}`}
              ref={register({ required: false })}
              disabled
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.STREET_ADDRESS" />
          </Form.Label>
          <Form.Control
            name="address2"
            type="text"
            className={`${errors.address2 ? "is-invalid" : ""} ${
              !errors.address2 && getValues("address2")?.length >= 1
                ? "is-valid"
                : ""
            }`}
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.STREET_ADDRESS",
            })}`}
            ref={register({ required: false, maxLength: 50 })}
          />
          {errors.address2 && errors.address2.type === "maxLength" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_ADDRESS_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CITY" />
          </Form.Label>
          <Form.Control
            name="address1"
            type="text"
            className={`${errors.address1 ? "is-invalid" : ""} ${
              !errors.address1 && getValues("address1")?.length >= 1
                ? "is-valid"
                : ""
            }`}
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.CITY_NAME",
            })}`}
            ref={register({ required: false, maxLength: 58 })}
          />
          {errors.address1 && errors.address1.type === "maxLength" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INCORRECT_CITY_LENGTH" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Form.Label>
            <FormattedMessage id="GENERAL.POSTAL_CODE" />
          </Form.Label>
          <Form.Control
            name="postalCode"
            type="number"
            className={`${
              (errors.postalCode || errors.postalCode2) &&
              (getValues("postalCode")?.length >= 1 ||
                getValues("postalCode2")?.length >= 1)
                ? "is-invalid"
                : ""
            }
            ${
              !errors.postalCode &&
              !errors.postalCode2 &&
              getValues("postalCode")?.length >= 1 &&
              getValues("postalCode2")?.length >= 1
                ? "is-valid"
                : ""
            }`}
            placeholder="00"
            ref={register({
              required: false,
              maxLength: 2,
              minLength: 2,
            })}
          />
          {((errors.postalCode &&
            (errors.postalCode?.type === "minLength" ||
              errors.postalCode?.type === "maxLength")) ||
            (errors.postalCode2 && errors.postalCode2?.type === "minLength") ||
            errors.postalCode2?.type === "maxLength") && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INVALID_FORMAT" />
            </div>
          )}
        </Form.Group>
        <Col
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          -
        </Col>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <span style={{ visibility: "hidden" }}>.</span>
          </Form.Label>
          <Form.Control
            name="postalCode2"
            type="number"
            className={`${
              (errors.postalCode || errors.postalCode2) &&
              (getValues("postalCode")?.length >= 1 ||
                getValues("postalCode2")?.length >= 1)
                ? "is-invalid"
                : ""
            }
            ${
              !errors.postalCode &&
              !errors.postalCode2 &&
              getValues("postalCode")?.length >= 1 &&
              getValues("postalCode2")?.length >= 1
                ? "is-valid"
                : ""
            }`}
            placeholder="000"
            ref={register({
              required: false,
              maxLength: 3,
              minLength: 3,
            })}
          />
          {((errors.postalCode &&
            (errors.postalCode?.type === "minLength" ||
              errors.postalCode?.type === "maxLength")) ||
            (errors.postalCode2 && errors.postalCode2?.type === "minLength") ||
            errors.postalCode2?.type === "maxLength") && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.INVALID_FORMAT" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.BIRTHDAY" /> *
          </Form.Label>
          <DatePicker
            name="birthDate"
            selected={birthDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => setBirthDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PESEL" />
          </Form.Label>
          <Form.Control
            name="pesel"
            type="text"
            className={`${errors.pesel ? "is-invalid" : ""} ${
              !errors.pesel && getValues("pesel")?.length === 11
                ? "is-valid"
                : ""
            }`}
            placeholder="PESEL"
            ref={register({ required: false, pattern: /^[0-9]{11}$/ })}
          />
          {errors.pesel?.type === "pattern" && (
            <div className="error invalid-feedback">
              <FormattedMessage id="ERROR.INVALID_FORMAT" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CONTACT_NUMBER" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="contactNumber"
              type="number"
              className={`input--no_arrows ${
                errors.contactNumber ? "is-invalid" : ""
              } ${
                !errors.contactNumber && getValues("contactNumber")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.CONTACT_NUMBER",
              })}`}
              ref={register({ required: false, maxLength: 15 })}
            />
            {errors.contactNumber && errors.contactNumber.type === "maxLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_PHONE_NUMBER_LENGTH" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.INTERNAL_CODE" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="badge"
              type="text"
              className={`${errors.badge ? "is-invalid" : ""} ${
                !errors.badge && getValues("badge")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.INTERNAL_CODE",
              })}`}
              ref={register({ required: false, maxLength: 20 })}
            />
            {errors.badge && errors.badge.type === "maxLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_INTERNAL_CODE_LENGTH" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PASSPORT_NUMBER" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="passport"
              type="text"
              className={`${errors.passport ? "is-invalid" : ""} ${
                !errors.passport && getValues("passport")?.length >= 1
                  ? "is-valid"
                  : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.PASSPORT_NUMBER",
              })}`}
              ref={register({ required: false, maxLength: 9 })}
            />
            {errors.passport && errors.passport.type === "maxLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_PASSPORT_LENGTH" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CONTRACT_TYPE" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="contractTypeId"
            ref={register({ required: true })}
          >
            {formData && (
              <>
                {formData.contractTypes.map((contract) => (
                  <option key={`contract-${contract.id}`} value={contract.id}>
                    {contract.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.JOB_POSITION" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="workSiteId"
            ref={register({ required: true })}
            onChange={(e) => onChangingJobPosition(e)}
          >
            {formData && (
              <>
                {formData.workSites.map((position) => (
                  <option key={`position-${position.id}`} value={position.id}>
                    {position.name}
                  </option>
                ))}
              </>
            )}
          </Form.Control>
        </Form.Group>
        {emailInputVisible && (
          <>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.EMAIL" /> *
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  className={`${errors.email ? "is-invalid" : ""} ${
                    !errors.email &&
                    touched.email &&
                    getValues("email")?.length >= 3
                      ? "is-valid"
                      : ""
                  }`}
                  placeholder={`${intl.formatMessage({
                    id: "GENERAL.EMAIL",
                  })}`}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                    },
                  })}
                  disabled={emailInputBlocked}
                />
                {emailInputBlocked && (
                  <InputGroup.Append>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-tooltip">
                          <FormattedMessage id="GENERAL.RE_SEND_ACTIVATION" />
                        </Tooltip>
                      }
                    >
                      <Button type="button" variant="primary" onClick={handleResetEmailButtonClick} disabled={resendEmailBtnBlocked}>
                        <i className="far fa-envelope icon-nm pr-0"></i>
                      </Button>
                    </OverlayTrigger>
                  </InputGroup.Append>
                )}
              </InputGroup>
              {errors.email && errors.email.type === "required" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                </div>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <div
                  className="error invalid-feedback"
                  style={{ display: "block" }}
                >
                  <FormattedMessage id="ERROR.INCORRECT_EMAIL" />
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}></Form.Group>
          </>
        )}
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_FROM" /> *
          </Form.Label>
          <DatePicker
            name="fromDate"
            selected={fromDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("from", date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.DATE_TO" /> *
          </Form.Label>
          <DatePicker
            name="toDate"
            selected={toDate}
            dateFormat="dd.MM.yyyy"
            locale={datepickerLocale}
            showMonthDropdown
            showYearDropdown
            onChange={(date) => onChangingDate("to", date)}
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.STATUS" /> *
          </Form.Label>
          <Form.Control
            as="select"
            name="isActive"
            ref={register({ required: true })}
          >
            {status.map((stat) => (
              <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                {(message) => <option value={stat.value}>{message}</option>}
              </FormattedMessage>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.COMMENT" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="comment"
              as="textarea"
              rows={5}
              type="text"
              className={`${errors.comment ? "is-invalid" : ""}`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.COMMENT",
              })}`}
              maxLength={150}
              ref={register({ required: false, maxLength: 150 })}
            />
            <div className="w-100 text-right small mt-1">
              {watch("comment")?.length} / 150
            </div>
            {errors.comment && errors.comment.type === "maxLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_COMMENT_LENGTH" />
              </div>
            )}
          </InputGroup>
        </Form.Group>
        <Col md={12} className="text-right">
          {mode === "update" && (
            <Button
              className="mr-2"
              onClick={() => onGeneratingNewLabel()}
              disabled={true}
            /*  disabled={loading}*/
            >
              <FormattedMessage id="GENERAL.GENERATE_NEW_LABEL" />
            </Button>
          )}
          <Button
            type="submit"
            disabled={loading || Object.keys(errors).length !== 0}
          >
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}
