import * as React from "react";
import {
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  ButtonGroup,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import displayFeedback from "../../../../components/helpers/displayFeedback";
import errorHandler from "../../../../shared/errorHandler";
import { useForm } from "react-hook-form";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { updateCropViewImageComment } from "../../../../services/cropViewCrud";

export default function CropViewImagePreview() {
  const intl = useIntl();

  const dispatch = useDispatch();

  const [fetching, setFetching] = React.useState(false);
  const [commentSending, setCommentSending] = React.useState(false);

  const { register, errors, handleSubmit, setValue, watch } = useForm({
    mode: "onChange",
  });

  const selectedImage = useSelector(
    (state) => state.cropViewModal.selectedImage
  );

  React.useEffect(() => {
    if (selectedImage.comment) setValue("comment", selectedImage.comment);
  }, [selectedImage.comment, setValue]);

  const close = React.useCallback(() => {
    dispatch({
      type: "cropViewModal/clearSelectedImage",
    });
  }, [dispatch]);

  const download = (url, name) => {
    if (!url) {
      displayFeedback({
        type: "error",
        message: `${intl.formatMessage({
          id: "FEEDBACK.RESPOND_TAKING_TO_LONG",
        })}`,
      });
    }
    if (url) {
      setFetching(true);
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          if (name && name.length) a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          setFetching(false);
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    }
  };

  const onSubmit = (data) => {
    setCommentSending(true);
    updateCropViewImageComment(selectedImage.imageId, data.comment)
      .then((response) => {
        setCommentSending(false);
        displayFeedback({
          type: "success",
          message: `${intl.formatMessage({ id: "CROP_VIEW.COMMENT_SAVED" })}`,
        });
        dispatch({
          type: "cropViewModal/updateImageComment",
          payload: {
            imageId: response.data.imageId,
            comment: response.data.comment,
          },
        });
      })
      .catch((error) => {
        setCommentSending(false);
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  return (
    <Row>
      <Col md={4} className="mb-8 mb-md-0">
        <div>
          <Button onClick={() => close()} className="mr-3">
            <i className="fa fa-arrow-left mr-2" />
            <FormattedMessage id="CROP_VIEW.BACK_TO_GALLERY" />
          </Button>
          <Button
            onClick={() =>
              download(selectedImage.path, `${selectedImage.name}.jpg`)
            }
            disabled={fetching}
          >
            <FormattedMessage id="GENERAL.DOWNLOAD" />
          </Button>
        </div>
        <div className="mt-8">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group className="w-100">
                <Form.Label>
                  <FormattedMessage id="GENERAL.COMMENT" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="comment"
                    as="textarea"
                    rows={5}
                    type="text"
                    className={`${errors.comment ? "is-invalid" : ""}`}
                    placeholder={`${intl.formatMessage({
                      id: "GENERAL.COMMENT",
                    })}`}
                    maxLength={150}
                    ref={register({ required: false, maxLength: 150 })}
                  />
                  <div className="w-100 text-right small mt-1">
                    {watch("comment")?.length ? watch("comment")?.length : 0} / 150
                  </div>
                  {errors.comment && errors.comment.type === "maxLength" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.INCORRECT_COMMENT_LENGTH" />
                    </div>
                  )}
                </InputGroup>
              </Form.Group>
              <div className="text-right w-100">
                <Button type="submit" disabled={commentSending}>
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
              </div>
            </Form.Row>
          </Form>
        </div>
      </Col>
      <Col md={8}>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent>
                <img
                  src={selectedImage.path}
                  alt=""
                  className="d-block w-100"
                />
              </TransformComponent>
              <div className="position-absolute bottom-0 right-0 mr-6 mb-4">
                <ButtonGroup vertical>
                  <Button onClick={() => zoomIn()} variant="light">
                    <i className="fa fa-plus pr-0" />
                  </Button>
                  <hr className="crop-view__preview-control-btn-separator" />
                  <Button onClick={() => zoomOut()} variant="light">
                    <i className="fa fa-minus pr-0" />
                  </Button>
                  <hr className="crop-view__preview-control-btn-separator" />
                  <Button onClick={() => resetTransform()} variant="light">
                    <i className="fa fa-redo pr-0" />
                  </Button>
                </ButtonGroup>
              </div>
            </>
          )}
        </TransformWrapper>
      </Col>
    </Row>
  );
}
