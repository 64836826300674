import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import { getCroppingAndFieldToOrgentity } from "../../../services/crop-manager/farmSharingCrud";
import { DashboardFilter } from "../components/DashboardFilter";
import { DashboardMap } from "../components/DashboardMap";
import { Row, Col, Button } from "react-bootstrap";
import displayFeedback from "../../../components/helpers/displayFeedback";
import { useDispatch, useSelector } from "react-redux";

const CropManagerDashboard = () => {
  const intl = useIntl();
  const history = useHistory();
  const [allCroppings, setAllCroppings] = useState([]);
  const [filteredCroppings, setFilteredCroppings] = useState([]);
  const [showShapesAsMarkers, setShowShapesAsMarkers] = useState(false);
  const [selectedCroppings, setSelectedCroppings] = useState([]);
  const [showOnlyEcoCroppings, setShowOnlyEcoCroppings] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [initialFilterValue, setInitialFilterValue] = useState("");
  const [shapesDownloaded, setShapesDownloaded] = useState(false);
  const sessionStorageFilterKey = "CROPCHART.DASHBOARD.FILTER_VALUE";

  useEffect(() => filterChanged(searchValue), [showOnlyEcoCroppings]);
  useEffect(() => {
    if (allCroppings.length) {
      restoreFilterValueFromSessionStorage();
    }
  }, [allCroppings]);

  const getPartnersFieldsAndCroppings = (from, to) => {
    const fromDate = from
      ? moment(from).format("YYYY-MM-DD")
      : moment("2001-01-01").format("YYYY-MM-DD");
    const toDate = to
      ? moment(to).format("YYYY-MM-DD")
      : moment("2099-12-30").format("YYYY-MM-DD");

    getCroppingAndFieldToOrgentity(fromDate, toDate)
      .then((response) => {
        const shapesWithoutEmptyCoords = [
          ...response.data.croppings,
          ...response.data.fields,
        ].filter((cropping) => {
          if (cropping.shape) {
            return cropping.shape.children.length > 0;
          } else if (cropping.point) {
            return cropping.point.point.coordinates.length > 0;
          } else {
            return false;
          }
        });

        setShapesDownloaded(true);
        setAllCroppings(shapesWithoutEmptyCoords);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        setShapesDownloaded(true);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const filterCroppingsToShowOnMap = (bPartnerId) => {
    setSelectedCroppings(
      filteredCroppings.filter(
        (crop) => crop.bpartner.bpartnerId === bPartnerId
      )
    );
  };

  const onTogglingEcoCroppings = () => {
    setShowOnlyEcoCroppings(!showOnlyEcoCroppings);
  };

  const setShapesAsMarkers = () => {
    setShowShapesAsMarkers(!showShapesAsMarkers);
  };

  const filterChanged = (value) => {
    if (!value.length) {
      setFilteredCroppings([]);
      setSearchValue("");
      setSelectedCroppings([]);
      return;
    }

    setSearchValue(value);

    const filtered = allCroppings.filter((row) => {
      if (showOnlyEcoCroppings) {
        return croppingContainsSearchedValue(row, value) && row.isEco;
      }

      return croppingContainsSearchedValue(row, value);
    });

    setFilteredCroppings(filtered);
    setSelectedCroppings(filtered);

    if (!filtered.length) {
      setSelectedCroppings([]);
    }
  };

  const croppingContainsSearchedValue = (row, search = "") => {
    search = search.toString().toLowerCase();
    return (
      (row.shortName && row.shortName.toLowerCase().includes(search)) ||
      (row.desc && row.desc.toLowerCase().includes(search)) ||
      filterByVariety(row, search) ||
      filterByBPartner(row, search)
    );
  };

  const filterByVariety = (row, search = "") => {
    if (!row) {
      return false;
    }

    return (
      row.variety &&
      ((row.variety.desc && row.variety.desc.toLowerCase().includes(search)) ||
        (row.variety.speciesDesc &&
          row.variety.speciesDesc.toLowerCase().includes(search)))
    );
  };

  const filterByBPartner = (row, search = "") => {
    if (!row) {
      return false;
    }

    return (
      row.bpartner &&
      ((row.bpartner.bpartnerFullName &&
        row.bpartner.bpartnerFullName.toLowerCase().includes(search)) ||
        (row.bpartner.bpartnerShortName &&
          row.bpartner.bpartnerShortName.toLowerCase().includes(search)) ||
        (row.bpartner.number &&
          row.bpartner.number.toLowerCase().includes(search)))
    );
  };

  const redirectToCropDetails = (shapeId, type) => {
    window.sessionStorage.setItem(sessionStorageFilterKey, searchValue);
    history.push("/crop-manager/shared-farmland/" + type + "/" + shapeId);
  };

  const restoreFilterValueFromSessionStorage = () => {
    const filterValue = window.sessionStorage.getItem(sessionStorageFilterKey);
    if (
      filterValue !== undefined &&
      filterValue !== null &&
      filterValue.toString().length
    ) {
      setInitialFilterValue(filterValue);
      setTimeout(() => {
        filterChanged(filterValue);
        window.sessionStorage.removeItem(sessionStorageFilterKey);
      });
    }
  };

  useEffectOnce(getPartnersFieldsAndCroppings);

  const dispatch = useDispatch();
  const showFilters = useSelector((state) => state.cmDashboard.showFilters);
  const isMobile = useSelector((state) => state.misc.isMobile);
  const setFiltersOpen = useCallback(
    (payload) => {
      dispatch({
        type: "cmDashboard/setShowFilters",
        payload,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isMobile) setFiltersOpen(true);
    else setFiltersOpen(false);
  }, [isMobile, setFiltersOpen]);

  return (
    <>
      {shapesDownloaded && (
        <DashboardMap
          croppings={selectedCroppings}
          allCroppings={allCroppings}
          redirectToCropDetails={redirectToCropDetails}
        />
      )}
      <Row className="maps-menu__container">
        {!showFilters && (
          <Button
            onClick={() => setFiltersOpen(true)}
            variant="light"
            className="position-relative mt-22 mt-sm-9 mt-lg-n4 ml-3"
            data-testid="display-filters"
          >
            <i className="fa fa-filter" />
            <FormattedMessage id="GENERAL.FILTERS" />
          </Button>
        )}
        <Col md={12} lg={4} xxl={3} className="to-parent-height">
          <DashboardFilter
            initialFilterValue={initialFilterValue}
            croppings={filteredCroppings}
            showShapesAsMarkers={showShapesAsMarkers}
            showOnlyEcoCroppings={showOnlyEcoCroppings}
            onTogglingEcoCroppings={onTogglingEcoCroppings}
            filterFieldsAndCroppings={filterChanged}
            getPartnersFieldsAndCroppings={getPartnersFieldsAndCroppings}
            centerOnMap={filterCroppingsToShowOnMap}
            setShapesAsMarkers={setShapesAsMarkers}
          />
        </Col>
      </Row>
    </>
  );
};

export default CropManagerDashboard;
