import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { OffersFilter } from "./OffersFilter";
import { OffersSaleOffersTable } from "./OffersSaleOffersTable";
import { OffersContractorOffersTable } from "./OffersContractorOffersTable";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export function OffersOffers(props) {
  const [key, setKey] = useState("saleOffers");

  return (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="saleOffers"
            title={<FormattedMessage id="PE.SALE_OFFERS" />}
          >
            {props.saleOffers.length > 0 ? (
              <>
                {props.applicationStatus === "A" && key === "saleOffers" && (
                  <div>
                    <OffersFilter
                      getFilteredSaleOffers={props.getFilteredSaleOffers}
                      refresh={props.refresh}
                    />
                  </div>
                )}
                <OffersSaleOffersTable
                  offers={props.saleOffers}
                  applicationStatus={props.applicationStatus}
                  onSelectingOffer={props.onSelectingGrowerOffer}
                />
              </>
            ) : (
              <div className="text-center mt-5">
                <h6>
                  <FormattedMessage id="PE.NO_OFFERS" />
                </h6>
              </div>
            )}
          </Tab>
          <Tab
            eventKey="purchaseOffers"
            title={<FormattedMessage id="PE.PURCHASE_OFFERS" />}
            disabled={
              props.applicationStatus !== "A" || props?.buyOffers.length === 0
            }
          >
            <OffersContractorOffersTable
              offers={props.buyOffers}
              onSelectingOffer={props.onSelectingContractorOffer}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
