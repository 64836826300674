import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "react-bootstrap";

export function PartnersTable(props) {
  const { partners, activePartner, onSelectingPartner } = props;

  return (
    <Table striped bordered hover responsive size="sm">
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.FARM_NAME" />
          </th>
        </tr>
      </thead>
      <tbody>
        {partners.map((businessPartner) => (
          <tr
            key={`businessPartner-${businessPartner.bpartnerId}`}
            className={`text-center ${
              businessPartner.bpartnerId === activePartner?.bpartnerId
                ? "offer--active"
                : ""
            }`}
            onClick={() => onSelectingPartner(businessPartner.bpartnerId)}
          >
            <td>{businessPartner.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
