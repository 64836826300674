const initialParametersState = null;

export const reducer = (state = initialParametersState, action) => {
  switch (action.type) {
    case "brigadier/settingsParameters/set": {
      return action.payload;
    }

    default:
      return state;
  }
};
