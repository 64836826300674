import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm, useFieldArray } from "react-hook-form";
import moment from "moment";
import { Dropzone } from "../../../components/Dropzone";
import {
  Form,
  Row,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { DeleteDocumentModal } from "./DeleteDocumentModal";

export function DocumentForm(props) {
  const {
    mode,
    document,
    businessPartners,
    handleDocumentCreate,
    handleDocumentSend,
    handleDocumentsSend,
    handleDocumentDelete,
  } = props;
  const intl = useIntl();
  const {
    control,
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
  } = useForm({ mode: "onChange" });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });
  const { touched, submitCount } = formState;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [partnersIds, setPartnersIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setFormParameters = () => {
    setValue("title", document.title);
    setValue("createAt", moment(document.createAt).format("YYYY-MM-DD HH:mm"));
    setValue("message", document.message);
    triggerValidation();
  };

  const onAddingRecipient = () => {
    const selectedPartnerId = getValues("recipient");
    if (partnersIds.includes(selectedPartnerId)) return;
    const newPartnersIds = [...partnersIds];
    newPartnersIds.push(selectedPartnerId);
    setPartnersIds(newPartnersIds);
    const selectedPartner = businessPartners.find((partner) => {
      return partner.bpartnerId === parseInt(selectedPartnerId);
    });
    append({
      id: selectedPartner.bpartnerId,
      value: selectedPartner.bpartnerFullName,
    });
  };

  const onRemovingField = (index, field) => {
    const newPartnersIds = partnersIds.filter((id) => {
      return parseInt(id) !== field.id;
    });
    setPartnersIds(newPartnersIds);
    remove(index);
  };

  const onAddingFile = (newFile) => {
    const reader = new FileReader();
    reader.readAsText(newFile, ["UTF-8"]);
    setFile(newFile);
  };

  useEffect(() => {
    if (!document || mode === "create") {
      reset();
      setFile(null);
      setPartnersIds([]);
    }
    (document || mode !== "create") && setFormParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, mode]);

  async function onSubmit(data) {
    setLoading(true);
    let formData = new FormData();
    if (file === null || file === undefined || !partnersIds.length) {
      setLoading(false);
      return;
    }
    formData.append("title", data.title);
    formData.append("message", data.message);
    formData.append("bpartnerIds", partnersIds);
    formData.append("file", file);
    const callback = await handleDocumentCreate(formData);
    callback && setLoading(false);
  }

  async function handleSendFileToPartner(id) {
    setLoading(true);
    const callback = await handleDocumentSend(id);
    callback && setLoading(false);
  }

  async function handleSendFile() {
    setLoading(true);
    const callback = await handleDocumentsSend(document.id);
    callback && setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    const callback = await handleDocumentDelete(document.id);
    callback && setLoading(false);
  }

  const onOpeningOrClosingModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <DeleteDocumentModal
        open={isModalOpen}
        close={onOpeningOrClosingModal}
        handleDocumentDelete={() => handleDelete()}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.TITLE" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="title"
                type="string"
                className={`${
                  (touched?.title && getValues("title").length === 0) ||
                  (submitCount >= 1 && getValues("title")?.length === 0) ||
                  errors.title ||
                  (touched?.title && getValues("title").length >= 51) ||
                  (submitCount >= 1 && getValues("title")?.length >= 51)
                    ? "is-invalid"
                    : ""
                } ${
                  !errors.title && getValues("title")?.length >= 1
                    ? "is-valid"
                    : ""
                }`}
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.MAIL_TITLE",
                })}`}
                ref={register({ required: true, maxLength: 50 })}
                disabled={mode === "preview" ? true : false}
              />
            </InputGroup>
            {(errors.title?.required ||
              (touched?.title && getValues("title").length === 0) ||
              (submitCount >= 1 && getValues("title")?.length === 0)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {(errors.title?.maxLength ||
              (touched?.title && getValues("title").length >= 51) ||
              (submitCount >= 1 && getValues("title")?.length >= 51)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.MAXIMUM_NUMBER_OF_CHARACTERS_50" />
              </div>
            )}
          </Form.Group>
          <Form.Group as={Col} md={6}>
            {mode === "preview" && (
              <>
                <Form.Label>
                  <FormattedMessage id="GENERAL.POST_DATE" />
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="createAt"
                    type="string"
                    ref={register({ required: false })}
                    disabled
                  />
                </InputGroup>
              </>
            )}
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.ATTACHMENT" /> *
            </Form.Label>
            {mode === "preview" ? (
              <Col xs={12}>
                <span className="text--bold">{document.name}</span>
              </Col>
            ) : (
              <Dropzone
                disabled={loading}
                file={file}
                onAddingFile={onAddingFile}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label style={{ width: "100%" }}>
              <FormattedMessage id="GENERAL.RECIPIENTS" /> *
              {mode === "preview" ? (
                <Row>
                  {document.recipientList.map((recipient) => (
                    <Col
                      key={`recipient-${recipient.fileSharingId}`}
                      xs={6}
                      className="mb-1 d-flex align-items-center justify-content-between"
                    >
                      <span className="text--bold">
                        {recipient.bpartner.bpartnerFullName}
                      </span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="user-tooltip">
                            <FormattedMessage id="GENERAL.SEND_DOCUMENT" />
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="primary"
                          type="button"
                          onClick={() =>
                            handleSendFileToPartner(recipient.fileSharingId)
                          }
                        >
                          <i className="fa fa-at icon-nm pr-0"></i>
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  ))}
                </Row>
              ) : (
                <InputGroup>
                  <Form.Control
                    as="select"
                    name="recipient"
                    ref={register({ required: true })}
                  >
                    {businessPartners.map((partner) => (
                      <option
                        key={`partner-${partner.bpartnerId}`}
                        value={partner.bpartnerId}
                      >
                        {partner.bpartnerFullName}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-tooltip">
                          <FormattedMessage id="GENERAL.ADD_RECIPIENT" />
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="primary"
                        type="button"
                        onClick={onAddingRecipient}
                      >
                        <i className="fas fa-plus icon-nm pr-0"></i>
                      </Button>
                    </OverlayTrigger>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Form.Label>
          </Form.Group>
          {mode === "create" && (
            <>
              {fields.map((field, index) => (
                <Form.Group key={field.id} as={Col} xs={6}>
                  <InputGroup>
                    <Form.Control
                      type="recipient"
                      name={`recipients[${index}]`}
                      ref={register({})}
                      defaultValue={field.value}
                      disabled
                    />
                    <InputGroup.Append>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="user-tooltip">
                            <FormattedMessage id="GENERAL.REMOVE_RECIPIENT" />
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          type="button"
                          onClick={() => onRemovingField(index, field)}
                        >
                          <i className="fa fa-trash icon-nm pr-0"></i>
                        </Button>
                      </OverlayTrigger>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              ))}
            </>
          )}
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.MESSAGE" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="message"
                as="textarea"
                rows="3"
                className={`${
                  (touched?.message && getValues("message").length === 0) ||
                  (submitCount >= 1 && getValues("message")?.length === 0) ||
                  errors.message ||
                  (touched?.message && getValues("message").length >= 256) ||
                  (submitCount >= 1 && getValues("message")?.length >= 256)
                    ? "is-invalid"
                    : ""
                } ${
                  !errors.message && getValues("message")?.length >= 1
                    ? "is-valid"
                    : ""
                }`}
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.MAIL_MESSAGE",
                })}`}
                ref={register({ required: true, maxLength: 255 })}
                disabled={mode === "preview" ? true : false}
              />
            </InputGroup>
            {(errors.message?.required ||
              (touched?.message && getValues("message").length === 0) ||
              (submitCount >= 1 && getValues("message")?.length === 0)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {(errors.message?.maxLength ||
              (touched?.message && getValues("message").length >= 256) ||
              (submitCount >= 1 && getValues("message")?.length >= 256)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.MAXIMUM_NUMBER_OF_CHARACTERS_255" />
              </div>
            )}
          </Form.Group>
          <Col md={12} className="text-right">
            {mode === "create" && (
              <Button
                type="submit"
                disabled={loading || Object.keys(errors).length !== 0}
              >
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            )}
            {mode === "preview" && (
              <>
                <Button
                  className="mr-1"
                  type="button"
                  disabled={loading}
                  onClick={handleSendFile}
                >
                  <FormattedMessage id="GENERAL.SEND_AGAIN" />
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  disabled={loading}
                  onClick={() => setIsModalOpen(true)}
                >
                  <FormattedMessage id="GENERAL.DELETE" />
                </Button>
              </>
            )}
          </Col>
        </Form.Row>
      </Form>
    </>
  );
}
