import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ShareReportForm } from "../components/ShareReportForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export function ShareReportModal({ open, close, onSendingReport }) {
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [form, setForm] = useState();

  const onClosingModal = () => {
    setIsValid(false);
    setForm();
    close();
  };

  const onFormChange = (errors, form) => {
    let emails = [];
    for (const [key, value] of Object.entries(form)) {
      emails.push(value);
    }
    setForm(emails);
    if (Object.keys(errors).length === 0) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  async function onSendingForm() {
    setLoading(true);
    const callback = await onSendingReport(form);
    if (callback === "OK") {
      setIsValid(false);
      setForm();
      setLoading(false);
      close();
    } else {
      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.SHARE_REPORT" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="GENERAL.SHARE_REPORT_MESSAGE" />.
        </DialogContentText>
        <ShareReportForm onFormChange={onFormChange} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          type="submit"
          onClick={onSendingForm}
          disabled={loading || !isValid}
        >
          <FormattedMessage id="GENERAL.SEND" />
        </Button>
        <Button disabled={loading} onClick={onClosingModal}>
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
