import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";

export const actionTypes = {
  OrganizationSet: "[Organization Set] Action",
  UserDataSet: "[User Data Set] Action",
  UserDataRefresh: "[User Data Subscription Refresh] Action",
};

const initialUiState = {
  organization: undefined,
  userData: undefined,
};

export const reducer = persistReducer(
  { storage, key: "ui", whitelist: ["organization", "userData"] },
  (state = initialUiState, action) => {
    switch (action.type) {
      case actionTypes.OrganizationSet: {
        const { organization } = action.payload;

        return { organization: organization, userData: undefined };
      }

      case actionTypes.UserDataSet: {
        const { userData } = action.payload;

        return { ...state, userData: userData };
      }

      case actionTypes.UserDataRefresh: {
        const { userData } = action.payload;
        return {
          ...state,
          userData: {
            ...state.userData,
            subscriptions: userData.subscriptions,
            _subscriptions: userData._subscriptions,
            orgentities: userData.orgentities,
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  organizationSet: (organization) => ({
    type: actionTypes.OrganizationSet,
    payload: { organization },
  }),
  userDataSet: (userData) => ({
    type: actionTypes.UserDataSet,
    payload: { userData },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.OrganizationSet, function* organizationSet() {});

  yield takeLatest(actionTypes.UserDataSet, function* userDataSet() {});
}
