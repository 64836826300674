import React, { useState, useMemo, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { addDefaultRateToEmployee } from "../../../../services/brigadier/workersCrud";
import errorHandler from "../../../../shared/errorHandler";
import displayFeedback from "../../../../components/helpers/displayFeedback";
import { useForm } from "react-hook-form";
import { ButtonGroup } from "react-bootstrap";
import displayFeedbackFromHeaders from "../../../../components/helpers/displayFeedbackFromHeaders";

export function NewTariffCustomRateForm({ formData, workerId }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    control,
    watch,
  } = useForm({ mode: "onChange" });
  const value = watch("tariffCustomRate_tariffGroup_value");

  const tariffGroups = useSelector(
    (state) => state.brigadierCustomTariffRate.tariffGroups
  );

  const defaultRates = useSelector(
    (state) => state.brigadierCustomTariffRate.workerDefaultRates?.tariffGroups
  );

  const [selectedTariffGroup, setSelectedTariffGroup] = useState();

  const availableTariffGroups = useMemo(() => {
    if (tariffGroups?.length > 0)
      return tariffGroups.map((tariffGroup, index) => ({
        ...tariffGroup,
        value: tariffGroup.tariffGroupId,
        label: tariffGroup.name,
        isDisabled: defaultRates?.find(
          (group) => group.tariffGroupId === tariffGroup.tariffGroupId
        ),
      }));
  }, [defaultRates, tariffGroups]);

  async function handleAddDefaultRate() {
    setLoading(true);
    if (selectedTariffGroup) {
      const form = {
        tariffGroupId: selectedTariffGroup.tariffGroupId,
        value: 1,
      };
      addDefaultRateToEmployee(workerId, form)
        .then((response) => {
          displayFeedbackFromHeaders(response.headers);
          dispatch({
            type: "brigadier/customTariffRates/addWorkerDefaultTariffGroupRate",
            payload: {
              data: response.data,
            },
          });
          setSelectedTariffGroup(null);
          setLoading(false);
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({
                id: errorOptions.message,
              })}`,
            });
          }
          setLoading(false);
        });
    }
  }

  const selectedTariffGroupDetails = useMemo(() => {
    if (
      selectedTariffGroup &&
      selectedTariffGroup?.tariffList?.length > 0 &&
      formData
    )
      return {
        tariffTypeValue: selectedTariffGroup.tariffList[0].tariffTypeValue,
        uomName: formData.tariffUom.find(
          (uom) => uom.id === selectedTariffGroup.tariffList[0].uomId
        )?.desc,
        currencyName: formData.currencies.find(
          (currency) =>
            currency.id === selectedTariffGroup.tariffList[0].currencyId
        )?.name,
      };
    else return undefined;
  }, [formData, selectedTariffGroup]);

  const openGroupEditModal = () => {
    dispatch({
      type: "brigadier/customTariffRates/setGroupEditModalOpen",
      payload: {
        groupEditModalOpen: true,
      },
    });
  };

  return (
    <Form className="mb-8">
      <Form.Row>
        <Col xs={12} className="mb-4">
          <div className="d-flex align-items-center">
            <h6 className="mr-4 my-0">
              <FormattedMessage id="BRIGADIER.ADD_INDIVIDUAL_TARIFF_RATE" />
            </h6>
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-flex flex-column flex-sm-row">
            <Select
              options={availableTariffGroups}
              value={selectedTariffGroup}
              onChange={setSelectedTariffGroup}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.SELECT_TARIFF_GROUP",
              })}...`}
              isClearable
              className="w-100 mr-4"
            />
            <ButtonGroup className="flex-shrink-0 mt-4 mt-sm-0">
              <Button variant="secondary" onClick={openGroupEditModal}>
                <FormattedMessage id="GENERAL.EDIT_GROUPS" />
              </Button>
              <Button
                onClick={handleAddDefaultRate}
                disabled={!selectedTariffGroup || loading}
              >
                <FormattedMessage id="GENERAL.ADD" />
              </Button>
            </ButtonGroup>
          </div>
        </Col>
        {selectedTariffGroupDetails && (
          <Col xs={12} className="mt-4">
            <div className="d-flex">
              <p className="mr-4 mb-0">
                <FormattedMessage id="GENERAL.TARIFF_TYPE" />
                {": "}
                <FormattedMessage
                  id={`GENERAL.${selectedTariffGroupDetails.tariffTypeValue}`}
                />
              </p>
              <p className="mr-4 mb-0">
                <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" />
                {": "}
                {selectedTariffGroupDetails.uomName}
              </p>
              <p className="mr-4 mb-0">
                <FormattedMessage id="GENERAL.CURRENCY" />
                {": "}
                {selectedTariffGroupDetails.currencyName}
              </p>
            </div>
          </Col>
        )}
      </Form.Row>
    </Form>
  );
}
