import axios from "axios";

export const PARTNER_NUMBER_URL = `${process.env.REACT_APP_URL}cropmanager/bpartnernumber`;

export function getBPartnerNumbersAll() {
  return axios.get(`${PARTNER_NUMBER_URL}/bpartners`);
}

export function getBPartnerNumberById(id) {
  return axios.get(`${PARTNER_NUMBER_URL}/${id}`);
}

export function updateBPartnerNumber(form) {
  return axios.put(`${PARTNER_NUMBER_URL}/update`, form);
}
