import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PolicyModal from "../modals/PolicyModal";
import TermsModal from "../modals/TermsModal";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { signIn } from "../_redux/authCrud";
import errorHandler from "../../../shared/errorHandler";
import SVG from "react-inlinesvg";
import InfoTooltip from "../components/InfoTooltip";
import displayFeedbackFromHeaders from "../../../components/helpers/displayFeedbackFromHeaders";
import displayFeedback from "../../../components/helpers/displayFeedback";

function Login(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [openModalPolicy, setOpenModalPolicy] = useState(false);
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    const loginWithoutWhiteSpaces = data.login.replace(/\s/g, "");
    data.login = loginWithoutWhiteSpaces;
    data.rememberMe = rememberMe;
    setLoading(true);
    signIn(data)
      .then((response) => {
        setLoading(false);
        const user = {
          canLogin: response.data.canLogin,
          email: response.data.email,
          id: response.data.id,
          isAnonymous: response.data.isAnonymous,
          login: response.data.login,
          permissions: response.data.permissions,
          person: response.data.person,
          subscriptions: response.data.subscriptions,
          applications: response.data.applications,
          farms: response.data.bpartners,
          showTutorials: response.data.showTutorials,
          showOnboardings: response.data.showOnboardings,
        };
        localStorage.setItem(
          "i18nConfig",
          JSON.stringify({ selectedLang: response.data.languageCode || "en" })
        );
        localStorage.setItem(
          "activeFarmId",
          response.data.bpartners[0].bpartnerId
        );
        props.login(response.data.accessToken, user);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
          setLoading(false);
        }
      });
  };

  const handleChange = () => setRememberMe(!rememberMe);

  const handleClickTerm = () => setOpenModalTerms(!openModalTerms);

  const handleClickPolicy = () => setOpenModalPolicy(!openModalPolicy);

  return (
    <div className="auth__form">
      <TermsModal
        open={openModalTerms}
        close={handleClickTerm}
        acceptVisible={false}
      />
      <PolicyModal
        open={openModalPolicy}
        close={handleClickPolicy}
        acceptVisible={false}
      />

      <div className="auth__welcoming">
        <h1>
          <FormattedMessage id="AUTH.WELCOME" />!
        </h1>
      </div>
      <div className="auth__logo d-flex flex-column justify-content-center align-items-center px-lg-4 pt-3 pb-8 py-lg-6">
        <SVG src={toAbsoluteUrl("/media/logos/cropchart__logo.svg")} />
        <SVG
          src={toAbsoluteUrl("/media/logos/brigadier__logo.svg")}
          style={{ height: "25px" }}
          className="mt-2"
        />
        <div className="d-flex mt-4 align-items-center">
          <p className="m-0">
            <FormattedMessage id="AUTH.SIGN_IN_MULTI-ACCOUNT" />
          </p>
          <InfoTooltip />
        </div>
      </div>
      <form
        className="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group">
          <div>
            <input
              name="login"
              type="text"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.LOGIN",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.login && errors.login ? "is-invalid" : ""
              }${formState.touched.login && !errors.login ? "is-valid" : ""}`}
              ref={register({
                required: true,
                minLength: 7,
              })}
            />
            {errors.login?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.login?.type === "minLength" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_LOGIN_LENGTH" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <div>
            <input
              name="password"
              type="password"
              placeholder={`${intl.formatMessage({
                id: "PLACEHOLDER.ENTER_PASSWORD",
              })}`}
              className={`form-control form-control-solid h-auto py-5 px-6 ${
                formState.touched.password && errors.password
                  ? "is-invalid"
                  : ""
              }${
                formState.touched.password && !errors.password ? "is-valid" : ""
              }`}
              ref={register({ required: true, minLength: 8 })}
            />
            {errors.password?.type === "required" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.password?.type === "minLength" && (
              <div className="error invalid-feedback">
                <FormattedMessage id="ERROR.INCORRECT_PASSWORD_LENGTH" />
              </div>
            )}
          </div>
        </div>
        <div className="form-group auth_links flex-wrap">
          <FormControlLabel
            className={`checkbox__primary ${rememberMe ? "checkbox--active " : ""}`}
            control={
              <Checkbox
                checked={rememberMe}
                onChange={() => handleChange()}
                value={rememberMe}
              />
            }
            label={`${intl.formatMessage({
              id: "PLACEHOLDER.REMEMBER_ME",
            })}`}
          />
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.FORGOT_PASSWORD" />?
          </Link>
        </div>
        <div
          className="form-group text-center button_bar button_bar--center flex-wrap"
          style={{ marginBottom: "4px" }}
        >
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formState.isValid || loading}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 button_auth button--auth_background_primary`}
          >
            <span>
              <FormattedMessage id="AUTH.LOG_IN" />
            </span>
          </button>
        </div>
        <div className="text-center mb-10 mb-lg-10">
          <p
            className="text-muted font-weight-bold"
            style={{ fontSize: "0.75rem" }}
          >
            <FormattedMessage id="GENERAL.BY_LOGGING_IN_YOU_AGREE_TO_OUR" />{" "}
            <a
              className="text-dark-50 text-hover-primary"
              onClick={() => handleClickTerm()}
            >
              <FormattedMessage id="GENERAL.TERMS_OF_SERVICE" />
            </a>{" "}
            <FormattedMessage id="GENERAL.AND" />{" "}
            <a
              className="text-dark-50 text-hover-primary"
              onClick={() => handleClickPolicy()}
            >
              <FormattedMessage id="GENERAL.PRIVACY_POLICY" />.
            </a>
          </p>
        </div>
        <div className="d-flex flex-column-auto justify-content-center">
          <span
            className="font-weight-bold text-dark-50 pr-1"
            style={{ display: "inline-block" }}
          >
            <FormattedMessage id="AUTH.DONT_HAVE_AN_ACCOUNT_YET" />?{" "}
          </span>
          <Link
            to="/auth/registration"
            id="kt_login_signup"
            className="auth__link_text--color text--bold"
          >
            <FormattedMessage id="AUTH.SIGN_UP" />!
          </Link>
        </div>
      </form>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
