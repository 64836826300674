import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Form } from "react-bootstrap";

export function GeneratePackagingLabelsModal({
  open,
  close,
  onGeneratingPackagingLabels,
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState("");

  async function onGeneratingLabels() {
    setLoading(true);
    const callback = await onGeneratingPackagingLabels(quantity);
    callback === "OK" ? onClosingModal() : setLoading(false);
  }

  const checkIfButtonShouldBeDisabled = () => {
    return quantity === "" || quantity <= 0 ? true : false;
  };

  const onClosingModal = () => {
    setLoading(false);
    setQuantity("");
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.GENERATE_LABELS" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="GENERAL.GENERATE_LABELS_MESSAGE" />.
        </DialogContentText>
        <Form.Control
          type="number"
          placeholder={`${intl.formatMessage({
            id: "PLACEHOLDER.QUANTITY",
          })}`}
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          onClick={() => onGeneratingLabels()}
          disabled={loading || checkIfButtonShouldBeDisabled()}
        >
          <FormattedMessage id="GENERAL.ACCEPT" />
        </Button>
        <Button
          color="default"
          disabled={loading}
          onClick={() => onClosingModal()}
        >
          <FormattedMessage id="GENERAL.CANCEL" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
