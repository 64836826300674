import React, { useEffect, useState } from "react";
import { InputGroup, Button, FormControl } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

export default function SingleApplicationPricing(props) {
  const { pricing, appName } = props;
  const dispatch = useDispatch();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const [brigadierCount, setBrigadierCount] = useState(
    pricing.parameters[0].value
  );
  const [price, setPrice] = useState(0);
  const cmTimeIndex = useSelector((state) => {
    if (state.pricing.cmTime === "yearly") return 1;
    else if (state.pricing.cmTime === "monthly") return 0;
    else return 0;
  });

  useEffect(() => {
    if (pricing && !pricing.isTrial && appName === "BRIGADIER") {
      const param = pricing.parameters.find(
        (p) => p.name === "BRIGADIER_NUMBER"
      );
      const paramPrice = (brigadierCount - param.value) * param.price.price;
      const price = pricing.prices[0].amount + paramPrice;
      setPrice(price);
    }
  }, [
    pricing.isTrial,
    pricing.parameters,
    pricing.prices,
    brigadierCount,
    pricing,
    appName,
  ]);

  const changeBrigadierQuantity = (action) => {
    if (action === "plus") setBrigadierCount(brigadierCount + 1);
    else if (action === "minus" && brigadierCount > pricing.parameters[0].value)
      setBrigadierCount(brigadierCount - 1);
  };

  const handleBrigadierQuantityInputChange = (count) => {
    if (count > pricing.parameters[0].value) setBrigadierCount(count);
    else setBrigadierCount(pricing.parameters[0].value);
  };

  const setPlan = () => {
    dispatch({
      type: "pricing/setSelectedPlan",
      payload: {
        selectedPlan: {
          data: pricing,
          brigadierCount: appName === "BRIGADIER" ? brigadierCount : undefined,
          appName,
          cmTimeIndex: appName === "CROPMANAGER" ? cmTimeIndex : undefined,
        },
      },
    });
    dispatch({
      type: "pricing/setStep",
      payload: {
        step: 2,
      },
    });
  };

  if (appName === "BRIGADIER")
    return (
      <div className="col-md-6 p-4">
        <div
          className="app-pricing-card text-center h-100 d-flex flex-column justify-content-center align-items-center"
          style={{ borderColor: pricing.prices[0].colour }}
        >
          <h4 className="text-center">{pricing.desc}</h4>
          {pricing.prices[0].month && (
            <p>
              <FormattedMessage id="PRICING.SUBSCRIPTION_TIME" />:{" "}
              <strong>
                {pricing.prices[0].month}{" "}
                <FormattedMessage id="PRICING.TIME.MONTHS" />
              </strong>
            </p>
          )}
          {pricing.prices[0].days && (
            <p>
              <FormattedMessage id="PRICING.SUBSCRIPTION_TIME" />:{" "}
              <strong>
                {pricing.prices[0].days}{" "}
                <FormattedMessage id="PRICING.TIME.DAYS" />
              </strong>
            </p>
          )}
          {pricing.isTrial && (
            <p>
              <strong>1</strong>{" "}
              <FormattedMessage id="PRICING.TEST_BRIGADIER" />
            </p>
          )}
          {!pricing.isTrial && (
            <div className="d-flex align-items-center justify-content-center mt-3 mb-6">
              <p className="my-0 flex-shrink-0 mr-4">
                <FormattedMessage id="PRICING.BRIGADIER_COUNT" />:
              </p>
              <InputGroup size="sm" style={{ width: "35%" }}>
                <InputGroup.Prepend>
                  <Button
                    variant="outline-secondary"
                    id="button-addon1"
                    size="sm"
                    onClick={() => changeBrigadierQuantity("minus")}
                  >
                    -
                  </Button>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  min={pricing.parameters[0].value}
                  value={brigadierCount}
                  className="text-center"
                  onChange={(e) =>
                    handleBrigadierQuantityInputChange(
                      Number(e.currentTarget.value)
                    )
                  }
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => changeBrigadierQuantity("plus")}
                  >
                    +
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          )}
          {pricing.isTrial && (
            <h5 className="mb-4">
              <FormattedMessage id="PRICING.FREE" />
            </h5>
          )}
          {!pricing.isTrial && (
            <div style={{ color: pricing.prices[0].colour }}>
              <h5>
                {price.toLocaleString(languageCode)} {pricing.prices[0].currency.name}
              </h5>
              <p>
                <FormattedMessage id="PRICING.PLUS_VAT" />
              </p>
            </div>
          )}
          {pricing.isTrial && (
            <Button variant="light" className="mt-1">
              <FormattedMessage id="PRICING.TRY" />
            </Button>
          )}
          {!pricing.isTrial && (
            <Button
              variant="link"
              className="mt-1"
              style={{
                backgroundColor: pricing.prices[0].colour,
                color: "#fff",
              }}
              onClick={setPlan}
            >
              <FormattedMessage id="PRICING.BUY_NOW" />
            </Button>
          )}
        </div>
      </div>
    );
  else if (appName === "CROPMANAGER")
    return (
      <div className="col-md-6 p-4">
        <div
          className="app-pricing-card text-center h-100 d-flex flex-column justify-content-center align-items-center"
          style={{ borderColor: pricing.prices[0].colour }}
        >
          <h4 className="text-center">{pricing.desc}</h4>
          {pricing.parameters[0].value && (
            <p>
              <FormattedMessage id="PRICING.AGRONOMIST_COUNT" />:{" "}
              <strong>{pricing.parameters[0].value.toLocaleString(languageCode)}</strong>
            </p>
          )}
          <p>
            <FormattedMessage id="PRICING.AVG_PER_USER" />:{" "}
            <strong>
              {cmTimeIndex === 0 &&
                (pricing.prices[0].amount / pricing.parameters[0].value).toLocaleString(languageCode)}
              {cmTimeIndex === 1 &&
                (pricing.prices[1].amount /
                  pricing.parameters[0].value /
                  12).toLocaleString(languageCode)}{" "}
              {pricing.prices[cmTimeIndex].currency.name}
            </strong>
          </p>
          {cmTimeIndex === 0 && (
            <p className="mt-n3">
              <FormattedMessage id="PRICING.AVG_PER_USER_YEARLY.BEFORE" />{" "}
              <strong>
                {(pricing.prices[1].amount / pricing.parameters[0].value / 12).toLocaleString(languageCode)}{" "}
                {pricing.prices[1].currency.name}
              </strong>{" "}
              <FormattedMessage id="PRICING.AVG_PER_USER_YEARLY.AFTER" />
            </p>
          )}
          {pricing.isTrial && (
            <h5 className="mb-4">
              <FormattedMessage id="PRICING.FREE" />
            </h5>
          )}
          {!pricing.isTrial && (
            <div>
              <div className="d-flex justify-content-center">
                <h5 style={{ color: pricing.prices[0].colour }}>
                  {cmTimeIndex === 0 && pricing.prices[cmTimeIndex].amount.toLocaleString(languageCode)}{" "}
                  {cmTimeIndex === 1 && (pricing.prices[cmTimeIndex].amount / 12).toLocaleString(languageCode)}{" "}
                  {pricing.prices[cmTimeIndex].currency.name}
                  /<FormattedMessage id="PRICING.TIME.MONTHS" />
                </h5>
                {cmTimeIndex === 1 && (
                  <span
                    className="ml-4"
                    style={{ textDecoration: "line-through" }}
                  >
                    {pricing.prices[0].amount.toLocaleString(languageCode)}{" "}
                    {pricing.prices[cmTimeIndex].currency.name}
                  </span>
                )}
              </div>
              <p style={{ color: pricing.prices[0].colour }}>
                <FormattedMessage id="PRICING.PLUS_VAT" />
              </p>
            </div>
          )}
          {cmTimeIndex === 1 && (
            <p className="mt-n3">
              <FormattedMessage id="PRICING.AVG_SAVE.BEFORE" />{" "}
              <strong>
                {(pricing.prices[0].amount -
                  pricing.prices[1].amount /
                    12).toLocaleString(languageCode)}{" "}
                {pricing.prices[1].currency.name}
              </strong>{" "}
              <FormattedMessage id="PRICING.AVG_SAVE.AFTER" />
            </p>
          )}
          {!pricing.isTrial && (
            <Button
              variant="link"
              className="mt-1"
              style={{
                backgroundColor: pricing.prices[0].colour,
                color: "#fff",
              }}
              onClick={setPlan}
            >
              <FormattedMessage id="PRICING.BUY_NOW" />
            </Button>
          )}
        </div>
      </div>
    );
  else return null;
}
