import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form } from "react-bootstrap";
import { checkDiscountCode } from "../../../../services/paymentsCrud";
import errorHandler from "../../../../shared/errorHandler";
import displayFeedback from "../../../../components/helpers/displayFeedback";

export default function DiscountCodeInput() {
  const appSymbol = useSelector(
    (state) => state.pricing.selectedApplication.symbol
  );
  const discount = useSelector((state) => state.pricing.discount);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const intl = useIntl();

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    if (discount) setCode(discount.discountCode);
  }, [discount]);

  const checkCode = (e) => {
    e.preventDefault();
    if (code)
      checkDiscountCode(code, appSymbol)
        .then((response) => {
          dispatch({
            type: "pricing/setDiscount",
            payload: {
              discount: response.data,
            },
          });
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
          }
        });
    else if (discount)
      dispatch({
        type: "pricing/setDiscount",
        payload: {
          discount: undefined,
        },
      });
  };

  return (
    <>
      <Form onSubmit={(e) => checkCode(e)}>
        <Form.Row>
          <Form.Group as={Col} md="4">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                <FormattedMessage id="PRICING.DISCOUNT_CODE" />
              </Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  size="sm"
                  className="mr-3"
                  value={code}
                  onChange={handleChange}
                />
                <Button size="sm" type="submit">
                  <FormattedMessage id="GENERAL.ACCEPT" />
                </Button>
              </div>
              {discount && (
                <Form.Text className="text-success">
                  * <FormattedMessage id="PRICING.DISCOUNT_CODE_USED" />
                </Form.Text>
              )}
            </Form.Group>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}
