import React from "react";
import { FormattedMessage } from "react-intl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export function PackagingLabelRow({
  label,
  onSelectingLabelsToPrint,
  onDeletingLabels,
}) {
  return (
    <tr
      className={`text-center ${label.printerCount > 0 ? "row__factory" : ""}`}
    >
      <td>
        <FormControlLabel
          control={
            <Checkbox
              checked={label.print}
              value={label.print}
              name="print"
              onChange={() => onSelectingLabelsToPrint([label.id])}
            />
          }
        />
      </td>
      <td>{label.item}</td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="user-tooltip">
              <FormattedMessage id="GENERAL.DELETE" />
            </Tooltip>
          }
        >
          <Button
            className="mr-0"
            type="button"
            variant="secondary"
            onClick={() => onDeletingLabels([label.id])}
          >
            <i className="fa fa-trash icon-nm pr-0"></i>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
}
