import React from "react";
import { FormattedMessage } from "react-intl";
import { ResultRow } from "./ResultRow";
import { Table } from "react-bootstrap";

export function ResultsTable({ data, onSelectingRow }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>
            <FormattedMessage id="GENERAL.ITEM" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.EMPLOYEE_FIRSTNAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.EMPLOYEE_LASTNAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.OVERSEER_FIRSTNAME" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.OVERSEER_LASTNAME" />
          </th>
          <th>
            <FormattedMessage id="PREVIEW.brigadeName" />
          </th>
          <th style={{ minWidth: "120px" }}>
            <FormattedMessage id="GENERAL.DATE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CROPPING" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.VARIETY" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.QUANTITY" />
          </th>
          <th>
            <FormattedMessage id="BRIGADIER.MEASUREMENT_UNIT_SHORTCUT" />
          </th>
          <th>
            <FormattedMessage id="BRIGADIER.RATE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.VALUE" />
          </th>
          <th>
            <FormattedMessage id="GENERAL.CURRENCY" />
          </th>
           <th>
            <FormattedMessage id="GENERAL.PACKAGING" />
          </th>
          <th style={{ minWidth: "100px" }}>
            <FormattedMessage id="GENERAL.TARIFF" />
          </th>
          <th>
            <FormattedMessage id="PREVIEW.resultDescription" />
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((result, index) => (
          <ResultRow
            key={`result-${result.__resultId}`}
            result={result}
            index={index}
            onSelectingRow={onSelectingRow}
          />
        ))}
      </tbody>
    </Table>
  );
}
