import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/pl";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ru";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";

import enMessages from "./messages/en";
import plMessages from "./messages/pl";
import deMessages from "./messages/de";
import ruMessages from "./messages/ru";
import esMessages from "./messages/es";

const allMessages = {
  en: enMessages,
  pl: plMessages,
  de: deMessages,
  ru: ruMessages,
  es: esMessages,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
