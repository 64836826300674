import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import SVG from "react-inlinesvg";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function GrowersTable(props) {
  const [activeRowId, setActiveRowId] = useState();

  const onRowClick = (id) => {
    if (activeRowId !== id) {
      setActiveRowId(id);
      props.onSelectingOffer(id);
    } else {
      setActiveRowId(null);
      props.onSelectingOffer(null);
    }
  };

  const onButtonClick = () => {
    if (activeRowId) {
      setActiveRowId(null);
      props.onSelectingOffer(null);
    }
    props.onChangingMode();
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Documents.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="PE.SALE_OFFERS" />
          </h3>
        </div>
        <div className="card-toolbar">
          <span>
            <FormattedMessage id="PE.AVAIABLE_PULP" />: {props.growerLimit || 0}{" "}
            t
          </span>
        </div>
      </div>
      <div className="card-body">
        {props.offers.length > 0 ? (
          <Table striped bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>
                  <FormattedMessage id="GENERAL.ID" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.WEIGHT" /> [t]
                </th>
                <th>
                  <FormattedMessage id="GENERAL.PRICE" /> [PLN/t]
                </th>
                <th>
                  <FormattedMessage id="GENERAL.OFFERS" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.EXPIRATION_DATE" />
                </th>
                <th>
                  <FormattedMessage id="GENERAL.STATUS" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.offers.length > 0 &&
                props.offers.map((offer) => (
                  <tr
                    key={offer.sellOfferId}
                    className={`text--not_selectable cursor--pointer ${
                      activeRowId === offer.sellOfferId ? "offer--active" : ""
                    }`}
                    onClick={() => onRowClick(offer.sellOfferId)}
                  >
                    <td className="text-center">{offer.sellOfferId}</td>
                    <td className="text-right">{offer.primaryWeight}</td>
                    <td className="text-right">{offer.price}</td>
                    <td className="text-right">{offer.buyOffers?.length}</td>
                    <td className="text-center">
                      {offer.expirationDate
                        ? `${moment(offer.expirationDate).format("DD.MM.YYYY")}`
                        : null}
                    </td>
                    <td className="text-center">
                      {offer.status === "A" && (
                        <Badge pill variant="info">
                          <FormattedMessage id="GENERAL.PUBLISHED" />
                        </Badge>
                      )}
                      {offer.status === "C" && (
                        <Badge pill variant="danger">
                          <FormattedMessage id="GENERAL.REMOVED" />
                        </Badge>
                      )}
                      {offer.status === "E" && (
                        <Badge pill variant="dark">
                          <FormattedMessage id="GENERAL.EXPIRED" />
                        </Badge>
                      )}
                      {offer.status === "F" && (
                        <Badge pill variant="success">
                          <FormattedMessage id="GENERAL.FINISHED" />
                        </Badge>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center">
            <h6>
              <FormattedMessage id="PE.NO_OFFERS" />
            </h6>
          </div>
        )}

        <div className="text-right">
          <Button disabled={props.createMode} onClick={() => onButtonClick()}>
            <FormattedMessage id="PE.NEW_OFFER" />
          </Button>
        </div>
      </div>
    </div>
  );
}
