import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { MapFilters } from "./MapFilters";
import { FieldsShortcut } from "./FieldsShortcut";
import { CroppingsShortcut } from "./CroppingsShortcut";
import { Tabs, Tab, Row, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

export function MapsMenu({
  fields,
  croppings,
  species,
  treatmentsType,
  onSpeciesFilterChange,
  onSettingTreatmentsVisibility,
  onCenteringOnMap,
  getTreatments,
  onTogglingEcoCroppings,
  showShapesAsMarkers,
  setShapesAsMarkers,
  setMapShapesVisibility,
  filterFieldsAndCroppings,
  onTogglingActiveFCOnly,
  showOnlyActiveFieldsAndCroppings,
  showFieldsAndCroppings,
  showTreatments,
  showOnlyEcoCroppings,
  treatmentsStatusVisibility,
  setTreatmentsStatusVisibility,
}) {
  const [key, setKey] = useState("filters");

  const showFilters = useSelector((state) => state.maps.showFilters);
  const dispatch = useDispatch();

  const closeFilters = () => {
    dispatch({
      type: "maps/setShowFilters",
      payload: false,
    });
  };

  return (
    <div
      className={`card card-custom gutter-b to-parent-height${
        showFilters ? "" : " d-none"
      }`}
    >
      <Button
        onClick={() => closeFilters()}
        size="sm"
        variant="light"
        className="align-self-start position-relative z-50"
      >
        <i className="fa fa-times p-0" />
      </Button>
      <div className="card-body d-flex flex-column scrollable-tab pt-0 mt-n10">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="ml-3">
          <Tab
            eventKey="filters"
            title={<FormattedMessage id="GENERAL.FILTERS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row>
                {" "}
                <MapFilters
                  species={species}
                  treatmentsType={treatmentsType}
                  onSpeciesFilterChange={onSpeciesFilterChange}
                  onSettingTreatmentsVisibility={onSettingTreatmentsVisibility}
                  getTreatments={getTreatments}
                  onTogglingEcoCroppings={onTogglingEcoCroppings}
                  showShapesAsMarkers={showShapesAsMarkers}
                  setShapesAsMarkers={setShapesAsMarkers}
                  setMapShapesVisibility={setMapShapesVisibility}
                  filterFieldsAndCroppings={filterFieldsAndCroppings}
                  onTogglingActiveFCOnly={onTogglingActiveFCOnly}
                  showFieldsAndCroppings={showFieldsAndCroppings}
                  showTreatments={showTreatments}
                  showOnlyEcoCroppings={showOnlyEcoCroppings}
                  showOnlyActiveFieldsAndCroppings={
                    showOnlyActiveFieldsAndCroppings
                  }
                  treatmentsStatusVisibility={treatmentsStatusVisibility}
                  setTreatmentsStatusVisibility={setTreatmentsStatusVisibility}
                />
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="fields"
            title={<FormattedMessage id="GENERAL.FIELDS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row>
                <FieldsShortcut
                  fields={fields}
                  onCenteringOnMap={onCenteringOnMap}
                  showOnlyActiveFieldsAndCroppings={
                    showOnlyActiveFieldsAndCroppings
                  }
                />
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="croppings"
            title={<FormattedMessage id="GENERAL.CROPPINGS" />}
          >
            <Container fluid className={"scrollable-container"}>
              <Row>
                {" "}
                <CroppingsShortcut
                  croppings={croppings}
                  onCenteringOnMap={onCenteringOnMap}
                  showOnlyActiveFieldsAndCroppings={
                    showOnlyActiveFieldsAndCroppings
                  }
                />
              </Row>
            </Container>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
