import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { enGB, de, pl, ru } from "date-fns/locale";
import { Search } from "../../../components/Search";
import { WorkersModalTable } from "../components/WorkersModalTable";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

export function WorkersOperationsModal({
  open,
  close,
  formData,
  workers,
  createReceivablesTransactions,
}) {
  const intl = useIntl();
  const {
    register,
    triggerValidation,
    errors,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [workersCopy, setWorkersCopy] = useState([]);
  const [modalFormData, setModalFormData] = useState();
  const [selectedWorkerIds, setSelectedWorkerIds] = useState([]);
  const [datepickerLocale, setDatepickerLocale] = useState("pl");
  const [currentDate, setCurrentDate] = useState(new Date());
  registerLocale("pl", pl);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "en") {
      registerLocale("enGB", enGB);
      setDatepickerLocale("enGB");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "pl"
    ) {
      registerLocale("pl", pl);
      setDatepickerLocale("pl");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "de"
    ) {
      registerLocale("de", de);
      setDatepickerLocale("de");
    } else if (
      JSON.parse(localStorage.getItem("i18nConfig")).selectedLang === "ru"
    ) {
      registerLocale("ru", ru);
      setDatepickerLocale("ru");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      triggerValidation();
    });
    if (!modalFormData) {
      setFormData();
    }
  }, [open]);

  useEffect(() => {
    setWorkersCopy(JSON.parse(JSON.stringify(workers)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, workers]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
  };

  const isValue = (element) => element.value === "ADMINISTRATION";

  const setFormData = () => {
    if (formData) {
      let newFormData = JSON.parse(JSON.stringify(formData));
      const index = newFormData.payoutTypes.findIndex(isValue);
      const spliced = newFormData.payoutTypes.splice(index, 1);
      const newPayoutTypes = spliced.concat(newFormData.payoutTypes);
      newFormData.payoutTypes = newPayoutTypes;
      setModalFormData(newFormData);
    }
  };

  async function onSubmit(data) {
    setLoading(true);
    const form = data;
    form.employeeIds = selectedWorkerIds;
    form.registerAt = moment(moment(currentDate)).format("YYYY-MM-DD");
    const callback = await createReceivablesTransactions(form);
    callback === "OK" ? onClosingModal() : setLoading(false);
  }

  const onClosingModal = () => {
    setLoading(false);
    reset();
    setSelectedWorkerIds([]);
    setFilter("");
    setCurrentDate(new Date());
    close();
  };

  const checkIfButtonShouldBeDisabled = () => {
    return Object.keys(errors).length ? true : false;
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      maxWidth="md"
      className="brigadier-theme"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.CREATE_BULK_OPERATION" />
      </DialogTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers={true}>
          <DialogContentText>
            <FormattedMessage id="BRIGADIER.CREATE_WORKERS_OPERATIONS_MESSAGE" />
            .
          </DialogContentText>
          <Row>
            <Col xs={12} className="mb-2">
              <Search onChange={onFilteringDataset} value={filter} />
            </Col>
            <Col md={6} className="modal__table mb-6 mb-md-0">
              <WorkersModalTable
                workers={workersCopy}
                selectedWorkerIds={selectedWorkerIds}
                setSelectedWorkerIds={setSelectedWorkerIds}
                filter={filter}
              />
            </Col>
            <Col md={6} className="modal__table">
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.OPERATION_REGISTER_DATE" /> *
                  </Form.Label>
                  <DatePicker
                    name="currentDate"
                    selected={currentDate}
                    dateFormat="dd.MM.yyyy"
                    locale={datepickerLocale}
                    showMonthDropdown
                    showYearDropdown
                    onChange={(date) => setCurrentDate(date)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.OPERATION_TYPE" /> *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="payoutTypeId"
                    ref={register({ required: true })}
                  >
                    {modalFormData && (
                      <>
                        {modalFormData.payoutTypes.map((payout) => (
                          <option key={`payout-${payout.id}`} value={payout.id}>
                            {payout.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.AMOUNT" />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="value"
                      type="number"
                      className={`${
                        errors.value ||
                        getValues("value") === (null || undefined || 0)
                          ? "is-invalid"
                          : ""
                      } ${!errors.value ? "is-valid" : ""}`}
                      placeholder={`${intl.formatMessage({
                        id: "GENERAL.AMOUNT",
                      })}`}
                      step="0.01"
                      ref={register({ required: true, min: 0.01 })}
                    />
                  </InputGroup>
                  {errors.value?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
                  {errors.value?.type === "min" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_0_01" />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.PAYOUT_TYPE" /> *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="currencyId"
                    ref={register({ required: true })}
                  >
                    {modalFormData && (
                      <>
                        {modalFormData.currencies.map((currency) => (
                          <option
                            key={`currency-${currency.id}`}
                            value={currency.id}
                          >
                            {currency.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    <FormattedMessage id="GENERAL.COMMENT" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="desc"
                    ref={register({ required: false })}
                  />
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            disabled={
              loading ||
              selectedWorkerIds.length === 0 ||
              checkIfButtonShouldBeDisabled()
            }
          >
            <FormattedMessage id="GENERAL.ACCEPT" />
          </Button>
          <Button
            color="default"
            disabled={loading}
            onClick={() => onClosingModal()}
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
