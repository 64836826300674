import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GoogleMap,
  Marker,
  DrawingManager,
  Polygon,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { DeleteFieldOrCroppingShapeModal } from "../../../components/DeleteFieldOrCroppingShapeModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { createPolygonStructureWithHoles } from "../../../utils/mapUtils";

const googleMapStyle = {
  width: "100%",
  height: "400px",
};

const drawingManagerOptions = {
  drawingControl: false,
  drawingControlOptions: {
    drawingModes: ["polygon"],
  },
  polygonOptions: {
    fillColor: "#FF00FF",
    fillOpacity: "0.45",
    strokeColor: "#FF00FF",
    strokeWeight: "3",
    editable: true,
    zIndex: 2,
  },
};

const parentPolygonOptions = {
  visible: true,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const childrenPolygonOptions = {
  fillColor: "#32CD32",
  fillOpacity: "0.45",
  strokeColor: "#32CD32",
  strokeWeight: "3",
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 2,
};

let temporaryGlobalChildrens;

export function CroppingsMap(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [libraries] = useState(["drawing", "visualization", "places"]);
  const [googleMap, setGoogleMap] = useState();
  const [searchbox, setSearchbox] = useState();
  const [drawingMode, setDrawingMode] = useState("polygon");
  const [drawingManager, setDrawingManager] = useState();
  const [selectedShape, setSelectedShape] = useState();
  const [croppingExist, setCroppingExist] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [mode, setMode] = useState(null);
  const [canRestore, setCanRestore] = useState(false);
  const [temporaryChildrenPolygons, setTemporaryChildrenPolygons] = useState(
    []
  );
  const [childrenPaths, setChildrenPaths] = useState();
  const [childrenPolygons, setChildrenPolygons] = useState([]);
  const [shapeMap, setShapeMap] = useState();
  const [centerPoint, setCenterPoint] = useState({ lat: 52, lng: 20 });
  const [zoom, setZoom] = useState(6);
  const [fieldsCoordinates, setFieldsCoordinates] = useState([]);
  const [fieldsPolygons, setFieldsPolygons] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marker, setMarker] = useState();
  const [fieldMarker, setFieldMarker] = useState([]);
  const [markerShape, setMarkerShape] = useState();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries: libraries,
    version: process.env.REACT_APP_GOOGLE_MAPS_VERSION,
  });

  useEffect(() => {
    setMapCenter();
  }, [props.croppingShape, props.activeTab, props.fieldLongestLine]);

  useEffect(() => {
    resetCroppingStates();
    setTimeout(() => {
      if (props.croppingShape !== null) {
        onCreatingCroppingPolygons();
      }
    });
  }, [props.croppingShape, drawingManager]);

  useEffect(() => {
    resetFieldStates();
    onSettingDrawingManager("hide");
    setTimeout(() => {
      if (props.assignedFields?.length) {
        setButtonsVisible(true);
        onCreatingField();
      }
    });
  }, [props.assignedFields, drawingManager]);

  useEffect(() => {
    props.croppingMarker
      ? setTimeout(() => {
          setMarker(props.croppingMarker);
        })
      : setMarker(null);
  }, [props.croppingMarker]);

  const setMapCenter = () => {
    const latLngList = [];
    setTimeout(() => {
      if (props.fieldLongestLine && props.croppingShape) {
        props.fieldLongestLine.forEach((coordinate) => {
          latLngList.push(
            new window.google.maps.LatLng(coordinate[1], coordinate[0])
          );
        });
        let bounds = new window.google.maps.LatLngBounds();
        latLngList.forEach((row) => {
          bounds.extend(row);
        });
        googleMap.setCenter(bounds.getCenter());
        googleMap.fitBounds(bounds);
        googleMap.setZoom(googleMap.getZoom() - 1);
      }
    }, 250);
  };

  const resetCroppingStates = () => {
    setCroppingExist(false);
    setChildrenPaths([]);
    if (selectedShape) {
      handleDelete();
    }
    if (childrenPolygons.length) {
      childrenPolygons.forEach((crop) => {
        crop.setMap(null);
      });
      setChildrenPolygons([]);
    }
  };

  const resetFieldStates = () => {
    setButtonsVisible(false);
    setFieldsCoordinates([]);
    if (markerShape) {
      markerShape.setMap(null);
      setMarkerShape();
      setMarker(null);
    }
    if (fieldsPolygons.length) {
      fieldsPolygons.forEach((field) => {
        field.setMap(null);
      });
      setFieldsPolygons([]);
    }
    setMode(null);
    setCanSave(false);
    setCanDelete(false);
    setCanRestore(false);
    setCenterPoint({ lat: 52, lng: 20 });
    setZoom(6);
  };

  const onSettingDrawingManager = (operationType) => {
    if (drawingManager) {
      if (operationType === "show") {
        drawingManager.setDrawingMode(croppingExist ? null : "polygon");
        drawingManager.setOptions({
          drawingControl: true,
        });
      } else {
        drawingManager.setDrawingMode(null);
        drawingManager.setOptions({
          drawingControl: false,
        });
      }
    }
  };

  const onCreatingField = () => {
    if (!props.assignedFields?.length) {
      return;
    }

    const polygonFields = props.assignedFields.filter((row) => row.shapeId);
    const pointFields = props.assignedFields.filter((row) => row.pointId);
    onCreatingPolygonField(polygonFields);
    onCreatingMarkerField(pointFields);
  };

  const onCreatingPolygonField = (fields) => {
    let fieldsList = [];
    let setCenterAndZoom = false;
    if (fields?.length) {
      fields.forEach((field) => {
        let fullParentCoordinates = [];
        let outerCoordinates = [];
        field.shape.parent.shape.coordinates.forEach((row) => {
          row.forEach((coordinate) => {
            if (!setCenterAndZoom) {
              setCenterPoint({ lat: coordinate[1], lng: coordinate[0] });
              setZoom(13);
              setCenterAndZoom = true;
            }
            outerCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
          });
        });
        fullParentCoordinates.push(outerCoordinates);
        if (field.shape.children.length) {
          field.shape.children.forEach((child) => {
            const innerCoordinates = [];
            child.shape.coordinates.forEach((row) => {
              row.forEach((coordinate) => {
                innerCoordinates.push({
                  lat: coordinate[1],
                  lng: coordinate[0],
                });
              });
            });
            fullParentCoordinates.push(innerCoordinates);
          });
        }
        fieldsList.push(fullParentCoordinates);
      });
    }

    setFieldsCoordinates(fieldsList);
  };

  const onCreatingMarkerField = (fields) => {
    setFieldMarker(
      fields.map((row) => {
        row.position = {
          lat: row.point.point.coordinates[1],
          lng: row.point.point.coordinates[0],
        };
        return row;
      })
    );
  };

  const onCreatingCroppingPolygons = () => {
    let childrenCoordinates = [];
    if (props.croppingShape) {
      props.croppingShape.forEach((child) => {
        const outerCoordinates = createPolygonStructureWithHoles(
          child.shape.coordinates
        );
        childrenCoordinates.push(outerCoordinates);
      });
    }
    setCroppingExist(true);
    setChildrenPaths(childrenCoordinates);
  };

  const onSearchBoxLoad = (searcher) => {
    setSearchbox(searcher);
  };

  const onPlacesChanged = () => {
    const searchedPlace = searchbox.getPlaces()[0];
    setCenterPoint({
      lat: searchedPlace.geometry.location.lat(),
      lng: searchedPlace.geometry.location.lng(),
    });
  };

  const onDrawingManagerLoad = (drawMan) => {
    setDrawingManager(drawMan);
  };

  const onOverlayComplete = (newOverlay) => {
    if (mode) {
      let newShape = newOverlay.overlay;
      newShape.type = newOverlay.type;
      window.google.maps.event.addListener(newShape, "click", function() {
        onSettingSelection(newShape);
      });
      onSettingSelection(newShape);
      let newTemporaryChildrenPolygons = [...temporaryChildrenPolygons];
      newTemporaryChildrenPolygons.push(newShape);
      setTemporaryChildrenPolygons(newTemporaryChildrenPolygons);
      temporaryGlobalChildrens = newTemporaryChildrenPolygons;
      selectNewPolygonAndUnselectOldPolygons(
        newShape,
        temporaryGlobalChildrens
      );
      setCanDelete(true);
      setCanSave(true);
    } else newOverlay.overlay.setMap(null);
  };

  const onSettingSelection = (shape) => {
    onClearingSelection();
    setSelectedShape(shape);
    setCanDelete(true);
    selectNewPolygonAndUnselectOldPolygons(shape, temporaryGlobalChildrens);
  };

  const onClearingSelection = () => {
    if (selectedShape) {
      setSelectedShape(null);
    }
  };

  const selectNewPolygonAndUnselectOldPolygons = (
    shape,
    newlyCreatedChildrenPolygons
  ) => {
    if (childrenPolygons.length) {
      childrenPolygons.forEach((polygon) => {
        if (polygon.fillColor === "#990099") {
          polygon.setOptions({
            fillColor: "#FF00FF",
            strokeColor: "#FF00FF",
          });
        }
      });
    }
    if (typeof newlyCreatedChildrenPolygons !== "undefined") {
      if (newlyCreatedChildrenPolygons.length) {
        newlyCreatedChildrenPolygons.forEach((polygon) => {
          if (polygon.fillColor === "#990099") {
            polygon.setOptions({
              fillColor: "#FF00FF",
              strokeColor: "#FF00FF",
            });
          }
        });
      }
    }
    shape.setOptions({
      fillColor: "#990099",
      strokeColor: "#990099",
    });
  };

  const onParentPolygonLoad = (polygon) => {
    let newFieldPolygons = fieldsPolygons;
    newFieldPolygons.push(polygon);
    setFieldsPolygons(newFieldPolygons);
  };

  const onChildPolygonLoad = (polygon) => {
    let newChildrenPolygons = childrenPolygons;
    newChildrenPolygons.push(polygon);
    setChildrenPolygons(newChildrenPolygons);
  };

  const onSelectingChildPolygon = (index) => {
    if (selectedShape) {
      selectedShape.setOptions({
        fillColor: "#FF00FF",
        strokeColor: "#FF00FF",
      });
    }
    setSelectedShape(childrenPolygons[index]);
    selectNewPolygonAndUnselectOldPolygons(
      childrenPolygons[index],
      temporaryChildrenPolygons
    );
    setCanDelete(true);
  };

  const onPolygonModification = () => {
    setCanSave(true);
    setCanRestore(true);
  };

  const handleDelete = () => {
    setLoading(true);
    if (selectedShape) {
      setShapeMap(selectedShape.getMap());
      selectedShape.setMap(null);
      setCanDelete(false);
      setLoading(false);
    }
  };

  async function handleUpdate() {
    setLoading(true);
    let newCroppings = [];
    if (childrenPolygons.length) {
      childrenPolygons.forEach((childPolygon) => {
        if (childPolygon.map !== null) {
          let coords2;
          const latLngKey = Object.keys(childPolygon.latLngs)[0];
          if (childPolygon.latLngs[latLngKey]?.length > 1) {
            coords2 = childPolygon.latLngs[latLngKey][1][latLngKey].map((p) => {
              return [p.lng(), p.lat()];
            });
            coords2.push([
              childPolygon.latLngs[latLngKey][1][latLngKey][0].lng(),
              childPolygon.latLngs[latLngKey][1][latLngKey][0].lat(),
            ]);
          }
          newCroppings.push({
            shape: {
              type: "Polygon",
              coordinates: coords2
                ? [
                    childPolygon
                      .getPath()
                      .getArray()
                      .map((p) => {
                        return [p.lng(), p.lat()];
                      }),
                    coords2,
                  ]
                : [
                    childPolygon
                      .getPath()
                      .getArray()
                      .map((p) => {
                        return [p.lng(), p.lat()];
                      }),
                  ],
            },
            isExcluded: false,
          });
        }
      });
    }
    if (temporaryChildrenPolygons.length) {
      temporaryChildrenPolygons.forEach((temporaryChildPolygon) => {
        if (temporaryChildPolygon.map !== null) {
          newCroppings.push({
            shape: {
              type: "Polygon",
              coordinates: [
                temporaryChildPolygon
                  .getPath()
                  .getArray()
                  .map((p) => {
                    return [p.lng(), p.lat()];
                  }),
              ],
            },
            isExcluded: false,
          });
        }
      });
    }
    const form = {
      children: newCroppings,
      parent: null,
      shapeArea: null,
    };
    if (croppingExist) {
      const callback = await props.onUpdatingPolygon(form);
      if (callback) {
        setLoading(false);
        if (temporaryChildrenPolygons.length) {
          temporaryChildrenPolygons.forEach((child) => {
            child.setMap(null);
          });
        }
      }
    } else {
      const callback = await props.onCreatingPolygon(form);
      if (callback) {
        setLoading(false);
        if (temporaryChildrenPolygons.length) {
          temporaryChildrenPolygons.forEach((child) => {
            child.setMap(null);
          });
        }
      }
    }
  }

  const handleRestore = () => {
    setLoading(true);
    if (childrenPolygons.length) {
      childrenPolygons.forEach((childPolygon) => {
        if (childPolygon.map === null) {
          childPolygon.setMap(shapeMap);
        }
      });
    }
    onCreatingCroppingPolygons();
    setCanRestore(false);
    setLoading(false);
  };

  const handleCancel = () => {
    if (croppingExist) {
      handleRestore();
    }
    if (childrenPolygons.length) {
      childrenPolygons.forEach((childPolygon) => {
        childPolygon.setOptions({
          fillColor: "#32CD32",
          fillOpacity: "0.45",
          strokeColor: "#32CD32",
          strokeWeight: "3",
          clickable: false,
          editable: false,
        });
      });
    }
    if (temporaryChildrenPolygons.length) {
      temporaryChildrenPolygons.forEach((temporaryChildPolygon) => {
        temporaryChildPolygon.setMap(null);
      });
      setTemporaryChildrenPolygons([]);
    }
    fieldsPolygons.forEach((field) => {
      field.setOptions({
        fillColor: "#000",
        fillOpacity: "0.45",
        strokeColor: "#000",
        strokeWeight: "3",
      });
    });
    setTimeout(() => {
      onSettingDrawingManager("hide");
    });
    setSelectedShape(null);
    setMode(null);
    setCanSave(false);
    setCanDelete(false);
    setCanRestore(false);
  };

  const handleChangeMode = (modeToSet) => {
    setMode(modeToSet);
    if (modeToSet === "update") {
      prepareParentForModification(modeToSet);
      onSettingDrawingManager("show");
    }
  };

  const prepareParentForModification = (modeToSet) => {
    if (modeToSet === "update") {
      fieldsPolygons.forEach((field) => {
        field.setOptions({
          fillColor: "#b8cf67",
          fillOpacity: "0.45",
          strokeColor: "#b8cf67",
          strokeWeight: "3",
        });
      });
      childrenPolygons.forEach((child) => {
        child.setOptions({
          fillColor: "#FF00FF",
          strokeColor: "#FF00FF",
          clickable: true,
          editable: true,
        });
      });
    }
  };

  async function handleRemoveCropping() {
    setLoading(true);
    const form = { children: null, parent: null, shapeArea: null };
    const callback = await props.onUpdatingPolygon(form);
    if (callback) {
      setLoading(false);
    }
  }

  const onOpeningOrClosingModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onMarkerLoad = (marker) => {
    // setMarkerShape(marker);
  };

  const renderMap = () => {
    // wrapping to a function is useful in case you want to access `window.google`
    // to eg. setup options or create latLng object, it won't be available otherwise
    // feel free to render directly if you don't need that
    function onLoad(mapInstance) {
      setGoogleMap(mapInstance);
      mapInstance.setOptions({ mapTypeControl: false });
    }
    return (
      <GoogleMap
        mapContainerStyle={googleMapStyle}
        center={centerPoint}
        zoom={zoom}
        mapTypeId={"hybrid"}
        onLoad={onLoad}
      >
        {!childrenPaths.length && marker && (
          <Marker onLoad={onMarkerLoad} position={marker.position} />
        )}
        {!fieldsCoordinates.length &&
          fieldMarker.map((coordinates, index) => (
            <Marker
              key={"field-marker-" + index}
              position={coordinates.position}
            />
          ))}
        <StandaloneSearchBox
          onLoad={onSearchBoxLoad}
          onPlacesChanged={onPlacesChanged}
        >
          <input
            type="text"
            placeholder={`${intl.formatMessage({
              id: "PLACEHOLDER.SEARCH",
            })}...`}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              top: "10px",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox>
        <DrawingManager
          drawingMode={drawingMode}
          options={drawingManagerOptions}
          onLoad={onDrawingManagerLoad}
          onOverlayComplete={onOverlayComplete}
        />
        {fieldsCoordinates.length > 0 && (
          <>
            {fieldsCoordinates.map((coordinates, index) => (
              <Polygon
                key={index}
                paths={coordinates}
                options={parentPolygonOptions}
                onLoad={onParentPolygonLoad}
              />
            ))}
          </>
        )}
        {childrenPaths && (
          <>
            {childrenPaths.map((child, index) => (
              <Polygon
                key={index}
                paths={childrenPaths[index]}
                options={childrenPolygonOptions}
                onLoad={onChildPolygonLoad}
                onClick={() => onSelectingChildPolygon(index)}
                onMouseUp={onPolygonModification}
              />
            ))}
          </>
        )}
      </GoogleMap>
    );
  };

  return (
    <Row className="mt-5">
      <DeleteFieldOrCroppingShapeModal
        open={isModalOpen}
        close={onOpeningOrClosingModal}
        screen={"croppings"}
        onRemovingFieldOrCropping={handleRemoveCropping}
      />
      <Col sm={4} className="mb-5 mb-sm-0">
        {buttonsVisible && (
          <>
            {mode !== "update" && (
              <>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => handleChangeMode("update")}
                >
                  {croppingExist ? (
                    <FormattedMessage id="GENERAL.MODIFY_CROPPING" />
                  ) : (
                    <FormattedMessage id="GENERAL.DRAW_CROPPING" />
                  )}
                </Button>
                {croppingExist && (
                  <Button
                    variant="secondary"
                    style={{ width: "100%" }}
                    className="mt-5"
                    onClick={onOpeningOrClosingModal}
                  >
                    <FormattedMessage id="GENERAL.DELETE" />
                  </Button>
                )}
              </>
            )}
            {mode === "update" && (
              <>
                <Button
                  style={{ width: "100%" }}
                  onClick={handleUpdate}
                  disabled={loading || !canSave}
                >
                  <FormattedMessage id="GENERAL.SAVE" />
                </Button>
                {croppingExist && (
                  <Button
                    style={{ width: "100%" }}
                    className="mt-5"
                    onClick={handleRestore}
                    disabled={loading || !canRestore}
                  >
                    <FormattedMessage id="GENERAL.RESTORE" />
                  </Button>
                )}
                <Button
                  variant="secondary"
                  style={{ width: "100%" }}
                  className="mt-5"
                  disabled={loading || !canDelete}
                  onClick={handleDelete}
                >
                  <FormattedMessage id="GENERAL.DELETE" />
                </Button>
                <Button
                  variant="secondary"
                  style={{ width: "100%" }}
                  className="mt-5"
                  onClick={handleCancel}
                >
                  <FormattedMessage id="GENERAL.CANCEL" />
                </Button>
              </>
            )}
          </>
        )}
      </Col>
      <Col sm={8}>{isLoaded ? renderMap() : null}</Col>
    </Row>
  );
}
