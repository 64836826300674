import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export function AgronomistsTableRow({
  agronomist,
  onDeletingAgronomist,
}) {
  const isExtended = agronomist.roles.find(role => role.value === "ROLE_AGRONOMIST_EXTEND")

  return (
    <tr className="text-center">
      <td>{agronomist.login}</td>
      <td>{agronomist.roles[0].name}</td>
      <td>
        {isExtended ? (
          <i
            className="flaticon2-check-mark"
            style={{ padding: "none", color: "#1BC5BD" }}
          />
        ) : (
          <i
            className="flaticon2-cross text--muted"
            style={{ padding: "none" }}
          />
        )}
      </td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="user-tooltip">
              <FormattedMessage id="GENERAL.DELETE" />
            </Tooltip>
          }
        >
          <Button
            type="button"
            variant="secondary"
            onClick={() => onDeletingAgronomist(agronomist.loginId)}
          >
            <i className="fa fa-trash icon-nm pr-0"></i>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
}

AgronomistsTableRow.propTypes = {
  agronomist: PropTypes.object,
};
