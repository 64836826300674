import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Search } from "../../../components/Search";
import { DocumentsTable } from "./DocumentsTable";
import { Button } from "react-bootstrap";

export const DocumentsCard = (props) => {
  const { mode, documents, handleRowClick, handleCreateButtonClick } = props;
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState(documents || []);

  useEffect(() => {
    const filteredArray = filterDataset(filter);
    setFilteredData(filteredArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const onFilteringDataset = (e) => {
    setFilter(e.target.value);
    if (documents.length) {
      const filteredArray = filterDataset(e.target.value);
      setFilteredData(filteredArray);
    }
  };

  const filterDataset = (text) => {
    return documents.filter((document) => {
      const nameExist = document.name
        ? document.name.toLowerCase().includes(text.toLowerCase())
        : false;
      const dateExist = document.createdAt
        ? document.createdAt
            .toString()
            .toLowerCase()
            .includes(text.toLowerCase())
        : false;
      return nameExist || dateExist;
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-toolbar" style={{ width: "100%" }}>
          <Search onChange={onFilteringDataset} value={filter} />
        </div>
      </div>
      <div className="card-body">
        {documents.length > 0 ? (
          <div className="card__table">
            <DocumentsTable
              data={filteredData}
              handleRowClick={handleRowClick}
            />
          </div>
        ) : (
          <div className="text-center">
            <h6>
              <FormattedMessage id="GENERAL.NO_DOCUMENTS_TO_SHOW" />
            </h6>
          </div>
        )}
        <div className="text-right">
          <Button
            disabled={mode === "create"}
            onClick={() => handleCreateButtonClick()}
          >
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsCard;
