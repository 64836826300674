import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import useEffectOnce from "../hooks/useEffectOnce";
import errorHandler from "../shared/errorHandler";
import {
  getFieldsToBPartnerWithShape,
  getFieldDetails,
} from "../services/fieldsCrud";
import {
  getCroppingsWithShapeToBPartner,
  getCroppingDetails,
} from "../services/croppingsCrud";
import {
  getTreatmentsToCropping,
  getTreatmentToLoginInDatesWithFilters,
  getTreatment,
} from "../services/treatmentsCrud";
import { getSpeciesInUserCroppingAndField } from "../services/speciesCrud";
import { getShapeLongestLine } from "../services/shapesCrud";
import { getLongestLineToBPartner } from "../services/mapsCrud";
import { Map, MapsMenu } from "../modules/maps";
import { Row, Col, Button } from "react-bootstrap";
import displayFeedback from "../components/helpers/displayFeedback";
import { useDispatch, useSelector } from "react-redux";

export function MapsPage() {
  const intl = useIntl();
  const [fields, setFields] = useState([]);
  const [croppings, setCroppings] = useState([]);
  const [treatmentsType, setTreatmentsType] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [species, setSpecies] = useState([]);
  const [filter, setFilter] = useState("");
  const [
    fieldsAndCroppingsVisibility,
    setFieldsAndCroppingsVisibility,
  ] = useState({ showFields: true, showCroppings: true });
  const [showTreatments, setShowTreatments] = useState(true);
  const [details, setDetails] = useState();
  const [speciesFilter, setSpeciesFilter] = useState([]);
  const [showOnlyEcoCroppings, setShowOnlyEcoCroppings] = useState(false);
  const [
    showOnlyActiveFieldsAndCroppings,
    setShowOnlyActiveFieldsAndCroppings,
  ] = useState(true);
  const [showShapesAsMarkers, setShowShapesAsMarkers] = useState(false);
  const [line, setLine] = useState(null);
  const [treatmentsStatusVisibility, setTreatmentsStatusVisibility] = useState({
    active: true,
    finished: true,
    canceled: true,
  });

  const getFields = () => {
    getFieldsToBPartnerWithShape(localStorage.getItem("activeFarmId"), true)
      .then((response) => {
        setFields(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getFields);

  const getCroppings = () => {
    getCroppingsWithShapeToBPartner(localStorage.getItem("activeFarmId"), true)
      .then((response) => {
        setCroppings(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getCroppings);

  const getTreatmentsType = () => {
    getTreatmentsToCropping()
      .then((response) => {
        const treatmentTypes = response.data.filter((treatment) => {
          return treatment.treatmentTypeDesc !== "Zbiór";
        });
        let treatmentTypeIds = [];
        setTreatmentsType(treatmentTypes);
        treatmentTypes.forEach((treatmentType) => {
          treatmentTypeIds.push(treatmentType.treatmentTypeId);
        });
        getTreatments(
          moment(new Date()).format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD"),
          treatmentTypeIds
        );
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getTreatmentsType);

  const getTreatments = (from, to, treatmentTypeIds) => {
    const fromDate = moment(from).format("YYYY-MM-DD");
    const toDate = moment(to).format("YYYY-MM-DD");
    getTreatmentToLoginInDatesWithFilters(
      localStorage.getItem("activeFarmId"),
      fromDate,
      toDate,
      treatmentTypeIds
    )
      .then((response) => {
        setTreatments(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const getSpecies = () => {
    getSpeciesInUserCroppingAndField(localStorage.getItem("activeFarmId"))
      .then((response) => {
        const typeList = response.data.map((type) => {
          return { desc: type.desc, speciesId: type.id, checked: true };
        });
        setSpecies([
          {
            id: null,
            desc: intl.formatMessage({ id: "SPECIES.W/O_SPECIES" }),
            varietyList: [],
            checked: true,
          },
          ...response.data,
        ]);
        setSpeciesFilter([
          {
            desc: intl.formatMessage({ id: "SPECIES.W/O_SPECIES" }),
            speciesId: null,
            checked: true,
          },
          ...typeList,
        ]);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  useEffectOnce(getSpecies);

  const onCenteringOnMap = (shapeId) => {
    getShapeLongestLine(shapeId)
      .then((response) => {
        setLine(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onSpeciesFilterChange = (typeList) => {
    setSpeciesFilter(typeList);
  };

  const onTogglingEcoCroppings = () => {
    setShowOnlyEcoCroppings(!showOnlyEcoCroppings);
  };

  const onTogglingActiveFCOnly = () => {
    setShowOnlyActiveFieldsAndCroppings(!showOnlyActiveFieldsAndCroppings);
  };

  const onSettingTreatmentsVisibility = (value) => {
    setShowTreatments(value);
  };

  const onGetFieldDetails = (fieldId) => {
    getFieldDetails(fieldId)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onGetCroppingDetails = (croppingId) => {
    getCroppingDetails(croppingId)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const onGetTreatmentDetails = (treatmentHeaderId) => {
    getTreatment(treatmentHeaderId)
      .then((response) => {
        setDetails(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };

  const setShapesAsMarkers = () => {
    setShowShapesAsMarkers(!showShapesAsMarkers);
  };

  const setMapShapesVisibility = (showFieldsAndCroppings) => {
    setFieldsAndCroppingsVisibility(showFieldsAndCroppings);
  };

  const getCroppingOrFieldLongestLine = () => {
    getLongestLineToBPartner(localStorage.getItem("activeFarmId"))
      .then((response) => {
        setLine(response.data);
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  };
  useEffectOnce(getCroppingOrFieldLongestLine);

  const filteredFields = useMemo(() => {
    if (fields?.length > 0 && fieldsAndCroppingsVisibility.showFields) {
      return fields.filter((field) => {
        const fieldNameExist = field.shortName
          ? field.shortName.toLowerCase().includes(filter.toLowerCase())
          : false;
        const supplierExist = field.supplier?.fullName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesExist = field.species?.desc
          .toLowerCase()
          .includes(filter.toLowerCase());
        const varietyExist = field.variety?.desc
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesFilterExist = () => {
          if (!field.species)
            return speciesFilter.find((species) => species.speciesId === null)?.checked;
          let times = 0;
          speciesFilter.forEach((type) => {
            if (type.speciesId === field.species.id && type.checked === true) {
              times += 1;
            }
          });
          if (times > 0) return true;
          else return false;
        };
        return (
          !!(showOnlyActiveFieldsAndCroppings ? field.isActive : true) &&
          !!(filter.length > 0
            ? fieldNameExist || supplierExist || speciesExist || varietyExist
            : true) &&
          !!speciesFilterExist()
        );
      });
    } else return [];
  }, [
    fields,
    fieldsAndCroppingsVisibility.showFields,
    filter,
    showOnlyActiveFieldsAndCroppings,
    speciesFilter,
  ]);

  const filteredCroppings = useMemo(() => {
    if (croppings?.length > 0 && fieldsAndCroppingsVisibility.showCroppings) {
      return croppings.filter((cropping) => {
        const croppingNameExist = cropping.shortName
          ? cropping.shortName.toLowerCase().includes(filter.toLowerCase())
          : false;
        const varietyExist = cropping.variety.shortName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesExist = cropping.variety.speciesDesc
          .toLowerCase()
          .includes(filter.toLowerCase());
        const supplierExist = cropping.supplier?.fullName
          .toLowerCase()
          .includes(filter.toLowerCase());
        const speciesFilterExist = () => {
          let times = 0;
          speciesFilter.forEach((type) => {
            if (
              type.speciesId === cropping.variety.speciesId &&
              type.checked === true
            ) {
              times += 1;
            }
          });
          if (times > 0) return true;
          else return false;
        };
        return (
          !!(showOnlyActiveFieldsAndCroppings ? cropping.isActive : true) &&
          !!(showOnlyEcoCroppings ? cropping.isEco : true) &&
          !!(filter.length > 0
            ? croppingNameExist || varietyExist || speciesExist || supplierExist
            : true) &&
          !!speciesFilterExist()
        );
      });
    } else return [];
  }, [
    croppings,
    fieldsAndCroppingsVisibility.showCroppings,
    filter,
    showOnlyActiveFieldsAndCroppings,
    showOnlyEcoCroppings,
    speciesFilter,
  ]);

  const filteredTreatments = useMemo(() => {
    if (treatments?.length > 0 && showTreatments) {
      return treatments.filter((treatment) => {
        return (
          !!(!treatment.isFinished && !treatment.isCanceled
            ? treatmentsStatusVisibility.active
            : true) &&
          !!(treatment.isFinished && !treatment.isCanceled
            ? treatmentsStatusVisibility.finished
            : true) &&
          !!(!treatment.isFinished && treatment.isCanceled
            ? treatmentsStatusVisibility.canceled
            : true)
        );
      });
    } else return [];
  }, [
    showTreatments,
    treatments,
    treatmentsStatusVisibility.active,
    treatmentsStatusVisibility.canceled,
    treatmentsStatusVisibility.finished,
  ]);

  const dispatch = useDispatch();
  const showFilters = useSelector((state) => state.maps.showFilters);
  const isMobile = useSelector((state) => state.misc.isMobile);
  const setFiltersOpen = useCallback(
    (payload) => {
      dispatch({
        type: "maps/setShowFilters",
        payload,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isMobile) setFiltersOpen(true);
    else setFiltersOpen(false);
  }, [isMobile, setFiltersOpen]);

  return (
    <>
      <Map
        line={line}
        fields={filteredFields}
        croppings={filteredCroppings}
        treatments={filteredTreatments}
        showTreatments={showTreatments}
        details={details}
        onGetFieldDetails={onGetFieldDetails}
        onGetCroppingDetails={onGetCroppingDetails}
        onGetTreatmentDetails={onGetTreatmentDetails}
        showShapesAsMarkers={showShapesAsMarkers}
      />
      <Row className="maps-menu__container">
        {!showFilters && (
          <Button
            onClick={() => setFiltersOpen(true)}
            variant="light"
            className="position-relative mt-22 mt-sm-9 mt-lg-n4 ml-3"
            data-testid="display-filters"
          >
            <i className="fa fa-filter" />
            <FormattedMessage id="GENERAL.FILTERS" />
          </Button>
        )}

        <Col md={12} lg={4} xxl={3} className="to-parent-height">
          <MapsMenu
            fields={filteredFields}
            croppings={filteredCroppings}
            species={species}
            treatmentsType={treatmentsType}
            onSpeciesFilterChange={onSpeciesFilterChange}
            onSettingTreatmentsVisibility={onSettingTreatmentsVisibility}
            getTreatments={getTreatments}
            onCenteringOnMap={onCenteringOnMap}
            onTogglingEcoCroppings={onTogglingEcoCroppings}
            showShapesAsMarkers={showShapesAsMarkers}
            setShapesAsMarkers={setShapesAsMarkers}
            setMapShapesVisibility={setMapShapesVisibility}
            filterFieldsAndCroppings={setFilter}
            onTogglingActiveFCOnly={onTogglingActiveFCOnly}
            showOnlyActiveFieldsAndCroppings={showOnlyActiveFieldsAndCroppings}
            showFieldsAndCroppings={fieldsAndCroppingsVisibility}
            showTreatments={showTreatments}
            showOnlyEcoCroppings={showOnlyEcoCroppings}
            treatmentsStatusVisibility={treatmentsStatusVisibility}
            setTreatmentsStatusVisibility={setTreatmentsStatusVisibility}
          />
        </Col>
      </Row>
    </>
  );
}
