import React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { TariffForm } from "./TariffForm";

export function TarrifGeneralDataCard({
  mode,
  formData,
  activeTariff,
  onCreatingTariff,
  onUpdatingTariff,
  tariffLevelGroups
}) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            {mode === "create" ? (
              <SVG src={toAbsoluteUrl("/media/icons/Add_Field.svg")} />
            ) : (
              <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
            )}
          </span>
          <h3 className="card-label">
            {mode === "create" ? (
              <FormattedMessage id="GENERAL.ADD_TARIFF" />
            ) : (
              <>{activeTariff.name}</>
            )}
          </h3>
        </div>
      </div>
      <div className="card-body">
        <TariffForm
          mode={mode}
          formData={formData}
          activeTariff={activeTariff}
          onCreatingTariff={onCreatingTariff}
          onUpdatingTariff={onUpdatingTariff}
          tariffLevelGroups={tariffLevelGroups}
        />
      </div>
    </div>
  );
}
