import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button as MUIButton,
  DialogActions,
} from "@material-ui/core";
import { Search } from "../../../components/Search";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AgGridReact } from "ag-grid-react";
import localePl from "../../../shared/agGridLocales/locale.pl";
import localeDe from "../../../shared/agGridLocales/locale.de";
import localeRu from "../../../shared/agGridLocales/locale.ru";
import localeEs from "../../../shared/agGridLocales/locale.es";

export function BulkStatusChangeModal({
  open,
  close,
  workers,
  onChangingStatuses,
}) {
  const intl = useIntl();
  const [workersCopy, setWorkersCopy] = useState([]);
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [gridApi, setGridApi] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [columnDefs] = useState([
    {
      field: "firstName",
      headerName: intl.formatMessage({ id: "GENERAL.FIRSTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: intl.formatMessage({ id: "GENERAL.LASTNAME" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
    },
    {
      field: "item",
      headerName: intl.formatMessage({ id: "GENERAL.LABEL" }),
      enableCellChangeFlash: false,
      sortable: true,
      minWidth: 120,
      maxWidth: 150,
    },
  ]);

  const [selectedWorkerIds, setSelectedWorkerIds] = useState([]);

  const { register, getValues } = useForm({ mode: "all" });

  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(workers));
    setWorkersCopy(copy.map((worker) => ({ ...worker, isSelected: false })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, workers]);

  const onFilteringDataset = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const onAccepting = () => {
    onChangingStatuses(selectedWorkerIds, getValues("isActive"));
    onClosingModal();
  };

  const onSelectionChanged = (event) => {
    setSelectedWorkerIds(
      event.api.getSelectedNodes().map((node) => node.data.id)
    );
  };

  const selectAll = () => {
    const ids = [];
    if (gridApi)
      gridApi.forEachNode((node) => {
        if (node.data.id && node.displayed) ids.push(node.data.id);
      });
    selectInGrid(ids);
  };

  const deselectAll = () => {
    selectInGrid([]);
  };

  const selectAllInactive = () => {
    const ids = [];
    if (gridApi)
      gridApi.forEachNode((node) => {
        if (node.data.id && !node.data.isActive && node.displayed)
          ids.push(node.data.id);
      });
    selectInGrid([...selectedWorkerIds, ...ids]);
  };

  const deselectAllInactive = () => {
    selectInGrid(
      selectedWorkerIds.filter(
        (id) =>
          !workersCopy
            .filter((worker) => !worker.isActive)
            .map((worker) => worker.id)
            .includes(id)
      )
    );
  };

  const selectAllActive = () => {
    const ids = [];
    if (gridApi)
      gridApi.forEachNode((node) => {
        if (node.data.id && node.data.isActive && node.displayed)
          ids.push(node.data.id);
      });
    selectInGrid([...selectedWorkerIds, ...ids]);
  };

  const deselectAllActive = () => {
    selectInGrid(
      selectedWorkerIds.filter(
        (id) =>
          !workersCopy
            .filter((worker) => worker.isActive)
            .map((worker) => worker.id)
            .includes(id)
      )
    );
  };

  const anyWorkerSelected = useMemo(() => {
    return {
      all:
        !!workersCopy.filter((worker) => {
          return selectedWorkerIds.includes(worker.id);
        }).length > 0,
      inactive:
        !!workersCopy.filter((worker) => {
          return selectedWorkerIds.includes(worker.id) && !worker.isActive;
        }).length > 0,
      active:
        !!workersCopy.filter((worker) => {
          return selectedWorkerIds.includes(worker.id) && worker.isActive;
        }).length > 0,
    };
  }, [selectedWorkerIds, workersCopy]);

  const onClosingModal = () => {
    setSelectedWorkerIds([]);
    setGridApi(null);
    close();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getLocale = () => {
    if (languageCode === "pl") return localePl;
    if (languageCode === "de") return localeDe;
    if (languageCode === "ru") return localeRu;
    if (languageCode === "es") return localeEs;
    return null;
  };

  const getRowClass = (params) => {
    if (!params.data.isActive) {
      return "row__factory";
    }
  };

  useEffect(() => {
    if (gridApi && open) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi, open]);

  const selectInGrid = useCallback(
    (ids) => {
      if (gridApi)
        gridApi.forEachNode((node) => {
          if (ids.includes(node.data.id)) node.setSelected(true);
          else node.setSelected(false);
        });
    },
    [gridApi]
  );

  const onFilterChanged = (event) => {
    if (event.api.getDisplayedRowCount() === 0) setBtnDisabled(true);
    else setBtnDisabled(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClosingModal}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      className="brigadier-theme"
    >
      <DialogTitle id="scroll-dialog-title">
        <FormattedMessage id="GENERAL.BULK_STATUS_CHANGE" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          <FormattedMessage id="GENERAL.BULK_STATUS_CHANGE_MESSAGE" />.
        </DialogContentText>
        <Form>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>
                <FormattedMessage id="GENERAL.STATUS" /> *
              </Form.Label>
              <Form.Control
                as="select"
                name="isActive"
                ref={register({ required: true })}
              >
                {status.map((stat) => (
                  <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                    {(message) => <option value={stat.value}>{message}</option>}
                  </FormattedMessage>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
        <Row>
          <Col xs={12} className="mb-2">
            <Search onChange={onFilteringDataset} />
          </Col>
          <Col xs={12} className="mb-4">
            <div
              className="ag-theme-alpine"
              style={{ height: window.innerHeight * 0.4, width: "auto" }}
            >
              <AgGridReact
                rowData={workersCopy}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                localeText={getLocale()}
                rowHeight={24}
                pivotMode="false"
                suppressPivotMode
                suppressReactUi
                rowClass="ag-row-selectable"
                getRowClass={getRowClass}
                rowSelection="multiple"
                rowMultiSelectWithClick
                onSelectionChanged={(e) => onSelectionChanged(e)}
                suppressCellFocus
                suppressScrollOnNewData
                suppressRowVirtualisation
                onFilterChanged={onFilterChanged}
              />
            </div>
          </Col>
          <Col xs={12} className="mb-3">
            <Button
              onClick={() =>
                !anyWorkerSelected.all ? selectAll() : deselectAll()
              }
              disabled={
                (selectedWorkerIds.length === 0 && btnDisabled) ||
                workersCopy.length === 0
              }
            >
              {!anyWorkerSelected.all ? (
                <FormattedMessage id="GENERAL.SELECT_ALL" />
              ) : (
                <FormattedMessage id="GENERAL.DESELECT_ALL" />
              )}
            </Button>
          </Col>
          <Col xs={12} className="mb-3">
            <Button
              onClick={() =>
                !anyWorkerSelected.inactive
                  ? selectAllInactive()
                  : deselectAllInactive()
              }
              disabled={
                (selectedWorkerIds.length === 0 && btnDisabled) ||
                workersCopy.length === 0
              }
            >
              {!anyWorkerSelected.inactive ? (
                <FormattedMessage id="GENERAL.SELECT_ALL_INACTIVE" />
              ) : (
                <FormattedMessage id="GENERAL.DESELECT_ALL_INACTIVE" />
              )}
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              onClick={() =>
                !anyWorkerSelected.active
                  ? selectAllActive()
                  : deselectAllActive()
              }
              disabled={
                (selectedWorkerIds.length === 0 && btnDisabled) ||
                workersCopy.length === 0
              }
            >
              {!anyWorkerSelected.active ? (
                <FormattedMessage id="GENERAL.SELECT_ALL_ACTIVE" />
              ) : (
                <FormattedMessage id="GENERAL.DESELECT_ALL_ACTIVE" />
              )}
            </Button>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <MUIButton
          type="submit"
          color="primary"
          disabled={!anyWorkerSelected.all}
          onClick={() => onAccepting()}
        >
          <FormattedMessage id="GENERAL.SAVE" />
        </MUIButton>
        <MUIButton color="default" onClick={() => onClosingModal()}>
          <FormattedMessage id="GENERAL.CANCEL" />
        </MUIButton>
      </DialogActions>
    </Dialog>
  );
}
