import React, { useState, useEffect, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function TariffForm({
  mode,
  formData,
  activeTariff,
  onCreatingTariff,
  onUpdatingTariff,
  tariffLevelGroups,
}) {
  const intl = useIntl();
  const [convertersLoaded, setConvertersLoaded] = useState(false);
  const {
    register,
    errors,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    triggerValidation,
    control,
    watch,
  } = useForm({ mode: "onChange" });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "converters",
    }
  );
  const { touched, submitCount } = formState;
  const [status] = useState([
    { id: 1, name: "ACTIVE", value: true },
    { id: 2, name: "INACTIVE", value: false },
  ]);
  const [loading, setLoading] = useState(false);
  const [uomBlocked, setUomBlocked] = useState(false);
  const numberDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "DECIMAL_PLACE"
      )?.numericValue
  );
  const numberDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "DECIMAL_PLACE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });
  const rateDecimalValue = useSelector(
    (state) =>
      state.brigadierSettingsParameters?.find(
        (param) => param.parameter.symbol === "RATE"
      )?.numericValue
  );
  const rateDecimalStep = useSelector((state) => {
    const stateValue = state.brigadierSettingsParameters?.find(
      (param) => param.parameter.symbol === "RATE"
    )?.numericValue;
    if (stateValue != null && stateValue === 0) return 1;
    if (stateValue === 1) return 0.1;
    if (stateValue === 2) return 0.01;
    if (stateValue === 3) return 0.001;
    else return 0.01;
  });

  useEffect(() => {
    setConvertersLoaded(false);
    setUomBlocked(false);
    if (mode === "create") reset();
    else {
      remove();
      setFormParameters();
    }
  }, [activeTariff, mode]);

  useEffect(() => {
    if (
      activeTariff &&
      activeTariff.converters?.length > 0 &&
      fields?.length === 0 &&
      !convertersLoaded
    ) {
      append(
        activeTariff.converters.map((con) => ({
          factor: con.factor,
          isDefault: con.isDefault,
          uomIdFrom: con.uomFrom.id,
          uomIdTo: con.uomTo.id,
        }))
      );
      setConvertersLoaded(true);
    }
  }, [activeTariff, append, fields?.length]);

  const onSubmit = (data) => {
    setLoading(true);
    mode === "create" ? createTariff(data) : updateTariff(data);
  };

  const setFormParameters = () => {
    setValue("name", activeTariff.name);
    setValue("isActive", activeTariff.isActive);
    setValue("value", activeTariff.value);
    setValue("currencyId", activeTariff.currencyId);
    setValue("tariffTypeId", activeTariff.tariffTypeId);
    setValue("uomId", activeTariff.uomId);
    if (activeTariff.tariffTypeValue === "HOURLY") {
      setUomBlocked(true);
      setHourUOM();
    }
    triggerValidation();
  };

  const isTariffInBonusOrGroup = useMemo(() => {
    if (
      tariffLevelGroups?.length > 0 &&
      ((activeTariff?.tariffId &&
        tariffLevelGroups.find((group) =>
          group.tariffIds.includes(activeTariff.tariffId)
        )) ||
        activeTariff?.hasDefaultRate)
    )
      return true;
    else return false;
  }, [activeTariff?.hasDefaultRate, activeTariff?.tariffId, tariffLevelGroups]);

  const onSelectingTariffType = (e) => {
    const searchedTariffType = formData.types.find((type) => {
      return type.id === parseInt(e.currentTarget.value);
    });
    if (searchedTariffType && searchedTariffType.symbol === "HOURLY") {
      setUomBlocked(true);
      setHourUOM();
    } else setUomBlocked(false);
  };

  const setHourUOM = () => {
    const hourUOM = formData?.uom?.find((uom) => {
      return uom.symbol === "h";
    });
    if (hourUOM) setValue("uomId", hourUOM.id);
  };

  async function createTariff(form) {
    const callback = await onCreatingTariff(form);
    if (callback) {
      setLoading(false);
    }
  }

  async function updateTariff(form) {
    const callback = await onUpdatingTariff(activeTariff.tariffId, form);
    if (callback) {
      setLoading(false);
    }
  }

  const addConverter = () => {
    append({
      factor: 0,
      isDefault: fields.length === 0 ? true : false,
      uomIdFrom: 0,
      uomIdTo: 0,
    });
  };

  const removeConverter = (index) => {
    if (fields.length > 1) {
      if (getValues(`converters[${index}].isDefault`) === true) {
        displayFeedback({
          type: "error",
          message: `${intl.formatMessage({
            id: "ERROR.CANNOT_DELETE_PRIMARY_MEASUREMENT_2+_CONVERTERS",
          })}`,
        });
      } else remove(index);
    } else remove(index);
  };

  const uomId = watch("uomId");

  const clearCheckboxes = (checkedIndex) => {
    fields.forEach((field, index) => {
      if (index !== checkedIndex)
        setValue(`converters[${index}].isDefault`, false);
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.NAME" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="name"
                type="text"
                className={`${
                  (touched?.name && getValues("name").length === 0) ||
                  (submitCount >= 1 && getValues("name")?.length === 0) ||
                  errors.name ||
                  (touched?.name && getValues("name").length >= 51) ||
                  (submitCount >= 1 && getValues("name")?.length >= 51)
                    ? "is-invalid"
                    : ""
                } ${
                  !errors.name && getValues("name")?.length >= 1
                    ? "is-valid"
                    : ""
                }`}
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.NAME",
                })}`}
                ref={register({ required: true, maxLength: 50 })}
              />
            </InputGroup>
            {(errors.name?.required ||
              (touched?.name && getValues("name").length === 0) ||
              (submitCount >= 1 && getValues("name")?.length === 0)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {(errors.name?.maxLength ||
              (touched?.name && getValues("name").length >= 51) ||
              (submitCount >= 1 && getValues("name")?.length >= 51)) && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_NAME_LENGTH" />
              </div>
            )}
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.STATUS" /> *
            </Form.Label>
            <Form.Control
              as="select"
              name="isActive"
              ref={register({ required: true })}
            >
              {status.map((stat) => (
                <FormattedMessage id={`GENERAL.${stat.name}`} key={stat.id}>
                  {(message) => <option value={stat.value}>{message}</option>}
                </FormattedMessage>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="BRIGADIER.RATE" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="value"
                type="number"
                className={`${errors.value ? "is-invalid" : ""} ${
                  !errors.value ? "is-valid" : ""
                }`}
                placeholder={`${intl.formatMessage({
                  id: "BRIGADIER.RATE",
                })}`}
                step={rateDecimalStep}
                ref={register({
                  required: false,
                  min: rateDecimalStep,
                  max: 1000,
                  validate: (value) => {
                    const split = value.toString().split(".");
                    if (split.length > 1) {
                      if (split[1].length > rateDecimalValue) return false;
                      else return true;
                    } else return true;
                  },
                })}
              />
            </InputGroup>
            {errors.value?.type === "min" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                {` ${rateDecimalStep}`}
              </div>
            )}
            {errors.value?.type === "max" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000" />
              </div>
            )}
            {errors.value?.type === "validate" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="BRIGADIER.RATE" />:{" "}
                <FormattedMessage
                  id={`ERROR.MAX_${rateDecimalValue}_DECIMAL_PLACES`}
                />
              </div>
            )}
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CURRENCY" /> *
            </Form.Label>
            <Form.Control
              as="select"
              name="currencyId"
              ref={register({ required: true })}
              disabled={isTariffInBonusOrGroup}
            >
              {formData && (
                <>
                  {formData.currencies.map((currency) => (
                    <option key={`currency-${currency.id}`} value={currency.id}>
                      {currency.name}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.TARIFF_TYPE" /> *
            </Form.Label>
            <Form.Control
              as="select"
              name="tariffTypeId"
              ref={register({ required: true })}
              onChange={(e) => onSelectingTariffType(e)}
              disabled={isTariffInBonusOrGroup}
            >
              {formData && (
                <>
                  {formData.types.map((type) => (
                    <FormattedMessage
                      id={`GENERAL.${type.symbol}`}
                      key={`type-${type.id}`}
                    >
                      {(message) => <option value={type.id}>{message}</option>}
                    </FormattedMessage>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>
              <FormattedMessage id="GENERAL.MEASUREMENT_UNIT" /> *
            </Form.Label>
            <Form.Control
              as="select"
              name="uomId"
              ref={register({ required: true })}
              disabled={uomBlocked || isTariffInBonusOrGroup}
            >
              {formData && (
                <>
                  {formData.uom.map((unit) => (
                    <option key={`unit-${unit.id}`} value={unit.id}>
                      {unit.desc}
                    </option>
                  ))}
                </>
              )}
            </Form.Control>
          </Form.Group>
          {/* <Form.Group as={Col} md={12}>
          <div>
            <span className="text--bold">
              <FormattedMessage id="GENERAL.LEVEL" />
            </span>
          </div>
          <div className="separator separator-dashed mt-5 mb-5" />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.VALUE" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="levelValue"
              type="number"
                            className={`${errors.levelValue ? "is-invalid" : ""} ${
                !errors.levelValue ? "is-valid" : ""
              }`}
              placeholder={`${intl.formatMessage({
                id: "GENERAL.VALUE",
              })}`}
              step="0.01"
              ref={register({ required: false, min: 1, max: 1000 })}
            />
          </InputGroup>
                    {errors.levelValue?.type === "min" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN_1" />
            </div>
          )}
          {errors.levelValue?.type === "max" && (
            <div
              className="error invalid-feedback"
              style={{ display: "block" }}
            >
              <FormattedMessage id="ERROR.VALUE_MUST_BE_LESS_THAN_1000" />
            </div>
          )}
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.INFIMUM" />
          </Form.Label>
          <InputGroup>
            <Form.Control
              name="infimum"
              type="number"
              placeholder={`${intl.formatMessage({
                id: "GENERAL.INFIMUM",
              })}`}
              step="0.01"
              ref={register({ required: false })}
            />
          </InputGroup>
        </Form.Group> */}
          <Col md={12} className="mb-4">
            <div className="d-flex align-items-center">
              <h6 className="mr-4 my-0">
                <FormattedMessage id="BRIGADIER.UNIT_CONVERTER" />
              </h6>
              <Button size="sm" onClick={addConverter}>
                <i className="fa fa-plus pr-0" />
              </Button>
            </div>
          </Col>
          {fields.map((field, index) => (
            <Col md={12} key={field.id}>
              <Form.Row>
                <Col md={4} className="d-flex align-items-end">
                  <div className="d-flex align-items-end">
                    <Form.Group style={{ maxWidth: "8rem" }} className="mr-3">
                      <Form.Label>
                        <FormattedMessage id="GENERAL.QUANTITY" />
                      </Form.Label>
                      <Form.Control
                        name={`converters[${index}].factorFrom`}
                        type="number"
                        value={1}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mr-3">
                      <Form.Label>
                        <FormattedMessage id="BRIGADIER.BASIC_MEASUREMENT" />
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name={`converters[${index}].uomIdFrom`}
                        ref={register({ required: true })}
                        disabled
                        value={uomId}
                      >
                        {formData && (
                          <>
                            {formData.uom.map((unit) => (
                              <option key={`unit-${unit.id}`} value={unit.id}>
                                {unit.desc}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mr-3">
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.25rem",
                          marginBottom: ".5rem",
                          display: "block",
                        }}
                      >
                        =
                      </span>
                    </Form.Group>
                  </div>
                </Col>
                <Col md={8} className="d-flex align-items-end">
                  <div className="d-flex align-items-end flex-wrap flex-md-nowrap">
                    <Form.Group style={{ maxWidth: "8rem" }} className="mr-3">
                      <Form.Label>
                        <FormattedMessage id="GENERAL.QUANTITY" /> *
                      </Form.Label>
                      <Form.Control
                        name={`converters[${index}].factor`}
                        type="number"
                        min={numberDecimalStep}
                        step={numberDecimalStep}
                        className={`${
                          errors.converters && errors.converters[index]?.factor
                            ? "is-invalid"
                            : ""
                        }`}
                        ref={register({
                          required: true,
                          min: numberDecimalStep,
                          validate: (value) => {
                            const split = value.toString().split(".");
                            if (split.length > 1) {
                              if (split[1].length > numberDecimalValue)
                                return false;
                              else return true;
                            } else return true;
                          },
                        })}
                        defaultValue={field.factor ? field.factor : 1}
                      />
                    </Form.Group>
                    <Form.Group className="mr-3">
                      <Form.Label>
                        <FormattedMessage id="BRIGADIER.ADDITIONAL_MEASUREMENT" />{" "}
                        *
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name={`converters[${index}].uomIdTo`}
                        ref={register({ required: true })}
                        defaultValue={field.uomIdTo}
                      >
                        {formData && (
                          <>
                            {formData.uom.map((unit) => (
                              <option key={`unit-${unit.id}`} value={unit.id}>
                                {unit.desc}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="d-flex flex-column align-items-center mr-5">
                      <Form.Label>
                        <FormattedMessage id="BRIGADIER.PRIMARY_MEASUREMENT" />
                      </Form.Label>
                      <Controller
                        as={Checkbox}
                        control={control}
                        name={`converters[${index}].isDefault`}
                        defaultValue={field.isDefault}
                        onChange={([e]) => {
                          clearCheckboxes(index);
                          return e;
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button
                        size="sm"
                        onClick={() => removeConverter(index)}
                        variant="danger"
                      >
                        <i
                          className="fa fa-trash pr-0"
                          style={{ fontSize: "1rem" }}
                        />
                      </Button>
                    </Form.Group>
                  </div>
                </Col>
              </Form.Row>
              <div className="mt-n5 mb-12 mb-md-5">
                {errors.converters &&
                  errors.converters[index]?.factor?.type === "min" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                      <FormattedMessage id="ERROR.VALUE_MUST_BE_GREATER_THAN" />{" "}
                      {` ${numberDecimalStep}`}
                    </div>
                  )}
                {errors.converters &&
                  errors.converters[index]?.factor?.type === "validate" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                      <FormattedMessage
                        id={`ERROR.MAX_${numberDecimalValue}_DECIMAL_PLACES`}
                      />
                    </div>
                  )}
                {errors.converters &&
                  errors.converters[index]?.factor?.type === "required" && (
                    <div
                      className="error invalid-feedback"
                      style={{ display: "block" }}
                    >
                      <FormattedMessage id="GENERAL.QUANTITY" />:{" "}
                      <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
                    </div>
                  )}
              </div>
            </Col>
          ))}
          <Col md={12} className="text-right">
            <Button
              type="submit"
              disabled={loading || Object.keys(errors).length !== 0}
            >
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
}
