import React, { useState } from "react";
import { useIntl } from "react-intl";
import useEffectOnce from "../../../hooks/useEffectOnce";
import errorHandler from "../../../shared/errorHandler";
import {
  getResultsFormData,
  getAllResults,
  createResultToEmployee,
  updateResults,
  deactivateResult,
  getResultToEmployeesExcel,
} from "../../../services/brigadier/resultsCrud";
import {
  ResultsTableCard,
  AddOrCopyResultModal,
  ModifyResultsModal,
  RemoveResultsModal,
  ExportResultsModal,
  BulkEditResultsModal,
} from "../index";
import { Row, Col } from "react-bootstrap";
import displayFeedback from "../../../components/helpers/displayFeedback";

export function ResultsEditingPage() {
  const intl = useIntl();
  const [formData, setFormData] = useState();
  const [results, setResults] = useState([]);
  const [lastForm, setLastForm] = useState();
  const [addOrCopyResultModalVisible, setAddOrCopyResultModalVisible] = useState(false);
  const [addOrCopyResultModalCopyMode, setAddOrCopyResultModalCopyMode] = useState(false);
  const [modifyResultsModalVisible, setModifyResultsModalVisible] = useState(
    false
  );
  const [bulkEditResultsModalVisible, setBulkEditResultsModalVisible] = useState(
    false
  );
  const [removeResultsModalVisible, setRemoveResultsModalVisible] = useState(
    false
  );
  const [exportResultsModalVisible, setExportResultsModalVisible] = useState(
    false
  );
  const [resetFilter, setResetFilter] = useState(false);

  function getFormData() {
    getResultsFormData()
      .then((response) => {
        const data = response.data;
        setFormData(data);
        getResults({
          croppingId: null,
          currencyId: null,
          dateFrom: new Date(),
          dateTo: new Date(),
          employeeId: null,
          employee: null,
          localizationId: null,
          loginId: null,
          tariffId: null,
        });
      })
      .catch((error) => {
        const errorOptions = errorHandler(error);
        if (errorOptions.type === "error") {
          displayFeedback({
            type: errorOptions.type,
            message: `${intl.formatMessage({ id: errorOptions.message })}`,
          });
        }
      });
  }

  function getResults(form) {
    setLastForm(form);
    return new Promise((resolve) => {
      getAllResults(form)
        .then((response) => {
          const newResults = response.data;
          if (newResults.length) {
            newResults.forEach((result) => {
              result.isSelected = false;
            });
          }
          setResults(newResults);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  }

  const onSelectingRow = (id) => {
    const newResults = [...results];
    newResults.forEach((result) => {
      if (result.__resultId === id) {
        result.isSelected = !result.isSelected;
      }
    });
    setResults(newResults);
  };

  const onSelectingOrDeselectingAll = (select) => {
    setTimeout(() => {
      const newResults = [...results];
      newResults.forEach((result) => {
        result.isSelected = !select;
      });
      setResults(newResults);
    }, 200);
  };

  const onClosingOpeningAddingOrCopyingResultModal = (copyMode) => {
    setAddOrCopyResultModalVisible(!addOrCopyResultModalVisible);
    if (copyMode) setAddOrCopyResultModalCopyMode(true)
    else setTimeout(() => {
      setAddOrCopyResultModalCopyMode(false)
    }, 150);
  };

  const onClosingOpeningUpdatingResultsModal = () => {
    setModifyResultsModalVisible(!modifyResultsModalVisible);
  };

  const onClosingOpeningBulkEditResultsModal = () => {
    setBulkEditResultsModalVisible(!bulkEditResultsModalVisible);
  };

  const onClosingOpeningRemovingResultsModal = () => {
    setRemoveResultsModalVisible(!removeResultsModalVisible);
  };

  const onClosingOpeningExportingResultsModal = () => {
    setExportResultsModalVisible(!exportResultsModalVisible);
  };

  const onAddingResult = (form) => {
    const employeeId = form.employeeId;
    form.employeeId = null;
    return new Promise((resolve) => {
      createResultToEmployee(employeeId, form)
        .then(() => {
          getResults(lastForm);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onUpdatingResults = (form, isBulk) => {
    const resultIds = [...results]
      .filter((result) => {
        return result.isSelected;
      })
      .map((result) => {
        return result.__resultId;
      });
    let hourlyTariffs;
    if (isBulk) hourlyTariffs = [...results].filter((result) => {
      return result.isSelected && result.__tarifTypeValue === "HOURLY";
    })
    const newForm = {
      resultIds: resultIds,
      result: hourlyTariffs?.length > 0 ? {
        ...form,
        hours: form.quantity,
        dateTo: form.registerAt,
      } : form
    };
    return new Promise((resolve) => {
      updateResults(newForm)
        .then(() => {
          getResults(lastForm);
          setResetFilter(true);
          setTimeout(() => {
            setResetFilter(false);
          });
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onRemovingResults = () => {
    const resultIds = [...results]
      .filter((result) => {
        return result.isSelected;
      })
      .map((result) => {
        return result.__resultId;
      });
    return new Promise((resolve) => {
      deactivateResult(resultIds)
        .then(() => {
          getResults(lastForm);
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  const onExportingResults = () => {
    return new Promise((resolve) => {
      getResultToEmployeesExcel(lastForm)
        .then((response) => {
          const fileName = `results-${new Date().toISOString()}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          resolve("OK");
        })
        .catch((error) => {
          const errorOptions = errorHandler(error);
          if (errorOptions.type === "error") {
            displayFeedback({
              type: errorOptions.type,
              message: `${intl.formatMessage({ id: errorOptions.message })}`,
            });
            resolve("error");
          }
        });
    });
  };

  useEffectOnce(getFormData);

  return (
    <Row>
      <AddOrCopyResultModal
        open={addOrCopyResultModalVisible}
        formData={formData}
        close={onClosingOpeningAddingOrCopyingResultModal}
        onAddingResult={onAddingResult}
        results={results}
        copyMode={addOrCopyResultModalCopyMode}
      />
      <ModifyResultsModal
        open={modifyResultsModalVisible}
        formData={formData}
        results={results}
        close={onClosingOpeningUpdatingResultsModal}
        onUpdatingResults={onUpdatingResults}
      />
      <BulkEditResultsModal
        open={bulkEditResultsModalVisible}
        formData={formData}
        results={results}
        close={onClosingOpeningBulkEditResultsModal}
        onUpdatingResults={onUpdatingResults}
      />
      <RemoveResultsModal
        open={removeResultsModalVisible}
        close={onClosingOpeningRemovingResultsModal}
        onRemovingResults={onRemovingResults}
      />
      <ExportResultsModal
        open={exportResultsModalVisible}
        close={onClosingOpeningExportingResultsModal}
        onExportingResults={onExportingResults}
      />
      <Col md={12}>
        <ResultsTableCard
          results={results}
          formData={formData}
          resetFilter={resetFilter}
          onGettingResults={getResults}
          onSelectingRow={onSelectingRow}
          onSelectingOrDeselectingAll={onSelectingOrDeselectingAll}
          onClosingOpeningAddingResultModal={onClosingOpeningAddingOrCopyingResultModal}
          onClosingOpeningUpdatingResultsModal={
            onClosingOpeningUpdatingResultsModal
          }
          onClosingOpeningBulkEditResultsModal={
            onClosingOpeningBulkEditResultsModal
          }
          onClosingOpeningRemovingResultsModal={
            onClosingOpeningRemovingResultsModal
          }
          onClosingOpeningExportingResultsModal={
            onClosingOpeningExportingResultsModal
          }
        />
      </Col>
    </Row>
  );
}

export default ResultsEditingPage;
