import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

export function ChangePassword({ onChangingPassword }) {
  const intl = useIntl();
  const {
    register,
    getValues,
    triggerValidation,
    errors,
    formState,
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange" });
  const { touched, submitCount } = formState;
  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    delete data.passwordConfirmation;
    setLoading(true);
    const callback = await onChangingPassword(data);
    if (callback === "OK") {
      setLoading(false);
      reset();
    } else {
      setLoading(false);
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.CURRENT_PASSWORD" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="currentPassword"
                type="password"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.CURRENT_PASSWORD",
                })}`}
                className={`${
                  formState.touched.currentPassword && errors.currentPassword
                    ? "is-invalid"
                    : ""
                }${
                  formState.touched.currentPassword && !errors.currentPassword
                    ? "is-valid"
                    : ""
                }`}
                ref={register({ required: true, minLength: 8 })}
              />
            </InputGroup>
            {errors.currentPassword?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.currentPassword?.type === "minLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_PASSWORD_LENGTH" />
              </div>
            )}
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.NEW_PASSWORD" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="newPassword"
                type="password"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.NEW_PASSWORD",
                })}`}
                className={`${
                  formState.touched.newPassword && errors.newPassword
                    ? "is-invalid"
                    : ""
                }${
                  formState.touched.newPassword && !errors.newPassword
                    ? "is-valid"
                    : ""
                }`}
                ref={register({
                  required: true,
                  minLength: 8,
                  validate: (value) => {
                    if (
                      (value === getValues()["passwordConfirmation"] &&
                        value.length >= 8) ||
                      (getValues()["passwordConfirmation"].length &&
                        value.length >= 8)
                    ) {
                      triggerValidation("passwordConfirmation");
                      return true;
                    } else if (value.length >= 8) {
                      return true;
                    }
                  },
                })}
              />
            </InputGroup>
            {errors.newPassword?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.newPassword?.type === "minLength" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_PASSWORD_LENGTH" />
              </div>
            )}
          </Form.Group>
          <Form.Group as={Col} md={12}>
            <Form.Label>
              <FormattedMessage id="GENERAL.REPEAT_NEW_PASSWORD" /> *
            </Form.Label>
            <InputGroup>
              <Form.Control
                name="passwordConfirmation"
                type="password"
                placeholder={`${intl.formatMessage({
                  id: "PLACEHOLDER.RE_ENTER_YOUR_NEW_PASSWORD",
                })}`}
                className={`${
                  formState.touched.passwordConfirmation &&
                  errors.passwordConfirmation
                    ? "is-invalid"
                    : ""
                }${
                  formState.touched.passwordConfirmation &&
                  !errors.passwordConfirmation
                    ? "is-valid"
                    : ""
                }`}
                ref={register({
                  required: true,
                  validate: (value) => {
                    return value === getValues()["newPassword"];
                  },
                })}
              />
            </InputGroup>
            {errors.passwordConfirmation?.type === "required" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="GENERAL.FIELD_IS_REQUIRED" />
              </div>
            )}
            {errors.passwordConfirmation?.type === "validate" && (
              <div
                className="error invalid-feedback"
                style={{ display: "block" }}
              >
                <FormattedMessage id="ERROR.INCORRECT_PASSWORDS" />
              </div>
            )}
          </Form.Group>
          <div className="col-xxl-12 text-right">
            <Button type="submit" disabled={loading}>
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>
          </div>
        </Form.Row>
      </Form>
    </div>
  );
}
