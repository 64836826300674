import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { getOnboardings, getWatchedOnboardings, setOnboardingAsWatched } from "../../../services/onboardingsCrud"
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function TutorialModal() {
  const [open, setOpen] = useState(true)
  const [onboardings, setOnboardings] = useState([])
  const [foundOnboarding, setFoundOnboarding] = useState({})
  const location = useLocation()
  const showTutorials = useSelector(state => state.auth.user.showTutorials)

  const close = () => {
    setOpen(false)
    setOnboardingAsWatched(foundOnboarding.id)
    setTimeout(() => {
      setFoundOnboarding({})
    }, 100);
  }

  useEffect(() => {
    getOnboardings()
      .then((response) => {
        setOnboardings(response.data)
      })
  }, [])

  const getVideoId = (url) => {
    if (url) return new URL(url).searchParams.get("v")
  }

  useEffect(() => {
    if (location.pathname !== "/" && onboardings.length > 0 && showTutorials) {
      const foundOnboarding = onboardings.find(onboarding => onboarding.path === location.pathname)
      if (foundOnboarding) {
        getWatchedOnboardings()
          .then((response) => {
            const isWatched = !!response.data.find(id => id === foundOnboarding.id)
            if (!isWatched) {
              setFoundOnboarding(foundOnboarding)
              setOpen(true)
            } 
          })
      }
    }
  }, [location, onboardings, showTutorials])

  return (
    <Dialog
      open={open && !!Object.keys(foundOnboarding).length > 0}
      onClose={close}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title-selected-video"
      maxWidth={false}
    >
      {Object.keys(foundOnboarding).length > 0 && (
        <>
          <DialogTitle id="scroll-dialog-title-selected-video">
            <span className="mui-dialog-popup__title">
              {foundOnboarding.name}
            </span>
            <button className="bg-transparent border-0 mui-dialog-popup__close-btn" onClick={close}>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
            </button>
          </DialogTitle>
          <DialogContent>
            <div className="video-popup-wrapper">
              <iframe
                src={`https://www.youtube.com/embed/${getVideoId(foundOnboarding.source)}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
                title={foundOnboarding.name}
              ></iframe>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
