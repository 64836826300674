import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export function CroppingTreatmentsTable({
  historicalTreatments,
  onFinishingTreatment,
  onCancelingTreatment,
  onActivatingTreatment
}) {
  const [loading, setLoading] = useState(false);

  async function finishTreatment(eventId) {
    setLoading(true);
    const callback = await onFinishingTreatment(eventId);
    if (callback === ("OK" || "error")) {
      setLoading(false);
    }
  }

  async function cancelTreatment(eventId) {
    setLoading(true);
    const callback = await onCancelingTreatment(eventId);
    if (callback === ("OK" || "error")) {
      setLoading(false);
    }
  }

  async function activateTreatment(eventId) {
    setLoading(true);
    const callback = await onActivatingTreatment(eventId);
    if (callback === ("OK" || "error")) {
      setLoading(false);
    }
  }

  return (
    <>
      {historicalTreatments.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr className="text-center">
              <th>
                <FormattedMessage id="GENERAL.DATE_FROM" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.DATE_TO" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.SOIL_CULTIVATION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PLANTING" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.IRRIGATION_TREATMENT" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.FERTILIZATION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.PLANT_PROTECTION" />
              </th>
              <th>
                <FormattedMessage id="GENERAL.ACTIONS" />
              </th>
            </tr>
          </thead>
          <tbody>
            {historicalTreatments.map((treatment) => (
              <tr
                key={treatment.treatmentHeaderId}
                className={`text-center ${
                  treatment.isFinished ? "offer--active" : ""
                } ${treatment.isCanceled ? "row__factory" : ""}`}
              >
                <td>{moment(treatment.dateStart).format("YYYY-MM-DD")}</td>
                <td>{moment(treatment.dateEnd).format("YYYY-MM-DD")}</td>

                <td>
                  {treatment.isSoilCultivation ? (
                    <i
                      className="flaticon2-check-mark"
                      style={{ padding: "none", color: "#1BC5BD" }}
                    />
                  ) : (
                    <i
                      className="flaticon2-check-mark text--muted"
                      style={{ padding: "none" }}
                    />
                  )}
                </td>
                <td>
                  {treatment.isPlanting ? (
                    <i
                      className="flaticon2-check-mark"
                      style={{ padding: "none", color: "#1BC5BD" }}
                    />
                  ) : (
                    <i
                      className="flaticon2-check-mark text--muted"
                      style={{ padding: "none" }}
                    />
                  )}
                </td>
                <td>
                  {treatment.isIrrigation ? (
                    <i
                      className="flaticon2-check-mark"
                      style={{ padding: "none", color: "#1BC5BD" }}
                    />
                  ) : (
                    <i
                      className="flaticon2-check-mark text--muted"
                      style={{ padding: "none" }}
                    />
                  )}
                </td>
                <td>
                  {treatment.isFertilization ? (
                    <i
                      className="flaticon2-check-mark"
                      style={{ padding: "none", color: "#1BC5BD" }}
                    />
                  ) : (
                    <i
                      className="flaticon2-check-mark text--muted"
                      style={{ padding: "none" }}
                    />
                  )}
                </td>
                <td>
                  {treatment.isPlantProtection ? (
                    <i
                      className="flaticon2-check-mark"
                      style={{ padding: "none", color: "#1BC5BD" }}
                    />
                  ) : (
                    <i
                      className="flaticon2-check-mark text--muted"
                      style={{ padding: "none" }}
                    />
                  )}
                </td>
                <td>
                  {treatment.isFinished === false &&
                    treatment.isCanceled === false && (
                      <div className="d-flex flex-column">
                        <Button
                          type="button"
                          variant="primary"
                          disabled={loading}
                          onClick={() => finishTreatment(treatment.eventId)}
                          className="mb-2"
                        >
                          <FormattedMessage id="GENERAL.FINISH" />
                        </Button>
                        <Button
                          type="button"
                          variant="secondary"
                          disabled={loading}
                          onClick={() => cancelTreatment(treatment.eventId)}
                        >
                          <FormattedMessage id="GENERAL.CANCEL" />
                        </Button>
                      </div>
                    )}
                  {treatment.isFinished === true && (
                    <div className="d-flex flex-column">
                      <span className="text--bold mb-2">
                        <FormattedMessage id="GENERAL.TREATMENT_HAS_BEEN_FINISHED" />
                      </span>
                      <Button
                        type="button"
                        variant="secondary"
                        disabled={loading}
                        onClick={() => activateTreatment(treatment.eventId)}
                      >
                        <FormattedMessage id="GENERAL.ACTIVATE" />
                      </Button>
                    </div>
                  )}
                  {treatment.isCanceled === true && (
                    <div className="d-flex flex-column">
                      <span className="text--bold mb-2">
                        <FormattedMessage id="GENERAL.TREATMENT_HAS_BEEN_CANCELED" />
                      </span>
                      <Button
                        type="button"
                        variant="secondary"
                        disabled={loading}
                        onClick={() => activateTreatment(treatment.eventId)}
                      >
                        <FormattedMessage id="GENERAL.ACTIVATE" />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <h6>
            <FormattedMessage id="GENERAL.NO_HISTORICAL_TREATMENTS" />
          </h6>
        </div>
      )}
    </>
  );
}
