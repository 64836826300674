import React, { useEffect, useState, useCallback, useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Row, Col, Button } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import PDFExportButton from "./pdfExport/PDFExportButton";
import localePl from "../../../shared/agGridLocales/locale.pl";
import localeDe from "../../../shared/agGridLocales/locale.de";
import localeRu from "../../../shared/agGridLocales/locale.ru";
import localeEs from "../../../shared/agGridLocales/locale.es";

export function Preview({
  selectedReportType,
  reportData,
  onExportingReportToXLS,
}) {
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const pdfDecimalPlaces = useRef(null);

  const intl = useIntl();
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  useEffect(() => {
    if (reportData?.result.length) {
      onCreatingDataset();
    } else {
      setCols([]);
      setRows([]);
    }
  }, [reportData]);

  const onCreatingDataset = () => {
    const newResults = JSON.parse(JSON.stringify(reportData.result));
    createCols(newResults[0]);
    setRows(newResults);
    pdfDecimalPlaces.current = null;
  };

  const createCols = () => {
    let newCols = [];
    if (
      selectedReportType.group === "P" ||
      selectedReportType.group === "Z" ||
      selectedReportType.group === "G" ||
      selectedReportType.group === "J" ||
      selectedReportType.group === "K" ||
      selectedReportType.group === "H" ||
      selectedReportType.group === "I" ||
      selectedReportType.group === "L" ||
      selectedReportType.group === "O" ||
      selectedReportType.group === "M" ||
      selectedReportType.group === "N"
    ) {
      newCols.push({ name: "PREVIEW.orderNumber", type: "Numeric" });
    }
    reportData.columns.forEach((column) => {
      if (!column.name.includes("__")) {
        newCols.push(column);
      }
    });
    setCols(newCols);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    var columnToolPanel = params.api.getToolPanelInstance("columns");
    columnToolPanel.setPivotModeSectionVisible(false);
  };

  const autoSizeColumns = (params) => {
    var allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  const getFilterType = (columnType) => {
    if (columnType === "Long" || columnType === "Numeric")
      return "agNumberColumnFilter";
    else if (columnType === "Date" || columnType === "OffsetDateTime")
      return "agDateColumnFilter";
    else return true;
  };

  const valueFormatter = (params, columnType) => {
    if (
      (columnType === "Date" || columnType === "OffsetDateTime") &&
      params.value
    )
      return new Date(params.value).toLocaleDateString(languageCode, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    else if (
      (columnType === "Numeric" || columnType === "BigInt") &&
      params.value
    )
      return params.value.toLocaleString(languageCode);
    else if (columnType === "Boolean" && params.value != null) {
      if (params.value.toString() === "true")
        return intl.formatMessage({ id: "GENERAL.YES" });
      if (params.value.toString() === "false")
        return intl.formatMessage({ id: "GENERAL.NO" });
    } else if (params.colDef.field === "PREVIEW.orderNumber")
      return params.node.childIndex + 1;
    return params.value;
  };

  const getLocale = () => {
    if (languageCode === "pl") return localePl;
    if (languageCode === "de") return localeDe;
    if (languageCode === "ru") return localeRu;
    if (languageCode === "es") return localeEs;
    return null;
  };

  const getEnableValue = (columnType) => {
    if (columnType === "Long" || columnType === "Numeric") return true;
    else return false;
  };

  const defaultColDef = {
    resizable: true,
  };

  const getMainMenuItems = useCallback(
    (params) => {
      switch (params.column.getColDef().filter) {
        case "agNumberColumnFilter":
          const numberMenuItems = params.defaultItems.slice(0);
          numberMenuItems.push({
            name: intl.formatMessage({ id: "PREVIEW.DECIMAL_PLACES" }),
            subMenu: [
              {
                name: "1",
                action: function() {
                  pdfDecimalPlaces.current = 1;
                  params.column.colDef.valueFormatter = function(params) {
                    if (params.value) {
                      return params.value.toLocaleString(languageCode, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      });
                    }
                  };
                  gridApi.redrawRows();
                },
              },
              {
                name: "2",
                action: function() {
                  pdfDecimalPlaces.current = 2;
                  params.column.colDef.valueFormatter = function(params) {
                    if (params.value) {
                      return params.value.toLocaleString(languageCode, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    }
                  };
                  gridApi.redrawRows();
                },
              },
              {
                name: "3",
                action: function() {
                  pdfDecimalPlaces.current = 3;
                  params.column.colDef.valueFormatter = function(params) {
                    if (params.value) {
                      return params.value.toLocaleString(languageCode, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      });
                    }
                  };
                  gridApi.redrawRows();
                },
              },
              {
                name: intl.formatMessage({ id: "GENERAL.RESET" }),
                action: function() {
                  pdfDecimalPlaces.current = null;
                  params.column.colDef.valueFormatter = function(params) {
                    if (params.value) {
                      return params.value.toLocaleString(languageCode);
                    }
                  };
                  gridApi.redrawRows();
                },
              },
            ],
          });
          return numberMenuItems;
        default:
          return params.defaultItems;
      }
    },
    [gridApi, intl, languageCode]
  );

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{ height: window.innerHeight * 0.6, width: "auto" }}
      >
        <AgGridReact
          rowData={rows}
          suppressFieldDotNotation
          onGridReady={onGridReady}
          onFirstDataRendered={autoSizeColumns}
          onRowDataChanged={autoSizeColumns}
          sideBar
          localeText={getLocale()}
          rowHeight={24}
          suppressReactUi
          pivotMode="false"
          suppressPivotMode
          defaultColDef={defaultColDef}
          getMainMenuItems={getMainMenuItems}
        >
          {cols.map((col, index) => (
            <AgGridColumn
              headerName={intl.formatMessage({ id: col.name })}
              field={col.name}
              key={index}
              resizable
              autoHeight
              wrapText
              filter={getFilterType(col.type)}
              sortable
              enableRowGroup
              valueFormatter={(e) => valueFormatter(e, col.type)}
              enableValue={getEnableValue(col.type)}
              filterParams={{
                valueFormatter: (e) => valueFormatter(e, col.type),
              }}
            />
          ))}
        </AgGridReact>
      </div>
      <Row>
        <Col className="text-right mt-5" xs={12}>
          <div className="d-flex align-items-center justify-content-end">
            <p className="mr-4 my-0">
              <FormattedMessage id="GENERAL.EXPORT_REPORT" />:
            </p>
            <Button
              onClick={async () => {
                setLoading(true);
                const callback = await onExportingReportToXLS(
                  reportData?.reportHistoryId
                );
                if (callback) {
                  setLoading(false);
                } else {
                  setLoading(false);
                }
              }}
              disabled={!selectedReportType?.canExportToExcel || loading}
              className="mr-2"
            >
              Excel
            </Button>
            <PDFExportButton
              gridApi={gridApi}
              columnApi={columnApi}
              reportName={intl.formatMessage({ id: selectedReportType.name })}
              decimalPlaces={pdfDecimalPlaces}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
