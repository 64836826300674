import React from "react";
import { FormattedMessage } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Link } from "react-router-dom";

export function HelpCenterLinkButton() {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="logout-tooltip">
          <FormattedMessage id="GENERAL.HELP_CENTER" />
        </Tooltip>
      }
    >
      <Link to="/user/help" className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 svg-icon svg-icon-dark-75">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} style={{height: "24px", width: "24px"}} />
        </div>
      </Link>
    </OverlayTrigger>
  );
}
