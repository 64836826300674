import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormControl, Table } from "react-bootstrap";

export default function Step2() {
  const selectedPlan = useSelector((state) => state.pricing.selectedPlan);
  const languageCode = JSON.parse(localStorage.getItem("i18nConfig"))
    .selectedLang;

  const dispatch = useDispatch();
  const [brigadierPrice, setBrigadierPrice] = useState(0);
  const [brigadierPriceBrutto, setBrigadierPriceBrutto] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [finalPriceBrutto, setFinalPriceBrutto] = useState(0);
  const [courierTabIndex, setCourierTabIndex] = useState(0);

  const tabIndex = selectedPlan.cmTimeIndex ? selectedPlan.cmTimeIndex : 0;

  const setParameterCount = (count, paramId, step, symbol, index) => {
    let countToDispatch = 0;
    if (symbol === "PACKAGE_ITEM") {
      if (count > selectedPlan.data.parameters[index].value) {
        if (count <= 100) countToDispatch = 100;
        else if (count > 100 && count <= 200) countToDispatch = 200;
        else if (count > 200 && count <= 500) countToDispatch = 500;
        else if (count > 500 && count <= 1000) countToDispatch = 1000;
        else if (count > 1000) countToDispatch = 1500;
      } else {
        if (count < 1500) countToDispatch = 1000;      
        if (count < 1000) countToDispatch = 500;
        if (count < 500) countToDispatch = 200;
        if (count < 200) countToDispatch = 100;
        if (count < 100) countToDispatch = 0;
      }
    } else {
      if (count % step === 0) countToDispatch = count;
      else countToDispatch = count - (count % step) + step;
    }
    dispatch({
      type: "pricing/setSelectedPlanParameterCount",
      payload: {
        count: countToDispatch > 0 ? countToDispatch : 0,
        paramId,
      },
    });
  };

  const setBrigadierCount = (count, min) => {
    dispatch({
      type: "pricing/setSelectedPlanBrigadierCount",
      payload: {
        brigadierCount: count > min ? count : min,
      },
    });
  };

  const getParamInputStep = (param) => {
    if (param.symbol === "BRIGADIER_IDENTIFIER") return 25;
    return 1;
  };

  const getParamPriceBasedOnQuantity = (param, isBrutto) => {
    let priceToReturn = 0;
    if (isBrutto) {
      if (param.prices.length === 1)
        priceToReturn = param.prices[0].priceBrutto * param.value;
      else if (param.prices.length > 1)
        param.prices.forEach((price) => {
          if (
            param.value >= price.quantityFrom &&
            param.value <= price.quantityTo
          )
            priceToReturn = price.priceBrutto * param.value;
        });
    } else {
      if (param.prices.length === 1)
        priceToReturn = param.prices[0].price * param.value;
      else if (param.prices.length > 1)
        param.prices.forEach((price) => {
          if (
            param.value >= price.quantityFrom &&
            param.value <= price.quantityTo
          )
            priceToReturn = price.price * param.value;
        });
    }
    return priceToReturn;
  };

  const getParamUnitPriceBasedOnQuantity = (param) => {
    let unitPriceToReturn = 0;
    if (param.prices.length === 1) unitPriceToReturn = param.prices[0].price;
    else if (param.prices.length > 1)
      param.prices.forEach((price) => {
        if (
          param.value >= price.quantityFrom &&
          param.value <= price.quantityTo
        )
          unitPriceToReturn = price.price;
      });
    return unitPriceToReturn;
  };

  useEffect(() => {
    if (selectedPlan.appName === "BRIGADIER") {
      const brigadierOnlyPrice =
        (selectedPlan.brigadierCount - selectedPlan.data.parameters[0].value) *
          selectedPlan.data.parameters[0].price.price +
        selectedPlan.data.prices[0].amount;
      setBrigadierPrice(brigadierOnlyPrice);
      const brigadierOnlyPriceBrutto =
        (selectedPlan.brigadierCount - selectedPlan.data.parameters[0].value) *
          selectedPlan.data.parameters[0].price.priceBrutto +
        selectedPlan.data.prices[0].amountBrutto;
      setBrigadierPriceBrutto(brigadierOnlyPriceBrutto);
      let paramsPrice = 0;
      selectedPlan.data.parameters.forEach((param) => {
        if (param.name === "BRIGADIER_NUMBER") return;
        else paramsPrice += getParamPriceBasedOnQuantity(param);
      });
      setFinalPrice(brigadierOnlyPrice + paramsPrice);
      let paramsPriceBrutto = 0;
      selectedPlan.data.parameters.forEach((param) => {
        if (param.name === "BRIGADIER_NUMBER") return;
        else paramsPriceBrutto += getParamPriceBasedOnQuantity(param, true);
      });
      setFinalPriceBrutto(brigadierOnlyPriceBrutto + paramsPriceBrutto);
    } else if (selectedPlan.appName === "CROPMANAGER") {
      setFinalPrice(selectedPlan.data.prices[tabIndex].amount);
      setFinalPriceBrutto(selectedPlan.data.prices[tabIndex].amountBrutto);
    }
  }, [
    selectedPlan.appName,
    selectedPlan.brigadierCount,
    selectedPlan.data.parameters,
    selectedPlan.data.prices,
    tabIndex,
  ]);

  useEffect(() => {
    const params = selectedPlan.data.parameters;
    if (selectedPlan.appName === "BRIGADIER") {
      if (params.find((param) => param.name === "COURIER_WEIGHT").value)
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_WEIGHT")
        );
      else if (
        params.find((param) => param.name === "COURIER_SMARTPHONE").value
      )
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_SMARTPHONE")
        );
      else if (
        params.find((param) => param.name === "COURIER_ITEM_IDENTIFIER").value
      )
        setCourierTabIndex(
          params.findIndex((param) => param.name === "COURIER_ITEM_IDENTIFIER")
        );
      else setCourierTabIndex(0);
    }
  }, [selectedPlan.data.parameters, selectedPlan.appName]);

  const nextStep = () => {
    dispatch({
      type: "pricing/setStep",
      payload: {
        step: 3,
      },
    });
  };

  return (
    <div className="card-body">
      <div className="mb-10">
        <h6>
          <FormattedMessage id="PRICING.INFORMATIONS" />
        </h6>
        <p>
          <FormattedMessage id="PRICING.SELECTED_SUBSCRIPTION" />:{" "}
          <strong>{selectedPlan.data.desc}</strong>
        </p>
        {selectedPlan.appName === "CROPMANAGER" && (
          <p className="mt-n3">
            <FormattedMessage id="PRICING.AGRONOMIST_COUNT" />:{" "}
            <strong>{selectedPlan.data.parameters[0].value}</strong>
          </p>
        )}
        <p className="mt-n3">
          <FormattedMessage id="PRICING.SUBSCRIPTION_TIME" />:{" "}
          {selectedPlan.data.prices[tabIndex].month && (
            <strong>
              {selectedPlan.data.prices[tabIndex].month}{" "}
              <FormattedMessage id="PRICING.TIME.MONTHS" />
            </strong>
          )}
          {selectedPlan.data.prices[tabIndex].days && (
            <strong>
              {selectedPlan.data.prices[tabIndex].days}{" "}
              <FormattedMessage id="PRICING.TIME.DAYS" />
            </strong>
          )}
        </p>
        {selectedPlan.appName === "BRIGADIER" && (
          <div className="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row">
            <div className="d-flex align-items-center">
              <label className="flex-shrink-0 mb-0 mr-6">
                <FormattedMessage id="PRICING.BRIGADIER_COUNT" />:
              </label>
              <FormControl
                type="number"
                min={selectedPlan.data.parameters[0].value}
                value={selectedPlan.brigadierCount}
                className="text-center"
                onChange={(e) =>
                  setBrigadierCount(
                    Number(e.currentTarget.value),
                    e.currentTarget.min
                  )
                }
                size="sm"
                style={{ width: "60px" }}
              />
            </div>
            <div className="d-flex justify-content-center flex-column mt-2 mt-sm-0">
              <h6 className="m-0 mb-1">
                {brigadierPrice.toLocaleString(languageCode, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                <FormattedMessage id="PRICING.NETTO" />
              </h6>
              <h6 className="m-0">
                <strong>
                  {brigadierPriceBrutto.toLocaleString(languageCode, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                  <FormattedMessage id="PRICING.BRUTTO" />
                </strong>
              </h6>
            </div>
          </div>
        )}
        {selectedPlan.appName === "CROPMANAGER" && (
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex align-items-center">
              <h6 className="m-0">
                <strong>
                  {(
                    selectedPlan.data.prices[tabIndex].amount /
                    selectedPlan.data.prices[tabIndex].month
                  ).toLocaleString(languageCode, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                  <FormattedMessage id="PRICING.NETTO" />
                  /<FormattedMessage id="PRICING.TIME.MONTH" />
                </strong>
              </h6>
            </div>
          </div>
        )}
      </div>
      {selectedPlan.appName === "BRIGADIER" && (
        <div className="mb-6">
          <h6>
            <FormattedMessage id="PRICING.ADDITIONAL_PARAMETERS" />
          </h6>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="PLACEHOLDER.NAME" />
                </th>
                <th>
                  <FormattedMessage id="PRICING.PRICE.NETTO" />
                </th>
                <th>
                  <FormattedMessage id="PLACEHOLDER.QUANTITY" />
                </th>
                <th className="text-right">
                  <FormattedMessage id="PRICING.VALUE.NETTO" />
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPlan.data.parameters.map((param, index) => {
                if (index === 0 || param.symbol.includes("COURIER"))
                  return null;
                else
                  return (
                    <tr key={`step2-params-${index}`}>
                      <td className="align-middle">
                        <FormattedMessage
                          id={`PRICING.PRODUCT.${param.name}`}
                        />
                        {param.name === "PACKAGE_ITEM" && <span className="text-danger"><strong>*</strong></span>}
                      </td>
                      <td className="align-middle">
                        {getParamUnitPriceBasedOnQuantity(param).toLocaleString(
                          languageCode,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                        {selectedPlan.data.prices[0].currency.name}/
                        <FormattedMessage id="PRICING.PCS" />
                      </td>
                      <td className="align-middle">
                        <FormControl
                          type="number"
                          min={0}
                          value={param.value}
                          className="text-center"
                          onChange={(e) =>
                            setParameterCount(
                              Number(e.currentTarget.value),
                              param.sparameterId,
                              Number(e.currentTarget.step),
                              param.symbol,
                              index
                            )
                          }
                          size="sm"
                          style={{ width: "60px" }}
                          step={getParamInputStep(param)}
                        />
                      </td>
                      <td
                        className="align-middle text-right"
                        style={{ width: "15%" }}
                      >
                        <strong>
                          {getParamPriceBasedOnQuantity(param).toLocaleString(
                            languageCode,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}{" "}
                          {selectedPlan.data.prices[0].currency.name}
                        </strong>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
        </div>
      )}
      {selectedPlan.appName === "BRIGADIER" &&
        courierTabIndex > 0 &&
        selectedPlan.data.parameters[courierTabIndex] &&
        selectedPlan.data.parameters[courierTabIndex].value > 0 && (
          <div className="mb-10">
            <h6 className="mb-4">
              <FormattedMessage id="PRICING.DELIVERY" />
            </h6>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50">
                <div className="symbol-label">
                  <i className="fa fa-truck" />
                </div>
              </div>
              <div className="ml-4">
                <p className="m-0">
                  <FormattedMessage id="PRICING.DHL_COURIER" />
                </p>
                <p className="m-0 font-weight-bold">
                  {selectedPlan.data.parameters[
                    courierTabIndex
                  ].price.price.toLocaleString(languageCode, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {selectedPlan.data.prices[tabIndex].currency.name}
                </p>
              </div>
            </div>
          </div>
        )}
      {selectedPlan.appName === "BRIGADIER" && (
        <p>
          <span className="text-danger"><strong>*</strong></span> <FormattedMessage id="PRICING.NO_OPTION_CUSTOM_PRICE" />
        </p>
      )}
      <hr />
      <div className="mb-10">
        <div className="d-flex justify-content-between flex-column flex-sm-row">
          <h6>
            <FormattedMessage id="PRICING.FINAL_PRICE" />
          </h6>
          <div className="d-flex flex-column justify-content-center">
            <h6 className="m-0 mb-1">
              {finalPrice.toLocaleString(languageCode, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {selectedPlan.data.prices[tabIndex].currency.name}{" "}
              <FormattedMessage id="PRICING.NETTO" />
            </h6>
            <h6>
              <strong>
                {finalPriceBrutto.toLocaleString(languageCode, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {selectedPlan.data.prices[tabIndex].currency.name}{" "}
                <FormattedMessage id="PRICING.BRUTTO" />
              </strong>
            </h6>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button onClick={() => nextStep()}>
          <FormattedMessage id="GENERAL.NEXT" />
        </Button>
      </div>
    </div>
  );
}
