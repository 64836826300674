import * as React from "react";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ModuleOffer } from "./ModuleOffer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function AvailableModules({ modules }) {
  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <span className="agrico-svg-icon svg-icon menu-icon mr-2">
            <SVG src={toAbsoluteUrl("/media/icons/Field.svg")} />
          </span>
          <h3 className="card-label">
            <FormattedMessage id="GENERAL.MODULES" /> lub{" "}
            <FormattedMessage id="GENERAL.MODULES_PRICE_LIST" />
          </h3>
        </div>
      </div>
      <div className="card-body">
        <Row>
          {modules.length > 0 ? (
            <>
              {modules.map((mod) => (
                <Col
                  md={modules.length <= 1 ? 12 : 6}
                  xxl={modules.length <= 1 ? 12 : 3}
                  key={mod.name}
                  className="text-center"
                >
                  <ModuleOffer offer={mod} />
                </Col>
              ))}
            </>
          ) : (
            <></>
          )}
        </Row>
      </div>
    </div>
  );
}
