import React from "react";
import {FormattedMessage} from "react-intl";
import {isMobile} from "react-device-detect";
import moment from "moment";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import robotoFont from "../../../../fonts/Roboto-Light.ttf";
import {Col, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

Font.register({family: "Robot", src: robotoFont});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Robot",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  details: {
    fontSize: 10,
  },
  secondRowDetails: {
    fontSize: 10,
    marginTop: 15
  },
  table: {
    fontSize: 8,
    marginTop: 20,
    border: "1px solid #000",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
  },
  tableHeaderCell: {
    paddingLeft: 5,
    paddingRight: 5,
    width: "25%",
  },
  tableHeaderCellWithBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "25%",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableHeaderWithBorder: {
    borderTop: 1,
    display: "flex",
    flexDirection: "row",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableSmallCellWithBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "4%",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableMediumCellWithBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "15%",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableLargeCellWithBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "20%",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableLargerCellWithBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "30%",
    borderStyle: "solid",
    borderColor: "#000",
  },
  tableLargeCell: {
    paddingLeft: 5,
    paddingRight: 5,
    width: "20%",
  },
  tableExtraLargeCell: {
    paddingLeft: 5,
    paddingRight: 5,
    width: "65%",
  },
  tableLargeCellWithoutBorder: {
    paddingLeft: 5,
    paddingRight: 5,
    width: "40%",
  },
  tc_w10_b: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRight: 1,
    width: "10%",
    borderStyle: "solid",
    borderColor: "#000",
  },
});

const ReportDocument = ({selectedReportType, reportData}) => {
  const {t} = useTranslation();
 
  return (
    <Document>
      <Page size="A4"
            style={styles.body}
            orientation={selectedReportType === 'X' || selectedReportType === 'Y' ? 'landscape' : 'portrait'}>
        {selectedReportType === "A" && (
          <>
            <View style={styles.header}>
              <View>
                <Text style={styles.details}>
                {t("REPORTS.AGRICULTURAL_CARD")} ............, {t("REPORTS.YEAR")} 20......, {t("REPORTS.FIELD_COMMON_NAME")} {reportData[0].fieldShortName}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderCellWithBorder}>
                {t("REPORTS.FIELD_ACREAGE_HA")}
                </Text>
                <Text style={styles.tableHeaderCellWithBorder}>
                  {t("REPORTS.FIELD_ACREAGE_HA")}
                </Text>
                <Text style={styles.tableHeaderCellWithBorder}>
                  {t("REPORTS.PLANT_YIELD")}
                </Text>
                <Text style={styles.tableHeaderCell}>
                  {t("REPORTS.PLANTS_CULTIVATION")}
                </Text>
              </View>
              <View style={styles.tableHeaderWithBorder}>
                <Text style={styles.tableHeaderCellWithBorder}>
                  {reportData[0].acreage}
                </Text>
                <Text style={styles.tableHeaderCellWithBorder}></Text>
                <Text style={styles.tableHeaderCellWithBorder}></Text>
                <Text style={styles.tableHeaderCell}></Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableSmallCellWithBorder}>Lp.</Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("REPORTS.TREATMENT_DATE")}
                </Text>
                <Text style={styles.tableLargeCellWithBorder}>
                  {t("REPORTS.TREATMENT_NAME")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("REPORTS.CHEMISTRY_NAME")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("REPORTS.CHEMISTRY_QUANTITY")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("REPORTS.CHEMISTRY_REASON")}
                </Text>
                <Text style={styles.tableLargeCell}>{t("REPORTS.COMMENTS")}</Text>
              </View>
              {reportData.map((row, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && (
                    <View style={styles.tableHeaderWithBorder}>
                      <Text style={styles.tableSmallCellWithBorder}>{index}</Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row.eventDateFrom} - {row.eventDateTo}
                      </Text>
                      <Text style={styles.tableLargeCellWithBorder}>
                        {row.treatmentDesc}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row.chemistryName}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row.dose}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}></Text>
                      <Text style={styles.tableLargeCell}></Text>
                    </View>
                  )}
                </React.Fragment>
              ))}
              <View style={styles.tableHeaderWithBorder}>
                <Text style={styles.tableSmallCellWithBorder}> </Text>
                <Text style={styles.tableMediumCellWithBorder}> </Text>
                <Text style={styles.tableLargeCellWithBorder}>{t("REPORTS.HARVEST")}</Text>
                <Text style={styles.tableExtraLargeCell}>
                  {t("REPORTS.PRODUCT_TYPE_QUANTITY")}
                </Text>
              </View>
              <View style={styles.tableHeaderWithBorder}>
                <Text>{t("REPORTS.ADDITIONAL_EXPLANATION")}</Text>
              </View>
            </View>
          </>
        )}
        {selectedReportType === "F" && (
          <>
            <View style={styles.header}>
              <View>
                <Text style={styles.details}>
                 {t("REPORTS.REPORT_FOR_CROP")} {reportData[0].croppingName}  {t("REPORTS.PERIOD_FROM")} {reportData[0].eventDateFrom} {t("REPORTS.TO")} {reportData[0].eventDateTo}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableMediumCellWithBorder}>
                 {t("REPORTS.TREATMENT_DATE")}
                </Text>
                <Text style={styles.tableLargerCellWithBorder}>
                 {t("REPORTS.TREATMENT_NAME")}
                </Text>
                <Text style={styles.tableLargerCellWithBorder}>
                 {t("REPORTS.CHEMISTRY_NAME")}   
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                {t("REPORTS.CHEMISTRY_QUANTITY")}   
                </Text>
                <Text style={styles.tc_w10_b}>
                {t("REPORTS.APPLICATION_AREA")}    
                </Text>
              </View>
              {reportData.map((row, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && (
                    <View style={styles.tableHeaderWithBorder}>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row.eventDateFrom && (
                          <>
                            {row.eventDateFrom} - {row.eventDateTo}
                          </>
                        )}
                      </Text>
                      <Text style={styles.tableLargerCellWithBorder}>
                        {row.treatmentDesc}
                      </Text>
                      <Text style={styles.tableLargerCellWithBorder}>
                        {row.chemistryName}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row.treatmentDose} {row.doseUOM}
                      </Text>
                      <Text style={styles.tc_w10_b}>{row.treatmentArea}</Text>
                    </View>
                  )}
                </React.Fragment>
              ))}
            </View>
          </>
        )}
        {(selectedReportType === "X" || selectedReportType === "Y") && (
          <>
            <View style={styles.header}>
              <View>
                <Text style={styles.details}>
                  {t("PREVIEW.FIELDS_CARD")}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableSmallCellWithBorder}>
                  {t("PREVIEW.orderNumber")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.shortName")}
                </Text>
                <Text style={styles.tableLargeCellWithBorder}>
                  {t("PREVIEW.desc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.acreage")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.coordinates")}
                </Text>
                {selectedReportType === 'Y' && (
                  <Text style={styles.tableMediumCellWithBorder}>
                    {t("PREVIEW.bPartnerFullName")}
                  </Text>
                )}
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.varietyDesc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.speciesDesc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.isEco")}
                </Text>
              </View>
              {reportData
                .filter(row => row.__isField === true)
                .map((row, index) => (
                  <React.Fragment key={index}>
                    <View style={styles.tableHeaderWithBorder}>
                      <Text style={styles.tableSmallCellWithBorder}>{index + 1}</Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.shortName"]}
                      </Text>
                      <Text style={styles.tableLargeCellWithBorder}>
                        {row["PREVIEW.desc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.acreage"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.coordinates"]}
                      </Text>
                      {selectedReportType === 'Y' && (
                        <Text style={styles.tableMediumCellWithBorder}>
                          {row["PREVIEW.bPartnerFullName"]}
                        </Text>
                      )}
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.varietyDesc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.speciesDesc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.isEco"]}
                      </Text>
                    </View>
                  </React.Fragment>
                ))}
            </View>

            <View style={styles.header}>
              <View>
                <Text style={styles.secondRowDetails}>
                  {t("PREVIEW.CROPPINGS_CARD")}
                </Text>
              </View>
            </View>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableSmallCellWithBorder}>
                  {t("PREVIEW.orderNumber")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.shortName")}
                </Text>
                <Text style={styles.tableLargeCellWithBorder}>
                  {t("PREVIEW.desc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.acreage")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.coordinates")}
                </Text>
                {selectedReportType === 'Y' && (
                  <Text style={styles.tableMediumCellWithBorder}>
                    {t("PREVIEW.bPartnerFullName")}
                  </Text>
                )}
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.varietyDesc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.speciesDesc")}
                </Text>
                <Text style={styles.tableMediumCellWithBorder}>
                  {t("PREVIEW.isEco")}
                </Text>
              </View>
              {reportData
                .filter(row => row.__isField === false)
                .map((row, index) => (
                  <React.Fragment key={index}>
                    <View style={styles.tableHeaderWithBorder}>
                      <Text style={styles.tableSmallCellWithBorder}>{index + 1}</Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.shortName"]}
                      </Text>
                      <Text style={styles.tableLargeCellWithBorder}>
                        {row["PREVIEW.desc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.acreage"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.coordinates"]}
                      </Text>
                      {selectedReportType === 'Y' && (
                        <Text style={styles.tableMediumCellWithBorder}>
                          {row["PREVIEW.bPartnerFullName"]}
                        </Text>
                      )}
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.varietyDesc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.speciesDesc"]}
                      </Text>
                      <Text style={styles.tableMediumCellWithBorder}>
                        {row["PREVIEW.isEco"] ? t("GENERAL.YES") : t("GENERAL.NO")}
                      </Text>
                    </View>
                  </React.Fragment>
                ))}
            </View>
          </>
        )}
      </Page>
    </Document>
  )
};

export function Report({selectedReportType, reportData, onOpeningModal}) {
  return (
    <>
      {isMobile ? (
        <Col
          md={12}
          lg={12}
          xxl={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div className="text-center mt-5">
            {reportData?.result?.length > 0 && (
              <PDFDownloadLink
                document={
                  <ReportDocument
                    selectedReportType={selectedReportType}
                    reportData={reportData.result}
                  />
                }
                fileName={`licensing-${moment().format(
                  "DD.MM.YYYY_HH:mm"
                )}.pdf`}
              >
                <Button>
                  <FormattedMessage id="GENERAL.CLICK_HERE_TO_DOWNLOAD_PDF"/>
                </Button>
              </PDFDownloadLink>
            )}
          </div>
        </Col>
      ) : (
        <>
          {reportData?.result?.length > 0 ? (
            <Col md={12} lg={12} xxl={12} style={{minHeight: "50vh"}}>
              <PDFViewer style={{width: "100%", height: "100%"}}>
                <ReportDocument
                  selectedReportType={selectedReportType}
                  reportData={reportData.result}
                />
              </PDFViewer>
            </Col>
          ) : (
            <Col md={12} lg={12} xxl={12}>
              <div className="text-center mt-5">
                <h6>
                  <FormattedMessage id="GENERAL.NO_DATA_TO_DISPLAY"/>
                </h6>
              </div>
            </Col>
          )}
        </>
      )}
      <Col xs={12} className="text-right mt-5">
        {reportData?.result?.length > 0 && (
          <Button onClick={onOpeningModal}>
            <FormattedMessage id="GENERAL.SHARE"/>
          </Button>
        )}
      </Col>
    </>
  );
}
