import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function SzTransportRegulations() {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30">
        <div>
          <h1>Polityka prywatności</h1>
          <div>
            <p>
              <br />
              Producent i dostawca aplikacji SZP Transport – spółka Seth Software - dokłada  najwyższych starań,
              by dane osobowe Użytkowników były przetwarzane zgodnie z obowiązującymi przepisami prawa polskiego i Unii Europejskiej.
              Szanując prawo do prywatności Użytkowników usług gwarantujemy im prawo wyboru w zakresie udostępniania informacji, które ich dotyczą.
              Polityka Prywatności aplikacji SZP Transport 2022 informuje jak przetwarzamy dane osobowe Użytkowników usługi SZP Transport, a przede wszystkim:
              <br />
              <br />
              <ul>
                <li>Kto jest administratorem danych osobowych,</li>
                <li>Jakie dane przetwarzamy oraz czy przetwarzanie tych danych jest niezbędne ze względu na usługi świadczone przez nas drogą elektroniczną,</li>
                <li>W jakim celu i na jakiej podstawie przetwarzamy dane,</li>
                <li>Jak długo przetwarzamy dane,</li>
                <li>Jakie prawa przysługują naszym Użytkownikom jako podmiotom danych osobowych,</li>
                <li>Komu udostępniamy dane</li>
              </ul>
            </p>


            <p>
              <strong>
                {"§1. Administrator danych osobowych"}
              </strong>
            </p>
            <p>
              1. Administratorem danych osobowych w rozumieniu art. 4 pkt 7 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dn. 27.04.2016 r.
              w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia dyrektywy 95/46/WE (RODO) jest Seth Software Spółka z ograniczoną odpowiedzialnością z siedzibą
              w Głogowie Małopolskim pod adresem ul. Strefowa 1, 36-060 Głogów Małopolski, NIP: 8132709001, REGON: 690512599, KRS: 0000114098,
              sąd rejestrowy: Sąd Rejonowy w Rzeszowie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego, kapitał zakładowy 50.000 zł.
            <br />
              2. Dane kontaktowe administratora danych: adres e-mail info@seth.software.
            <br />
              3. Administrator w myśl art. 32 ust. 1 RODO przestrzega zasady ochrony danych osobowych oraz stosuje odpowiednie środki techniczne i
              organizacyjne w celu zapobieżenia przypadkowego lub niezgodnego z prawem zniszczenia, utraty, modyfikacji,
              nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przetwarzanych w związku z prowadzoną działalnością.
            <br />
              4. Podanie danych osobowych jest dobrowolne, ale niezbędne w celu uruchomienia usługi SZP Transport.
            <br />
              5. Administrator danych przetwarza dane osobowe wyłącznie w zakresie wymaganym do prawidłowej realizacji usługi na rzecz podmiotu, którego dane dotyczą.
            </p>

            <p>
              <strong>
                {"§2. Zbieranie i wykorzystanie danych przez aplikację SDP Transport"}
              </strong>
            </p>
            <p>
              Aplikacja SDP Transport służy do nadzorowania pracy kierowców, realizujących zlecenie transportu produktów z fabryki do rolnika.
              Podczas załadunku kierowca otrzymuje kod/kody kreskowe z informacjami, gdzie dane transporty powinny dotrzeć. W momencie rejestracji
              rozładunku aplikacja prosi kierowcę o zeskanowanie kodu, następnie pobiera lokalizację GPS. Na podstawie współrzędnych GPS pobierana
              jest nazwa lokalizacji. W aplikacji zapisywany jest czas rozładunku. Na podstawie weryfikacji danych o rozładunku podejmowane są decyzje o
              płatnościach za zrealizowane transporty.

            <p>
                1. Seth Software gromadzi dane osobowe przekazane firmie Seth przez użytkowników, na przykład podczas zakładania konta.
              <br />
                2.	Seth Software gromadzi dane powstałe podczas korzystania z usługi SDP Transport, na przykład dotyczące lokalizacji, korzystania z aplikacji i dane z urządzenia.
              <br />
                3.	Dane zbierane i wykorzystywane przez aplikację SDP Transport:
              <br />
                <ul>
                  <li>Dane osobowe:
                  <br />
                    Imię, nazwisko, nr telefonu  - przekazane przez Użytkownika podczas zakładania konta i służące do zalogowania się w aplikacji.

              </li>
                  <li> Dane geolokalizacyjne - zbierane na podstawie współrzędnych GPS dane dotyczące lokalizacji kierowców w celu umożliwienia realizowania
                przejazdów i dostaw, umożliwienia monitorowania przejazdów/dostawy, zapewnienia bezpieczeństwa oraz ich poprawnego rozliczenia i spełnienia wymogów prawnych. </li>
                  <li> Identyfikatory urządzenia i dane o konfiguracji telefonu, w tym o zgodzie na użycie kamery urządzenia wyłącznie w celu umożliwienia
                zeskanowania urządzeniem wysyłanych kierowcy kodów kreskowych.</li>
                </ul>
              </p>
            </p>

            <p>
              <strong>
                {"§3. Cel i podstawy przetwarzania danych osobowych"}
              </strong>
            </p>
            <p>
              Administrator przetwarza dane osobowe w następujących celach: a) świadczenie usługi i realizacja zobowiązań umownych, na podstawie
              zawartej umowy (art. 6 ust. 1 lit. b RODO); c) obsługa procesu reklamacyjnego, na podstawie obowiązku ciążącym na administratorze
              danych w związku z obowiązującymi przepisami prawa (art. 6 ust. 1 lit. c RODO); d) rachunkowych związanych z wystawianiem i przyjmowaniem
              dokumentów rozliczeniowych, na podstawie przepisów prawa podatkowego (art. 6 ust. 1 lit. c RODO); e) archiwizacja danych dla ewentualnego
              ustalenia, dochodzenia lub obrony przed roszczeniami lub potrzeby wykazania faktów, co jest prawnie uzasadnionym interesem administratora
              danych (art. 6 ust. 1 lit f RODO); f) kontakt telefoniczny lub za pośrednictwem poczty elektronicznej, w szczególności w odpowiedzi na zapytania
              kierowane do administratora danych, co jest prawnie uzasadnionym interesem administratora danych (art. 6 ust. 1 lit. f RODO); g) przesyłanie informacji
              technicznych dotyczących funkcjonowania usług, z których korzysta klient, co jest prawnie uzasadnionym
              interesem administratora danych (art. 6 ust. 1 lit. f RODO); h) marketing, co jest jego prawnie uzasadnionym
              interesem (art. 6 ust. 1 lit. f RODO) lub odbywa się na podstawie uprzednio udzielonej zgody (art. 6 ust. 1 lit. a RODO).
            </p>


            <p>
              <strong>
                {"§4. Odbiorcy danych. Przekazywanie danych do państw trzecich"}
              </strong>
            </p>
            <p>
              1. Odbiorcami danych osobowych przetwarzanych przez administratora danych mogą być podmioty współpracujące z administratorem danych,
              gdy jest to niezbędne do realizacji umowy zawartej z osobą, której dane dotyczą.
            <br />
              2. Odbiorcami danych osobowych przetwarzanych przez administratora danych mogą być również podwykonawcy – podmioty, z których usług
              korzysta administrator danych przy przetwarzaniu danych np. biura rachunkowe, kancelarie prawne, podmioty świadczące usługi IT (w tym usługi hostingowe).
            <br />
              3. Administrator danych może być zobowiązany do udostepnienia danych osobowych na podstawie obowiązujących przepisów prawa,
              w szczególności do udostępnienia danych osobowych uprawnionym organom lub instytucjom państwowym.
            <br />
              4. Firma Seth Software może udostępniać dane osobowe dostawcom, usługodawcom lub partnerom biznesowym spoza terytorium EOG,
              gdy jest to niezbędne do realizacji usługi, np. firmie Google w związku z korzystaniem z Map Google w aplikacji SDP Transport
              (informacje na temat gromadzenia i wykorzystywania danych przez firmę Google znajdują się w polityce prywatności firmy Google).
            <br />
              Seth Software weryfikuje, aby Partner dawał gwarancje wysokiego stopnia ochrony danych osobowych. Gwarancje te wynikają w
              szczególności ze zobowiązania do stosowania standardowych klauzul umownych przyjętych przez Komisję (UE) stosownie do zapisów art. 46 ust. 2 RODO.
            </p>

            <p>
              <strong>
                {"§5. Okres przechowywania danych osobowych"}
              </strong>
            </p>
            <p>
              1. Administrator danych przechowuje dane osobowe przez okres obowiązywania umowy zawartej z osobą, której dane dotyczą oraz po zakończeniu jej
              obowiązywania w celach związanych z dochodzeniem roszczeń związanych z umową, wykonania obowiązków wynikających z obowiązujących przepisów prawa,
              ale przez czas nie dłuższy niż termin przedawnienia zgodnie z przepisami Kodeksu cywilnego.
              <br />
              2. Administrator danych przechowuje dane osobowe znajdujące się na dokumentach rozliczeniowych (np. faktury) przez okres czasu wskazany
              przepisami ustawy o podatku od towarów i usług oraz ustawą o rachunkowości.
              <br />
              3. Administrator danych przechowuje dane osobowe przetwarzane w celach marketingowych przez okres lat 10, jednak nie dalej niż
              do momentu wycofania zgody na przetwarzanie danych lub wniesienia sprzeciwu wobec przetwarzania danych.
              <br />
              4. Administrator danych przechowuje dane osobowe w celach innych niż wskazane w ust. 1-3 przez okres 3 lat, chyba że wcześniej
              wycofano zgodę na przetwarzanie danych, a przetwarzanie danych nie może być kontynuowane na innej podstawie niż zgoda osoby, której dane dotyczą.
            </p>
            <p>
              <strong>
                {"§6. Uprawnienia osoby, której dane dotyczą"}
              </strong>
            </p>
            <p>
              1. Każda osoba, której dane dotyczą, ma prawo: a) dostępu – uzyskania od administratora potwierdzenia, czy przetwarzane
              są jej dane osobowe. Jeżeli dane o osobie są przetwarzane, jest ona uprawniona do uzyskania dostępu do nich oraz uzyskania
              następujących informacji: o celach przetwarzania, kategoriach danych osobowych, informacji o odbiorcach lub kategoriach odbiorców,
              którym dane zostały lub zostaną ujawnione, o okresie przechowywania danych lub o kryteriach ich ustalania, o prawie do żądania sprostowania,
              usunięcia lub ograniczenia przetwarzania danych osobowych przysługujących osobie, której dane dotyczą, oraz do wniesienia sprzeciwu wobec takiego
              przetwarzania (art. 15 RODO); b) do otrzymania kopii danych – uzyskania kopii danych podlegających przetwarzaniu, przy czym pierwsza kopia jest bezpłatna,
              a za kolejne kopie administrator może nałożyć opłatę w rozsądnej wysokości wynikającą z kosztów administracyjnych (art. 15 ust. 3 RODO);
              c) do sprostowania – żądania sprostowania dotyczących jej danych osobowych, które są nieprawidłowe lub uzupełnienia niekompletnych danych (art. 16 RODO);
              d) do usunięcia danych – żądania usunięcia jej danych osobowych, jeżeli administrator nie ma już podstawy prawnej do ich przetwarzania lub dane nie są
               już niezbędne do celów przetwarzania (art. 17 RODO); e) do ograniczenia przetwarzania – żądania ograniczenia przetwarzania danych osobowych (art. 18 RODO),
               gdy: - osoba, której dane dotyczą, kwestionuje prawidłowość danych osobowych – na okres pozwalający administratorowi sprawdzić prawidłowość tych danych,
               - przetwarzanie jest niezgodne z prawem, a osoba, której dane dotyczą, sprzeciwia się ich usunięciu, żądając ograniczenia ich wykorzystywania, - administrator
               nie potrzebuje już tych danych, ale są one potrzebne osobie, której dane dotyczą, do ustalenia, dochodzenia lub obrony roszczeń, - osoba, której dane dotyczą,
               wniosła sprzeciw wobec przetwarzania – do czasu stwierdzenia, czy prawnie uzasadnione podstawy po stronie administratora są nadrzędne
               wobec podstaw sprzeciwu osoby, której dane dotyczą; f) do przenoszenia danych – otrzymania w ustrukturyzowanym, powszechnie używanym
               formacie nadającym się do odczytu maszynowego danych osobowych jej dotyczących, które dostarczyła administratorowi, oraz żądania
               przesłania tych danych innemu administratorowi, jeżeli dane są przetwarzane na podstawie zgody osoby, której dane dotyczą lub
               umowy z nią zawartej oraz jeżeli dane są przetwarzane w sposób zautomatyzowany (art. 20 RODO); g) do sprzeciwu – wniesienia sprzeciwu
               wobec przetwarzania jej danych osobowych w prawnie uzasadnionych celach administratora, z przyczyn związanych z jej szczególną
               sytuacją, w tym wobec profilowania. Wówczas administrator dokonuje oceny istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania,
               nadrzędnych wobec interesów, praw i wolności osób, których dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń. Jeżeli
               zgodnie z oceną interesy osoby, której dane dotyczą, będą ważniejsze od interesów administratora, administrator będzie zobowiązany zaprzestać
               przetwarzania danych w tych celach (art. 21 RODO).
               <br />
              2. Aby skorzystać z wyżej wymienionych praw, osoba, której dane dotyczą, powinna skontaktować się, wykorzystując podane dane kontaktowe,
              z administratorem i poinformować go, z którego prawa i w jakim zakresie chce skorzystać.
               <br />
              3. Osoba, której dane dotyczą, ma prawo wnieść skargę do organu nadzoru, którym jest Prezes Urzędu Ochrony Danych Osobowych w Warszawie.
              </p>

            <p>
              <strong>
                {"§7. Zautomatyzowane podejmowanie decyzji. Profilowanie"}
              </strong>
            </p>
            <p>
              Dane osobowe nie będą przetwarzane w sposób automatyczny, w tym poprzez profilowanie.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// style={{
//   backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`,
// }}
