import React, { useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export function WorkerDataForm({ activeWorker }) {
  const intl = useIntl();
  const { register, reset, setValue } = useForm({ mode: "onChange" });

  useEffect(() => {
    activeWorker && setFormFields();
  }, [activeWorker]);

  const setFormFields = () => {
    reset();
    setTimeout(() => {
      setValue("firstName", activeWorker.firstName);
      setValue("lastName", activeWorker.lastName);
      if (
        activeWorker.address2 &&
        activeWorker.postalCode &&
        activeWorker.address1
      ) {
        setValue(
          "address",
          `${activeWorker.address2}, ${activeWorker.postalCode} ${activeWorker.address1}`
        );
      } else if (activeWorker.address2) {
        setValue("address", activeWorker.address2);
      }
      setValue("pesel", activeWorker.pesel);
      setValue("internalCode", activeWorker.badge);
      setValue("contractType", activeWorker.contractType.name);
      setValue("item", activeWorker.item);
      setValue("comment", activeWorker.comment);
      setValue("passport", activeWorker.passport);
    });
  };

  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.FIRSTNAME" />
          </Form.Label>
          <Form.Control
            name="firstName"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.FIRSTNAME",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.LASTNAME" />
          </Form.Label>
          <Form.Control
            name="lastName"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.LASTNAME",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.ADDRESS" />
          </Form.Label>
          <Form.Control
            name="address"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.ADDRESS",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>
            <FormattedMessage id="GENERAL.CONTRACT_TYPE" />
          </Form.Label>
          <Form.Control
            name="contractType"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.CONTRACT_TYPE",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PESEL" />
          </Form.Label>
          <Form.Control
            name="pesel"
            type="text"
            placeholder="PESEL"
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.INTERNAL_CODE" />
          </Form.Label>
          <Form.Control
            name="internalCode"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.INTERNAL_CODE",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.PASSPORT_NUMBER" />
          </Form.Label>
          <Form.Control
            name="passport"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.PASSPORT_NUMBER",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>
            <FormattedMessage id="GENERAL.LABEL" />
          </Form.Label>
          <Form.Control
            name="item"
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.LABEL",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Label>
            <FormattedMessage id="GENERAL.COMMENT" />
          </Form.Label>
          <Form.Control
            name="comment"
            as="textarea"
            rows={5}
            type="text"
            placeholder={`${intl.formatMessage({
              id: "GENERAL.COMMENT",
            })}`}
            ref={register()}
            disabled
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
