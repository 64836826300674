import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Button } from "react-bootstrap";

export function HistoricalOperationRow({
  index,
  operation,
  onSelectingRow,
  getPayoutSignature,
}) {
  return (
    <tr
      className={`text-center ${
        operation.isSelected === true ? "offer--active" : ""
      }`}
      onClick={() => onSelectingRow(operation.__payoutDetailsId)}
    >
      <td>{index + 1}</td>
      <td>{operation.documentNo}</td>
      <td>{operation.payoutTypeName}</td>
      <td>{moment(operation.registerAt).format("YYYY-MM-DD")}</td>
      <td>{operation.value}</td>
      <td>1 {operation.currencyName}</td>
      <td>
        <Button
          onClick={() => getPayoutSignature(operation.__payoutHeaderId)}
          disabled={!operation.__signature}
        >
          <FormattedMessage id="GENERAL.SIGNATURE" />
        </Button>
      </td>
    </tr>
  );
}
